import { Timeline as MuiTimeline } from "@material-ui/lab";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";

// Components
import TimelineDot from "./TimelineDot";

// Types
import { TimelineProps } from "./Timeline";

// Styles
import useStyles from "./Timeline.styles";

/**
 * Timeline Component
 * @example Correct usage
 * ```tsx
 * <Timeline
 *  dotVariant="outlined"
 *  timelineItems={timelineItems}
 * />
 * ```
 * @example Correct usage to align content alternatively
 * ```tsx
 * <Timeline
 *  align="alternate"
 *  timeLineItems={timelineItems}
 * />
 * ```
 */
export const Timeline = ({
  timelineItems,
  dotColor,
  connectorColor,
  align = "left",
  dotVariant = "default",
  centered = false,
}: TimelineProps): JSX.Element => {
  const classes = useStyles({
    centered: centered || align === "alternate",
    connectorColor,
  });

  return (
    <MuiTimeline align={align} className={classes.timeline}>
      {timelineItems.map((itemProps, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            {itemProps.bulletType === "dot" ? (
              <TimelineDot
                type={itemProps.bulletType}
                dotColor={itemProps.currentDotColor || dotColor}
                dotVariant={dotVariant}
              />
            ) : (
              <TimelineDot
                type={itemProps.bulletType}
                icon={itemProps.icon}
                iconConfig={itemProps.iconConfig}
              />
            )}
            {index < timelineItems.length - 1 && (
              <TimelineConnector className={classes.timelineConnector} />
            )}
          </TimelineSeparator>
          <TimelineContent>{itemProps.content}</TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  );
};
