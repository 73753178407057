import { Box, Typography } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import Button from "@Components/Button";
import Tooltip from "@Components/Tooltip";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Constants
import Constants from "@Constants/index";
import {
  SamlConfigDetailsHeaderMap,
  OidcConfigDetailsHeaderMap,
  IdpConfigPathsToUser,
} from "@Constants/common";
import { ButtonIDs, SettingsIds, SVGIconIds, TooltipIds } from "@Constants/Id";

// Actions
import { showAlert } from "@Store/actions";

// Icons
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";

// Styles
import useStyles from "./styles";

// Props for ConfigDetailsToUser component
type ConfigDetailsToUserProps = {
  /**
   * Checks wether oidc protocol is selected.
   */
  isOidcSelected?: boolean;

  /**
   * Handles showing the configuration details to the user.
   * @param isIdpConfigVisible - boolean indicating if the details for configuring is shown or not
   */
  setIsIdpConfigVisible?: (isIdpConfigVisible: boolean) => void;

  /**
   * Handles changing the selected protocol for the external IDP
   * @param isSAMLSelected - boolean indicating if the SAML protocol is selected or not
   */
  setIsSAMLSelected: (isSAMLSelected: boolean) => void;

  /**
   * Handles changing the selected protocol for the external IDP
   * @param isOpenIDSelected - boolean indicating if the OpenID protocol is selected or not
   */
  setIsOpenIDSelected: (isOpenIDSelected: boolean) => void;

  /**
   * Handles changing the open state of the modal
   * @param isOpen - boolean indicating if the modal is open or not
   */
  setIsOpen?: (isOpen: boolean) => void;
};

const ConfigDetailsToUser = ({
  isOidcSelected,
  setIsOpenIDSelected,
  setIsSAMLSelected,
  setIsIdpConfigVisible,
  setIsOpen,
}: ConfigDetailsToUserProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();

  const OidcConfigData: Record<OidcConfigDetailsHeader, string> = {
    redirectUrl: IdpConfigPathsToUser.common.redirectOrReplyUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
    homePageUrl: IdpConfigPathsToUser.common.homePageOrSignOnUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
    logoutUrl: IdpConfigPathsToUser.common.logoutUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
  };

  const SamlConfigData: Record<SamlConfigDetailsHeader, string> = {
    entityId: IdpConfigPathsToUser.saml.entityId,
    replyUrl: IdpConfigPathsToUser.common.redirectOrReplyUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
    relayStateUrl: IdpConfigPathsToUser.common.homePageOrSignOnUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
    logoutUrl: IdpConfigPathsToUser.common.logoutUrl({
      tenantAlias: selectedTenant.selectedTenantName,
    }),
  };

  const configData = isOidcSelected ? OidcConfigData : SamlConfigData;
  const configHeader = isOidcSelected
    ? OidcConfigDetailsHeaderMap
    : SamlConfigDetailsHeaderMap;

  // Show success toast after copying data
  const handleOnCopyAlert = () =>
    dispatch(
      showAlert({
        message: Constants.SuccessMessages.copied,
        severity: Constants.AlertSeverity.success,
      })
    );

  return (
    <Box id={SettingsIds.configDetails} className={classes.detailsCard}>
      {Object.entries(configData).map(([key, value]) => (
        <Box key={key} className={`align-center ${classes.detailsRow}`}>
          <span
            className={`${classes.detailsHeader} flex-gap-6 d-flex align-center`}
          >
            <Typography className="wt-600" variant="body2">
              {configHeader[key as IdpConfigDetailsHeader]}
            </Typography>
            {configHeader[key as IdpConfigDetailsHeader] ===
              OidcConfigDetailsHeaderMap.logoutUrl &&
              isOidcSelected && (
                <Tooltip
                  id={TooltipIds.logoutURLDescription}
                  title="front-channel"
                  arrow
                  placement="bottom"
                  tooltipChildren={
                    <span className={classes.svgWrapper}>
                      <SVGWrapper
                        viewBox="0 0 10 10"
                        id={SVGIconIds.warningIcon}
                      >
                        {Constants.SVGIcons.warning}
                      </SVGWrapper>
                    </span>
                  }
                />
              )}
          </span>
          <div className="d-flex justify-between">
            <Typography
              id={`${configHeader[key as IdpConfigDetailsHeader]
                .toLowerCase()
                .replace(/\s/g, "-")}-config-details`}
              className={classes.detailsValue}
            >
              {value}
            </Typography>
            <CopyToClipboard text={value} onCopy={handleOnCopyAlert}>
              <FileCopyRoundedIcon
                className={`${classes.copyIcon} copy-icon cursor-pointer`}
              />
            </CopyToClipboard>
          </div>
        </Box>
      ))}
      <div className="d-flex margin-top-50 justify-between">
        <Button
          id={ButtonIDs.configDetailsToUserBack}
          onClick={() =>
            isOidcSelected
              ? setIsOpenIDSelected(false)
              : setIsSAMLSelected(false)
          }
        >
          Back
        </Button>
        {setIsIdpConfigVisible ? (
          <Button
            id={ButtonIDs.configDetailsToUserNext}
            onClick={() => setIsIdpConfigVisible(false)}
          >
            Next
          </Button>
        ) : (
          <Button
            id={ButtonIDs.configDetailsToUserClose}
            onClick={() => setIsOpen?.(false)}
          >
            Close
          </Button>
        )}
      </div>
    </Box>
  );
};

export default ConfigDetailsToUser;
