import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

// components
import InfoCard from "@Components/InfoCard";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import TooltipWithCopy from "@Components/Tooltip/TooltipWithCopy";
import TagGroup from "@Components/TagGroup";

// Actions
import {
  deleteTagFromDevice,
  setLastDeletedTag,
  updateDeletedTagsFromDeviceInfo,
} from "@Store/actions";

// Constants
import Constants from "@Constants";
import { AboutDeviceHeadings } from "@Constants/common";
import {
  DeviceDetailsContainerIds,
  InfoCardIds,
  TooltipIds,
} from "@Constants/Id";

// Utils
import Utils from "@Utils";

interface AboutDataProps {
  [Constants.AboutDeviceHeadings.localName]?: string | JSX.Element;
  [Constants.AboutDeviceHeadings.deviceName]: string | JSX.Element;
  [Constants.AboutDeviceHeadings.registeredOn]: string | JSX.Element;
  [Constants.AboutDeviceHeadings.lastHeartbeat]: string | JSX.Element;
  Agent: string | JSX.Element;
  Driver?: string | JSX.Element;
}

const AboutDevice: React.FC = (): JSX.Element => {
  // get the details about the device from the store
  const { infoDetails, loading } = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );
  const { loading: onDeleteProgress } = useSelector(
    (state: GlobalState) => state.deleteTagFromDevices
  );

  // initializing hook
  const dispatch = useDispatch();
  const { id: deviceId } = useParams<{ id: string }>();

  // format the date-time for registered-on and last-heartbeat fields
  const formatDateTime = (date: string) => {
    return Utils.formatDateTime(date, Constants.DateTimeFormats.format2);
  };

  // return loader if loading otherwise the data;
  const getLoaderOrData = (
    data: string | JSX.Element,
    truncate = true,
    id: string
  ) =>
    loading ? (
      <SkeletonLoader id={`${id}-skeleton`} />
    ) : truncate ? (
      <TooltipWithCopy
        tooltipText={(data || "") as string}
        tooltipCopyChildren={data || ""}
        id={id}
      />
    ) : (
      data
    );

  const agentInfo = infoDetails.agent as Record<string, string>;
  const aboutData: AboutDataProps = {
    [Constants.AboutDeviceHeadings.deviceName]: getLoaderOrData(
      infoDetails?.name as string,
      true,
      TooltipIds.deviceName
    ),
    [Constants.AboutDeviceHeadings.registeredOn]: getLoaderOrData(
      <TooltipWithCopy
        tooltipText={Utils.formatDateTime(infoDetails?.created_date as string)}
        tooltipCopyChildren={formatDateTime(
          infoDetails?.created_date as string
        )}
        id={TooltipIds.deviceRegisteredDate}
      />,
      false,
      TooltipIds.deviceRegisteredDate
    ),
    [Constants.AboutDeviceHeadings.lastHeartbeat]: getLoaderOrData(
      <TooltipWithCopy
        tooltipText={Utils.formatDateTime(
          infoDetails?.last_heartbeat_date as string
        )}
        tooltipCopyChildren={formatDateTime(
          infoDetails?.last_heartbeat_date as string
        )}
        id={TooltipIds.deviceLastHeartbeat}
      />,
      false,
      TooltipIds.deviceLastHeartbeat
    ),
    Agent: getLoaderOrData(
      agentInfo?.version && `v${agentInfo?.version}`,
      false,
      TooltipIds.agentVersion
    ),
    Driver: getLoaderOrData(
      (agentInfo?.driver_version && `v${agentInfo.driver_version}`) ||
        "Unknown",
      false,
      TooltipIds.driverVersion
    ),
  };

  const aboutDataIcons: Record<
    AboutDeviceHeadings | "Agent" | "Driver",
    JSX.Element
  > = {
    Agent: Constants.SVGIcons.updateIcon,
    Driver: Constants.SVGIcons.updateIcon,
    [Constants.AboutDeviceHeadings.localName]: Constants.SVGIcons.hardDriveIcon,
    [Constants.AboutDeviceHeadings.deviceName]:
      Constants.SVGIcons.hardDriveIcon,
    [Constants.AboutDeviceHeadings.registeredOn]:
      Constants.SVGIcons.clipBoardIcon,
    [Constants.AboutDeviceHeadings.lastHeartbeat]:
      Constants.SVGIcons.heartbeatIcon,
  };

  if (
    infoDetails?.local_name &&
    infoDetails?.name &&
    infoDetails?.local_name === infoDetails?.name
  ) {
    delete aboutData[Constants.AboutDeviceHeadings.localName];
  }

  if (!agentInfo?.driver_version) {
    delete aboutData[Constants.AboutDeviceHeadings.driver];
  }

  const handleDeleteTag = (data: string) =>
    dispatch(
      deleteTagFromDevice(
        { ids: [deviceId], tags: [data] },
        {
          message: Constants.SuccessMessages.tagDeleted,
          sideEffect: () => {
            dispatch(updateDeletedTagsFromDeviceInfo({ tags: [data] }));
            dispatch(setLastDeletedTag({ tag: data }));
          },
        }
      )
    );

  return (
    <>
      <div
        id={DeviceDetailsContainerIds.tagGroupWrapper}
        className="margin-bottom-15"
      >
        <TagGroup
          tags={(infoDetails.tags as string[]) ?? []}
          onTagDelete={handleDeleteTag}
          isDeleteProgress={onDeleteProgress}
          isLoading={loading}
        />
      </div>

      <Grid
        id={DeviceDetailsContainerIds.deviceStatsWrapper}
        container
        spacing={2}
        className="margin-bottom-30"
      >
        {Object.entries(aboutData).map(
          ([key, value], index) =>
            key !== Constants.AboutDeviceHeadings.deviceName && (
              <InfoCard
                id={InfoCardIds.aboutDetails({
                  value: key.toLowerCase().replace(/\s/g, "-"),
                })}
                width={Object.entries(aboutData).length === 4 ? 4 : 3}
                key={index}
                infoTitle={key}
                infoSubtitle={value}
                icon={aboutDataIcons[key as AboutDeviceHeadings]}
              />
            )
        )}
      </Grid>
    </>
  );
};

export default AboutDevice;
