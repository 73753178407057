import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tooltipPopper: {
    "& .MuiTooltip-tooltip": {
      borderRadius: theme.pxToRem(20),
    },
  },
  tooltip: {
    padding: theme.pxToRem(20),
  },
  tooltipPrimaryColor: {
    color: theme.newPalette.barGraph.tooltipTextPrimary,
  },
  tooltipSecondaryColor: {
    color: theme.newPalette.barGraph.tooltipTextSecondary,
  },
  tooltipTitle: {
    fontSize: theme.pxToRem(10),
    fontWeight: theme.fontWeights.semiBold,
    borderBottom: `${theme.pxToRem(2)} solid ${
      theme.newPalette.barGraph.tooltipTextPrimary
    }`,
    paddingBottom: theme.pxToRem(10),
    marginBottom: theme.pxToRem(14),
  },
  tooltipSubTitle: {
    fontSize: theme.pxToRem(12),
    marginTop: theme.pxToRem(2),
    fontWeight: theme.fontWeights.semiBold,
  },
  // To hide the top horizontal line of the bar graph
  hideTopLine: {
    "& .recharts-cartesian-grid .recharts-cartesian-grid-horizontal line:last-child":
      {
        display: "none",
      },
  },
}));

export default useStyles;
