import Constants from "@Constants";
import Utils from "@Utils";

const initialState: EventsForDeviceOrThreatState = {
  loading: false,
  events: [],
  error: "",
};

const eventsForDeviceOrThreatReducer = (
  state: EventsForDeviceOrThreatState = initialState,
  action: GenericAction
): EventsForDeviceOrThreatState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_SUCCESS:
      return {
        loading: false,
        events: action?.payload?.data,
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_FAILED:
      return {
        loading: false,
        events: [],
        error: action?.payload,
      };
  }
  return state;
};

export default eventsForDeviceOrThreatReducer;
