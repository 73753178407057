import React, { useEffect, useMemo } from "react";
import { Route, Redirect } from "react-router-dom";

// Hooks
import useAuth from "@Hooks/useAuth";
import useTenantAccess from "@Hooks/useTenantAccess";
import useInterval from "@Hooks/useInterval";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Client
import Client from "@Client/client";

// Constants
import Constants from "@Constants";
import {
  LeftMenuTabs,
  POLLING_INTERVAL_FOR_USER_ACCOUNT_INFO,
} from "@Constants/common";

// Props
type WithLayoutRouteProps = {
  loginRequired?: boolean;
  Layout: React.FC;
  Component: React.FC;
  path?: string;
  exact?: boolean;
  tabId?: LeftMenuTabs;
};

const WithLayoutRoute = ({
  Component,
  Layout,
  loginRequired,
  path,
  exact,
  tabId,
}: WithLayoutRouteProps): JSX.Element => {
  const auth = useAuth();

  const { fetchTenantInfo, setUseHarbinger, selectedTenant } =
    useTenantAccess();

  const {
    fetchAccessRoles,
    userRoles,
    updateCurrentAccessLevel,
    userHasTabAccess,
    currentAccessLevel,
  } = usePermissionFilter();

  const { REACT_APP_BASE_UI_HOSTNAME } = process.env;

  useInterval(async () => {
    if (!auth.oidcAuth?.userData?.expired) {
      try {
        const client = new Client();
        await client.getUserAccountInfoFromKeycloak();
      } catch (err: any) {
        // Unauthorized response while fetching user information from keycloak
        if (err.response.status === Constants.ErrorCodes.invalidCredential) {
          // If refresh-token can be used to get a new access-token, the user will be signed in automatically
          // Otherwise if the refresh token is expired or user account has been deleted, user will be redirected to login page
          auth.oidcAuth.signIn();
        }
      }
    } else {
      // If refresh-token can be used to get a new access-token, the user will be signed in automatically
      // Otherwise if the refresh token is also expired, user will be redirected to the login page
      auth.oidcAuth.userManager.signinSilent();
    }
  }, 1000 * POLLING_INTERVAL_FOR_USER_ACCOUNT_INFO);

  // Check tenantName on init
  useEffect(() => {
    setUseHarbinger(
      !window.location.hostname.includes(REACT_APP_BASE_UI_HOSTNAME ?? "")
    );
  }, []);

  useEffect(() => {
    if (auth.oidcAuth.userData) {
      fetchTenantInfo();
      fetchAccessRoles();
      // Get user info if not already present
      if (!auth.userInfo.fullname) {
        auth.fetchUserData();
      }
    }
  }, [auth.oidcAuth.userData]);

  useEffect(() => {
    updateCurrentAccessLevel();
  }, [selectedTenant, userRoles]);

  const redirectToLoginIfRequired = () => {
    // If we are using basic sign-in method then we need to check and redirect if user is not logged in
    // Otherwise if we are using sso sign-in method, the oidc-react library will take care of redirecting to login page if not logged in
    if (
      process.env.REACT_APP_USE_NATIVE_LOGIN &&
      loginRequired &&
      !auth.isLoggedIn()
    ) {
      return <Redirect to={Constants.AppRoutes.PublicRoutes.LOGIN} />;
    }
  };

  const componentWithLayout = (
    <Layout>
      <Component />
    </Layout>
  );

  const userHasNoTabAccess =
    tabId && !userHasTabAccess(tabId as ModulesWithRbac);

  const renderRouteComponent = () => {
    if (redirectToLoginIfRequired()) return redirectToLoginIfRequired();
    if (userHasNoTabAccess)
      return <Redirect to={Constants.AppRoutes.PublicRoutes.NOT_FOUND} />;
    if (
      process.env.REACT_APP_USE_NATIVE_LOGIN ||
      auth.oidcAuth.userData?.access_token
    )
      return componentWithLayout;
    return <></>;
  };

  const isUserAccessLevelMatchCurrentAccessLevel = useMemo(
    () =>
      userRoles.data.find(
        (userRole) =>
          userRole.group === "Halcyon" ||
          userRole.group === selectedTenant.selectedTenantId
      )?.accessLevel === currentAccessLevel,
    [userRoles, selectedTenant, currentAccessLevel]
  );

  /**
   * This check is placed to wait for userRoles, currentAccessLevel states to get updated when
   *  - the page is refreshed
   *  - when the link is entered in the address bar
   *
   * if the users access role in the selected tenant matches the currentAccessLevel
   * all required states are updated and is proceeded with the Route checks
   */

  return isUserAccessLevelMatchCurrentAccessLevel ? (
    <Route exact={exact} path={path} render={renderRouteComponent} />
  ) : (
    <></>
  );
};

export default WithLayoutRoute;
