import { SortStateType } from "@Components/SortDropdownGroup";

// Different fields on which sorting can be applied for devices table
export const DevicesTableSortingFields = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Registered Date",
    value: "registeredDate",
  },
  {
    label: "OS Name",
    value: "osName",
  },
  {
    label: "Agent Version",
    value: "agentVersion",
  },
  {
    label: "Heartbeat",
    value: "heartbeat",
  },
];

// Events sorting field params
export enum EventsSortingFieldParams {
  name = "name",
  occurrenceDate = "occurrenceDate",
  firstOccurrenceDate = "firstOccurrenceDate",
  deviceName = "deviceName",
  actionType = "actionType",
}

// Different fields on which sorting can be applied for events table
export const EventsTableSortingFields = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Occurrence Date",
    value: "occurrenceDate",
  },
  {
    label: "First Occurrence Date",
    value: "firstOccurrenceDate",
  },
  {
    label: "Device Name",
    value: "deviceName",
  },
  {
    label: "Action Type",
    value: "actionType",
  },
];

// constant values used in sortDropdownGroup
export const ResetStateForSortBy = "RESET";

// Different sorting orders
export const SortingOrders: Record<"ASC" | "DESC", SortStateType> = {
  ASC: "asc",
  DESC: "desc",
};

// Default field on which sorting is applied for devices
export const DefaultSortingFieldForDevices = "registeredDate";

// Default sorting order
export const DefaultSortingOrder = SortingOrders.DESC;

// Default field on which sorting is applied for events
export const DefaultSortingFieldForEvents = "occurrenceDate";

// Query params for sorting
export const SortingQueryParamNames = ["sortBy", "sortOrder"];

// Constant value for sortBy search param
export const sortBySearchParam = "sortBy";

// Map between the sorting orders to toggle them when there is a change
export const NewSortOrderMap: Record<
  "default" | "asc" | "desc",
  "asc" | "desc"
> = {
  asc: "desc",
  desc: "asc",
  default: "desc",
};
