import { useEffect } from "react";
import { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Popover as MUIPopover } from "@material-ui/core";
import { usePopupState as usePopoverState } from "material-ui-popup-state/hooks";
import { useLocation } from "react-router-dom";

// Components
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import Constants from "@Constants";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";

// Styles
import useStyles from "./Popover.styles";

// Types
import { PopoverProps } from "./Popover";

/**
 * Popover component - used to add popovers
 *
 * @example Correct usage
 * ```ts
 * <Popover
 *  id={uniqueId}
 *  popOverChildren={
 *      <ProfilePopoverContent id={uniqueId} />
 *  }
 *  setPopoverOpenState={setProfilePopoverOpen}
 *  showDropDownCaret
 * >
 *  <div>Click me</div>
 * </Popover>
 * ```
 */
export const Popover = ({
  id,
  children,
  popOverChildren,
  extraClass = "",
  closeOnClick = false,
  showBorderRadius = false,
  setPopoverOpenState = null,
  popoverOpenState = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  showDropDownCaret = false,
}: PopoverProps): JSX.Element => {
  const location = useLocation();
  const classes = useStyles();
  const popoverState = usePopoverState({
    variant: "popper",
    popupId: "popover",
  });
  const { activeTheme } = useAppTheme();

  // It may be required to know the state whether the popover is open or not, set if popover state setter function is passed
  useEffect(() => {
    setPopoverOpenState?.(popoverState.isOpen);
    if (popoverState.isOpen) {
      // When popover is in open state, there will be a padding added in place of scrollbar
      // The background of the body can't be handled from scss, since we don't have access to the selected theme
      document.querySelector("body")?.classList.add(classes.appBg);
    }
  }, [popoverState.isOpen, activeTheme]);

  useEffect(() => {
    if (popoverOpenState) popoverState.open(popoverState.anchorEl);
    else popoverState.close();
  }, [popoverOpenState]);

  useEffect(() => {
    popoverState.close();
  }, [location]);

  return (
    <div className={extraClass} id={id}>
      <div
        className={`flex ${classes.root}`}
        {...bindTrigger(popoverState)}
        id={`${id}-children`}
      >
        {children}
        {showDropDownCaret && (
          <span
            className={`${classes.caret} ${
              popoverState.isOpen && classes.caretRotated
            }`}
          >
            <SVGWrapper width={20} height={20} viewBox="0 0 20 20">
              {Constants.SVGIcons.angleDown}
            </SVGWrapper>
          </span>
        )}
      </div>
      <MUIPopover
        {...bindPopover(popoverState)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: "custom-scroll-bar" }}
        className={`${classes.contentWrapper} ${
          showBorderRadius && classes.borderRadius
        } ${extraClass}`}
        id={`${id}-popup`}
      >
        <div
          onClick={() => closeOnClick && popoverState.close()}
          id={`${id}-popup-content`}
        >
          {popOverChildren}
        </div>
      </MUIPopover>
    </div>
  );
};
