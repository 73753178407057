import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      width: theme.pxToRem(630),
      padding: `${theme.pxToRem(40)} 0`,
      maxHeight: theme.pxToRem(200),
      transition: "max-height 100ms ease-in-out",
    },
    "& .MuiDialogContent-root": {
      paddingTop: theme.pxToRem(6),
      padding: `0 ${theme.pxToRem(40)}`,
      textAlign: "center",
    },
  },
  showIdpConfigExpanded: {
    "& .MuiDialog-paper": {
      transition: "max-height 100ms ease-out",
      maxHeight: theme.pxToRem(600),
    },
  },
  modalExpanded: {
    "& .MuiDialog-paper": {
      transition: "max-height 100ms ease-out",
      maxHeight: theme.pxToRem(720),
    },
  },
  modalTitle: {
    marginTop: theme.pxToRem(-11),
    padding: `0 ${theme.pxToRem(40)} !important`,
    width: "100%",
  },
  modalButton: {
    width: theme.pxToRem(207),
  },
  modalSubtitle: {
    alignSelf: "start",
    padding: `0 ${theme.pxToRem(40)} !important`,
    maxWidth: "unset",
    marginBottom: "unset",
  },
}));

export default useStyles;
