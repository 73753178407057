import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },

  switchBase: {
    padding: 1,

    "&.Mui-checked": {
      transform: `translateX(${theme.pxToRem(16)})`,
      color: theme.newColors.neutral[100],

      "& + $track": {
        backgroundColor: theme.newColors.primary.fieryOrange[500],
        opacity: 1,
        border: "none",
      },
    },

    "&.Mui-focusVisible $thumb": {
      color: theme.newColors.fieryOrange,
      border: `${theme.pxToRem(6)} solid ${theme.newColors.neutral[100]}`,
    },
  },

  thumb: {
    width: theme.pxToRem(22),
    height: theme.pxToRem(22),
    top: theme.pxToRem(1),
  },

  track: {
    borderRadius: theme.pxToRem(13),
    backgroundColor: theme.newColors.secondary.charcoal[300],
    opacity: 1,
  },
}));

export default useStyles;
