import { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import {
  DateRangePicker,
  Range,
  RangeKeyDict,
  ClassNames,
} from "react-date-range";

// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Components
import Button from "@Components/Button";
import Popover from "@Components/Popover";
import SVGWrapper from "@Components/SVGWrapper";

// Utils
import Utils from "@Utils";

// Constants
import Constants from "@Constants";
import { ButtonIDs } from "@Constants/Id";

// Styles
import useStyle from "./DatePicker.styles";

// Types
import { DatePickerProps } from "./DatePicker";

enum WeekDays {
  Sun = 0,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export const DatePicker = ({
  id,
  startDate = new Date(),
  endDate = new Date(),
  monthsRange = 2,
  showMonthAndYearPickers = true,
  moveRangeOnFirstSelection = true,
  weekStartsOn = WeekDays.Mon,
  dateFormat = Constants.DateTimeFormats.datePickerFormat,
  calendarDirection = window.innerWidth >
  Constants.ScreenWidth.minSpaceForDatePicker
    ? "horizontal"
    : "vertical",
  editableDateInputs = true,
  onChange,
}: DatePickerProps): JSX.Element => {
  const classes = useStyle();

  const [state, setState] = useState<Range>({
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  });

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
    }));
  }, [startDate, endDate]);

  const handleStateChange = (newRange: RangeKeyDict) => {
    setState(newRange.selection);
  };

  const applyDateRangeChange = () => {
    onChange?.(state);
    setPopoverOpen(false);
  };

  // custom classes for styling
  const datePickerClasses: ClassNames = {
    dateRangePickerWrapper: classes.calendarWrapper,
    calendarWrapper: classes.calendarWrapper,
    startEdge: classes.edge,
    endEdge: classes.edge,
    weekDays: classes.day,
    dayStartPreview: classes.dayInPreview,
    dayEndPreview: classes.dayInPreview,
    dayInPreview: classes.dayInPreview,
    inRange: classes.inRange,
    monthAndYearPickers: classes.monthAndYearPickers,
    dayNumber: classes.day,
    dayPassive: classes.dayPassive,
    dayDisabled: classes.dayDisabled,
    monthName: `text-align-center ${classes.monthName}`,
  };

  return (
    <Popover
      id={id}
      extraClass={classes.popoverWrapper}
      setPopoverOpenState={setPopoverOpen}
      popoverOpenState={popoverOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      popOverChildren={
        <>
          <div className={`${classes.titleWrapper} flex justify-between`}>
            <Typography
              id={`${id}-time-range`}
              variant="subtitle1"
              className={classes.popoverTitle}
            >
              Time Range
            </Typography>
            <Button
              id={ButtonIDs.closeDatePicker({ id })}
              variant="text"
              extraClass={`${classes.closeButton}`}
              onClick={() => setPopoverOpen(false)}
            >
              <SVGWrapper
                width={24}
                height={24}
                viewBox="0 0 24 24"
                id={`${id}-close-icon`}
              >
                {Constants.SVGIcons.cross}
              </SVGWrapper>
            </Button>
          </div>
          <DateRangePicker
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date()}
            ranges={[state]}
            months={monthsRange}
            showMonthAndYearPickers={showMonthAndYearPickers}
            moveRangeOnFirstSelection={moveRangeOnFirstSelection}
            direction={calendarDirection}
            weekStartsOn={weekStartsOn}
            editableDateInputs={editableDateInputs}
            classNames={datePickerClasses}
            onChange={handleStateChange}
          />
          <div className="w-100 text-align-right">
            <Button
              id={ButtonIDs.applyDatePicker({ id })}
              variant="contained"
              extraClass={classes.applyButton}
              onClick={applyDateRangeChange}
            >
              Apply
            </Button>
          </div>
        </>
      }
    >
      <div className={`flex justify-start ${classes.DropDownButton}`}>
        <span className={classes.text}>
          {Utils.formatDateTime(String(state.startDate ?? ""), dateFormat)} -{" "}
          {Utils.formatDateTime(String(state.endDate ?? ""), dateFormat)}
        </span>
        <ExpandMoreIcon
          className={`${classes.text} ${classes.icon} ${
            popoverOpen && classes.popoverExpandedIcon
          }`}
        />
      </div>
    </Popover>
  );
};
