import { AxiosInstance, AxiosResponse } from "axios";
import axios from "axios";

// Interceptor
import { axiosInterceptor } from "./interceptor";

// Constants
import Constants from "@Constants";
import { IdpProtocols } from "@Constants/common";

// Utils
import utils from "@Utils";

const { REACT_APP_BASE_API_URL } = process.env;

/**
 *  Add web utilities for interacting with the server here.
 */
export default class Client {
  baseHostName = process.env.REACT_APP_BASE_UI_HOSTNAME;

  apiBaseUrl?: string;

  axiosApiInstance: AxiosInstance;

  constructor() {
    this.apiBaseUrl = REACT_APP_BASE_API_URL;
    this.axiosApiInstance = axiosInterceptor().setupInterceptor(axios);
  }

  // Get events
  getEvents = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string,
    sortBy: string,
    sortOrder: string,
    deviceName: string,
    eventName: string,
    subject: string,
    actionType: string,
    search: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.Events}`,
        {
          page,
          pageSize,
          sortBy,
          sortOrder,
          deviceName,
          name: eventName,
          actionType,
          subject,
          search,
        }
      ),
      true,
      abortSignal
    );

  getCriticalEvents = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string,
    sortBy: string,
    sortOrder: string,
    deviceName: string,
    eventName: string,
    subject: string,
    search: string,
    startTime: string,
    endTime: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.Events}`,
        {
          page,
          pageSize,
          actionType: "Block",
          sortBy,
          sortOrder,
          deviceName,
          name: eventName,
          subject,
          search,
          startTime,
          endTime,
        }
      ),
      true,
      abortSignal
    );

  // Get event details
  getEventDetails = (abortSignal: AbortSignal | null, eventId: string) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.EventDetails(eventId)}`,
      true,
      abortSignal
    );

  // Get devices
  getDevices = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string,
    sortBy: string,
    sortOrder: string,
    attentionType: string,
    tag: string,
    name: string,
    operatingSystem: string,
    agentVersion: string,
    search: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.Devices}`,
        {
          page,
          pageSize,
          attentionType,
          sortBy,
          sortOrder,
          tag,
          name,
          operatingSystem,
          agentVersion,
          search,
        }
      ),
      true,
      abortSignal ?? null
    );

  // triggers login API
  userLogin = (
    _abortSignal: AbortSignal | null,
    username: string,
    password: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = {
      username: username,
      password: password,
    };
    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.Login}`,
      payload
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  postRequestFile = (
    _abortSignal: AbortSignal | null,
    eventId: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> =>
    this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.RequestFile(eventId)}`
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;

  // clear all tokens and logout user
  userLogout = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _abortSignal: AbortSignal | null
  ) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerLogout}`,
      sendTenantHeader: false,
    });

  refreshToken = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _abortSignal: AbortSignal | null
  ): Promise<AxiosResponse<{ [key: string]: string }>> =>
    this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.RefreshToken}`
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;

  // Get user details
  getUserDetails = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.UserDetails}`,
      false,
      abortSignal
    );

  // Get user details in Harbinger setup
  harbingerGetUserDetails = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerUserDetails}`,
      false,
      abortSignal
    );

  // Get list of events for a device or a threat
  getEventsForDeviceOrThreat = (
    abortSignal: AbortSignal | null,
    deviceId: string,
    page: string,
    pageSize: string,
    deviceOrThreat: string,
    sortBy: string,
    sortOrder: string,
    deviceName: string,
    eventName: string,
    search: string,
    subject: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.Events}`,
        {
          page,
          pageSize,
          ...(deviceOrThreat === "threat"
            ? { threatId: deviceId }
            : { deviceId }),
          sortBy,
          sortOrder,
          deviceName,
          eventName,
          subject,
          search,
        }
      ),
      true,
      abortSignal
    );

  // Get device info details
  getDeviceInfoDetails = (abortSignal: AbortSignal | null, deviceId: string) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.DeviceInfoDetails(deviceId)}`,
      true,
      abortSignal
    );

  // Get threat details
  getThreatDetails = (abortSignal: AbortSignal | null, threatId: string) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.ThreatDetails(threatId)}`,
      true,
      abortSignal
    );

  // Tenant users list
  getTenantUsers = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string,
    selectedTenantId: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerTenantUsers(
          selectedTenantId
        )}`,
        {
          page,
          page_size: pageSize,
        }
      ),
      false,
      abortSignal
    );

  // get device metrics
  getDeviceMetrics = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.DeviceMetrics}`,
      true,
      abortSignal
    );

  // get event metrics by month
  getEventMetricsByMonth = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.EventMetricsByMonth}`,
      true,
      abortSignal
    );

  // Get extracted key Info
  getExtractedKeyInfo = (
    abortSignal: AbortSignal | null,
    deviceId: string,
    extractedKeyId: string
  ) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.ExtractedKeyInfo(
        deviceId,
        extractedKeyId
      )}`,
      true,
      abortSignal
    );

  // Get Device Extracted Keys
  getDeviceExtractedKeys = (
    abortSignal: AbortSignal | null,
    deviceId: string,
    page: string,
    pageSize: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.DeviceExtractedKeys(deviceId)}`,
        {
          page: page,
          page_size: pageSize,
        }
      ),
      true,
      abortSignal
    );

  // Add a tenant user
  addTenantUser = (
    _abortSignal: AbortSignal | null,
    email: string,
    parentTenantId: string,
    role: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = { email, ...(role ? { role } : {}) };

    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerTenantUsers(
        parentTenantId
      )}`,
      payload,
      false
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  // Delete a tenant user
  deleteTenantUser = (
    _abortSignal: AbortSignal | null,
    userId: string,
    parentTenantId: string
  ) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.tenantUser(
        userId,
        parentTenantId
      )}`,
      sendTenantHeader: false,
    });

  // Update user details
  updateUserDetails = (
    _abortSignal: AbortSignal | null,
    fullName: string,
    email: string,
    isEulaAccepted: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = {
      full_name: fullName,
      email,
    };

    if (isEulaAccepted) {
      payload.accepted_eula = isEulaAccepted;
    }

    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.UserDetails}`,
      payload
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  harbingerUpdateUserProfile = (
    _abortSignal: AbortSignal | null,
    firstName: string,
    lastName: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = {
      firstName: firstName,
      lastName: lastName,
    };
    return this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerUserDetails}`,
      payload,
      false
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  // Add tag to devices
  createTag = (
    _abortSignal: AbortSignal | null,
    tag: string
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    return this.doPut(`${this.apiBaseUrl}/${Constants.APIUrls.tag(tag)}`);
  };

  // Delete the given tag
  deleteTag = (_abortSignal: AbortSignal | null, tag: string) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.tag(tag)}`,
      sendTenantHeader: true,
    });

  // Add tags to devices
  addTagsToDevices = (
    _abortSignal: AbortSignal | null,
    ids: string[],
    tags: string[]
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const payload: APIRequestPayload = {
      ids,
      tags,
    };
    return this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.deviceTags}`,
      payload,
      true
    );
  };

  // Delete tag from a specified resource
  deleteTagsFromDevices = (
    _abortSignal: AbortSignal | null,
    ids: string[],
    tags: string[]
  ) => {
    const body = {
      ids,
      tags,
    };
    return this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.deviceTags}`,
      body: body,
    });
  };

  // Fetch tag list
  fetchTagList = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.tag()}`,
      true,
      abortSignal
    );

  // Edit/update a tag
  editTag = (_abortSignal: AbortSignal | null, add: string, remove: string) =>
    this.doPut(`${this.apiBaseUrl}/${Constants.APIUrls.editTag}`, {
      add,
      remove,
    });

  // Fetch device tag count.
  deviceTagCount = (_abortSignal: AbortSignal | null, tags: string[]) =>
    this.doPost(`${this.apiBaseUrl}/${Constants.APIUrls.deviceTagCount}`, {
      filters: tags,
    });

  // Update user password
  updateUserPassword = (
    _abortSignal: AbortSignal | null,
    currentPassword: string,
    newPassword: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = {
      currentPassword,
      newPassword,
    };

    return this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerUpdateUserPassword}`,
      payload,
      false
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  // Unblock event action
  unblockEventAction = (
    _abortSignal: AbortSignal | null,
    threatId: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.UnblockEventAction(threatId)}`
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  // Get agent installer details
  getAgentInstallerDetails = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.AgentInstaller}`,
      true,
      abortSignal
    );

  // Get the events metrics for dashboard
  getEventMetrics = (
    abortSignal: AbortSignal | null,
    startTime?: string,
    endTime?: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.EventMetrics}`,
        {
          ...(startTime && { startTime }),
          ...(endTime && { endTime }),
        }
      ),
      true,
      abortSignal
    );

  // Get Partitioned Event Metrics
  getPartitionedEventMetrics = (
    abortSignal: AbortSignal | null,
    startTime?: string,
    endTime?: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.PartitionedEventMetrics}`,
        {
          ...(startTime && { startTime }),
          ...(endTime && { endTime }),
        }
      ),
      true,
      abortSignal
    );

  // Generate a new agent installer token
  generateAgentInstallerToken = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _abortSignal: AbortSignal | null
  ): Promise<AxiosResponse<{ [key: string]: string }>> =>
    this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.AgentInstaller}`
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;

  downloadThreat = (abortSignal: AbortSignal | null, threatId: string) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.DownloadThreat(threatId)}`,
      true,
      abortSignal
    );

  // Fetch child Tenants
  getChildTenants = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string,
    selectedTenantId: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerChildTenants(
          selectedTenantId
        )}`,
        {
          page: page,
          page_size: pageSize,
        }
      ),
      false,
      abortSignal
    );

  deleteChildTenant = (
    _abortSignal: AbortSignal | null,
    tenantId: string,
    parentTenantId: string
  ) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerDeleteChildTenant(
        tenantId,
        parentTenantId
      )}`,
      sendTenantHeader: false,
    });

  // Add or edit a tenant
  addTenant = (
    _abortSignal: AbortSignal | null,
    name: string,
    alias: string,
    tenantId: string
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const payload: PostRequestBody = {
      name,
      alias,
    };

    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerChildTenants(tenantId)}`,
      payload,
      false
    );
  };

  // Delete a device
  deleteDevice = (_abortSignal: AbortSignal | null, deviceId: string) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.DeleteDevice(deviceId)}`,
    });

  // Fetch Tenant(s) user can access
  getCurrentTenantInfo = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.HarbingerCurrentTenantInfo}`,
      false,
      abortSignal
    );

  getUserAccountInfoFromKeycloak = () =>
    this.doGet(
      `${process.env.REACT_APP_BASE_SSO_URL}/${Constants.APIUrls.HarbingerKeycloakUserAccountInfo}`,
      false
    );

  getUserRoles = (abortSignal: AbortSignal | null) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.UserRoles}`,
      false,
      abortSignal
    );

  getTenantUserRole = (
    abortSignal: AbortSignal | null,
    userId: string,
    tenantId: string
  ) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.TenantUserRole(
        userId,
        tenantId
      )}`,
      false,
      abortSignal
    );

  // Update the role of the user
  updateTenantUserRole = (
    _abortSignal: AbortSignal | null,
    userId: string,
    parentTenantId: string,
    role: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    const payload: APIRequestPayload = {
      role,
    };

    return this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.TenantUserRole(
        userId,
        parentTenantId
      )}`,
      payload,
      false
    ) as Promise<AxiosResponse<{ [key: string]: string }>>;
  };

  // Get all the tenant's cloud credentials
  getExportConfigs = (
    abortSignal: AbortSignal | null,
    page: string,
    pageSize: string
  ) =>
    this.doGet(
      utils.getURLFromQueryParams(
        `${this.apiBaseUrl}/${Constants.APIUrls.ExportConfigs}`,
        {
          page,
          page_size: pageSize,
        }
      ),
      true,
      abortSignal
    );

  // Update export config
  updateExportConfigs = (
    _abortSignal: AbortSignal | null,
    exportUrl: string,
    token: string,
    authHeaderKey: string,
    authHeaderValue: string,
    enabled: string,
    configId: string
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const payload = {
      exportUrl,
      ...(token && { token }),
      ...(authHeaderKey && { authHeaderKey }),
      ...(authHeaderValue && { authHeaderValue }),
      enabled: enabled === "true",
    };

    return this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.ExportConfigs}/${configId}`,
      payload
    );
  };

  //  Create new export config
  createExportConfigs = (
    _abortSignal: AbortSignal | null,
    exportUrl: string,
    token: string,
    authHeaderKey: string,
    authHeaderValue: string,
    enabled: string
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const payload = {
      exportUrl,
      ...(token && { token }),
      ...(authHeaderKey && { authHeaderKey }),
      ...(authHeaderValue && { authHeaderValue }),
      enabled: enabled === "true",
    };

    return this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.ExportConfigs}`,
      payload
    );
  };

  // Delete an export config by using "configId"
  deleteExportConfig = (_abortSignal: AbortSignal | null, configId: string) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.ExportConfigs}/${configId}`,
    });

  // Fetch tenant user by user id.
  fetchTenantUserByUserId = (
    _abortSignal: AbortSignal | null,
    userId: string,
    parentTenantId: string
  ) =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.tenantUser(
        userId,
        parentTenantId
      )}`,
      false
    );

  // Get all the tenant's cloud credentials
  fetchIdpConfig = (
    abortSignal: AbortSignal | null,
    tenantId: string
  ): Promise<AxiosResponse<never>> =>
    this.doGet(
      `${this.apiBaseUrl}/${Constants.APIUrls.IdpConfig(tenantId)}`,
      false,
      abortSignal
    );

  // Create Idp Config
  createIdpConfig = (
    _abortSignal: AbortSignal | null,
    { type, ...payload }: SAMLRequestSchema | OIDCRequestSchema,
    tenantId: string
  ) =>
    this.doPost(
      `${this.apiBaseUrl}/${Constants.APIUrls.createUpdateIDPConfig({
        tenantId,
        isSAML: type === IdpProtocols.saml,
      })}`,
      payload,
      false
    );

  // Update IDP config
  updateIdpConfig = (
    _abortSignal: AbortSignal | null,
    { type, ...payload }: SAMLRequestSchema | OIDCRequestSchema,
    tenantId: string
  ) =>
    this.doPut(
      `${this.apiBaseUrl}/${Constants.APIUrls.createUpdateIDPConfig({
        tenantId,
        isSAML: type === IdpProtocols.saml,
      })}`,
      payload,
      false
    );

  // Delete Idp
  deleteIdpConfig = (_abortSignal: AbortSignal | null, tenantID: string) =>
    this.doDelete({
      url: `${this.apiBaseUrl}/${Constants.APIUrls.IdpConfig(tenantID)}`,
      sendTenantHeader: false,
    });

  // Bulk tagging devices matching a device filter
  tagAllDevicePages = (
    _abortSignal: AbortSignal | null,
    payload: Record<string, any>
  ) =>
    this.doPost(`${this.apiBaseUrl}/${Constants.APIUrls.deviceTags}`, payload);

  // Makes get request
  doGet = async (
    url: string,
    sendTenantHeader?: boolean,
    abortSignal?: AbortSignal | null
  ): Promise<AxiosResponse<never>> => {
    const response = await this.axiosApiInstance.get(url, {
      _retry: false,
      sendTenantHeader: sendTenantHeader ?? true,
      ...(abortSignal ? { signal: abortSignal } : {}),
    });
    return response;
  };

  // Makes post request
  doPost = async (
    url: string,
    body?: PostRequestBody,
    sendTenantHeader?: boolean
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const response = await this.axiosApiInstance.post(url, body, {
      _retry: false,
      sendTenantHeader: sendTenantHeader ?? true,
    });
    return response;
  };

  // Makes put request
  doPut = async (
    url: string,
    body?: PostRequestBody,
    sendTenantHeader?: boolean
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const response = await this.axiosApiInstance.put(url, body, {
      _retry: false,
      sendTenantHeader: sendTenantHeader ?? true,
    });
    return response;
  };

  // Makes update request
  doPatch = async (
    url: string,
    body: APIRequestPayload,
    sendTenantHeader?: boolean
  ): Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>> => {
    const response = await this.axiosApiInstance.patch(url, body, {
      _retry: false,
      sendTenantHeader: sendTenantHeader ?? true,
    });
    return response;
  };

  doDelete = async ({
    url,
    body,
    sendTenantHeader,
  }: {
    url: string;
    body?: { [key: string]: string | string[] };
    sendTenantHeader?: boolean;
  }): Promise<
    AxiosResponse<never> | AxiosResponse<{ [key: string]: string | string[] }>
  > => {
    const response = await this.axiosApiInstance.delete(url, {
      data: body,
      _retry: false,
      sendTenantHeader: sendTenantHeader ?? true,
    });
    return response;
  };
}
