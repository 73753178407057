import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  fetchDeviceExtractedKeys,
  fetchExtractedKeyInfo,
} from "@Store/actions";

// Hooks
import usePagination from "@Hooks/usePagination";

// Components
import Button from "@Components/Button";
import PaginatedTable from "@Components/Table/PaginatedTable";

// Constants
import Constants from "@Constants";
import { ButtonIDs, TableIds } from "@Constants/Id";

// Utils
import Utils from "@Utils";

type EventsForDeviceProps = {
  deviceId: string;
};

const DeviceExtractedKeys = ({
  deviceId,
}: EventsForDeviceProps): JSX.Element => {
  // Get extracted keys for device state from redux store
  const { extractedKeys, pagination, loading } = useSelector(
    (state: GlobalState) => state.deviceExtractedKeys
  );

  // Get extracted key info details
  const extractedKeyInfo = useSelector(
    (state: GlobalState) => state.extractedKeyInfo
  );

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  const { currentPage, noOfRowsPerPage, onPageChange, onRowSizeChange } =
    usePagination({
      searchString: `tab=${Constants.DeviceDetailsTabIndex.extractedKeys}`,
    });

  // Track redux state extractedKeyInfo belongs to which extractedKey, that is, whose value is visible
  const [extractedKeyValues, setExtractedKeyValues] = useState<
    Record<string, string>
  >({});

  // when a new extractedKey is viewed, store it.
  useEffect(() => {
    const { extractedKey } = extractedKeyInfo;
    if (extractedKey.extracted_key_id && !extractedKeyValues[extractedKey.id])
      setExtractedKeyValues((prev) => ({
        ...prev,
        [extractedKey.id]: extractedKey.extracted_key,
      }));
  }, [extractedKeyInfo.extractedKey]);

  const infoIconColumnConfig: TableIconColumnConfig = {
    onClick: async (data: RowData) => {
      // fetch only if we don't have already fetched
      const id = data.id as CellData;
      if (!extractedKeyValues[id.value]) {
        dispatch(fetchExtractedKeyInfo(deviceId, id.value));
      }
    },
    iconElement: (data: RowData): JSX.Element => (
      <Button
        id={ButtonIDs.viewExtractedKeyValue({
          deviceId: data.device_id?.value,
        })}
        color="secondary"
        size="small"
        disabled={
          extractedKeyInfo.loading ||
          !!extractedKeyValues[(data.id as CellData)?.value] ||
          extractedKeyValues[(data.id as CellData)?.value] === ""
        }
      >
        View
      </Button>
    ),
  };

  // Trigger dispatching the action to fetch data, when the component loads or page number changes in table
  useEffect(() => {
    if (deviceId) {
      // Dispatch action to fetch data for table
      dispatch(
        fetchDeviceExtractedKeys(
          { page: currentPage, pageSize: noOfRowsPerPage },
          deviceId
        )
      );
    }
  }, [deviceId, currentPage, noOfRowsPerPage]);

  useEffect(() => {
    return () => {
      dispatch({
        type: Constants.ActionTypes.CLEAR_EXTRACTED_KEY_INFO_STATE,
      });
    };
  }, []);

  const addMaskingConfig = (rowsData: RowData[]): RowData[] => {
    return rowsData.map((rowData) => {
      const extractedKey = rowData?.extracted_key as CellData;
      const id = rowData?.id as CellData;

      // Unmask data even when the actual value of extracted_key is empty
      return extractedKeyValues[id?.value] ||
        extractedKeyValues[id?.value] === ""
        ? {
            ...rowData,
            extracted_key: {
              value: extractedKeyValues[id?.value] || "-",
              masked: false,
            },
          }
        : {
            ...rowData,
            extracted_key: {
              ...extractedKey,
              masked: true,
              maskedCharLength: 40,
            },
          };
    });
  };

  const getRowsData = (): RowData[] => {
    const parsedData = Utils.parseToRowDataType(
      Constants.TableColumnsConfig.deviceExtractedKeys,
      extractedKeys
    );
    return addMaskingConfig(parsedData);
  };

  return (
    <PaginatedTable
      id={TableIds.deviceExtractedKeys}
      isLoading={loading}
      headers={Constants.TableColumnsConfig.deviceExtractedKeys}
      rowsData={getRowsData()}
      iconColumn={infoIconColumnConfig}
      showPagination
      pagination={{
        noOfRowsPerPage: noOfRowsPerPage,
        totalRowsAvailable: pagination?.totalRowsAvailable,
        currentPageNumber: currentPage,
        onClickSetPageSize: (pageSize: number) => {
          onRowSizeChange(pageSize);
        },
        jumpToPage: (pageNumber: number) => {
          onPageChange(pageNumber);
        },
        lastPageNumber: pagination?.lastPageNumber,
      }}
    />
  );
};

export default DeviceExtractedKeys;
