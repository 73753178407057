import { useState } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";

import { showAlert } from "@Store/actions";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import Tooltip from "@Components/Tooltip";

// Icons
import SVGIcons from "@Constants/icon";

import Constants from "@Constants";

// Styles
import useStyles from "./styles";

interface MaskedContentProps {
  // Text to mask
  text: string;
  // Masking function
  // This function should return modified string
  maskFunction?: (text: string) => string | JSX.Element;
  // Masked with warning icon
  maskWithWarnIcon?: boolean;
}

const MaskedContent = ({
  text,
  maskFunction,
  maskWithWarnIcon,
}: MaskedContentProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  // Show success toast after copying data
  const handleOnCopyAlert = () => {
    dispatch(
      showAlert({
        message: Constants.SuccessMessages.copied,
        severity: Constants.AlertSeverity.success,
      })
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
      <Box
        component={"span"}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip
          title={text}
          showArrow
          placement="bottom-start"
          disableFocusListener
          disableHoverListener
          open={isTooltipOpen}
          classes={{ tooltip: classes.tooltip }}
          tooltipChildren={
            <Box
              sx={{
                maxWidth: "fit-content",
              }}
              component={"span"}
              className={
                maskWithWarnIcon
                  ? `cursor-pointer d-flex ${classes.warnIcon}`
                  : ""
              }
              onClick={() => setIsTooltipOpen(true)}
            >
              {maskWithWarnIcon ? (
                <>
                  <SVGWrapper
                    height={10}
                    width={10}
                    viewBox="0 0 10 10"
                    children={SVGIcons.warning}
                  />
                  <Typography className={classes.warningText}>
                    Warning
                  </Typography>
                </>
              ) : (
                <Typography className={`cursor-pointer ${classes.maskedText}`}>
                  {maskFunction?.(text) ?? text}
                </Typography>
              )}
            </Box>
          }
        />
        <CopyToClipboard onCopy={handleOnCopyAlert} text={text as string}>
          <IconButton
            className={`${classes.copyIcon} padding-0 margin-left-10`}
            aria-label="Copy export URL"
          >
            <SVGWrapper
              height={16}
              width={16}
              viewBox="0 0 16 16"
              children={SVGIcons.copyIcon}
              fill={theme.newPalette.text.primary.light}
            />
          </IconButton>
        </CopyToClipboard>
      </Box>
    </ClickAwayListener>
  );
};

export default MaskedContent;
