import { Button as MUIButton } from "@material-ui/core";

// Types
import { ButtonProps } from "./Button";

// Styles
import useStyles from "./Button.styles";

/**
 * Button Component
 * @example Correct usage for contained button
 * ```ts
 * <Button id="id">Contained Button</Button>
 * ```
 * @example Correct usage for outlined button
 * ```ts
 * <Button id="id" variant="outlined">Outlined Button</Button>
 * ```
 * @example Correct usage for text button
 * ```ts
 * <Button id="id" variant="text">Text Button</Button>
 * ```
 * @example Correct usage for small size button
 * ```ts
 * <Button id="id" size="small">Text Button</Button>
 * ```
 */
export const Button = ({
  id,
  type = "submit",
  size = "medium",
  children,
  onClick,
  disabled = false,
  extraClass = "",
  isTabButton = false,
  isTabActive = false,
  startIcon,
  endIcon,
  variant = "contained",
  color = "primary",
  disableElevation = false,
  title,
  disableRipple = false,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  ariaLabel,
}: ButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MUIButton
      id={id}
      title={title}
      variant={variant}
      size={size}
      type={type}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      color={color}
      className={`${extraClass} ${classes.disabledStyled} ${
        isTabButton && classes.tabButton
      } ${isTabActive && classes.tabActive}`}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      aria-label={ariaLabel}
    >
      {children}
    </MUIButton>
  );
};
