import Constants from "@Constants";

// Initial state for creating a tag
const initialState: CreateTag = {
  loading: false,
  isCreated: false,
  error: "",
};

/**
 * Handles actions for creating a tag
 * @param state CreateTag
 * @param action GenericAction
 * @returns CreateTag
 */
const createTagReducer = (
  state: CreateTag = initialState,
  action: GenericAction
): CreateTag => {
  switch (action.type) {
    case Constants.ActionTypes.CREATE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        isCreated: false,
      };
    case Constants.ActionTypes.CREATE_TAG_SUCCESS:
      return {
        loading: false,
        isCreated: true,
        error: "",
      };
    case Constants.ActionTypes.CREATE_TAG_FAILED:
      return {
        loading: false,
        isCreated: false,
        error: action?.payload,
      };
  }

  return state;
};

export default createTagReducer;
