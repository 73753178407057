import { Link as AppLink, useLocation } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";

// Styles
import useStyles from "./Link.styles";

// Types
import { LinkProps } from "./Link";

/**
 * Link component - to render the links in the app
 *
 * @example Correct usage without the "caret" icon
 * ```ts
 * <Link id={id} link={url}>Link text</Link>
 * ```
 *
 * @example Correct usage with the "caret" icon
 * ```ts
 * <Link id={id} link={url} showCaret>Link text</Link>
 * ```
 */
export const Link = ({
  id,
  children,
  link,
  extraClass = "",
  showCaret = false,
  from,
}: LinkProps): JSX.Element => {
  const classes = useStyles();
  const history = useCustomHistory();
  const location = useLocation();

  return (
    <AppLink
      id={id}
      to={{
        pathname: link,
        state: {
          from: [
            ...(history.location.state?.from ?? []),
            from ?? location.pathname,
          ],
        },
      }}
      className={`${classes.root} ${extraClass} flex justify-start`}
    >
      {children}
      {showCaret && <ChevronRightIcon className={classes.caretIcon} />}
    </AppLink>
  );
};
