import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  // Menu Item Styles
  menuItem: {
    height: theme.pxToRem(48),
    padding: `0 ${theme.pxToRem(40)}`,

    "&:hover": {
      background: theme.newPalette.tagItem.focus,
    },

    "&:focus": {
      background: theme.newPalette.tagItem.focus,
    },
  },

  /**
   * Menu Item Content Styles
   * Menu Content Styles includes tag and number of times tagged
   */
  menuContent: {
    flex: 80,

    "& .tag-tagged": {
      color: theme.newPalette.tagItem.tag,
      opacity: 0.5,
    },
  },

  inputWrapper: {
    width: "unset",
  },

  input: {
    color: theme.newPalette.tagItem.tag,
  },

  /**
   * Menu Item Action Styles
   * Action styles includes Edit and Delete Button icon
   */
  menuAction: {
    flex: 20,
    gap: theme.pxToRem(20),

    "& svg": {
      fill: theme.newPalette.tagItem.tag,
    },

    "&.disabled svg": {
      fill: theme.newPalette.tagItem.disabled,
    },

    "& .icon-btn": {
      padding: 0,
      height: theme.pxToRem(16),
      width: theme.pxToRem(16),
      minWidth: 0,
    },

    "& .icon-btn.MuiButton-root.Mui-disabled": {
      backgroundColor: "transparent !important",
      border: "none !important",
    },

    "& .delete-btn:hover svg": {
      fill: theme.newPalette.tagItem.dangerColor,
    },
  },
  button: {
    border: "1px solid transparent",

    "&.MuiButton-root.Mui-disabled": {
      opacity: 0.5,
      backgroundColor: `${theme.newPalette.manageTagModal.disabledButton} !important`,
      borderColor: `${theme.newPalette.manageTagModal.disabledButtonBorder} !important`,
    },
  },
  warningAdornment: {
    "& svg": {
      height: theme.pxToRem(16),
      width: theme.pxToRem(16),
      fill: theme.newPalette.error[500],
    },
  },
  tagCountWrapper: {
    width: theme.pxToRem(105),
    opacity: 0.6,
  },
}));

export default useStyles;
