import SettingsContainer from "@Containers/settings";
import ErrorBoundary from "@Containers/errorBoundary";

const Settings = (): JSX.Element => (
  <ErrorBoundary>
    <SettingsContainer />
  </ErrorBoundary>
);

export default Settings;
