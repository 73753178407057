import Constants from "@Constants";

const initialState: UserSessionPreferenceState = {
  askAgain: true,
  staySignedIn: false,
};

const userSessionPreferenceReducer = (
  state: UserSessionPreferenceState = initialState,
  action: GenericAction
): UserSessionPreferenceState => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_SESSION_PREFERENCE:
      return {
        ...action.payload,
      };
  }

  return state;
};

export default userSessionPreferenceReducer;
