import bcrypt from "../bcrypt";

// Constants
import { MockAPIAuthHash } from "@Constants/common";

// Handle Auth for mock API
const handleAuth = (params: Array<string>): boolean => {
  const payload: UserLoginPayload = {
    username: params[0],
    password: params[1],
  };

  return (
    bcrypt.compareHash(payload.username, MockAPIAuthHash.mockServerUsername) &&
    bcrypt.compareHash(payload.password, MockAPIAuthHash.mockServerPassword)
  );
};

export default {
  handleAuth,
};
