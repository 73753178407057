import Constants from "@Constants";

const initialState: DeleteTenantUserState = {
  loading: false,
  isDeleted: false,
  error: "",
};

/**
 * Handles delete tenant user actions
 *
 * @param state DeleteTenantUserState
 * @param action GenericAction
 * @returns DeleteTenantUserState
 */
const deleteTenantReducer = (
  state: DeleteTenantUserState = initialState,
  action: GenericAction
): DeleteTenantUserState => {
  switch (action.type) {
    case Constants.ActionTypes.DELETE_CHILD_TENANTS_REQUEST:
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };
    case Constants.ActionTypes.DELETE_CHILD_TENANTS_SUCCESS:
      return {
        loading: false,
        isDeleted: true,
        error: "",
      };
    case Constants.ActionTypes.DELETE_CHILD_TENANTS_FAILED:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        error: action?.payload,
      };
  }

  return state;
};

export default deleteTenantReducer;
