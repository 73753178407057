import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { Typography } from "@material-ui/core";

// Actions
import { showAlert } from "@Store/actions";

// Components
import Tooltip from "@Components/Tooltip";

// Constants
import Constants from "@Constants";

// Types
import { TooltipWithCopyProps } from "./TooltipWithCopy";

// Utils
import Utils from "@Utils";

// Styles
import useStyles from "./TooltipWithCopy.styles";

/**
 * TooltipWithCopy Component
 * A tooltip with copy button.
 * @example Correct usage
 * ```tsx
 * <TooltipWithCopy
 *  tooltipText={tooltipText}
 *  textToBeCopied={textToBeCopied}
 *  tooltipChildren={tooltipChildren}
 * />
 * ```
 * @example Correct usage with truncation.
 * ```tsx
 * <TooltipWithCopy
 *  tooltipText={tooltipText}
 *  textToBeCopied={textToBeCopied}
 *  tooltipChildren={tooltipChildren}
 * truncateLabelValuePair={truncateConfig}
 * />
 * ```
 */
export const TooltipWithCopy = ({
  id,
  tooltipText,
  textToBeCopied,
  tooltipCopyChildren,
  istooltipChildrenSpanWrap = true,
  placement = "bottom",
  truncateLabelValuePair,
  ...props
}: Omit<TooltipWithCopyProps, "title">): JSX.Element => {
  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Show success toast after copying data
  const handleOnCopyAlert = () => {
    dispatch(
      showAlert({
        message: Constants.SuccessMessages.copied,
        severity: Constants.AlertSeverity.success,
      })
    );
  };

  const classes = useStyles();

  return (
    <Tooltip
      id={id}
      title={
        <CopyToClipboard
          onCopy={handleOnCopyAlert}
          text={(textToBeCopied || tooltipText || "") as string}
        >
          <div
            className="flex padding-5 copy-icon cursor-pointer"
            id={`${id}-tooltip-copy-popover`}
          >
            <Typography variant="h6" className={classes.tooltipText}>
              {tooltipText || ""}
            </Typography>
            <FileCopyRoundedIcon className={classes.tooltip} />
          </div>
        </CopyToClipboard>
      }
      showArrow
      placement={placement}
      tooltipChildren={
        truncateLabelValuePair && typeof tooltipCopyChildren === "string" ? (
          <div className="display-inline-block">
            {Utils.getTruncatedString({
              ...truncateLabelValuePair,
              value: tooltipCopyChildren,
            })}
          </div>
        ) : istooltipChildrenSpanWrap ? (
          <span>{tooltipCopyChildren}</span>
        ) : (
          <>{tooltipCopyChildren}</>
        )
      }
      {...props}
    />
  );
};
