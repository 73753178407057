import Constants from "@Constants";

// Initial state for updating IDP config
const initialState: CreateUpdateIDPConfig = {
  loading: false,
  config: {},
  error: "",
};

/**
 * Handles actions for updating IDP config
 * @param state CreateUpdateIDPConfig
 * @param action GenericAction
 * @returns CreateUpdateIDPConfig
 */
const updateIDPConfig = (
  state: CreateUpdateIDPConfig = initialState,
  action: GenericAction
): CreateUpdateIDPConfig => {
  switch (action.type) {
    case Constants.ActionTypes.UPDATE_IDP_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.UPDATE_IDP_CONFIG_SUCCESS:
      return {
        loading: false,
        config: action?.payload,
        error: "",
      };
    case Constants.ActionTypes.UPDATE_IDP_CONFIG_FAILED:
      return {
        loading: false,
        error: action?.payload,
        config: {},
      };
  }
  return state;
};

export default updateIDPConfig;
