import Constants from "@Constants";

const initialState: SelectedTenantState = {
  selectedTenantId: "",
  selectedTenantName: "",
  manageTenant: false,
  isNonEnterprise: false,
};

const selectedTenantReducer = (
  state: SelectedTenantState = initialState,
  action: GenericAction
): SelectedTenantState => {
  switch (action.type) {
    case Constants.ActionTypes.UPDATE_SELECTED_TENANT:
      return action.payload || state;
  }

  return state;
};

export default selectedTenantReducer;
