import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth as useOIDCAuth, AuthContextProps } from "oidc-react";

// Actions
import {
  userLogout,
  refreshToken,
  harbingerFetchUserDetails,
} from "@Store/actions";

// Constants
import Constants from "@Constants";

// env variables
import envVariables from "@Config";

export type AuthHook = {
  isLoggedIn: () => boolean;
  handleLogout: () => void;
  fetchUserData: () => void;
  userInfo: UserDetailsStatePersisted;
  redirectToLoginOnTokenExpire: () => void;
  refreshTokens: () => Promise<string>;
  oidcAuth: AuthContextProps;
};

/**
 * useAuth: hook to check if user is logged in
 * @returns
 */
function useAuth(): AuthHook {
  const userDetails: UserDetailsStatePersisted = useSelector(
    (state: GlobalState) => state.userDetails
  );
  const dispatch = useDispatch();
  const { REACT_APP_USE_NATIVE_LOGIN } = process.env;
  const oidcAuth = useOIDCAuth();

  const isLoggedIn = (): boolean =>
    Boolean(
      REACT_APP_USE_NATIVE_LOGIN ? userDetails?.id : oidcAuth.userData?.id_token
    );

  const redirectToLoginOnTokenExpire = () => {
    dispatch({ type: Constants.ActionTypes.LOGOUT_SUCCESS });
  };

  // Basic Logout
  const basicLogOut = async () => {
    if (envVariables.USE_MOCK) {
      redirectToLoginOnTokenExpire();
    } else {
      dispatch(userLogout());
    }
  };

  // Fetch logged in user details
  const fetchUserData = async () => {
    await dispatch(harbingerFetchUserDetails());
  };

  // Refresh token
  const refreshTokens = async (): Promise<string> => {
    const { errorMessage } = await dispatch(refreshToken());

    return errorMessage ?? "";
  };

  // Logout user
  const handleLogout = () => {
    if (REACT_APP_USE_NATIVE_LOGIN) {
      basicLogOut();
      return;
    }

    const profilePath = Constants.AppRoutes.ProtectedRoutes.Dashboard;
    const redirectUrl = `${window.location.origin}${profilePath}`;
    const redirectArgs = { post_logout_redirect_uri: redirectUrl };
    oidcAuth.signOutRedirect(redirectArgs);
    oidcAuth.userManager.clearStaleState();
    dispatch({ type: Constants.ActionTypes.LOGOUT_SUCCESS });
  };

  const getUserDetails = () => ({
    // TODO: update on complete integration of Harbinger setup
    // Use as first name in harbinger setup
    username: userDetails?.username,
    // Use as last name in harbinger setup
    fullname: userDetails?.fullname,
    email: userDetails?.email,
    id: userDetails?.id,
    isEulaAccepted: userDetails.isEulaAccepted,
  });

  // User Info
  const [userInfo, setUserInfo] = useState<UserDetailsStatePersisted>(
    getUserDetails()
  );

  useEffect(() => {
    setUserInfo(getUserDetails());
  }, [oidcAuth.userData?.profile, userDetails]);

  return {
    isLoggedIn,
    handleLogout,
    fetchUserData,
    userInfo,
    redirectToLoginOnTokenExpire,
    refreshTokens,
    oidcAuth,
  };
}

export default useAuth;
