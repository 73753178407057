import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    padding: theme.pxToRem(30),
    borderRadius: theme.pxToRem(20),
    boxShadow: `${theme.pxToRem(20)} ${theme.pxToRem(20)} ${theme.pxToRem(
      80
    )} ${theme.newPalette.popover.shadowColor}`,
    minWidth: theme.pxToRem(400),
    background: theme.newPalette.popover.background,
    color: theme.newPalette.popover.color,

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: theme.pxToRem(20),
      minWidth: theme.pxToRem(325),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: theme.pxToRem(0),
      minWidth: "100%",
      background: "transparent",
      boxShadow: "initial",
    },
  },
  userAvatar: {
    width: theme.pxToRem(100),
    height: theme.pxToRem(100),
    fontSize: theme.pxToRem(40),
    margin: `0 0 ${theme.pxToRem(15)}`,

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      width: theme.pxToRem(75),
      height: theme.pxToRem(75),
      fontSize: theme.pxToRem(30),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: theme.pxToRem(56),
      height: theme.pxToRem(56),
      fontSize: theme.pxToRem(20),
    },
  },
  link: {
    width: "100%",
    textDecoration: "none",
    padding: `${theme.pxToRem(10)} 0`,

    "&:hover, &:active, &:focus": {
      "& .link-inner-text": {
        fontWeight: 800,
      },
    },
  },
  linkText: {
    ...theme.utils.buttonMedium(),
    color: theme.newPalette.popover.color,
  },
  angle: {
    height: theme.pxToRem(13),
    transform: "rotate(-90deg)",
    "& path": {
      fill: theme.newPalette.popover.color,
    },
  },
  logoutButton: {
    color: theme.newColors.red,
    padding: 0,
    "&: hover": {
      background: "none",
    },
  },
  padding: {
    padding: `${theme.pxToRem(10)} 0`,
  },
  settings: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: `0 ${theme.pxToRem(10)}`,
    },
  },
}));

export default useStyles;
