import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  configRowIcons: {
    marginRight: theme.pxToRem(16),
    padding: 0,
    minWidth: "unset",
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

export default useStyles;
