import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { List, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

// Components
import TagItem from "../TagItem";

// Constants
import {
  LocalStorageKeyForRecentlyUsedLabel,
  MaximumLimitForSelectedTags,
} from "@Constants/common";

// Styles
import useStyles from "@Containers/TagPopover/TagPopoverBody/styles";

type RecentlyUsedTagsListProps = {
  checkedSuggestions: string[];
  handleCheckInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const RecentlyUsedTagsList = ({
  checkedSuggestions,
  handleCheckInputChange,
}: RecentlyUsedTagsListProps) => {
  const classes = useStyles();
  const [recentlyUsedTags, setRecentlyUsedTags] = useState<string[]>([]);
  const { infoDetails } = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );
  // If "deviceId" is a truthy value, then it means we're viewing this popover on device-details page
  const { id: deviceId } = useParams<{ id: string }>();

  const appliedTags = (infoDetails.tags as string[]) ?? [];

  // Checks local storage for suggestions
  useEffect(() => {
    const jsonArray = JSON.parse(
      (localStorage.getItem(LocalStorageKeyForRecentlyUsedLabel) as string) ??
        "null"
    );
    if (Array.isArray(jsonArray) && jsonArray.length) {
      setRecentlyUsedTags(jsonArray);
    }
  }, []);

  const availableRecentlyUsedTags: string[] = useMemo(
    () =>
      recentlyUsedTags.filter((suggestion) =>
        deviceId
          ? !checkedSuggestions.includes(suggestion) &&
            !appliedTags.includes(suggestion)
          : !checkedSuggestions.includes(suggestion)
      ),
    [recentlyUsedTags]
  );

  return (
    <>
      {availableRecentlyUsedTags.length > 0 && (
        <>
          <Typography className={classes.listTitle} variant="body2">
            Recently Used
          </Typography>
          <List className={`${classes.subListContainer} custom-scroll-bar`}>
            {availableRecentlyUsedTags.map((suggestion) => (
              <TagItem
                key={suggestion}
                suggestion={suggestion}
                isChecked={
                  !!checkedSuggestions.find((checked) => checked === suggestion)
                }
                handleCheckInputChange={handleCheckInputChange}
                isDisabled={
                  checkedSuggestions.length >= MaximumLimitForSelectedTags &&
                  !checkedSuggestions.find((checked) => checked === suggestion)
                }
              />
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default RecentlyUsedTagsList;
