import Constants from "@Constants";
import Utils from "@Utils";

const initialState: DevicesState = {
  loading: false,
  devices: [],
  error: "",
};

/**
 * Handles actions of Devices Fetching
 * @param state DevicesState
 * @param action GenericAction
 * @returns DevicesState
 */
const devicesReducer = (
  state: DevicesState = initialState,
  action: GenericAction
): DevicesState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_DEVICES_SUCCESS:
      return {
        loading: false,
        devices: action?.payload?.data || [],
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICES_FAILED:
      return {
        loading: false,
        devices: [],
        error: action?.payload,
      };
  }
  return state;
};

export default devicesReducer;
