import Constants from "@Constants";
import Utils from "@Utils";

const initialState: TenantChildList = {
  loading: false,
  tenants: [],
  error: "",
};

const getUpdatedChildTenantList = (
  tenants: Record<string, string>[],
  newTenant: Record<string, string>
) => {
  let TenantUpdated = false;
  const updatedTenants = tenants.map((tenant) => {
    if (tenant.id === newTenant.id) {
      TenantUpdated = true;
      return newTenant;
    }
    return tenant;
  });
  return TenantUpdated ? updatedTenants : [...tenants, newTenant];
};

/**
 * Handles actions of fetching child tenants
 * @param state TenantChildList
 * @param action GenericAction
 * @returns TenantChildList
 */
const childTenantsReducer = (
  state: TenantChildList = initialState,
  action: GenericAction
): TenantChildList => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_CHILD_TENANTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_CHILD_TENANTS_SUCCESS:
      return {
        loading: false,
        tenants: action.payload?.data,
        pagination: Utils.getPaginationData(action.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_CHILD_TENANTS_FAILED:
      return {
        loading: false,
        tenants: [],
        error: action.payload,
      };
    case Constants.ActionTypes.ADD_OR_UPDATE_CHILD_TENANT_LIST:
      return {
        ...state,
        tenants: getUpdatedChildTenantList(state.tenants, action.payload),
      };
  }
  return state;
};

export default childTenantsReducer;
