import { Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

// Components
import InputField from "@Components/InputField";
import Button from "@Components/Button";

// Constants
import Constants from "@Constants";
import { ButtonIDs, InputFieldIds, ProfileIds } from "@Constants/Id";

// Helpers
import { harbingerUpdateUserDetailsFormValidation } from "@Helpers/validation";

// hooks
import useAuth from "@Hooks/useAuth";

// Actions
import { harbingerUpdateUserDetails } from "@Store/actions";

// Styles
import useStyles from "./styles";

const Profile = () => {
  const classes = useStyles();

  // Initialize hooks
  const { userInfo, fetchUserData, oidcAuth } = useAuth();
  const dispatch = useDispatch();

  // Get user details state from redux store
  const userDetails: UserDetailsStateNonPersisted = useSelector(
    (state: GlobalState) => state.userDetailsNonPersisted
  );

  const { firstName, lastName } = Constants.FormFields.UpdateProfile;

  const formik = useFormik({
    initialValues: {
      [firstName.name]: userInfo.username,
      [lastName.name]: userInfo.fullname,
    },
    enableReinitialize: true,
    validationSchema: harbingerUpdateUserDetailsFormValidation,
    onSubmit: (values) =>
      dispatch(
        harbingerUpdateUserDetails(
          {
            firstName: values[firstName.name] || "",
            lastName: values[lastName.name] || "",
          },
          {
            sideEffect: () => {
              // Fetch User data when profile is updated
              fetchUserData();
              // Refresh access token
              oidcAuth.userManager.signinSilent();
            },
          }
        )
      ),
  });

  // TODO: Update on complete integration to Harbinger
  const userFieldChanged = () =>
    formik.values[firstName.name] !== userInfo.username ||
    formik.values[lastName.name] !== userInfo.fullname;

  return (
    <div id={ProfileIds.container} className={`d-flex ${classes.formWrapper}`}>
      <div>
        {/* TODO: uncomment when implementing Profile picture options once API supports it*/}
        {/* {userData?.profile.picture && (
          <div className={`margin-bottom-30 ${classes.profileImage}`}>
            <img src={userData.profile.picture} />
            <div
              className={`flex-column justify-center ${classes.editProfileImage}`}
            >
              <SVGWrapper width={49} height={49} viewBox="0 0 49 49">
                {Constants.SVGIcons.camera}
              </SVGWrapper>
              <Typography>Edit Image</Typography>
            </div>
          </div>
        )} */}
        <Typography
          id={ProfileIds.pageTitle}
          variant="subtitle1"
          className={`margin-bottom-25`}
        >
          Personal Information
        </Typography>
        <form id={ProfileIds.profileForm} onSubmit={formik.handleSubmit}>
          <div className="margin-bottom-25">
            <InputField
              id={InputFieldIds.firstName}
              type="text"
              label={firstName.label}
              name={firstName.name}
              extraClass={classes.inputField}
              onChange={formik.handleChange}
              value={formik.values[firstName.name]}
              errorMessage={
                formik.touched[firstName.name] && formik.errors[firstName.name]
              }
            />
          </div>
          <div className="margin-bottom-25">
            <InputField
              id={InputFieldIds.lastName}
              type="text"
              label={lastName.label}
              name={lastName.name}
              extraClass={classes.inputField}
              onChange={formik.handleChange}
              value={formik.values[lastName.name]}
              errorMessage={
                formik.touched[lastName.name] && formik.errors[lastName.name]
              }
            />
          </div>
          <Button
            id={ButtonIDs.updateProfile}
            type="submit"
            disabled={userDetails.loading || !userFieldChanged()}
          >
            Save Changes
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
