import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

// Components
import Modal from "@Components/Modal";
import InputField from "@Components/InputField";
import Button from "@Components/Button";
import { AutoComplete } from "@Components/AutoComplete";

// Validation
import { addUserFormValidationHarbinger } from "@Helpers/validation";

// Actions
import { addTenantUser } from "@Store/actions";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Constants
import Constants from "@Constants";
import { AccessLevels, UserRoleLabel } from "@Constants/common";
import {
  AutoCompleteIDs,
  ButtonIDs,
  InputFieldIds,
  ModalIds,
  UsersIds,
} from "@Constants/Id";

// Styles
import useStyles from "./styles";

type AddAndEditUserPropsType = {
  editUserInfo?: EditUserInfo | null;
  isAddModalOpen?: boolean;
  isEditModalOpen?: boolean;
  handleModalClose: () => void;
  handleFetchUsers?: (() => Promise<BindClient>) | null;
};

const AddAndEditUser = ({
  isEditModalOpen,
  isAddModalOpen,
  editUserInfo,
  handleModalClose,
  handleFetchUsers,
}: AddAndEditUserPropsType): JSX.Element => {
  // Initialize styles
  const classes = useStyles();

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  const { selectedTenant } = useTenantAccess();

  // Get add user state from redux store
  const addUserState: AddUserState = useSelector(
    (state: GlobalState) => state.addUser
  );

  // Set data of user to be edited in case of edit
  const selectedUser: EditUserInfo | null | undefined = isEditModalOpen
    ? editUserInfo
    : null;

  const { email } = Constants.FormFields.AddAndEditUser;

  const [userRole, setUserRole] = useState<string | null>(null);

  // handle add user form
  const formik = useFormik({
    initialValues: {
      [email.name]: selectedUser?.email ?? "",
    },
    validationSchema: addUserFormValidationHarbinger,
    onSubmit: (values) =>
      dispatch(
        addTenantUser(
          {
            email: values[email.name],
            role: userRole,
          },
          selectedTenant.selectedTenantId,
          {
            sideEffect: () => {
              handleFetchUsers?.();
              handleModalClose();
            },
          }
        )
      ),
  });

  /**
   * Reset form when drawer is closed
   */
  useEffect(() => {
    if (!(isEditModalOpen && isAddModalOpen)) {
      formik.resetForm();
    }
  }, [isEditModalOpen, isAddModalOpen]);

  return (
    <Modal
      id={ModalIds.addAndEditUser}
      title={isEditModalOpen ? "Edit User Info" : "Add New User"}
      modalExtraClass={classes.modal}
      modalTitleExtraClass={"w-100 text-align-left"}
      modalContentExtraClass={classes.modalContent}
      isOpen={Boolean(isEditModalOpen || isAddModalOpen)}
      close={handleModalClose}
    >
      <form id={UsersIds.addOrEditUserForm} onSubmit={formik.handleSubmit}>
        <div className="margin-bottom-25">
          <InputField
            id={InputFieldIds.userEmail}
            type="text"
            label={email.label}
            name={email.name}
            extraClass={classes.inputField}
            onChange={formik.handleChange}
            value={formik.values[email.name]}
            errorMessage={
              formik.touched[email.name] && formik.errors[email.name]
            }
          />
        </div>
        <div className="margin-bottom-25">
          <AutoComplete
            id={AutoCompleteIDs.selectUserRole}
            value={userRole ?? ""}
            options={AccessLevels}
            onChange={(value: string | null) => setUserRole(value)}
            label={UserRoleLabel}
            openOnFocus
            fillInputOnSelect
            clearInvalidInputOnBlur
          />
        </div>
        <div className="text-align-right">
          <Button
            id={ButtonIDs.addOrEditUser}
            type="submit"
            disabled={addUserState.loading}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddAndEditUser;
