import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { Breakpoints, ToolbarHeight } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  profileImage: {
    width: theme.pxToRem(46),
    height: theme.pxToRem(46),

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      width: theme.pxToRem(32),
      height: theme.pxToRem(32),
      marginRight: theme.pxToRem(8),
    },
  },
  themeToggler: {
    padding: theme.pxToRem(0),
    minWidth: "unset",
    "& :hover": {
      background: "transparent",
    },
  },
  username: {
    color: theme.newPalette.header.color,
    maxWidth: theme.pxToRem(450),
  },
  appBar: {
    minHeight: theme.pxToRem(ToolbarHeight.mobile),
    backgroundColor: theme.newPalette.appBg,
    color: theme.newPalette.header.color,
    position: "sticky",
    padding: `${theme.pxToRem(16)} ${theme.pxToRem(24)} 0`,
    boxShadow: "none",

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: `${theme.pxToRem(6)} ${theme.pxToRem(24)} 0`,
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: `0 ${theme.pxToRem(18)}`,
      position: "relative",
    },
  },
  toolbarDesktop: {
    minHeight: theme.pxToRem(ToolbarHeight.aboveMobile),
    padding: 0,
  },
  secondaryTitle: {
    paddingLeft: theme.pxToRem(20),
    borderLeft: `${theme.pxToRem(1)} solid ${theme.newPalette.header.color}`,

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      paddingLeft: theme.pxToRem(10),
      fontSize: theme.pxToRem(14),
    },
  },
  backButton: {
    fontSize: theme.pxToRem(14),
    marginRight: theme.pxToRem(20),
    color: theme.newPalette.header.color,
    textDecoration: "none",
    padding: 0,
    "& svg": {
      marginTop: theme.pxToRem(2),
      height: theme.pxToRem(22),
      width: theme.pxToRem(16),
      transform: "rotate(90deg)",
      "& path": {
        fill: theme.newPalette.header.color,
      },
    },
    "&:hover": {
      background: "none",
      color: theme.newPalette.header.color,
    },
  },
  tabWrapper: {
    margin: `${theme.pxToRem(24)}  0  ${theme.pxToRem(30)}`,
    background: theme.newPalette.header.tabsBg,
    padding: theme.pxToRem(8),
    borderRadius: theme.pxToRem(12),

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      margin: `0 0 ${theme.pxToRem(10)}`,
      padding: theme.pxToRem(6),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      margin: `${theme.pxToRem(16)} 0 ${theme.pxToRem(10)}`,
    },
  },
  popoverContainer: {
    "& .MuiPaper-root": {
      background: "transparent",
      borderRadius: theme.pxToRem(20),
      padding: 0,
    },
  },
  shiftRight: {
    marginRight: `-${theme.pxToRem(16)}`,
  },
  shiftLeftPopoverChildren: {
    paddingRight: theme.pxToRem(17),
  },
  headerIcon: {
    margin: `${theme.pxToRem(4)} ${theme.pxToRem(8)} 0 0`,

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      "& svg": {
        width: theme.pxToRem(24),
        height: theme.pxToRem(24),
      },
    },
  },
  secondaryHeaderTitleText: {
    maxWidth: `calc(${theme.pxToRem(275)})`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    [theme.breakpoints.up(Breakpoints.largeDesktopMin)]: {
      maxWidth: `calc(${theme.pxToRem(450)})!important`,
      marginBottom: theme.pxToRem(4),
    },

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      // 100vw - sidebar width(204) - header padding(18*2) - secondary-tab's back button-width(80) - profile-content(205) - space-between-header-title-and-profile-content(40) - secondary button(62)
      maxWidth: `calc(100vw - ${theme.pxToRem(204 + 36 + 80 + 205 + 40 + 62)})`,
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      maxWidth: `calc(100% - ${theme.pxToRem(32)})`,
    },
  },

  tenantFilter: {
    padding: `0 ${theme.pxToRem(24)} 0 0`,
  },
  filterTextField: {
    width: theme.pxToRem(130),
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      paddingRight: theme.pxToRem(30),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
  },
}));

export default useStyles;
