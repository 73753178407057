import { Typography } from "@material-ui/core";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import Link from "@Components/Link";

// Constants
import Constants from "@Constants";
import { LogoAltText } from "@Constants/common";
import { LinkIds, SVGIconIds } from "@Constants/Id";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";

// Styles
import useStyles from "./style";

const NotFound = (): JSX.Element => {
  const classes = useStyles();
  const { activeTheme, getBrandLogo } = useAppTheme();

  return (
    <div className={`flex position-relative ${classes.root}`}>
      <div className={classes.barrierIllustration}>
        <SVGWrapper
          width={427}
          height={278}
          viewBox="0 0 431 278"
          id={SVGIconIds.barrierIllustrationIcon}
        >
          {activeTheme === Constants.Themes.light
            ? Constants.SVGIcons.barrierIllustrationLight
            : Constants.SVGIcons.barrierIllustrationDark}
        </SVGWrapper>
      </div>
      <div
        className={`${classes.bgIllustrations} ${classes.bottomLeftIllustration}`}
      >
        <SVGWrapper
          width={361}
          height={361}
          viewBox="0 0 1000 1000"
          id={SVGIconIds.circularBgIllustrationLeft}
        >
          {Constants.SVGIcons.circularBgIllustration}
        </SVGWrapper>
      </div>
      <div
        className={`${classes.bgIllustrations} ${classes.topRightIllustration}`}
      >
        <SVGWrapper
          width={932}
          height={932}
          viewBox="0 0 1000 1000"
          id={SVGIconIds.circularBgIllustrationRight}
        >
          {Constants.SVGIcons.circularBgIllustration}
        </SVGWrapper>
      </div>
      <div className={`min-height-100vh ${classes.leftDiv}`}>
        <Link
          link={Constants.AppRoutes.ProtectedRoutes.Dashboard}
          id={LinkIds.brandLogoLink}
        >
          <img
            src={getBrandLogo()}
            alt={LogoAltText}
            className={`margin-bottom-30 cursor-pointer ${classes.logo}`}
          />
        </Link>
      </div>
      <div
        className={`flex-column justify-center min-height-100vh ${classes.rightDiv}`}
      >
        <div className={classes.textContainer}>
          <Link
            link={Constants.AppRoutes.ProtectedRoutes.Dashboard}
            id={LinkIds.dashboardLink}
          >
            <img
              src={getBrandLogo()}
              alt={LogoAltText}
              className={`margin-bottom-30 cursor-pointer ${classes.logoSmallScreen}`}
            />
          </Link>
          <Typography className={classes.title} variant="h1">
            404
          </Typography>
          <Typography className={classes.subTitle} variant="h2">
            Whoops&#x00021;
          </Typography>
          <Typography className={classes.description} variant="h4">
            The page you were looking for doesn&#x00027;t exist.
          </Typography>
          <Link
            id={LinkIds.backToHome}
            link={Constants.AppRoutes.ProtectedRoutes.Dashboard}
            extraClass={`cursor-pointer ${classes.toHomeLink}`}
          >
            <Typography variant="subtitle1">
              Take me back to the home page
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
