import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

// Components
import SelectWithTextField from "@Components/SelectWithTextField";
import InputField from "@Components/InputField";
import Button from "@Components/Button";
import Checkbox from "@Components/Checkbox";

// Constants
import FormFields from "@Constants/form";
import {
  CreateEditSamlFormConsts,
  IdpProtocols,
  NameIdPolicyFormatValues,
  SAMLPrincipleType,
} from "@Constants/common";
import {
  ButtonIDs,
  CheckboxIds,
  InputFieldIds,
  SelectWithTextFieldIds,
  SettingsIds,
} from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Actions
import { createIdpConfig, updateIdpConfig } from "@Store/actions";

// Helpers
import { SAMLExternalIDPFormValidation } from "@Helpers/validation";

// Styles
import useStyles from "../AddOrEditOpenIDConnect/styles";

/**
 * Prop types for AddOrEditSAML component
 */
type AddOrEditSAMLProps = {
  /**
   * Handles showing the idpConfig details to the user
   * @param isIdpConfigVisible - boolean indicating if the IdpConfig panel is visible
   */
  setIsIdpConfigVisible: (isIdpConfigVisible: boolean) => void;

  /**
   * Handles updating the state when the external IDP configuration is created successfully
   * @param isProviderAdded - boolean indicating if the external IDP configuration is created successfully
   */
  setIsProviderAdded: (isProviderAdded: boolean) => void;

  /**
   * If formData is passed, initial values will be set in the form fields
   */
  formData?: Record<string, string> | null;
};

const AddOrEditSAML = ({
  setIsIdpConfigVisible,
  setIsProviderAdded,
  formData,
}: AddOrEditSAMLProps) => {
  const SamlIdpFormConfig = FormFields.SAMLExternalIDP;
  const classes = useStyles();
  const {
    signOnURL,
    logoutURL,
    nameIdPolicyFormat,
    displayName,
    entityId,
    certificate,
  } = SamlIdpFormConfig;
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();
  const [shouldIncludeCertificate, setShouldIncludeCertificate] =
    useState(false);
  const [errorInCertificateInput, setErrorInCertificateInput] = useState("");

  const { loading: isCreatingIdp } = useSelector(
    (state: GlobalState) => state.createIDPConfig
  );

  const { loading: isUpdatingIdp } = useSelector(
    (state: GlobalState) => state.updateIDPConfig
  );

  useEffect(() => {
    if (formData?.validateSignature) {
      setShouldIncludeCertificate(true);
    }
  }, [formData?.validateSignature]);

  const formik = useFormik({
    initialValues: {
      [displayName.name]: formData?.displayName ?? "",
      [entityId.name]: formData?.entityId ?? "",
      [signOnURL.name]: formData?.singleSignOnServiceUrl ?? "",
      [logoutURL.name]: formData?.singleLogOutServiceUrl ?? "",
      [nameIdPolicyFormat.name]: formData?.namePolicyIdFormat ?? "",
      [certificate.name]: formData?.signingCertificate ?? "",
    },
    enableReinitialize: true,
    validationSchema: SAMLExternalIDPFormValidation,
    onSubmit: async (values) => {
      const payload: CreateUpdateIdpParams = {
        idpConfigParams: {
          type: IdpProtocols.saml,
          displayName: values.displayName,
          isEnabled: true,
          entityId: values.entityId,
          namePolicyIdFormat: values.nameIdPolicyFormat,
          principalType: SAMLPrincipleType,
          singleSignOnServiceUrl: values.signOnURL,
          singleLogOutServiceUrl: values.logoutURL,
          ...(shouldIncludeCertificate
            ? {
                signingCertificate: values.certificate,
                validateSignature: true,
              }
            : {}),
        },
        tenantID: selectedTenant.selectedTenantId,
      };

      // Show error if the input value is empty
      if (shouldIncludeCertificate && !values.certificate) {
        setErrorInCertificateInput(
          CreateEditSamlFormConsts.certificateRequiredErrorMsg
        );
      }

      if (shouldIncludeCertificate && !values.certificate) {
        return;
      }

      return formData
        ? dispatch(
            updateIdpConfig(payload, {
              sideEffect: () => setIsProviderAdded(true),
            })
          )
        : dispatch(
            createIdpConfig(payload, {
              sideEffect: () => setIsProviderAdded(true),
            })
          );
    },
  });

  // Clear error when the input value changes
  useEffect(() => {
    setErrorInCertificateInput("");
  }, [formik.values.certificate]);

  /**
   * Handles changes in the select input for "nameIdPolicyFormat"
   * @param e - the event fired when there is a change in the select input value
   */
  const handleSelectInputChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    inputName: string
  ) => formik.setFieldValue(inputName, e.target.value, true);

  // The input fields for taking the encryption certificate and encryption algorithm from the users if they want to sign authentication requests and validate assertions
  const CertificateAndEncryptionAlgoFields = useMemo(
    () => (
      <div
        className={`${classes.encryptionField} ${
          shouldIncludeCertificate && classes.encryptionFieldsExpanded
        }`}
      >
        <InputField
          id={InputFieldIds.samlCertificate}
          type="text"
          label={certificate.label}
          name={certificate.name}
          extraClass={`margin-bottom-25 ${classes.inputField}`}
          onChange={formik.handleChange}
          value={formik.values[certificate.name]}
          errorMessage={errorInCertificateInput}
          required
          autoComplete="off"
          multiLine
          rows={3}
        />
      </div>
    ),
    [
      shouldIncludeCertificate,
      formik.values[certificate.name],
      errorInCertificateInput,
    ]
  );

  const validateValues = () => {
    // Show error if the input value is empty
    if (shouldIncludeCertificate && !formik.values.certificate) {
      setErrorInCertificateInput(
        CreateEditSamlFormConsts.certificateRequiredErrorMsg
      );
    }
  };

  return (
    <form id={SettingsIds.addOrEditSamlForm} onSubmit={formik.handleSubmit}>
      {Object.entries(SamlIdpFormConfig).map(
        ([fieldName, fieldConfig]) =>
          !fieldConfig.preventRenderInLoop && (
            <InputField
              id={InputFieldIds.addOrEditSaml({ fieldName })}
              key={fieldName}
              type="text"
              label={fieldConfig.label}
              name={fieldConfig.name}
              extraClass={`margin-bottom-25 ${classes.inputField}`}
              onChange={formik.handleChange}
              value={formik.values[fieldConfig.name]}
              errorMessage={
                formik.touched[fieldConfig.name] &&
                formik.errors[fieldConfig.name]
              }
              required={!!fieldConfig.validationMessages?.required}
              autoComplete="off"
            />
          )
      )}
      <SelectWithTextField
        id={SelectWithTextFieldIds.nameIdPolicyFormat}
        options={NameIdPolicyFormatValues}
        value={formik.values[nameIdPolicyFormat.name]}
        onChange={(e) => handleSelectInputChange(e, nameIdPolicyFormat.name)}
        label={nameIdPolicyFormat.label}
        name={nameIdPolicyFormat.name}
        errorMessage={
          formik.touched[nameIdPolicyFormat.name] &&
          formik.errors[nameIdPolicyFormat.name]
        }
        extraClass={classes.selectField}
      />
      <Checkbox
        id={CheckboxIds.includeCertificate}
        isChecked={shouldIncludeCertificate}
        name="includeCertificate"
        onChange={setShouldIncludeCertificate}
        label={CreateEditSamlFormConsts.signAuthAndValidateAssertionsLabel}
        extraClass="margin-top-15"
      />
      {CertificateAndEncryptionAlgoFields}
      <div
        className={`d-flex margin-top-25 justify-${
          formData ? "end" : "between"
        }`}
      >
        {!formData && (
          <Button
            id={ButtonIDs.addOrEditSamlViewBack}
            disabled={isCreatingIdp}
            onClick={() => setIsIdpConfigVisible(true)}
          >
            Back
          </Button>
        )}
        <Button
          id={ButtonIDs.addOrEditSamlFormSubmit}
          type="submit"
          disabled={isCreatingIdp || isUpdatingIdp}
          onClick={validateValues}
        >
          {formData ? "Update Identity Provider" : "Add new Identity Provider"}
        </Button>
      </div>
    </form>
  );
};

export default AddOrEditSAML;
