import AppRoutes from "./routes";
import {
  Regex,
  ErrorMessages,
  ErrorCodes,
  AlertSeverity,
  AlertToastConfig,
  LeftMenuTabList,
  TokenCookiesKeyName,
  Pagination,
  DateTimeFormats,
  TabRoutes,
  SuccessMessages,
  PageTitles,
  PageTabs,
  DashboardPageTabs,
  AllPageTabsName,
  StringFormatters,
  DeviceDetailsTabIndex,
  TimeoutIntervals,
  DeviceDetailsTabs,
  QueryParams,
  EventActions,
  AboutDeviceHeadings,
  PasswordPolicies,
  DevicesNeedingAttentionFilters,
  devicesNeedingAttentionFilterData,
  Themes,
  AlertMessages,
  DashboardCardsData,
  DashboardEventCardTitles,
  DashboardDevicesCardTitles,
  Breakpoints,
  DashboardDevicesDataKeys,
  DashboardEventsDataKeys,
  EventActionTypes,
  LogoAltText,
  ScreenWidth,
  MemoryUnits,
  MonthNames,
  DayNames,
} from "./common";
import ActionTypes from "./actionTypes";
import FormFields from "./form";
import TableColumnsConfig from "./tableColumns";
import SVGIcons from "./icon";
import { APIUrls, NoCSRFAPIUrls } from "./apiPaths";

export default Object.freeze({
  AppRoutes,
  Regex,
  Pagination,
  DateTimeFormats,
  ActionTypes,
  FormFields,
  ErrorMessages,
  ErrorCodes,
  AlertSeverity,
  AlertToastConfig,
  LeftMenuTabList,
  TokenCookiesKeyName,
  TableColumnsConfig,
  TabRoutes,
  SuccessMessages,
  PageTitles,
  SVGIcons,
  PageTabs,
  DashboardPageTabs,
  AllPageTabsName,
  StringFormatters,
  DeviceDetailsTabIndex,
  TimeoutIntervals,
  DeviceDetailsTabs,
  QueryParams,
  NoCSRFAPIUrls,
  EventActions,
  AboutDeviceHeadings,
  PasswordPolicies,
  DevicesNeedingAttentionFilters,
  devicesNeedingAttentionFilterData,
  Themes,
  AlertMessages,
  DashboardCardsData,
  DashboardEventCardTitles,
  DashboardDevicesCardTitles,
  Breakpoints,
  DashboardDevicesDataKeys,
  DashboardEventsDataKeys,
  EventActionTypes,
  LogoAltText,
  ScreenWidth,
  MemoryUnits,
  MonthNames,
  DayNames,
  APIUrls,
});
