import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@material-ui/core";

// Actions
import { updateExportConfig, createExportConfig } from "@Store/actions";

// Components
import Modal from "@Components/Modal";
import InputField from "@Components/InputField";
import Button from "@Components/Button";
import ToggleButton from "@Components/ToggleButton";

// Constants
import Constants from "@Constants";
import {
  ButtonIDs,
  InputFieldIds,
  ModalIds,
  ToggleButtonIds,
  SettingsIds,
} from "@Constants/Id";

// Helpers
import { createOrUpdateExportConfigForm } from "@Helpers/validation";

// Styles
import useStyles from "./styles";

// When "rowData" is passed as a prop, in that case, the component will be used for editing that config
type AddOrEditConfigProps = {
  rowData?: RowData;
  isModalOpen?: boolean;
  closeModal: () => void;
  configToBeEdited: {
    exportUrl: string;
    token?: string;
    authHeaderKey?: string;
    authHeaderValue?: string;
    enabled: boolean;
    configId: string;
  } | null;
  onSuccess?: () => void;
};

const AddOrUpdateConfig = ({
  isModalOpen = false,
  closeModal,
  configToBeEdited,
  onSuccess,
}: AddOrEditConfigProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const createOrUpdateExportConfigState = useSelector(
    (state: GlobalState) => state.updateExportConfig
  );

  const { enabled, exportUrl, token, authHeaderKey, authHeaderValue } =
    Constants.FormFields.CreateOrUpdateExportConfig;

  const formik = useFormik({
    initialValues: {
      [exportUrl.name]: configToBeEdited ? configToBeEdited.exportUrl : "",
      [enabled.name]: configToBeEdited ? configToBeEdited.enabled : false,
      [token.name]: configToBeEdited?.token ?? "",
      [authHeaderKey.name]: configToBeEdited?.authHeaderKey ?? "",
      [authHeaderValue.name]: configToBeEdited?.authHeaderValue ?? "",
    },
    enableReinitialize: true,
    validationSchema: createOrUpdateExportConfigForm,
    onSubmit: (values) => {
      const payload: UpdateExportConfigPayload = {
        exportUrl: values.exportUrl as string,
        token: values[token.name] as string,
        authHeaderKey: values[authHeaderKey.name] as string,
        authHeaderValue: values[authHeaderValue.name] as string,
        enabled: values.enabled.toString(),
      };

      /**
       * Side-effects after the API call is successful
       */
      const onApiSuccess = () => {
        onSuccess?.();
        closeModal();
      };

      dispatch(
        configToBeEdited
          ? updateExportConfig(payload, configToBeEdited.configId, {
              sideEffect: onApiSuccess,
            })
          : createExportConfig(payload, { sideEffect: onApiSuccess })
      );
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [isModalOpen]);

  return (
    <Modal
      id={ModalIds.addOrUpdateEventFeedConfig}
      isOpen={isModalOpen}
      close={closeModal}
      title={configToBeEdited ? "Edit Feed" : "Create new Feed"}
      modalExtraClass={classes.modalExtraClass}
    >
      <form id={SettingsIds.createFeedForm} onSubmit={formik.handleSubmit}>
        <div className="margin-top-25">
          <InputField
            id={InputFieldIds.exportUrl}
            type="text"
            label={exportUrl.label}
            name={exportUrl.name}
            onChange={formik.handleChange}
            value={formik.values[exportUrl.name] as string}
            errorMessage={
              formik.touched[exportUrl.name] && formik.errors[exportUrl.name]
            }
            autoComplete="off"
            placeholder={exportUrl.placeholder}
          />
        </div>
        <div className="margin-top-25">
          <InputField
            id={InputFieldIds.bearerToken}
            type="text"
            label={token.label}
            name={token.name}
            onChange={formik.handleChange}
            value={formik.values[token.name] as string}
            autoComplete="off"
          />
        </div>
        <div className="margin-top-15 text-align-center">OR</div>
        <div className="margin-top-15">
          <div>
            <InputField
              id={InputFieldIds.authHeaderKey}
              type="text"
              label={authHeaderKey.label}
              name={authHeaderKey.name}
              onChange={formik.handleChange}
              value={formik.values[authHeaderKey.name] as string}
              autoComplete="off"
            />
          </div>
          <div className="margin-top-15">
            <InputField
              id={InputFieldIds.authHeaderValue}
              type="text"
              label={authHeaderValue.label}
              name={authHeaderValue.name}
              onChange={formik.handleChange}
              value={formik.values[authHeaderValue.name] as string}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="margin-top-25">
          <FormControlLabel
            className={classes.toggleContainer}
            control={
              <ToggleButton
                id={ToggleButtonIds.eventFeedEnableToggleButton}
                checked={formik.values[enabled.name] as boolean}
                onChange={formik.handleChange}
                name={enabled.name}
              />
            }
            label={
              Boolean(formik.values[enabled.name]) ? "Enabled" : "Disabled"
            }
          />
        </div>
        <div className="margin-top-25 flex justify-end">
          <Button
            id={ButtonIDs.createOrUpdateFeedConfirmation}
            type="submit"
            disabled={createOrUpdateExportConfigState.loading}
          >
            {configToBeEdited ? "Update Feed" : "Create Feed"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddOrUpdateConfig;
