// Load variables from the .env file

// It is a flag to check if using mock is true in the .env file
const USE_MOCK = process.env.REACT_APP_USE_MOCK;

// It is a flag to check if dev mode is true in the .env file
const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE;

export default {
  USE_MOCK,
  IS_DEV_MODE,
};
