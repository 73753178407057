import Constants from "@Constants";

const initialState: TagAllPages = {
  success: false,
  loading: false,
  error: "",
};

const tagAllDevicePages = (
  state: TagAllPages = initialState,
  action: GenericAction
): TagAllPages => {
  switch (action.type) {
    case Constants.ActionTypes.TAG_ALL_DEVICE_PAGES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case Constants.ActionTypes.TAG_ALL_DEVICE_PAGES_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };
    case Constants.ActionTypes.TAG_ALL_DEVICE_PAGES_FAILED:
      return {
        loading: false,
        success: false,
        error: action?.payload,
      };
  }
  return state;
};

export default tagAllDevicePages;
