import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
    fontSize: theme.pxToRem(15),
    cursor: "pointer",
  },
  contentWrapper: {
    "& .MuiPopover-paper": {
      padding: `${theme.pxToRem(8)} 0`,
      marginTop: theme.pxToRem(5),
    },
  },
  borderRadius: {
    "& .MuiPaper-root": {
      borderRadius: theme.pxToRem(8),
    },
  },
  caret: {
    marginLeft: theme.pxToRem(15),
    height: theme.pxToRem(20),
    transition: "transform 200ms ease-in",

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      marginLeft: theme.pxToRem(6),
      transform: "scale(.9)",
    },

    "& path": {
      fill: theme.newPalette.header.color,
    },
  },
  caretRotated: {
    transform: "rotate(180deg)",
  },
  appBg: {
    background: `${theme.newPalette.appBg}!important`,
  },
}));

export default useStyles;

/**
 * Styles for storybook
 */
export const useStorybookStyles = makeStyles((theme: Theme) => ({
  popoverBg: {
    "& .MuiPopover-paper": {
      background: theme.newPalette.popover.background,
      color: theme.newPalette.popover.color,
      boxShadow: theme.newPalette.popover.shadowColor,
    },
  },
}));
