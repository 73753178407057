import Constants from "@Constants";

const initialState: ThreatDetailsState = {
  loading: false,
  threat: {},
  error: "",
};

const threatDetailsReducer = (
  state: ThreatDetailsState = initialState,
  action: GenericAction
): ThreatDetailsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_THREAT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_THREAT_DETAILS_SUCCESS:
      return {
        loading: false,
        threat: action?.payload,
        error: "",
      };
    case Constants.ActionTypes.FETCH_THREAT_DETAILS_FAILED:
      return {
        loading: false,
        threat: {},
        error: action?.payload,
      };
  }
  return state;
};

export default threatDetailsReducer;
