import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: theme.pxToRem(20),
    boxShadow: "none",
    color: theme.newPalette.text.primary.main,
    backgroundColor: theme.newPalette.card.background,
    padding: theme.pxToRem(30),

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: theme.pxToRem(20),
      minWidth: theme.pxToRem(200),
    },

    "&:hover": {
      boxShadow: `${theme.pxToRem(20)} ${theme.pxToRem(20)} ${theme.pxToRem(
        80
      )} ${theme.newPalette.card.shadowColor}`,
    },
  },
  subPart: {
    flexBasis: "60%",
    minHeight: theme.pxToRem(60),
    minWidth: theme.pxToRem(100),
  },
  graph: {
    flexBasis: "100%",
  },
  footer: {
    padding: `${theme.pxToRem(25)} 0 0`,
  },
  cardTitle: {
    fontSize: theme.pxToRem(14),
    marginBottom: theme.pxToRem(5),
    color: theme.newPalette.text.primary.light,
  },
  number: {
    marginRight: theme.pxToRem(10),

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      fontSize: theme.pxToRem(32),
    },
  },
  link: {
    color: theme.newPalette.text.primary.main,
    width: "100%",
    // typography styles same as button medium
    ...theme.utils.buttonMedium(),
  },
}));

export default useStyles;
