// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { SuccessMessages } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// Handle actions for requesting file
export const postFileRequest = (eventID: string) =>
  bindClientFunc({
    clientFunc: client.postRequestFile,
    onFailure: ActionTypes.REQUEST_FILE_FAILED,
    onRequest: ActionTypes.REQUEST_FILE_REQUEST,
    onSuccess: ActionTypes.REQUEST_FILE_SUCCESS,
    params: [eventID],
    handleSuccess: { message: SuccessMessages.threatDetailsFileRequest },
  }) as unknown as Promise<BindClient>;

// Handles actions for fetching the threat details
export const fetchThreatDetails = (threatId: string) =>
  bindClientFunc({
    clientFunc: client.getThreatDetails,
    onFailure: ActionTypes.FETCH_THREAT_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_THREAT_DETAILS_REQUEST,
    onSuccess: ActionTypes.FETCH_THREAT_DETAILS_SUCCESS,
    params: [threatId],
  }) as unknown as Promise<BindClient>;

// Handle actions for downloading threat
export const downloadThreat = (threatId: string) =>
  bindClientFunc({
    clientFunc: client.downloadThreat,
    onFailure: ActionTypes.FETCH_THREAT_DOWNLOAD_FAILED,
    onRequest: ActionTypes.FETCH_THREAT_DOWNLOAD_REQUEST,
    onSuccess: ActionTypes.FETCH_THREAT_DOWNLOAD_SUCCESS,
    params: [threatId],
  }) as unknown as Promise<BindClient>;
