import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Button from "@Components/Button";

// Constants
import Constants from "@Constants";
import DeleteConfirmationDialog from "@Containers/users/deleteUser/confirmationDialog";
import { ButtonIDs } from "@Constants/Id";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";

// Styles
import useStyles from "@Containers/deviceDetails/subComponents/deleteDevice/styles";

const DeleteUser = () => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const { loading: isDeletingUser } = useSelector(
    (state: GlobalState) => state?.deleteTenantUser
  );
  const history = useCustomHistory();
  const classes = useStyles();

  /**
   * When the user is deleted take the user back to the "Users" page
   */
  const goBack = () =>
    history.replace(Constants.AppRoutes.ProtectedRoutes.Users);

  return (
    <>
      <Button
        id={ButtonIDs.deleteUserHeader}
        onClick={() => setDeleteConfirmationOpen(true)}
        disabled={isDeletingUser}
        variant="outlined"
        color="primary"
        extraClass={classes.deleteButton}
      >
        Delete
      </Button>
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationOpen}
        handleClose={() => setDeleteConfirmationOpen(false)}
        userId={userId}
        handleDeleteSuccess={goBack}
      />
    </>
  );
};

export default DeleteUser;
