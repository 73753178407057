import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import ProgressButton from "@Components/ProgressButton";

// Actions
import {
  addTagsToDevices,
  resetLastDeletedTag,
  updateAddedTagsToDeviceInfo,
} from "@Store/actions";

// Constants
import Constants from "@Constants";
import {
  UndoTagPersistTimeMs,
  DefaultMaxProgressValue,
} from "@Constants/common";
import { ButtonIDs } from "@Constants/Id";

// Styles
import useStyles from "@Components/TagGroup/TagGroup.styles";

/**
 * UndoTagRemoval Component
 *
 * @example Correct usage
 * ```tsx
 *   <UndoTagRemoval />
 * ```
 */
export const UndoTagRemoval = () => {
  const classes = useStyles();

  // Initializing hooks
  const dispatch = useDispatch();
  const { id: deviceId } = useParams<{ id: string }>();

  const [progress, setProgress] = useState(DefaultMaxProgressValue);

  const { lastDeletedTag } = useSelector(
    (state: GlobalState) => state.deleteTagFromDevices
  );
  const { loading: addTagLoading } = useSelector(
    (state: GlobalState) => state.addTagsToDevices
  );

  // To track timeoutId and intervalId
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const progressInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  // Clears the interval and timeout, and sets progress to 100.
  const clearTimeoutAndInterval = () => {
    setProgress(DefaultMaxProgressValue);
    clearTimeout(timeout.current as NodeJS.Timeout);
    clearInterval(progressInterval.current as NodeJS.Timeout);
  };

  const setTimeoutAndInterval = () => {
    // dispatches resetLastDeleteTag after 5 seconds.
    timeout.current = setTimeout(
      () => dispatch(resetLastDeletedTag()),
      UndoTagPersistTimeMs
    );

    /**
     * Counts down the progress state, which is used to animate the ProgressButton Component.
     * On reaching `progress = 0` the interval is cleared.
     */
    progressInterval.current = setInterval(() => {
      setProgress((prevState) => {
        if (prevState <= 0) {
          clearInterval(progressInterval.current as NodeJS.Timeout);
        }
        return --prevState;
      });
    }, UndoTagPersistTimeMs / DefaultMaxProgressValue);
  };

  const handleUndoDelete = async () => {
    /**
     * Side effects for the success response from the API call
     */
    const onAddTagsToDevices = () => {
      // update tags in device info with the added tags
      dispatch(updateAddedTagsToDeviceInfo({ tags: [lastDeletedTag] }));
      dispatch(resetLastDeletedTag());
      clearTimeoutAndInterval();
    };

    dispatch(
      addTagsToDevices(
        { ids: [deviceId], tags: [lastDeletedTag] },
        {
          message: Constants.SuccessMessages.tagUndo,
          sideEffect: onAddTagsToDevices,
        },
        { customErrorMessage: Constants.ErrorMessages.tagUndo }
      )
    );
  };

  /**
   * Using useMemo so that we could set the timeout and interval before the first render
   */
  useMemo(() => {
    if (lastDeletedTag) {
      clearTimeoutAndInterval();
      setTimeoutAndInterval();
    }
    return () => {
      clearTimeoutAndInterval();
    };
  }, [lastDeletedTag]);

  return (
    <>
      {!!lastDeletedTag && (
        <ProgressButton
          id={ButtonIDs.undoTag}
          progress={progress}
          size="small"
          progressExtraClass={classes.undoProgressIndicator}
          extraClass={`${classes.undoButton} ${lastDeletedTag && "visible"} `}
          disabled={addTagLoading}
          onClick={handleUndoDelete}
          onMouseEnter={clearTimeoutAndInterval}
          onMouseOver={clearTimeoutAndInterval}
          onMouseLeave={() => {
            if (!addTagLoading) {
              setTimeoutAndInterval();
            }
          }}
        />
      )}
    </>
  );
};
