import { Dialog, DialogContent, Typography } from "@material-ui/core";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import Constants from "@Constants";
import { ButtonIDs } from "@Constants/Id";

// Styles
import useStyles from "./Modal.styles";

// Types
import { ModalProps } from "./Modal";

/**
 * Modal component
 *
 * @example Correct usage with title and subtitle
 * ```ts
 * <Modal
 *  id={uniqueId}
 *  isOpen={isOpen}
 *  close={handleClose}
 *  title="Modal title"
 *  subTitle="Modal description"
 * >
 *  Modal children
 * </Modal>
 * ```
 *
 * @example Correct usage without title and subtitle
 * ```ts
 * <Modal
 *  id={uniqueId}
 *  isOpen={isOpen}
 *  close={handleClose}
 * >
 *  Modal children
 * </Modal>
 * ```
 */
export const Modal = ({
  id,
  close,
  isOpen,
  children,
  title = "",
  subTitle = "",
  modalExtraClass = "",
  modalTitleExtraClass = "",
  modalSubtitleExtraClass = "",
  modalContentExtraClass = "",
  cancelButtonExtraClass = "",
  ...props
}: ModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      {...props}
      id={id}
      open={isOpen}
      onClose={close}
      className={`${classes.modal} ${modalExtraClass}`}
    >
      {(title || subTitle) && (
        <div className="flex flex-column" id={`${id}-header`}>
          {title && (
            <Typography
              variant="h5"
              className={`text-align-center padding-0 ${modalTitleExtraClass} ${classes.modalTitle}`}
              component="h2"
              id={`${id}-title`}
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              variant="body2"
              className={`text-align-center ${modalSubtitleExtraClass} ${classes.modalSubtitle}`}
              id={`${id}-subtitle`}
            >
              {subTitle}
            </Typography>
          )}
        </div>
      )}
      <Button
        id={ButtonIDs.modalClose}
        variant="text"
        extraClass={`position-fixed ${classes.cancelButton} ${cancelButtonExtraClass}`}
        onClick={close}
      >
        <SVGWrapper width={24} height={24} viewBox="0 0 24 24">
          {Constants.SVGIcons.cross}
        </SVGWrapper>
      </Button>
      <DialogContent
        className={`${classes.modalContent} ${modalContentExtraClass} custom-scroll-bar`}
        id={`${id}-content`}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
