import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// TODO: Uncomment while integration edit tenant functionality
// import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";

// Actions
import {
  // TODO: modify for dual backend setup
  // addOrUpdateChildTenantList,
  fetchChildTenants,
  deleteChildTenant,
} from "@Store/actions";

// Components
import PaginatedTable from "@Components/Table/PaginatedTable";
import ConfirmationDialog from "@Components/ConfirmationDialog";
import Button from "@Components/Button";
import EmptyState from "@Components/EmptyState";

// Containers
import AddTenant from "./addAndEditTenant";

// Constants
import Constants from "@Constants";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import {
  ButtonIDs,
  ConfirmationDialogIds,
  EmptyStateIds,
  TableIds,
} from "@Constants/Id";

// Hooks
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Utils
import Utils from "@Utils";

// Styles
import useStyles from "./styles";

const Tenants = (): JSX.Element => {
  // Initialize hooks
  const dispatch = useDispatch();
  const { selectedTenant, fetchTenantInfo } = useTenantAccess();
  const { checkAccessAndRender, canAccess } = usePermissionFilter();

  // Initialize state variables
  // Track if the modal used for creating a tenant or editing a tenant is open or not
  const [addOrEditModalOpen, setAddOrEditModalOpen] = useState(false);
  // Track selected tenant
  const [targetTenant, setTargetTenant] = useState<EditTenantInfo | null>(null);

  // Track if alert dialog is open/closed
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState<boolean>(false);

  const classes = useStyles();

  const { currentPage, noOfRowsPerPage, onPageChange, onRowSizeChange } =
    usePagination({});

  const childTenants = useSelector((state: GlobalState) => state.childTenants);
  const addTenant = useSelector((state: GlobalState) => state.addTenant);
  const deleteTenantState = useSelector(
    (state: GlobalState) => state.deleteTenant
  );

  /**
   * Set data of selected tenant
   * @param data Record<string, string>
   */
  const setSelectedTenantInfo = (data: RowData) => {
    const selectedTenantInfo: EditTenantInfo = {
      tenantId: (data.id as CellData).value,
      fullName: (data.name as CellData).value,
      alias: (data.alias as CellData).value,
    };

    setTargetTenant(selectedTenantInfo);
  };

  /**
   * Toggle delete alert dialog
   */
  const toggleDeleteConfirmationDialog = (): void => {
    setDeleteConfirmationDialogOpen(!isDeleteConfirmationDialogOpen);
  };

  // Handle closing the add or edit tenant modal
  const closeModal = () => {
    setAddOrEditModalOpen(false);
    setTargetTenant(null);
  };

  /**
   * Handle fetching tenants list
   * Dispatch action to fetch tenants data
   * Dispatch error toast in case of any error
   */
  const handleFetchingTenants = () => {
    if (selectedTenant.manageTenant) {
      dispatch(
        fetchChildTenants(
          { page: currentPage, pageSize: noOfRowsPerPage },
          selectedTenant.selectedTenantId
        )
      );
    }
  };

  const handleDeleteTenant = async () => {
    await dispatch(
      deleteChildTenant(
        {
          tenantId: targetTenant?.tenantId || "",
        },
        selectedTenant.selectedTenantId,
        {
          sideEffect: () => {
            handleFetchingTenants();
            fetchTenantInfo();
          },
        }
      )
    );

    setTargetTenant(null);
    // Close the dialog once API request is completed
    toggleDeleteConfirmationDialog();
  };

  // Fetch child tenants when the component gets mounted
  useEffect(() => {
    handleFetchingTenants();
    if (addTenant.success) fetchTenantInfo();
  }, [currentPage, noOfRowsPerPage, selectedTenant, addTenant.success]);

  // Options config for Edit and Delete tenant actions
  const tenantOptionsConfig: TableOptionsConfig[] = [
    {
      label: "Delete",
      icon: <DeleteOutlineIcon className="table-option-icon" />,
      onClick: (data: RowData) => {
        setSelectedTenantInfo(data);
        toggleDeleteConfirmationDialog();
      },
      shouldRender: canAccess(FeatureToAccessTypeMap.tenants.update),
    },
    // TODO: uncomment while integrating the edit tenant functionality
    // {
    //   label: "Edit",
    //   icon: <EditIcon className="table-option-icon" />,
    //   onClick: (data: RowData) => {
    //     setSelectedTenantInfo(data);
    //     setAddOrEditModalOpen(true);
    //   },
    // },
  ];

  return selectedTenant.manageTenant ? (
    <>
      <div
        className={`text-align-right text-align-left-sm position-relative-sm ${classes.addNewTenantBtnContainer}`}
      >
        {checkAccessAndRender({
          featureAccessLevel: FeatureToAccessTypeMap.tenants.create,
          child: (
            <Button
              id={ButtonIDs.addNewTenant}
              startIcon={<AddIcon />}
              onClick={() => setAddOrEditModalOpen(true)}
            >
              Add New Tenant
            </Button>
          ),
        })}
      </div>
      <PaginatedTable
        id={TableIds.tenants}
        isLoading={childTenants.loading}
        headers={Constants.TableColumnsConfig.Tenants}
        rowsData={Utils.parseToRowDataType(
          Constants.TableColumnsConfig.Tenants,
          childTenants.tenants
        )}
        showPagination
        pagination={{
          noOfRowsPerPage: noOfRowsPerPage,
          currentPageNumber: currentPage,
          totalRowsAvailable: childTenants.pagination?.totalRowsAvailable,
          jumpToPage: (pageNumber: number) => {
            onPageChange(pageNumber);
          },
          onClickSetPageSize: (pageSize: number) => {
            onRowSizeChange(pageSize);
          },
          lastPageNumber: childTenants.pagination?.lastPageNumber,
        }}
        options={{
          list: tenantOptionsConfig,
          shouldRender: canAccess(FeatureToAccessTypeMap.tenants.update),
        }}
      />
      <AddTenant open={addOrEditModalOpen} close={closeModal} />
      <ConfirmationDialog
        id={ConfirmationDialogIds.deleteTenant}
        alertTitle="Are you sure?"
        alertDescription={
          <Typography>
            Do you really want to{" "}
            <Typography component="span" className="font-weight-bold">
              delete this Tenant?
            </Typography>{" "}
            This action cannot be undone.
          </Typography>
        }
        confirmButtonText="Delete"
        disabledButtons={deleteTenantState.loading}
        isOpen={isDeleteConfirmationDialogOpen}
        handleClose={toggleDeleteConfirmationDialog}
        handleConfirmation={handleDeleteTenant}
      />
    </>
  ) : (
    <EmptyState
      id={EmptyStateIds.noAccessToManageTenants}
      messageTitle={Constants.ErrorMessages.noAccessToManageTenants}
    />
  );
};

export default Tenants;
