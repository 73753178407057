import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: theme.pxToRem(217),
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "none",
    },
  },
  logoSmallScreen: {
    width: theme.pxToRem(150),
    display: "none",
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "block",
    },
  },
  root: {
    backgroundColor: theme.newPalette.pageNotFound.leftBackground,
    overflow: "hidden",
  },
  leftDiv: {
    padding: theme.pxToRem(80),
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      padding: theme.pxToRem(0),
    },
  },
  rightDiv: {
    flexGrow: 1,
    backgroundColor: theme.newPalette.pageNotFound.rightBackground,
  },
  textContainer: {
    zIndex: 1,
    maxWidth: theme.pxToRem(473),
    color: theme.newPalette.text.primary.main,
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      padding: theme.pxToRem(20),
    },
  },
  title: {
    fontSize: theme.pxToRem(240),
    lineHeight: theme.pxToRem(240),
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      fontSize: theme.pxToRem(140),
      lineHeight: theme.pxToRem(160),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.pxToRem(84),
      lineHeight: theme.pxToRem(90),
    },
  },
  subTitle: {
    fontSize: theme.pxToRem(84),
    lineHeight: theme.pxToRem(100),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.pxToRem(54),
      lineHeight: theme.pxToRem(40),
    },
  },
  description: {
    ...theme.utils.regularText(),
    lineHeight: theme.pxToRem(40),
    margin: `${theme.pxToRem(20)} 0`,
    color: theme.newPalette.text.primary.light,
  },
  toHomeLink: {
    color: theme.newColors.fieryOrange,
  },
  bgIllustrations: {
    zIndex: 0,
    position: "absolute",
    opacity: 0.2,
  },
  bottomLeftIllustration: {
    bottom: theme.pxToRem(-100),
    left: theme.pxToRem(-70),
  },
  topRightIllustration: {
    top: theme.pxToRem(-250),
    right: theme.pxToRem(-350),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  barrierIllustration: {
    position: "absolute",
    left: theme.pxToRem(160),
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "none",
    },
  },
}));

export default useStyles;
