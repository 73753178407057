import Constants from "@Constants";

const initialState: AuthState = {
  loading: false,
  error: "",
};

/**
 * Handles auth actions of login and logout
 * @param state AuthState
 * @param action GenericAction
 * @returns AuthState
 */
const authReducer = (
  state: AuthState = initialState,
  action: GenericAction
): AuthState => {
  switch (action.type) {
    case Constants.ActionTypes.LOGIN_REQUEST ||
      Constants.ActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case Constants.ActionTypes.LOGIN_SUCCESS ||
      Constants.ActionTypes.LOGOUT_SUCCESS:
      return {
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.LOGIN_FAILED ||
      Constants.ActionTypes.LOGOUT_FAILED:
      return {
        loading: false,
        error: action?.payload,
      };
  }
  return state;
};

export default authReducer;
