import Constants from "@Constants";

const initialState: EventMetricsByMonth = {
  EventMetricsByMonthLoading: false,
  months: {},
  EventMetricsByMonthError: "",
};

const eventMetricsByMonthReducer = (
  state: EventMetricsByMonth = initialState,
  action: GenericAction
): EventMetricsByMonth => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_REQUEST:
      return {
        ...state,
        EventMetricsByMonthLoading: true,
      };
    case Constants.ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_SUCCESS:
      return {
        EventMetricsByMonthLoading: false,
        months: action?.payload?.months,
        EventMetricsByMonthError: "",
      };
    case Constants.ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_FAILED:
      return {
        ...state,
        EventMetricsByMonthLoading: false,
        EventMetricsByMonthError: action?.payload,
      };
  }
  return state;
};

export default eventMetricsByMonthReducer;
