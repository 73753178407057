import Security from "@Containers/security";
import ErrorBoundary from "@Containers/errorBoundary";

const SecurityPage = (): JSX.Element => (
  <ErrorBoundary>
    <Security />
  </ErrorBoundary>
);

export default SecurityPage;
