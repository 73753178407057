import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableRoot: {
    overflowX: "visible",
    "&.MuiTableContainer-root": {
      overflowX: "visible",
    },
  },
  hideBoxShadow: {
    "&.MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

export default useStyles;
