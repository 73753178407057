import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Types
import { StyleArgs } from "./Cell";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tableRowBorderLeft: {
    borderLeft: `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`,
    opacity: 1,
  },
  tableRow: {
    padding: theme.pxToRem(17),
    fontSize: theme.pxToRem(13),
    lineHeight: theme.pxToRem(12),
    color: ({ color }: StyleArgs) => color ?? theme.newPalette.table.color,
    border: 0,
    "& span": {
      maxWidth: ({ maxWidth, truncate }: StyleArgs) =>
        truncate
          ? `max(${theme.pxToRem(maxWidth || 0)}, ${theme.pxToRem(400)})`
          : "unset",
      [theme.breakpoints.down(Breakpoints.desktopMin)]: {
        maxWidth: ({ maxWidth, truncate }: StyleArgs) =>
          truncate ? theme.pxToRem(maxWidth || 150) : "unset",
      },
      [theme.breakpoints.down(Breakpoints.tabletMin)]: {
        maxWidth: "unset !important",
      },
    },

    "&:hover > .copy-icon": {
      display: "initial",
    },

    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      padding: theme.pxToRem(8),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: ({ inHorizontalTable }) =>
        inHorizontalTable ? theme.pxToRem(8) : 0,
      marginBottom: theme.pxToRem(10),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  maskedInput: {
    backgroundColor: "transparent",
    border: 0,
  },
  tableCell: {
    fontSize: theme.pxToRem(13),
    width: ({ isMobile }: StyleArgs) =>
      isMobile ? `calc(70% - ${theme.pxToRem(16)})` : "100%",
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      fontSize: theme.pxToRem(11),
    },
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      fontSize: theme.pxToRem(13),
    },
  },
  copyIcon: {
    fontSize: theme.pxToRem(20),
    color: theme.newPalette.text.primary.light,
    position: "absolute",
    right: theme.pxToRem(-18),
    display: "none",

    "&:hover": {
      color: theme.newColors.fieryOrange,
    },
  },
  mobileHeader: {
    color: theme.newPalette.table.headerColor,
    width: "30%",
    marginRight: theme.pxToRem(16),
    textTransform: "uppercase",
    fontSize: theme.pxToRem(13),
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
}));

export default useStyles;
