// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

/**
 * Fetches the list of devices
 * @param DevicesPayload - payload to be passed in to the API
 * @param attentionType - if present gets attention needed devices
 */
export const fetchDevices = (
  {
    page,
    pageSize,
    sortOrder,
    sortBy,
    attentionType,
    tag,
    name,
    operatingSystem,
    agentVersion,
    search,
  }: DevicesPayload,
  abortSignal?: AbortSignal
) =>
  bindClientFunc({
    clientFunc: client.getDevices,
    onFailure: ActionTypes.FETCH_DEVICES_FAILED,
    onRequest: ActionTypes.FETCH_DEVICES_REQUEST,
    onSuccess: ActionTypes.FETCH_DEVICES_SUCCESS,
    params: [
      page.toString(),
      pageSize.toString(),
      sortBy ?? "",
      sortOrder ?? "",
      attentionType ?? "",
      tag ?? "",
      name ? `\"${name}\"` : "",
      operatingSystem ? `\"${operatingSystem}\"` : "",
      agentVersion ?? "",
      search ? `\"${search}\"` : "",
    ],
    abortSignal,
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching device info details
export const fetchDeviceInfoDetails = ({
  deviceId,
}: DeviceInfoDetailsPayload) =>
  bindClientFunc({
    clientFunc: client.getDeviceInfoDetails,
    onFailure: ActionTypes.FETCH_DEVICE_INFO_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_DEVICE_INFO_DETAILS_REQUEST,
    onSuccess: ActionTypes.FETCH_DEVICE_INFO_DETAILS_SUCCESS,
    params: [deviceId],
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching device metrics
export const fetchDeviceMetrics = () =>
  bindClientFunc({
    clientFunc: client.getDeviceMetrics,
    onFailure: ActionTypes.FETCH_DEVICE_METRICS_FAILED,
    onRequest: ActionTypes.FETCH_DEVICE_METRICS_REQUEST,
    onSuccess: ActionTypes.FETCH_DEVICE_METRICS_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching information about extracted key
export const fetchExtractedKeyInfo = (
  deviceId: string,
  extractedKeyId: string
) =>
  bindClientFunc({
    clientFunc: client.getExtractedKeyInfo,
    onFailure: ActionTypes.FETCH_EXTRACTED_KEY_INFO_FAILED,
    onRequest: ActionTypes.FETCH_EXTRACTED_KEY_INFO_REQUEST,
    onSuccess: ActionTypes.FETCH_EXTRACTED_KEY_INFO_SUCCESS,
    params: [deviceId, extractedKeyId],
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching extracted keys for a device
export const fetchDeviceExtractedKeys = (
  { page, pageSize }: PageQueryParams,
  deviceId: string
) =>
  bindClientFunc({
    clientFunc: client.getDeviceExtractedKeys,
    onFailure: ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_FAILED,
    onRequest: ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_REQUEST,
    onSuccess: ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_SUCCESS,
    params: [deviceId, page.toString(), pageSize.toString()],
  }) as unknown as Promise<BindClient>;

// Handle actions for deleting a device
export const deleteDevice = (
  deviceId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.deleteDevice,
    onFailure: ActionTypes.DELETE_DEVICE_FAILED,
    onRequest: ActionTypes.DELETE_DEVICE_REQUEST,
    onSuccess: ActionTypes.DELETE_DEVICE_SUCCESS,
    params: [deviceId],
    handleSuccess,
  }) as unknown as Promise<BindClient>;

// Handle resetting the state for deleting the device
export const resetDeviceDeleted = (): GenericAction => ({
  type: ActionTypes.ERASE_DEVICE_DELETED_STATE,
});
