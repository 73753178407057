import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  /*TODO: Uncomment when implementing Profile picture options once API supports so*/
  // profileImage: {
  //   height: theme.pxToRem(140),
  //   width: theme.pxToRem(140),
  //   borderRadius: "50%",
  //   overflow: "hidden",
  //   position: "relative",
  //   cursor: "pointer",
  // },
  // editProfileImage: {
  //   transition: "400ms all ease",
  //   backgroundColor: "black",
  //   opacity: 0,
  //   position: "absolute",
  //   width: "100%",
  //   height: "100%",
  //   left: 0,
  //   top: 0,
  //   color: theme.newColors.neutral[100],
  //   "&:hover": {
  //     opacity: 0.5,
  //   },
  // },
  formWrapper: {
    flexDirection: "column",
    color: theme.newPalette.text.primary.main,
    maxWidth: theme.pxToRem(730),
    marginTop: theme.pxToRem(30),
  },
  inputField: {
    width: "100%",
  },
}));

export default useStyles;
