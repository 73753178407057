import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";

// Components
import Button from "@Components/Button";

//Images
import ErrorImage from "@Assets/images/error.svg";

// Constants
import { ButtonIDs, ErrorBoundaryIds } from "@Constants/Id";

// Styles
import { styles } from "./styles";

interface ErrorBoundaryProps extends WithStyles<typeof styles> {
  onError?: (error: Error) => void;
}

interface ErrorBoundaryState {
  errorMessage: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { errorMessage: error.message };
  }

  componentDidCatch(error: Error): void {
    this.props.onError?.(error);
  }

  onRetry() {
    window.location.reload();
  }

  render(): React.ReactNode {
    const classes = this.props.classes;
    if (this.state.errorMessage) {
      return (
        <div
          id={ErrorBoundaryIds.container}
          className="flex-column justify-center"
        >
          <img
            id={ErrorBoundaryIds.errorImage}
            src={ErrorImage}
            alt="Something Went Wrong"
            className={classes.errorImage}
          />
          <Typography
            id={ErrorBoundaryIds.errorText}
            component="h2"
            className={`${classes.errorText} font-cabinet-grostek font-weight-bold`}
          >
            Something went Wrong
          </Typography>
          <Button
            id={ButtonIDs.errorBoundaryTryAgain}
            onClick={() => this.onRetry()}
            color="primary"
            extraClass={classes.button}
          >
            Try Again
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);
