import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, FormControlLabel, DialogActions } from "@material-ui/core";

// Components
import Modal from "@Components/Modal";
import Button from "@Components/Button";

// Constants
import { SessionTime, UserSessionPrompts } from "@Constants/common";
import { ButtonIDs, ModalIds, InactivityTrackerIds } from "@Constants/Id";

// Hooks
import useAuth from "@Hooks/useAuth";
import useTenantAccess from "@Hooks/useTenantAccess";

// Actions
import { setUserActivity, setUserSessionPreference } from "@Store/actions";

// Styles
import useStyles from "./styles";

const InactivityTracker = ({ children }: { children: JSX.Element }) => {
  // Initialize hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useAuth();
  const { selectedTenant } = useTenantAccess();

  const userActivity = useSelector((state: GlobalState) => state.userActivity);
  const userSessionPreference = useSelector(
    (state: GlobalState) => state.userSessionPreference
  );

  // Stateful values
  const [activityPromptOpen, setActivityPromptOpen] = useState<boolean>(
    userSessionPreference.askAgain
  );
  const [askAgain, setAskAgain] = useState<boolean>(false);
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);
  const [logoutCountdown, setLogoutCountdown] = useState<number | null>(null);

  let checkUserIdleOnInterval: NodeJS.Timer;
  let logoutDecrement: NodeJS.Timeout | null = null;
  let throttleTimeout: NodeJS.Timeout | null;

  /**
   * Throttle function
   * @param callback callback function
   * @param wait delay between each call
   */
  const throttle = (callback: () => void, wait: number) => () => {
    if (throttleTimeout) return;
    throttleTimeout = setTimeout(
      () => (callback(), (throttleTimeout = null)),
      wait
    );
  };

  const stopUserActivityCheck = () => clearInterval(checkUserIdleOnInterval);

  /**
   * Decrease logout counter after 1 second
   * @param timeRemaining Remaining time to logout counter
   */
  const decrementCountdown = (timeRemaining: number | null) => {
    logoutDecrement = isUserIdle
      ? setTimeout(() => {
          setLogoutCountdown(timeRemaining);
        }, 1000)
      : null;
  };

  /**
   * Check if user is inactive
   * Start logout timer if user is in-active
   */
  const userIdle = () => {
    if (
      userActivity.lastActivity &&
      !logoutCountdown &&
      (new Date().getTime() - userActivity.lastActivity.getTime()) *
        SessionTime.coefficientToConvertSecToMin >=
        SessionTime.sessionTime
    ) {
      setIsUserIdle(true);
      if (checkUserIdleOnInterval) stopUserActivityCheck();
      setLogoutCountdown(SessionTime.timeToLogout * 60);
    }
  };

  /**
   * Save user's active time
   * @param restartSession boolean
   */
  const saveLastActivity = (restartSession?: boolean) => {
    if (!isUserIdle || restartSession) dispatch(setUserActivity(true));
  };

  /**
   * Save user session preferences to redux store
   * @param staySignedIn If user wants to stay signed in even after being inactive
   */
  const saveUserSessionPreference = (staySignedIn: boolean) => {
    dispatch(
      setUserSessionPreference({
        askAgain,
        staySignedIn,
      })
    );
    setActivityPromptOpen(false);
  };

  /**
   * check if a user is active at regular intervals of 10 seconds
   */
  const startUserActivityCheck = () => {
    checkUserIdleOnInterval = setInterval(() => {
      userIdle();
    }, 5000);
  };

  /**
   * Stop logout timer
   */
  const setStaySignedIn = async () => {
    if (logoutDecrement) {
      clearTimeout(logoutDecrement);
      logoutDecrement = null;
    }
    await setLogoutCountdown(null);
    await setIsUserIdle(false);
    saveUserSessionPreference(true);
  };

  /**
   * Start User session check on init
   */
  useEffect(() => {
    if (!userSessionPreference.staySignedIn) startUserActivityCheck();
    return () => {
      dispatch(setUserActivity(false));
      if (logoutDecrement) clearTimeout(logoutDecrement);
      if (throttleTimeout) clearTimeout(throttleTimeout);
      stopUserActivityCheck();
    };
  }, [userSessionPreference]);

  /**
   * Stop User activity check and start logout countdown once
   * user is idle
   */
  useEffect(() => {
    if (isUserIdle && checkUserIdleOnInterval) stopUserActivityCheck();
  }, [isUserIdle]);

  /**
   * Track logout countdown and handle actions
   */
  useEffect(() => {
    if (logoutCountdown) decrementCountdown(logoutCountdown - 1);
    // Logout once countdown reaches 0
    else if (logoutCountdown === 0) {
      auth.handleLogout();
      setActivityPromptOpen(false);
    }
  }, [logoutCountdown, isUserIdle]);

  return (userSessionPreference.staySignedIn &&
    !userSessionPreference.askAgain) ||
    !selectedTenant.selectedTenantId.length ? (
    children
  ) : (
    <div
      onMouseMove={throttle(saveLastActivity, 5000)}
      onKeyDown={throttle(saveLastActivity, 5000)}
      onScroll={throttle(saveLastActivity, 5000)}
      id={InactivityTrackerIds.container}
    >
      {children}
      <Modal
        id={ModalIds.inactivityTracker}
        isOpen={activityPromptOpen || logoutCountdown !== null}
        close={(_, reason) => {
          if (reason !== "backdropClick") {
            setActivityPromptOpen(false);
            setLogoutCountdown(null);
          }
        }}
        title={
          logoutCountdown
            ? UserSessionPrompts.LogoutWarningPromptTitle
            : UserSessionPrompts.activityPromptTitle
        }
        subTitle={
          logoutCountdown
            ? UserSessionPrompts.LogoutWarningPromptSubTitle(logoutCountdown)
            : UserSessionPrompts.activityPromptSubTitle
        }
        modalExtraClass={classes.modalExtraClass}
        modalContentExtraClass="flex-column"
        modalSubtitleExtraClass={(!logoutCountdown && classes.subtitle) || ""}
        cancelButtonExtraClass="d-none"
      >
        {logoutCountdown ? (
          <DialogActions
            id={InactivityTrackerIds.actionWrapper}
            className="justify-between padding-0 w-100"
          >
            <Button
              id={ButtonIDs.inactivityTrackerLogout}
              variant="outlined"
              extraClass={classes.button}
              onClick={auth.handleLogout}
            >
              Logout
            </Button>
            <Button
              id={ButtonIDs.inactivityTrackerStaySignedIn}
              extraClass={classes.button}
              onClick={() => {
                setStaySignedIn();
                startUserActivityCheck();
              }}
            >
              Stay Signed in
            </Button>
          </DialogActions>
        ) : (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!askAgain}
                  name="askAgain"
                  className={classes.checkbox}
                  size="small"
                />
              }
              label="Do not show again"
              onChange={(_e: React.ChangeEvent<{}>, checked: boolean) =>
                setAskAgain(!checked)
              }
              className={classes.checkboxWrapper}
            />
            <DialogActions
              id={InactivityTrackerIds.actionWrapper}
              className="justify-between padding-0 w-100"
            >
              <Button
                id={ButtonIDs.staySignedInNo}
                variant="outlined"
                extraClass={classes.button}
                onClick={() => saveUserSessionPreference(false)}
              >
                No
              </Button>
              <Button
                id={ButtonIDs.staySignedInYes}
                extraClass={classes.button}
                onClick={() => saveUserSessionPreference(true)}
              >
                Yes
              </Button>
            </DialogActions>
          </>
        )}
      </Modal>
    </div>
  );
};

export default InactivityTracker;
