import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";

// Actions
import {
  deleteTag,
  fetchTagList,
  showAlert,
  updateDeletedTagsFromDeviceInfo,
  editTag,
  updateTagInDeviceInfoDetails,
  fetchDeviceTagCount,
} from "@Store/actions";

// Components
import Button from "@Components/Button";
import ConfirmationDialog from "@Components/ConfirmationDialog";
import SVGWrapper from "@Components/SVGWrapper";
import InputField from "@Components/InputField";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import Tooltip from "@Components/Tooltip";

// Constants
import Constants from "@Constants/index";
import {
  ConfirmationDialogDeleteTag,
  LocalStorageKeyForRecentlyUsedLabel,
} from "@Constants/common";
import { ButtonIDs, ConfirmationDialogIds, InputFieldIds } from "@Constants/Id";

// Styles
import useStyles from "./styles";

type TagItemProps = {
  tag: string;
  taggedNumber?: number;
  id: string | number;
  isEditable: boolean;
  onSetEditableTagItem: (tag: string | null) => void;
  loading?: boolean;
};

const TagItem = ({
  tag,
  isEditable,
  onSetEditableTagItem,
  taggedNumber,
  loading = false,
}: TagItemProps) => {
  const textFieldRef = useRef<HTMLInputElement | null>();
  const dispatch = useDispatch();
  const appTheme = useTheme();

  const deleteTagState = useSelector((state: GlobalState) => state.deletedTag);
  const { tags } = useSelector((state: GlobalState) => state.tagList);
  const { loading: isTagLoading } = useSelector(
    (state: GlobalState) => state.deviceTagCount
  );

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isTagValid, setIsTagValid] = useState(true);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      [tag]: tag,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  /**
   * Triggered on deleting a tag item.
   * If not in edit mode open a confirmation dialog.
   * else toggle the edit mode.
   */
  const onDeleteTagHandler = () => {
    if (!isEditable) setIsConfirmationOpen(true);
    onSetEditableTagItem(null);
  };

  /**
   * Triggered on clicking the confirmation button in the dialog.
   */
  const onConfirmDeleteTagHandler = async () => {
    /**
     * The actions to be taken after a tag is deleted
     */
    const onDeleteTag = () => {
      dispatch(updateDeletedTagsFromDeviceInfo({ tags: [tag] }));

      // Removing the tag from recently used
      const jsonArray = JSON.parse(
        (localStorage.getItem(LocalStorageKeyForRecentlyUsedLabel) as string) ??
          "null"
      );
      if (Array.isArray(jsonArray) && jsonArray.length) {
        localStorage.setItem(
          LocalStorageKeyForRecentlyUsedLabel,
          JSON.stringify(jsonArray.filter((recent) => recent !== tag))
        );
      }
    };

    const { errorMessage } = await dispatch(
      deleteTag(tag, { sideEffect: onDeleteTag })
    );

    if (!errorMessage) {
      return;
    }

    setIsConfirmationOpen(false);
  };

  /**
   * Triggered on clicking the apply button on edit mode.
   * If updated tag is empty or same as tag, then no change happens and exits edit mode.
   * Else the api is being called with the id and updatedTag.
   */
  const handleEditTag = async () => {
    onSetEditableTagItem(null);
    const updatedTag = formik.values[tag].trim();
    if (updatedTag && updatedTag === tag) {
      formik.setFieldValue(tag, tag);
      return;
    }
    if (tags.includes(updatedTag)) {
      dispatch(
        showAlert({
          message: Constants.ErrorMessages.duplicateTagFound,
          severity: Constants.AlertSeverity.error,
        })
      );
      formik.setFieldValue(tag, tag);
      return;
    }
    const { errorMessage } = await dispatch(
      editTag({
        add: updatedTag,
        remove: tag,
      })
    );

    if (errorMessage) {
      return;
    }

    /**
     * Since we are storing the tags on adding them in the local storage.
     * We need to replace the tag which is edited with the new updated value.
     */
    const jsonArray = JSON.parse(
      (localStorage.getItem(LocalStorageKeyForRecentlyUsedLabel) as string) ??
        "null"
    );

    if (Array.isArray(jsonArray) && jsonArray.length) {
      localStorage.setItem(
        LocalStorageKeyForRecentlyUsedLabel,
        JSON.stringify(
          jsonArray.map((recent) => (recent === tag ? updatedTag : recent))
        )
      );
    }

    /**
     * Instead of relying on API call we are editing the tabs in the device info details manually.
     */
    dispatch(updateTagInDeviceInfoDetails({ add: updatedTag, remove: tag }));

    // Refetching the list of tags to update in the modal.
    const { response: updatedTagList } = await dispatch(fetchTagList());
    dispatch(fetchDeviceTagCount({ tags: updatedTagList }));
  };

  /**
   * To improve accessibility, on hitting enter when text field is focus will trigger the apply button
   * @param event - Keyboard Event
   */
  const onKeyPressHandler = (event: React.KeyboardEvent<HTMLDivElement>) =>
    !!(event.code === "Enter") && handleEditTag();

  /**
   * If on edit mode, the focus automatically goes to the textfield.
   */
  useEffect(() => {
    textFieldRef.current?.focus();
  }, [isEditable]);

  /**
   * Since the payload `tag name` needs to satisfy some regex,
   * we are validating the entered input.
   * @param value - value required for matching the regex.
   */
  const validateInput = (value: string) => {
    const pattern = Constants.Regex.IsValidTag;
    if (!pattern.test(value) && value !== "") {
      setIsTagValid(false);
      return;
    }
    setIsTagValid(true);
  };

  return (
    <>
      <MenuItem className={classes.menuItem} disableTouchRipple>
        <form className="d-flex w-100" onSubmit={formik.handleSubmit}>
          <div className={`flex justify-between ${classes.menuContent}`}>
            {isEditable ? (
              <div className="d-flex align-center">
                <InputField
                  id={InputFieldIds.modalTagItem}
                  autoComplete="off"
                  type="text"
                  inputRef={textFieldRef}
                  name={tag}
                  errorMessage={!isTagValid}
                  variant="standard"
                  onKeyUp={onKeyPressHandler}
                  inputProps={{ className: classes.input }}
                  InputProps={{
                    endAdornment: !isTagValid && (
                      <InputAdornment
                        className={classes.warningAdornment}
                        position="end"
                      ></InputAdornment>
                    ),
                  }}
                  extraClass={classes.inputWrapper}
                  onChange={(e) => {
                    formik.handleChange(e);
                    validateInput(e.target.value.trim());
                  }}
                  value={formik.values[tag]}
                />
                {!isTagValid && (
                  <Tooltip
                    title={Constants.ErrorMessages.invalidTag}
                    arrow
                    backgroundColor={appTheme.newPalette.card.background}
                    textColor={appTheme.newPalette.text.primary.main}
                    tooltipChildren={
                      <div className={classes.warningAdornment}>
                        <SVGWrapper viewBox="0 0 10 10">
                          {Constants.SVGIcons.warning}
                        </SVGWrapper>
                      </div>
                    }
                  />
                )}
              </div>
            ) : loading ? (
              <SkeletonLoader width={175} />
            ) : (
              <Typography component="span" variant="body2">
                {tag}
              </Typography>
            )}
            {isTagLoading || loading ? (
              <SkeletonLoader width={105} />
            ) : (
              <div className={classes.tagCountWrapper}>
                <Typography component="span" variant="body2">
                  {`tagged ${taggedNumber} times`}
                </Typography>
              </div>
            )}
          </div>
          <div
            className={`flex justify-end ${classes.menuAction} ${
              !!loading && "disabled"
            }`}
          >
            {isEditable ? (
              <Button
                disabled={!formik.values[tag] || loading || !isTagValid}
                size="small"
                color="secondary"
                onClick={handleEditTag}
                extraClass={classes.button}
              >
                Apply
              </Button>
            ) : (
              <Button
                variant="text"
                extraClass="icon-btn"
                onClick={() => onSetEditableTagItem(tag)}
                disabled={loading}
                disableRipple
              >
                <SVGWrapper height={16} width={16} viewBox="0 0 16 16">
                  {Constants.SVGIcons.editIcon}
                </SVGWrapper>
              </Button>
            )}
            <Button
              id={ButtonIDs.cancelEditOrDeleteTag}
              variant="text"
              extraClass="icon-btn delete-btn"
              onClick={onDeleteTagHandler}
              disabled={deleteTagState.loading || loading}
            >
              <SVGWrapper
                height={16}
                width={16}
                viewBox={isEditable ? "0 0 24 24" : "0 0 16 16"}
              >
                {isEditable
                  ? Constants.SVGIcons.cross
                  : Constants.SVGIcons.deleteIcon}
              </SVGWrapper>
            </Button>
          </div>
        </form>
      </MenuItem>
      <ConfirmationDialog
        id={ConfirmationDialogIds.deleteTag}
        alertTitle={ConfirmationDialogDeleteTag.alertTitle}
        alertDescription={`${ConfirmationDialogDeleteTag.alertDescription(
          tag
        )}`}
        isOpen={isConfirmationOpen}
        handleClose={() => setIsConfirmationOpen(false)}
        confirmButtonText={ConfirmationDialogDeleteTag.confirmationButtonText}
        handleConfirmation={onConfirmDeleteTagHandler}
        disabledButtons={deleteTagState.loading}
      />
    </>
  );
};

export default TagItem;
