import TagItem from "../TagItem";

const TagListSkeletonLoader = () => (
  <>
    {[...Array(8).keys()].map((loader) => (
      <TagItem
        key={loader}
        id={loader}
        tag={`${loader}`}
        isEditable={false}
        onSetEditableTagItem={() => {}}
        loading={true}
        taggedNumber={0}
      />
    ))}
  </>
);

export default TagListSkeletonLoader;
