import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      padding: theme.pxToRem(35),
      borderRadius: theme.pxToRem(8),
      maxWidth: theme.pxToRem(450),
      textAlign: "center",
      background: theme.newPalette.modal.background,
      minWidth: theme.pxToRem(432),
    },
    "& .MuiBackdrop-root": {
      background: theme.newPalette.modal.backdropColor,
    },
  },

  dialogContent: {
    margin: `${theme.pxToRem(10)} 0 ${theme.pxToRem(20)}`,
    textAlign: "center",
  },

  alertDescription: {
    color: theme.newPalette.modal.secondaryColor,
    maxWidth: theme.pxToRem(275),
    margin: "0 auto",
  },

  alertTitle: {
    "& .MuiTypography-root": {
      ...theme.utils.mediumText(),
      textAlign: "center",
      color: theme.newPalette.modal.primaryColor,
    },
  },

  confirmButton: {
    marginLeft: `${theme.pxToRem(10)} !important`,
    backgroundColor: theme.newColors.red,
    padding: `${theme.pxToRem(12)} ${theme.pxToRem(40)}`,

    "&:hover": {
      backgroundColor: theme.newPalette.colorTransformOnHover(
        theme.newColors.red,
        0.1
      ),
    },
  },
}));

export default useStyles;
