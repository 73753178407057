import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  handleSetCurrentlyActivePageTab,
  handleSetCurrentlyActiveTab,
} from "@Store/actions";

// Constants
import {
  DashboardPageTabs,
  LeftMenuHiddenTabs,
  LeftMenuTabs,
  DeviceDetailsTabIndex,
  ThreatDetailsTabIndex,
  PagesWithTabs,
  SettingsPageTabs,
} from "@Constants/common";

/**
 * The type for the argument to be passed in action dispatcher for setting previously active tab for a particular page
 */
export type PreviouslyActivePage = PagesWithTabs;

export type Tab = {
  lastActivePageTab: string;
  currentlyActiveTab: string;
  currentlyActivePageTab?: string;
  replacePageTab: boolean;
  setActiveTab: (tab: LeftMenuTabs | LeftMenuHiddenTabs) => void;
  setActivePageTab: (
    tab:
      | DashboardPageTabs
      | DeviceDetailsTabIndex
      | ThreatDetailsTabIndex
      | SettingsPageTabs,
    replace?: boolean,
    blacklistParams?: string[]
  ) => void;
  blacklistedParams?: string[];
};

/**
 * useTab: hook to set and get tab details
 * @returns
 */
function useTab(): Tab {
  const tabDetails: TabState = useSelector(
    (state: GlobalState) => state.tabDetails
  );
  const dispatch = useDispatch();

  const setActiveTab = (tab: LeftMenuTabs | LeftMenuHiddenTabs) => {
    dispatch(handleSetCurrentlyActiveTab(tab));
  };

  const setActivePageTab = (
    tab:
      | DashboardPageTabs
      | DeviceDetailsTabIndex
      | ThreatDetailsTabIndex
      | SettingsPageTabs,
    replace?: boolean,
    blacklistParams?: string[]
  ) => {
    dispatch(
      handleSetCurrentlyActivePageTab(`${tab}`, replace, blacklistParams)
    );
  };

  return {
    lastActivePageTab: tabDetails.lastActivePageTab,
    currentlyActiveTab: tabDetails.currentlyActive,
    currentlyActivePageTab: tabDetails.currentlyActivePageTab,
    replacePageTab: tabDetails.replacePageTab,
    setActiveTab,
    setActivePageTab,
    blacklistedParams: tabDetails.blacklistParams,
  };
}

export default useTab;
