import { alpha, darken } from "@material-ui/core";
import { InformationalColors, themeColors } from "./themeColors";

const textColors = {
  primary: {
    light: themeColors.secondary.charcoal[800],
    main: themeColors.secondary.charcoal[900],
  },
  secondary: {
    light: themeColors.secondary.charcoal[500],
    main: themeColors.secondary.charcoal[600],
  },
};

const lightPalette = {
  text: textColors,
  appBg: themeColors.neutral[200],
  button: {
    containedDisabledBg: themeColors.grayscale[50],
    disabledBorder: themeColors.neutral[300],
  },
  sidebar: {
    background: themeColors.neutral[100],
    activeTabBg: themeColors.secondary.charcoal[100],
    colorActive: textColors.primary.main,
    colorInActive: textColors.secondary.main,
  },
  downloadAgent: {
    background: themeColors.secondary.charcoal[100],
    actionBackground: themeColors.primary.fieryOrange[200],
  },
  card: {
    background: themeColors.neutral[100],
    shadowColor: "#0e26800d",
  },
  table: {
    rowBgOnHover: themeColors.neutral[100],
    rowShadowColorOnHover: "#0E26800F",
    optionsPopoverBg: themeColors.neutral[100],
    optionsPopoverItemHoverBg: themeColors.neutral[200],
    headerColor: textColors.primary.light,
    color: textColors.primary.main,
  },
  radioInput: {
    optionHoverBg: themeColors.neutral[200],
    checkedRadioBg: themeColors.primary.fieryOrange[500],
    color: themeColors.neutral[500],
  },
  header: {
    tabsBg: themeColors.secondary.charcoal[200],
    activeTabBg: themeColors.neutral[100],
    activeTabColor: textColors.primary.main,
    inActiveTabColor: textColors.secondary.main,
    color: textColors.primary.main,
    fixedMobileHeaderBg: themeColors.neutral[100],
    fixedMobileHeaderShadow: themeColors.neutral[200],
  },
  barGraph: {
    emptyBarColor: themeColors.neutral[200],
    color: textColors.secondary.light,
    tooltipBg: themeColors.secondary.charcoal[900],
    tooltipTextPrimary: themeColors.neutral[100],
    tooltipTextSecondary: themeColors.neutral[300],
    tooltipBoxShadowColor: alpha("#000000", 0.12),
  },
  modal: {
    background: themeColors.neutral[100],
    primaryColor: textColors.primary.main,
    secondaryColor: textColors.secondary.main,
    cancelIconColor: themeColors.secondary.charcoal[600],
    backdropColor: alpha(themeColors.neutral[900], 0.4),
    cancelButtonBg: themeColors.neutral[200],
    titleBorderColor: themeColors.neutral[300],
  },
  popover: {
    background: "#FFFFFF",
    color: textColors.primary.main,
    shadowColor: "#0e26800d",
  },
  downloadAgentModal: {
    tabsBg: themeColors.secondary.charcoal[200],
    activeTabBg: themeColors.neutral[100],
    tabColor: textColors.secondary.main,
    activeTabColor: textColors.primary.light,
    installationTokenBg: themeColors.secondary.charcoal[100],
    installationTokenColor: themeColors.neutral[400],
    installationTokenBorderColor: themeColors.neutral[400],
    CopyIconColor: textColors.primary.light,
    generateTokenBtnBg: themeColors.neutral[200],
    generateTokenBtnColor: themeColors.neutral[400],
  },
  passwordPolicyTooltip: {
    background: themeColors.secondary.charcoal[900],
    progressEmptyColor: themeColors.neutral[300],
    progressLabelColor: themeColors.secondary.charcoal[800],
  },
  input: {
    color: textColors.secondary.main,
    placeholderColor: themeColors.neutral[400],
    activeBorderColor: themeColors.neutral[600],
    borderColor: themeColors.neutral[400],
  },
  select: {
    optionsPopoverBg: themeColors.neutral[100],
    optionsPopoverItemHoverBg: themeColors.neutral[200],
    multiSelectChipBg: themeColors.neutral[200],
    multiSelectChipTextColor: themeColors.neutral[600],
  },
  tabs: {
    bottomBorder: themeColors.neutral[300],
  },
  pageNotFound: {
    leftBackground: themeColors.neutral[200],
    rightBackground: themeColors.neutral[100],
  },
  filterAutoSuggestOption: {
    chipBg: themeColors.grayscale[25],
    helperTextColor: themeColors.grayscale[400],
  },
  filterAutoSuggest: {
    background: themeColors.neutral[100],
    border: themeColors.grayscale[50],
    borderActive: themeColors.grayscale[100],
    popoverBg: themeColors.neutral[100],
    optionsHoverBg: themeColors.grayscale[200],
  },
  sortDropdownGroup: {
    text: themeColors.grayscale[300],
    textActive: themeColors.neutral[900],
    background: themeColors.neutral[100],
    icon: themeColors.grayscale[100],
    iconActive: themeColors.grayscale[600],
    itemHover: themeColors.grayscale[25],
  },
  tagGroup: {
    background: themeColors.neutral[100],
    addTagText: themeColors.neutral[500],
    tagText: themeColors.neutral[900],
    deleteIcon: themeColors.neutral[300],
    chipHover: themeColors.neutral[300],
    undoElevationColor: themeColors.neutral[300],
  },
  tagItem: {
    tag: themeColors.secondary.charcoal[800],
    dangerColor: InformationalColors.error[500],
    focus: themeColors.neutral[200],
    disabled: themeColors.neutral[400],
  },
  manageTagModal: {
    background: themeColors.neutral[100],
    tag: themeColors.neutral[400],
    focus: themeColors.neutral[200],
    disabledButton: themeColors.neutral[200],
    disabledButtonBorder: themeColors.neutral[300],
  },
  labelPopover: {
    checkbox: themeColors.primary.fieryOrange[600],
    settings: themeColors.secondary.charcoal[600],
    text: themeColors.neutral[700],
    background: themeColors.neutral[100],
  },
  checkbox: {
    color: themeColors.primary.fieryOrange[600],
  },
  ...InformationalColors,
  colorTransformOnHover: darken,
};

export type PaletteType = {
  [Key in keyof typeof lightPalette]: typeof lightPalette[Key];
};

export default lightPalette;
