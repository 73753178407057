import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

// Components
import InputField from "@Components/InputField";
import PasswordWithPolicies from "@Components/PasswordWithPolicy";
import Button from "@Components/Button";

// Hooks
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Validation
import { updatePasswordFormValidation } from "@Helpers/validation";

// Actions
import { updateUserPassword } from "@Store/actions";

// Constants
import Constants from "@Constants";
import { ButtonIDs, InputFieldIds, SecurityIds } from "@Constants/Id";

// styles
import useStyles from "../profile/styles";

const Security = () => {
  const classes = useStyles();

  // Initialize hooks
  const dispatch = useDispatch();
  const { isIdpUser } = usePermissionFilter();

  // Get user details state from redux store
  const userDetails: UserDetailsStateNonPersisted = useSelector(
    (state: GlobalState) => state.userDetailsNonPersisted
  );

  // Handle update user password form
  const formik = useFormik({
    initialValues: {
      [Constants.FormFields.UpdatePassword.oldPassword.name]: "",
      [Constants.FormFields.UpdatePassword.newPassword.name]: "",
      [Constants.FormFields.UpdatePassword.confirmPassword.name]: "",
    },
    enableReinitialize: true,
    validationSchema: updatePasswordFormValidation,
    onSubmit: (values) =>
      dispatch(
        updateUserPassword({
          currentPassword:
            values[Constants.FormFields.UpdatePassword.oldPassword.name],
          newPassword:
            values[Constants.FormFields.UpdatePassword.newPassword.name],
        })
      ),
  });

  /**
   * Reset form when drawer is closed
   */
  useEffect(() => {
    return () => formik.resetForm();
  }, []);

  return isIdpUser ? (
    <Redirect to={Constants.AppRoutes.PublicRoutes.NOT_FOUND} />
  ) : (
    <form
      id={SecurityIds.passwordAndSecurityForm}
      onSubmit={formik.handleSubmit}
      className={classes.formWrapper}
    >
      <div className="margin-bottom-25">
        <InputField
          id={InputFieldIds.oldPassword}
          type="password"
          label={Constants.FormFields.UpdatePassword.oldPassword.label}
          name={Constants.FormFields.UpdatePassword.oldPassword.name}
          extraClass={classes.inputField}
          onChange={formik.handleChange}
          value={
            formik.values[Constants.FormFields.UpdatePassword.oldPassword.name]
          }
          errorMessage={
            formik.touched[
              Constants.FormFields.UpdatePassword.oldPassword.name
            ] &&
            formik.errors[Constants.FormFields.UpdatePassword.oldPassword.name]
          }
        />
      </div>
      <div className="margin-bottom-25">
        <PasswordWithPolicies
          id={InputFieldIds.newPassword}
          type="password"
          label={Constants.FormFields.UpdatePassword.newPassword.label}
          name={Constants.FormFields.UpdatePassword.newPassword.name}
          extraClass={classes.inputField}
          onChange={formik.handleChange}
          value={
            formik.values[Constants.FormFields.UpdatePassword.newPassword.name]
          }
          errorMessage={
            formik.touched[
              Constants.FormFields.UpdatePassword.newPassword.name
            ] &&
            formik.errors[Constants.FormFields.UpdatePassword.newPassword.name]
          }
        />
      </div>
      <div className="margin-bottom-25">
        <InputField
          id={InputFieldIds.confirmPassword}
          type="password"
          label={Constants.FormFields.UpdatePassword.confirmPassword.label}
          name={Constants.FormFields.UpdatePassword.confirmPassword.name}
          extraClass={classes.inputField}
          onChange={formik.handleChange}
          value={
            formik.values[
              Constants.FormFields.UpdatePassword.confirmPassword.name
            ]
          }
          errorMessage={
            formik.touched[
              Constants.FormFields.UpdatePassword.confirmPassword.name
            ] &&
            formik.errors[
              Constants.FormFields.UpdatePassword.confirmPassword.name
            ]
          }
        />
      </div>
      <div className="margin-bottom-25 text-align-right">
        <Button
          id={ButtonIDs.updatePassword}
          type="submit"
          disabled={userDetails.loading}
        >
          Update Password
        </Button>
      </div>
    </form>
  );
};

export default Security;
