import { Theme } from "@material-ui/core";

export const styles = (
  theme: Theme
): Record<string, Record<string, string>> => ({
  errorImage: {
    width: theme.pxToRem(258),
    padding: `${theme.pxToRem(121)} 0 ${theme.pxToRem(61)} 0 `,
  },
  errorText: {
    fontSize: theme.pxToRem(39),
    lineHeight: theme.pxToRem(47),
    color: theme.lightTheme.secondary.deepBlue,
  },
  button: {
    margin: `${theme.pxToRem(51)} 0 ${theme.pxToRem(121)} 0`,
    padding: `${theme.pxToRem(11)} ${theme.pxToRem(70)}`,
  },
});
