// Components
import Link from "@Components/Link";
import SVGWrapper from "@Components/SVGWrapper";

// Hooks
import useTab from "@Hooks/useTab";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Constants
import Constants from "@Constants";
import { LeftMenuTabs } from "@Constants/common";

// Utils
import Utils from "@Utils";

// Types
import { TabItemProps } from "./TabItem";

// Styles
import useStyles from "@Components/LeftMenu/LeftMenu.styles";
import { SVGIconIds } from "@Constants/Id";

/**
 * TabItem Component
 *
 * Renders respective TabItem if all permission/conditions are valid else returns undefined
 * @example Correct usage
 * ```ts
 * <TabItem tab={tab}/>
 * ```
 */
export const TabItem = ({ tab }: TabItemProps): JSX.Element | null => {
  const classes = useStyles();
  const { userHasTabAccess } = usePermissionFilter();
  const { selectedTenant } = useTenantAccess();
  const tabs = useTab();

  /**
   * Get tab item's route when tab id is passed in.
   * If the user is in the same location as the tab route returns an empty string.
   * else returns the route.
   * @param menuTab - tab id
   */
  const getTabItemRoute = (menuTab: LeftMenuTabs): string => {
    const route = Utils.getKeyByValue(Constants.TabRoutes, menuTab);
    return route && route !== location.pathname ? route : "";
  };

  // SVGWrapper component with styling for the menu icons
  const SVGIcon = ({
    icon,
    id,
  }: {
    icon: JSX.Element;
    id: string;
  }): JSX.Element => (
    <SVGWrapper width={23} height={18} viewBox="0 0 21 21" id={id}>
      {icon}
    </SVGWrapper>
  );

  // Contains icon component to be mapped with each tab label
  const TabIcons: Record<LeftMenuTabs, JSX.Element> = {
    dashboard: (
      <SVGIcon
        icon={Constants.SVGIcons.dashboardIcon}
        id={SVGIconIds.dashboardIcon}
      />
    ),
    devices: (
      <SVGIcon
        icon={Constants.SVGIcons.deviceIcon}
        id={SVGIconIds.deviceIcon}
      />
    ),
    events: (
      <SVGIcon
        icon={Constants.SVGIcons.calenderIcon}
        id={SVGIconIds.calenderIcon}
      />
    ),
    users: (
      <SVGIcon icon={Constants.SVGIcons.usersIcon} id={SVGIconIds.usersIcon} />
    ),
    tenants: (
      <SVGIcon icon={Constants.SVGIcons.homeIcon} id={SVGIconIds.homeIcon} />
    ),
    settings: (
      <SVGIcon
        icon={Constants.SVGIcons.settings}
        id={SVGIconIds.settingsIcon}
      />
    ),
  };

  const MenuLinks: JSX.Element = (
    <div
      className={`${classes.menuButton} ${
        tabs.currentlyActiveTab === tab.id ? classes.selected : ""
      } d-inline-flex menu-link-inner-content`}
    >
      <span className={classes.menuLinkIcon}>
        {TabIcons[tab.id as LeftMenuTabs]}
      </span>
      <span className={classes.menuLinkLabel}>{tab.label}</span>
    </div>
  );

  /**
   * If user has no access to the tab, does not renders the corresponding tab item.
   * TabItem returns null
   */
  if (!userHasTabAccess(tab.id as ModulesWithRbac)) return null;

  /**
   * If user has no permission to manage tenants, does not renders the tenants tab.
   * TabItem returns null
   */
  if (
    !selectedTenant.manageTenant &&
    getTabItemRoute(tab.id as LeftMenuTabs) ===
      Constants.AppRoutes.ProtectedRoutes.Tenants
  )
    return null;

  return (
    <div className="margin-bottom-10 w-100" key={tab.id}>
      {getTabItemRoute(tab.id as LeftMenuTabs) ? (
        <Link
          id={tab.id}
          link={getTabItemRoute(tab.id as LeftMenuTabs)}
          extraClass={classes.tabItemLink}
        >
          {MenuLinks}
        </Link>
      ) : (
        MenuLinks
      )}
    </div>
  );
};
