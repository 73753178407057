import { useHistory } from "react-router-dom";

export type History = {
  length: number;
  action: "REPLACE" | "POP" | "PUSH";
  location: {
    hash: string;
    pathname: string;
    state?: any;
    key?: string;
    search?: string;
  };
  push: (pathname: string, state?: Record<string, any>) => void;
  replace: (pathname: string, state?: Record<string, any>) => void;
  go: (n: number) => void;
  goBack: () => void;
  goForward: () => void;
  block: (prompt: string) => void;
};

/**
 * useCustomHistory: custom useCustomHistory() hook to be used in our app
 * @returns
 */
function useCustomHistory(): History {
  const history = useHistory();

  const push = (pathname: string, state?: Record<string, any>) => {
    history.push(pathname, state || history.location.state);
  };

  const replace = (pathname: string, state?: Record<string, any>) => {
    history.replace(pathname, state || history.location.state);
  };

  return {
    ...history,
    push,
    replace,
  };
}

export default useCustomHistory;
