import { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  deleteIdpConfig,
  deleteIdpConfigFromStore,
  fetchIdpConfig,
} from "@Store/actions";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";
import ConfirmationDialog from "@Components/ConfirmationDialog";
import AddOrEditProvider from "./SubComponents/AddOrEditProvider";
import { IdpConfigLoader } from "./SubComponents/IdpConfigLoader";
import ViewIdpDetails from "./SubComponents/ViewIdpDetails";

// Constants
import SVGIcons from "@Constants/icon";
import {
  ConfirmationDialogDeleteIDP,
  ErrorCodes,
  IDPDataKeyToHeaderMap,
  NameIdPolicyFormatValues,
} from "@Constants/common";
import { ButtonIDs, ConfirmationDialogIds, SVGIconIds } from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Styles
import useStyles from "./styles";

const IdentityProvider = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();

  const { data: idpConfigData, loading: isIdpConfigLoading } = useSelector(
    (state: GlobalState) => state.idpConfig
  );
  const { config: updatedConfig } = useSelector(
    (state: GlobalState) => state.updateIDPConfig
  );
  const { config: createdConfig } = useSelector(
    (state: GlobalState) => state.createIDPConfig
  );

  const { loading: deleteInProgress } = useSelector(
    (state: GlobalState) => state.deleteIDPConfig
  );

  const [isIdpConfigAvailable, setIsIdpConfigAvailable] = useState(true);
  const [isAddOrEditProviderModalOpen, setIsAddOrEditProviderModalOpen] =
    useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [editFormData, setEditFormData] = useState<Record<
    string,
    string
  > | null>(null);

  // Fetch the configuration of the external identity provider
  useEffect(() => {
    setIsIdpConfigAvailable(true);
    dispatch(
      fetchIdpConfig(selectedTenant.selectedTenantId, {
        sideEffect: (_apiError, statusCode) =>
          statusCode === ErrorCodes.notFound && setIsIdpConfigAvailable(false),
        /**
         * Don't show the error alert when we get 404 error code as the UI will change accordingly
         * - It'll show the UI for creating the configuration
         */
        skipErrorReporting: (_apiError, statusCode) =>
          statusCode === ErrorCodes.notFound,
      })
    );
  }, [updatedConfig, createdConfig, selectedTenant.selectedTenantId]);

  const handleDeleteIdpConfig = () => {
    /**
     * Actions to be taken after the IdP configuration is deleted
     */
    const onDeleteIdpConfig = () => {
      dispatch(deleteIdpConfigFromStore());
      /**
       * Since we are using edit form state to populate the field when we are in edit mode.
       * These fields need to be cleared, or else when we add a new config the old deleted config values will get populated in the form.
       */
      setEditFormData(null);
      setIsIdpConfigAvailable(false);
      setIsDeleteConfirmationModalOpen(false);
    };

    // Delete the Idp config
    dispatch(
      deleteIdpConfig(
        { tenantID: selectedTenant.selectedTenantId },
        { sideEffect: onDeleteIdpConfig }
      )
    );
  };

  /**
   * Handles comparing two keys of the provider details to sort the details based on keys
   * @param configValue1 the 1st key/value pair of the provider details
   * @param configValue2 the 2nd key/value pair of the provider details
   * @returns either 1, -1, or 0
   */
  const compareIdpDetailKeys = (
    configValue1: string[],
    configValue2: string[]
  ) =>
    configValue1[0].toLowerCase().localeCompare(configValue2[0].toLowerCase());

  /**
   * Handles setting the default values and opening the modal when the edit button is clicked
   */
  const handleEditIdpConfig = () => {
    setEditFormData(idpConfigData);
    setIsAddOrEditProviderModalOpen(true);
  };

  /**
   * Returns the label for NameIdPolicyFormat values
   * @param value the value for NameIdPolicyFormat
   * @returns the label
   */
  const renderLabelIfPresent = (value: string) =>
    NameIdPolicyFormatValues.find(
      ({ value: currentValue }) => value === currentValue
    )?.label;

  return (
    <>
      <div className={classes.identityProviderWrapper}>
        <Box className="d-flex align-center justify-between">
          <Typography variant="subtitle2">Identity Provider</Typography>
          <ViewIdpDetails />
        </Box>
        {isIdpConfigAvailable ? (
          <Box className={classes.detailsCard}>
            {isIdpConfigLoading ? (
              <IdpConfigLoader />
            ) : (
              Object.entries(idpConfigData)
                .sort((configValue1, configValue2) =>
                  compareIdpDetailKeys(configValue1, configValue2)
                )
                .map(
                  ([key, value]) =>
                    IDPDataKeyToHeaderMap[key] &&
                    idpConfigData[key] && (
                      <Box
                        key={key}
                        className={`d-flex align-center ${classes.detailsRow}`}
                      >
                        <Typography
                          className={`${classes.detailsHeader} wt-600`}
                          variant="body2"
                        >
                          {IDPDataKeyToHeaderMap[key]}
                        </Typography>
                        <Typography>
                          {renderLabelIfPresent(value) || value}
                        </Typography>
                      </Box>
                    )
                )
            )}
            {!isIdpConfigLoading && (
              <Box className="d-flex flex-gap-6 margin-top-25">
                <Button
                  id={ButtonIDs.editIdentityProvider}
                  variant="outlined"
                  extraClass={classes.editButton}
                  onClick={handleEditIdpConfig}
                >
                  Edit
                </Button>
                <Button
                  id={ButtonIDs.deleteIdentityProvider}
                  extraClass={classes.deleteButton}
                  onClick={() => setIsDeleteConfirmationModalOpen(true)}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Button
            id={ButtonIDs.addIdentityProvider}
            extraClass={classes.addProviderButton}
            startIcon={SVGIcons.add}
            onClick={() => setIsAddOrEditProviderModalOpen(true)}
          >
            <SVGWrapper
              height={25}
              width={25}
              viewBox="0 0 18 18"
              id={SVGIconIds.addIcon}
            >
              {SVGIcons.add}
            </SVGWrapper>
            Add an Identity Provider
          </Button>
        )}
        <AddOrEditProvider
          isOpen={isAddOrEditProviderModalOpen}
          setIsOpen={setIsAddOrEditProviderModalOpen}
          formData={editFormData}
        />
      </div>
      <ConfirmationDialog
        id={ConfirmationDialogIds.deleteIdpConfig}
        alertTitle={ConfirmationDialogDeleteIDP.title}
        isOpen={isDeleteConfirmationModalOpen}
        handleClose={() => setIsDeleteConfirmationModalOpen(false)}
        handleConfirmation={handleDeleteIdpConfig}
        confirmButtonText={ConfirmationDialogDeleteIDP.confirmationDialogText}
        disabledButtons={deleteInProgress}
      />
    </>
  );
};

export default IdentityProvider;
