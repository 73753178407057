// All actions are defined here.

export enum ActionTypes {
  // Login
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILED = "LOGIN_FAILED",

  // Logout
  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILED = "LOGOUT_FAILED",

  // Fetch Events
  FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST",
  FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_FAILED = "FETCH_EVENTS_FAILED",

  // Fetch Critical Events
  FETCH_CRITICAL_EVENTS_REQUEST = "FETCH_CRITICAL_EVENTS_REQUEST",
  FETCH_CRITICAL_EVENTS_SUCCESS = "FETCH_CRITICAL_EVENTS_SUCCESS",
  FETCH_CRITICAL_EVENTS_FAILED = "FETCH_CRITICAL_EVENTS_FAILED",

  // Fetch Devices
  FETCH_DEVICES_REQUEST = "FETCH_DEVICES_REQUEST",
  FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS",
  FETCH_DEVICES_FAILED = "FETCH_DEVICES_FAILED",

  // User details
  FETCH_USER_DETAILS_REQUEST = "FETCH_USER_DETAILS_REQUEST",
  FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS",
  FETCH_USER_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED",

  // User details in harbinger setup
  HARBINGER_FETCH_USER_DETAILS_SUCCESS = "HARBINGER_FETCH_USER_DETAILS_SUCCESS",

  // Update user details
  UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST",
  UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED",

  // Update user password
  UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILED = "UPDATE_USER_PASSWORD_FAILED",

  // Alert
  SHOW_ALERT = "SHOW_ALERT",
  CLOSE_ALERT = "CLOSE_ALERT",

  // Events for device
  FETCH_EVENTS_FOR_DEVICE_OR_THREAT_REQUEST = "FETCH_EVENTS_FOR_OR_THREAT_DEVICE_REQUEST",
  FETCH_EVENTS_FOR_DEVICE_OR_THREAT_SUCCESS = "FETCH_EVENTS_FOR_OR_THREAT_DEVICE_SUCCESS",
  FETCH_EVENTS_FOR_DEVICE_OR_THREAT_FAILED = "FETCH_EVENTS_FOR_OR_THREAT_DEVICE_FAILED",

  // Threat Details
  FETCH_THREAT_DETAILS_REQUEST = "FETCH_THREAT_DETAILS_REQUEST",
  FETCH_THREAT_DETAILS_SUCCESS = "FETCH_THREAT_DETAILS_SUCCESS",
  FETCH_THREAT_DETAILS_FAILED = "FETCH_THREAT_DETAILS_FAILED",

  // Event Details
  FETCH_EVENT_DETAILS_REQUEST = "FETCH_EVENT_DETAILS_REQUEST",
  FETCH_EVENT_DETAILS_SUCCESS = "FETCH_EVENT_DETAILS_SUCCESS",
  FETCH_EVENT_DETAILS_FAILED = "FETCH_EVENT_DETAILS_FAILED",

  // Refresh tokens
  REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED",

  // File Request
  REQUEST_FILE_REQUEST = "REQUEST_FILE_REQUEST",
  REQUEST_FILE_SUCCESS = "REQUEST_FILE_SUCCESS",
  REQUEST_FILE_FAILED = "REQUEST_FILE_FAILED",

  // Show/close drawer of add Tenant
  SHOW_ADD_TENANT_DRAWER = "SHOW_ADD_TENANT_DRAWER",
  CLOSE_ADD_TENANT_DRAWER = "CLOSE_ADD_TENANT_DRAWER",

  // Tabs
  SET_CURRENTLY_ACTIVE_TAB = "SET_CURRENTLY_ACTIVE_TAB",
  SET_CURRENTLY_ACTIVE_PAGE_TAB = "SET_CURRENTLY_ACTIVE_PAGE_TAB",
  SET_LAST_ACTIVE_PAGE_TAB = "SET_LAST_ACTIVE_PAGE_TAB",
  RESET_REPLACE_PAGE_TAB = "RESET_REPLACE_PAGE_TAB",
  RESET_BLACKLISTED_PARAMS = "RESET_BLACKLISTED_PARAMS",

  // Tenant users
  FETCH_TENANT_USERS_REQUEST = "FETCH_TENANT_USERS_REQUEST",
  FETCH_TENANT_USERS_SUCCESS = "FETCH_TENANT_USERS_SUCCESS",
  FETCH_TENANT_USERS_FAILED = "FETCH_TENANT_USERS_FAILED",

  // Add tenant users
  ADD_TENANT_USERS_REQUEST = "ADD_TENANT_USERS_REQUEST",
  ADD_TENANT_USERS_SUCCESS = "ADD_TENANT_USERS_SUCCESS",
  ADD_TENANT_USERS_FAILED = "ADD_TENANT_USERS_FAILED",

  // Delete a tenant user
  DELETE_TENANT_USER_REQUEST = "DELETE_TENANT_USER_REQUEST",
  DELETE_TENANT_USER_SUCCESS = "DELETE_TENANT_USER_SUCCESS",
  DELETE_TENANT_USER_FAILED = "DELETE_TENANT_USER_FAILED",

  // Agent installer
  FETCH_AGENT_INSTALLER_DETAILS_REQUEST = "FETCH_AGENT_INSTALLER_DETAILS_REQUEST",
  FETCH_AGENT_INSTALLER_DETAILS_SUCCESS = "FETCH_AGENT_INSTALLER_DETAILS_SUCCESS",
  FETCH_AGENT_INSTALLER_DETAILS_FAILED = "FETCH_AGENT_INSTALLER_DETAILS_FAILED",

  // Generate new agent installer token
  GENERATE_NEW_AGENT_INSTALLER_TOKEN_REQUEST = "GENERATE_NEW_AGENT_INSTALLER_TOKEN_REQUEST",
  GENERATE_NEW_AGENT_INSTALLER_TOKEN_SUCCESS = "GENERATE_NEW_AGENT_INSTALLER_TOKEN_SUCCESS",
  GENERATE_NEW_AGENT_INSTALLER_TOKEN_FAILED = "GENERATE_NEW_AGENT_INSTALLER_TOKEN_FAILED",

  // Device info details
  FETCH_DEVICE_INFO_DETAILS_REQUEST = "FETCH_DEVICE_INFO_DETAILS_REQUEST",
  FETCH_DEVICE_INFO_DETAILS_SUCCESS = "FETCH_DEVICE_INFO_DETAILS_SUCCESS",
  FETCH_DEVICE_INFO_DETAILS_FAILED = "FETCH_DEVICE_INFO_DETAILS_FAILED",
  RESET_DEVICE_INFO_DETAILS = "RESET_DEVICE_INFO_DETAILS",

  // Event metrics for dashboard
  FETCH_EVENT_METRICS_FAILED = "FETCH_EVENT_METRICS_FAILED",
  FETCH_EVENT_METRICS_REQUEST = "FETCH_EVENT_METRICS_REQUEST",
  FETCH_EVENT_METRICS_SUCCESS = "FETCH_EVENT_METRICS_SUCCESS",

  // Device Metrics
  FETCH_DEVICE_METRICS_REQUEST = "FETCH_DEVICE_METRICS_REQUEST",
  FETCH_DEVICE_METRICS_SUCCESS = "FETCH_DEVICE_METRICS_SUCCESS",
  FETCH_DEVICE_METRICS_FAILED = "FETCH_DEVICE_METRICS_FAILED",

  //Event Metrics By Month
  FETCH_EVENT_METRICS_BY_MONTH_REQUEST = "FETCH_EVENT_METRICS_BY_MONTH_REQUEST",
  FETCH_EVENT_METRICS_BY_MONTH_SUCCESS = "FETCH_EVENT_METRICS_BY_MONTH_SUCCESS",
  FETCH_EVENT_METRICS_BY_MONTH_FAILED = "FETCH_EVENT_METRICS_BY_MONTH_FAILED",

  //Download threat
  FETCH_THREAT_DOWNLOAD_REQUEST = "FETCH_THREAT_DOWNLOAD_REQUEST",
  FETCH_THREAT_DOWNLOAD_SUCCESS = "FETCH_THREAT_DOWNLOAD_SUCCESS",
  FETCH_THREAT_DOWNLOAD_FAILED = "FETCH_THREAT_DOWNLOAD_FAILED",

  // Unblock event action
  UNBLOCK_EVENT_ACTION_REQUEST = "UNBLOCK_EVENT_ACTION_REQUEST",
  UNBLOCK_EVENT_ACTION_SUCCESS = "UNBLOCK_EVENT_ACTION_SUCCESS",
  UNBLOCK_EVENT_ACTION_FAILED = "UNBLOCK_EVENT_ACTION_FAILED",

  // Extracted key information actions
  FETCH_EXTRACTED_KEY_INFO_REQUEST = "FETCH_EXTRACTED_KEY_INFO_REQUEST",
  FETCH_EXTRACTED_KEY_INFO_SUCCESS = "FETCH_EXTRACTED_KEY_INFO_SUCCESS",
  FETCH_EXTRACTED_KEY_INFO_FAILED = "FETCH_EXTRACTED_KEY_INFO_FAILED",
  CLEAR_EXTRACTED_KEY_INFO_STATE = "CLEAR_EXTRACTED_KEY_INFO_STATE",

  // Device extracted keys action
  FETCH_DEVICE_EXTRACTED_KEYS_REQUEST = "FETCH_DEVICE_EXTRACTED_KEYS_REQUEST",
  FETCH_DEVICE_EXTRACTED_KEYS_SUCCESS = "FETCH_DEVICE_EXTRACTED_KEYS_SUCCESS",
  FETCH_DEVICE_EXTRACTED_KEYS_FAILED = "FETCH_DEVICE_EXTRACTED_KEYS_FAILED",

  // Action for changing the theme
  CHANGE_THEME = "CHANGE_THEME",

  // Partitioned event metrics action
  FETCH_PARTITIONED_EVENT_METRICS_REQUEST = "FETCH_PARTITIONED_EVENT_METRICS_REQUEST",
  FETCH_PARTITIONED_EVENT_METRICS_SUCCESS = "FETCH_PARTITIONED_EVENT_METRICS_SUCCESS",
  FETCH_PARTITIONED_EVENT_METRICS_FAILED = "FETCH_PARTITIONED_EVENT_METRICS_FAILED",

  // Sidebar actions
  OPEN_SIDEBAR = "OPEN_SIDEBAR",
  CLOSE_SIDEBAR = "CLOSE_SIDEBAR",

  // Update selected tenant
  UPDATE_SELECTED_TENANT = "UPDATE_SELECTED_TENANT",

  // Fetch child tenant actions
  FETCH_CHILD_TENANTS_REQUEST = "FETCH_CHILD_TENANTS_REQUEST",
  FETCH_CHILD_TENANTS_SUCCESS = "FETCH_CHILD_TENANTS_SUCCESS",
  FETCH_CHILD_TENANTS_FAILED = "FETCH_CHILD_TENANTS_FAILED",
  ADD_OR_UPDATE_CHILD_TENANT_LIST = "ADD_OR_UPDATE_CHILD_TENANT_LIST",

  // Add or Edit tenant actions
  ADD_OR_EDIT_TENANT_REQUEST = "ADD_OR_EDIT_TENANT_REQUEST",
  ADD_OR_EDIT_TENANT_SUCCESS = "ADD_OR_EDIT_TENANT_SUCCESS",
  ADD_OR_EDIT_TENANT_FAILED = "ADD_OR_EDIT_TENANT_FAILED",

  // delete child tenant actions
  DELETE_CHILD_TENANTS_REQUEST = "DELETE_CHILD_TENANTS_REQUEST",
  DELETE_CHILD_TENANTS_SUCCESS = "DELETE_CHILD_TENANTS_SUCCESS",
  DELETE_CHILD_TENANTS_FAILED = "DELETE_CHILD_TENANTS_FAILED",

  // Get current User's tenant info
  GET_CURRENT_TENANT_INFO_REQUEST = "GET_CURRENT_TENANT_INFO_REQUEST",
  GET_CURRENT_TENANT_INFO_SUCCESS = "GET_CURRENT_TENANT_INFO_SUCCESS",
  GET_CURRENT_TENANT_INFO_FAILED = "GET_CURRENT_TENANT_INFO_FAILED",

  // Delete device actions
  DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST",
  DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_FAILED = "DELETE_DEVICE_FAILED",
  ERASE_DEVICE_DELETED_STATE = "ERASE_DEVICE_DELETED_STATE",

  // Actions for fetching Export Configurations
  FETCH_EXPORT_CONFIG_REQUEST = "FETCH_EXPORT_CONFIG_REQUEST",
  FETCH_EXPORT_CONFIG_SUCCESS = "FETCH_EXPORT_CONFIG_SUCCESS",
  FETCH_EXPORT_CONFIG_FAILED = "FETCH_EXPORT_CONFIG_FAILED",

  // Actions for updating an export config
  ADD_OR_UPDATE_EXPORT_CONFIG_REQUEST = "ADD_OR_UPDATE_EXPORT_CONFIG_REQUEST",
  ADD_OR_UPDATE_EXPORT_CONFIG_SUCCESS = "ADD_OR_UPDATE_EXPORT_CONFIG_SUCCESS",
  ADD_OR_UPDATE_EXPORT_CONFIG_FAILED = "ADD_OR_UPDATE_EXPORT_CONFIG_FAILED",

  // Actions for deleting an export config
  DELETE_EXPORT_CONFIG_REQUEST = "DELETE_EXPORT_CONFIG_REQUEST",
  DELETE_EXPORT_CONFIG_SUCCESS = "DELETE_EXPORT_CONFIG_SUCCESS",
  DELETE_EXPORT_CONFIG_FAILED = "DELETE_EXPORT_CONFIG_FAILED",

  // Actions for user activity
  USER_ACTIVE = "USER_ACTIVE",
  CLEAR_USER_ACTIVITY = "CLEAR_USER_ACTIVITY",

  // Actions for User session preference
  ADD_SESSION_PREFERENCE = "ADD_SESSION_PREFERENCE",

  // Fetch user roles
  FETCH_USER_ROLES_REQUEST = "FETCH_USER_ROLES_REQUEST",
  FETCH_USER_ROLES_SUCCESS = "FETCH_USER_ROLES_SUCCESS",
  FETCH_USER_ROLES_FAILED = "FETCH_USER_ROLES_FAILED",

  // Update user's access level
  UPDATE_ACCESS_LEVEL = "UPDATE_ACCESS_LEVEL",

  // Actions for handling selection of the table rows
  ADD_ROW = "ADD_ROW",
  REMOVE_ROW = "REMOVE_ROW",
  SELECT_GIVEN_ROWS = "SELECT_GIVEN_ROWS",
  RESET_SELECTED_ROWS = "RESET_SELECTED_ROWS",
  SELECT_ALL_PAGES = "SELECT_ALL_PAGES",
  RESET_ALL_SELECTED_PAGES = "RESET_ALL_SELECTED_PAGES",

  // Add tag to devices
  ADD_TAGS_TO_DEVICES_REQUEST = "ADD_TAGS_TO_DEVICES_REQUEST",
  ADD_TAGS_TO_DEVICES_SUCCESS = "ADD_TAGS_TO_DEVICES_SUCCESS",
  ADD_TAGS_TO_DEVICES_FAILED = "ADD_TAGS_TO_DEVICES_FAILED",
  ADD_TAGS_TO_DEVICES_STATE_RESET = "ADD_TAGS_TO_DEVICES_STATE_RESET",

  // Action for concatenating the previously applied tags to the device info details state
  ADD_TAGS_TO_DEVICE_INFO_DETAILS = "ADD_TAGS_TO_DEVICE_INFO_DETAILS",

  // Delete tags from a device
  DELETE_TAGS_FROM_DEVICES_REQUEST = "DELETE_TAGS_FROM_DEVICES_REQUEST",
  DELETE_TAGS_FROM_DEVICES_SUCCESS = "DELETE_TAGS_FROM_DEVICES_SUCCESS",
  DELETE_TAGS_FROM_DEVICES_FAILED = "DELETE_TAGS_FROM_DEVICES_FAILED",

  // Actions for creating a tag
  CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST",
  CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILED = "CREATE_TAG_FAILED",

  // Actions for deleting a tag
  DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST",
  DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILED = "DELETE_TAG_FAILED",
  RESET_DELETE_TAG_STATE = "RESET_DELETE_TAG_STATE",

  // Actions for updating/editing a tag
  UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST",
  UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAILED = "UPDATE_TAG_FAILED",

  // Actions for fetching the device tag count
  FETCH_DEVICE_TAG_COUNT_REQUEST = "FETCH_DEVICE_TAG_COUNT_REQUEST",
  FETCH_DEVICE_TAG_COUNT_SUCCESS = "FETCH_DEVICE_TAG_COUNT_SUCCESS",
  FETCH_DEVICE_TAG_COUNT_FAILED = "FETCH_DEVICE_TAG_COUNT_FAILED",

  // Action for updating the tag in device info details state
  UPDATE_TAG_IN_DEVICE_INFO_DETAILS = "UPDATE_TAG_IN_DEVICE_INFO_DETAILS",

  // Action for removing the deleted tag from the device info details state
  DELETE_TAGS_FROM_DEVICE_INFO_DETAILS = "DELETE_TAGS_FROM_DEVICE_INFO_DETAILS",

  // List tags
  FETCH_TAG_LIST_REQUEST = "FETCH_TAG_LIST_REQUEST",
  FETCH_TAG_LIST_SUCCESS = "FETCH_TAG_LIST_SUCCESS",
  FETCH_TAG_LIST_FAILED = "FETCH_TAG_LIST_FAILED",

  //
  TAG_ALL_DEVICE_PAGES_REQUEST = "TAG_ALL_DEVICE_PAGES_REQUEST",
  TAG_ALL_DEVICE_PAGES_SUCCESS = "TAG_ALL_DEVICE_PAGES_SUCCESS",
  TAG_ALL_DEVICE_PAGES_FAILED = "TAG_ALL_DEVICE_PAGES_FAILED",

  // Action for setting last deleted tag
  SET_LAST_DELETED_TAG = "SET_LAST_DELETED_TAG",

  // Reset last deleted tag
  RESET_LAST_DELETED_TAG = "RESET_LAST_DELETED_TAG",

  // Actions for updating the role of a tenant user
  UPDATE_TENANT_USER_ROLE_REQUEST = "UPDATE_TENANT_USER_ROLE_REQUEST",
  UPDATE_TENANT_USER_ROLE_SUCCESS = "UPDATE_TENANT_USER_ROLE_SUCCESS",
  UPDATE_TENANT_USER_ROLE_FAILED = "UPDATE_TENANT_USER_ROLE_FAILED",

  // Actions for fetching the role of a tenant user
  FETCH_TENANT_USER_ROLE_REQUEST = "FETCH_TENANT_USER_ROLE_REQUEST",
  FETCH_TENANT_USER_ROLE_SUCCESS = "FETCH_TENANT_USER_ROLE_SUCCESS",
  FETCH_TENANT_USER_ROLE_FAILED = "FETCH_TENANT_USER_ROLE_FAILED",

  // Fetch User by user id
  FETCH_TENANT_USER_BY_ID_REQUEST = "FETCH_TENANT_USER_BY_ID_REQUEST",
  FETCH_TENANT_USER_BY_ID_SUCCESS = "FETCH_TENANT_USER_BY_ID_SUCCESS",
  FETCH_TENANT_USER_BY_ID_FAILED = "FETCH_TENANT_USER_BY_ID_FAILED",

  // Actions for fetching the configuration of external identity provider
  FETCH_EXTERNAL_IDP_CONFIG_REQUEST = "FETCH_EXTERNAL_IDP_CONFIG_REQUEST",
  FETCH_EXTERNAL_IDP_CONFIG_SUCCESS = "FETCH_EXTERNAL_IDP_CONFIG_SUCCESS",
  FETCH_EXTERNAL_IDP_CONFIG_FAILED = "FETCH_EXTERNAL_IDP_CONFIG_FAILED",

  // Action for creating IDP config
  CREATE_IDP_CONFIG_REQUEST = "CREATE_IDP_CONFIG_REQUEST",
  CREATE_IDP_CONFIG_SUCCESS = "CREATE_IDP_CONFIG_SUCCESS",
  CREATE_IDP_CONFIG_FAILED = "CREATE_IDP_CONFIG_FAILED",

  // Action for updating IDP config
  UPDATE_IDP_CONFIG_REQUEST = "UPDATE_IDP_CONFIG_REQUEST",
  UPDATE_IDP_CONFIG_SUCCESS = "UPDATE_IDP_CONFIG_SUCCESS",
  UPDATE_IDP_CONFIG_FAILED = "UPDATE_IDP_CONFIG_FAILED",

  // Action for deleting IDP from a tenant
  DELETE_IDP_CONFIG_REQUEST = "DELETE_IDP_CONFIG_REQUEST",
  DELETE_IDP_CONFIG_SUCCESS = "DELETE_IDP_CONFIG_SUCCESS",
  DELETE_IDP_CONFIG_FAILED = "DELETE_IDP_CONFIG_FAILED",

  // Action to reset IDP config from the store
  DELETE_IDP_CONFIG_FROM_STORE = "DELETE_IDP_CONFIG_FROM_STORE",
}

export default ActionTypes;
