import { Dispatch, SetStateAction } from "react";
import moment from "moment-timezone";
import { generatePath } from "react-router-dom";
import { parse } from "url";
import { OidcQueryParams } from "@Constants/common";

// Actions
import { showAlert } from "@Store/actions";

// Constants
import Constants from "@Constants";
import {
  EventActionTypes,
  PageSizeConfigs,
  StringFormatters,
} from "@Constants/common";
import {
  AllowedActionTypeValues,
  EventsSearchParams,
  FieldNameForSearchField,
  tableSearchErrorMessages,
  DevicesInitSearchConfig,
} from "@Constants/tableSearchConst";

// Theme colors
import { themeColors } from "@Theme/themeColors";

/**
 * Checks if a key exists in an object from its value.
 *
 * @param object { [key: string]: string }
 * @param value string
 * @returns boolean
 */
const getKeyByValue = (
  object: { [key: string]: string },
  value: string
): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

/**
 * Get first letter of each word in a string
 * @param str string
 * @returns first letter of each word in a string
 */
const getFirstLetterOfWordsInString = (str: string): string => {
  const matches = str.match(/\b(\w)/g);
  return matches ? matches.join("") : "";
};

/**
 * Returns a string made form given url and querryparams.
 * @param url: string of orignial plane url prefer appending / at end
 * @param queryParams: an object of keys and values as querry params
 * @returns string
 */
const getURLFromQueryParams = (
  url: string,
  queryParams: {
    [x: string]: string | number | boolean | undefined;
  }
): string => {
  const str = [];
  for (const p in queryParams)
    if (queryParams.hasOwnProperty(p) && Boolean(queryParams[p])) {
      str.push(
        encodeURIComponent(p) +
          "=" +
          encodeURIComponent(queryParams[p] as string | number | boolean)
      );
    }
  if (str.length < 1) {
    return url;
  }
  return url + "?" + str.join("&");
};

/**
 * Function to generate new url by handling existing query params and current url
 * @param newPath: New url's path
 * @param searchString: current url's search param which should be passed along
 * @param queryParams: new query params
 * @param next: value for next query param
 */
const getNewPath = (
  newPath: string,
  searchString: string,
  queryParams: { [key: string]: any },
  next?: string | undefined
): any => {
  let searchText = "";
  // Handling existing query params with passes query params
  const query = new URLSearchParams(searchString);
  let searchQueries = queryParams;
  query.forEach((value, key) => {
    if (Object.keys(searchQueries).indexOf(key) === -1) {
      searchQueries[key] = value;
    }
  });
  // Handling next
  if (next && !query.get("next")) {
    searchQueries = {
      ...searchQueries,
      next,
    };
  }

  // Generating url with new query params
  if (searchQueries) {
    Object.keys(searchQueries).forEach((queryParamKey) => {
      const prefix = searchText === "" ? "?" : "&";
      const value = searchQueries[queryParamKey];
      if (value && value !== "null") {
        searchText = `${searchText}${prefix}${queryParamKey}=${value}`;
      }
    });
  }

  return {
    pathname: newPath,
    search: encodeURI(searchText),
  };
};

/**
 * Function to format date and time using moment package
 * @param dateTimeString: Time string
 * @param format: Standards Date time format
 */
const formatDateTime = (
  dateTimeString: string,
  format: string = Constants.DateTimeFormats.format1
): string => {
  if (dateTimeString) {
    return moment(new Date(dateTimeString)).format(format);
  }
  return "Invalid Date";
};

/**
 * @param currentPageNumber number
 * @param action "increase" | "decrease"
 * @returns changed page number
 */
const changePageNumber = (
  currentPageNumber: number,
  increase: boolean
): number => {
  if (increase) {
    return currentPageNumber + 1;
  } else if (currentPageNumber !== 1) {
    return currentPageNumber - 1;
  }

  return 1;
};

/**
 * Get page number from query param
 * @param queryParams URLSearchParams
 * @returns valid page number
 */
const getPageNumberFromQueryParams = (queryParams: URLSearchParams): number => {
  const page = Number(queryParams.get(Constants.QueryParams.page));

  return page > 1 ? page : 1;
};

/**
 * Get page size from query param
 * @param queryParams URLSearchParams
 * @returns valid page size
 */
const getPageSizeFromQueryParams = (queryParams: URLSearchParams): number => {
  const pageSize = Number(queryParams.get(PageSizeConfigs.pageSize));
  if (!isNaN(pageSize) && PageSizeConfigs.rowsPerPageSize.includes(pageSize)) {
    return pageSize;
  }
  return PageSizeConfigs.rowsPerPageSize[0];
};

/**
 * Get tab number from query param
 * @param queryParams URLSearchParams
 * @returns valid tab number
 */
const getTabNumberFromQueryParams = (queryParams: URLSearchParams): number => {
  const tab = Number(queryParams.get(Constants.QueryParams.tab));

  return tab ? tab : 0;
};

/**
 * Get tab from query param
 * @param queryParams URLSearchParams
 * @returns valid tabString or null
 */
const getTabStringFromQueryParams = (
  queryParams: URLSearchParams
): string | null =>
  queryParams.get(Constants.QueryParams.tab)
    ? String(queryParams.get(Constants.QueryParams.tab))
    : null;

/**
 * Get tab from query param
 * @param queryParams URLSearchParams
 * @returns {starTime?: string, endTime?: string};
 */
const getDateRangeFromQueryParam = (
  queryParams: URLSearchParams
): QueryParamDateRange => {
  const startTime = queryParams.get(Constants.QueryParams.startTime);
  const endTime = queryParams.get(Constants.QueryParams.endTime);
  if (startTime && endTime) return { startTime, endTime };
  else return {};
};

/**
 * Get attentionType from query param
 * @param queryParams URLSearchParams
 * @returns attentionType?: string;
 */
const getAttentionTypeFromQueryParams = (
  queryParams: URLSearchParams
): string | null => queryParams.get(Constants.QueryParams.attentionType);

/**
 * Check if page query param is valid
 * @param queryParams URLSearchParams
 * @returns boolean
 */
const isInvalidPageQueryParam = (queryParams: URLSearchParams): boolean => {
  const page = Number(queryParams.get(Constants.QueryParams.page));
  return page <= 0 || isNaN(page);
};

/**
 * Check if page size query param is valid
 * @param queryParams URLSearchParams
 * @returns boolean
 */
const isPageSizeQueryParamInvalid = (queryParams: URLSearchParams): boolean => {
  const pageSize = Number(queryParams.get(PageSizeConfigs.pageSize));
  return (
    pageSize <= 0 ||
    isNaN(pageSize) ||
    !PageSizeConfigs.rowsPerPageSize.includes(pageSize)
  );
};

/**
 * Check if tab query param is valid
 * @param queryParams URLSearchParams
 * @returns boolean
 */
const isInvalidTabQueryParam = (queryParams: URLSearchParams): boolean => {
  const tabString = queryParams.get(Constants.QueryParams.tab);
  return !tabString;
};

/**
 * Get value from nested object
 * @param data Record<string, {[key: string]: string}>
 * @param keyName string
 * @returns string
 */
const getDataFromNestedObject = (
  data: Record<string, Record<string, string>>,
  keyName: string
): string => {
  const keys = keyName.split(".");
  let object: Record<string, string> = {};
  for (const key of keys) {
    if (typeof data[key] === "object") {
      object = data[key];
    }
  }

  return object[keys[keys.length - 1]];
};

/**
 * Extract and return required pagination data from API pagination response
 * @param PaginationResponseFromAPI
 * @returns PaginationData
 */
const getPaginationData = (
  pagination: PaginationResponseFromAPI
): PaginationData => ({
  currentPageNumber: pagination?.page ?? pagination?.currentPage,
  lastPageNumber: pagination?.last_page ?? pagination?.totalPages,
  totalRowsAvailable: pagination?.total ?? pagination?.totalItems,
  totalPagesAvailable: pagination?.total_pages ?? pagination?.totalPages,
});

/**
 * Add comma(s) to numbers
 * @param string | number
 * @returns string
 */
const addCommasToNumber = (num: string | number): string => {
  return isNaN(Number(num))
    ? ""
    : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Check if an object is empty
 * @param obj Record<string, string>
 * @returns boolean
 */
const isObjectEmpty = (
  obj: Record<string, string | string[] | Record<string, string>>
): boolean => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 * Truncate string from start, middle or last
 * @param TruncateString
 * @returns string
 */
const getTruncatedString = ({
  value,
  noOfCharactersToDisplayFromStart,
  noOfCharactersToDisplayFromEnd,
  truncateFromBothStartAndEnd,
}: TruncateString): string => {
  const stringValue = typeof value !== "string" ? value.toString() : value;
  const stringValueLength = stringValue.length;

  let truncatedString = "";

  if (
    noOfCharactersToDisplayFromStart &&
    noOfCharactersToDisplayFromStart < stringValueLength - 1
  ) {
    truncatedString =
      stringValue.slice(0, noOfCharactersToDisplayFromStart) +
      Constants.StringFormatters.truncateString;
  }

  if (
    truncateFromBothStartAndEnd &&
    noOfCharactersToDisplayFromStart &&
    noOfCharactersToDisplayFromStart < stringValueLength - 1 &&
    noOfCharactersToDisplayFromEnd &&
    noOfCharactersToDisplayFromEnd < stringValueLength - 1
  ) {
    truncatedString += stringValue.slice(
      stringValueLength - 1 - noOfCharactersToDisplayFromEnd,
      stringValueLength
    );
  }

  if (
    !truncateFromBothStartAndEnd &&
    noOfCharactersToDisplayFromEnd &&
    noOfCharactersToDisplayFromEnd < stringValueLength - 1
  ) {
    truncatedString =
      Constants.StringFormatters.truncateString +
      stringValue.slice(
        stringValueLength - 1 - noOfCharactersToDisplayFromEnd,
        stringValueLength
      );
  }

  return truncatedString || stringValue;
};

/**
 * Convert location path to basepath
 * @param locationPath
 * @returns string
 */

const locationPathToBasePath = (locationPath: string): string => {
  if (locationPath) return `/${locationPath.split("/")[1]}`;
  return "";
};

/**
 * Get month name from month number
 * @param month
 * @param year
 * @returns string
 */

const getMonthNameFromNumber = (month: number, year: number): string => {
  const months: Record<number, string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  return `${months[month]} ${year}`;
};

const hasSpecialChar = (str: string): boolean =>
  Constants.Regex.HasSpecialChar.test(str);

const hasLowerCase = (str: string): boolean =>
  Constants.Regex.HasLowerCase.test(str);

const hasUpperCase = (str: string): boolean =>
  Constants.Regex.HasUpperCase.test(str);

const hasDigit = (str: string): boolean => Constants.Regex.HasDigit.test(str);

/**
 * @param headers TableHeaderConfig[]
 * @param data data from the API, expected type Record<string, any>[]
 * @param rowIdKeyInData The key of the record the value of which that can be (or is required) to act as rowId
 * @returns modified row-data of the type RowData[]
 */
const parseToRowDataType = (
  headers: TableHeaderConfig[],
  data?: Record<string, any>[],
  rowIdKeyInData?: string
): RowData[] => {
  const rowsData: RowData[] = [];

  data?.forEach((item) => {
    let rowData: RowData = {};
    let cellData: CellData;

    headers.map((header) => {
      if (header.isDataKeyNested) {
        cellData = { value: getDataFromNestedObject(item, header.name) };
      } else {
        cellData = { value: item[header.name] };
      }
      rowData = { ...rowData, [header.name]: cellData };
    });

    // if rowIdKeyInData is present, add rowId in the row
    if (rowIdKeyInData) {
      rowData = { ...rowData, rowId: item[rowIdKeyInData] };
    }

    // now adding remaining key value pairs which are part of data but were not part of headers
    Object.keys(item).map((dataKey) => {
      if (!rowData[dataKey]) {
        rowData = { ...rowData, [dataKey]: { value: item[dataKey] } };
      }
    });

    rowsData.push(rowData);
  });
  return rowsData;
};

/**
 *
 * @param headerName name of the header on whose cells onClick is to be applied
 * @param headersConfig existing config of the table-headers
 * @param onColumnCellClick onClick handler for clicking on the cell below this header
 * @param getRedirectUrl handler to get the redirect url for the link
 * @returns modified table-headers config
 */
const provideButtonOrLinkConfigToHeader = (
  headerName: string,
  headersConfig: TableHeaderConfig[],
  onColumnCellClick?: ((data: RowData) => void) | null,
  getRedirectUrl?: ((data: RowData) => string) | null
): TableHeaderConfig[] => {
  const modifiedHeadersConfig: TableHeaderConfig[] = [];
  headersConfig.map((header) => {
    if (header.name === headerName) {
      modifiedHeadersConfig.push({
        ...header,
        renderAsButton: onColumnCellClick ? { onColumnCellClick } : null,
        renderAsLink: getRedirectUrl ? { getRedirectUrl } : null,
      });
    } else modifiedHeadersConfig.push(header);
  });
  return modifiedHeadersConfig;
};

/**
 *
 * @param onClick onClick handler for clicking on the cell below this header
 * @param headerName name of the header on whose cells onClick is to be applied
 * @param headersConfig existing config of the table-headers
 * @returns modified table-headers config
 */
const provideClickHandlerToHeader = (
  onColumnCellClick: (data: RowData) => void,
  headerName: string,
  headersConfig: TableHeaderConfig[]
): TableHeaderConfig[] => {
  const modifiedHeadersConfig: TableHeaderConfig[] = [];
  headersConfig.map((header) => {
    if (header.name === headerName) {
      modifiedHeadersConfig.push({
        ...header,
        renderAsButton: { onColumnCellClick: onColumnCellClick },
      });
    } else modifiedHeadersConfig.push(header);
  });
  return modifiedHeadersConfig;
};

/**
 * @param getColor handler for getting the color code through cell-data
 * @param headerName name of the header on whose color is to be applied
 * @param headersConfig existing config of the table-headers
 * @returns modified table-headers config
 */
const provideColorHandlerToHeader = (
  getColor: (data: CellData) => string,
  headerName: string,
  headersConfig: TableHeaderConfig[]
): TableHeaderConfig[] => {
  const modifiedHeadersConfig: TableHeaderConfig[] = [];
  headersConfig.map((header) => {
    if (header.name === headerName) {
      modifiedHeadersConfig.push({ ...header, color: getColor });
    } else modifiedHeadersConfig.push(header);
  });
  return modifiedHeadersConfig;
};

/**
 * Transform the data(type = Record<string, string>) to an array of label value pairs with keys in the data as labels
 * @param data data with type Record<string, string>
 * @returns label-value pairs
 */
const getLabelValuePairsForTable = (
  data: Record<string, string>,
  labelConfigByFieldName: Record<string, Omit<TableHeaderConfig, "name">>
) => {
  const labelValuePairs: LabelValueTypeForTable[] = [];

  if (isObjectEmpty(data)) return [];

  Object.keys(data).map((dataKey) => {
    // if the key is not blacklisted and value associated with key is string, then add it to label value pair
    if (
      !labelConfigByFieldName[dataKey]?.blacklist &&
      typeof data[dataKey] === "string"
    )
      labelValuePairs.push({
        label: {
          ...labelConfigByFieldName[dataKey],
          name: dataKey,
          label: labelConfigByFieldName[dataKey]?.label || dataKey,
        },
        value: {
          value: data[dataKey],
        },
      });
  });

  return labelValuePairs;
};

/**
 * Transforms bytes into larger units
 * @param bytes number of bytes
 * @returns string in the format "number Bytes|KB|MB|GB|TB"
 */
const transformBytesToLargerUnits = (bytes: number): string => {
  let remainder = bytes;
  let unitIndex = 0;
  while (remainder >= 1000 && unitIndex < Constants.MemoryUnits.length - 1) {
    remainder /= 1000;
    unitIndex++;
  }
  return `${
    Math.floor(remainder) === remainder ? remainder : remainder.toFixed(1)
  } ${Constants.MemoryUnits[unitIndex]}`;
};

/**
 * Transforms storage-size into larger units
 * @param storage storage in the format "[size] [Bytes|KB|MB|GB|TB]"
 * @returns string in the format "[size] [Bytes|KB|MB|GB|TB]"
 */
const transformStorageSizeToLargerUnits = (storage: string): string => {
  // storage is assumed to have the format "[number] [Bytes|KB|MB|GB|TB]" or "[number]";
  const storageUnit = storage.split(" ")[1];
  // get storage-size, remove commas(if there are any) and convert to integer for further operations
  let storageSize = parseInt(
    storage.split(" ")[0]?.split(",")?.join("") || "0"
  );
  let unitIndex = Constants.MemoryUnits.indexOf(storageUnit);

  if (unitIndex === -1) unitIndex = 0; // if no unit is provided, consider "Bytes" as the default unit

  while (storageSize >= 1000 && unitIndex < Constants.MemoryUnits.length - 1) {
    storageSize /= 1000;
    unitIndex++;
  }
  return `${
    Math.floor(storageSize) === storageSize
      ? storageSize
      : storageSize.toFixed(1)
  } ${Constants.MemoryUnits[unitIndex]}`;
};

/**
 * Transform the cell-data with storage-size to larger units
 * @param data CellData
 * @param storageType "string"|"number"
 * @returns CellData with value attribute transformed
 */
const transformFileSizeCellData = (
  data: CellData,
  storageType: "string" | "number"
): CellData => {
  return {
    ...data,
    value:
      storageType === "string"
        ? transformStorageSizeToLargerUnits(data.value)
        : transformBytesToLargerUnits(parseInt(data.value)),
  };
};

/**
 * Converts month-index got from getMonth() to month-name
 * @param monthIndex index got from getMonth()
 * @returns month-name
 */
const monthNameByIndex = (monthIndex: number): string =>
  Constants.MonthNames[monthIndex];

/**
 * Converts month-name to index(0-11)
 * @param monthName full-name of the month (capitalized)
 * @returns index(0-11)
 */
const indexByMonthName = (monthName: string): number =>
  Constants.MonthNames.indexOf(monthName);

/**
 * Converts day-index got from getDay() to day-name
 * @param dayIndex index got from getDay()
 * @returns day-name
 */
const dayNameByIndex = (dayIndex: number): string =>
  Constants.DayNames[dayIndex];

/**
 * Converts day-name to index(0-6)
 * @param dayName full-name of the day (capitalized)
 * @returns index(0-6)
 */
const indexByDayName = (dayName: string): number =>
  Constants.DayNames.indexOf(dayName);

/**
 * Get year, month, date, day, hour, minutes from dateString
 * @param dateString a valid date-string
 * @returns \{year: number, month: string, date: string, day: string, hours: string, minutes: string};
 */
const getDetailsFromDateString = (dateString: string) => {
  const date = new Date(dateString);
  const appendZeroIfRequired = (num: number) => (num < 10 ? `0${num}` : num);
  const year = date.getFullYear();
  const month = monthNameByIndex(date.getMonth());
  const monthDate = appendZeroIfRequired(date.getDate());
  const day = dayNameByIndex(date.getDay());
  const hours = appendZeroIfRequired(date.getHours());
  const minutes = appendZeroIfRequired(date.getMinutes());
  return { year, month, date: monthDate, day, hours, minutes };
};

/**
 * get color for Action column of events table
 * @param cellData cellData of type CellData
 * @returns color of the cell
 */
const getEventActionColor = (cellData: CellData) =>
  cellData.value === EventActionTypes.block
    ? themeColors.primary.poppyRed[500]
    : "inherit";

/**
 * returns true if the page has scrollbar
 * @returns boolean
 */
const pageHasScrollbar = () => {
  const body = document.querySelector("body");
  const html = document.documentElement;
  const height = Math.max(
    body?.scrollHeight || 0,
    body?.offsetHeight || 0,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  return height > window.innerHeight + 1;
};

/**
 * Checks if URL returns Success
 * @param url string
 * @returns boolean
 */
const urlExists = async (url: string) => {
  try {
    const response = await fetch(url).then((res) => {
      return res.status !== Constants.ErrorCodes.notFound;
    });
    return response;
  } catch (err) {
    return false;
  }
};

/**
 * Returns the route where the user should be redirected after clicking on event name in the events table
 * @param data RowData
 * @returns url string
 */
const getEventDetailsUrlForTableCell = (data: RowData): string => {
  const eventId = (data.id as CellData)?.value;
  const threatId = (data.threat_id as CellData)?.value;

  return eventId && threatId
    ? generatePath(Constants.AppRoutes.ProtectedRoutes.EventDetails, {
        eventId,
        threatId,
      })
    : "";
};

/**
 * Returns the route where the user should be redirected after clicking on device name in the table cells
 * @param data RowData
 * @returns url string
 */
const getDeviceDetailsUrlForTableCell = (data: RowData): string =>
  generatePath(Constants.AppRoutes.ProtectedRoutes.DeviceDetails, {
    id: (data.id as CellData)?.value,
  });

/**
 * Get Label corresponding to a value in a labelValueType array
 * @param labelValuePairArray Array of LabelValueType objects
 * @param value Value whose corresponding label we need
 * @returns Label corresponding to given value or null
 */
const getLabelFromLabelValuePair = (
  labelValuePairArray: LabelValueType[],
  value: string
): string | null =>
  labelValuePairArray.find((labelValuePair) => labelValuePair.value === value)
    ?.label || null;

/**
 * Except protocol, domain name and port number,
 * replace the rest of the URL with ellipses(...)
 * @param url url string
 * @returns string "[protocol]://[hostName][portNumber]"
 */
const addEllipsesToUrl = (url: string) => {
  const { protocol, auth, host, path, hash } = parse(url);

  return (
    (protocol ? protocol + "//" : "") +
    (auth ? StringFormatters.hideString : "") +
    host +
    (path !== "/" || hash ? StringFormatters.hideString : "")
  );
};

/**
 * Returns the information in a record after parsing the search string of the FilterAutoSuggest component
 * @param searchString - the search string from the input field of FilterAutoSuggest component
 * @param fieldNames - the field names that can be there in the search string
 * @returns The parsed key-value pairs
 */
const parseSearchString = (
  searchString: string,
  fieldNames: string[],
  allowedValuesByFieldName?: Record<string, string[]>
): Record<string, string> => {
  let fuzzySearchString = searchString.trim();
  const searchStringToRecord: Record<string, string> = {};

  const startsWithFieldName = (str: string) =>
    fieldNames.some((fieldName) => str.startsWith(fieldName));

  while (fuzzySearchString.includes(":")) {
    // Extracting the field-name
    // The index at which the first fieldName ends
    const fieldEndsOn = fuzzySearchString.search(":");
    // Substring till the first colon in the search string, we'll use this substring to remove it from the search string after extracting the fieldName
    const substringTillColon = fuzzySearchString.substring(0, fieldEndsOn + 1);
    // Substring containing field name but without the colon
    const subStringsContainingFieldName = substringTillColon
      .substring(0, substringTillColon.length - 1)
      .trim();
    // If there are more than one words in the substring containing the field name, get the array of all those words
    const fieldNameStringSeparatedBySpaces =
      subStringsContainingFieldName.split(" ");
    // The last word in the array should be the fieldName(because it was nearest to colon)
    const fieldName =
      fieldNameStringSeparatedBySpaces[
        fieldNameStringSeparatedBySpaces.length - 1
      ].toLowerCase();

    // Remove the search string till the colon as the field name is now parsed
    fuzzySearchString = fuzzySearchString
      .replace(substringTillColon, "")
      .trim();

    // Now extracting the value
    const stringsSplitByQuotes = fuzzySearchString.split(`"`);
    const stringsSplitBySpaces = fuzzySearchString.split(" ");

    // The value was given between the quotes
    if (fuzzySearchString.startsWith(`"`) && stringsSplitByQuotes.length > 1) {
      // Add the field-name and value as key-value pair in the record if the field-name is valid one
      if (
        fieldNames.some(
          (validFieldName) => validFieldName.toLowerCase() === fieldName
        )
      ) {
        /**
         * Check if we have provided with set of permissible values for the current field
         * If yes, add value only if it is from the given set
         */
        if (
          (allowedValuesByFieldName?.[fieldName] &&
            allowedValuesByFieldName?.[fieldName]?.includes(
              stringsSplitByQuotes[1]
            )) ||
          !allowedValuesByFieldName?.[fieldName]
        ) {
          searchStringToRecord[fieldName] = stringsSplitByQuotes[1].trim();
        }
      }
      // Remove the extracted value
      stringsSplitByQuotes.splice(0, 2);
      // Update the remaining fuzzy string to be parsed
      fuzzySearchString = stringsSplitByQuotes.join(`"`).trim();

      // Extract the value associated with the current "fieldName" if the value was given without using the quotes
    } else if (
      stringsSplitBySpaces.length > 0 &&
      !startsWithFieldName(stringsSplitBySpaces.join(" "))
    ) {
      // Add the field-name and value as key-value pair in the record if the field-name is valid one
      if (
        fieldNames.some(
          (validFieldName) => validFieldName.toLowerCase() === fieldName
        )
      ) {
        if (
          (allowedValuesByFieldName?.[fieldName] &&
            allowedValuesByFieldName?.[fieldName]?.includes(
              stringsSplitByQuotes[0]
            )) ||
          !allowedValuesByFieldName?.[fieldName]
        ) {
          searchStringToRecord[fieldName] = stringsSplitByQuotes[0].trim();
        }
      }
      // Remove the extracted value
      stringsSplitBySpaces.splice(0, 1);
      // Update the remaining fuzzy string to be parsed
      fuzzySearchString = stringsSplitBySpaces.join(" ").trim();
    }
  }
  // Get the search string at the end of fuzzy search string and add it to the search field in the record
  if (fuzzySearchString.length > 0) {
    searchStringToRecord.search = fuzzySearchString.trim();
  }
  return searchStringToRecord;
};

/**
 * Removes query params related to OIDC and blacklisted params, returns the rest params to append in the page URL
 * @param queryParams - The page query params
 * @param blacklistedParams - the array of query params that should not be considered while creating the search string
 * @returns The search string to append to the query params
 */
const getSearchStringToAppend = (
  queryParams: URLSearchParams,
  blacklistedParams?: string[]
) => {
  let searchStringToAppend = "";
  queryParams.forEach(
    (value, key) =>
      (searchStringToAppend +=
        OidcQueryParams.includes(key) || blacklistedParams?.includes(key)
          ? ""
          : `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  );
  return searchStringToAppend;
};

/**
 * Returns a searchString with filters from the searchConfig applied
 * @param searchConfig - contains search query params
 * @returns The new search string for the updated searchConfig
 */
const getSearchStringFromSearchConfig = (
  searchConfig: Record<string, string | null>
) => {
  // Check if there are any filters applied
  if (
    Object.keys(searchConfig).some((searchKey) => !!searchConfig[searchKey])
  ) {
    let newSearchString = "";
    // Construct the search string
    Object.keys(searchConfig).forEach(
      (searchKey) =>
        !!searchConfig[searchKey] &&
        searchKey !== FieldNameForSearchField &&
        (newSearchString += ` ${searchKey}:"${searchConfig[searchKey]}"`)
    );
    if (searchConfig.search) {
      newSearchString += ` ${searchConfig.search}`;
    }
    return newSearchString.trim();
  }
  return null;
};

/**
 * The handler for updating the search config for the events table when enter key is pressed
 * @param searchString - the current search string in the FilterAutoSuggest input
 * @param options - the options for fieldNames for the events table
 * @param searchConfig - the current "searchConfig" for the events table
 * @param setSearchConfig - the setter function for updating the "searchConfig"
 * @param dispatch - the dispatch for dispatching the alert, if required
 */
const handleApplySearchForEvents = (
  searchString: string,
  options: TableSearchAutoSuggestOptionType[],
  searchConfig: Record<string, string | null>,
  setSearchConfig: (searchConfig: Record<string, string | null>) => void,
  dispatch: DispatchType,
  setCurrentPage: Dispatch<SetStateAction<number>>
) => {
  const searchFor = parseSearchString(
    searchString,
    options.map((option) => option.field),
    // Use lowercase for field name as we're using lower case for case insensitive matching of the field names
    { actiontype: AllowedActionTypeValues }
  );
  if (
    searchString
      .toLowerCase()
      .includes(EventsSearchParams.actionType.toLowerCase()) &&
    !searchFor.actiontype
  ) {
    dispatch(
      showAlert({
        message: tableSearchErrorMessages.invalidSearchString,
        severity: "warning",
      })
    );
  }

  // Use lowercase for field name as we're using lower case for case insensitive matching of the field names
  if (
    (searchFor.devicename ?? null) !== searchConfig.devicename ||
    (searchFor.eventname ?? null) !== searchConfig.eventName ||
    (searchFor.subject ?? null) !== searchConfig.subject ||
    (searchFor.actiontype ?? null) !== searchConfig.actiontype ||
    (searchFor.search ?? null) !== searchConfig.search
  ) {
    setSearchConfig({
      deviceName: searchFor.devicename ?? null,
      eventName: searchFor.eventname ?? null,
      subject: searchFor.subject ?? null,
      actionType: searchFor.actiontype ?? null,
      search: searchFor.search ?? null,
    });
  }

  // On applying search the current page should reset to the first page.
  setCurrentPage(1);
};

/**
 * Returns the devices search filters from query params
 * @returns Object containing search filters extracted from the query params.
 */
export const getDeviceSearchFiltersFromQueryParams = () => {
  const deviceFilters = Object.keys(DevicesInitSearchConfig);
  const { search } = location;
  const paramsObject = Object.fromEntries(new URLSearchParams(search));
  return Object.fromEntries(
    deviceFilters
      .filter((key) => key in paramsObject)
      .map((key) => [key, paramsObject[key]])
  );
};

export default {
  getKeyByValue,
  getNewPath,
  formatDateTime,
  getURLFromQueryParams,
  getFirstLetterOfWordsInString,
  changePageNumber,
  getPageNumberFromQueryParams,
  getTabNumberFromQueryParams,
  isInvalidPageQueryParam,
  getDataFromNestedObject,
  getPaginationData,
  addCommasToNumber,
  isObjectEmpty,
  getTruncatedString,
  locationPathToBasePath,
  isInvalidTabQueryParam,
  getPageSizeFromQueryParams,
  isPageSizeQueryParamInvalid,
  getTabStringFromQueryParams,
  getMonthNameFromNumber,
  getDateRangeFromQueryParam,
  getAttentionTypeFromQueryParams,
  hasSpecialChar,
  hasUpperCase,
  hasLowerCase,
  hasDigit,
  parseToRowDataType,
  provideClickHandlerToHeader,
  provideButtonOrLinkConfigToHeader,
  provideColorHandlerToHeader,
  getLabelValuePairsForTable,
  transformBytesToLargerUnits,
  transformFileSizeCellData,
  dayNameByIndex,
  monthNameByIndex,
  getDetailsFromDateString,
  indexByDayName,
  indexByMonthName,
  getEventActionColor,
  pageHasScrollbar,
  urlExists,
  getEventDetailsUrlForTableCell,
  getDeviceDetailsUrlForTableCell,
  getLabelFromLabelValuePair,
  addEllipsesToUrl,
  parseSearchString,
  getSearchStringToAppend,
  getSearchStringFromSearchConfig,
  handleApplySearchForEvents,
};
