// Utils
import Utils from "@Utils";

// Containers
import MaskedText from "@Containers/settings/EventFeed/subComponents/maskedContent";
import ToggleEnabled from "@Containers/settings/EventFeed/subComponents/addOrUpdateConfig/toggleEnabled";

/**
 * Events for device
 */
enum EventsForDeviceOrThreatColumnNames {
  deviceName = "name",
  occurrence = "occurrence_date",
  subject = "executable_path",
  deviceUser = "executing_user",
  action = "action_type",
  first_occurrence = "first_occurrence_date",
  total_occurrences = "total_occurrences",
}

const eventsForDeviceOrThreat: TableHeaderConfig[] = [
  {
    name: EventsForDeviceOrThreatColumnNames.deviceName,
    label: "Name",
    isColumnColored: true,
  },
  {
    name: EventsForDeviceOrThreatColumnNames.subject,
    label: "Subject",
    maxWidth: 200,
    addTooltipWithCopy: true,
    isColumnColored: true,
  },
  {
    name: EventsForDeviceOrThreatColumnNames.occurrence,
    label: "Occurrence",
    isDate: true,
  },
  {
    label: "First Occurrence",
    name: EventsForDeviceOrThreatColumnNames.first_occurrence,
    isDate: true,
  },
  {
    label: "Total",
    name: EventsForDeviceOrThreatColumnNames.total_occurrences,
  },
  // {
  //   name: EventsForDeviceOrThreatColumnNames.deviceUser,
  //   label: "Device User",
  //   maxWidth: 90,
  //   addTooltipWithCopy: true,
  // },
  {
    name: EventsForDeviceOrThreatColumnNames.action,
    label: "Action",
  },
];

/**
 * Events
 */
enum EventsColumnNames {
  deviceName = "device_name",
  name = "name",
  occurrence = "occurrence_date",
  subject = "executable_path",
  deviceUser = "executing_user",
  action = "action_type",
  first_occurrence = "first_occurrence_date",
  total_occurrences = "total_occurrences",
}

const events: TableHeaderConfig[] = [
  {
    label: "Device Name",
    name: EventsColumnNames.deviceName,
    isColumnColored: true,
  },
  {
    label: "Name",
    name: EventsColumnNames.name,
    isColumnColored: true,
  },
  {
    label: "Subject",
    name: EventsColumnNames.subject,
    maxWidth: 200,
    addTooltipWithCopy: true,
    isColumnColored: true,
  },
  {
    label: "Occurrence",
    name: EventsColumnNames.occurrence,
    isDate: true,
  },
  {
    label: "First Occurrence",
    name: EventsColumnNames.first_occurrence,
    isDate: true,
  },
  {
    label: "Total",
    name: EventsColumnNames.total_occurrences,
  },
  // TODO: Subject and User data is not coming from Critical Events API
  /*{
    name: EventsColumnNames.deviceUser,
    label: "Device User",
  },*/
  {
    name: EventsColumnNames.action,
    label: "Action",
    isColumnColored: true,
    color: Utils.getEventActionColor,
  },
];

/**
 * Devices
 */
export enum DevicesColumnNames {
  localName = "name",
  osName = "os_name",
  type = "type",
  registeredDate = "registered_date",
  heartbeat = "heartbeat",
  agentVersion = "agent_version",
}

const devices: TableHeaderConfig[] = [
  {
    label: "Local Name",
    name: DevicesColumnNames.localName,
    isColumnColored: true,
  },
  {
    label: "OS",
    name: DevicesColumnNames.osName,
    maxWidth: 150,
  },
  {
    label: "Registered",
    name: DevicesColumnNames.registeredDate,
    isDate: true,
  },
  {
    label: "Heartbeat",
    name: DevicesColumnNames.heartbeat,
    isDate: true,
  },
  {
    label: "Agent Version",
    name: DevicesColumnNames.agentVersion,
  },
];

/**
 * Tenants
 */
enum TenantsColumnNames {
  id = "id",
  fullName = "name",
  noOfUsers = "no_of_users",
  alias = "alias",
  parentId = "parent_id",
  creationDate = "createdAt",
  updatedAt = "updated_at",
  deletedAt = "deleted_at",
  isMssp = "is_mssp",
}

const Tenants: TableHeaderConfig[] = [
  // TODO: Uncomment below to show the ID column also
  // {
  //   label: "ID",
  //   name: TenantsColumnNames.id,
  //   blacklist: true,
  // },
  {
    label: "Full Name",
    name: TenantsColumnNames.fullName,
  },
  {
    label: "Alias",
    name: TenantsColumnNames.alias,
  },
  {
    label: "Creation date",
    name: TenantsColumnNames.creationDate,
    isDate: true,
  },
  // TODO: uncomment below to show the following columns in the tenants table
  // {
  //   label: "Updated",
  //   name: TenantsColumnNames.updatedAt,
  //   isDate: true,
  //   blacklist: true,
  // },
  // {
  //   label: "Deleted",
  //   name: TenantsColumnNames.deletedAt,
  //   isDate: true,
  //   blacklist: true,
  // },
  // {
  //   label: "Parent ID",
  //   name: TenantsColumnNames.parentId,
  //   maxWidth: 250,
  //   addTooltipWithCopy: true,
  //   blacklist: true,
  // },
  // {
  //   label: "MSSP",
  //   name: TenantsColumnNames.isMssp,
  //   blacklist: true,
  // },
];

/**
 * Users
 */
enum UsersColumnNames {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
}

const users: TableHeaderConfig[] = [
  {
    label: "First Name",
    name: UsersColumnNames.firstName,
  },
  {
    label: "Last Name",
    name: UsersColumnNames.lastName,
  },
  {
    label: "Email",
    name: UsersColumnNames.email,
  },
];

// Threat Details Fields label
const ThreatDetailsLabelConfigByFieldName: Record<
  string,
  Omit<TableHeaderConfig, "name">
> = {
  id: {
    label: "ID:",
    bold: true,
    showCopyIconOnHover: true,
    blacklist: true,
  },
  sha1: {
    label: "SHA1 hash:",
    bold: true,
    showCopyIconOnHover: true,
  },
  md5: {
    label: "MD5 hash",
    bold: true,
    showCopyIconOnHover: true,
  },
  name: {
    label: "File name:",
    bold: true,
    showCopyIconOnHover: true,
    capitalized: true,
  },
  file_type: {
    label: "File type:",
    bold: true,
    showCopyIconOnHover: true,
    capitalized: true,
  },
  file_size: {
    label: "File size:",
    bold: true,
    showCopyIconOnHover: true,
    transformData: (data: CellData) =>
      Utils.transformFileSizeCellData(data, "number"),
  },
};

// Threat details Tooltip fields
const ThreatDetailsTruncateFields: Record<string, string> = {
  md5: "md5",
  sha1: "sha1",
};

/**
 * Device info details
 */
export enum DeviceInfoDetailsType {
  endPointProtectionInfo = "epp_list",
  agentInfo = "agent",
  systemInfo = "system",
  osDetailsInfo = "os_details",
}

export enum OSDetailsInfoType {
  osInstallInfo = "os_install",
  osInfo = "os",
}

const AgentLabelConfigByFieldName: Record<
  string,
  Omit<TableHeaderConfig, "name">
> = {
  name: {
    label: "Name",
  },
  version: {
    label: "Version",
  },
  last_updated: {
    label: "Updated On",
    isDate: true,
  },
};

const EppLabelConfigByFieldName: Record<
  string,
  Omit<TableHeaderConfig, "name">
> = {
  name: {
    label: "Name",
  },
  version: {
    label: "Version",
  },
  last_updated: {
    label: "Updated On",
    isDate: true,
  },
};

const SystemsLabelConfigByFieldName: Record<
  string,
  Omit<TableHeaderConfig, "name">
> = {
  id: {
    label: "Id",
    blacklist: true,
  },
  manufacturer: {
    label: "Manufacturer",
  },
  model: {
    label: "Model",
  },
  total_physical_memory: {
    label: "Memory",
    transformData: (data: CellData) =>
      Utils.transformFileSizeCellData(data, "string"),
  },
  type: {
    label: "Type",
  },
  processor: {
    label: "Processor",
  },
  bios_version: {
    label: "Bios Version",
  },
  boot_device: {
    label: "Boot Device",
  },
  timezone: {
    label: "Timezone",
  },
  system_locale: {
    label: "System Locale",
  },
  boot_time: {
    label: "Booted On",
    isDate: true,
  },
};

const OsLabelConfigByFieldName: Record<
  string,
  Omit<TableHeaderConfig, "name">
> = {
  id: {
    label: "Id",
    blacklist: true,
  },
  name: {
    label: "Name",
  },
  version: {
    label: "Version",
  },
  manufacturer: {
    label: "Manufacturer",
  },
  configuration: {
    label: "Configuration",
  },
  build_type: {
    label: "Build Type",
  },
  os_subtype: {
    label: "Os Subtype",
  },
  os_type: {
    label: "Os Type",
  },
  package_manager: {
    label: "Package manager",
  },
  product_id: {
    label: "Product ID",
  },
  install_date: {
    label: "Installed On",
    isDate: true,
  },
  registered_org: {
    label: "Organization",
  },
  registered_owner: {
    label: "Owner",
  },
  domain: {
    label: "Domain",
  },
  logon_server: {
    label: "Logon Server",
  },
  hotfixes: {
    label: "Hotfixes",
  },
};

const DeviceDetailsInfoTableNames: Record<DeviceInfoDetailsType, string> = {
  [DeviceInfoDetailsType.agentInfo]: "AGENT",
  [DeviceInfoDetailsType.endPointProtectionInfo]: "ENDPOINT PROTECTION",
  [DeviceInfoDetailsType.systemInfo]: "SYSTEM",
  [DeviceInfoDetailsType.osDetailsInfo]: "OPERATING SYSTEM",
};

/**
 * Device Extracted Keys
 */
enum DeviceExtractedKeysColumnNames {
  threatId = "threat_id",
  createdAt = "created_at",
  keyValue = "extracted_key",
}

const deviceExtractedKeys: TableHeaderConfig[] = [
  {
    label: "Threat ID",
    name: DeviceExtractedKeysColumnNames.threatId,
    maxWidth: 350,
    addTooltipWithCopy: true,
  },
  {
    label: "Created At",
    name: DeviceExtractedKeysColumnNames.createdAt,
    isDate: true,
  },
  {
    label: "Key Value",
    name: DeviceExtractedKeysColumnNames.keyValue,
    maxWidth: 350,
    addTooltipWithCopy: true,
  },
];

enum EventFeedColumnNames {
  exportUrl = "exportUrl",
  enabled = "enabled",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  status = "status",
}

const EventFeed: TableHeaderConfig[] = [
  {
    label: "Export URL",
    name: EventFeedColumnNames.exportUrl,
    transformData: (data: CellData) => ({
      ...data,
      value: (
        <MaskedText text={data.value} maskFunction={Utils.addEllipsesToUrl} />
      ),
    }),
  },
  {
    label: "Enabled",
    name: EventFeedColumnNames.enabled,
    transformData: (data: CellData, rowData?: RowData) => ({
      ...data,
      value: (
        <ToggleEnabled
          enabled={Boolean(data.value)}
          configId={rowData?.id.value ?? ""}
          exportUrl={rowData?.exportUrl.value ?? ""}
          token={rowData?.token?.value ?? ""}
          authHeaderKey={rowData?.authHeaderKey?.value ?? ""}
          authHeaderValue={rowData?.authHeaderValue?.value ?? ""}
        />
      ),
    }),
  },
  {
    label: "Created At",
    name: EventFeedColumnNames.createdAt,
    isDate: true,
  },
  {
    label: "Updated At",
    name: EventFeedColumnNames.updatedAt,
    isDate: true,
  },
  {
    label: "Status",
    name: EventFeedColumnNames.status,
    transformData: (data: CellData) => ({
      ...data,
      value: data.value ? (
        <MaskedText text={data.value} maskWithWarnIcon />
      ) : (
        <>-</>
      ),
    }),
  },
];

export default {
  eventsForDeviceOrThreat,
  events,
  devices,
  users,
  EventsForDeviceOrThreatColumnNames,
  EventsColumnNames,
  DevicesColumnNames,
  UsersColumnNames,
  Tenants,
  TenantsColumnNames,
  DeviceInfoDetailsType,
  DeviceDetailsInfoTableNames,
  ThreatDetailsTruncateFields,
  DeviceExtractedKeysColumnNames,
  deviceExtractedKeys,
  ThreatDetailsLabelConfigByFieldName,
  AgentLabelConfigByFieldName,
  EppLabelConfigByFieldName,
  SystemsLabelConfigByFieldName,
  OsLabelConfigByFieldName,
  EventFeed,
  EventFeedColumnNames,
};
