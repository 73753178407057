import Constants from "@Constants";

const initialState: AddOrUpdateTenantState = {
  loading: false,
  data: {},
  success: false,
  error: "",
};

/**
 * Handles actions of adding or updating a tenant
 * @param state AddOrUpdateTenantState
 * @param action GenericAction
 * @returns AddOrUpdateTenantState
 */
const addTenantReducer = (
  state: AddOrUpdateTenantState = initialState,
  action: GenericAction
): AddOrUpdateTenantState => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_OR_EDIT_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case Constants.ActionTypes.ADD_OR_EDIT_TENANT_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        error: "",
      };
    case Constants.ActionTypes.ADD_OR_EDIT_TENANT_FAILED:
      return {
        loading: false,
        data: {},
        success: false,
        error: action.payload,
      };
  }
  return state;
};

export default addTenantReducer;
