import { makeStyles, Theme } from "@material-ui/core";
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  topActionBtnContainer: {
    position: "fixed",
    zIndex: 1100,
    top: theme.pxToRem(115),
    gap: theme.pxToRem(10),
    right: theme.pxToRem(30),
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      top: theme.pxToRem(70),
      right: theme.pxToRem(25),
    },
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      position: "static",
      margin: `${theme.pxToRem(80)} 0 ${theme.pxToRem(16)}`,
      justifyContent: "flex-start!important",
    },
  },
  jsonModal: {
    "& .MuiDialog-paper": {
      paddingBottom: 0,
      overflow: "hidden",
      maxWidth: `${theme.pxToRem(1190)} !important`,
      maxHeight: "100%",
    },
  },
  jsonModalContent: {
    backgroundColor: theme.newPalette.appBg,
    margin: `0 -${theme.pxToRem(40)}`,
    padding: theme.pxToRem(40),
  },
  manageCredButton: {
    backgroundColor: theme.newPalette.downloadAgentModal.tabsBg,
    padding: theme.pxToRem(12),
    height: theme.pxToRem(46),
    "& svg": {
      fill: theme.newPalette.downloadAgentModal.tabColor,
    },

    "&:hover": {
      backgroundColor: theme.newPalette.downloadAgentModal.activeTabBg,
    },
  },
}));

export default useStyles;
