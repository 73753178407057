import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.caption.fontSize,
    marginLeft: theme.pxToRem(10),
  },

  tooltipText: {
    fontSize: theme.pxToRem(11),
  },
}));

export default useStyles;
