import { makeStyles, Theme } from "@material-ui/core/styles";

// Constants
import { ResetStateForSortBy, SortingOrders } from "@Constants/sorting";
import { Breakpoints } from "@Constants/common";

// Types
import { SortStateType } from "./SortDropdownGroup";

const useStyles = makeStyles<
  Theme,
  { sortState: SortStateType; value: string | null }
>((theme: Theme) => ({
  dropdownGroupContainer: {
    gap: theme.pxToRem(8),
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: "100%",
    },
  },

  // Styles for select
  selectStyles: {
    fontSize: theme.pxToRem(13),
    minWidth: theme.pxToRem(150),
    borderRadius: theme.pxToRem(8),
    backgroundColor: theme.newPalette.sortDropdownGroup.background,
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: "100%",
    },

    "& .MuiSelect-root": {
      color: (props) =>
        props.value === ResetStateForSortBy
          ? theme.newPalette.sortDropdownGroup.text
          : theme.newPalette.sortDropdownGroup.textActive,
      borderRadius: theme.pxToRem(8.5),
      padding: `${theme.pxToRem(10)} ${theme.pxToRem(32)}
      ${theme.pxToRem(10)} ${theme.pxToRem(18)}`,
      border: `${theme.pxToRem(1)} solid transparent`,

      "&:focus": {
        background: theme.newPalette.sortDropdownGroup.icon,
      },
    },

    "& .MuiSvgIcon-root": {
      top: 0,
      bottom: 0,
      right: theme.pxToRem(12),
      width: theme.pxToRem(15),
      height: theme.pxToRem(15),
      margin: "auto 0",
      fontSize: "inherit",
      color: theme.newPalette.sortDropdownGroup.iconActive,
      stroke: theme.newPalette.sortDropdownGroup.iconActive,
      strokeWidth: 0.2,
    },
  },

  selectBorder: {
    border: "none",
    outline: "none",
  },

  // Styles for dropdown
  dropdownPaper: {
    marginTop: theme.pxToRem(4),
    backgroundColor: theme.newPalette.sortDropdownGroup.background,
  },

  // Styles for menuItem
  menuItem: {
    "& .MuiMenuItem-root": {
      color: theme.newPalette.sortDropdownGroup.text,
      padding: `${theme.pxToRem(8)} ${theme.pxToRem(10)}`,

      "&:hover": {
        color: theme.newPalette.sortDropdownGroup.textActive,
        background: theme.newPalette.sortDropdownGroup.itemHover,
      },
    },

    "& .Mui-selected": {
      color: theme.newPalette.sortDropdownGroup.textActive,
      background: theme.newPalette.sortDropdownGroup.itemHover,
    },
  },

  // Styles for icon button
  iconButton: {
    backgroundColor: theme.newPalette.sortDropdownGroup.background,
    minHeight: theme.pxToRem(36),
    width: theme.pxToRem(32),
    borderRadius: theme.pxToRem(8),

    "&:hover": {
      backgroundColor: theme.newPalette.sortDropdownGroup.background,
    },

    "&.Mui-focusVisible": {
      "&:focus": {
        background: theme.newPalette.sortDropdownGroup.icon,
      },
    },

    "& .MuiIconButton-label": {
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiSvgIcon-root": {
      color: theme.newPalette.sortDropdownGroup.icon,
      height: theme.pxToRem(15),
      width: theme.pxToRem(15),
      stroke: theme.newColors.neutral[900],
      strokeWidth: 0.2,
    },

    "& .icon-up": {
      transform: "rotate(180deg)",
      color: (props) =>
        props.sortState === SortingOrders.ASC &&
        theme.newPalette.sortDropdownGroup.iconActive,
    },

    "& .icon-down": {
      color: (props) =>
        props.sortState === SortingOrders.DESC &&
        theme.newPalette.sortDropdownGroup.iconActive,
    },
  },
}));

export default useStyles;
