// Containers
import ErrorBoundary from "@Containers/errorBoundary";
import DeviceInfoDrawer from "./subComponents/deviceInfoDrawer";

// Components
import Modal from "@Components/Modal";

// Constants
import { ModalIds } from "@Constants/Id";

// styles
import useStyles from "./styles";

// Props type
type DeviceInfoDetailsProps = {
  isOpen: boolean;
  handleClose: () => void;
  deviceId: string;
};

const DeviceInfoDetails = ({
  handleClose,
  deviceId,
  isOpen,
}: DeviceInfoDetailsProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      id={ModalIds.deviceDetails}
      fullWidth={true}
      maxWidth="xl"
      modalExtraClass={classes.modal}
      modalContentExtraClass={classes.modalContent}
      cancelButtonExtraClass={classes.modalCancelBtn}
      modalTitleExtraClass={`text-align-left w-100 margin-bottom-25`}
      title="Device Info Details"
      isOpen={isOpen}
      close={handleClose}
    >
      <ErrorBoundary>
        <DeviceInfoDrawer deviceId={deviceId} isOpen={isOpen} />
      </ErrorBoundary>
    </Modal>
  );
};

export default DeviceInfoDetails;
