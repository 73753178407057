import { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { closeAlert } from "@Store/actions";

// Constants
import Constants from "@Constants";

// Types
import { AlertPropsType } from "./Alert";

const AlertWrapper = (props: AlertProps): JSX.Element => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

/**
 * Alert Component - to show the toasts to the users
 * @example Correct Usage - default
 * ```ts
 * <Alert />
 * ```
 * @example Correct Usage with custom alignment
 * ```ts
 * <Alert vertical="bottom" horizontal="left" />
 * ```
 */
export const Alert = ({
  vertical = "top",
  horizontal = "center",
}: AlertPropsType): JSX.Element => {
  const { open, severity, message } = useSelector(
    (state: GlobalState) => state.alert
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  /**
   * Handles closing the toast
   */
  const handleClose = () => {
    setIsOpen(false);
    dispatch(closeAlert());
  };

  return (
    <Snackbar
      id="snackbar"
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={isOpen}
      autoHideDuration={Constants.AlertToastConfig.alertDuration}
      onClose={handleClose}
    >
      <AlertWrapper onClose={handleClose} severity={severity as Color}>
        {message}
      </AlertWrapper>
    </Snackbar>
  );
};
