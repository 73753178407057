import Constants from "@Constants";

const initialState: AlertState = {
  open: false,
  message: "",
  severity: "",
};

/**
 * Handles error actions
 * @param state
 * @param action
 * @returns
 */
const alertReducer = (
  state: AlertState = initialState,
  action: GenericAction
): AlertState => {
  switch (action.type) {
    case Constants.ActionTypes.SHOW_ALERT:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case Constants.ActionTypes.CLOSE_ALERT:
      return {
        ...state,
        open: false,
        message: "",
        severity: "",
      };
  }

  return state;
};

export default alertReducer;
