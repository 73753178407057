// Id's for the AutoComplete component.
export const AutoCompleteIDs = {
  option: ({ id, option }: { id: string; option: string }) =>
    `${id}-${option}-option`,
  selectTenantsHeader: "select-tenants-header-autocomplete",
  selectTenantsLeftMenu: "select-tenants-leftMenu-autocomplete",
  tagInputAutoComplete: ({ id }: { id: string }) => `${id}-autocomplete`,
  // Including tag input in autocomplete because the component is used just once throughout the app.
  multiSelectCloudResourceTagInput: "select-cloud-resource-tag",
  selectUserRole: "select-user-role-autocomplete",
};

// Id's for the Avatar component.
export const AvatarIDs = {
  userAvatarHeader: "user-header-avatar",
  userAvatarProfilePopover: "user-avatar-profile-popover",
};

// Id's for the Button component.
export const ButtonIDs = {
  // Users Container
  addUser: "add-user-button",
  addOrEditUser: "add-or-edit-user-button",

  // UserDetails Container
  deleteUserHeader: "delete-user-header-button",

  // ThreatDetail Container
  unblockThreatEvent: "unblock-threat-event-button",
  threatRequestDownload: "threat-request-download-button",

  // Tenants Container
  addNewTenant: "add-new-tenant-button",
  addNewTenantConfirmation: "add-new-tenant-button-confirmation-button",

  // TagPopover Container
  tagPopoverBodySettings: "tag-popover-body-settings-button",
  applyTagToResources: "apply-tag-to-resources-button",
  createTag: "create-tag-button",
  createTagConfirmationModalClose: "create-tag-confirmation-modal-close-button",
  createTagConfirmation: "create-tag-confirmation-button",

  // SignIn Container
  login: "login-button",

  // IdentityProvider Container
  editIdentityProvider: "edit-identity-provider-button",
  deleteIdentityProvider: "delete-identity-provider-button",
  addIdentityProvider: "add-identity-provider-button",

  // IdentityProvider Container ViewIdpDetails
  viewIdpConfiguration: "view-idp-configuration-button",
  viewOpenIdConfiguration: "view-open-id-configuration-button",
  viewSamlConfiguration: "view-saml-configuration-button",

  // IdentityProvider Container ConfigDetailsToUser
  configDetailsToUserBack: "config-details-to-user-back-button",
  configDetailsToUserNext: "config-details-to-user-next-button",
  configDetailsToUserClose: "config-details-to-user-close-button",

  // IdentityProvider Container AddOrEditSaml
  addOrEditSamlViewBack: "add-or-edit-saml-view-back-button",
  addOrEditSamlFormSubmit: "add-or-edit-saml-form-submit-button",

  // IdentityProvider Container AddOrEditProvider
  selectSamlView: "select-saml-view-button",
  selectOidcView: "select-oidc-view-button",
  providerAddedOk: "provider-added-ok-button",

  // IdentityProvider Container AddOrEditOpenIdConnect
  addOrEditOidcViewBack: "add-or-edit-oidc-view-back-button",
  addOrEditOidcFormSubmit: "add-or-edit-oidc-form-submit-button",

  // EventFeed Container AddOrUpdateConfig
  createOrUpdateFeedConfirmation: "create-or-update-feed-confirmation-button",
  createFeedButton: "create-feed-button",

  // Security Container
  updatePassword: "update-password-button",

  // Profile Container
  updateProfile: "update-profile-button",

  // PasswordRecovery Container
  resetPassword: "reset-password-button",
  forgotPasswordSendMail: "forgot-password-send-mail-button",

  // MultiVersionDownloadModal Container
  downloadClientVersion: "download-client-version-button",
  generateNewTokenConfirmation: "generate-new-token-confirmation-button",
  generateNewToken: "generate-new-token-button",
  cancelGenerateNewTokenConfirmation:
    "cancel-generate-new-token-confirmation-button",

  // ManageTagModal Container
  createNewTagModal: "create-new-tag-modal-button",

  // ManageTagModal Container TagItem
  editTag: "edit-tag-button",
  cancelEditOrDeleteTag: "cancel-edit-or-delete-tag-button",

  // Inactivity Tracker Container
  inactivityTrackerLogout: "inactivity-tracker-logout-button",
  inactivityTrackerStaySignedIn: "inactivity-tracker-stay-signed-in-button",
  staySignedInYes: "stay-signed-in-yes-button",
  staySignedInNo: "stay-signed-in-no-button",

  // Events Details Container
  unblockEvent: "unblock-event-button",

  // ErrorBoundary Container
  errorBoundaryTryAgain: "error-boundary-try-again-button",

  // Devices Container
  addBulkTagHeader: "add-bulk-tag-button-header-button",

  // Device Details Container
  unblockEventWithId: ({ id }: { id: string }) => `unblock-event-${id}-button`,
  deleteDevice: "delete-device-button",
  viewExtractedKeyValue: ({ deviceId }: { deviceId: string }) =>
    `view-extracted-key-for-${deviceId}-device-button`,

  // Date Picker Component
  closeDatePicker: ({ id }: { id?: string }) => `${id}-close-button`,
  applyDatePicker: ({ id }: { id?: string }) => `${id}-apply-button`,

  // Confirmation Dialog Component
  cancelConfirmationDialog: ({ id }: { id?: string }) => `${id}-cancel-button`,
  confirmConfirmationDialog: ({ id }: { id?: string }) =>
    `${id}-confirm-button`,

  // Empty State component.
  emptyStateActionButton: ({ id }: { id?: string }) => `${id}-action-button`,

  // Header Component InPageTabs
  inPageTabs: ({ pageTab }: { pageTab: string }) =>
    `${pageTab}-page-tab-button`,

  // Header Component ProfilePopoverContent
  profilePopoverContent: "profile-popover-content-button",

  // Header Component toolbar
  toolbarBackButton: "toolbar-back-button",
  toolbarToggleTheme: "toolbar-toggleTheme-button",
  toolbarSelectProfile: "toolbar-select-profile-button",

  // Header Component toolbar mobile
  toolbarMobileBackButton: "toolbar-mobile-back-button",
  toolbarMobileHamburger: "toolbar-mobile-hamburger-button",

  // LeftMenu Component downloadAgentCard
  agentCardDownload: "agent-card-download-button",

  // Modal Component
  modalClose: "modal-close-button",

  // Pagination Component
  selectAllPages: "select-all-pages-button",
  clearSelection: "clear-selection-button",

  // UndoTagRemoval Component
  undoTag: "undo-tag-button",

  // Filter Component
  filterButton: ({ filterId }: { filterId?: string }) =>
    `${filterId}-filter-button `,
};

// Id's for Card Component
export const CardIds = {
  // EventDetails Container
  downloadRequestThreat: "download-request-threat-card",

  // Dashboard Container
  devicesStats: ({ title }: { title: string }) => `devices-${title}-stats-card`,
  eventsStats: ({ title }: { title: string }) => `events-${title}-stats-card`,
  eventsBarGraph: "events-bar-graph-card",
};

// Id's for the Checkbox Component
export const CheckboxIds = {
  // Checkbox FormLabel
  formLabel: ({ id }: { id: string }) => `${id}-label`,

  // AddOrEditSaml Container
  includeCertificate: "include-certificate-checkbox",
};

// Id's for the ConfirmationDialog Component
export const ConfirmationDialogIds = {
  // Users Container
  deleteUser: "delete-user-confirmation-dialog",

  // Tenants Container
  deleteTenant: "delete-tenant-confirmation-dialog",

  // IdentityProvider Container
  deleteIdpConfig: "delete-idp-confirmation-dialog",

  // ManageTagModal Container TagItem
  deleteTag: "delete-tag-confirmation-dialog",

  // DeviceDetails Container
  deleteDevice: "delete-device-confirmation-dialog",

  // ConfigActions Component
  deleteConfig: ({ targetName }: { targetName: string }) =>
    `delete-${targetName}-confirmation-dialog`,
};

// Id's for the DatePicker Component
export const DatePickerIds = {
  // Dashboard Container
  datePickerForEvents: "date-picker-for-events",
};

// Id's for the Drawer Component
export const DrawerIds = {
  // EventsForDevice Container
  eventsForDevice: "events-for-device-drawer",

  // ThreatDetails Container
  threatDetails: "threat-details-drawer",
};

// Id's for the EmptyState Component
export const EmptyStateIds = {
  // Tenant Container
  noAccessToManageTenants: "no-access-to-manage-tenants-empty-state",

  // EventFeed Container
  eventConfig: "event-config-empty-state",

  // ManageTagModal Container
  tagList: "tag-list-empty-state",

  // Paginated Table Component
  paginatedTable: "paginated-table-empty-state",

  // LabelValuePair Table Component
  labelValuePair: "label-value-pair-empty-state",

  // Threat-details Container
  threatDetails: "threat-details-empty-state",
};

// Id's for the FilterAutoSuggest Component
export const FilterAutoSuggestIds = {
  dashboardDevices: "dashboard-devices-filter-autosuggest",
  dashboardEvents: "dashboard-events-filter-autosuggest",
  threatDetailsEvents: "threat-details-events-filter-autosuggest",
  deviceDetailsEvents: "device-details-events-filter-autosuggest",
  events: "events-filter-autosuggest",
  devices: "devices-filter-autosuggest",
};

// Id's for the FilterAutoSuggestOption Component
export const FilterAutoSuggestOptionIds = {
  option: ({ label }: { label: string }) =>
    `${label}-filter-autosuggest-option`,
};

// Id's for the Graph Components
export const GraphsIds = {
  dashboardEventsBarGraph: "dashboard-events-bar-graph",
  sparkLineGraph: ({ dataKey }: { dataKey: string }) =>
    `${dataKey}-spark-line-graph`,
};

// Id for the Header Component
export const HeaderIds = {
  header: "app-header",
  inPageTabs: "in-page-tabs",
  profilePopover: "profile-popover",
  pageTitle: "page-title-header",
};

// Id's for the InfoCard Component
export const InfoCardIds = {
  aboutDetails: ({ value }: { value: string }) =>
    `about-details-${value}-info-card`,
  threatDetails: "threat-details-threat-id-info-card",
  eventDetailsAction: "event-details-action-info-card",
  eventDetailsOccurrence: "event-details-occurrence-info-card",
  eventDetailsDeviceName: "event-details-device-name-info-card",
  eventDetailsDeviceUser: "event-details-device-user-info-card",
};

// Id's for the InputField Component
export const InputFieldIds = {
  // MangeTagModal Container
  modalCreateTag: "modal-create-tag-input-field",
  modalTagItem: "modal-tag-item-input-field",

  // TagPopover Container
  createTagModal: "create-tag-modal-input-field",
  searchTags: "search-tags-input-field",

  // Profile Container
  firstName: "first-name-input-field",
  lastName: "last-name-input-field",

  // Security Container
  oldPassword: "old-password-input-field",
  newPassword: "new-password-input-field",
  confirmPassword: "confirm-password-input-field",

  // EventFeed Container
  exportUrl: "export-url-input-field",
  bearerToken: "event-feed-bearer-token-input-field",
  authHeaderKey: "event-feed-header-key-input-field",
  authHeaderValue: "event-feed-header-value-input-field",

  // IdentityProvider Container
  addOrEditOidc: ({ fieldName }: { fieldName: string }) =>
    `${fieldName}-odic-input-fields`,
  addOrEditSaml: ({ fieldName }: { fieldName: string }) =>
    `${fieldName}-saml-input-fields`,
  samlCertificate: "certificate-saml-input-field",

  // Tenants Container
  tenantName: "tenant-name-input-field",
  tenantAlias: "tenant-alias-input-field",

  // Users Container
  userEmail: "user-email-input-field",
};

// Id for different tables in the app
export const TableIds = {
  devices: "devices-table",
  deviceExtractedKeys: "device-extracted-keys-table",
  events: "events-table",
  users: "users-table",
  tenants: "tenants-table",
  agentInfo: "agent-info-table",
  system: "system-details-table",
  operatingSystem: "os-details-table",
  eventFeed: "event-feed-table",
  threat: "threat-table",
};

// Id for different tabs in the app
export const TabIds = {
  // Tab Ids for device details page
  events: "events",
  system: "system",
  operatingSystem: "os",
  extractedKeys: "extracted-keys",

  // Tab Ids for "Download Halcyon Agent" modal
  agentVersions: "agent-version",
  installationToken: "installation-token",

  // Tab Ids for threat details page
  threat: "threat",
  threatEvents: "threat-events",
};

/**
 * Id for different ToggleButtons in the app
 */
export const ToggleButtonIds = {
  eventFeedEnableToggleButton: "event-feed-enable-toggle-button",
};

/**
 * Id for different Tooltips in the app
 */
export const TooltipIds = {
  deviceName: "device-name",
  deviceRegisteredDate: "device-registered-date",
  deviceLastHeartbeat: "device-last-heartbeat",
  agentVersion: "agent-version",
  eventOccurrence: "event-occurrence",
  deviceUser: "device-user",
  threatId: "threat-id",
  logoutURLDescription: "logout-url-description",
  driverVersion: "driver-version",
};

/**
 * Id for different svg icons
 */
export const SVGIconIds = {
  appIcon: "app-icon",
  systemIcon: "system-icon",
  extractedKeyIcon: "extracted-key-icon",
  osIcon: "os-icon",
  plusIcon: "plus-icon",
  addIcon: "add-icon",
  warningIcon: "warning-icon",
  settingsIcon: "settings-icon",
  searchIcon: "search-icon",
  barrierIllustrationIcon: "barrier-illustration-icon",
  circularBgIllustrationLeft: "circular-bg-illustration-left",
  circularBgIllustrationRight: "circular-bg-illustration-right",
  themeToggleIcon: "theme-toggle-icon",
  backBtnIcon: "back-btn-icon",
  brandLogo: "brand-logo",
  hamburgerIcon: "hamburger-icon",
  downloadIcon: "download-icon",
  dashboardIcon: "dashboard-icon",
  deviceIcon: "device-icon",
  calenderIcon: "calendar-icon",
  usersIcon: "users-icon",
  homeIcon: "home-icon",
};

/**
 * Ids of different SelectWithTextField components
 */
export const SelectWithTextFieldIds = {
  nameIdPolicyFormat: "name-id-policy-format-field",
  storybookMockId: "storybook-mock-id",
};

/**
 * Ids of different Popover components
 */
export const PopoverIds = {
  tableOptionsPopover: "table-options-popover",
  tagPopover: "tag-popover",
};

export const CommonIds = {
  filterAutoSuggestAndSortGroupWrapper:
    "filter-autosuggest-and-sort-group-wrapper",
};

export const DashboardContainerIds = {
  page: ({ tab }: { tab: string }) => `dashboard-page-${tab}-tab`,

  // Id's for elements in events tab.
  datePickerWrapper: "date-picker-wrapper",
  eventsStatsWrapper: "events-stats-wrapper",
  eventsTableTitle: "events-table-title",

  // Id's for elements in devices tab.
  devicesStatsWrapper: "devices-stats-wrapper",
  devicesTableTitleAndFilterWrapper: "devices-table-title-and-filter-wrapper",
  devicesTableTitle: "devices-table-title",
};

// Id's for DeviceDetails Container
export const DeviceDetailsContainerIds = {
  // About Details
  tagGroupWrapper: "tag-group-wrapper",
  deviceStatsWrapper: "device-stats-wrapper",
};

// Id's for DeviceInfo Container
export const DeviceInfoContainerIds = {
  deviceInfoWrapper: "device-info-wrapper",
  agentInfoWrapper: "agent-info-wrapper",
  agentInfoTableTitle: "agent-info-table-title",
  systemDetailsWrapper: "system-details-wrapper",
  systemDetailsTableTitle: "system-details-table-title",
  osDetailsWrapper: "os-details-wrapper",
  osDetailsTableTitle: "os-details-table-title",
};

// Id's for ErrorBoundary Container
export const ErrorBoundaryIds = {
  container: "error-boundary",
  errorImage: "error-image",
  errorText: "error-text",
};

// Id's for EventDetails Container
export const EventDetailsIds = {
  eventDetailsInfoCardsWrapper: "event-details-info-card-wrapper",
  threatTableTitle: "threat-table-title",
};

// Id's for InactivityTracker Container
export const InactivityTrackerIds = {
  container: "inactivity-tracker",
  actionWrapper: "inactivity-actions-wrapper",
};

// Id's for MultiVersionDownloadModal Container
export const MultiVersionDownloadModalIds = {
  tokenContainer: "token-container",
  token: "token",
  copyTokenWrapper: "copy-token-wrapper",
  generateTokenConfirmationWrapper: "generate-token-confirmation-wrapper",
};

// Id's for Profile Container
export const ProfileIds = {
  container: "my-profile-page",
  pageTitle: "my-profile-page-title",
  profileForm: "my-profile-form",
};

// Id's for Security Container
export const SecurityIds = {
  passwordAndSecurityForm: "password-and-security-form",
};

// Id's for Settings Container
export const SettingsIds = {
  createFeedForm: "create-feed-form",
  idpTitle: "idp-tab-title",
  addOrEditOidcForm: "add-or-edit-oidc-form",
  addOrEditSamlForm: "add-or-edit-saml-form",
  configDetails: "config-details",
};

// Id's for Tenants Container
export const TenantsIds = {
  addOrEditTenantForm: "add-or-edit-tenant-form",
};

// Id's for User Container
export const UsersIds = {
  addOrEditUserForm: "add-or-edit-user-form",
};

// Id's for UserDetails
export const UserDetailsIds = {
  firstName: "first-name",
  lastName: "last-name",
  email: "email",
  userRole: "user-role",
};

/**
 * Ids of different Link components
 */
export const LinkIds = {
  myProfileLink: "my-profile-link",
  passwordAndSecurity: "password-and-security-link",
  brandLogoLink: "brand-logo-link",
  dashboardLink: "dashboard-link",
  backToHome: "back-to-home-link",
};

/**
 * Ids of the LeftMenu component and its sub-components
 */
export const LeftMenuIds = {
  leftMenuDrawer: "left-menu-drawer",
  leftMenuDrawerCard: "left-menu-drawer-card",
  settingsTitleMobile: "settings-title-mobile",
  profilePopoverContentUser: "profile-popover-content-user",
  profilePopoverContentSettings: "profile-popover-content-settings",
  menuHeader: "menu-header",
  tenantInputContainer: "tenant-input-container",
  agentDownloadBtnContainer: "agent-download-btn-container",
  agentCardContainer: "agent-card-container",
  agentCardTextContainer: "agent-card-text-container",
  agentCardTitle: "agent-card-title",
  agentCardSubtitle: "agent-card-subtitle",
  agentDownloadIconContainer: "agent-download-icon-container",
};

/**
 * Ids of the SkeletonLoader component
 */
export const SkeletonLoaderIds = {
  deviceName: "device-name-skeleton",
  eventName: "event-details-skeleton",
  userName: "user-name-skeleton",
  tagChip: "tag-chip-skeleton",
  deviceStats: "device-stats-skeleton",
  eventStats: "events-stats-skeleton",
  eventAction: "event-action-skeleton",
  eventOccurrence: "event-occurrence-skeleton",
  eventDevice: "event-device-skeleton",
  eventDeviceUser: "event-device-user-skeleton",
  editIdpBtn: "add-idp-btn-skeleton",
  deleteIdpBtn: "delete-idp-btn-skeleton",
};

/**
 * Ids of the Modal component
 */
export const ModalIds = {
  deviceDetails: "device-details-modal",
  inactivityTracker: "inactivity-tracker-modal",
  manageTag: "manage-tag-modal",
  downloadAgent: "download-agent-modal",
  addOrUpdateEventFeedConfig: "add-update-event-feed-config-modal",
  addOrEditProvider: "add-or-edit-provider-modal",
  viewIdpDetails: "view-idp-details-modal",
  createTag: "create-tag-modal",
  addAndEditTenant: "add-and-edit-tenant-modal",
  addAndEditUser: "add-and-edit-user-modal",
};

// Id's for the TagGroup component
export const TagGroupIds = {
  component: "tag-group",
  tagChip: ({ tag }: { tag: string }) => `${tag}-tag`,
  addTag: "add-tag-button",
};

// Id's for the Filter component
export const FilterIds = {
  devices: "devices-filter",
  userRoles: "user-roles-filter",
};

// Id's for the SortDropdownGroup component
export const SortDropdownGroupIds = {
  devices: "devices-sort-dropdown-group",
  events: "events-sort-dropdown-group",
};
