import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./auth";
import alertReducer from "./alert";
import devicesReducer from "./devices";
import eventsReducer from "./events";
import userDetailsReducer from "./user";
import eventsForDeviceOrThreatReducer from "./eventsForDeviceOrThreat";
import threatDetailsReducer from "./threatDetails";
import addUserReducer from "./tenant/addUser";
import usersReducer from "./tenant/users";
import deleteUserReducer from "./tenant/deleteUser";
import tabReducer from "./tab";
import agentInstallerReducer from "./agentInstaller";
import deviceInfoDetailsReducer from "./deviceInfoDetails";
import eventDetailsReducer from "./eventDetails";
import criticalEventsReducer from "./criticalEvents";
import deviceMetricsReducer from "./deviceMetrics";
import eventMetricsReducer from "./eventMetrics";
import eventMetricsByMonthReducer from "./eventMetricsByMonth";
import downloadThreatReducer from "./downloadThreat";
import fileRequestReducer from "./fileRequest";
import unblockEventActionReducer from "./unblockEventAction";
import extractedKeyInfoReducer from "./extractedKeyInfo";
import deviceExtractedKeysReducer from "./deviceExtractedKeys";
import themeReducer from "./theme";
import partitionedEventMetricsReducer from "./partitionedEventMetrics";
import sidebarReducer from "./sidebar";
import selectedTenantReducer from "./tenant/selectedTenant";
import childTenantsReducer from "./tenant/childTenants";
import deleteTenantReducer from "./tenant/deleteTenant";
import addTenantReducer from "./tenant/addOrUpdateTenant";
import deleteDeviceReducer from "./deleteDevice";
import availableTenantsReducer from "./tenant/availableTenants";
import userActivityReducer from "./userActivity";
import userSessionPreferenceReducer from "./userSessionPreference";
import userRolesReducer from "./userRoles";
import currentAccessLevelReducer from "./currentAccesssLevel";
import exportConfigReducer from "./exportConfigs";
import updateExportConfigReducer from "./updateExportConfig";
import deleteExportConfigReducer from "./deleteExportConfig";
import selectedRowsReducer from "./selectedRows";
import deleteTagsFromDevices from "./deleteTagsFromDevices";
import createTagReducer from "./createTag";
import deleteTagReducer from "./deleteTag";
import addTagsToDevicesReducer from "./addTagsToDevices";
import tagListReducer from "./tagList";
import tagAllDevicePages from "./TagAllDevicePages";
import updateTenantUserRoleReducer from "./updateTenantUserRole";
import fetchTenantUserRoleReducer from "./fetchTenantUserRole";
import fetchTenantUserByIdReducer from "./fetchTenantUserById";
import editTagReducer from "./editTag";
import tagCountReducer from "./tagCount";
import fetchIdentityProvider from "./identityProvider/fetchIdentityProviderConfig";
import createIDPConfig from "./createIDPConfig";
import updateIDPConfig from "./updateIDPConfig";
import deleteIDPConfig from "./deleteIDPConfig";

// Redux persis config
// "root" is the key of local-storage value
// whitelist: list of reducer state we want to persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userDetails",
    "activeTheme",
    "configDetails",
    "selectedTenant",
    "userSessionPreference",
  ],
};

// Combine all reducers
const appReducer = combineReducers({
  auth: authReducer,
  devices: devicesReducer,
  events: eventsReducer,
  alert: alertReducer,
  userDetailsNonPersisted: userDetailsReducer.userDetailsReducerNonPersisted,
  userDetails: userDetailsReducer.userDetailsReducerPersisted,
  eventsForDeviceOrThreat: eventsForDeviceOrThreatReducer,
  threatDetails: threatDetailsReducer,
  addUser: addUserReducer,
  tabDetails: tabReducer,
  tenantUsers: usersReducer,
  deleteTenantUser: deleteUserReducer,
  agentInstallerDetails: agentInstallerReducer,
  deviceInfoDetails: deviceInfoDetailsReducer,
  eventDetails: eventDetailsReducer,
  criticalEvents: criticalEventsReducer,
  deviceMetrics: deviceMetricsReducer,
  eventMetricsByMonth: eventMetricsByMonthReducer,
  eventMetrics: eventMetricsReducer,
  downloadThreat: downloadThreatReducer,
  fileRequest: fileRequestReducer,
  unblockEventAction: unblockEventActionReducer,
  extractedKeyInfo: extractedKeyInfoReducer,
  deviceExtractedKeys: deviceExtractedKeysReducer,
  activeTheme: themeReducer,
  partitionedEventMetrics: partitionedEventMetricsReducer,
  sidebar: sidebarReducer,
  childTenants: childTenantsReducer,
  deleteTenant: deleteTenantReducer,
  addTenant: addTenantReducer,
  deleteDevice: deleteDeviceReducer,
  availableTenants: availableTenantsReducer,
  selectedTenant: selectedTenantReducer,
  userActivity: userActivityReducer,
  userSessionPreference: userSessionPreferenceReducer,
  userRoles: userRolesReducer,
  currentAccessLevel: currentAccessLevelReducer,
  exportConfigs: exportConfigReducer,
  updateExportConfig: updateExportConfigReducer,
  deleteExportConfig: deleteExportConfigReducer,
  selectedRows: selectedRowsReducer,
  addTagsToDevices: addTagsToDevicesReducer,
  deleteTagFromDevices: deleteTagsFromDevices,
  createdTag: createTagReducer,
  deletedTag: deleteTagReducer,
  tagList: tagListReducer,
  tagAllDevicePages: tagAllDevicePages,
  updateTenantUserRole: updateTenantUserRoleReducer,
  tenantUserRole: fetchTenantUserRoleReducer,
  tenantUser: fetchTenantUserByIdReducer,
  editTag: editTagReducer,
  deviceTagCount: tagCountReducer,
  idpConfig: fetchIdentityProvider,
  createIDPConfig: createIDPConfig,
  updateIDPConfig: updateIDPConfig,
  deleteIDPConfig: deleteIDPConfig,
});

const rootReducer = (state: GlobalState | undefined, action: GenericAction) => {
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
