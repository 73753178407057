import { useEffect, useMemo, useState } from "react";
import { useLocation, generatePath } from "react-router-dom";
import { Card, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";

// Components
import { AutoComplete } from "@Components/AutoComplete";
import TabItem from "./SubComponents/TabItem";
import DownloadAgentCard from "./SubComponents/DownloadAgentCard";
import ProfilePopoverContent from "@Components/Header/SubComponents/ProfilePopoverContent";

// Actions
import { toggleSidebar } from "@Store/actions";

// Containers
import MultiVersionAgentDownloadModal from "@Containers/multiVersionDownloadModal";

// Utils
import Utils from "@Utils";

// Constants
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import Constants from "@Constants";
import { AutoCompleteIDs, LeftMenuIds } from "@Constants/Id";

// Hooks
import useTab from "@Hooks/useTab";
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";
import useAppTheme from "@Hooks/useAppTheme";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Styles
import useStyles from "./LeftMenu.styles";

/**
 * LeftMenu Component
 *
 * Renders a persisting menu drawer on the left side in desktop screens.
 * Renders a temporary menu drawer which opens and closes on the right side in mobile/tablet screens
 * @example Correct usage
 * ```ts
 * <LeftMenu />
 * ```
 */
export const LeftMenu = (): JSX.Element => {
  const classes = useStyles();

  // initialize hooks
  const dispatch = useDispatch();
  const location = useLocation();

  const tabs = useTab();
  const { isMobile } = useCustomMediaQuery();
  const { activeTheme, getBrandLogo } = useAppTheme();
  const { currentAccessLevel, checkAccessAndRender } = usePermissionFilter();
  const { selectedTenant, tenantsLabelValuePair, updateSelectedTenantByAlias } =
    useTenantAccess();

  const [isMultiVersionDownloadModalOpen, setDownloadDrawerOpen] =
    useState(false);

  const sidebar = useSelector((state: GlobalState) => state.sidebar);

  // handler for toggling the MultiVersionDownloadModal
  const toggleMultiVersionDownloadModal = (): void => {
    setDownloadDrawerOpen(!isMultiVersionDownloadModalOpen);
  };

  // handler for closing the sidebar
  const closeSidebar = () => dispatch(toggleSidebar(false));

  useEffect(() => {
    tabs.setActiveTab(
      Constants.TabRoutes[
        Utils.locationPathToBasePath(
          generatePath(location.pathname)
        ) as TabRoutesType
      ]
    );
  }, [location.pathname]);

  // Array containing TabItems which are accessible to the user
  const tabItems = Constants.LeftMenuTabList.map((tab: LeftMenuTabConfig) => (
    <TabItem key={tab.id} tab={tab} />
  ));

  return useMemo(
    () => (
      <Drawer
        id={LeftMenuIds.leftMenuDrawer}
        className={classes.drawer}
        BackdropProps={{ invisible: !isMobile }}
        variant={isMobile ? "temporary" : "persistent"}
        anchor={isMobile ? "right" : "left"}
        open={isMobile ? sidebar.open : true}
        onClose={() => closeSidebar()}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Card
          id={LeftMenuIds.leftMenuDrawerCard}
          className={`d-flex
              ${!isMobile && "justify-between"}
              ${classes.card}`}
        >
          <div>
            {!isMobile ? (
              <img
                src={getBrandLogo()}
                alt={Constants.LogoAltText}
                className={`margin-bottom-30 ${classes.logo}`}
              />
            ) : (
              <>
                <ProfilePopoverContent
                  id={LeftMenuIds.profilePopoverContentUser}
                  showUserNameAndAvatar
                />
                <div
                  className={`flex ${classes.tenantFilter}`}
                  id={LeftMenuIds.tenantInputContainer}
                >
                  <AutoComplete
                    id={AutoCompleteIDs.selectTenantsLeftMenu}
                    value={selectedTenant.selectedTenantName}
                    defaultValue={selectedTenant.selectedTenantName}
                    options={Array.from(
                      tenantsLabelValuePair,
                      (filter) => filter.label
                    )}
                    onChange={(value: string | null) =>
                      updateSelectedTenantByAlias(value ?? "")
                    }
                    textFieldSize="small"
                    textFieldExtraClass={classes.filterTextField}
                    placeholder={selectedTenant.selectedTenantName}
                    fillInputOnSelect
                  />
                </div>
                <Typography
                  id={LeftMenuIds.menuHeader}
                  variant="subtitle2"
                  className={classes.sectionHeader}
                >
                  Menu
                </Typography>
              </>
            )}

            {tabItems}
          </div>
          {isMobile && (
            <>
              <Typography
                id={LeftMenuIds.settingsTitleMobile}
                variant="subtitle2"
                className={classes.sectionHeader}
              >
                Settings
              </Typography>
              <ProfilePopoverContent
                id={LeftMenuIds.profilePopoverContentSettings}
                showSettings
              />
            </>
          )}
          {checkAccessAndRender({
            featureAccessLevel: FeatureToAccessTypeMap.installers.view,
            child: (
              <DownloadAgentCard
                onClick={toggleMultiVersionDownloadModal}
                extraClass="margin-top-25"
              />
            ),
          })}
        </Card>
        {checkAccessAndRender({
          featureAccessLevel: FeatureToAccessTypeMap.installers.view,
          child: (
            <MultiVersionAgentDownloadModal
              isOpen={isMultiVersionDownloadModalOpen}
              handleClose={toggleMultiVersionDownloadModal}
            />
          ),
        })}
      </Drawer>
    ),
    [
      location.pathname,
      tabs.currentlyActiveTab,
      isMultiVersionDownloadModalOpen,
      isMobile,
      sidebar.open,
      activeTheme,
      selectedTenant,
      currentAccessLevel,
    ]
  );
};
