import { ReactNode } from "react";
import { useSelector } from "react-redux";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";

// Containers
import TagPopover from "@Containers/TagPopover";

// Constants
import SVGIcons from "@Constants/icon";
import { ButtonIDs, SVGIconIds } from "@Constants/Id";

// Styles
import useStyles from "./styles";

// Type of the props
type AddBulkLabelHeaderBtnProps = {
  onClick?: () => void;
};

// Type of the PopoverWrapper props
type PopoverWrapperProps = {
  // The popover children which is responsible for opening the popover when clicked
  children: ReactNode;
  // If true, the "Add Tag" button in the header is disabled
  isBtnDisabled: boolean;
};

/**
 * Prevents the popover to open up when the button in the header is disabled
 */
const PopoverWrapper = ({ children, isBtnDisabled }: PopoverWrapperProps) =>
  isBtnDisabled ? <>{children}</> : <TagPopover>{children}</TagPopover>;

/**
 * The header button for supporting bulk tagging
 */
const AddBulkLabelHeaderBtn = ({ onClick }: AddBulkLabelHeaderBtnProps) => {
  const classes = useStyles();
  const { rows: selectedRows, arePagesSelected } = useSelector(
    (state: GlobalState) => state.selectedRows
  );

  return (
    <PopoverWrapper isBtnDisabled={!selectedRows.length && !arePagesSelected}>
      <Button
        id={ButtonIDs.addBulkTagHeader}
        extraClass={classes.headerBtn}
        variant="outlined"
        disabled={!selectedRows.length && !arePagesSelected}
        onClick={onClick}
      >
        <SVGWrapper
          width={14}
          height={14}
          viewBox="0 0 14 14"
          extraClass={classes.plusIcon}
          id={SVGIconIds.plusIcon}
        >
          {SVGIcons.plus}
        </SVGWrapper>
        <span>Add Label</span>
      </Button>
    </PopoverWrapper>
  );
};

export default AddBulkLabelHeaderBtn;
