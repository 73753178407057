import Constants from "@Constants";

const initialState: DeviceMetricsState = {
  loading: false,
  metrics: {
    total: 0,
    heartbeats: 0,
    needUpdates: 0,
  },
  error: "",
};

const deviceMetricsReducer = (
  state: DeviceMetricsState = initialState,
  action: GenericAction
): DeviceMetricsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_DEVICE_METRICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_DEVICE_METRICS_SUCCESS:
      return {
        loading: false,
        metrics: {
          total: Number(action?.payload?.total) || 0,
          heartbeats: Number(action?.payload?.no_heartbeats) || 0,
          needUpdates: Number(action?.payload?.need_updates) || 0,
        },
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICE_METRICS_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
  }
  return state;
};

export default deviceMetricsReducer;
