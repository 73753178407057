import Constants from "@Constants";

const initialState: UpdateTenantUserRoleState = {
  loading: false,
  isUpdated: false,
  error: "",
};

/**
 * Handles updating the role of the users
 *
 * @param state UpdateTenantUserRoleState
 * @param action GenericAction
 * @returns UpdateTenantUserRoleState
 */
const updateTenantUserRoleReducer = (
  state: UpdateTenantUserRoleState = initialState,
  action: GenericAction
): UpdateTenantUserRoleState => {
  switch (action.type) {
    case Constants.ActionTypes.UPDATE_TENANT_USER_ROLE_REQUEST:
      return {
        loading: true,
        isUpdated: false,
        error: "",
      };
    case Constants.ActionTypes.UPDATE_TENANT_USER_ROLE_SUCCESS:
      return {
        loading: false,
        isUpdated: true,
        error: "",
      };
    case Constants.ActionTypes.UPDATE_TENANT_USER_ROLE_FAILED:
      return {
        loading: false,
        isUpdated: false,
        error: action?.payload,
      };
  }

  return state;
};

export default updateTenantUserRoleReducer;
