// Constants
import Constants from "@Constants";

// Utils
import Utils from "@Utils";

const initialState: ExportConfigState = {
  loading: false,
  data: [],
  error: "",
};

/**
 * Handles actions of fetching export configurations
 * @param state ExportConfigState
 * @param action GenericAction
 * @returns ExportConfigState
 */
const exportConfigReducer = (
  state: ExportConfigState = initialState,
  action: GenericAction
): ExportConfigState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EXPORT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EXPORT_CONFIG_SUCCESS:
      return {
        loading: false,
        pagination: Utils.getPaginationData(action.payload?.pagination),
        data: action.payload.data,
        error: "",
      };
    case Constants.ActionTypes.FETCH_EXPORT_CONFIG_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
  }
  return state;
};

export default exportConfigReducer;
