import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      padding: theme.pxToRem(40),
      overflow: "hidden",
      maxWidth: `${theme.pxToRem(1190)} !important`,
      maxHeight: "100%",
    },
  },
  modalContent: {
    overflowX: "hidden",
  },
  modalCancelBtn: {
    top: theme.pxToRem(40),
    right: theme.pxToRem(40),
  },
  sectionTitle: {
    margin: `0 0 ${theme.pxToRem(17)}`,
    padding: `${theme.pxToRem(17)} 0`,
    borderBottom: `${theme.pxToRem(1)} solid ${
      theme.newPalette.modal.titleBorderColor
    }`,
  },
}));

export default useStyles;
