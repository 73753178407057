import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  modalExtraClass: {
    "& .MuiDialog-container .MuiPaper-root": {
      width: theme.pxToRem(635),
      padding: theme.pxToRem(40),

      "& .flex.flex-column": {
        alignItems: "start",
      },
    },
  },
  toggleContainer: {
    margin: 0,
    gap: theme.pxToRem(15),

    "& .MuiFormControlLabel-label": {
      color: theme.newPalette.input.color,
    },
  },
}));

export default useStyles;
