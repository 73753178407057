import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Popover from "@Components/Popover";
import CreateTagModal from "./CreateTagModal";
import TagPopoverBody from "./TagPopoverBody";

// Containers
import ManageTagModal from "@Containers/ManageTagModal";

// Actions
import { fetchTagList } from "@Store/actions";

// Constants
import { PopoverIds } from "@Constants/Id";

// Styles
import useStyles from "./styles";

const TagPopover = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState("");

  /**
   * Function triggered on changing the value inside the search box.
   */
  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(e.target.value);
  };

  const toggleCreateTagModal = () => {
    setIsCreateModalOpen((prevState) => !prevState);
  };

  const toggleManageTagModal = () => {
    setIsPopoverOpen(false);
    setIsManageModalOpen((prevState) => !prevState);
  };

  const fetchTagsList = () => dispatch(fetchTagList());

  /**
   * When clearing the search string in the popover a UI Jump occurs
   * to prevent this clear the string whenever the popover opens.
   */
  useEffect(() => {
    if (isPopoverOpen) {
      setSearchValue("");
      fetchTagsList();
    }
  }, [isPopoverOpen]);

  return (
    <>
      <Popover
        id={PopoverIds.tagPopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        children={children}
        popoverOpenState={isPopoverOpen}
        setPopoverOpenState={setIsPopoverOpen}
        extraClass={classes.rootContainer}
        popOverChildren={
          <TagPopoverBody
            searchValue={searchValue}
            onToggleOpenPopover={() =>
              setIsPopoverOpen((prevState) => !prevState)
            }
            onInputSearchChange={handleSearchInputChange}
            onCreateTag={toggleCreateTagModal}
            onManageModalToggle={toggleManageTagModal}
          />
        }
      />
      <CreateTagModal
        defaultValue={searchValue}
        isOpen={isCreateModalOpen}
        onClose={toggleCreateTagModal}
        handleTogglePopover={() => setIsPopoverOpen((prevState) => !prevState)}
      />
      <ManageTagModal
        handleClose={toggleManageTagModal}
        isModalOpen={isManageModalOpen}
      />
    </>
  );
};

export default TagPopover;
