import { Theme, darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  pagingButtons: {
    display: "flex",
    gap: theme.pxToRem(5),
    margin: `0 ${theme.pxToRem(5)} 0  ${theme.pxToRem(20)}`,
    "& .MuiIconButton-root": {
      height: theme.pxToRem(29),
      minWidth: theme.pxToRem(29),
      borderRadius: theme.pxToRem(4),
      fontSize: theme.pxToRem(13),
      color: theme.newPalette.text.primary.light,
      "&:hover": {
        backgroundColor: darken(theme.newPalette.appBg, 0.05),
      },
    },

    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      margin: `${theme.pxToRem(8)} 0 0`,
      justifyContent: "center",
    },
  },
  disabledIconButton: {
    opacity: 0.4,
  },
  activePagingButton: {
    opacity: 1,
    backgroundColor: `${theme.newPalette.table.rowBgOnHover} !important`,
    "& .MuiIconButton-label": {
      color: theme.newColors.primary.fieryOrange[500],
    },
  },
  tableFooterRow: {
    marginTop: theme.pxToRem(20),
    "& td": {
      borderBottom: 0,
      [theme.breakpoints.down(Breakpoints.desktopMin)]: {
        display: "block",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        marginTop: 0,
      },
      "& .MuiTypography-root": {
        fontSize: theme.pxToRem(13),
        color: theme.newPalette.text.primary.light,
      },
      "& .MuiTablePagination-select": {
        fontSize: theme.pxToRem(13),
        color: theme.newPalette.text.primary.light,
        paddingBottom: theme.pxToRem(5),
      },
      "& .MuiTablePagination-selectIcon": {
        fill: theme.newPalette.text.primary.light,
        transform: "scale(0.9)",
        top: `calc(50% - ${theme.pxToRem(13)})`,
      },
    },
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "block",
      marginTop: theme.pxToRem(10),
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "block",
      textAlign: "center",
      padding: 0,
    },
  },
  caption: {
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      display: "inline",
      position: "relative",
      top: theme.pxToRem(6),
    },
    "&:nth-child(4)": {
      display: "none",
    },
  },
  hiddenInMobile: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "none",
    },
  },
  iconButton: {
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      padding: theme.pxToRem(4),
    },
  },
  rowsPerPageSelectInput: {
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      margin: 0,
      top: theme.pxToRem(12),
    },
    [theme.breakpoints.between(
      Breakpoints.largeDesktopMin,
      Breakpoints.extraLargeDesktopMin
    )]: {
      marginRight: 0,
    },

    "&.Mui-focused": {
      background: darken(theme.newPalette.table.optionsPopoverBg, 0.05),
    },
  },
  menuItem: {
    background: theme.newPalette.table.optionsPopoverBg,
    color: theme.newPalette.table.color,

    "&:hover, &.Mui-focusVisible": {
      background: theme.newPalette.table.optionsPopoverItemHoverBg,
    },

    "&.Mui-selected, &.Mui-selected:hover": {
      background: darken(theme.newPalette.table.optionsPopoverItemHoverBg, 0.1),
    },
  },
  selectAllItemsCell: {
    position: "absolute",
    padding: `${theme.pxToRem(12)} 0 0`,
    zIndex: 1,
    color: theme.newPalette.text.primary.main,
    maxWidth: `calc(100% - ${theme.pxToRem(640)})`,
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "none !important",
    },
    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      maxWidth: "100%",
      marginTop: `${theme.pxToRem(70)} !important`,
      textAlign: "center",
    },
  },
  selectAllItemsBtn: {
    padding: 0,
    marginLeft: theme.pxToRem(4),
    color: theme.newPalette.error[500],
    "& .MuiButton-label": {
      lineHeight: theme.pxToRem(27),
      [theme.breakpoints.down(Breakpoints.desktopMin)]: {
        lineHeight: theme.pxToRem(25),
      },
    },
    "&:hover": {
      color: theme.newPalette.error[500],
      textDecoration: "underline",
    },
  },
}));

export default useStyles;
