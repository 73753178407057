import { UserManager } from "oidc-react";

import Constants from "@Constants";

function getBaseUrl(
  locationProto: string,
  locationHostName: string,
  locationPort: string
) {
  let locationUrl = `${locationProto}//${locationHostName}`;
  if (locationPort && locationPort != "80") {
    locationUrl += `:${locationPort}`;
  }

  return locationUrl;
}

function getRedirectUrl(
  baseUrl: string | undefined,
  locationPathName: string,
  urlParams: URLSearchParams
) {
  if (urlParams.has("kc_idp_hint")) {
    urlParams.delete("kc_idp_hint");
  }

  const redirectUrl =
    locationPathName != "/"
      ? `${baseUrl}${locationPathName}`
      : `${baseUrl}${Constants.AppRoutes.ProtectedRoutes.Dashboard}`;

  const urlParamsString = urlParams.toString();
  if (urlParamsString) {
    return `${redirectUrl}?${urlParams.toString()}`;
  }

  return redirectUrl;
}

function getAuthority(tenantName: string) {
  const { REACT_APP_BASE_SSO_URL } = process.env;

  return `${REACT_APP_BASE_SSO_URL}/realms/${tenantName}`;
}

function getUserManager() {
  const currentLocation = window.location;
  const urlParams = new URLSearchParams(currentLocation.search);
  const idpHint = urlParams.get("kc_idp_hint");
  const tenantName = "halcyon";
  const baseUrl = getBaseUrl(
    currentLocation.protocol,
    currentLocation.hostname,
    currentLocation.port
  );
  const authority = getAuthority(tenantName);
  const redirectUrl = getRedirectUrl(
    baseUrl,
    currentLocation.pathname,
    urlParams
  );
  const userManagerSettings = {
    url: baseUrl,
    authority: authority,
    redirect_uri: redirectUrl,
    client_id: "console-ui",
    response_type: "code",
    scope: "openid",
    post_logout_redirect_uri: redirectUrl,
    silent_redirect_uri: redirectUrl,
  };

  if (idpHint) {
    Object.assign(userManagerSettings, {
      extraQueryParams: { kc_idp_hint: idpHint },
    });
  }

  return new UserManager(userManagerSettings);
}

export default getUserManager;
