import { Table, TableContainer as MuiTableContainer } from "@material-ui/core";

// Types
import { TableContainerProps } from "./TableContainer";

//Styles
import useStyles from "./TableContainer.styles";

/**
 * TableContainer Component
 * @example Correct usage
 * ```tsx
 * <TableContainer>
 *  {children}
 * </TableContainer>
 * ```
 * @example Correct usage with applied min height
 * ```tsx
 * <TableContainer applyMinHeight>
 *  {children}
 * </TableContainer>
 * ```
 */
export const TableContainer = ({
  id,
  children,
  extraClass = "",
  removeTableShadow = false,
  applyMinHeight = false,
}: TableContainerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiTableContainer
      id={id}
      className={`${classes.tableRoot} ${
        removeTableShadow && classes.hideBoxShadow
      } position-relative ${applyMinHeight && "min-height-400"} ${extraClass}`}
    >
      <Table className="position-relative">{children}</Table>
    </MuiTableContainer>
  );
};
