import Constants from "@Constants";

const initialStateNonPersisted: UserDetailsStateNonPersisted = {
  loading: false,
  error: "",
};

const initialStatePersisted: UserDetailsStatePersisted = {
  username: "",
  email: "",
  id: "",
  fullname: "",
  isEulaAccepted: false,
};

/**
 * Handles logged in user details
 * Here we are keeping UserDetailsStateNonPersisted as non-persisted to exclude it from the local storage
 * @param state
 * @param action
 * @returns UserDetailsStateNonPersisted
 */
const userDetailsReducerNonPersisted = (
  state: UserDetailsStateNonPersisted = initialStateNonPersisted,
  action: GenericAction
): UserDetailsStateNonPersisted => {
  switch (action.type) {
    case Constants.ActionTypes.UPDATE_USER_PASSWORD_REQUEST:
    case Constants.ActionTypes.UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.UPDATE_USER_DETAILS_SUCCESS:
    case Constants.ActionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.UPDATE_USER_DETAILS_FAILED:
    case Constants.ActionTypes.UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
  }

  return state;
};

/**
 * Handles logged in user details
 * Here we are keeping UserDetailsStatePersisted as persisted to store in the local storage
 * @param state
 * @param action
 * @returns UserDetailsStatePersisted
 */
const userDetailsReducerPersisted = (
  state: UserDetailsStatePersisted = initialStatePersisted,
  action: GenericAction
): UserDetailsStatePersisted => {
  switch (action.type) {
    case Constants.ActionTypes.HARBINGER_FETCH_USER_DETAILS_SUCCESS:
      return {
        // TODO: update on complete integration of Harbinger setup
        // Use as last name in harbinger setup
        fullname: action.payload?.lastName,
        email: action.payload?.email,
        id: action.payload?.id,

        // Use as first name in harbinger setup
        username: action.payload?.firstName,
        isEulaAccepted: action.payload?.accepted_eula,
      };
    case Constants.ActionTypes.FETCH_USER_DETAILS_SUCCESS:
      return {
        fullname: action.payload.full_name,
        email: action?.payload?.email,
        id: action?.payload?.id,
        username: action?.payload?.username,
        isEulaAccepted: action.payload?.accepted_eula,
      };
    case Constants.ActionTypes.LOGOUT_SUCCESS:
      return {
        email: "",
        fullname: "",
        id: "",
        username: "",
        isEulaAccepted: false,
      };
  }
  return state;
};

export default {
  userDetailsReducerNonPersisted,
  userDetailsReducerPersisted,
};
