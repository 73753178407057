import { Avatar as MUIAvatar } from "@material-ui/core";

// Styles
import useStyles from "./Avatar.styles";

// Types
import { AvatarProps } from "./Avatar";

/**
 * Avatar Component
 * @example Correct usage
 * ```ts
 * <Avatar id="id" text="JD" />
 * ```
 */
export const Avatar = ({
  text,
  id,
  extraClass = "",
}: AvatarProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MUIAvatar
      id={id}
      className={`${classes.avatar} ${extraClass} font-weight-bold text-uppercase`}
    >
      {text}
    </MUIAvatar>
  );
};
