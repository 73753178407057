// Components
import Cell from "./Cell";
import IconCell from "./IconCell";
import OptionCell from "./OptionsCell";
import SelectionCell from "./SelectionCell";

// Types
import { CellsProps } from "./Cells";

/**
 * Cells Component
 * @example Correct usage
 * ```ts
 * <Cells
 *  headers={headers}
 *  rowData={rowData}
 * />
 * ```
 *
 * @example Correct usage with Icon Column
 * ```ts
 * <Cells
 *  addIconCell
 *  headers={headers}
 *  rowData={rowData}
 *  iconCellConfig={iconCellConfig}
 * />
 * ```
 *
 * @example Correct usage with Options Cell
 * ```ts
 * <Cells
 *  addOptionsCell
 *  headers={headers}
 *  rowData={rowData}
 *  optionsCellConfig={optionsCellConfig}
 * />
 */
export const Cells = ({
  headers,
  rowData,
  iconCellConfig,
  optionsCellConfig,
  handleSelection,
  supportSelection = false,
  isSelected = false,
  isSelectLimitReached = false,
  borderAroundAllCells = false,
  inHorizontalTable = false,
  addOptionsCell = false,
  addIconCell = false,
}: CellsProps): JSX.Element => {
  /**
   * Extracts the cell data from row data using the corresponding header name
   */
  const getCellData = (header: TableHeaderConfig) => {
    return rowData[header.name] as unknown as CellData;
  };

  return (
    <>
      {supportSelection && (
        <SelectionCell
          isSelected={isSelected}
          onChange={(selected) => handleSelection?.(selected, rowData)}
          isSelectLimitReached={isSelectLimitReached}
        />
      )}
      {/* Renders the data cells of the row. */}
      {headers.map(
        (header) =>
          !header.blacklist && (
            <Cell
              key={header.name}
              cellData={getCellData(header)}
              header={header}
              borderAroundCell={borderAroundAllCells}
              rowData={rowData}
              truncateConfig={header.truncateConfig}
              inHorizontalTable={inHorizontalTable}
              showCopyIcon={header.showCopyIconOnHover}
            />
          )
      )}
      {/* Renders the icon cell */}
      {addIconCell &&
        (typeof iconCellConfig?.hidden === "function"
          ? iconCellConfig?.hidden(iconCellConfig?.rowData || {})
          : !iconCellConfig?.hidden) && (
          <IconCell
            iconElement={iconCellConfig?.iconElement}
            onClick={iconCellConfig?.onClick}
            rowData={iconCellConfig?.rowData || {}}
            borderAroundCell={iconCellConfig?.borderAroundCell}
          />
        )}
      {/* Renders the option cell */}
      {addOptionsCell && (
        <OptionCell
          optionsList={optionsCellConfig?.optionsList}
          renderOptions={optionsCellConfig?.renderOptions}
          hideOption={optionsCellConfig?.hideOption}
          rowData={optionsCellConfig?.rowData || {}}
          borderAroundCell={optionsCellConfig?.borderAroundCell}
        />
      )}
    </>
  );
};
