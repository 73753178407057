// Constants
import Constants from "@Constants";
import { EventMetricsPartitionTypes } from "@Constants/common";

// Utils
import Utils from "@Utils";

const initialState: PartitionedEventMetricsState = {
  loading: false,
  partitionType: "",
  partitions: [],
  error: "",
};

const parseDataToPartitionsType = (
  partitionsMap: Record<string, Record<string, number>>,
  partitionType: EventMetricsPartitionTypes
): PartitionedEventMetricsDataItem[] => {
  const partitions: PartitionedEventMetricsDataItem[] = [];

  const getTime = (partition: string) => {
    const dateDetails = Utils.getDetailsFromDateString(partition);
    switch (partitionType) {
      case EventMetricsPartitionTypes.months:
        // slice the month name to get it in Mmm format
        return `${dateDetails.month?.substring(0, 3)} ${dateDetails.year}`;
      case EventMetricsPartitionTypes.days:
        return `${dateDetails.date} ${dateDetails.month.substring(0, 3)}`;
      case EventMetricsPartitionTypes.hours:
        return `${dateDetails.date} ${dateDetails.month.substring(0, 3)}, ${
          dateDetails.hours
        }:${dateDetails.minutes}`;
      case EventMetricsPartitionTypes.weeks:
        return `${dateDetails.date} ${dateDetails.month.substring(0, 3)}`;
    }
  };

  Object.keys(partitionsMap).map((partition: string) => {
    partitions.push({
      when: getTime(partition),
      total: partitionsMap[partition]?.total || 0,
      allowed: partitionsMap[partition]?.allowed || 0,
      blocked: partitionsMap[partition]?.blocked || 0,
      // Uncomment to integrate sandbox events
      // sandboxed: partitionsMap[partition]?.sandboxed || 0,
    });
  });

  return partitions.reverse();
};

const partitionedEventMetricsReducer = (
  state: PartitionedEventMetricsState = initialState,
  action: GenericAction
): PartitionedEventMetricsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_REQUEST:
      return { ...state, loading: true };
    case Constants.ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_SUCCESS:
      return {
        loading: false,
        partitionType: action.payload?.partition_type,
        partitions: parseDataToPartitionsType(
          action.payload?.partitions,
          action.payload?.partition_type
        ),
        error: "",
      };
    case Constants.ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_FAILED:
      return {
        loading: false,
        partitionType: "",
        partitions: [],
        error: action.payload,
      };
  }
  return state;
};

export default partitionedEventMetricsReducer;
