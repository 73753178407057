import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  modalExtraClass: {
    "& .MuiDialog-container .MuiPaper-root": {
      padding: theme.pxToRem(30),
      maxWidth: theme.pxToRem(400),
    },
  },
  checkboxWrapper: { margin: `${theme.pxToRem(10)} 0` },
  checkbox: {
    "&.Mui-checked": {
      color: theme.newColors.fieryOrange,
    },
  },
  subtitle: {
    margin: 0,
  },
  button: {
    flexGrow: 1,
  },
}));

export default useStyles;
