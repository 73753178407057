import { createStore, applyMiddleware, Store, AnyAction } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducers";

// env variables
import envVariables from "@Config";

// Configure redux store with redux persist
let mainStore: Store<GlobalState | undefined, AnyAction> & {
  dispatch: DispatchType;
};

if (envVariables.IS_DEV_MODE) {
  mainStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
} else {
  mainStore = createStore(rootReducer, applyMiddleware(thunk));
}

const persistedStore = persistStore(mainStore);

export default { mainStore, persistedStore };
