// Components
import Button from "@Components/Button";
import { ProgressButtonProps } from "./ProgressButton";

// Styles
import useStyles from "./ProgressButton.styles";

/**
 * ProgressButton Component
 * @example Correct usage
 * <ProgressButton
 *  id={uniqueId}
 *  progress={progress}
 * />
 */
export const ProgressButton = ({
  extraClass: buttonExtraClass,
  progressExtraClass,
  progress,
  ...restProps
}: ProgressButtonProps) => {
  const classes = useStyles({ progress });

  return (
    <Button {...restProps} extraClass={`${classes.button} ${buttonExtraClass}`}>
      <div className={`${classes.progress} ${progressExtraClass}`}></div>
      <span className={classes.buttonText}>Undo</span>
    </Button>
  );
};
