import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Components
import Modal from "@Components/Modal";
import Tabs from "@Components/Tabs";

import InstallationToken from "./subComponents/installationTokenPanel";
import AgentVersions from "./subComponents/versionsPanel";

// Containers
import ErrorBoundary from "@Containers/errorBoundary";

// Actions
import { fetchAgentInstallerDetails } from "@Store/actions";

// Constants
import { ModalIds, TabIds } from "@Constants/Id";

// Styles
import useStyles from "./styles";

// Props type
type MultiVersionDownloadContainerProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const MultiVersionDownloadContainer = ({
  handleClose,
  isOpen,
}: MultiVersionDownloadContainerProps): JSX.Element => {
  const classes = useStyles();

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Trigger dispatching the action to fetch data, when the component loads
  useEffect(() => {
    if (isOpen) {
      // Dispatch action to fetch agent installer details
      dispatch(fetchAgentInstallerDetails());
    }
  }, [isOpen]);

  const tabsData: TabsData[] = [
    {
      tab: {
        label: "Download Halcyon Agent",
      },
      tabPanel: <AgentVersions />,
      id: TabIds.agentVersions,
    },
    {
      tab: {
        label: "Get your installation token",
      },
      tabPanel: <InstallationToken />,
      id: TabIds.installationToken,
    },
  ];

  return (
    <Modal
      id={ModalIds.downloadAgent}
      title="Download Halcyon Agent"
      isOpen={isOpen}
      close={handleClose}
    >
      <ErrorBoundary>
        <Tabs
          buttonTab
          tabs={tabsData}
          containerClassName="text-align-center"
          tabClassName={classes.tabs}
          tabPanelClassName="margin-top-30"
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default MultiVersionDownloadContainer;
