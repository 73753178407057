import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { deleteDevice, resetDeviceDeleted } from "@Store/actions";

// Components
import Button from "@Components/Button";
import ConfirmationDialog from "@Components/ConfirmationDialog";

// Constants
import Constants from "@Constants";
import { SuccessMessages } from "@Constants/common";
import { ButtonIDs, ConfirmationDialogIds } from "@Constants/Id";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";

// Styles
import useStyles from "./styles";

const DeleteDevice = () => {
  // get the details about the device from the store
  const { infoDetails, loading } = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const dispatch = useDispatch();
  const deviceDeleted = useSelector((state: GlobalState) => state.deleteDevice);
  const history = useCustomHistory();
  const classes = useStyles();

  const handleDeleteConfirmation = async () => {
    await dispatch(
      deleteDevice(infoDetails.id as string, {
        message: SuccessMessages.deviceDeleted,
        sideEffect: () => {
          dispatch(resetDeviceDeleted());

          if ((history.location.state as any)?.from) {
            history.replace(history.location.state.from.pop());
            return;
          }

          history.push(Constants.AppRoutes.ProtectedRoutes.Dashboard);
        },
      })
    );
    setDeleteConfirmationOpen(false);
  };

  // When this device is deleted, this page should be unmounted
  useEffect(() => {
    if (!deviceDeleted.isDeleted) {
      return;
    }
    setTimeout(() => {
      // reset the state for deleting the device
    }, 2000);
  }, [deviceDeleted.isDeleted]);

  return (
    <>
      <Button
        id={ButtonIDs.deleteDevice}
        onClick={() => setDeleteConfirmationOpen(true)}
        disabled={loading}
        variant="outlined"
        color="primary"
        extraClass={classes.deleteButton}
      >
        Delete
      </Button>

      <ConfirmationDialog
        id={ConfirmationDialogIds.deleteDevice}
        isOpen={deleteConfirmationOpen}
        handleClose={() => setDeleteConfirmationOpen(false)}
        alertTitle="Are you sure?"
        alertDescription="Do you really want to delete this Device? This action cannot be undone."
        confirmButtonText="Delete"
        handleConfirmation={handleDeleteConfirmation}
      />
    </>
  );
};

export default DeleteDevice;
