import { Typography } from "@material-ui/core";

// Components
import BaseCard from "@Components/Card";
import Link from "@Components/Link";
import SparkLineGraph from "@Components/Graphs/SparkLineGraph";

// Styles
import useStyles from "./DashboardCard.styles";

// Types
import { DashboardCardProps } from "./Dashboard";

// Constants
import { GraphsIds } from "@Constants/Id";

/**
 * DashboardCard - the card component for the dashboard
 * @example Correct Usage
 * ```ts
 * <DashboardCard
 *  id="id"
 *  title="Dashboard Card"
 *  statsNumber={200}
 *  graph={{
 *    data: graphData,
 *    dataKey: keyInGraphData
 *  }}
 *  link={{
 *    text: "Link text",
 *    url: redirectURL,
 *  }}
 * />
 * ```
 */
export const DashboardCard = ({
  id,
  title,
  statsNumber,
  link,
  graph,
  extraClass = "",
}: DashboardCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <BaseCard id={id} extraClass={`${classes.cardWrapper} ${extraClass}`}>
      <div className="d-flex">
        <div className={classes.subPart}>
          <Typography
            id={`${id}-title`}
            variant="subtitle2"
            component="h2"
            className={`${classes.cardTitle} text-capitalize`}
          >
            {title}
          </Typography>
          <Typography
            id={`${id}-stats-number`}
            variant="h1"
            component="h3"
            className={`${classes.number} text-truncate`}
          >
            {statsNumber}
          </Typography>
        </div>
        {graph && (
          <div className={`${classes.subPart} ${classes.graph} flex`}>
            <SparkLineGraph
              id={GraphsIds.sparkLineGraph({ dataKey: graph.dataKey })}
              data={graph.data}
              dataKey={graph.dataKey}
              marginBottom={20}
              strokeColor={graph.color}
            />
          </div>
        )}
      </div>
      <div className={classes.footer}>
        {link?.url && link?.text && (
          <Link
            id={`${id}-link`}
            link={link.url}
            extraClass={`${classes.link} text-capitalize`}
            showCaret
          >
            {link.text}
          </Link>
        )}
      </div>
    </BaseCard>
  );
};
