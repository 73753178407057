// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import Constants from "@Constants";
import { SVGIconIds } from "@Constants/Id";

// Types
import { DownloadButtonProps } from "./DownloadButton";

// Styles
import useStyles from "@Components/LeftMenu/SubComponents/DownloadAgentCard/DownloadAgentCard.styles";

/**
 * DownloadButton component
 * @example Correct usage
 * ```tsx
 * <DownloadButton
 *  onClick={handleOnClick}
 *  text={buttonText}
 * />
 * ```
 */
export const DownloadButton = ({ text, id, onClick }: DownloadButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      id={id}
      onClick={onClick}
      extraClass={classes.button}
      startIcon={
        <SVGWrapper
          height={16}
          width={16}
          viewBox="0 0 16 16"
          fill="inherit"
          id={SVGIconIds.downloadIcon}
        >
          {Constants.SVGIcons.downloadIcon}
        </SVGWrapper>
      }
    >
      {text}
    </Button>
  );
};
