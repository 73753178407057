import { Card as MuiCard } from "@material-ui/core";

// Styles
import useStyles from "./Card.styles";

// Types
import { BaseCardProps } from "./Card";

/**
 * BaseCard Component - Wrapper component for cards
 * @example Correct Usage
 * ```ts
 * <Card id="card">
 *  <div>Card Body</div>
 * </Card>
 * ```
 */
export const Card = ({
  id,
  children,
  extraClass = "",
}: BaseCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiCard id={id} className={`${classes.baseCard} ${extraClass}`}>
      {children}
    </MuiCard>
  );
};
