import Constants from "@Constants";
import Utils from "@Utils";

const initialState: CriticalEventsState = {
  loading: false,
  events: [],
  error: "",
};

/**
 * Handles actions of Critical Events Fetching
 * @param state CriticalEventsState
 * @param action GenericAction
 * @returns CriticalEventsState
 */
const criticalEventsReducer = (
  state: CriticalEventsState = initialState,
  action: GenericAction
): CriticalEventsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_CRITICAL_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_CRITICAL_EVENTS_SUCCESS:
      return {
        loading: false,
        events: action?.payload?.data,
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_CRITICAL_EVENTS_FAILED:
      return {
        loading: false,
        events: [],
        error: action?.payload,
      };
  }
  return state;
};

export default criticalEventsReducer;
