import ActionTypes from "@Constants/actionTypes";

const initialState: CurrentAccessLevelState = {
  accessLevel: "User",
};

const currentAccessLevelReducer = (
  state: CurrentAccessLevelState = initialState,
  action: GenericAction
): CurrentAccessLevelState => {
  switch (action.type) {
    case ActionTypes.UPDATE_ACCESS_LEVEL:
      return action.payload;
  }

  return state;
};

export default currentAccessLevelReducer;
