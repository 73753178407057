import Constants from "@Constants";

const initialState: LastActivityState = {
  lastActivity: new Date(),
};

const userActivityReducer = (
  state: LastActivityState = initialState,
  action: GenericAction
): LastActivityState => {
  switch (action.type) {
    case Constants.ActionTypes.USER_ACTIVE:
      return {
        lastActivity: new Date(),
      };
    case Constants.ActionTypes.CLEAR_USER_ACTIVITY:
      return {
        lastActivity: null,
      };
  }

  return state;
};

export default userActivityReducer;
