import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import EventFeed from "./EventFeed";
import IdentityProvider from "./IdentityProvider";

// Constants
import { SettingsPageTabs } from "@Constants/common";

// Hooks
import useTab from "@Hooks/useTab";

// Utils
import Utils from "@Utils";

const Settings = () => {
  const tabs = useTab();
  const location = useLocation();

  // Get query params from location
  const queryParams = new URLSearchParams(location.search);

  // Set the tab from query-params if it is valid, otherwise set it to default, which is EventFeed.
  useEffect(() => {
    const tabNameFromQueryParam =
      Utils.getTabStringFromQueryParams(queryParams);
    const tabName = (((tabNameFromQueryParam === SettingsPageTabs.EventFeed ||
      tabNameFromQueryParam === SettingsPageTabs.IdentityProvider) &&
      tabNameFromQueryParam) ||
      SettingsPageTabs.EventFeed) as unknown as SettingsPageTabs;
    tabs.setActivePageTab(tabName);
  }, []);

  return (
    <>
      {tabs.currentlyActivePageTab === SettingsPageTabs.EventFeed && (
        <EventFeed />
      )}
      {tabs.currentlyActivePageTab === SettingsPageTabs.IdentityProvider && (
        <IdentityProvider />
      )}
    </>
  );
};

export default Settings;
