import { makeStyles, Theme } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  appWrapper: {
    background: theme.newPalette.appBg,
    maxWidth: "100vw",
    color: theme.newPalette.text.primary.main,
  },
  container: {
    marginLeft: theme.pxToRem(252),
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      marginLeft: theme.pxToRem(204),
    },
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      margin: "auto",
      maxWidth: theme.pxToRem(480),
    },
  },
}));

export default useStyles;
