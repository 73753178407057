import { useDispatch } from "react-redux";

// Actions
import { handleSetLastActivePageTab } from "@Store/actions";

// Components
import Button from "@Components/Button";

// Styles
import useStyles from "@Components/Header/Header.styles";

// constants
import { ButtonIDs, HeaderIds } from "@Constants/Id";

// Types
import { InPageTabsProps } from "./InPageTabs";

/**
 * InPageTabs component - used for rendering the in-page tabs below the header
 *
 * @example Correct usage
 * ```ts
 * <InPageTabs
 *  pageTabs={pageTabsConfig}
 *  activePageTab={activePageTab}
 *  setActivePageTab={handleActivePageTabChange}
 * />
 * ```
 */
export const InPageTabs = ({
  extraClass,
  pageTabs,
  activePageTab,
  setActivePageTab,
}: InPageTabsProps): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();
  return (
    <div
      id={HeaderIds.inPageTabs}
      className={`${classes.tabWrapper} ${extraClass} display-inline-block`}
    >
      {pageTabs.map((pageTab, index) => (
        <Button
          id={ButtonIDs.inPageTabs({ pageTab: pageTab.name })}
          disableElevation
          isTabButton
          key={index}
          variant="contained"
          isTabActive={activePageTab === pageTab.name}
          onClick={() => {
            dispatch(handleSetLastActivePageTab());
            setActivePageTab?.(pageTab.name);
          }}
        >
          {pageTab.title}
        </Button>
      ))}
    </div>
  );
};
