import Constants from "@Constants";

const initialState: TabState = {
  currentlyActive: "",
  currentlyActivePageTab: "",
  replacePageTab: false,
  lastActivePageTab: "",
  // When in-page tab changes, blacklisted params will not be added to the URL while updating the query params
  blacklistParams: [],
};

/**
 * Handles tab actions
 * @param state
 * @param action
 * @returns TabState
 */
const tabReducer = (
  state: TabState = initialState,
  action: GenericAction
): TabState => {
  switch (action.type) {
    case Constants.ActionTypes.SET_CURRENTLY_ACTIVE_TAB:
      return {
        ...state,
        currentlyActive: action?.payload,
      };
    case Constants.ActionTypes.SET_LAST_ACTIVE_PAGE_TAB:
      return {
        ...state,
        lastActivePageTab: state.currentlyActivePageTab,
      };
    case Constants.ActionTypes.SET_CURRENTLY_ACTIVE_PAGE_TAB:
      return {
        ...state,
        currentlyActivePageTab: action?.payload.tabName,
        replacePageTab: action?.payload?.replace,
        blacklistParams: action.payload?.blacklistParams,
      };
    case Constants.ActionTypes.RESET_REPLACE_PAGE_TAB:
      return {
        ...state,
        replacePageTab: false,
      };
    case Constants.ActionTypes.RESET_BLACKLISTED_PARAMS:
      return {
        ...state,
        blacklistParams: [],
      };
  }

  return state;
};

export default tabReducer;
