import { Tooltip as MUITooltip } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

// Theme
import theme from "@Theme";

// Types
import { TooltipType } from "./Tooltip";

// Styles
import useStyles from "./Tooltip.styles";

/**
 * Tooltip Component
 * @example Correct usage
 * ```tsx
 * <Tooltip
 *  title={title}
 *  placement={placement}
 * />
 * ```
 * @example Correct usage arrow
 * ```tsx
 * <Tooltip
 *  showArrow
 *  title={title}
 *  placement={placement}
 * />
 */
export const Tooltip = ({
  title,
  showArrow,
  isOpen,
  tooltipChildren,
  textColor,
  backgroundColor = theme.newColors.purpleNavy,
  placement = "right",
  tooltipPopperExtraClass = "",
  ...props
}: TooltipType): JSX.Element => {
  const classes = useStyles({
    backgroundColor: backgroundColor,
    textColor: textColor,
  });

  return (
    <MUITooltip
      title={title}
      arrow={showArrow}
      placement={placement}
      open={isOpen}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 400 }}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
        popper: tooltipPopperExtraClass,
      }}
      interactive
      {...props}
    >
      {tooltipChildren || <span className={classes.hideChildren}>.</span>}
    </MUITooltip>
  );
};
