import { useDispatch, useSelector } from "react-redux";
import { TableBody, TableRow, TableHead } from "@material-ui/core";

// Actions
import {
  addOrRemoveRow,
  resetAllSelectedPages,
  selectAllPages,
  selectRows,
} from "@Store/actions";

// Components
import EmptyState from "@Components/EmptyState";
import { RowSkeletonLoader } from "../RowSkeletonLoader/RowSkeletonLoader.component";
import TablePagination from "../TablePagination";
import TableContainer from "../TableContainer";
import Cells from "../Cells";
import Headers from "../Headers";

// Types
import { PaginatedTableProps } from "./PaginatedTable";

//Styles
import useStyles from "./PaginatedTable.styles";

// Hooks
import usePagination from "@Hooks/usePagination";

// Constants
import { MaximumDevicesThatCanBeTaggedOnce } from "@Constants/common";
import { EmptyStateIds } from "@Constants/Id";

/**
 * PaginatedTable Component
 * @example Correct usage
 * ```tsx
 * <PaginatedTable
 *  showPagination
 *  rowsData={rowsData}
 *  headers={headersConfig}
 *  pagination={paginationConfig}
 * />
 * ```
 *
 * @example Correct usage with icon column
 * ```tsx
 * <PaginatedTable
 *  showPagination
 *  rowsData={rowsData}
 *  headers={headersConfig}
 *  pagination={paginationConfig}
 *  iconColumn={iconColumnConfig}
 * />
 * ```
 *
 * @example Correct usage with options column
 * ```tsx
 * <PaginatedTable
 *  showPagination
 *  rowsData={rowsData}
 *  headers={headersConfig}
 *  pagination={paginationConfig}
 *  options={optionsConfig}
 * />
 * ```
 */
export const PaginatedTable = ({
  id,
  rowsData,
  handleRowClick,
  headers,
  options,
  hideOption,
  iconColumn,
  pagination,
  selection,
  extraClass = "",
  optionsHeaderName = "",
  borderAroundAllCells = false,
  showPagination = false,
  isLoading = false,
  removeTableShadow = false,
}: PaginatedTableProps): JSX.Element => {
  const classes = useStyles();
  const { rows: selectedRows, arePagesSelected } = useSelector(
    (state: GlobalState) => state.selectedRows
  );
  const dispatch = useDispatch();
  const { noOfRowsPerPage } = usePagination({});

  /**
   * Checks if all rows are selected in the current page of the table.
   * @returns `true` | `false`
   */
  const areAllRowsSelectedInCurrentPage = (): boolean =>
    /**
     * Checking the intersection between the selected rows and the current table rows
     * If the length of the intersection is equal to the table rows then all rows are selected.
     */
    rowsData.filter(({ id: { value } }) => selectedRows.includes(value))
      .length === rowsData.length;

  const currentPageSelectedRows = rowsData
    .filter((data) =>
      selectedRows.includes(data[selection?.customRowId || "rowId"]?.value)
    )
    .map((data) => data[selection?.customRowId || "rowId"]?.value);

  // `true` if current page have selected rows
  const indeterminateState =
    selection?.isEnabled &&
    !!currentPageSelectedRows.length &&
    currentPageSelectedRows.length !== rowsData.length;

  /**
   * Handles updating the selected rows when the user clicks on any checkbox
   * @param isSelected - If the current state of the checkbox is selected or not
   * @param toggleAllRows - If the user clicked the checkbox in the header
   * @param rowData - The data of the row for which the checkbox is clicked
   */
  const onChange = (
    isSelected: boolean,
    toggleAllRows?: boolean,
    rowData?: RowData
  ) => {
    if (toggleAllRows) {
      const allRowsInCurrentPage = rowsData.map(
        (data) => data[selection?.customRowId || "rowId"].value
      );

      /**
       * If in indeterminate state then deselects all the rows on the current page only.
       */
      if (indeterminateState) {
        const selectedRowsWithCurrentPageRowsRemoved = selectedRows.filter(
          (rowId) => !currentPageSelectedRows.includes(rowId)
        );
        dispatch(selectRows(selectedRowsWithCurrentPageRowsRemoved));
        return;
      }

      /**
       * If isSelected = `true` then select all the rows in the current page only.
       */
      if (isSelected) {
        dispatch(selectRows([...allRowsInCurrentPage, ...selectedRows]));
        return;
      }

      if (arePagesSelected) {
        dispatch(resetAllSelectedPages());
        return;
      }

      /**
       * If isSelected = `false` then deselect all the rows in the current page only.
       */
      const rowsWithSelectedRowsRemoved = selectedRows.filter(
        (rowId) => !allRowsInCurrentPage.includes(rowId)
      );

      dispatch(selectRows(rowsWithSelectedRowsRemoved));
    } else if (rowData) {
      dispatch(
        addOrRemoveRow(
          rowData[selection?.customRowId || "rowId"].value,
          !isSelected
        )
      );
    }
  };

  const isHeaderLimitReached =
    selectedRows?.length + noOfRowsPerPage > MaximumDevicesThatCanBeTaggedOnce;

  const isSelectDeviceLimitReached =
    selectedRows.length >= MaximumDevicesThatCanBeTaggedOnce;

  return rowsData.length !== 0 || isLoading ? (
    <TableContainer
      removeTableShadow={removeTableShadow}
      extraClass={`${classes.tableContainer} ${extraClass}`}
      applyMinHeight={isLoading || rowsData?.length === 0}
      id={id}
    >
      <TableHead>
        {headers && headers.length !== 0 && (
          <TableRow className={classes.headerRow}>
            <Headers
              headers={headers}
              isLoading={isLoading}
              addOptionsHeader={
                options?.list && !!options.list.length && options.shouldRender
              }
              optionsHeaderName={optionsHeaderName}
              addIconsHeader={!!iconColumn}
              iconsHeaderName={iconColumn?.columnHeading}
              supportSelection={selection?.isEnabled}
              areAllRowsSelected={
                arePagesSelected ||
                (!!selectedRows.length && areAllRowsSelectedInCurrentPage())
              }
              handleRowsSelection={onChange}
              isIndeterminateState={indeterminateState}
              isSelectLimitReached={isHeaderLimitReached}
            />
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {rowsData &&
          rowsData.length !== 0 &&
          !isLoading &&
          rowsData.map((data, index) => (
            <TableRow
              key={index + 1}
              className={`${classes.row} ${handleRowClick && "cursor-pointer"}`}
              onClick={() => handleRowClick?.(data)}
            >
              <Cells
                rowData={data}
                headers={headers}
                borderAroundAllCells={borderAroundAllCells}
                addIconCell={!!iconColumn}
                iconCellConfig={{
                  iconElement: iconColumn?.iconElement,
                  onClick: iconColumn?.onClick,
                  rowData: data,
                  borderAroundCell: borderAroundAllCells,
                  hidden: iconColumn?.hidden,
                }}
                addOptionsCell={options?.list && options.list.length > 0}
                optionsCellConfig={{
                  optionsList: options?.list,
                  renderOptions: options?.shouldRender,
                  hideOption,
                  rowData: data,
                  borderAroundCell: borderAroundAllCells,
                }}
                supportSelection={selection?.isEnabled}
                isSelected={
                  selectedRows.includes(
                    data[selection?.customRowId || "rowId"]?.value
                  ) || arePagesSelected
                }
                handleSelection={(checked, rowData) =>
                  onChange(checked, false, rowData)
                }
                isSelectLimitReached={isSelectDeviceLimitReached}
              />
            </TableRow>
          ))}
        {isLoading && <RowSkeletonLoader headers={headers} />}
        {/*TODO(YM): OPTIONAL(can add colspan for more white columns)
          {emptyRows > 0 && (
            <TableRow style={{ height: 30 * emptyRows }}>
              <TableCell colSpan={totalHeader} />
            </TableRow>
          )} */}
      </TableBody>
      {!isLoading &&
        rowsData.length > 0 &&
        showPagination &&
        pagination &&
        pagination.currentPageNumber <= (pagination.lastPageNumber || 1) && (
          <TablePagination
            {...pagination}
            showAllPagesSelectionOption={
              !!selectedRows.length && areAllRowsSelectedInCurrentPage()
            }
            handleAllPagesSelection={() =>
              arePagesSelected
                ? dispatch(resetAllSelectedPages())
                : dispatch(selectAllPages())
            }
            areAllPagesSelected={arePagesSelected}
            id={`${id}-pagination`}
          />
        )}
    </TableContainer>
  ) : (
    <EmptyState id={EmptyStateIds.paginatedTable} />
  );
};
