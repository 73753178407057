import { alpha, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: `min(${theme.pxToRem(426)}, 90vw)`,
    backgroundColor: alpha(theme.colors.black, 0.8),
    color: theme.colors.white,
  },

  warnIcon: {
    alignItems: "center",
    padding: `${theme.pxToRem(4)} ${theme.pxToRem(6)}`,
    borderRadius: theme.pxToRem(12),
    backgroundColor: alpha(theme.newColors.fieryOrange, 0.9),
    gap: theme.pxToRem(3),

    "&:hover": {
      backgroundColor: theme.newColors.fieryOrange,
    },
  },

  warningText: {
    color: theme.newColors.neutral[700],
    ...theme.utils.bodyText3(),
    ...theme.utils.semiBoldText(),
    lineHeight: theme.pxToRem(12),
  },

  maskedText: {
    "&:hover": {
      textDecoration: "underline",
    },
  },

  copyIcon: {
    "&.Mui-focusVisible svg": {
      fill: theme.newColors.primary.fieryOrange[500],
    },
  },
}));

export default useStyles;
