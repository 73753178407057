import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    position: "relative",
    "& .MuiFormHelperText-contained": {
      position: "absolute",
      bottom: theme.pxToRem(-20),
    },
  },
  selectField: {
    margin: `0 0 ${theme.pxToRem(2)}!important`,
  },
  visibilityIcon: {
    color: theme.newPalette.input.placeholderColor,
  },
  encryptionField: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 100ms ease-out",
  },
  encryptionFieldsExpanded: {
    paddingTop: theme.pxToRem(16),
    maxHeight: theme.pxToRem(250),
    height: theme.pxToRem(210),
  },
}));

export default useStyles;
