import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    background: theme.newPalette.filterAutoSuggest.background,

    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
  },

  autoComplete: {
    minWidth: `min(100%, ${theme.pxToRem(380)})`,
    maxWidth: `min(100%, ${theme.pxToRem(650)})`,

    "& .MuiInputBase-root": {
      paddingRight: `0 !important`,

      "& .MuiInputBase-input": {
        color: theme.newPalette.text.primary.main,
      },
    },

    "& .MuiOutlinedInput-root": {
      width: "100%",
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.newPalette.filterAutoSuggest.border,
      background: "transparent",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.newPalette.filterAutoSuggest.borderActive}!important`,
    },

    "& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.newPalette.filterAutoSuggest.borderActive,
      },

    "& .MuiFormLabel-root": {
      color: theme.newPalette.filterAutoSuggest.border,

      "&.Mui-focused": {
        color: theme.newPalette.filterAutoSuggest.borderActive,
      },
    },

    "& .Mui-error": {
      "&.MuiFormLabel-root": {
        color: `${theme.newColors.primary.poppyRed[500]}!important`,
      },

      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.newColors.primary.poppyRed[500]}!important`,
      },
    },
  },

  startAdornment: {
    padding: theme.pxToRem(12),

    "& svg path": {
      stroke: theme.newPalette.text.secondary.light,
    },
  },

  endAdornment: {
    paddingRight: theme.pxToRem(2),

    "& svg path": {
      fill: theme.newPalette.text.secondary.light,
    },
  },

  errorMessage: {
    color: theme.newColors.primary.poppyRed[500],
    fontSize: theme.pxToRem(12),
    margin: `${theme.pxToRem(2)} 0 ${theme.pxToRem(-14)} ${theme.pxToRem(14)}`,
  },

  optionsPopper: {
    "& div": {
      background: theme.newPalette.filterAutoSuggest.popoverBg,
      color: theme.newPalette.text.primary.main,
      boxShadow: `0 ${theme.pxToRem(29)} ${theme.pxToRem(55)} ${
        theme.newPalette.popover.shadowColor
      }`,
    },

    "& ul": {
      background: theme.newPalette.filterAutoSuggest.popoverBg,

      "& li": {
        color: theme.newPalette.text.primary.main,
      },

      "& li:hover": {
        background: theme.newPalette.select.optionsPopoverItemHoverBg,
      },
    },
  },

  autoCompleteOption: {
    '&[data-focus="true"]': {
      background: theme.newPalette.select.optionsPopoverItemHoverBg,
    },
  },
}));

export default useStyles;
