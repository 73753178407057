// All forms validation schemas are defined here

import * as yup from "yup";
import Constants from "@Constants";

// Add user form
export const addUserFormValidation = yup.object({
  [Constants.FormFields.AddAndEditUser.fullName.name]: yup
    .string()
    .trim()
    .max(
      Constants.FormFields.AddAndEditUser.fullName.maxCharacters,
      Constants.FormFields.AddAndEditUser.fullName.validationMessages.length
    )
    .required(
      Constants.FormFields.AddAndEditUser.fullName.validationMessages.required
    ),
  [Constants.FormFields.AddAndEditUser.username.name]: yup
    .string()
    .max(
      Constants.FormFields.AddAndEditUser.fullName.maxCharacters,
      Constants.FormFields.AddAndEditUser.fullName.validationMessages.length
    )
    .required(
      Constants.FormFields.AddAndEditUser.username.validationMessages.required
    ),
  [Constants.FormFields.AddAndEditUser.email.name]: yup
    .string()
    .required(
      Constants.FormFields.AddAndEditUser.email.validationMessages.required
    )
    .email(Constants.FormFields.AddAndEditUser.email.validationMessages.regex)
    .max(
      Constants.FormFields.AddAndEditUser.email.maxCharacters,
      Constants.FormFields.AddAndEditUser.email.validationMessages.length
    ),
  [Constants.FormFields.AddAndEditUser.password.name]: yup
    .string()
    .matches(
      Constants.Regex.Password,
      Constants.FormFields.AddAndEditUser.password.validationMessages.regex
    )
    .required(
      Constants.FormFields.AddAndEditUser.password.validationMessages.required
    ),
  [Constants.FormFields.AddAndEditUser.confirmPassword.name]: yup
    .string()
    .oneOf(
      [yup.ref(Constants.FormFields.AddAndEditUser.password.name), null],
      Constants.FormFields.AddAndEditUser.confirmPassword.validationMessages
        .mismatch
    )
    .required(
      Constants.FormFields.AddAndEditUser.confirmPassword.validationMessages
        .required
    ),
});

// Add user form Harbinger
export const addUserFormValidationHarbinger = yup.object({
  [Constants.FormFields.AddAndEditUser.email.name]: yup
    .string()
    .required(
      Constants.FormFields.AddAndEditUser.email.validationMessages.required
    )
    .email(Constants.FormFields.AddAndEditUser.email.validationMessages.regex)
    .max(
      Constants.FormFields.AddAndEditUser.email.maxCharacters,
      Constants.FormFields.AddAndEditUser.email.validationMessages.length
    ),
});

// Add or Edit tenant details
export const tenantForm = yup.object({
  [Constants.FormFields.AddTenant.fullName.name]: yup
    .string()
    .trim()
    .required(
      Constants.FormFields.AddTenant.fullName.validationMessages.required
    )
    .min(
      Constants.FormFields.AddTenant.fullName.minCharacters,
      Constants.FormFields.AddTenant.fullName.validationMessages.length
    )
    .max(
      Constants.FormFields.AddTenant.fullName.maxCharacters,
      Constants.FormFields.AddTenant.fullName.validationMessages.length
    ),
  [Constants.FormFields.AddTenant.alias.name]: yup
    .string()
    .trim()
    .required(Constants.FormFields.AddTenant.alias.validationMessages.required)
    .min(
      Constants.FormFields.AddTenant.alias.minCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    )
    .max(
      Constants.FormFields.AddTenant.alias.maxCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    ),
  [Constants.FormFields.AddTenant.alias.name]: yup
    .string()
    .trim()
    .required(Constants.FormFields.AddTenant.alias.validationMessages.required)
    .min(
      Constants.FormFields.AddTenant.alias.minCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    )
    .max(
      Constants.FormFields.AddTenant.alias.maxCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    ),
  [Constants.FormFields.AddTenant.alias.name]: yup
    .string()
    .trim()
    .required(Constants.FormFields.AddTenant.alias.validationMessages.required)
    .min(
      Constants.FormFields.AddTenant.alias.minCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    )
    .max(
      Constants.FormFields.AddTenant.alias.maxCharacters,
      Constants.FormFields.AddTenant.alias.validationMessages.length
    ),
});

// Update user details form
export const updateUserDetailsFormValidation = yup.object({
  [Constants.FormFields.AddAndEditUser.fullName.name]: yup
    .string()
    .trim()
    .required(
      Constants.FormFields.AddAndEditUser.fullName.validationMessages.required
    )
    .max(
      Constants.FormFields.AddAndEditUser.fullName.maxCharacters,
      Constants.FormFields.AddAndEditUser.fullName.validationMessages.length
    ),
  [Constants.FormFields.AddAndEditUser.email.name]: yup
    .string()
    .required(
      Constants.FormFields.AddAndEditUser.email.validationMessages.required
    )
    .email(Constants.FormFields.AddAndEditUser.email.validationMessages.regex)
    .max(
      Constants.FormFields.AddAndEditUser.email.maxCharacters,
      Constants.FormFields.AddAndEditUser.email.validationMessages.length
    ),
});

// Update user details form harbinger
export const harbingerUpdateUserDetailsFormValidation = yup.object({
  [Constants.FormFields.UpdateProfile.firstName.name]: yup
    .string()
    .trim()
    .required(
      Constants.FormFields.UpdateProfile.firstName.validationMessages.required
    )
    .max(
      Constants.FormFields.UpdateProfile.firstName.maxCharacters,
      Constants.FormFields.UpdateProfile.firstName.validationMessages.length
    ),
  [Constants.FormFields.UpdateProfile.lastName.name]: yup
    .string()
    .required(
      Constants.FormFields.UpdateProfile.lastName.validationMessages.required
    )
    .max(
      Constants.FormFields.UpdateProfile.lastName.maxCharacters,
      Constants.FormFields.UpdateProfile.lastName.validationMessages.length
    ),
});

// Update user password form
export const updatePasswordFormValidation = yup.object({
  [Constants.FormFields.UpdatePassword.oldPassword.name]: yup
    .string()
    .required(
      Constants.FormFields.UpdatePassword.oldPassword.validationMessages
        .required
    ),
  [Constants.FormFields.UpdatePassword.newPassword.name]: yup
    .string()
    .matches(
      Constants.Regex.Password,
      Constants.FormFields.UpdatePassword.newPassword.validationMessages.regex
    )
    .required(
      Constants.FormFields.UpdatePassword.newPassword.validationMessages
        .required
    ),
  [Constants.FormFields.UpdatePassword.confirmPassword.name]: yup
    .string()
    .required(
      Constants.FormFields.UpdatePassword.confirmPassword.validationMessages
        .required
    )
    .oneOf(
      [yup.ref(Constants.FormFields.UpdatePassword.newPassword.name), null],
      Constants.FormFields.UpdatePassword.confirmPassword.validationMessages
        .mismatch
    ),
});

// Export config
export const createOrUpdateExportConfigForm = yup.object({
  [Constants.FormFields.CreateOrUpdateExportConfig.exportUrl.name]: yup
    .string()
    .trim()
    .required(
      Constants.FormFields.CreateOrUpdateExportConfig.exportUrl
        .validationMessages.required
    )
    .matches(
      Constants.Regex.IsHttpsURL,
      Constants.FormFields.CreateOrUpdateExportConfig.exportUrl
        .validationMessages.validURL
    ),
});

// Create tag form
export const createNewTagValidation = yup.object({
  [Constants.FormFields.TagConfig.createTag.name]: yup
    .string()
    .required(
      Constants.FormFields.TagConfig.validationMessages.requiredCreateNewTag
    )
    .matches(
      Constants.Regex.IsValidTag,
      Constants.FormFields.TagConfig.validationMessages.invalidTag
    ),
});

export const openIDExternalIDPFormValidation = yup.object({
  [Constants.FormFields.OpenIDExternalIDP.displayName.name]: yup
    .string()
    .required(
      Constants.FormFields.OpenIDExternalIDP.displayName.validationMessages
        .required
    ),
  [Constants.FormFields.OpenIDExternalIDP.tokenURL.name]: yup
    .string()
    .required(
      Constants.FormFields.OpenIDExternalIDP.tokenURL.validationMessages
        .required
    )
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.OpenIDExternalIDP.tokenURL.validationMessages.regex
    ),
  [Constants.FormFields.OpenIDExternalIDP.authURL.name]: yup
    .string()
    .required(
      Constants.FormFields.OpenIDExternalIDP.authURL.validationMessages.required
    )
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.OpenIDExternalIDP.authURL.validationMessages.regex
    ),
  [Constants.FormFields.OpenIDExternalIDP.clientID.name]: yup
    .string()
    .required(
      Constants.FormFields.OpenIDExternalIDP.clientID.validationMessages
        .required
    ),
  [Constants.FormFields.OpenIDExternalIDP.clientSecret.name]: yup.string(),
  [Constants.FormFields.OpenIDExternalIDP.userInfoURL.name]: yup
    .string()
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.OpenIDExternalIDP.userInfoURL.validationMessages
        .regex
    ),
  [Constants.FormFields.OpenIDExternalIDP.logoutURL.name]: yup
    .string()
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.OpenIDExternalIDP.userInfoURL.validationMessages
        .regex
    ),
});

export const SAMLExternalIDPFormValidation = yup.object({
  [Constants.FormFields.SAMLExternalIDP.displayName.name]: yup
    .string()
    .required(
      Constants.FormFields.SAMLExternalIDP.displayName.validationMessages
        .required
    ),
  [Constants.FormFields.SAMLExternalIDP.entityId.name]: yup
    .string()
    .required(
      Constants.FormFields.SAMLExternalIDP.entityId.validationMessages.required
    ),
  [Constants.FormFields.SAMLExternalIDP.signOnURL.name]: yup
    .string()
    .required(
      Constants.FormFields.SAMLExternalIDP.signOnURL.validationMessages.required
    )
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.SAMLExternalIDP.signOnURL.validationMessages.regex
    ),
  [Constants.FormFields.SAMLExternalIDP.logoutURL.name]: yup
    .string()
    .required(
      Constants.FormFields.SAMLExternalIDP.logoutURL.validationMessages.required
    )
    .matches(
      Constants.Regex.IsURL,
      Constants.FormFields.SAMLExternalIDP.logoutURL.validationMessages.regex
    ),
  [Constants.FormFields.SAMLExternalIDP.nameIdPolicyFormat.name]: yup
    .string()
    .required(
      Constants.FormFields.SAMLExternalIDP.nameIdPolicyFormat.validationMessages
        .required
    ),
});
