// components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import Constants from "@Constants";

// styles
import useStyles from "./BackButton.styles";

// Types
import { BackButtonProps } from "./BackButton";

/**
 * BackButton Component - to show the back button in the header
 * @example Correct usage
 * ```ts
 * <BackButton id="id" onClick={handleClick} />
 * ```
 */
export const BackButton = ({
  extraClass = "",
  onClick,
  id,
}: BackButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      id={id}
      extraClass={`${extraClass} ${classes.backButton}`}
      variant="text"
      onClick={onClick}
      startIcon={
        <SVGWrapper
          width={20}
          height={20}
          viewBox="0 0 20 20"
          id={`${id}-arrow`}
        >
          {Constants.SVGIcons.angleDown}
        </SVGWrapper>
      }
    >
      Back
    </Button>
  );
};
