import { useDispatch, useSelector } from "react-redux";

// Components
import ConfirmationDialog from "@Components/ConfirmationDialog";

// Actions
import { deleteTenantUser } from "@Store/actions";

// Constants
import { AlertMessages } from "@Constants/common";
import { ConfirmationDialogIds } from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

/**
 * Props for DeleteConfirmationDialog component
 */
type DeleteConfirmationDialogProps = {
  // If true, the confirmation dialog will be open
  isOpen: boolean;
  // Handles closing
  handleClose: () => void;
  // The "userId" of the user to be deleted
  userId: string;
  // The handler called when a user is successfully deleted
  handleDeleteSuccess?: () => void;
};

const DeleteConfirmationDialog = ({
  isOpen,
  handleClose,
  userId,
  handleDeleteSuccess,
}: DeleteConfirmationDialogProps) => {
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();
  const { loading: isDeletingUser } = useSelector(
    (state: GlobalState) => state?.deleteTenantUser
  );

  /**
   * Handles making an API call to delete the user
   */
  const handleDeleteUser = async () => {
    await dispatch(
      deleteTenantUser(
        {
          userId,
        },
        selectedTenant.selectedTenantId,
        { sideEffect: handleDeleteSuccess }
      )
    );

    // Close the dialog once API request is completed
    handleClose();
  };

  return (
    <ConfirmationDialog
      id={ConfirmationDialogIds.deleteUser}
      alertTitle={AlertMessages.deleteConfirmationTitle}
      alertDescription={
        <>
          Do you really want to{" "}
          <span className="font-weight-bold">Delete this user?</span> This
          action cannot be undone.
        </>
      }
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      disabledButtons={isDeletingUser}
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirmation={handleDeleteUser}
    />
  );
};

export default DeleteConfirmationDialog;
