import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";
import RecentlyUsedTagsList from "./subComponents/RecentlyUsedTagsList";
import SelectedTagsList from "./subComponents/SelectedTagsList";
import SuggestionTagsList from "./subComponents/SuggestionTagsList";
import TagPopoverBodyHeader from "./subComponents/TagPopoverBodyHeader";

// Actions
import {
  addTagsToDevices,
  tagAllDevicePages,
  resetAddTagsState,
  resetAllSelectedPages,
  updateAddedTagsToDeviceInfo,
  showAlert,
} from "@Store/actions";

// Constants
import Constants from "@Constants";
import {
  LocalStorageKeyForRecentlyUsedLabel,
  MaximumLimitForSelectedTags,
  SuccessMessages,
} from "@Constants/common";
import { ButtonIDs, SVGIconIds } from "@Constants/Id";

// Utils
import { getDeviceSearchFiltersFromQueryParams } from "@Utils/index";

// Styles
import useStyles from "./styles";

type TagPopoverBodyProps = {
  /**
   * Value to be searched in the suggestion.
   * If no suggestion found, can be used to create new tag.
   */
  searchValue: string;

  /**
   * Function which is triggered on changing the value in the search bar.
   */
  onInputSearchChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /**
   * Function which is triggered when user creates a new tag.
   * Opens up the create tag modal dialog.
   */
  onCreateTag: () => void;
  /**
   * Function triggered when user selects the settings icon.
   * Opens up the manage tag modal.
   */
  onManageModalToggle: () => void;

  onToggleOpenPopover: () => void;
};

const TagPopoverBody = ({
  searchValue,
  onCreateTag,
  onInputSearchChange,
  onManageModalToggle,
  onToggleOpenPopover,
}: TagPopoverBodyProps) => {
  // initialize hook
  const dispatch = useDispatch();

  // get the device id of the current device
  const { id: deviceId } = useParams<{ id: string }>();

  // Get the Ids of the selected device in the devices table
  const { rows: selectedRowsIds, arePagesSelected: allRowsSelected } =
    useSelector((state: GlobalState) => state.selectedRows);

  const { loading: isTagsAddLoading } = useSelector(
    (state: GlobalState) => state.addTagsToDevices
  );

  // To keep track of checked suggestions
  const [checkedSuggestions, setCheckedSuggestions] = useState<string[]>([]);

  /**
   * Function triggered on changing the state for the checkbox.
   * ie: whenever the user selects or unselects the tag
   * if 'true' the value is added to a checkedSuggestions array.
   * if 'false' the value is removed from the array if present.
   * @param event - event which the change is acted upon
   * @param checked - checked status of the checkbox
   */
  const handleCheckInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setCheckedSuggestions((prevState) => [...prevState, event.target.value]);
    } else {
      setCheckedSuggestions(
        checkedSuggestions.filter(
          (checkedSuggestion) => checkedSuggestion !== event.target.value
        )
      );
    }
  };

  /**
   * Stores the applied label into the local storage to use later as recently used tags
   */
  const handleSetRecentlyUsedLocalStorage = () => {
    const jsonArray = JSON.parse(
      (localStorage.getItem(LocalStorageKeyForRecentlyUsedLabel) as string) ??
        "null"
    );
    if (Array.isArray(jsonArray) && jsonArray.length) {
      //  Applied suggestions are added to the recently used suggestion
      const updatedRecentlyUsed = [
        ...new Set([...checkedSuggestions, ...jsonArray]),
      ];
      localStorage.setItem(
        LocalStorageKeyForRecentlyUsedLabel,
        JSON.stringify(updatedRecentlyUsed.slice(0, 5))
      );
      return;
    }

    localStorage.setItem(
      LocalStorageKeyForRecentlyUsedLabel,
      JSON.stringify(checkedSuggestions)
    );
  };

  /**
   * Handles bulk tagging
   */
  const handlerBulkTagging = async () => {
    const {
      agentVersion,
      name,
      operatingSystem,
      search,
      tag,
    }: DeviceSearchConfig = getDeviceSearchFiltersFromQueryParams();
    const criterion = {
      ...(!!operatingSystem && { osName: operatingSystem }),
      ...(!!name && { deviceName: name }),
      ...(!!agentVersion && { agentVersion }),
      ...(!!search && { search }),
      ...(!!tag && { tag }),
    };
    dispatch(
      tagAllDevicePages(
        {
          tags: checkedSuggestions,
          criterion,
        },
        {
          sideEffect: (response) =>
            dispatch(
              showAlert({
                message: SuccessMessages.bulkTagsApplied({
                  deviceCount: response?.target_devices_count as number,
                }),
                severity: Constants.AlertSeverity.success,
              })
            ),
        }
      )
    );

    dispatch(resetAllSelectedPages());
  };

  /**
   * Applies tags to the specified device.
   * When successfully applied, reset the entire state
   */
  const handleApplyTag = async () => {
    // If all rows flag is true handle bulk tagging.
    if (allRowsSelected) {
      await handlerBulkTagging();
      return;
    }

    /**
     * Actions to be taken after the tags are added to the devices
     */
    const onAddTagsToDevices = () => {
      handleSetRecentlyUsedLocalStorage();
      dispatch(resetAllSelectedPages());
      // update tags in device info with the added tags
      if (deviceId)
        dispatch(updateAddedTagsToDeviceInfo({ tags: checkedSuggestions }));
      dispatch(resetAddTagsState());
      setCheckedSuggestions([]);
      onToggleOpenPopover();
    };

    dispatch(
      addTagsToDevices(
        {
          ids: deviceId ? [deviceId] : selectedRowsIds,
          tags: checkedSuggestions,
        },
        {
          sideEffect: onAddTagsToDevices,
        }
      )
    );
  };

  const classes = useStyles();

  return (
    <div className={`flex-column ${classes.rootContainer}`}>
      <TagPopoverBodyHeader
        handleInputSearchCHange={onInputSearchChange}
        handleSettingsClick={onManageModalToggle}
        searchValue={searchValue}
      />
      <Typography
        variant="body2"
        className={` ${classes.maxTagMessage} ${
          checkedSuggestions.length >= MaximumLimitForSelectedTags &&
          "maxMessage"
        }`}
      >
        <span className="flex justify-start flex-gap-6">
          <SVGWrapper
            height={16}
            width={16}
            fill="red"
            viewBox="0 0 10 10"
            id={SVGIconIds.warningIcon}
          >
            {Constants.SVGIcons.warning}
          </SVGWrapper>
          Up to 5 labels can be added at a time
        </span>
      </Typography>
      <div className={`${classes.listContainer} custom-scroll-bar`}>
        {searchValue ? (
          // If searchValue is present we show the suggestion from the fetched tags
          <SuggestionTagsList
            searchValue={searchValue}
            checkedSuggestions={checkedSuggestions}
            handleCheckInputChange={handleCheckInputChange}
          />
        ) : (
          <>
            {/* The suggestions which are checked we show them in a selected section */}
            <SelectedTagsList
              checkedSuggestions={checkedSuggestions}
              handleCheckInputChange={handleCheckInputChange}
            />
            {/* If searchValue is not present we show the suggestion saved in localStorage as recently used */}
            <RecentlyUsedTagsList
              checkedSuggestions={checkedSuggestions}
              handleCheckInputChange={handleCheckInputChange}
            />
          </>
        )}
      </div>
      <div className={`flex-column ${classes.actionContainer}`}>
        {/* Show the apply label button only if at least one suggestion is checked */}
        {!!checkedSuggestions.length && (
          <Button
            id={ButtonIDs.applyTagToResources}
            extraClass={classes.button}
            onClick={handleApplyTag}
            disabled={isTagsAddLoading}
          >
            {isTagsAddLoading ? <CircularProgress size={16} /> : "Apply Label"}
          </Button>
        )}
        <Button
          id={ButtonIDs.createTag}
          variant="outlined"
          onClick={onCreateTag}
        >
          <SVGWrapper
            height={16}
            width={16}
            viewBox="0 0 17 16"
            id={SVGIconIds.addIcon}
          >
            {Constants.SVGIcons.add}
          </SVGWrapper>
          Create new label
        </Button>
      </div>
    </div>
  );
};

export default TagPopoverBody;
