import Constants from "@Constants";

const initialState: AddUserState = {
  loading: false,
  error: "",
};

/**
 * Handles add user actions
 * @param state
 * @param action
 * @returns AddUserState
 */
const addUserReducer = (
  state: AddUserState = initialState,
  action: GenericAction
): AddUserState => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_TENANT_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.ADD_TENANT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.ADD_TENANT_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
  }

  return state;
};

export default addUserReducer;
