import { Overrides } from "@material-ui/core/styles/overrides";
import { alpha } from "@material-ui/core";

import { themeColors } from "./themeColors";
import { pxToRem } from "./pxToRem";
import BreakpointValues from "./BreakpointValues";

const buttonsTheme: Overrides["MuiButton"] = {
  root: {
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    lineHeight: "100%",
    fontWeight: 500,
    fontSize: pxToRem(14),
    borderRadius: pxToRem(8),
    padding: `${pxToRem(12)} ${pxToRem(24)}`,
    boxShadow: "none",
    "&$disabled": {
      opacity: 0.9,
      color: `${themeColors.neutral[400]} !important`,
      border: `${pxToRem(1)} solid ${themeColors.neutral[300]} !important`,
      backgroundColor: `${themeColors.neutral[200]} !important`,
    },

    [`@media (max-width: ${BreakpointValues.md}rem)`]: {
      fontSize: pxToRem(13),
      padding: `${pxToRem(10)} ${pxToRem(20)}`,
    },

    [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
      fontSize: pxToRem(12),
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
    },
  },
  text: {
    fontSize: pxToRem(14),
    padding: `${pxToRem(12)} ${pxToRem(24)}`,

    [`@media (max-width: ${BreakpointValues.md}rem)`]: {
      fontSize: pxToRem(13),
      padding: `${pxToRem(10)} ${pxToRem(20)}`,
    },

    [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
      fontSize: pxToRem(12),
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
    },
  },
  sizeSmall: {
    fontSize: pxToRem(12),
    padding: `${pxToRem(8)} ${pxToRem(18)}`,

    [`@media (max-width: ${BreakpointValues.md}rem)`]: {
      fontSize: pxToRem(11),
      padding: `${pxToRem(6)} ${pxToRem(16)}`,
    },

    [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
      fontSize: pxToRem(10),
      padding: `${pxToRem(4)} ${pxToRem(12)}`,
    },
  },
  sizeLarge: {
    fontSize: pxToRem(16),
    padding: `${pxToRem(16)} ${pxToRem(24)}`,

    [`@media (max-width: ${BreakpointValues.md}rem)`]: {
      fontSize: pxToRem(15),
      padding: `${pxToRem(14)} ${pxToRem(21)}`,
    },

    [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
      fontSize: pxToRem(14),
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
    },
  },
  contained: {
    boxShadow: "none",
  },
  containedPrimary: {
    backgroundColor: themeColors.primary.fieryOrange[500],
    color: themeColors.neutral[100],
    "&:hover": {
      backgroundColor: themeColors.primary.fieryOrange[600],
      boxShadow: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(20)} 
      ${alpha(themeColors.primary.fieryOrange[500], 0.2)}`,
    },
  },
  containedSecondary: {
    backgroundColor: themeColors.secondary.deepBlue[600],
    color: themeColors.neutral[100],
    "&:hover": {
      backgroundColor: themeColors.secondary.deepBlue[700],
      boxShadow: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(20)}
      ${alpha(themeColors.secondary.deepBlue[700], 0.2)}`,
    },
  },
  outlined: {
    border: `${pxToRem(1)} solid`,
    // Default padding in contained variant is "12px, 24px", here decreased both by 1 so, that the contained and outlined both are of same height and width (since 1px is taken by the border).
    padding: `${pxToRem(11)} ${pxToRem(23)}`,
    backgroundColor: "none",

    [`@media (max-width: ${BreakpointValues.md}rem)`]: {
      padding: `${pxToRem(9)} ${pxToRem(19)}`,
    },

    [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
      padding: `${pxToRem(7)} ${pxToRem(15)}`,
    },
  },
  outlinedPrimary: {
    borderColor: themeColors.primary.fieryOrange[500],
    color: themeColors.primary.fieryOrange[500],
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(20)}
      ${alpha(themeColors.primary.fieryOrange[500], 0.2)}`,
      borderColor: themeColors.primary.fieryOrange[600],
      color: themeColors.primary.fieryOrange[600],
    },
  },
  outlinedSecondary: {
    borderColor: themeColors.secondary.deepBlue[600],
    color: themeColors.secondary.deepBlue[600],
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(20)}
      ${alpha(themeColors.secondary.deepBlue[700], 0.2)}`,
      borderColor: themeColors.secondary.deepBlue[700],
      color: themeColors.secondary.deepBlue[700],
    },
  },
  textPrimary: {
    color: themeColors.primary.fieryOrange[500],
    justifyContent: "start",
    "&:hover": {
      backgroundColor: "transparent",
      color: themeColors.primary.fieryOrange[600],
    },
  },
  textSecondary: {
    color: themeColors.secondary.deepBlue[600],
    "&:hover": {
      backgroundColor: "transparent",
      color: themeColors.secondary.deepBlue[700],
    },
  },
};

export default buttonsTheme;
