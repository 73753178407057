import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  baseCard: {
    padding: theme.pxToRem(30),
    boxShadow: "none",
    borderRadius: theme.pxToRem(20),
    backgroundColor: theme.newPalette.card.background,
    color: theme.newPalette.text.primary.main,

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: theme.pxToRem(20),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      maxWidth: "100%",
      minWidth: "unset",
    },
  },
}));

export default useStyles;
