// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// Handle user login action
export const userLogin = (body: UserLoginPayload) =>
  bindClientFunc({
    clientFunc: client.userLogin,
    onFailure: ActionTypes.LOGIN_FAILED,
    onRequest: ActionTypes.LOGIN_REQUEST,
    onSuccess: ActionTypes.LOGIN_SUCCESS,
    params: [body.username, body.password],
  }) as unknown as Promise<BindClient>;

// Handle user logout action
export const userLogout = () =>
  bindClientFunc({
    clientFunc: client.userLogout,
    onFailure: ActionTypes.LOGOUT_FAILED,
    onRequest: ActionTypes.LOGOUT_REQUEST,
    onSuccess: ActionTypes.LOGOUT_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

export const refreshToken = () =>
  bindClientFunc({
    clientFunc: client.refreshToken,
    onFailure: ActionTypes.REFRESH_TOKEN_FAILED,
    onRequest: ActionTypes.REFRESH_TOKEN_REQUEST,
    onSuccess: ActionTypes.REFRESH_TOKEN_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;
