import { darken, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.pxToRem(2),
    gap: theme.pxToRem(8),
    margin: 0,
  },

  chip: {
    borderRadius: theme.pxToRem(16),
    fontSize: theme.pxToRem(13),
    color: theme.newPalette.tagGroup.tagText,
    background: theme.newPalette.tagGroup.background,

    "& .MuiChip-label": {
      maxWidth: theme.pxToRem(160),
    },

    "& .MuiChip-deleteIcon": {
      color: theme.newPalette.tagGroup.addTagText,
    },

    "&:hover": {
      background: theme.newPalette.tagGroup.chipHover,
    },

    "&:focus": {
      background: darken(theme.newPalette.tagGroup.background, 0.2),
    },
  },

  addChipWithTag: {
    color: theme.newPalette.tagGroup.addTagText,

    "& .MuiChip-icon": {
      color: theme.newPalette.tagGroup.addTagText,
    },
  },

  addChipWithoutTag: {
    borderRadius: "50%",
    height: theme.pxToRem(32),
    width: theme.pxToRem(32),

    "& .MuiChip-icon": {
      color: theme.newPalette.tagGroup.addTagText,
      margin: 0,
      padding: theme.pxToRem(4),
    },

    "& .MuiChip-label": {
      display: "none",
    },
  },

  skeletonLoaderWrapper: {
    width: `${theme.pxToRem(100)}`,
    height: theme.pxToRem(32),
  },

  skeletonLoaderRoundWrapper: {
    borderRadius: "50%",
    height: theme.pxToRem(32),
    width: theme.pxToRem(32),
  },

  skeletonLoader: {
    height: "100%",
    borderRadius: theme.pxToRem(16),
  },

  undoButton: {
    borderRadius: theme.pxToRem(8),
    fontSize: theme.pxToRem(13),
    color: theme.newPalette.tagGroup.tagText,
    background: theme.newPalette.appBg,
    border: `${theme.pxToRem(1)} solid ${theme.newPalette.tagGroup.background}`,
    boxShadow: `${theme.pxToRem(0)} ${theme.pxToRem(4)} 
      ${theme.pxToRem(4)} ${theme.newPalette.tagGroup.undoElevationColor}`,

    "&.MuiButton-contained:hover": {
      boxShadow: `${theme.pxToRem(0)} ${theme.pxToRem(4)} 
      ${theme.pxToRem(5)} ${theme.newPalette.card.shadowColor}`,
    },

    "&:hover ,&:hover $undoProgressIndicator": {
      background: theme.newPalette.tagGroup.chipHover,
      border: `${theme.pxToRem(1)} solid ${
        theme.newPalette.tagGroup.chipHover
      }`,
    },
  },

  undoProgressIndicator: {
    background: theme.newPalette.tagGroup.background,
  },
}));

export default useStyles;
