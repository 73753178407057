import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

// Actions
import {
  fetchEventsForDeviceOrThreat,
  unblockEventAction,
} from "@Store/actions";

// Components
import Button from "@Components/Button";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";
import PaginatedTable from "@Components/Table/PaginatedTable";

// Constants
import Constants from "@Constants";
import {
  EventsInitSearchConfig,
  EventsSearchParams,
  EventsTableAutoSuggestOptions,
  KeyForEventsTableAutoSuggest,
  SuggestionsForActionType,
} from "@Constants/tableSearchConst";
import {
  EventsTableSortingFields,
  DefaultSortingFieldForEvents,
  DefaultSortingOrder,
  EventsSortingFieldParams,
} from "@Constants/sorting";
import { DeviceDetailsTabIndex } from "@Constants/common";
import {
  ButtonIDs,
  CommonIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  SortDropdownGroupIds,
  TableIds,
} from "@Constants/Id";

// Hooks
import usePagination from "@Hooks/usePagination";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";

// Utils
import Utils from "@Utils";

type EventsForDeviceProps = {
  deviceId: string;
};

const EventsForDevice = ({ deviceId }: EventsForDeviceProps): JSX.Element => {
  // Get events for device state from redux store
  const { events, pagination, loading }: EventsForDeviceOrThreatState =
    useSelector((state: GlobalState) => state.eventsForDeviceOrThreat);

  // state to know whether running api for unblocking event action
  const unblockEventActionState = useSelector(
    (state: GlobalState) => state.unblockEventAction
  );

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // State for the search string in the filter auto-suggest reusable component
  const [searchString, setSearchString] = useState("");

  // Sorting
  const { filters: sort, setFilters: setSort } = useFilterAndSorting(
    {
      sortBy: DefaultSortingFieldForEvents,
      sortOrder: DefaultSortingOrder,
    },
    { tab: DeviceDetailsTabIndex.events }
  );

  const EventsTableAutoSuggestOptionsWithoutDeviceName = useMemo(
    () =>
      EventsTableAutoSuggestOptions.filter(
        (event) => event.field !== EventsSearchParams.deviceName
      ),
    []
  );

  const EventsTableSortingFieldWithoutDeviceName = useMemo(
    () =>
      EventsTableSortingFields.filter(
        (event) => event.value !== EventsSortingFieldParams.deviceName
      ),
    []
  );

  // removing `deviceName` property from EventsInitSearchConfig
  const { deviceName, ...EventsInitSearchConfigWithoutDeviceName } =
    EventsInitSearchConfig as Record<string, string | null>;

  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(EventsInitSearchConfigWithoutDeviceName);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
  } = usePagination({
    searchString: `tab=${Constants.DeviceDetailsTabIndex.events}`,
    otherQueryParams: {
      ...sort,
      ...searchConfig,
    },
  });

  // To abort the fetch API request in case we want to make a new fetch API request with different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  // Handle changes in the applied sorting
  const handleSortingFilterChange = (
    sortBy: string | null,
    sortOrder: SortStateType
  ) => setSort({ sortBy, sortOrder });

  /**
   * Dispatch action to fetch data for table
   * Dispatch action to show error toast on error
   */
  const handleFetchEventsForDevice = () => {
    dispatch(
      fetchEventsForDeviceOrThreat(
        {
          deviceOrThreatId: deviceId,
          pageQueryParams: {
            page: currentPage,
            pageSize: noOfRowsPerPage,
          },
          deviceOrThreat: "device",
          ...sort,
          ...searchConfig,
        },
        abortControllerRef.current.signal
      )
    );
  };

  const handleEventSubjectClick = (data: RowData): string => {
    const threatId = (data.threat_id as CellData).value;
    const eventId = (data.id as CellData).value;
    return threatId && eventId
      ? generatePath(Constants.AppRoutes.ProtectedRoutes.ThreatDetails, {
          threatId,
          eventId,
        })
      : "";
  };

  const infoIconColumnConfig: TableIconColumnConfig = {
    onClick: (data: RowData) => {
      const actionType = (data.action_type as CellData).value;
      const threatId = (data.threat_id as CellData).value;
      if (actionType === Constants.EventActions.block) {
        dispatch(unblockEventAction(threatId));
      }
    },
    iconElement: (data: RowData): JSX.Element => {
      const actionType: string = (data?.action_type as CellData).value;
      return actionType === Constants.EventActions.block ? (
        <Button
          id={ButtonIDs.unblockEventWithId({ id: data.id.value })}
          color="secondary"
          size="small"
          disabled={unblockEventActionState.loading}
        >
          Unblock
        </Button>
      ) : (
        <></>
      );
    },
    hidden: (data: RowData): boolean => {
      const actionType: string = (data?.action_type as CellData).value;
      return actionType === Constants.EventActions.block;
    },
  };

  // Trigger dispatching the action to fetch data, when the component loads or page number changes in table
  // TODO: Remove the logic to abort the request when we have a way to handle calling the API only once
  useEffect(() => {
    if (deviceId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      handleFetchEventsForDevice();
    }
  }, [deviceId, currentPage, noOfRowsPerPage, sort, searchConfig]);

  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    setSearchString(
      (prev) => Utils.getSearchStringFromSearchConfig(searchConfig) ?? prev
    );
  }, [searchConfig]);

  const getConfiguredHeaders = (): TableHeaderConfig[] => {
    const withDeviceNameClickHandler = Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames
        .deviceName,
      Constants.TableColumnsConfig.eventsForDeviceOrThreat,
      null,
      Utils.getEventDetailsUrlForTableCell
    );
    return Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames.subject,
      withDeviceNameClickHandler,
      null,
      handleEventSubjectClick
    );
  };

  const getRowsData = (): RowData[] => {
    const parsedRowsData = Utils.parseToRowDataType(
      Constants.TableColumnsConfig.eventsForDeviceOrThreat,
      events
    );
    return parsedRowsData.map((rowData) => {
      const threatId = (rowData.threat_id as CellData).value;
      if (!threatId) {
        // get cell data of device name cell
        const deviceNameCellData = rowData[
          Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames
            .deviceName
        ] as CellData;

        // add colored -> false, to not color if threat_id is null
        rowData[
          Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames.deviceName
        ] = { ...deviceNameCellData, colored: false };
        return rowData;
      }
      return rowData;
    });
  };

  return (
    <>
      <div
        id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
        className={`flex-column-md margin-top-15 flex-gap-16 d-flex justify-between`}
      >
        <FilterAutoSuggest
          id={FilterAutoSuggestIds.deviceDetailsEvents}
          value={null}
          options={{
            items: EventsTableAutoSuggestOptionsWithoutDeviceName,
            key: KeyForEventsTableAutoSuggest,
            renderOption: (option: Record<string, string>) => (
              <FilterAutoSuggestOption
                id={FilterAutoSuggestOptionIds.option({ label: option.label })}
                label={option.label}
                helperText={option.helperText}
              />
            ),
          }}
          inputValue={searchString}
          onInputValueChange={(newValue) => setSearchString(newValue)}
          placeholder="Search"
          onEnterKeyPress={() =>
            Utils.handleApplySearchForEvents(
              searchString,
              EventsTableAutoSuggestOptionsWithoutDeviceName,
              searchConfig,
              setSearchConfig,
              dispatch,
              setCurrentPage
            )
          }
          handleClearInput={() =>
            setSearchConfig(EventsInitSearchConfigWithoutDeviceName)
          }
          extraClass="flex-grow-1"
          suggestionConfigForValue={{
            fieldName: EventsSearchParams.actionType,
            suggestions: SuggestionsForActionType,
          }}
        />
        <SortDropdownGroup
          id={SortDropdownGroupIds.events}
          value={sort.sortBy ?? ""}
          sortState={sort.sortOrder as SortStateType}
          options={EventsTableSortingFieldWithoutDeviceName}
          onChange={handleSortingFilterChange}
        />
      </div>
      <PaginatedTable
        id={TableIds.events}
        isLoading={loading}
        headers={getConfiguredHeaders()}
        rowsData={getRowsData()}
        extraClass="no-border-radius"
        iconColumn={infoIconColumnConfig}
        showPagination
        pagination={{
          noOfRowsPerPage: noOfRowsPerPage,
          totalRowsAvailable: pagination?.totalRowsAvailable,
          currentPageNumber: currentPage,
          jumpToPage: (pageNumber: number) => {
            onPageChange(pageNumber);
          },
          onClickSetPageSize: (pageSize: number) => {
            onRowSizeChange(pageSize);
          },
          lastPageNumber: pagination?.lastPageNumber,
        }}
      />
    </>
  );
};

export default EventsForDevice;
