import { makeStyles, Theme } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    borderRadius: theme.pxToRem(20),
    boxShadow: "none",
    color: theme.newPalette.text.primary.main,
    backgroundColor: theme.newPalette.card.background,
    padding: theme.pxToRem(30),
    alignItems: "end",
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: theme.pxToRem(20),
    },

    "&:hover": {
      boxShadow: `${theme.pxToRem(20)} ${theme.pxToRem(20)} 
      ${theme.pxToRem(80)} ${theme.newPalette.card.shadowColor}`,
    },
  },
  infoTitle: {
    color: theme.newPalette.text.secondary.main,
  },
  infoSubtitle: {
    ...theme.utils.semiBoldText(),
    margin: `${theme.pxToRem(2)} 0 0`,
  },
  alertSubtitle: {
    color: theme.newPalette.error[500],
  },
  link: {
    "&:hover": {
      color: theme.lightTheme.secondary.clearWater,
    },
  },
  cardContent: {
    gap: theme.pxToRem(20),
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "block",
    },
  },
}));

export default useStyles;
