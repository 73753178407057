// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { ErrorCodes } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

// Action for fetching the configuration for external IDP
export const fetchIdpConfig = (
  tenantId: string,
  handleError?: ApiErrorReporting
): any =>
  bindClientFunc({
    clientFunc: client.fetchIdpConfig,
    onFailure: ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_FAILED,
    onRequest: ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_REQUEST,
    onSuccess: ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_SUCCESS,
    params: [tenantId],
    handleError: {
      skipErrorReporting: (_apiError, statusCode) =>
        statusCode === ErrorCodes.notFound,
      ...handleError,
    },
  });

// Handle actions for creating IDP config
export const createIdpConfig = (
  { idpConfigParams, tenantID }: CreateUpdateIdpParams,
  handleSuccess?: ApiSuccessReporting
): any =>
  bindClientFunc({
    clientFunc: client.createIdpConfig,
    onFailure: ActionTypes.CREATE_IDP_CONFIG_FAILED,
    onRequest: ActionTypes.CREATE_IDP_CONFIG_REQUEST,
    onSuccess: ActionTypes.CREATE_IDP_CONFIG_SUCCESS,
    params: [idpConfigParams, tenantID],
    handleSuccess,
  });

// Handle actions for updating IDP Config
export const updateIdpConfig = (
  { idpConfigParams, tenantID }: CreateUpdateIdpParams,
  handleSuccess?: ApiSuccessReporting
): any =>
  bindClientFunc({
    clientFunc: client.updateIdpConfig,
    onFailure: ActionTypes.UPDATE_IDP_CONFIG_FAILED,
    onRequest: ActionTypes.UPDATE_IDP_CONFIG_REQUEST,
    onSuccess: ActionTypes.UPDATE_IDP_CONFIG_SUCCESS,
    params: [idpConfigParams, tenantID],
    handleSuccess,
  });

// Handle actions for deleting IDP
export const deleteIdpConfig = (
  { tenantID }: { tenantID: string },
  handleSuccess?: ApiSuccessReporting
): any =>
  bindClientFunc({
    clientFunc: client.deleteIdpConfig,
    onFailure: ActionTypes.DELETE_IDP_CONFIG_FAILED,
    onRequest: ActionTypes.DELETE_IDP_CONFIG_REQUEST,
    onSuccess: ActionTypes.DELETE_IDP_CONFIG_SUCCESS,
    params: [tenantID],
    handleSuccess,
  });

/**
 * remove/delete Idp config from Identity Provider store.
 */
export const deleteIdpConfigFromStore = (): GenericAction => ({
  type: ActionTypes.DELETE_IDP_CONFIG_FROM_STORE,
});
