import TagItem from "../TagItem";

const SuggestionTagListLoader = () => (
  <>
    {[...Array(8).keys()].map((loader) => (
      <TagItem
        key={loader}
        isDisabled={true}
        isChecked={false}
        loading={true}
        suggestion={"loader"}
        handleCheckInputChange={() => {}}
      />
    ))}
  </>
);

export default SuggestionTagListLoader;
