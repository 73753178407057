import Constants from "@Constants";

const initialState: IdentityProvider = {
  loading: false,
  data: {},
  error: "",
};

/**
 * Handles actions of fetching the configuration for external IDP
 * @param state IdentityProvider
 * @param action GenericAction
 * @returns IdentityProvider
 */
const fetchIdentityProvider = (
  state: IdentityProvider = initialState,
  action: GenericAction
): IdentityProvider => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case Constants.ActionTypes.FETCH_EXTERNAL_IDP_CONFIG_FAILED:
      return {
        loading: false,
        data: {},
        error: action.payload,
      };
    case Constants.ActionTypes.DELETE_IDP_CONFIG_FROM_STORE:
      return {
        loading: false,
        data: {},
        error: "",
      };
  }
  return state;
};

export default fetchIdentityProvider;
