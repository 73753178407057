import { Typography } from "@material-ui/core";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import DownloadButton from "../DownloadButton";

// Constants
import Constants from "@Constants";
import { ButtonIDs, LeftMenuIds, SVGIconIds } from "@Constants/Id";

// hooks
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";

// Types
import { DownloadAgentCardProps } from "./DownloadAgentCard";

// styles
import useStyles from "./DownloadAgentCard.styles";

/**
 * DownloadAgentCard component
 * @example Correct usage
 * <DownloadAgentCard
 *  onClick={handleOnClick}
 *  extraClass="margin-10"
 * />
 */
export const DownloadAgentCard = ({
  onClick,
  extraClass = "",
}: DownloadAgentCardProps): JSX.Element => {
  const classes = useStyles();
  const { isMobile } = useCustomMediaQuery();

  return isMobile ? (
    <div className={extraClass} id={LeftMenuIds.agentDownloadBtnContainer}>
      <DownloadButton
        id={ButtonIDs.agentCardDownload}
        text="Download Halcyon agent"
        onClick={onClick}
      />
    </div>
  ) : (
    <div
      className={`flex-column text-align-center ${classes.card} ${extraClass}`}
      id={LeftMenuIds.agentCardContainer}
    >
      <div
        className={classes.iconContainer}
        id={LeftMenuIds.agentDownloadIconContainer}
      >
        <SVGWrapper
          width={45}
          height={37}
          viewBox="0 0 50 40"
          id={SVGIconIds.downloadIcon}
        >
          {Constants.SVGIcons.downloadIconLarge}
        </SVGWrapper>
      </div>
      <div className={classes.text} id={LeftMenuIds.agentCardTextContainer}>
        <Typography
          variant="subtitle2"
          className="font-weight-bold"
          id={LeftMenuIds.agentCardTitle}
        >
          Halcyon Agent
        </Typography>
        <Typography
          className={classes.details}
          id={LeftMenuIds.agentCardSubtitle}
        >
          Get the Halcyon Agent
        </Typography>
      </div>
      <DownloadButton
        id={ButtonIDs.agentCardDownload}
        text="Download"
        onClick={onClick}
      />
    </div>
  );
};
