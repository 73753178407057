import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  detailsCard: {
    borderRadius: theme.pxToRem(20),
    marginTop: theme.pxToRem(30),
  },
  detailsHeader: {
    alignSelf: "start",
    minWidth: theme.pxToRem(135),
    textAlign: "start",
  },
  detailsRow: {
    marginBottom: theme.pxToRem(20),
  },
  detailsValue: {
    wordBreak: "break-all",
    textAlign: "start",
    width: "fit-content",
  },
  svgWrapper: {
    alignSelf: "center",
    "& svg": {
      fill: theme.newPalette.text.primary.light,
      height: 16,
      width: 16,
    },
  },
  copyIcon: {
    marginLeft: theme.pxToRem(10),
    height: 16,
    width: 16,
  },
}));

export default useStyles;
