import { TableCell } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import useStyles from "./styles";

export type SelectionCellProps = {
  isSelected?: boolean;
  onChange?: (selected: boolean) => void;
  borderAroundCell?: boolean;
  toggleAllRows?: boolean;
  isIndeterminate?: boolean;
  isDataLoading?: boolean;
  isSelectLimitReached?: boolean;
};

const SelectionCell = ({
  isSelected,
  onChange,
  borderAroundCell,
  isIndeterminate,
  isDataLoading,
  isSelectLimitReached,
}: SelectionCellProps): JSX.Element => {
  const classes = useStyles({ borderAroundCell });

  return (
    <TableCell
      key="selection-checkbox"
      className={`${classes.checkboxCell} text-align-left`}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected && !isDataLoading}
            disabled={
              isDataLoading ||
              (isSelectLimitReached && !isSelected && !isIndeterminate)
            }
            indeterminate={isIndeterminate && !isDataLoading}
            name="SelectRow"
            className={classes.checkbox}
            inputProps={{ "aria-label": "Select row" }}
            size="small"
          />
        }
        onChange={(_e: React.ChangeEvent<{}>, checked: boolean) =>
          onChange?.(checked)
        }
        label={null}
        className={classes.checkboxWrapper}
      />
    </TableCell>
  );
};

export default SelectionCell;
