import { IconButton, TableCell } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// Constants
import { PopoverIds } from "@Constants/Id";

// Components
import Popover from "@Components/Popover";

// Types
import { OptionsCellProps } from "./OptionsCell";

// Styles
import useStyles from "./OptionsCell.styles";

/**
 * OptionsCell Component - renders a cell with options
 * @example Correct usage
 * ```ts
 * <OptionsCell
 *  renderOptions
 *  optionsList={optionsList}
 *  rowData={rowData}
 *  hideOption={hideOptionConfig}
 * />
 * ```
 */
export const OptionsCell = ({
  optionsList,
  hideOption,
  rowData,
  renderOptions = false,
  borderAroundCell = false,
}: OptionsCellProps) => {
  const classes = useStyles();

  /**
   * Create pop-over options
   *
   * @returns JSX.Element pop-over options
   */
  const GenerateOptionsItems = ({ data }: { data: RowData }): JSX.Element => (
    <>
      {optionsList?.map(
        (optionItem) =>
          (!hideOption ||
            data[hideOption.keyName].value !== hideOption.value ||
            hideOption.optionLabel !== optionItem.label) &&
          (optionItem.shouldRender ?? true) && (
            <IconButton
              key={optionItem.label}
              className={`${classes.optionItem} flex justify-start cursor-pointer`}
              onClick={() => optionItem.onClick?.(data)}
            >
              {optionItem.icon}
              {optionItem.label}
            </IconButton>
          )
      )}
    </>
  );

  return (
    <>
      {renderOptions && (
        <TableCell
          key="options"
          className={`${borderAroundCell && classes.tableRowBorderLeft} ${
            classes.optionIcon
          } ${
            hideOption &&
            rowData[hideOption.keyName].value !== hideOption.value &&
            "cursor-pointer"
          } text-align-right`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* TODO: during integration of edit tenant user flow, remove below logic to show edit option to logged in user */}
          {(!hideOption ||
            rowData[hideOption.keyName].value !== hideOption.value) && (
            <Popover
              id={PopoverIds.tableOptionsPopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              closeOnClick
              popOverChildren={<GenerateOptionsItems data={rowData} />}
              extraClass={classes.optionsPopover}
            >
              <IconButton className={`${classes.iconBtn} padding-0`}>
                <MoreVertIcon />
              </IconButton>
            </Popover>
          )}
        </TableCell>
      )}
    </>
  );
};
