import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Actions
import { fetchCurrentTenantInfo, updateSelectedTenant } from "@Store/actions";

function useTenantAccess() {
  const { REACT_APP_BASE_UI_HOSTNAME } = process.env;

  const dispatch = useDispatch();

  const availableTenants = useSelector(
    (state: GlobalState) => state.availableTenants
  );

  const selectedTenant = useSelector(
    (state: GlobalState) => state.selectedTenant
  );

  const [tenantsLabelValuePair, setTenantsLabelValuePair] = useState<
    LabelValueType[]
  >([]);

  const [useHarbinger, setUseHarbinger] = useState<boolean>(
    !window.location.hostname.includes(REACT_APP_BASE_UI_HOSTNAME ?? "")
  );

  /**
   * Generate label-value pair for all available tenants
   * @returns Mapped label-value pair based on the available tenants
   */
  const getTenantsLabelValuePair = () => {
    const result = availableTenants.data.length
      ? availableTenants.data.map((item: Record<string, string | null>) => ({
          label: item.alias ?? "",
          value: item.id ?? "",
        }))
      : [];
    return result;
  };

  /**
   * Handles dispatching the action to update the selected tenant based on a given tenantId
   * @param tenantId The tenantId of the tenant which needs to be selected
   */
  const updateSelectedTenantById = (tenantId?: string | null) => {
    let targetTenant: SelectedTenantState = {
      selectedTenantId: "",
      selectedTenantName: "",
      manageTenant: false,
      isNonEnterprise: false,
    };

    // Looping through all available tenants to find the selected tenant
    for (const tenant in availableTenants.data) {
      if (availableTenants.data[tenant].id === tenantId) {
        targetTenant = {
          selectedTenantId: availableTenants.data[tenant].id ?? "",
          selectedTenantName: availableTenants.data[tenant].alias ?? "",
          manageTenant:
            String(availableTenants.data[tenant].manageTenants) === "true",
          isNonEnterprise:
            String(availableTenants.data[tenant].isNonEnterprise) === "true",
        };
        break;
      }
    }
    dispatch(updateSelectedTenant(targetTenant));
  };

  /**
   * Handles updating the selected tenant based on a given tenantAlias
   * @param tenantAlias The tenantAlias of the tenant which needs to be selected
   */
  const updateSelectedTenantByAlias = (tenantAlias: string) => {
    // Looping through all available tenants to find the selected tenant
    for (const tenant in availableTenants.data) {
      if (availableTenants.data[tenant].alias === tenantAlias) {
        dispatch(
          updateSelectedTenant({
            selectedTenantId: availableTenants.data[tenant].id ?? "",
            selectedTenantName: availableTenants.data[tenant].alias ?? "",
            manageTenant:
              String(availableTenants.data[tenant].manageTenants) === "true",
            isNonEnterprise:
              String(availableTenants.data[tenant].isNonEnterprise) === "true",
          })
        );
        break;
      }
    }
  };

  /**
   * Check whether the selected tenant exist in the current tenant list or not
   * @returns true, if the selected tenant is not available in the current tenant list
   */
  const isSelectedTenantUnavailable = () =>
    availableTenants.data.every(
      ({ id }) => id !== selectedTenant.selectedTenantId
    );

  // Fetch current user's tenant info
  const fetchTenantInfo = async () => {
    await dispatch(fetchCurrentTenantInfo());
    setTenantsLabelValuePair(getTenantsLabelValuePair());
  };

  useEffect(() => {
    //  Update the tenants' label value pair whenever the list of tenants is fetched/updated
    setTenantsLabelValuePair(getTenantsLabelValuePair());

    /**
     * If we've tenants' label-value pair available and any tenant isn't selected or the selected tenant is invalid
     * Update the selected tenant with the correct tenant
     * Otherwise reset the selected tenant
     */
    if (
      availableTenants.data.length &&
      (selectedTenant.selectedTenantId.length === 0 ||
        isSelectedTenantUnavailable())
    ) {
      updateSelectedTenantById(availableTenants.data[0].id);
    } else if (!availableTenants.data.length) {
      /**
       * Since we're not passing the payload, the selected tenant will be reset
       * Resetting the selected tenant so that the previously selected tenant ID(stored in local-storage) doesn't conflict with the available tenants for the current role
       */
      dispatch(updateSelectedTenant());
    }
  }, [availableTenants]);

  return {
    fetchTenantInfo,
    tenantsLabelValuePair,
    updateSelectedTenantById,
    updateSelectedTenantByAlias,
    selectedTenant,
    setUseHarbinger,
    useHarbinger,
  };
}

export default useTenantAccess;
