// Constants
import ActionTypes from "@Constants/actionTypes";

/**
 * Handle setting currently active tab
 * @param tab The tab which needs to be activated
 * @returns action to be dispatched
 */
export const handleSetCurrentlyActiveTab = (tab: string): GenericAction => ({
  type: ActionTypes.SET_CURRENTLY_ACTIVE_TAB,
  payload: tab,
});

/**
 * Handle setting the currently active page tab
 * @param tabName name of in-page tab to be activated
 * @param replace after update, the route should be replaced(if true) or a new route should be pushed(default)
 * @param blacklistParams - the query params that should be discarded while creating the new params for the url
 * @returns action to be dispatched
 */
export const handleSetCurrentlyActivePageTab = (
  tabName: string,
  replace?: boolean,
  blacklistParams?: string[]
): GenericAction => ({
  type: ActionTypes.SET_CURRENTLY_ACTIVE_PAGE_TAB,
  payload: {
    tabName,
    replace: replace || false,
    blacklistParams,
  },
});

/**
 * Handle setting the last active page tab
 * @returns action to be dispatched
 */
export const handleSetLastActivePageTab = (): GenericAction => ({
  type: ActionTypes.SET_LAST_ACTIVE_PAGE_TAB,
});

/**
 * This will reset the replace state, so that routes are not set to the replace state forever
 * Because replacing the route on tab change is not always the case
 * (For eg. route will be replaced if default page-tab is set on page load)
 * @returns Action to reset the replace state for updating in-page tabs
 */
export const resetReplacePageTab = (): GenericAction => ({
  type: ActionTypes.RESET_REPLACE_PAGE_TAB,
});

/**
 * The action for re-setting the blacklisted params once they are discarded
 * @returns Action to reset the blacklisted params
 */
export const resetBlacklistedParams = (): GenericAction => ({
  type: ActionTypes.RESET_BLACKLISTED_PARAMS,
});
