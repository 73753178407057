import { useState } from "react";
import { Typography } from "@material-ui/core";

// Utils
import Utils from "@Utils";

// Constants
import { DefaultFilterLabel } from "@Constants/common";
import { ButtonIDs } from "@Constants/Id";

// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Components
import Popover from "@Components/Popover";
import RadioGroup from "@Components/RadioInput";
import Button from "@Components/Button";

// Types
import { FilterProps } from "./Filter";

// Styles
import useStyles from "./Filter.styles";

/**
 * Filter Component
 *
 * @example Correct usage
 * ```tsx
 * <Filter
 *  id="id"
 *  filterConfig={filterConfig}
 *  onChangeHandler={handleChangeFilter}
 *  defaultFilterValue={defaultValue}
 *  selectedFilterValue={selectedValue}
 * />
 * ```
 */
export const Filter = ({
  id,
  filterConfig,
  selectedFilterValue,
  onChangeHandler,
  filterLabel,
  defaultFilterValue,
  filterGroupName = "filter",
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}: FilterProps): JSX.Element => {
  const classes = useStyles();

  // Track of filter popover is open
  const [filterPopoverOpen, setFilterPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      id={`${id}-popover`}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      popOverChildren={
        <RadioGroup
          id={`${id}-options`}
          radioInputConfigs={filterConfig}
          defaultValue={selectedFilterValue}
          radioGroupName={filterGroupName}
          onChangeHandler={onChangeHandler}
        />
      }
      showBorderRadius
      closeOnClick
      setPopoverOpenState={setFilterPopoverOpen}
      extraClass={classes.filterPopover}
    >
      <Button
        id={ButtonIDs.filterButton({ filterId: id })}
        extraClass="cursor-pointer d-flex padding-0"
        variant="text"
      >
        <Typography
          id={`${selectedFilterValue
            .toLowerCase()
            .replace(/\s/g, "-")}-selected-filter`}
          component="span"
          className={classes.filterText}
        >
          {(selectedFilterValue === defaultFilterValue
            ? filterLabel
            : Utils.getLabelFromLabelValuePair(
                filterConfig,
                selectedFilterValue
              )) ?? DefaultFilterLabel}
        </Typography>
        <ExpandMoreIcon
          id={`${id}-expand-more-icon`}
          className={`${classes.filterToggleIcon} ${
            filterPopoverOpen ? classes.filterToggleIconOpen : ""
          }`}
        />
      </Button>
    </Popover>
  );
};
