import { useEffect, useState } from "react";
import {
  IconButton as MuiIconButton,
  Typography,
  IconButtonProps,
} from "@material-ui/core";

// Icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// Types
import { TablePaginationActionsProps } from "./TablePaginationActions";

// Constants
import { Pagination, StringFormatters } from "@Constants/common";

// Styles
import useStyles from "@Components/Table/TablePagination/TablePagination.styles";

/**
 * TablePaginationActions Component
 * @example Correct usage
 * ```tsx
 * <TablePaginationActions
 *  currentPageNo={currentPageNo}
 *  currentPageNumber={currentPageNumber}
 *  pageNoInRange={pageNoInRange}
 *  disableBackClick={disableBackClick}
 *  disableNextClick={disableNextClick}
 *  lastPageNumber={lastPageNumber}
 *  jumpToPage={jumpToPage}
 * />
 * ```
 */
export const TablePaginationActions = ({
  currentPageNo,
  jumpToPage,
  disableBackClick,
  disableNextClick,
  lastPageNumber,
  totalRowsAvailable,
}: TablePaginationActionsProps): JSX.Element => {
  const classes = useStyles();

  const [pageNoInRange, setPageNoInRange] = useState<number[]>([]);

  const IconButton = ({
    className,
    ...props
  }: IconButtonProps): JSX.Element => (
    <MuiIconButton
      {...props}
      className={`${classes.iconButton} ${className}`}
    />
  );

  // get page number params and update pagination numbers
  useEffect(() => {
    // total page numbers to be printed except first and last
    const totalPageNoInArray = Pagination.maxNoOfPageButtons - 2;
    let tempPageNoArray: number[] = [];

    // if total number of pages is less than 5 print all page numbers
    if (lastPageNumber < Pagination.maxNoOfPageButtons) {
      tempPageNoArray = Array.from(
        { length: lastPageNumber - 2 },
        (_x, i) => i + 2
      );
    } else if (currentPageNo < totalPageNoInArray) {
      tempPageNoArray = Array.from(
        { length: totalPageNoInArray },
        (_x, i) => i + 2
      );
    } else if (lastPageNumber - currentPageNo < totalPageNoInArray) {
      tempPageNoArray = Array.from(
        { length: totalPageNoInArray },
        (_x, i) => lastPageNumber - totalPageNoInArray + i
      );
    } else {
      if (totalPageNoInArray % 2 === 1) {
        for (let i = 0; i < totalPageNoInArray; i++) {
          tempPageNoArray = [
            ...tempPageNoArray,
            currentPageNo + (i - (totalPageNoInArray - 1) / 2),
          ];
        }
      } else {
        for (let i = 1; i <= totalPageNoInArray; i++) {
          tempPageNoArray = [
            ...tempPageNoArray,
            currentPageNo + (i - totalPageNoInArray / 2),
          ];
        }
      }
    }
    setPageNoInRange(tempPageNoArray);
  }, [totalRowsAvailable]);

  // Disables the button to got to the next page on reaching the last page or if disableNextClick prop is passed in as `true`.
  const disableNextButton = Boolean(
    disableNextClick || currentPageNo === lastPageNumber
  );

  // Disables the button to go to the previous page, if current page is the first page or if disablePreviousButton prop is passed in as `true`.
  const disablePreviousButton = disableBackClick || currentPageNo === 1;

  return lastPageNumber > 1 ? (
    <div className={classes.pagingButtons}>
      <IconButton
        disabled={disablePreviousButton}
        onClick={() => jumpToPage(1)}
        className={`${classes.hiddenInMobile} ${
          disablePreviousButton ? classes.disabledIconButton : ""
        }`}
      >
        First
      </IconButton>
      <IconButton
        onClick={() => jumpToPage(currentPageNo - 1)}
        disabled={disablePreviousButton}
        aria-label="Previous page"
      >
        <KeyboardArrowLeft
          className={disablePreviousButton ? classes.disabledIconButton : ""}
        />
      </IconButton>
      <IconButton
        disabled={disablePreviousButton}
        onClick={() => jumpToPage(1)}
        className={currentPageNo === 1 ? classes.activePagingButton : ""}
      >
        01
      </IconButton>
      {currentPageNo > 3 && lastPageNumber > Pagination.maxNoOfPageButtons && (
        <Typography component="span">
          {StringFormatters.truncateString}
        </Typography>
      )}
      {pageNoInRange.map((number) => {
        if (number < lastPageNumber)
          return (
            <IconButton
              key={number}
              onClick={() => jumpToPage(number)}
              className={
                currentPageNo === number ? classes.activePagingButton : ""
              }
            >
              {number < 10 ? "0" + number : number}
            </IconButton>
          );
      })}
      {/* minus 2 because two terminal page buttons are constantly displayed */}
      {lastPageNumber - currentPageNo >= Pagination.maxNoOfPageButtons - 2 && (
        <Typography component="span">
          {StringFormatters.truncateString}
        </Typography>
      )}
      <IconButton
        onClick={() => jumpToPage(lastPageNumber)}
        disabled={disableNextButton}
        className={
          currentPageNo === lastPageNumber ? classes.activePagingButton : ""
        }
      >
        {lastPageNumber < 10 ? "0" + lastPageNumber : lastPageNumber}
      </IconButton>
      <IconButton
        onClick={() => jumpToPage(currentPageNo + 1)}
        disabled={disableNextButton}
        aria-label="Next page"
      >
        <KeyboardArrowRight
          className={disableNextButton ? classes.disabledIconButton : ""}
        />
      </IconButton>
      <IconButton
        onClick={() => jumpToPage(lastPageNumber)}
        disabled={disableNextButton}
        className={`${classes.hiddenInMobile} ${
          disableNextButton ? classes.disabledIconButton : ""
        }`}
      >
        Last
      </IconButton>
    </div>
  ) : (
    <></>
  );
};
