import { TimelineDot as MuiTimelineDot } from "@material-ui/lab";

// Components
import SVGWrapper from "@Components/SVGWrapper";

// Types
import { TimelineDotProps } from "./TimelineDot";

// Styles
import useStyles from "./TimelineDot.styles";

/**
 * TimelineDot Component
 * @example Correct usage
 * ```tsx
 * <TimelineDot
 *  icon={icon}
 *  iconConfig={iconConfig}
 *  dotColor={dotColor}
 *  dotVariant={dotVariant}
 * />
 * ```
 */
export const TimelineDot = (props: TimelineDotProps) => {
  const classes = useStyles({
    ...(props.type === "dot" && { dotColor: props.dotColor }),
  });

  return props.type === "dot" ? (
    <MuiTimelineDot
      variant={props.dotVariant}
      className={`${classes.timelineDot} ${
        props.dotVariant === "default" && classes.timelineDotFilled
      } ${props.dotVariant === "outlined" && classes.timelineDotOutlined}`}
    />
  ) : (
    <SVGWrapper {...props.iconConfig}>{props.icon}</SVGWrapper>
  );
};
