import { AxiosResponse } from "axios";

// Actions
import { showAlert } from "@Store/actions";

// Constants
import Constants from "@Constants";
import { AbortRequestErrorMessage, AlertSeverity } from "@Constants/common";

// Helpers for mockAPI
import mockAPIHelpers from "@Helpers/mockAPI";

// env variables
import envVariables from "@Config";

type BindClientParams = {
  clientFunc: (
    abortSignal: AbortSignal | null,
    ...args: any
  ) =>
    | Promise<AxiosResponse<never>>
    | Promise<AxiosResponse<{ [key: string]: string | string[] | boolean }>>;
  onRequest: string;
  onSuccess: string;
  onFailure: string;
  params: Array<string | string[] | Record<string, any>>;
  abortSignal?: AbortSignal;
  handleSuccess?: ApiSuccessReporting;
  handleError?: ApiErrorReporting;
};

// A common wrapper to make API calls
const bindClientFunc = (params: BindClientParams) => {
  const isStringArray = (x: any[]): x is string[] =>
    x.every((i) => typeof i === "string");

  const { handleError, handleSuccess } = params;

  return async (dispatch: DispatchType): Promise<BindClient> => {
    if (params.onRequest) {
      dispatch(<GenericAction>{ type: params.onRequest });
    }
    let response = null;
    let errorMessage: string | null = null;

    // If USE_MOCK is true then use existing mock data and stop actual API calls
    if (envVariables.USE_MOCK) {
      const mockAPIData = await (await import("../mockData")).default();
      const parsedMockData = mockAPIData as unknown as Record<string, string>;
      const requestedData = parsedMockData[
        params.onSuccess as string
      ] as unknown as Record<string, Record<string, string>>;

      if (requestedData) {
        response = requestedData?.data;

        // Handle auth for mock API
        if (params.onSuccess === Constants.ActionTypes.LOGIN_SUCCESS) {
          if (isStringArray(params.params))
            if (!mockAPIHelpers.handleAuth(params.params)) {
              errorMessage = Constants.ErrorMessages.invalidLoginCredential;
              dispatch(<GenericAction>{
                type: params.onFailure,
                payload: errorMessage,
              });

              return { response, errorMessage };
            }
        }

        /**
         * Using setTimeout to add a delay of 1000 milliseconds before receiving the mock data
         * this is done to showcase loader
         */
        setTimeout(() => {
          const payload = !requestedData?.pagination
            ? requestedData?.items
            : {
                pagination: requestedData?.pagination,
                data: requestedData?.items,
              };

          dispatch(<GenericAction>{
            type: params.onSuccess,
            payload,
          });
        }, Constants.TimeoutIntervals.mockServerDelay);
      } else {
        errorMessage = Constants.ErrorMessages.mockAPIDataNotAvailable;
        dispatch(<GenericAction>{
          type: params.onFailure,
          payload: errorMessage,
        });
      }
    } else {
      await params
        .clientFunc(params.abortSignal ?? null, ...params.params)
        .then((res) => {
          response = res?.data?.items ?? res?.data?.data ?? res?.data;
          const payload = !res?.data.pagination
            ? res?.data?.items ?? res?.data?.data ?? res?.data
            : {
                pagination: res?.data.pagination,
                data: res?.data?.items ?? res?.data?.data,
              };
          dispatch(<GenericAction>{
            type: params.onSuccess,
            payload,
          });
          if (handleSuccess?.message) {
            showAlert({
              message: handleSuccess.message,
              severity: AlertSeverity.success,
            });
          }
          handleSuccess?.sideEffect?.(payload as Record<string, unknown>);
        })
        .catch(function (error) {
          if (error) {
            if (error.message === AbortRequestErrorMessage) {
              return;
            }
            errorMessage = Constants.ErrorMessages.generalMessage;
            const errorStatusCode = error.response.status as number;
            if (error?.response) {
              errorMessage =
                error?.response?.data?.errors?.[0] ||
                error?.response?.data?.error ||
                error?.response?.data?.status ||
                error?.response?.data?.msg ||
                error?.response?.data?.reason ||
                (typeof error?.response?.data === "string" &&
                  error?.response?.data);
            }
            if (
              errorMessage === Constants.ErrorMessages.tokenExpired ||
              errorMessage === Constants.ErrorMessages.jwtExpired ||
              errorMessage ===
                Constants.ErrorMessages.invalidOrMissingRefreshToken
            ) {
              errorMessage = Constants.ErrorMessages.sessionExpired;
            } else if (errorMessage === Constants.ErrorMessages.tokenRevoked) {
              errorMessage = Constants.ErrorMessages.sessionRevoked;
            }
            dispatch(<GenericAction>{
              type: params.onFailure,
              payload: errorMessage,
            });

            // Get the error handlers
            const { skipErrorReporting, customErrorMessage, sideEffect } =
              handleError || {};

            // Check if error message shouldn't be shown
            const skipShowingError =
              typeof skipErrorReporting === "boolean"
                ? skipErrorReporting
                : skipErrorReporting?.(errorMessage, errorStatusCode);

            // Check if custom message is provided
            const customMessage =
              typeof customErrorMessage === "string"
                ? customErrorMessage
                : customErrorMessage?.(errorMessage, errorStatusCode);

            // Show the error message
            if (errorMessage && !skipShowingError) {
              dispatch(
                showAlert({
                  severity: AlertSeverity.error,
                  message: customMessage || errorMessage,
                })
              );
            }

            // Side effect
            sideEffect?.(errorMessage, errorStatusCode);
          }
        });
    }
    return { response, errorMessage };
  };
};

export default bindClientFunc;
