import { useEffect, useState } from "react";
import { useTheme, alpha } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

// Actions
import { fetchDeviceInfoDetails } from "@Store/actions";

// Containers
import ErrorBoundary from "@Containers/errorBoundary";

// Components
import Tabs from "@Components/Tabs";
import SVGWrapper from "@Components/SVGWrapper";
import EventsForDevice from "./subComponents/eventsForDevice";
import AboutDevice from "./subComponents/aboutDevice";
import Panel from "./subComponents/deviceDetailsTabPanel";
import DeviceExtractedKeys from "./subComponents/deviceExtractedKeys";

// Constants
import Constants from "@Constants";
import {
  DeviceDetailsPageTabs,
  DeviceDetailsQueryPageTabs,
  DeviceDetailsTabIndex,
  DeviceDetailTabs,
  QueryParams,
} from "@Constants/common";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import { SortingQueryParamNames } from "@Constants/sorting";
import { SVGIconIds, TabIds } from "@Constants/Id";

// Utils
import Utils from "@Utils";

// hooks
import useTab from "@Hooks/useTab";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useCustomHistory from "@Hooks/useCustomHistory";

const DeviceDetailsContainer = (): JSX.Element => {
  const theme = useTheme();
  const tabs = useTab();
  const selectedTenant = useTenantAccess();

  // get the device id of the current device
  const { id } = useParams<{ id: string }>();

  // Initialize useLocation hook
  const location = useLocation();

  // get the device id of the current device
  const queryParams = new URLSearchParams(location.search);

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();
  const history = useCustomHistory();

  const { canAccess } = usePermissionFilter();

  const deviceDetailsTabs: TabParameters[] = [
    DeviceDetailsQueryPageTabs.events,
    DeviceDetailsQueryPageTabs.system,
    DeviceDetailsQueryPageTabs.os,
    // TODO: uncomment below to show EPP tab also
    // DeviceDetailsQueryPageTabs.epp,
    DeviceDetailsQueryPageTabs.extractedKeys,
  ];

  const isValidTabQueryParam = (): boolean => {
    const params = Object.keys(DeviceDetailsPageTabs).indexOf(
      queryParams.get(Constants.QueryParams.tab) as string
    );
    return params == -1 ? false : true;
  };

  const [currentTab, setCurrentTab] = useState(() => {
    const paramString = Utils.getTabStringFromQueryParams(queryParams);
    return isValidTabQueryParam() ? paramString : DeviceDetailsTabIndex.events;
  });

  useEffect(() => {
    dispatch(
      fetchDeviceInfoDetails({
        deviceId: id,
      })
    );
  }, [id, selectedTenant]);

  const tabsData: TabsData[] = [
    {
      tab: {
        icon: <EventIcon />,
        color: alpha(theme.lightTheme.primary.fieryOrange, 0.75),
        label: Constants.DeviceDetailsTabs.events,
        isCustomIcon: true,
      },
      tabPanel: (
        <ErrorBoundary>
          <EventsForDevice deviceId={id} />
        </ErrorBoundary>
      ),
      id: TabIds.events,
    },
    {
      tab: {
        icon: (
          <SVGWrapper
            width={17}
            height={16}
            viewBox="0 0 17 16"
            id={SVGIconIds.systemIcon}
          >
            {Constants.SVGIcons.system}
          </SVGWrapper>
        ),
        color: theme.colors.green,
        label: Constants.DeviceDetailsTabs.system,
        isCustomIcon: true,
      },
      tabPanel: (
        <ErrorBoundary>
          <Panel panelType={Constants.DeviceDetailsTabs.system} />
        </ErrorBoundary>
      ),
      id: TabIds.system,
    },
    {
      tab: {
        icon: (
          <SVGWrapper
            width={17}
            height={16}
            viewBox="0 0 17 16"
            id={SVGIconIds.osIcon}
          >
            {Constants.SVGIcons.os}
          </SVGWrapper>
        ),
        color: theme.palette.primary.main,
        label: Constants.DeviceDetailsTabs.os,
        isCustomIcon: true,
      },
      tabPanel: (
        <ErrorBoundary>
          <Panel panelType={Constants.DeviceDetailsTabs.os} />
        </ErrorBoundary>
      ),
      id: TabIds.operatingSystem,
    },
    // TODO: uncomment the below to show the EPP tab also
    // {
    //   tab: {
    //     icon: (
    //       <SVGWrapper width={17} height={16} viewBox="0 0 17 16">
    //         {Constants.SVGIcons.epp}
    //       </SVGWrapper>
    //     ),
    //     color: theme.lightTheme.primary.warmYellow,
    //     label: Constants.DeviceDetailsTabs.epp,
    //     isCustomIcon: true,
    //   },
    //   tabPanel: (
    //     <ErrorBoundary>
    //       <Panel panelType={Constants.DeviceDetailsTabs.epp} />
    //     </ErrorBoundary>
    //   ),
    // },
    {
      tab: {
        icon: (
          <SVGWrapper
            width={17}
            height={16}
            viewBox="0 0 17 16"
            id={SVGIconIds.extractedKeyIcon}
          >
            {Constants.SVGIcons.extractedKey}
          </SVGWrapper>
        ),
        color: theme.lightTheme.secondary.mediumDark,
        label: Constants.DeviceDetailsTabs.extractedKeys,
        isCustomIcon: true,
        hidden: !canAccess(FeatureToAccessTypeMap.extractedKeys.view),
      },
      tabPanel: (
        <ErrorBoundary>
          <DeviceExtractedKeys deviceId={id} />
        </ErrorBoundary>
      ),
      id: TabIds.extractedKeys,
    },
  ];

  // change tabs when browsers back button is clicked
  useEffect(() => {
    if (!Utils.isInvalidTabQueryParam(queryParams)) {
      if (!isValidTabQueryParam()) {
        setCurrentTab(DeviceDetailsTabIndex.events);
        tabs.setActivePageTab(DeviceDetailsTabIndex.events, true);
      } else {
        setCurrentTab(Utils.getTabStringFromQueryParams(queryParams));
        tabs.setActivePageTab(
          Utils.getTabStringFromQueryParams(
            queryParams
          ) as DeviceDetailsTabIndex,
          true,
          SortingQueryParamNames
        );
      }
    }

    /**
     * If on switching tab from events, sorting queries are still applied.
     * then the history will be reset since there is no functionality for sorting in details page.
     * */
    if (
      tabs.currentlyActivePageTab !== DeviceDetailsTabIndex.events &&
      queryParams.has(QueryParams.page)
    ) {
      history.replace(
        `${location.pathname}?tab=${tabs.currentlyActivePageTab}`
      );
    }
  }, [location]);

  useEffect(() => {
    if (!Utils.isInvalidTabQueryParam(queryParams)) {
      if (!isValidTabQueryParam()) setCurrentTab(DeviceDetailsTabIndex.events);
      else setCurrentTab(Utils.getTabStringFromQueryParams(queryParams));
    }
  }, [location]);

  useEffect(() => {
    if (Utils.isInvalidTabQueryParam(queryParams)) {
      //since updating page tab if invalid; after change, the route should be replaced, not pushed
      //that's why passing the second argument(for setting state to replace the route) as true
      tabs?.setActivePageTab(
        currentTab as DeviceDetailsTabIndex,
        true,
        currentTab === DeviceDetailsTabIndex.events
          ? []
          : SortingQueryParamNames
      );
    }
  }, [location.key]);

  const handleTabSwitch = (newTab: number) => {
    const paramString = deviceDetailsTabs[newTab].label;
    setCurrentTab(paramString);
    tabs?.setActivePageTab?.(
      paramString as DeviceDetailsTabIndex,
      false,
      paramString === DeviceDetailsTabIndex.events ? [] : SortingQueryParamNames
    );
  };

  return (
    <>
      <AboutDevice />
      <Tabs
        defaultSelectedTab={(() => {
          return deviceDetailsTabs[
            DeviceDetailTabs[currentTab as DeviceDetailsTabIndex]
          ].index;
        })()}
        onTabSwitch={handleTabSwitch}
        tabs={tabsData}
      />
    </>
  );
};

export default DeviceDetailsContainer;
