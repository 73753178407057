import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

// Components
import LabelValuePairTable from "@Components/Table/LabelValuePairTable";

// Actions
import { fetchDeviceInfoDetails } from "@Store/actions";

// Constants
import Constants from "@Constants";
import {
  DeviceInfoDetailsType,
  OSDetailsInfoType,
} from "@Constants/tableColumns";
import { DeviceInfoContainerIds, TableIds } from "@Constants/Id";

// Utils
import Utils from "@Utils";

// styles
import useStyles from "../styles";

type DeviceInfoDrawerProps = {
  isOpen: boolean;
  deviceId: string;
};

const DeviceInfoDrawer = ({
  deviceId,
  isOpen,
}: DeviceInfoDrawerProps): JSX.Element => {
  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  const classes = useStyles();

  // Get events for device state from redux store
  const { infoDetails, loading }: DeviceInfoDetailsState = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );

  // Format data for different tables from the API response
  const getLabelValuePairsFor = (
    detailsType: DeviceInfoDetailsType
  ): LabelValueTypeForTable[] => {
    if (!Utils.isObjectEmpty(infoDetails)) {
      switch (detailsType) {
        // Format data for Agent details
        case DeviceInfoDetailsType.agentInfo:
          const agentInfo = infoDetails[
            DeviceInfoDetailsType.agentInfo
          ] as Record<string, string>;
          return Utils.getLabelValuePairsForTable(
            agentInfo,
            Constants.TableColumnsConfig.AgentLabelConfigByFieldName
          );

        // Format data for Endpoint protection details
        case DeviceInfoDetailsType.endPointProtectionInfo:
          const endPointDetails = infoDetails[
            DeviceInfoDetailsType.endPointProtectionInfo
          ] as unknown as Record<string, string>[];

          let eppLabelValuePairs: LabelValueTypeForTable[] = [];

          endPointDetails.forEach((endPointInfo) => {
            const data = Utils.getLabelValuePairsForTable(
              endPointInfo,
              Constants.TableColumnsConfig.EppLabelConfigByFieldName
            );

            eppLabelValuePairs = [...eppLabelValuePairs, ...data];
          });

          return eppLabelValuePairs;

        // Format data for System details
        case DeviceInfoDetailsType.systemInfo:
          return Utils.getLabelValuePairsForTable(
            infoDetails[DeviceInfoDetailsType.systemInfo] as Record<
              string,
              string
            >,
            Constants.TableColumnsConfig.SystemsLabelConfigByFieldName
          );

        // Format data for OS details
        case DeviceInfoDetailsType.osDetailsInfo:
          const osInstallInfo = infoDetails[
            OSDetailsInfoType.osInstallInfo
          ] as unknown as Record<string, string>;

          const osInfo = infoDetails[
            OSDetailsInfoType.osInfo
          ] as unknown as Record<string, string>;

          return Utils.getLabelValuePairsForTable(
            { ...osInfo, ...osInstallInfo },
            Constants.TableColumnsConfig.OsLabelConfigByFieldName
          );
      }
    }
    return [];
  };

  // Trigger dispatching the action to fetch data, when the component loads or page number changes in table
  useEffect(() => {
    if (isOpen && deviceId) {
      dispatch(
        fetchDeviceInfoDetails({
          deviceId,
        })
      );
    }
  }, [isOpen, deviceId]);

  const labelValuePairConfigForAgent = getLabelValuePairsFor(
    DeviceInfoDetailsType.agentInfo
  );
  // const labelValuePairConfigForEpp = getLabelValuePairsFor(
  //   DeviceInfoDetailsType.endPointProtectionInfo
  // );
  const labelValuePairConfigForSystem = getLabelValuePairsFor(
    DeviceInfoDetailsType.systemInfo
  );
  const labelValuePairConfigForOs = getLabelValuePairsFor(
    DeviceInfoDetailsType.osDetailsInfo
  );

  const labelWidth = 150;

  return (
    <Grid id={DeviceInfoContainerIds.deviceInfoWrapper} container spacing={2}>
      {(loading || labelValuePairConfigForAgent.length > 0) && (
        <Grid id={DeviceInfoContainerIds.agentInfoWrapper} item xs={12} md={12}>
          <Typography
            id={DeviceInfoContainerIds.agentInfoTableTitle}
            className={classes.sectionTitle}
            variant="subtitle2"
          >
            Agent
          </Typography>
          <LabelValuePairTable
            id={TableIds.agentInfo}
            labelWidth={labelWidth}
            labelValuePairs={labelValuePairConfigForAgent}
            isLoading={loading}
          />
        </Grid>
      )}
      {/* {(loading || labelValuePairConfigForEpp.length > 0) && (
        <Grid item xs={12} md={6}>
          <Typography className={classes.sectionTitle} variant="subtitle2">
            Endpoint Protection
          </Typography>
          <PaginatedTable
            labelWidth={labelWidth}
            labelValuePairs={labelValuePairConfigForEpp}
            isLoading={loading}
          />
        </Grid>
      )} */}
      {(loading || labelValuePairConfigForSystem.length > 0) && (
        <Grid
          id={DeviceInfoContainerIds.systemDetailsWrapper}
          item
          xs={12}
          md={6}
        >
          <Typography
            id={DeviceInfoContainerIds.systemDetailsTableTitle}
            className={classes.sectionTitle}
            variant="subtitle2"
          >
            System
          </Typography>
          <LabelValuePairTable
            id={TableIds.system}
            labelValuePairs={labelValuePairConfigForSystem}
            labelWidth={labelWidth}
            isLoading={loading}
          />
        </Grid>
      )}
      {(loading || labelValuePairConfigForOs.length > 0) && (
        <Grid id={DeviceInfoContainerIds.osDetailsWrapper} item xs={12} md={6}>
          <Typography
            id={DeviceInfoContainerIds.osDetailsTableTitle}
            className={classes.sectionTitle}
            variant="subtitle2"
          >
            Operating System
          </Typography>
          <LabelValuePairTable
            id={TableIds.operatingSystem}
            labelValuePairs={labelValuePairConfigForOs}
            labelWidth={labelWidth}
            isLoading={loading}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceInfoDrawer;
