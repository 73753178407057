import React from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@material-ui/core";

// Styles
import useStyles from "./Checkbox.styles";

// Types
import { CheckboxProps } from "./Checkbox";

// Constants
import { CheckboxIds } from "@Constants/Id";

/**
 * Checkbox component - rendering the checkbox
 *
 * @example Correct usage
 * ```ts
 * <Checkbox
 *  id="id"
 *  isChecked={isChecked}
 *  onChange={handleChange}
 *  label={label}
 *  />
 * ```
 */
export const Checkbox = ({
  id,
  isChecked = false,
  onChange,
  label,
  name,
  extraClass = "",
}: CheckboxProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      id={CheckboxIds.formLabel({ id })}
      control={
        <MuiCheckbox
          id={id}
          checked={isChecked}
          name={name}
          className={classes.checkbox}
          size="small"
        />
      }
      label={label}
      onChange={(_event: React.ChangeEvent<{}>, checked: boolean) =>
        onChange(checked)
      }
      className={`${classes.formControlLabel} ${extraClass}`}
    />
  );
};
