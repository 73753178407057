import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Breakpoints
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      overflow: "hidden!important",
    },
    "& .MuiTable-root": {
      [theme.breakpoints.down(Breakpoints.mobileMin)]: {
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  headerRow: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "none",
    },
  },
  row: {
    color: theme.newPalette.text.primary.main,
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "flex",
      flexDirection: "column",
      borderRadius: theme.pxToRem(4),
      padding: `${theme.pxToRem(16)} ${theme.pxToRem(12)}`,
      marginBottom: theme.pxToRem(10),
    },
    "&:hover": {
      backgroundColor: theme.newPalette.table.rowBgOnHover,
      boxShadow: `${theme.pxToRem(10)} ${theme.pxToRem(10)} ${theme.pxToRem(
        40
      )} ${theme.newPalette.table.rowShadowColorOnHover}`,
    },
    "& .MuiTableCell-root:first-child": {
      borderRadius: `${theme.pxToRem(8)} 0 0 ${theme.pxToRem(8)}`,
    },
    "& .MuiTableCell-root:last-child": {
      borderRadius: `0 ${theme.pxToRem(8)} ${theme.pxToRem(8)} 0`,
    },
  },
  pagingButtons: {
    display: "flex",
    marginRight: theme.pxToRem(5),
    "& .MuiIconButton-root": {
      width: theme.pxToRem(40),
      height: theme.pxToRem(40),
    },
  },
  tableRoot: {
    "& .MuiTableContainer-root": {
      overflow: "visible",
    },
  },
  hideBoxShadow: {
    "&.MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  emptyStateContainer: {
    margin: `${theme.pxToRem(42)} 0 ${theme.pxToRem(42)} 0`,
  },
  tableRow: {
    fontSize: theme.pxToRem(13),
    padding: theme.pxToRem(17),
  },
  tableCell: {
    fontSize: theme.pxToRem(13),
  },
  tableRowBorderLeft: {
    borderLeft: `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`,
    opacity: 1,
  },
  noDataFound: {
    fontSize: theme.pxToRem(14),
    color: theme.colors.grey,
    verticalAlign: "baseline",
  },
  isLoading: {
    height: theme.pxToRem(300),
  },
  disabledIconButton: {
    color: theme.colors.lightGrey,
  },
  emptyStateWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  optionItem: {
    padding: `${theme.pxToRem(5)} ${theme.pxToRem(15)}`,
    "&:hover": {
      backgroundColor: theme.colors.bgGrey,
    },
  },
  copyIcon: {
    fontSize: theme.pxToRem(20),
    color: theme.colors.textLightGrey,
    position: "absolute",
    right: theme.pxToRem(17),
    display: "none",

    "&:hover": {
      color: theme.lightTheme.secondary.clearWater,
    },
  },
  showCopyIcon: {
    "&:hover > .copy-icon": {
      display: "initial",
    },
  },
  rowsPerPageSelector: {
    "& .MuiInputBase-input": {
      paddingBottom: theme.pxToRem(3),
    },
  },
  maskedInput: {
    backgroundColor: "transparent",
    border: 0,
  },
}));

export default useStyles;
