import React from "react";
import { IconButton, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

// Constants
import { NewSortOrderMap, ResetStateForSortBy } from "@Constants/sorting";

// Types
import { SortDropdownGroupProps } from "./SortDropdownGroup";

// Styles
import useStyles from "./SortDropdownGroup.styles";

/**
 * SortDropdownGroup Component
 *
 * @example Correct usage
 * ```tsx
 *  <SortDropdownGroup
 *    id="id"
 *    value={sortBy}
 *    sortState={sortOrder}
 *    options={options}
 *    onChange={handleSortFilterChange}
 *  />
 *```
 */
export const SortDropdownGroup = ({
  id,
  options,
  placeholder = "Sort By",
  value,
  sortState = "desc",
  onChange,
}: SortDropdownGroupProps) => {
  const classes = useStyles({ sortState, value });

  const onSortByChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    onChange(event.target.value as string, sortState);

  const onSortOrderChangeHandler = () =>
    onChange(value, NewSortOrderMap[sortState ?? "default"]);

  /**
   * aria-label for the button to toggle the sort order
   * @returns aria-label for the button to toggle the sort order
   */
  const getSortOrderLabel = () =>
    `Sort in ${sortState === "desc" ? "ascending" : "descending"} order`;

  return (
    <div id={id} className={`${classes.dropdownGroupContainer} d-inline-flex`}>
      <Select
        id={`${id}-sort-by`}
        displayEmpty
        className={classes.selectStyles}
        value={value ?? ""}
        onChange={onSortByChange}
        IconComponent={ExpandMore}
        input={
          <OutlinedInput classes={{ notchedOutline: classes.selectBorder }} />
        }
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.dropdownPaper,
            list: classes.menuItem,
          },
        }}
        renderValue={() =>
          options.find((option) => option.value === value)?.label ?? placeholder
        }
      >
        <MenuItem disabled className="reset-item" value={ResetStateForSortBy}>
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            id={`${option.value}-item`}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        id={`${id}-sort-order`}
        onClick={onSortOrderChangeHandler}
        className={classes.iconButton}
        aria-label={getSortOrderLabel()}
      >
        <ExpandMore viewBox="0 7 24 24" className="icon-up" />
        <ExpandMore viewBox="0 7 24 24" className="icon-down" />
      </IconButton>
    </div>
  );
};
