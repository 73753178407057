import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { List } from "@material-ui/core";

// Actions
import {
  createTag,
  fetchDeviceTagCount,
  fetchTagList,
  resetDeleteTagState,
} from "@Store/actions";

// Components
import Button from "@Components/Button";
import Modal from "@Components/Modal";
import InputField from "@Components/InputField";
import EmptyState from "@Components/EmptyState";
import TagItem from "./TagItem";
import TagListSkeletonLoader from "./TagListSkeletonLoader";

// Validation
import { createNewTagValidation } from "@Helpers/validation";

// Constants
import Constants from "@Constants";
import { EmptyStateMessages } from "@Constants/common";
import {
  ButtonIDs,
  EmptyStateIds,
  InputFieldIds,
  ModalIds,
} from "@Constants/Id";

//Styles
import useStyles from "./styles";

type ManageTagModalProps = {
  isModalOpen: boolean;
  handleClose: () => void;
};

const ManageTagModal = ({ isModalOpen, handleClose }: ManageTagModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { tags, loading } = useSelector((state: GlobalState) => state.tagList);
  const deleteTagState = useSelector((state: GlobalState) => state.deletedTag);
  const { tagCount } = useSelector(
    (state: GlobalState) => state.deviceTagCount
  );
  const { loading: isTagUpdating } = useSelector(
    (state: GlobalState) => state.editTag
  );

  const tagFormConfig = Constants.FormFields.TagConfig;

  const formik = useFormik({
    initialValues: {
      [tagFormConfig.createTag.name]: "",
    },
    validationSchema: createNewTagValidation,
    onSubmit: async (values, { resetForm }) => {
      const tag = values[tagFormConfig.createTag.name];

      /**
       * The actions to be done after the create tag API is successful
       */
      const onCreateTag = async () => {
        resetForm({ values: { [tagFormConfig.createTag.name]: "" } });
        const { response: updatedTagList } = await dispatch(fetchTagList());
        await dispatch(fetchDeviceTagCount({ tags: updatedTagList }));
      };

      dispatch(createTag(tag, { sideEffect: onCreateTag }));
    },
  });

  /**
   * Used to track which tag is on edit state.
   * Since tag name is unique will use it to keep track.
   */
  const [editableTag, setEditableTag] = useState<string | null>(null);

  /**
   * Function sets the editable item with the help of tag name.
   * @param id - tracks the list item using tag name.
   */
  const onSetEditableTagItemHandler = (tag: string | null) =>
    setEditableTag(tag);

  useEffect(() => {
    if (deleteTagState.isDeleted) {
      dispatch(fetchTagList());
      dispatch(resetDeleteTagState());
    }
  }, [deleteTagState.isDeleted]);

  useEffect(() => {
    if (isModalOpen) {
      setEditableTag(null);

      // Delete the fetch device tags count
      dispatch(
        fetchTagList({
          sideEffect: () => dispatch(fetchDeviceTagCount({ tags })),
        })
      );

      // Reset the form
      formik.resetForm();
    }
  }, [isModalOpen]);

  return (
    <Modal
      id={ModalIds.manageTag}
      isOpen={isModalOpen}
      close={handleClose}
      title="Manage Labels"
      modalExtraClass={classes.modal}
      modalTitleExtraClass={`text-align-left ${classes.modalTitle}`}
      modalSubtitleExtraClass={`text-align-left ${classes.modalSubTitle}`}
      modalContentExtraClass={classes.modalContent}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={`d-flex ${classes.inputWrapper}`}
      >
        <InputField
          id={InputFieldIds.modalCreateTag}
          type="text"
          name={tagFormConfig.createTag.name}
          fullWidth
          variant="filled"
          label={tagFormConfig.createTag.label}
          value={formik.values[tagFormConfig.createTag.name]}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          size="small"
          onChange={formik.handleChange}
          autoComplete="off"
          errorMessage={
            formik.touched[tagFormConfig.createTag.name] &&
            formik.errors[tagFormConfig.createTag.name]
          }
          extraClass={classes.inputField}
        />
        <Button
          id={ButtonIDs.createNewTagModal}
          type="submit"
          extraClass={classes.button}
          disabled={!formik.values[tagFormConfig.createTag.name]}
        >
          Create new Label
        </Button>
      </form>
      {
        <>
          {tags.length || loading ? (
            <List className={`${classes.tagList} custom-scroll-bar`}>
              {!loading && !isTagUpdating ? (
                tags.map((tag) => (
                  <TagItem
                    key={tag}
                    id={tag}
                    tag={tag}
                    isEditable={tag === editableTag}
                    onSetEditableTagItem={onSetEditableTagItemHandler}
                    taggedNumber={tagCount[tag] ?? 0}
                  />
                ))
              ) : (
                <TagListSkeletonLoader />
              )}
            </List>
          ) : (
            <EmptyState
              id={EmptyStateIds.tagList}
              extraClass="margin-top-15"
              messageTitle={EmptyStateMessages.manageTagModal}
            />
          )}
        </>
      }
    </Modal>
  );
};

export default ManageTagModal;
