import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// Component
import HeaderComponent from "@Components/Header";
import DeleteDevice from "@Containers/deviceDetails/subComponents/deleteDevice";
import DeleteUser from "@Containers/UserDetails/DeleteUserHeaderBtn";

// Hooks
import useTab from "@Hooks/useTab";
import useCustomHistory from "@Hooks/useCustomHistory";
import useAuth from "@Hooks/useAuth";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Actions
import {
  handleSetCurrentlyActivePageTab,
  resetBlacklistedParams,
  resetReplacePageTab,
  toggleSidebar,
} from "@Store/actions";

// Containers
import AddBulkLabelHeaderBtn from "@Containers/devices/addBulkLabel/addBulkLabelHeaderBtn";

// Constants
import {
  LeftMenuTabs,
  DashboardPageTabs,
  DeviceDetailsTabIndex,
  ThreatDetailsTabIndex,
  SecondaryPageTitles,
  SettingsPageTabs,
} from "@Constants/common";
import Constants from "@Constants";
import { BaseRoutePaths } from "@Constants/routes";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import { SortingQueryParamNames } from "@Constants/sorting";
import { HeaderIds } from "@Constants/Id";

// Utils
import Utils from "@Utils";

const Header = (): JSX.Element | null => {
  // Initialize hooks
  const { userInfo } = useAuth();
  const dispatch = useDispatch();
  const tabs = useTab();
  const { canAccess } = usePermissionFilter();

  // Variables used in handling in-page tabs
  const location = useLocation();
  // Get query params from location
  const queryParams = new URLSearchParams(location.search);
  const history = useCustomHistory();
  const pageTabs = Constants.PageTabs[tabs.currentlyActiveTab as LeftMenuTabs];
  const [currentActivePageTab, setCurrentlyActivePageTab] = useState<string>(
    Utils.getTabStringFromQueryParams(queryParams) || DashboardPageTabs.Events
  );

  useEffect(() => {
    setCurrentlyActivePageTab(
      Utils.getTabStringFromQueryParams(queryParams) ||
        tabs.currentlyActivePageTab ||
        DashboardPageTabs.Events
    );
  });

  const getValidPageTabFromQueryParam = () =>
    Utils.getDataFromNestedObject(
      Constants.AllPageTabsName,
      `${
        Constants.TabRoutes[location.pathname as TabRoutesType]
      }.${queryParams.get("tab")}`
    );

  useEffect(() => {
    if (
      tabs.currentlyActivePageTab ||
      (Utils.getTabStringFromQueryParams(queryParams) &&
        getValidPageTabFromQueryParam())
    ) {
      setCurrentlyActivePageTab(
        Utils.getTabStringFromQueryParams(queryParams) ??
          tabs.currentlyActivePageTab ??
          ""
      );
      const tabName = (Utils.getTabStringFromQueryParams(queryParams) ??
        tabs.currentlyActivePageTab ??
        "") as unknown as
        | DashboardPageTabs
        | DeviceDetailsTabIndex
        | ThreatDetailsTabIndex
        | SettingsPageTabs;
      tabs.setActivePageTab(tabName, true);
    }
    // close sideBar on mobile on location change
    dispatch(toggleSidebar(false));
  }, [location]);

  /**
   * Returns header button
   * @param tab LeftMenuTabs
   */
  const getHeaderButton = (tab: string): JSX.Element | undefined => {
    switch (tab) {
      case SecondaryPageTitles.deviceDetails:
        return canAccess(FeatureToAccessTypeMap.devices.delete) ? (
          <DeleteDevice />
        ) : undefined;
      case SecondaryPageTitles.userDetails:
        return canAccess(FeatureToAccessTypeMap.users.delete) ? (
          <DeleteUser />
        ) : undefined;
      case LeftMenuTabs.Devices:
        return <AddBulkLabelHeaderBtn />;
    }
  };

  /**
   * Handle setting the currently active in-page tab
   * @param tabName name of page tab to be activated
   * @param replace on changing the page tab, the route should be replaced(if true) or pushed(default)
   */
  const handlePageTabChange = (tabName: string, replace?: boolean) => {
    dispatch(
      handleSetCurrentlyActivePageTab(tabName, replace, SortingQueryParamNames)
    );
  };

  // Update in-page tab from query param if a valid tab exists in it
  useEffect(() => {
    const validPageTabFromQueryParam = getValidPageTabFromQueryParam();

    if (validPageTabFromQueryParam) {
      // since updating in-page tab from query param, so the route should not change due to tab-update in any case, hence replace
      handlePageTabChange(validPageTabFromQueryParam, true);
    }
  }, []);

  // Update default active in-page tab
  useEffect(() => {
    /**
     * If no in-page tab already exists then set the default active in-page tab
     */
    if (!getValidPageTabFromQueryParam() && !tabs.currentlyActivePageTab) {
      pageTabs?.map((pageTab) => {
        if (pageTab.isActive) {
          // since the default tab is being activated, a new route shouldn't be pushed, hence replace
          handlePageTabChange(pageTab.name, true);
        }
      });
    }
  }, [tabs.currentlyActiveTab]);

  // Update tab query param in URL on changing tabs
  useEffect(() => {
    if (tabs.currentlyActivePageTab != "") {
      const newPath = Utils.getNewPath(
        location.pathname,
        Utils.getSearchStringToAppend(queryParams, tabs.blacklistedParams),
        {
          page: queryParams.get(Constants.QueryParams.page),
          page_size: queryParams.get(Constants.QueryParams.pageSize),
          tab: tabs.currentlyActivePageTab,
        }
      );
      if (tabs.replacePageTab) {
        history.replace(newPath);
        // the replace state for updating route should now be reset,
        // because replacing the route is the case when page first gets loaded
        // and a new default page-tab is set not forever after that
        dispatch(resetReplacePageTab());
      } else {
        history.push(
          Utils.getNewPath(
            location.pathname,
            Utils.getSearchStringToAppend(queryParams, tabs.blacklistedParams),
            {
              tab: tabs.currentlyActivePageTab,
            }
          )
        );
      }
      dispatch(resetBlacklistedParams());
    }
  }, [tabs.currentlyActivePageTab]);

  return useMemo(
    () => (
      <HeaderComponent
        id={HeaderIds.header}
        tabName={
          Constants.PageTitles[
            Utils.locationPathToBasePath(location.pathname) as BaseRoutePaths
          ] ??
          Constants.PageTitles[tabs.currentlyActiveTab as LeftMenuTabs] ??
          tabs.currentlyActiveTab
        }
        isTabSecondary={
          !!Constants.PageTitles[
            Utils.locationPathToBasePath(location.pathname) as BaseRoutePaths
          ]
        }
        username={`${userInfo.username} ${userInfo.fullname}`}
        headerButton={getHeaderButton(
          Constants.PageTitles[
            Utils.locationPathToBasePath(location.pathname) as BaseRoutePaths
          ]?.title ||
            Constants.PageTitles[tabs.currentlyActiveTab as LeftMenuTabs]
              ?.title ||
            tabs.currentlyActiveTab
        )}
        activePageTab={currentActivePageTab}
        setActivePageTab={handlePageTabChange}
      />
    ),
    [
      tabs.currentlyActiveTab,
      location.pathname,
      tabs.currentlyActivePageTab,
      userInfo,
      currentActivePageTab,
    ]
  );
};

export default Header;
