import { useDispatch, useSelector } from "react-redux";
import { useAuth as useOidcAuth } from "oidc-react";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Actions
import { getUserRoles, updateAccessLevel } from "@Store/actions";

// Constants
import {
  FeatureToAccessTypeMap,
  InheritedHigherAccessMap,
} from "@Constants/Access.map";
import { DefaultAccessLevel } from "@Constants/common";

interface CheckAccessAndRenderProps {
  /**
   * Access level for the feature
   * @default "ReadOnly"
   */
  featureAccessLevel?: AccessLevelTypes;
  /**
   * Feature UI element
   */
  child: JSX.Element | React.ReactNode;
}

function usePermissionFilter() {
  const dispatch = useDispatch();

  const { selectedTenant } = useTenantAccess();

  const userRoles = useSelector((state: GlobalState) => state.userRoles);

  const currentAccessLevel: AccessLevelTypes = useSelector(
    (state: GlobalState) => state.currentAccessLevel.accessLevel
  );

  /**
   * Check if user has access
   * @param featureAccess access level for the feature
   * @returns boolean (True if user has the access)
   */
  const canAccess = (featureAccess: AccessLevelTypes): boolean =>
    InheritedHigherAccessMap[featureAccess].includes(currentAccessLevel);

  /**
   * Permission Filter
   *
   * @param CheckAccessAndRenderProps
   *
   * This function returns back the child if the user has access
   * else returns null
   */
  const checkAccessAndRender = ({
    featureAccessLevel = DefaultAccessLevel,
    child,
  }: CheckAccessAndRenderProps): JSX.Element | React.ReactNode | null =>
    canAccess(featureAccessLevel) ? child : null;

  /**
   * Check if user has access to the tab
   * @param tabId Tab name
   * @returns boolean
   */
  const userHasTabAccess = (tabId: ModulesWithRbac) =>
    !FeatureToAccessTypeMap[tabId]?.view ||
    canAccess(FeatureToAccessTypeMap[tabId].view);

  /**
   * Fetch user roles
   */
  const fetchAccessRoles = async () => {
    dispatch(getUserRoles());
  };

  /**
   * Check and update the current access level of the user
   */
  const updateCurrentAccessLevel = () => {
    /**
     * Update role depending upon the data from the API
     * If groupId === "Halcyon", set the given access level for all the available tenants and sub-tenants
     * Otherwise set access level depending upon the groupId.
     * If for a particular role, the groupId matches the tenantId, then access level for that tenant will be the given access level for that particular role
     */
    const userRole = userRoles.data?.find(
      (role) =>
        role.group === "Halcyon" ||
        role.group === selectedTenant.selectedTenantId
    );
    if (userRole) {
      dispatch(updateAccessLevel({ accessLevel: userRole.accessLevel }));
    }
  };

  return {
    canAccess,
    checkAccessAndRender,
    fetchAccessRoles,
    currentAccessLevel,
    userRoles,
    updateCurrentAccessLevel,
    userHasTabAccess,
    isIdpUser: !!useOidcAuth().userData?.profile?.identity_origin,
  };
}

export default usePermissionFilter;
