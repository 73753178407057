import { Typography } from "@material-ui/core";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import Button from "@Components/Button";

// Constants
import Constants from "@Constants";
import { ButtonIDs } from "@Constants/Id";

// Styles
import useStyles from "./EmptyState.styles";

// Types
import { EmptyStateProps } from "./EmptyState";

/**
 * EmptyState component
 *
 * @example Correct usage - default
 * ```ts
 * <EmptyState id="id" />
 * ```
 *
 * @example Correct usage with custom message and image
 * ```ts
 * <EmptyState
 *  id="id"
 *  svgImage={svgImageJSX}
 *  svgHeight={height}
 *  svgWidth={width}
 *  messageTitle="Custom message title for the empty state"
 * />
 * ```
 */
export const EmptyState = ({
  id,
  svgHeight = 190,
  svgWidth = 190,
  svgImage = Constants.SVGIcons.pageNotFoundIcon,
  extraClass = "",
  messageTitle = "There is no data to display.",
  messageSubTitle = null,
  actionButton = null,
}: EmptyStateProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={`${classes.contentContainer} flex-column justify-center ${extraClass}`}
    >
      <div className={classes.notFoundIconContainer}>
        <SVGWrapper
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          id={`${id}-image`}
        >
          {svgImage}
        </SVGWrapper>
      </div>
      <div className="flex-column">
        <Typography
          id={`${id}-title`}
          variant="h5"
          align="center"
          component="h2"
          className={classes.heading}
        >
          {messageTitle}
        </Typography>
        {messageSubTitle && (
          <Typography
            id={`${id}-subtitle`}
            variant="body2"
            align="center"
            className={classes.navigateMsg}
          >
            {messageSubTitle}
          </Typography>
        )}
        {/* TODO: Refactor once button component is modified according to revamp-2 */}
        {actionButton && (
          <Button
            id={ButtonIDs.emptyStateActionButton({ id })}
            size="small"
            onClick={actionButton.onClick}
            extraClass={`${classes.actionButton} ${actionButton?.extraClass}`}
          >
            {actionButton.text}
          </Button>
        )}
      </div>
    </div>
  );
};
