// API urls
const APIUrls = {
  // API Paths
  Login: "identity/auth/login",
  UserDetails: "v1/user",
  Events: "search/events",
  Devices: "search/devices",
  EventDetails: (eventId: string): string => `search/events/${eventId}`,
  DeviceInfoDetails: (deviceId: string): string => `search/devices/${deviceId}`,
  ThreatDetails: (threatId: string): string => `v1/threat/${threatId}`,
  RefreshToken: "v1/auth/refresh",
  TenantUser: "v1/tenant/user",
  AgentInstaller: "v1/tenant/installer",
  Registration: "v1/registration",
  Predict: "v1/predictor/predict",
  EventMetrics: "search/events/metrics",
  DeviceMetrics: "search/devices/metrics",
  EventMetricsByMonth: "v1/event/metrics-by-month",
  DownloadThreat: (threatId: string): string =>
    `v1/threat/${threatId}/download`,
  UnblockEventAction: (threatId: string): string =>
    `v1/threat/${threatId}/allow`,
  ExtractedKeyInfo: (deviceId: string, extractedKeyId: string): string =>
    `v1/device/${deviceId}/extracted_keys/${extractedKeyId}`,
  DeviceExtractedKeys: (deviceId: string): string =>
    `v1/device/${deviceId}/extracted_keys`,
  PartitionedEventMetrics: "search/events/partitioned-metrics",
  DeleteDevice: (deviceId: string): string => `v1/device/${deviceId}`,
  RequestFile: (eventId: string): string =>
    `v1/event/${eventId}/request_threat`,
  // Endpoint for creating/deleting the tags
  tag: (tag?: string) => `v1/tags${tag ? "/" + tag : ""}`,
  // Endpoint for adding a tag to a device or removing it from a device
  deviceTags: "v1/tags/devices",
  // Endpoint for editing/updating a tag
  editTag: "v1/tags/edit",
  // Endpoint for getting tag count
  deviceTagCount: "search/devices/tags/count",
  // Harbinger API Paths
  HarbingerLogout: "identity/auth/logout",
  HarbingerUserDetails: "identity/user",
  HarbingerUpdateUserPassword: "identity/user/password",
  HarbingerCurrentTenantInfo: "identity/tenants?all=true",
  HarbingerTenantUsers: (tenantId: string) =>
    `identity/tenants/${tenantId}/users`,
  tenantUser: (userId: string, tenantId: string): string =>
    `identity/tenants/${tenantId}/users/${userId}`,
  HarbingerChildTenants: (tenantId: string): string =>
    `identity/tenants/${tenantId}/tenants`,
  HarbingerDeleteChildTenant: (
    tenantId: string,
    parentTenantId: string
  ): string => `identity/tenants/${parentTenantId}/tenants/${tenantId}`,
  HarbingerKeycloakUserAccountInfo: `realms/halcyon/protocol/openid-connect/userinfo`,
  UserRoles: "identity/user/roles",
  TenantUserRole: (userId: string, tenantId: string): string =>
    `identity/tenants/${tenantId}/users/${userId}/role`,
  // Export Config
  ExportConfigs: "config/export",
  // External IDP
  IdpConfig: (tenantId: string) => `identity/tenants/${tenantId}/idp`,
  // Tags to devices
  TagsToDevices: "v1/tags/devices",
  Tags: "v1/tags/",

  // IDP support
  createUpdateIDPConfig: ({
    tenantId,
    isSAML,
  }: {
    tenantId: string;
    isSAML: boolean;
  }) => `identity/tenants/${tenantId}/idp/${isSAML ? "saml" : "oidc"}`,
};

const NoCSRFAPIUrls = [APIUrls.Login, APIUrls.Registration, APIUrls.Predict];

export { APIUrls, NoCSRFAPIUrls };
