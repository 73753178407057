import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as ReChartTooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Typography, useTheme } from "@material-ui/core";

// Components
import SVGWrapper from "@Components/SVGWrapper";
import Tooltip from "@Components/Tooltip";

// constants
import constants from "@Constants";

// Styles
import useStyles from "./BarGraph.styles";

// Types
import { BarGraphType } from "./BarGraph";

const axisTickStyle = {
  fontSize: "10px",
};

/**
 * BarGraph component - to include bar graphs in the UI
 *
 * @example Correct usage
 * ```ts
 * <BarGraph
 *  id="id"
 *  data={graphData}
 *  xAxisDataKeyName="name"
 *  barProperties={barProperties}
 * />
 * ```
 */
export const BarGraph = ({
  id,
  width = 500,
  height = 400,
  marginBottom = 0,
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  maxBarSize = 45,
  tickMargin = 10,
  axisTickColor = "#000000",
  data,
  xAxisDataKeyName,
  showXAxisLine,
  showYAxisLine,
  barProperties,
}: BarGraphType): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [tooltipHoverKey, setTooltipHoverKey] = useState("");

  const toolTipForBarGraph = ({
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    return (
      <div className={classes.tooltip}>
        <Typography
          component="p"
          className={`${classes.tooltipTitle} ${classes.tooltipSecondaryColor}`}
        >
          {label}
        </Typography>
        <div className="flex">
          {payload?.map(
            (payloadData) =>
              payloadData.dataKey === tooltipHoverKey && (
                <div>
                  <Typography
                    variant="h4"
                    component="p"
                    className={classes.tooltipPrimaryColor}
                  >
                    {payloadData.value}
                  </Typography>
                  <Typography
                    component="p"
                    className={`${classes.tooltipSubTitle} text-capitalize`}
                  >
                    {payloadData.name} events
                  </Typography>
                </div>
              )
          )}
          {payload && payload.length > 1 && (
            <div className="margin-left-20">
              {payload?.map((payloadData) => (
                <div className="flex justify-start">
                  <div className="margin-right-10">
                    <SVGWrapper
                      fill={payloadData.color}
                      viewBox="0 0 6 6"
                      height={6}
                      width={6}
                    >
                      {constants.SVGIcons.circle}
                    </SVGWrapper>
                  </div>
                  <Typography
                    component="p"
                    className={`${classes.tooltipSubTitle} text-capitalize`}
                  >
                    {payloadData.value} {payloadData.name} events
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>): JSX.Element | null =>
    active && tooltipHoverKey && payload && payload.length ? (
      <Tooltip
        backgroundColor={theme.newPalette.barGraph.tooltipBg}
        placement="top"
        title={toolTipForBarGraph({ payload, label }) || <></>}
        tooltipPopperExtraClass={classes.tooltipPopper}
        showArrow
        isOpen
      />
    ) : null;

  return (
    <ResponsiveContainer id={id} width="100%" height="100%">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: marginTop,
          bottom: marginBottom,
          left: marginLeft,
          right: marginRight,
        }}
        maxBarSize={maxBarSize}
        className={classes.hideTopLine}
      >
        <XAxis
          dataKey={xAxisDataKeyName}
          tickMargin={tickMargin}
          tickLine={false}
          axisLine={showXAxisLine}
          style={axisTickStyle}
          tick={{ fill: axisTickColor }}
        />
        <YAxis
          padding={{ top: 20 }}
          tickMargin={tickMargin}
          tickLine={false}
          axisLine={showYAxisLine}
          style={axisTickStyle}
          tick={{ fill: axisTickColor }}
        />
        <ReChartTooltip
          offset={0}
          cursor={false}
          content={<CustomTooltip />}
          animationDuration={0}
        />
        {Array.isArray(barProperties) ? (
          barProperties.map((bar, index) => (
            <Bar
              dataKey={bar.dataKey}
              stackId={bar.stackId}
              onMouseEnter={() => setTooltipHoverKey(bar.dataKey || "")}
              fill={bar.fillColor}
              background={
                index !== barProperties.length - 1
                  ? {
                      fill: theme.newPalette.barGraph.emptyBarColor,
                      radius: 8,
                    }
                  : false
              }
              radius={index === 0 ? [0, 0, 8, 8] : [8, 8, 0, 0]}
            />
          ))
        ) : (
          <Bar
            dataKey={barProperties.dataKey}
            stackId={barProperties.stackId}
            fill={barProperties.fillColor}
            radius={8}
            background={{
              fill: theme.newPalette.barGraph.emptyBarColor,
              radius: 8,
            }}
            onMouseEnter={() => setTooltipHoverKey(barProperties.dataKey || "")}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
