import { useState } from "react";
import { Typography, Toolbar as MuiToolbar } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

// Components
import Avatar from "@Components/Avatar";
import Button from "@Components/Button";
import Popover from "@Components/Popover";
import SVGWrapper from "@Components/SVGWrapper";
import ProfilePopoverContent from "../ProfilePopoverContent";
import BackButton from "@Components/BackButton";
import { AutoComplete } from "@Components/AutoComplete";
import Tooltip from "@Components/Tooltip";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";
import useTenantAccess from "@Hooks/useTenantAccess";

// Utils
import Utils from "@Utils";

// Constants
import Constants from "@Constants";
import { Themes } from "@Constants/common";
import {
  AutoCompleteIDs,
  AvatarIDs,
  ButtonIDs,
  HeaderIds,
  SVGIconIds,
} from "@Constants/Id";

// Styles
import useStyles from "@Components/Header/Header.styles";

// Types
import { ToolbarProps } from "./Toolbar";

/**
 * ToolbarMobile Component - the Toolbar component for mobile view
 *
 * @example Correct usage without in-page tab
 * ```ts
 * <Toolbar
 *   id="id"
 *   username="John Doe"
 *   pageTitle="Device Details"
 *   isTabSecondary={true}
 *   handleBack={handleBack}
 *   headerButton={headerButton}
 * />
 * ```
 *
 * @example Correct usage without in-page tab
 * ```ts
 * <Toolbar
 *   id="id"
 *   username="John Doe"
 *   pageTitle="Device Details"
 *   isTabSecondary={true}
 *   handleBack={handleBack}
 *   headerButton={headerButton}
 *   pageTabs={pageTabs}
 *   activePageTab={activePageTab}
 *   setActivePageTab={setActivePageTab}
 * />
 * ```
 */
export const Toolbar = ({
  id,
  pageTitle,
  isTabSecondary = false,
  username,
  headerButton,
  handleBack,
}: ToolbarProps): JSX.Element => {
  const classes = useStyles();
  const [profilePopoverOpen, setProfilePopoverOpen] = useState(false);
  const { activeTheme, toggleTheme } = useAppTheme();
  const appTheme = useTheme();
  const { selectedTenant, tenantsLabelValuePair, updateSelectedTenantByAlias } =
    useTenantAccess();

  return (
    <>
      <MuiToolbar id={id} className={classes.toolbarDesktop}>
        <div className="flex">
          {isTabSecondary && (
            <BackButton id={ButtonIDs.toolbarBackButton} onClick={handleBack} />
          )}
          <Typography
            id={HeaderIds.pageTitle}
            variant="h4"
            className={`text-capitalize ${
              isTabSecondary && "flex justify-start"
            } ${isTabSecondary && classes.secondaryTitle}`}
          >
            {pageTitle}
          </Typography>
        </div>
        {/* TODO: When upgrading users page move this action button to individual pages and remove from header */}
        {headerButton && (
          <div className="margin-left-20 margin-top-5">{headerButton}</div>
        )}
        <div
          className={`flex margin-left-auto ${
            profilePopoverOpen && Utils.pageHasScrollbar() && classes.shiftRight
          }`}
        >
          <Tooltip
            title={`Switch to ${
              activeTheme === Themes.dark ? Themes.light : Themes.dark
            } mode`}
            backgroundColor={appTheme.newPalette.card.background}
            textColor={appTheme.newPalette.text.primary.main}
            placement="bottom"
            showArrow
            tooltipChildren={
              <div className="margin-right-10">
                <Button
                  id={ButtonIDs.toolbarToggleTheme}
                  extraClass={classes.themeToggler}
                  variant="text"
                  onClick={toggleTheme}
                  ariaLabel={`Switch to ${
                    activeTheme === Themes.dark ? Themes.light : Themes.dark
                  } mode`}
                >
                  <SVGWrapper
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    id={SVGIconIds.themeToggleIcon}
                  >
                    {activeTheme === Themes.dark
                      ? Constants.SVGIcons.sunIcon
                      : Constants.SVGIcons.moonIcon}
                  </SVGWrapper>
                </Button>
              </div>
            }
          />
          <div className={classes.tenantFilter}>
            <AutoComplete
              id={AutoCompleteIDs.selectTenantsHeader}
              value={selectedTenant.selectedTenantName}
              defaultValue={selectedTenant.selectedTenantName}
              options={Array.from(
                tenantsLabelValuePair,
                (filter) => filter.label
              )}
              onChange={(value: string | null) =>
                updateSelectedTenantByAlias(value ?? "")
              }
              textFieldSize="small"
              textFieldExtraClass={classes.filterTextField}
              placeholder={selectedTenant.selectedTenantName}
              fillInputOnSelect
            />
          </div>
          {username && (
            <Popover
              id={`${id}-popover`}
              popOverChildren={
                <ProfilePopoverContent
                  showUserNameAndAvatar
                  showSettings
                  id={`${id}-popover-content`}
                />
              }
              setPopoverOpenState={setProfilePopoverOpen}
              extraClass={classes.popoverContainer}
              showDropDownCaret
            >
              <Button
                id={ButtonIDs.toolbarSelectProfile}
                variant="text"
                extraClass="padding-0"
              >
                <Avatar
                  id={AvatarIDs.userAvatarHeader}
                  text={Utils.getFirstLetterOfWordsInString(username)}
                  extraClass={classes.profileImage}
                />
                <Typography
                  variant="subtitle1"
                  className={`${classes.username} text-truncate`}
                >
                  {username}
                </Typography>
              </Button>
            </Popover>
          )}
        </div>
      </MuiToolbar>
    </>
  );
};
