import React, { useEffect, useState, useMemo } from "react";
import { passwordStrength } from "check-password-strength";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// Components
import InputField from "@Components/InputField";
import LevelledProgressBar, {
  ChecklistItem,
} from "@Components/LevelledProgressBar";

// Constants
import Constants from "@Constants";

// Utils
import Utils from "@Utils";

// Types
import { PasswordStrengthResults } from "./PasswordWithPolicy";
import { InputFieldProps } from "@Components/InputField";

/**
 * Data for password checklist
 */
const checklistInitial = [
  {
    completed: false,
    text: Constants.PasswordPolicies.atLeastEightChar,
  },
  {
    completed: false,
    text: Constants.PasswordPolicies.atLeastOneUpper,
  },
  {
    completed: false,
    text: Constants.PasswordPolicies.atLeastOneLower,
  },
  {
    completed: false,
    text: Constants.PasswordPolicies.atLeastOneNumber,
  },
  {
    completed: false,
    text: Constants.PasswordPolicies.atLeastOneSpecial,
  },
];

/**
 * Returns the strength of the given password
 * @param theme - the theme config
 * @param value - the value of the password
 * @returns strength of the given password
 */
const getStrength = (
  theme: Theme,
  value: string | undefined
): PasswordStrengthResults => {
  if (!value) {
    return {
      completedLevels: 0,
      color: "",
      label: "",
    };
  }
  switch (passwordStrength(value).value.toLowerCase()) {
    case "too weak":
      return {
        completedLevels: 1,
        color: theme.lightTheme.primary.poppyRed,
        label: "Too Weak",
      };
    case "weak":
      return {
        completedLevels: 2,
        color: theme.lightTheme.primary.warmYellowLight,
        label: "Weak",
      };
    case "medium":
      return {
        completedLevels: 3,
        color: theme.lightTheme.primary.warmYellow,
        label: "Good",
      };
    case "strong":
      return {
        completedLevels: 4,
        color: theme.lightTheme.primary.green,
        label: "Strong",
      };
  }
  return {
    completedLevels: 0,
    color: "",
    label: "",
  };
};

/**
 * Handles checking which points of the checklist are covered by the given password
 * @param checklist - the checklist items
 * @param value - the current value of the password
 * @returns Updated checklist config and a flag to denote if all the checklist items are covered
 */
const checkIfPointsCovered = (
  checklist: ChecklistItem[],
  value: string
): { checklist: ChecklistItem[]; checklistSatisfied: boolean } => {
  const {
    atLeastEightChar,
    atLeastOneLower,
    atLeastOneUpper,
    atLeastOneNumber,
    atLeastOneSpecial,
  } = Constants.PasswordPolicies;
  const hasDigit = Utils.hasDigit(value);
  const eightChar = value.length > 7;
  const hasUpperCase = Utils.hasUpperCase(value);
  const hasLowerCase = Utils.hasLowerCase(value);
  const hasSpecialChar = Utils.hasSpecialChar(value);
  const checklistSatisfied =
    hasDigit && eightChar && hasUpperCase && hasLowerCase && hasSpecialChar;
  checklist.map((checklistItem) => {
    if (checklistItem.text === atLeastOneNumber)
      checklistItem.completed = hasDigit;
    else if (checklistItem.text === atLeastEightChar)
      checklistItem.completed = eightChar;
    else if (checklistItem.text === atLeastOneLower)
      checklistItem.completed = hasLowerCase;
    else if (checklistItem.text === atLeastOneUpper)
      checklistItem.completed = hasUpperCase;
    else if (checklistItem.text === atLeastOneSpecial)
      checklistItem.completed = hasSpecialChar;
    return checklistItem;
  });
  return { checklist, checklistSatisfied };
};

/**
 * PasswordWithPolicies component - used for password input which should follow a given checklist
 * @example Correct usage
 * ```ts
 * <PasswordWithPolicies
 *  id={uniqueId}
 *  label="Password"
 *  name="password"
 *  onChange={handlePasswordChange}
 *  value={passwordValue}
 *  errorMessage={errorMessage}
 * />
 * ```
 */
export const PasswordWithPolicies = ({
  errorMessage,
  id,
  ...props
}: InputFieldProps): JSX.Element => {
  const [checklist, setChecklist] = useState<ChecklistItem[]>(checklistInitial);
  const [focused, setFocused] = useState(false);
  const [checklistSatisfied, setChecklistSatisfied] = useState(false);
  const theme = useTheme<Theme>();

  useEffect(() => {
    setChecklist((prev) => {
      const satisfiabilityRes = checkIfPointsCovered(prev, props?.value || "");
      setChecklistSatisfied(satisfiabilityRes.checklistSatisfied);
      return [...satisfiabilityRes.checklist];
    });
  }, [props.value]);

  /**
   * Handles the focus event for the password input
   * @param e - the event fired when the password input is focused
   */
  const focusHandler = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setFocused(true);
  };

  /**
   * Handles the blur event for the password input
   * @param e - the event fired whe the password input is blurred
   */
  const blurHandler = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setFocused(false);
  };

  /**
   * The strength of the password
   */
  const strength = useMemo(
    () => getStrength(theme, props.value),
    [props.value]
  );

  return (
    <>
      <InputField
        {...props}
        type="password"
        onFocus={focusHandler}
        onBlur={blurHandler}
        errorMessage={!!errorMessage}
        id={id}
      />
      {(focused || errorMessage) && (
        <LevelledProgressBar
          totalLevels={4}
          completedLevels={strength.completedLevels}
          label={strength.label}
          checklist={checklist}
          showChecklist={!checklistSatisfied}
          id={`${id}-progress-bar`}
        />
      )}
    </>
  );
};
