import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

// Styles
import useStyles from "./SelectWithTextField.styles";

// Types
import { SelectWithTextFieldProps } from "./SelectWithTextField";

/**
 * SelectWithTextField component - can be used to render a select component with given options and ability to enter text if any of the given options isn't suitable
 *
 * @example Correct usage without the custom option
 * ```ts
 * <SelectWithTextField
 *  options={selectOptions}
 *  value={selectValue}
 *  label="Choose Credentials"
 *  onChange={handleChange}
 *  errorMessage={errorMessage}
 * />
 * ```
 *
 * @example Correct usage without the custom option
 * ```ts
 * <SelectWithTextField
 *  options={selectOptions}
 *  value={selectValue}
 *  label="Choose Credentials"
 *  onChange={handleChange}
 *  errorMessage={errorMessage}
 *  customOptions={{
 *    label: "Create new credential",
 *    value: newCredValue,
 *    onChange: handleCustomOptionChange,
 *    onStatusChange: handleCustomOptionSelectionToggle
 *  }}
 * />
 * ```
 */
export const SelectWithTextField = ({
  id,
  options,
  label = "",
  value,
  name,
  onChange,
  errorMessage = null,
  customOption,
  extraClass = "",
}: SelectWithTextFieldProps) => {
  const [customOptionSelected, setCustomOptionSelected] = useState(false);
  const [customOptionFieldFocused, setCustomOptionFieldFocused] =
    useState(false);
  const classes = useStyles();
  const CustomOptionValueInSelect = " ";

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const isCustomOptionSelected =
      (event.target.value as string) === CustomOptionValueInSelect;
    setCustomOptionSelected(isCustomOptionSelected);
    customOption?.onStatusChange?.(isCustomOptionSelected);
    onChange(event);
  };

  return (
    <>
      <FormControl
        id={id}
        error={!!errorMessage}
        variant="outlined"
        className={`text-align-left ${classes.formControl} ${
          customOptionSelected && classes.addBgToMuiSelectIcon
        } ${customOptionFieldFocused && classes.inputActive} ${extraClass}`}
      >
        {customOption && customOptionSelected && (
          <TextField
            value={customOption.value}
            name={customOption.name}
            placeholder={customOption.placeholder}
            onChange={customOption.onChange}
            className={classes.textField}
            onFocus={() => setCustomOptionFieldFocused(true)}
            onBlur={() => setCustomOptionFieldFocused(false)}
            id={`${id}-custom-option`}
          />
        )}
        <>
          {label && (
            <InputLabel className={classes.colorRed} id={`${id}-input-label`}>
              {label}
            </InputLabel>
          )}
          <Select
            MenuProps={{
              classes: {
                paper: classes.dropDown,
              },
            }}
            value={value}
            onChange={handleChange}
            label={label}
            name={name}
            id={`${id}-select`}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className={classes.selectOption}
              >
                {option.label}
              </MenuItem>
            ))}
            {customOption && (
              <MenuItem
                value={CustomOptionValueInSelect}
                className={classes.selectOption}
              >
                {customOption.label}
              </MenuItem>
            )}
          </Select>
        </>
      </FormControl>
      {errorMessage && (
        <div
          className={`${classes.errorMessage} text-align-left`}
          id={`${id}-error-text`}
        >
          {errorMessage}
        </div>
      )}
    </>
  );
};
