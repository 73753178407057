import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

// Components
import InputField from "@Components/InputField";
import Button from "@Components/Button";
import Modal from "@Components/Modal";

// Validation
import { tenantForm } from "@Helpers/validation";

// Actions
import { addTenant } from "@Store/actions";

// Constants
import Constants from "@Constants";
import { ButtonIDs, InputFieldIds, ModalIds, TenantsIds } from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Styles
import useStyles from "./styles";

type AddAndEditTenantPropsType = {
  open: boolean;
  close: () => void;
};

const AddTenant = ({ open, close }: AddAndEditTenantPropsType): JSX.Element => {
  // Initialize styles
  const classes = useStyles();
  const { selectedTenant } = useTenantAccess();

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();
  const addTenantState = useSelector((state: GlobalState) => state.addTenant);
  const { fullName, alias } = Constants.FormFields.AddTenant;

  // handle add tenant form
  const formik = useFormik({
    initialValues: {
      [fullName.name]: "",
      [alias.name]: "",
    },
    enableReinitialize: true,
    validationSchema: tenantForm,
    onSubmit: async (values) => {
      const payload: AddOrUpdateTenantPayload = {
        name: values.name,
        alias: values.alias,
      };
      dispatch(
        addTenant(payload, selectedTenant.selectedTenantId, {
          sideEffect: close,
        })
      );
    },
  });

  /**
   * Reset form when drawer is closed
   */
  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Modal
      id={ModalIds.addAndEditTenant}
      title="Add new Tenant"
      modalExtraClass={classes.modal}
      cancelButtonExtraClass={classes.modalCancelBtn}
      isOpen={open}
      close={close}
    >
      <form
        id={TenantsIds.addOrEditTenantForm}
        onSubmit={formik.handleSubmit}
        className={classes.addOrEditForm}
      >
        <div className="margin-bottom-25">
          <InputField
            id={InputFieldIds.tenantName}
            type="text"
            label={fullName.label}
            name={fullName.name}
            extraClass={classes.inputField}
            onChange={formik.handleChange}
            value={formik.values[fullName.name]}
            errorMessage={
              formik.touched[fullName.name] && formik.errors[fullName.name]
            }
          />
        </div>
        <div className="margin-bottom-25">
          <InputField
            id={InputFieldIds.tenantAlias}
            type="text"
            label={alias.label}
            name={alias.name}
            extraClass={classes.inputField}
            onChange={formik.handleChange}
            value={formik.values[alias.name]}
            errorMessage={
              formik.touched[alias.name] && formik.errors[alias.name]
            }
          />
        </div>
        <div className="flex justify-end">
          <Button
            id={ButtonIDs.addNewTenantConfirmation}
            type="submit"
            disabled={addTenantState.loading}
          >
            Add Tenant
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddTenant;
