import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Types
import { StyleArgs } from "./IconCell";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tableRowBorderLeft: {
    borderLeft: `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`,
    opacity: 1,
  },
  iconCell: {
    border: 0,
    borderLeft: ({ borderAroundCell }: StyleArgs) =>
      borderAroundCell
        ? `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`
        : "initial",
    opacity: ({ borderAroundCell }: StyleArgs) =>
      borderAroundCell ? 1 : "initial",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      color: theme.newPalette.table.color,
      opacity: 0.5,
      fontSize: theme.pxToRem(20),
      "&:hover": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: 0,
    },
  },
  iconBtn: {
    maxHeight: theme.pxToRem(12),

    "&.Mui-focusVisible": {
      boxShadow: `0 0 ${theme.pxToRem(16)} 0px ${
        theme.newColors.primary.fieryOrange[500]
      } `,
    },
  },
}));

export default useStyles;
