import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  //-------------------------Create New Label Modal Styles-------------------------
  modalTitle: {
    alignSelf: "start",
  },

  label: {
    marginBottom: theme.pxToRem(10),
  },

  modalContent: {
    overflow: "hidden",
  },

  actionContainerModal: {
    margin: `${theme.pxToRem(20)} 0 ${theme.pxToRem(10)}`,
    gap: theme.pxToRem(10),
  },

  primaryButton: {
    border: "1px solid transparent",

    "&.MuiButton-root.Mui-disabled": {
      opacity: 0.5,
      backgroundColor: `${theme.newPalette.manageTagModal.disabledButton} !important`,
      borderColor: `${theme.newPalette.manageTagModal.disabledButtonBorder} !important`,
    },
  },
}));

export default useStyles;
