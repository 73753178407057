import { LineChart, Line, ResponsiveContainer } from "recharts";

// Theme
import theme from "@Theme";

// Types
import { SparkLineGraphProps } from "./SparkLineGraph";

/**
 * BarGraph component - to include bar graphs in the UI
 *
 * @example Correct usage
 * ```ts
 * <SparkLineGraph
 *  id="id"
 *  data={graphData}
 *  dataKey="uv"
 * />
 * ```
 *
 * @example Correct usage with stroke color for the line
 * ```ts
 * <SparkLineGraph
 *  id="id"
 *  data={graphData}
 *  dataKey="uv"
 *  strokeColor={color}
 * />
 * ```
 */
export const SparkLineGraph = ({
  id,
  width = 100,
  height = 100,
  marginBottom = 0,
  marginTop = 25,
  marginLeft = 0,
  marginRight = 0,
  strokeColor = theme.lightTheme.secondary.deepBlue,
  curveType = "linear",
  strokeWidth = 3,
  data,
  dataKey,
}: SparkLineGraphProps): JSX.Element => {
  return (
    <ResponsiveContainer id={id} width="100%" height="100%">
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: marginTop,
          bottom: marginBottom,
          left: marginLeft,
          right: marginRight,
        }}
      >
        <Line
          type={curveType}
          dataKey={dataKey}
          stroke={strokeColor}
          dot={false}
          strokeWidth={strokeWidth}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
