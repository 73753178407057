import { TableRow, TableCell } from "@material-ui/core";

// Components
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";

// Constants
import { tableSkeletonConfigs } from "@Constants/common";

// styles
import useStyles from "../Cells/Cell/Cell.styles";

export const TableSkeleton = () => {
  const classes = useStyles({ maxWidth: 150, inHorizontalTable: true });

  return (
    <>
      {Array(tableSkeletonConfigs.noOfRows)
        .fill(
          <SkeletonLoader height={tableSkeletonConfigs.skeletonLoaderHeight} />
        )
        .map((el: JSX.Element, index) => (
          <TableRow key={index}>
            <TableCell className={classes.tableRow} width={50}>
              {el}
            </TableCell>
            <TableCell className={classes.tableRow} width={130}>
              {el}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
