import { useSelector } from "react-redux";
import { List } from "@material-ui/core";

// Components
import TagItem from "../TagItem";
import SuggestionTagListLoader from "../SuggestionTagListLoader";

// Constants
import { MaximumLimitForSelectedTags } from "@Constants/common";

// Styles
import useStyles from "@Containers/TagPopover/TagPopoverBody/styles";

type SuggestionTagsListProps = {
  searchValue: string;
  checkedSuggestions: string[];
  handleCheckInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const SuggestionTagsList = ({
  checkedSuggestions,
  handleCheckInputChange,
  searchValue,
}: SuggestionTagsListProps) => {
  const classes = useStyles();

  const { infoDetails } = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );
  const { tags, loading } = useSelector((state: GlobalState) => state.tagList);

  const appliedTags = (infoDetails.tags as string[]) ?? [];

  return (
    <List className={`${classes.subListContainer}  custom-scroll-bar`}>
      {!loading ? (
        tags
          .filter(
            (suggestion) =>
              suggestion.startsWith(searchValue) &&
              !appliedTags.includes(suggestion)
          )
          .map((suggestion) => (
            <TagItem
              key={suggestion}
              suggestion={suggestion}
              isChecked={
                !!checkedSuggestions.find((checked) => checked === suggestion)
              }
              handleCheckInputChange={handleCheckInputChange}
              isDisabled={
                checkedSuggestions.length >= MaximumLimitForSelectedTags &&
                !checkedSuggestions.find((checked) => checked === suggestion)
              }
            />
          ))
      ) : (
        <SuggestionTagListLoader />
      )}
    </List>
  );
};

export default SuggestionTagsList;
