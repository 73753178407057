// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { SuccessMessages } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

export const fetchEvents = (
  {
    page,
    pageSize,
    sortBy,
    sortOrder,
    deviceName,
    eventName,
    subject,
    actionType,
    search,
  }: EventsPayload,
  abortSignal?: AbortSignal
) =>
  bindClientFunc({
    clientFunc: client.getEvents,
    onFailure: ActionTypes.FETCH_EVENTS_FAILED,
    onRequest: ActionTypes.FETCH_EVENTS_REQUEST,
    onSuccess: ActionTypes.FETCH_EVENTS_SUCCESS,
    params: [
      page.toString(),
      pageSize.toString(),
      sortBy ?? "",
      sortOrder ?? "",
      deviceName ? `\"${deviceName}\"` : "",
      eventName ? `\"${eventName}\"` : "",
      subject ? `\"${subject}\"` : "",
      actionType ?? "",
      search ? `\"${search}\"` : "",
    ],
    abortSignal,
  }) as unknown as Promise<BindClient>;

export const fetchCriticalEvents = (
  {
    page,
    pageSize,
    deviceName,
    eventName,
    subject,
    search,
  }: PageQueryParams & EventsSearchParams,
  sortBy: string,
  sortOrder: string,
  startTime?: string,
  endTime?: string,
  abortSignal?: AbortSignal
) =>
  bindClientFunc({
    clientFunc: client.getCriticalEvents,
    onFailure: ActionTypes.FETCH_CRITICAL_EVENTS_FAILED,
    onRequest: ActionTypes.FETCH_CRITICAL_EVENTS_REQUEST,
    onSuccess: ActionTypes.FETCH_CRITICAL_EVENTS_SUCCESS,
    params: [
      page.toString(),
      pageSize.toString(),
      sortBy,
      sortOrder,
      deviceName ? `\"${deviceName}\"` : "",
      eventName ? `\"${eventName}\"` : "",
      subject ? `\"${subject}\"` : "",
      search ? `\"${search}\"` : "",
      startTime ?? "",
      endTime ?? "",
    ],
    abortSignal,
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching events for a device or threat
export const fetchEventsForDeviceOrThreat = (
  {
    deviceOrThreatId,
    pageQueryParams: { page, pageSize },
    deviceOrThreat,
    sortBy,
    sortOrder,
    deviceName,
    eventName,
    search,
    subject,
  }: EventsForDeviceOrThreatPayload,
  abortSignal?: AbortSignal
) =>
  bindClientFunc({
    clientFunc: client.getEventsForDeviceOrThreat,
    onFailure: ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_FAILED,
    onRequest: ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_REQUEST,
    onSuccess: ActionTypes.FETCH_EVENTS_FOR_DEVICE_OR_THREAT_SUCCESS,
    params: [
      deviceOrThreatId,
      page.toString(),
      pageSize.toString(),
      deviceOrThreat,
      sortBy ?? "",
      sortOrder ?? "",
      deviceName ? `\"${deviceName}\"` : "",
      eventName ? `\"${eventName}\"` : "",
      search ? `\"${search}\"` : "",
      subject ? `\"${subject}\"` : "",
    ],
    abortSignal,
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching events metrics for dashboard
export const fetchEventMetrics = (startTime?: string, endTime?: string) =>
  bindClientFunc({
    clientFunc: client.getEventMetrics,
    onFailure: ActionTypes.FETCH_EVENT_METRICS_FAILED,
    onRequest: ActionTypes.FETCH_EVENT_METRICS_REQUEST,
    onSuccess: ActionTypes.FETCH_EVENT_METRICS_SUCCESS,
    // TODO: Update it and pass directly once it is synced with the updated code for the client function
    params: [...(startTime ? [startTime] : []), ...(endTime ? [endTime] : [])],
    // if startTime and endTime is having a valid value(!undefined), then only add them to array
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching event details
export const fetchEventDetails = (eventId: string) =>
  bindClientFunc({
    clientFunc: client.getEventDetails,
    onFailure: ActionTypes.FETCH_EVENT_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_EVENT_DETAILS_REQUEST,
    onSuccess: ActionTypes.FETCH_EVENT_DETAILS_SUCCESS,
    params: [eventId],
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching event metrics by month
export const fetchEventMetricsByMonth = () =>
  bindClientFunc({
    clientFunc: client.getEventMetricsByMonth,
    onFailure: ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_FAILED,
    onRequest: ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_REQUEST,
    onSuccess: ActionTypes.FETCH_EVENT_METRICS_BY_MONTH_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// unblock event action
export const unblockEventAction = (
  threatId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.unblockEventAction,
    onFailure: ActionTypes.UNBLOCK_EVENT_ACTION_FAILED,
    onRequest: ActionTypes.UNBLOCK_EVENT_ACTION_REQUEST,
    onSuccess: ActionTypes.UNBLOCK_EVENT_ACTION_SUCCESS,
    params: [threatId],
    handleSuccess: {
      message: SuccessMessages.unblockRequestSubmitted,
      ...handleSuccess,
    },
  }) as unknown as Promise<BindClient>;

// Handle actions for fetching partitioned event metrics
export const fetchPartitionedEventMetrics = (
  startTime?: string,
  endTime?: string
) =>
  bindClientFunc({
    clientFunc: client.getPartitionedEventMetrics,
    onFailure: ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_FAILED,
    onRequest: ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_REQUEST,
    onSuccess: ActionTypes.FETCH_PARTITIONED_EVENT_METRICS_SUCCESS,
    params: [...(startTime ? [startTime] : []), ...(endTime ? [endTime] : [])],
  }) as unknown as Promise<BindClient>;
