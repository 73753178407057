import { useCallback, useEffect, useMemo, useState } from "react";

// Components
import Modal from "@Components/Modal";
import Button from "@Components/Button";
import AddOrEditOpenIDConnect from "../AddOrEditOpenIDConnect";
import AddOrEditSAML from "../AddOrEditSAML";
import ConfigDetailsToUser from "../ConfigDetailsToUser";

// Constants
import { AddIdentityProviderModalConst, IdpProtocols } from "@Constants/common";
import { ButtonIDs, ModalIds } from "@Constants/Id";

// Styles
import useStyles from "./styles";

/**
 * Prop types for Add Provider component
 */
type AddOrEditProviderProps = {
  /**
   * Denotes whether the AddOrEditProvider modal is opened or not
   */
  isOpen: boolean;

  /**
   * Handles changing the open state of the modal
   * @param isOpen - the new state of the modal
   */
  setIsOpen: (isOpen: boolean) => void;

  /**
   * If the form data is passed, it'll be used for edit
   */
  formData?: Record<string, string> | null;
};

const AddOrEditProvider = ({
  isOpen,
  setIsOpen,
  formData,
}: AddOrEditProviderProps) => {
  const classes = useStyles();
  const [isSAMLSelected, setIsSAMLSelected] = useState(false);
  const [isOpenIDSelected, setIsOpenIDSelected] = useState(false);
  const [isProviderAdded, setIsProviderAdded] = useState(false);
  const [isIdpConfigVisible, setIsIdpConfigVisible] = useState(true);

  // Buttons for selecting the protocol
  const ProtocolButtons = useMemo(
    () => (
      <div className="flex flex-gap-16">
        <Button
          id={ButtonIDs.selectOidcView}
          extraClass={classes.modalButton}
          onClick={() => setIsOpenIDSelected(true)}
        >
          OpenID Connect
        </Button>
        <Button
          id={ButtonIDs.selectSamlView}
          extraClass={classes.modalButton}
          onClick={() => setIsSAMLSelected(true)}
        >
          SAML
        </Button>
      </div>
    ),
    []
  );

  /**
   * Resets the selected protocol and provider added state when the modal is re-opened
   */
  useEffect(() => {
    if (isOpen) {
      setIsOpenIDSelected(false);
      setIsSAMLSelected(false);
      setIsProviderAdded(false);
    }
  }, [isOpen]);

  /**
   * Set the selected protocol depending on the current external IDP configuration
   */
  useEffect(() => {
    if (formData?.providerType === IdpProtocols.oidc) {
      setIsOpenIDSelected(true);
    } else if (formData?.providerType === IdpProtocols.saml) {
      setIsSAMLSelected(true);
    }
  }, [formData, isOpen]);

  /**
   * Returns the title of the modal
   */
  const getModalTitle: () => string = useCallback(() => {
    if (isProviderAdded)
      return formData
        ? AddIdentityProviderModalConst.providerUpdated
        : AddIdentityProviderModalConst.providerAdded;
    if (isSAMLSelected) return AddIdentityProviderModalConst.samlTitle;
    else if (isOpenIDSelected) return AddIdentityProviderModalConst.openIDTitle;
    return AddIdentityProviderModalConst.title;
  }, [isSAMLSelected, isOpenIDSelected, isProviderAdded]);

  useEffect(() => {
    if (isOpen && !formData) {
      setIsIdpConfigVisible(true);
      return;
    }
    if (formData) {
      setIsIdpConfigVisible(false);
    }
  }, [isOpen, isSAMLSelected, isOpenIDSelected, formData]);

  const showForm = useMemo(
    () => !isProviderAdded && !isIdpConfigVisible,
    [isProviderAdded, isIdpConfigVisible]
  );

  const isIdpConfigCurrentPanel =
    (isOpenIDSelected || isSAMLSelected) && isIdpConfigVisible;

  return (
    <Modal
      id={ModalIds.addOrEditProvider}
      isOpen={isOpen}
      close={(_event, reason) => reason !== "backdropClick" && setIsOpen(false)}
      title={getModalTitle()}
      {...(isIdpConfigVisible &&
        (isSAMLSelected || isOpenIDSelected) && {
          subTitle: AddIdentityProviderModalConst.configProvided,
        })}
      modalExtraClass={`${classes.modal} ${
        (isOpenIDSelected || isSAMLSelected) && !isIdpConfigVisible
          ? classes.modalExpanded
          : ""
      } ${isIdpConfigCurrentPanel ? classes.showIdpConfigExpanded : ""}`}
      modalTitleExtraClass={`${classes.modalTitle} ${
        (isOpenIDSelected || isSAMLSelected) && !isProviderAdded
          ? "text-align-left"
          : ""
      }`}
      modalSubtitleExtraClass={`${classes.modalSubtitle} text-align-left`}
    >
      {!isOpenIDSelected &&
        !isSAMLSelected &&
        !isProviderAdded &&
        ProtocolButtons}

      {!isProviderAdded &&
        (isOpenIDSelected || isSAMLSelected) &&
        isIdpConfigVisible && (
          <ConfigDetailsToUser
            setIsOpenIDSelected={setIsOpenIDSelected}
            setIsSAMLSelected={setIsSAMLSelected}
            setIsIdpConfigVisible={setIsIdpConfigVisible}
            isOidcSelected={isOpenIDSelected}
          />
        )}

      {isOpenIDSelected && showForm && (
        <AddOrEditOpenIDConnect
          setIsIdpConfigVisible={setIsIdpConfigVisible}
          setIsProviderAdded={setIsProviderAdded}
          formData={formData}
        />
      )}
      {isSAMLSelected && showForm && (
        <AddOrEditSAML
          setIsIdpConfigVisible={setIsIdpConfigVisible}
          setIsProviderAdded={setIsProviderAdded}
          formData={formData}
        />
      )}
      {isProviderAdded && (
        <Button
          id={ButtonIDs.providerAddedOk}
          onClick={() => setIsOpen(false)}
          extraClass={classes.modalButton}
        >
          OK
        </Button>
      )}
    </Modal>
  );
};

export default AddOrEditProvider;
