import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  optionRow: {
    padding: `${theme.pxToRem(5)} 0`,
  },

  labelChip: {
    background: theme.newPalette.filterAutoSuggestOption.chipBg,
    padding: `${theme.pxToRem(5)} ${theme.pxToRem(10)}`,
    borderRadius: `${theme.pxToRem(16)}`,
  },

  helperText: {
    color: theme.newPalette.filterAutoSuggestOption.helperTextColor,
  },
}));

export default useStyles;
