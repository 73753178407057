import Constants from "@Constants";

const initialState: EventDetailsState = {
  loading: false,
  eventDetails: {},
  error: "",
};

const eventDetailsReducer = (
  state: EventDetailsState = initialState,
  action: GenericAction
): EventDetailsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EVENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS:
      return {
        loading: false,
        eventDetails: action?.payload,
        error: "",
      };
    case Constants.ActionTypes.FETCH_EVENT_DETAILS_FAILED:
      return {
        loading: false,
        eventDetails: {},
        error: action?.payload,
      };
  }
  return state;
};

export default eventDetailsReducer;
