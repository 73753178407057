import { Typography, Grid, Card } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// Components
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import { InfoCardIconType } from "@Constants/common";

// Types
import { InfoCardPropsType } from "./InfoCard";

// Styles
import useStyles from "./InfoCard.styles";

/**
 * InfoCard Component
 *
 * @example Correct usage
 * ```ts
 * <InfoCard
 *  id="id"
 *  infoTitle="info card title"
 *  infoSubTitle="info card subtitle"
 * />
 * ```
 *
 * @example Correct usage with icon
 * ```ts
 * <InfoCard
 *  id="id"
 *  infoTitle="info card title"
 *  infoSubTitle="info card subtitle"
 *  icon={Constants.SVGIcons.device}
 * />
 */
export const InfoCard = ({
  id,
  infoTitle,
  infoSubtitle,
  onClickInfoSubtitle,
  icon,
  width,
  button,
  iconType = InfoCardIconType.smallPrimary,
  infoSubTitleClasses = "",
  extraClass = "",
  isCssTruncate = true,
  isAlertCard = false,
}: InfoCardPropsType) => {
  const classes = useStyles();
  const theme = useTheme();

  const iconSize = iconType === InfoCardIconType.smallPrimary ? 26 : 52;

  return (
    <Grid
      id={id}
      item
      xs={12}
      sm={width ?? 6}
      lg={width ?? 3}
      className={extraClass}
    >
      <Card
        className={`${button && "flex justify-between"} ${classes.wrapper}`}
      >
        <div
          className={
            width === 12 && iconType === InfoCardIconType.smallPrimary
              ? `flex justify-start ${classes.cardContent}`
              : ""
          }
        >
          {icon && (
            <SVGWrapper
              width={iconSize}
              height={iconSize}
              viewBox={`0 0 ${iconSize} ${iconSize}`}
              fill={
                iconType === InfoCardIconType.smallPrimary
                  ? theme.newPalette.text.primary.main
                  : "none"
              }
              id={`${id}-icon`}
            >
              {icon}
            </SVGWrapper>
          )}
          <div>
            <Typography
              id={`${id}-title`}
              className={classes.infoTitle}
              component="h4"
              variant="body1"
            >
              {infoTitle}
            </Typography>
            <Typography
              id={`${id}-subtitle`}
              component="p"
              variant="subtitle2"
              className={`${classes.infoSubtitle}
              ${onClickInfoSubtitle && `cursor-pointer ${classes.link}`}
              ${isCssTruncate && "text-truncate"} ${infoSubTitleClasses}
              ${isAlertCard && classes.alertSubtitle}`}
              onClick={onClickInfoSubtitle}
            >
              {infoSubtitle}
            </Typography>
          </div>
        </div>
        {button}
      </Card>
    </Grid>
  );
};
