// Types
import { SVGWrapperPropsType } from "./SVGWrapper";

/**
 * SVGWrapper component - svg wrapper for svg icons
 *
 * @example Correct usage with default width, height and viewBox
 * ```ts
 * <SVGWrapper>{icon}</SVGWrapper>
 * ```
 *
 * @example Correct usage with custom width, height and viewBox
 * ```ts
 * <SVGWrapper
 *  width={32}
 *  height={32}
 *  viewBox="0 0 32 32"
 * >
 *  {icon}
 * </SVGWrapper>
 * ```
 */
export const SVGWrapper = ({
  id,
  children,
  viewBox = "0 0 42 42",
  height = 42,
  width = 42,
  fill = "none",
  onHoverFill,
  extraClass = "",
}: SVGWrapperPropsType): JSX.Element => (
  <svg
    id={id}
    width={width}
    height={height}
    viewBox={viewBox}
    fill={onHoverFill || fill}
    xmlns="http://www.w3.org/2000/svg"
    className={extraClass}
  >
    {children}
  </svg>
);
