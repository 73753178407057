import { Theme, darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    padding: `${theme.pxToRem(25)} 0`,
  },
  notFoundIconContainer: {
    borderRadius: "50%",
    padding: theme.pxToRem(50),
    marginBottom: theme.pxToRem(50),
    backgroundColor: darken(theme.newPalette.appBg, 0.05),
  },
  heading: {
    color: theme.newPalette.text.primary.light,
    ...theme.utils.mediumText(),
  },
  navigateMsg: {
    color: theme.newPalette.text.secondary.main,
    width: theme.pxToRem(414),
    padding: `${theme.pxToRem(5)} 0 0 0`,
  },
  actionButton: {
    marginTop: theme.pxToRem(10),
  },
}));

export default useStyles;
