import UserDetailsContainer from "@Containers/UserDetails";

import ErrorBoundary from "@Containers/errorBoundary";

const UserDetails = (): JSX.Element => (
  <ErrorBoundary>
    <UserDetailsContainer />
  </ErrorBoundary>
);

export default UserDetails;
