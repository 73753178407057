// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

// Constants
import {
  AddTagToDevicesParams,
  AlertMessages,
  DeleteTagsFromDevicesParams,
  SuccessMessages,
} from "@Constants/common";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// Dispatch the action to add or remove a given row
export const addOrRemoveRow = (
  rowId: string,
  shouldRemove: boolean
): GenericAction => ({
  type: shouldRemove ? ActionTypes.REMOVE_ROW : ActionTypes.ADD_ROW,
  payload: rowId,
});

// Dispatch the action to select the given rows
export const selectRows = (rowIds: string[]): GenericAction => ({
  type: ActionTypes.SELECT_GIVEN_ROWS,
  payload: rowIds,
});

// Dispatch the action to reset all the selected rows
export const resetSelectedRows = (): GenericAction => ({
  type: ActionTypes.RESET_SELECTED_ROWS,
});

// Dispatch the action to select rows throughout all the pages
export const selectAllPages = (): GenericAction => ({
  type: ActionTypes.SELECT_ALL_PAGES,
});

// Dispatch the action to reset all the selected rows on all pages
export const resetAllPagesSelected = (): GenericAction => ({
  type: ActionTypes.RESET_ALL_SELECTED_PAGES,
});

// Add tag to the specified resource
export const addTagsToDevices = (
  { ids, tags }: AddTagToDevicesParams,
  handleSuccess?: ApiSuccessReporting,
  handleError?: ApiErrorReporting
) =>
  bindClientFunc({
    clientFunc: client.addTagsToDevices,
    onFailure: ActionTypes.ADD_TAGS_TO_DEVICES_FAILED,
    onRequest: ActionTypes.ADD_TAGS_TO_DEVICES_REQUEST,
    onSuccess: ActionTypes.ADD_TAGS_TO_DEVICES_SUCCESS,
    params: [ids, tags],
    handleSuccess: { message: SuccessMessages.tagsAdded, ...handleSuccess },
    handleError,
  }) as unknown as Promise<BindClient>;

// Delete tags from devices
export const deleteTagFromDevice = (
  { ids, tags }: DeleteTagsFromDevicesParams,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.deleteTagsFromDevices,
    onFailure: ActionTypes.DELETE_TAGS_FROM_DEVICES_FAILED,
    onRequest: ActionTypes.DELETE_TAGS_FROM_DEVICES_REQUEST,
    onSuccess: ActionTypes.DELETE_TAGS_FROM_DEVICES_SUCCESS,
    params: [ids, tags],
    handleSuccess,
  }) as unknown as Promise<BindClient>;

// Handle actions for creating a tag
export const createTag = (tag: string, handleSuccess?: ApiSuccessReporting) =>
  bindClientFunc({
    clientFunc: client.createTag,
    onFailure: ActionTypes.CREATE_TAG_FAILED,
    onRequest: ActionTypes.CREATE_TAG_REQUEST,
    onSuccess: ActionTypes.CREATE_TAG_SUCCESS,
    params: [tag],
    handleSuccess: { message: AlertMessages.tagCreated, ...handleSuccess },
  }) as unknown as Promise<BindClient>;

// Handle actions for creating a tag
export const deleteTag = (tag: string, handleSuccess?: ApiSuccessReporting) =>
  bindClientFunc({
    clientFunc: client.deleteTag,
    onFailure: ActionTypes.DELETE_TAG_FAILED,
    onRequest: ActionTypes.DELETE_TAG_REQUEST,
    onSuccess: ActionTypes.DELETE_TAG_SUCCESS,
    params: [tag],
    handleSuccess,
  }) as unknown as Promise<BindClient>;

// List tags
export const fetchTagList = (handleSuccess?: ApiSuccessReporting) =>
  bindClientFunc({
    clientFunc: client.fetchTagList,
    onFailure: ActionTypes.FETCH_TAG_LIST_FAILED,
    onRequest: ActionTypes.FETCH_TAG_LIST_REQUEST,
    onSuccess: ActionTypes.FETCH_TAG_LIST_SUCCESS,
    params: [],
    handleSuccess,
  }) as unknown as Promise<BindClient>;

// Reset delete tag state
export const resetDeleteTagState = (): GenericAction => ({
  type: ActionTypes.RESET_DELETE_TAG_STATE,
});

export const resetAddTagsState = (): GenericAction => ({
  type: ActionTypes.ADD_TAGS_TO_DEVICES_STATE_RESET,
});

// Set last deleted tag
export const setLastDeletedTag = ({ tag }: { tag: string }): GenericAction => ({
  type: ActionTypes.SET_LAST_DELETED_TAG,
  payload: tag,
});

// Clear last deleted tag
export const resetLastDeletedTag = (): GenericAction => ({
  type: ActionTypes.RESET_LAST_DELETED_TAG,
});

// Dispatch the action to reset all the selected rows on all pages
export const resetAllSelectedPages = () => ({
  type: ActionTypes.RESET_ALL_SELECTED_PAGES,
});

/**
 * remove deleted tags from the device info details
 * @tags - deleted tags from the device
 */
export const updateDeletedTagsFromDeviceInfo = ({
  tags,
}: {
  tags: string[];
}): GenericAction => ({
  type: ActionTypes.DELETE_TAGS_FROM_DEVICE_INFO_DETAILS,
  payload: tags,
});

/**
 * Add previously added tags to the device info details
 * @tags - added tags to the device
 */
export const updateAddedTagsToDeviceInfo = ({
  tags,
}: {
  tags: string[];
}): GenericAction => ({
  type: ActionTypes.ADD_TAGS_TO_DEVICE_INFO_DETAILS,
  payload: tags,
});

// Bulk tagging devices matching a filter.
export const tagAllDevicePages = (
  { tags, criterion }: BulkTaggingPayload,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.tagAllDevicePages,
    onFailure: ActionTypes.TAG_ALL_DEVICE_PAGES_FAILED,
    onRequest: ActionTypes.TAG_ALL_DEVICE_PAGES_REQUEST,
    onSuccess: ActionTypes.TAG_ALL_DEVICE_PAGES_SUCCESS,
    params: [{ tags, criterion }],
    handleSuccess,
  }) as unknown as Promise<BindClient>;

// Edit/Update tag
export const editTag = ({ add, remove }: EditTagPayload) =>
  bindClientFunc({
    clientFunc: client.editTag,
    onFailure: ActionTypes.UPDATE_TAG_FAILED,
    onRequest: ActionTypes.UPDATE_TAG_REQUEST,
    onSuccess: ActionTypes.UPDATE_TAG_SUCCESS,
    params: [add, remove],
    handleSuccess: { message: SuccessMessages.tagUpdated },
  }) as unknown as Promise<BindClient>;

// Editing/Updating tag in device info details state
export const updateTagInDeviceInfoDetails = ({
  add,
  remove,
}: EditTagPayload): GenericAction => ({
  type: ActionTypes.UPDATE_TAG_IN_DEVICE_INFO_DETAILS,
  payload: {
    add,
    remove,
  },
});

// Fetch tag count for devices
export const fetchDeviceTagCount = ({ tags }: DeviceTagCountPayload) =>
  bindClientFunc({
    clientFunc: client.deviceTagCount,
    onFailure: ActionTypes.FETCH_DEVICE_TAG_COUNT_FAILED,
    onRequest: ActionTypes.FETCH_DEVICE_TAG_COUNT_REQUEST,
    onSuccess: ActionTypes.FETCH_DEVICE_TAG_COUNT_SUCCESS,
    params: [tags],
  }) as unknown as Promise<BindClient>;

export const resetDeviceInfoDetails = (): GenericAction => ({
  type: ActionTypes.RESET_DEVICE_INFO_DETAILS,
});
