// All route paths are defined here

// Base paths for dynamic protected or public routes
export enum BaseRoutePaths {
  DeviceDetails = "/device-details",
  EventDetails = "/event-details",
  ThreatDetails = "/threat-details",
  Profile = "/profile",
  Security = "/security",
  UserDetails = "/user-details",
}

// Protected routes are those which can be accessed only if user is authenticated
export enum ProtectedRoutes {
  Dashboard = "/dashboard",
  Devices = "/devices",
  Events = "/events",
  DeviceDetails = "/device-details/:id",
  EventDetails = "/event-details/:eventId/:threatId",
  ThreatDetails = "/threat-details/:eventId/:threatId",
  Users = "/users",
  UserDetails = "/user-details/:userId",
  Tenants = "/tenants",
  Profile = "/profile",
  Security = "/security",
  Settings = "/settings",
}

// Public routes are those which do not require user authentication
export enum PublicRoutes {
  LOGIN = "/login",
  SIGNUP = "/register",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  TENANT_MISSING = "/tenant-missing",
  NOT_FOUND = "/404",
}

export const AppRoutes = {
  ProtectedRoutes,
  PublicRoutes,
  BaseRoutePaths,
};

export default AppRoutes;
