import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type StyleArgs = {
  borderAroundCell?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  checkboxCell: {
    border: 0,
    padding: 0,
    borderLeft: ({ borderAroundCell }: StyleArgs) =>
      borderAroundCell
        ? `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`
        : "initial",
    opacity: ({ borderAroundCell }: StyleArgs) =>
      borderAroundCell ? 1 : "initial",
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      padding: 0,
      display: "none",
    },
  },
  checkboxWrapper: { marginLeft: theme.pxToRem(4) },
  checkbox: {
    padding: 0,
    color: theme.newColors.grayscale[400],

    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
      color: theme.newColors.fieryOrange,
    },
  },
}));

export default useStyles;
