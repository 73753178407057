import { makeStyles, Theme } from "@material-ui/core";

import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    padding: `${theme.pxToRem(16)} 0`,
    "& .MuiDialog-paper": {
      color: theme.newPalette.modal.primaryColor,
      background: theme.newPalette.modal.background,
      boxSizing: "border-box",
      width: "100%",
      maxWidth: theme.pxToRem(870),
      maxHeight: `min(100%, ${theme.pxToRem(600)})`,
      padding: theme.pxToRem(40),
      borderRadius: theme.pxToRem(20),
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down(Breakpoints.tabletMin)]: {
        padding: theme.pxToRem(30),
      },
    },
    "& .MuiBackdrop-root": {
      background: theme.newPalette.modal.backdropColor,
    },
  },
  modalTitle: {
    marginBottom: theme.pxToRem(20),
    color: theme.newPalette.modal.primaryColor,
  },
  modalSubtitle: {
    maxWidth: "70%",
    marginBottom: theme.pxToRem(30),
    color: theme.newPalette.modal.secondaryColor,
  },
  modalContent: {
    maxHeight: "100%",
    padding: 0,
  },
  cancelButton: {
    background: "none",
    textDecoration: "none",
    position: "absolute",
    right: theme.pxToRem(30),
    top: theme.pxToRem(30),
    padding: 0,
    width: theme.pxToRem(28),
    height: theme.pxToRem(28),
    minWidth: "initial",

    "& path": {
      fill: theme.newPalette.modal.cancelIconColor,
    },

    "&:hover": {
      background: "none",
    },
  },
}));

export default useStyles;
