// Field name that is used to store the search phrase(search phrase is used for fuzzy matching on all the fields of the table)
export const FieldNameForSearchField = "search";

/**
 * The options for the auto-suggest component on devices table
 */
export const DevicesTableAutoSuggestOptions = [
  {
    label: "Name",
    field: "name",
    helperText: "Filter based on device's name",
  },
  {
    label: "Tag",
    field: "tag",
    helperText: "Filter by tags",
  },
  {
    label: "Operating System",
    field: "operatingSystem",
    helperText: "Filter based on operating system",
  },
  {
    label: "Agent Version",
    field: "agentVersion",
    helperText: "Filter based on the agent version",
  },
];

/**
 * Initial search config for devices table
 * It should have all the keys based on which filters can be applied to the table
 */
export const DevicesInitSearchConfig = {
  tag: null,
  name: null,
  operatingSystem: null,
  agentVersion: null,
  search: null,
};

/**
 * The key(among the keys of the options/suggestions record) that should be used for matching the suggestions and modifying the input string when the the user chooses one of the suggestions
 */
export const KeyForDevicesTableAutoSuggest = "field";

/**
 * Search fields for events
 */
export enum EventsSearchParams {
  deviceName = "deviceName",
  eventName = "eventName",
  subject = "subject",
  actionType = "actionType",
}

/**
 * Initial search config for devices table
 * It should have all the keys based on which filters can be applied to the table
 */
export const EventsInitSearchConfig = {
  deviceName: null,
  eventName: null,
  subject: null,
  actionType: null,
  search: null,
};

/**
 * The options for the auto-suggest component on events table
 */
export const EventsTableAutoSuggestOptions = [
  {
    label: "Device Name",
    field: "deviceName",
    helperText: "Filter based on device's name",
  },
  {
    label: "Event Name",
    field: "eventName",
    helperText: "Filter based on event's name",
  },
  {
    label: "Subject",
    field: "subject",
    helperText: "Filter based on subject",
  },
  {
    label: "Action Name",
    field: "actionType",
    helperText: "Filter based on event action(Allow or Block)",
  },
];

/**
 * The key(among the keys of the options/suggestions record) that should be used for matching the suggestions and modifying the input string when the user chooses one of the suggestions
 */
export const KeyForEventsTableAutoSuggest = "field";

/**
 * Suggestions for the value of event's "actionType" field
 */
export const SuggestionsForActionType = [
  {
    label: "Allow",
    field: "Allow",
  },
  {
    label: "Block",
    field: "Block",
  },
];

/**
 * The values allowed for "actionType" filter in the events table
 */
export const AllowedActionTypeValues = ["Allow", "Block"];

/**
 * Error messages
 */
export const tableSearchErrorMessages = {
  invalidSearchString: "Invalid search string",
  invalidTagSearched: "Searched Invalid Tag",
  invalidAgentVersionSearched: "Agent version should not have white spaces",
};
