import { Typography } from "@material-ui/core";

// Types
import { FilterAutoSuggestOptionProps } from "./FilterAutoSuggestOption";

// Styles
import useStyles from "./FilterAutoSuggestOption.styles";

/**
 * The component to render the options for FilterAutoSuggest component
 *
 * @example - Correct usage without helper text
 * ```ts
 * <FilterAutoSuggestOption id="id" label={label} />
 * ```
 *
 * @example - Correct usage with helper text
 * ```ts
 * <FilterAutoSuggestOption
 *   id="id
 *   label={label}
 *   helperText={helperText}
 * />
 * ```
 */
export const FilterAutoSuggestOption = ({
  id,
  label,
  helperText = "",
}: FilterAutoSuggestOptionProps) => {
  const classes = useStyles();

  return (
    <Typography
      id={id.toLowerCase().replace(/\s/g, "-")}
      className={classes.optionRow}
    >
      <span className={`margin-right-10 ${classes.labelChip}`}>{label}</span>
      {helperText && <span className={classes.helperText}>{helperText}</span>}
    </Typography>
  );
};
