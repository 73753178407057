import Constants from "@Constants";

const initialState: DeviceTagCount = {
  loading: false,
  tagCount: {},
  error: "",
};

const tagCountReducer = (
  state = initialState,
  action: GenericAction
): DeviceTagCount => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_DEVICE_TAG_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_DEVICE_TAG_COUNT_SUCCESS:
      /**
       * Mapping the array of objects [ Record<'group',number> ] => object { [groupName] : count  }
       */
      const tagCountMap = Object.fromEntries(
        (action?.payload as TagCount[]).map((obj) => [obj.group, obj.count])
      );
      return {
        loading: false,
        tagCount: tagCountMap,
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICE_TAG_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
  }

  return state;
};

export default tagCountReducer;
