import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";

// Actions
import {
  fetchTenantUserByUserId,
  fetchTenantUserRole,
  updateTenantUserRole,
} from "@Store/actions";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";

// Components
import Filter from "@Components/Filter";

// Constants
import { UserRoleSelectComponentConfig } from "@Constants/common";
import { FilterIds } from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Styles
import useStyles from "./styles";
import { UserDetailsIds } from "@Constants/Id";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();
  const classes = useStyles();
  const { userId } = useParams<{ userId: string }>();
  const { role, loading: isFetchingRole } = useSelector(
    (state: GlobalState) => state?.tenantUserRole
  );
  const { loading: isUpdatingRole, isUpdated: isRoleUpdated } = useSelector(
    (state: GlobalState) => state?.updateTenantUserRole
  );
  const { user } = useSelector((state: GlobalState) => state?.tenantUser);
  const [userRole, setUserRole] = useState("");

  /**
   * Handles making an API call to fetch the details of the user
   */
  const handleFetchUser = () =>
    dispatch(
      fetchTenantUserByUserId({
        userId,
        tenantId: selectedTenant.selectedTenantId,
      })
    );

  /**
   * Handles making an API call to fetch the role of the user
   */
  const fetchUserRole = () =>
    dispatch(fetchTenantUserRole(userId, selectedTenant.selectedTenantId));

  /**
   * Handles making an API call to update the role of the user
   * @param newRole The new role of the user
   */
  const updateUserRole = (newRole: string) =>
    dispatch(
      updateTenantUserRole(userId, selectedTenant.selectedTenantId, newRole)
    );

  /**
   * Fetch the user details and the role of the user when the page gets mounted
   */
  useEffect(() => {
    handleFetchUser();
    fetchUserRole();
  }, []);

  /**
   * Fetch the role of the user when the page is mounted or the role is updated
   */
  useEffect(() => {
    if (isRoleUpdated) {
      fetchUserRole();
    }
  }, [isRoleUpdated]);

  /**
   * When the "role" is fetched, update the local value to show it in the UI
   */
  useEffect(() => {
    setUserRole(role);
  }, [role]);

  /**
   * Extract the details of the user
   * @returns The array containing the user details along with the title for each row
   */
  const getUserDetails = () => [
    {
      title: "First Name",
      value: user.firstName,
      id: UserDetailsIds.firstName,
    },
    {
      title: "Last Name",
      value: user.lastName,
      id: UserDetailsIds.lastName,
    },
    {
      title: "Email",
      value: user.email,
      id: UserDetailsIds.email,
    },
  ];

  /**
   * Handles updating the role of the user when the user changes it from the dropdown
   * @param value The new value of the role
   */
  const onUserRoleChange = (value: string) => {
    setUserRole(value as unknown as AccessLevelTypes);
    updateUserRole(value);
  };

  return (
    <>
      <Typography variant="subtitle2" className="margin-bottom-30">
        User Details
      </Typography>
      {getUserDetails().map(({ title, value, id }) => (
        <div className="d-flex margin-bottom-25">
          <Typography
            id={id}
            variant="body2"
            className={`font-weight-bold ${classes.title} text-uppercase`}
          >
            {title}
          </Typography>
          {value ? (
            <Typography id={`${id}-value`} variant="body2">
              {value}
            </Typography>
          ) : (
            <SkeletonLoader
              width={100}
              height={30}
              className={classes.skeletonLoader}
            />
          )}
        </div>
      ))}
      <div className="d-flex margin-bottom-25">
        <Typography
          id={UserDetailsIds.userRole}
          variant="body2"
          className={`font-weight-bold ${classes.title} text-uppercase`}
        >
          Role
        </Typography>
        {isFetchingRole || isUpdatingRole ? (
          <SkeletonLoader
            width={100}
            height={30}
            className={classes.skeletonLoader}
          />
        ) : (
          <Filter
            id={FilterIds.userRoles}
            filterConfig={UserRoleSelectComponentConfig}
            onChangeHandler={onUserRoleChange}
            defaultFilterValue=""
            selectedFilterValue={userRole}
          />
        )}
      </div>
    </>
  );
};

export default UserDetails;
