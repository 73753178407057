import React from "react";

import ErrorBoundary from "@Containers/errorBoundary";
import EventsContainer from "@Containers/events";

const Events = (): JSX.Element => (
  <ErrorBoundary>
    <EventsContainer />
  </ErrorBoundary>
);

export default Events;
