import ActionTypes from "@Constants/actionTypes";

const initialState: UserRolesState = {
  loading: false,
  error: "",
  data: [],
};

const userRolesReducer = (
  state: UserRolesState = initialState,
  action: GenericAction
): UserRolesState => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.FETCH_USER_ROLES_SUCCESS:
      return {
        loading: false,
        error: "",
        data: action.payload,
      };
    case ActionTypes.FETCH_USER_ROLES_FAILED:
      return {
        loading: false,
        error: action.payload,
        data: [],
      };
  }

  return state;
};

export default userRolesReducer;
