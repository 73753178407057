import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  labelFont: {
    "& td": {
      verticalAlign: "top",
    },
  },
}));

export default useStyles;
