import lightPalette from "./lightTheme";
import { themeColors } from "./themeColors";
import buttonsTheme from "./buttonsTheme";

import { pxToRem } from "./pxToRem";
import BreakpointValues from "./BreakpointValues";

// Common typography styles for headers
const headerCommonStyles = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 600,
  lineHeight: "120%",
};

// Common typography styles for subtitles
const subTitleCommonStyles = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 500,
  lineHeight: "140%",
};

// common typography styles for bodyText
const bodyTextCommonStyles = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 400,
  lineHeight: "150%",
};

// MUI theme configuration
const theme = {
  palette: {
    primary: {
      main: "#5EACD8",
    },
    secondary: {
      main: "#3F4C76",
    },
    background: {
      default: "#F4F5F7",
    },
  },
  colors: {
    //TODO: remove unused from old ones
    white: "#ffffff",
    black: "#000000",
    accentred: "#F25C54",
    accentRedDark: "#d24a43",
    accentpeach: "#F4845F",
    accentorange: "#F7B267",
    textPrimary: "#253858",
    textGrey: "#6B6A76",
    textLightGrey: "rgba(0,0,0,0.5)",
    bgGrey: "#F4F5F7",
    grey: "#6B6A76",
    blueViolet: "#2601CB",
    outlineButtonHover: "rgba(86, 100, 210, 0.04)",
    outlineButtonBorder: "rgba(86, 100, 210, 0.5)",
    lightGrey: "#e0e0e0",
    disabledGrey: "rgba(0, 0, 0, 0.12)",
    divider: "#6B6A76",
    datePicker: "#3F4C76",
    textSecondary: "#3D4154",
    secondaryBlack: "#0E121E",
    tertiaryLightGrey: "#d7d9e2",

    // TODO: below color codes are the part of new theme, remove unused from above
    iconGray: "rgb(107, 114, 128)",
    pageBg: "#F0F1F6",
    green: "#7CB880",
    pink: "#FF867E",
    lightGreen: "#4EDE7F",
    lightBlue: "#5C6A82",
  },
  newColors: {
    ...themeColors,
    // color change don't happen for following colors due to change in theme
    // they are constant in both the themes
    red: "#F44544",
    purpleNavy: "#555B77",
    skyBlue: "#5EACD8",
    fieryOrange: "#FF8139",
    warmYellow: "#FFAC2F",
    darkYellow: "#E9A135",
  },
  newPalette: lightPalette,
  lightTheme: {
    primary: {
      poppyRed: "#F44544",
      fieryOrange: "#FF8139",
      warmYellow: "#FFAC2F",
      warmYellowLight: "#FFC36A",
      text: "#253858",
      green: "#7CB880",
    },
    secondary: {
      sky: "#AFE7F9",
      almostWhite: "#FFFDFC",
      almostBlack: "#0E121E",
      mediumDark: "#707798",
      charcoal: "#3D4154",
      lightGrey: "#E7E9F3",
      clearWater: "#5EACD8",
      deepBlue: "#3F4C76",
      mediumLight: "#C3C8DF",
    },
  },
  breakpoints: {
    values: BreakpointValues,
    unit: "rem",
    step: 0,
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  utils: {
    boldText: () => {
      return {
        fontWeight: theme.fontWeights.bold,
      };
    },
    semiBoldText: () => {
      return {
        fontWeight: theme.fontWeights.semiBold,
      };
    },
    mediumText: () => {
      return {
        fontWeight: theme.fontWeights.medium,
      };
    },
    regularText: () => {
      return {
        fontWeight: theme.fontWeights.regular,
      };
    },
    buttonLarge: () => ({
      fontFamily: theme.typography.fontFamily,
      lineHeight: "120%",
      fontSize: pxToRem(16),
      fontWeight: theme.fontWeights.semiBold,

      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(15),
      },

      [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
        fontSize: pxToRem(14),
      },
    }),
    buttonMedium: () => ({
      ...theme.utils.buttonLarge(),
      fontSize: pxToRem(14),
      fontWeight: theme.fontWeights.medium,

      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(13),
      },

      [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
        fontSize: pxToRem(12),
      },
    }),
    buttonSmall: () => ({
      ...theme.utils.buttonMedium(),
      fontSize: pxToRem(12),

      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(11),
      },

      [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
        fontSize: pxToRem(10),
      },
    }),
    subTitle1: () => theme.typography.subtitle1,
    subTitle2: () => theme.typography.subtitle2,
    bodyText1: () => theme.typography.body1,
    bodyText2: () => theme.typography.body2,
    bodyText3: () => theme.typography.body3,
    caption1: () => ({
      fontSize: theme.pxToRem(14),
      lineHeight: "120%",
    }),
    caption2: () => ({
      fontSize: theme.pxToRem(13),
      lineHeight: "120%",
    }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      ...headerCommonStyles,
      fontSize: pxToRem(48),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(34),
      },
    },
    h2: {
      ...headerCommonStyles,
      fontSize: pxToRem(42),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(32),
      },
    },
    h3: {
      ...headerCommonStyles,
      fontSize: pxToRem(32),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(24),
      },

      // for mobile viewport
      [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
        fontSize: pxToRem(20),
      },
    },
    h4: {
      ...headerCommonStyles,
      fontSize: pxToRem(28),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(22),
      },

      // for mobile viewport
      [`@media (max-width: ${BreakpointValues.sm}rem)`]: {
        fontSize: pxToRem(18),
      },
    },
    h5: {
      ...headerCommonStyles,
      fontSize: pxToRem(24),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(20),
      },
    },
    subtitle1: {
      ...subTitleCommonStyles,
      fontSize: pxToRem(20),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(18),
      },
    },
    subtitle2: {
      ...subTitleCommonStyles,
      fontSize: pxToRem(18),

      // for tablet viewport
      "@media (max-width: 64rem)": {
        fontSize: pxToRem(16),
      },

      // for mobile viewport
      "@media (max-width: 40.75rem)": {
        fontSize: pxToRem(13),
      },
    },
    body1: {
      ...bodyTextCommonStyles,
      fontSize: pxToRem(14),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(12),
      },
    },
    body2: {
      ...bodyTextCommonStyles,
      fontSize: pxToRem(13),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(11),
      },
    },
    body3: {
      ...bodyTextCommonStyles,
      fontSize: pxToRem(12),
      lineHeight: "130%",
      letterSpacing: pxToRem(0.5),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(10),
      },
    },
    p: {
      ...bodyTextCommonStyles,
      fontSize: pxToRem(14),

      // for tablet viewport
      [`@media (max-width: ${BreakpointValues.md}rem)`]: {
        fontSize: pxToRem(12),
      },
    },
  },
  pxToRem,
  overrides: {
    MuiButton: { ...buttonsTheme },
    MuiPaper: {
      elevation1: {
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)",
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: "row",
        "& > *:first-child": {
          margin: `0 ${pxToRem(10)} 0 0!important`,
        },
      },
    },
  },
} as const;

type MUITheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

// In addition to default MUI theme properties, add the custom ones
declare module "@material-ui/core/styles" {
  interface Theme extends MUITheme {
    colors: {
      [index: string]: string;
    };
  }
  interface ThemeOptions extends MUITheme {
    colors: {
      [index: string]: string;
    };
  }
}

export default theme;
