import { CheckCircle } from "@material-ui/icons";

// Components
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import Constants from "@Constants";

// Styles
import {
  useLevelledProgressBarStyles,
  useChecklistStyles,
} from "./LevelledProgressBar.styles";

// Types
import {
  ChecklistProps,
  LevelledProgressBarProps,
} from "./LevelledProgressBar";

/**
 * Checklist component - to render the checklists
 * @example Correct usage
 * ```ts
 * <Checklist checklist={checklistItems} />
 * ```
 */
const Checklist = ({ checklist, id }: ChecklistProps): JSX.Element => {
  const classes = useChecklistStyles();
  return (
    <>
      <ul className={`${classes.checklistContainer} `} id={id}>
        {checklist.map(({ completed, text }, index) => (
          <li
            key={index}
            className={`${classes.checklistItem} ${
              completed && classes.completedChecklist
            }`}
          >
            <span className={`d-block ${classes.checkIcon}`}>
              <CheckCircle className={`${completed ? "d-block" : "d-none"} `} />
            </span>
            <span className={`d-block ${classes.checklistItemText}`}>
              {text}
            </span>
          </li>
        ))}
      </ul>
      <span className={classes.triangle}>
        <SVGWrapper width={20} height={16} viewBox="0 0 20 16">
          {Constants.SVGIcons.triangle}
        </SVGWrapper>
      </span>
    </>
  );
};

/**
 * LevelledProgressBar component - used for rendering the password level with checklists
 *
 * @example Correct usage
 * ```ts
 * <LevelledProgressBar
 *  totalLevels={4}
 *  completedLevels={2}
 *  checklist={checklistItems}
 *  label="Strong"
 * />
 * ```
 */
export const LevelledProgressBar = ({
  id,
  totalLevels,
  completedLevels,
  checklist,
  showChecklist,
  label,
}: LevelledProgressBarProps): JSX.Element => {
  const classes = useLevelledProgressBarStyles({ totalLevels });

  /**
   * Returns the class to be applied to the bars depending on the completed levels
   */
  const getColorClass = (): string => {
    switch (completedLevels) {
      case 1:
        return classes.tooWeak;
      case 2:
        return classes.weak;
      case 3:
        return classes.medium;
      case 4:
        return classes.strong;
    }
    return "";
  };

  return (
    <>
      <div
        className={`flex position-relative ${classes.progressBarContainer}`}
        id={id}
      >
        {[...Array(completedLevels).keys()].map((item, index) => (
          <div
            key={item + index}
            className={`${classes.levelBar} ${getColorClass()}`}
          ></div>
        ))}
        {[...Array(totalLevels - completedLevels).keys()].map((item, index) => (
          <div key={item + index} className={`${classes.levelBar}`}></div>
        ))}
        {showChecklist && (
          <Checklist checklist={checklist} id={`${id}-checklist`} />
        )}
      </div>
      {label && (
        <span className={classes.label} id={`${id}-label`}>
          {label}
        </span>
      )}
    </>
  );
};
