import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Layouts
import Home from "@Layouts/home";

// Pages
import Events from "@Pages/events";
import Devices from "@Pages/devices";
import Dashboard from "@Pages/dashboard";
import ProfilePage from "@Pages/profilePage";
import SecurityPage from "@Pages/securityPage";
import Tenants from "@Pages/tenants";
import Users from "@Pages/users";
import NotFound from "@Pages/pageNotFound";
import DeviceDetails from "@Pages/deviceDetails";
import EventDetails from "@Pages/eventDetails";
import ThreatDetails from "@Pages/threatDetails";
import Settings from "@Pages/settings";
import UserDetails from "@Pages/userDetails";

// Constants
import Constants from "@Constants";
import { LeftMenuTabs } from "@Constants/common";

// Route with layout
import WithLayoutRoute from "./withLayoutRoute";

// Routes: All public and protected routes are defined here
const Routes: React.FC = () => (
  <Switch>
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Tenants}
      Component={Tenants}
      Layout={Home}
      loginRequired
      tabId={LeftMenuTabs.Tenants}
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Dashboard}
      Component={Dashboard}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Users}
      Component={Users}
      Layout={Home}
      loginRequired
      tabId={LeftMenuTabs.Users}
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.UserDetails}
      Component={UserDetails}
      Layout={Home}
      loginRequired
      // Keeping "tabId" same as the "Users" tab because the users can view this page only if the "Users" page is there
      tabId={LeftMenuTabs.Users}
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Events}
      Component={Events}
      Layout={Home}
      loginRequired
      tabId={LeftMenuTabs.Events}
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Devices}
      Component={Devices}
      Layout={Home}
      loginRequired
      tabId={LeftMenuTabs.Devices}
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.EventDetails}
      Component={EventDetails}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.DeviceDetails}
      Component={DeviceDetails}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.ThreatDetails}
      Component={ThreatDetails}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Profile}
      Component={ProfilePage}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Security}
      Component={SecurityPage}
      Layout={Home}
      loginRequired
    />
    <WithLayoutRoute
      exact
      path={Constants.AppRoutes.ProtectedRoutes.Settings}
      Component={Settings}
      Layout={Home}
      loginRequired
      tabId={LeftMenuTabs.Settings}
    />
    <Route
      exact
      path={Constants.AppRoutes.PublicRoutes.NOT_FOUND}
      component={NotFound}
    />
    {/*
     * Default redirection should be Dashboard, if a user is unauthenticated it will redirect to login. Furthermore
     * if someone hits the old /login route it will send them to instead.
     * */}
    <Redirect
      exact
      path={Constants.AppRoutes.PublicRoutes.LOGIN}
      to={{
        pathname: Constants.AppRoutes.ProtectedRoutes.Dashboard,
        search: location.search,
      }}
    />
    <Redirect
      exact
      from="/"
      to={Constants.AppRoutes.ProtectedRoutes.Dashboard}
    />
    {/* Wild routes should redirect to 404 page */}
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
