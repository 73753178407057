import { useSelector } from "react-redux";

// Components
import LabelValuePairTable from "@Components/Table/LabelValuePairTable";

// Constants
import Constants from "@Constants";
import { DeviceDetailsTabs, PageSizeConfigs } from "@Constants/common";
import {
  DeviceInfoDetailsType,
  OSDetailsInfoType,
} from "@Constants/tableColumns";

// Types
import { LabelValuePairTableProps } from "@Components/Table/LabelValuePairTable/LabelValuePairTable";

// Utils
import Utils from "@Utils";

// Styles
import useStyles from "./styles";
import { Grid } from "@material-ui/core";
import { TableIds } from "@Constants/Id";

// Props type
type DeviceInfoDetailsProps = {
  panelType: DeviceDetailsTabs;
};

const DeviceInfoDetails = ({
  panelType,
}: DeviceInfoDetailsProps): JSX.Element => {
  // Get device info details state from the redux store
  const { infoDetails, loading }: DeviceInfoDetailsState = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );

  const classes = useStyles();

  // Format data for different tables from the API response
  const getLabelValuePairsFor = (
    detailsType: DeviceInfoDetailsType
  ): LabelValueTypeForTable[] => {
    if (!Utils.isObjectEmpty(infoDetails)) {
      switch (detailsType) {
        // Format data for Agent details
        case DeviceInfoDetailsType.agentInfo:
          const agentInfo = infoDetails[
            DeviceInfoDetailsType.agentInfo
          ] as Record<string, string>;
          return Utils.getLabelValuePairsForTable(
            agentInfo,
            Constants.TableColumnsConfig.AgentLabelConfigByFieldName
          );

        // Format data for Endpoint protection details
        case DeviceInfoDetailsType.endPointProtectionInfo:
          const endPointDetails = infoDetails[
            DeviceInfoDetailsType.endPointProtectionInfo
          ] as unknown as Record<string, string>[];

          let eppLabelValuePairs: LabelValueTypeForTable[] = [];

          endPointDetails.forEach((endPointInfo) => {
            const data = Utils.getLabelValuePairsForTable(
              endPointInfo,
              Constants.TableColumnsConfig.EppLabelConfigByFieldName
            );

            eppLabelValuePairs = [...eppLabelValuePairs, ...data];
          });

          return eppLabelValuePairs;

        // Format data for System details
        case DeviceInfoDetailsType.systemInfo:
          return Utils.getLabelValuePairsForTable(
            infoDetails[DeviceInfoDetailsType.systemInfo] as Record<
              string,
              string
            >,
            Constants.TableColumnsConfig.SystemsLabelConfigByFieldName
          );

        // Format data for OS details
        case DeviceInfoDetailsType.osDetailsInfo:
          const osInstallInfo = infoDetails[
            OSDetailsInfoType.osInstallInfo
          ] as unknown as Record<string, string>;

          const osInfo = infoDetails[
            OSDetailsInfoType.osInfo
          ] as unknown as Record<string, string>;

          return Utils.getLabelValuePairsForTable(
            { ...osInfo, ...osInstallInfo },
            Constants.TableColumnsConfig.OsLabelConfigByFieldName
          );
      }
    }
    return [];
  };

  const tableCommonProperties: Omit<
    LabelValuePairTableProps,
    "labelValuePairs" | "id"
  > = {
    labelWidth: 150,
    isLoading: loading,
    extraClass: `${classes.labelFont}`,
    boldLabel: true,
  };

  // const labelValuePairConfigForEpp: LabelValueTypeForTable[] =
  //   getLabelValuePairsFor(DeviceInfoDetailsType.endPointProtectionInfo);
  const labelValuePairConfigForSystem = getLabelValuePairsFor(
    DeviceInfoDetailsType.systemInfo
  );
  const labelValuePairConfigForOs = getLabelValuePairsFor(
    DeviceInfoDetailsType.osDetailsInfo
  );

  /**
   * Split a horizontal table into two parts if it is long
   * @param labelValueForTable data to display in the table
   * @returns PaginatedTable component in Grid
   */
  const splitTable = (
    labelValueForTable: LabelValueTypeForTable[],
    id: string
  ): JSX.Element => {
    return labelValueForTable.length <=
      PageSizeConfigs.maxNoOfHorizontalTableRows ? (
      <LabelValuePairTable
        id={id}
        labelValuePairs={labelValueForTable}
        {...tableCommonProperties}
      />
    ) : (
      <Grid container>
        <Grid item sm={12} md={6}>
          <LabelValuePairTable
            id={`${id}-part-1`}
            labelValuePairs={labelValueForTable.slice(
              0,
              Math.ceil(labelValueForTable.length / 2)
            )}
            {...tableCommonProperties}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <LabelValuePairTable
            id={`${id}-part-2`}
            labelValuePairs={labelValueForTable.slice(
              Math.ceil(labelValueForTable.length / 2),
              labelValueForTable.length
            )}
            {...tableCommonProperties}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {/* {panelType === Constants.DeviceDetailsTabs.epp &&
        splitTable(labelValuePairConfigForEpp)} */}
      {panelType === Constants.DeviceDetailsTabs.system &&
        splitTable(labelValuePairConfigForSystem, TableIds.system)}
      {panelType === Constants.DeviceDetailsTabs.os &&
        splitTable(labelValuePairConfigForOs, TableIds.operatingSystem)}
    </>
  );
};

export default DeviceInfoDetails;
