import { Color } from "@material-ui/lab/Alert";

// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Handle display of alert toast
export const showAlert = (payload: {
  message: string;
  severity: Color;
}): GenericAction => ({
  type: ActionTypes.SHOW_ALERT,
  payload,
});

// Handle closing of alert toast
export const closeAlert = (): GenericAction => ({
  type: ActionTypes.CLOSE_ALERT,
});
