import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    gap: theme.pxToRem(20),
    borderRadius: theme.pxToRem(10),
    padding: `${theme.pxToRem(30)} ${theme.pxToRem(20)}`,
    backgroundColor: theme.newPalette.downloadAgent.background,

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginTop: theme.pxToRem(20),
    },
  },
  iconContainer: {
    borderRadius: "50%",
    backgroundColor: theme.newColors.secondary.deepBlue[900],
    padding: theme.pxToRem(30),

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: theme.pxToRem(24),
    },
  },
  icon: {
    height: theme.pxToRem(37),
  },
  text: {
    color: theme.newPalette.text.primary.main,
  },
  details: {
    color: theme.newPalette.text.secondary.main,
    marginTop: theme.pxToRem(4),
    ...theme.utils.bodyText3(),
  },
  button: {
    whiteSpace: "nowrap",
    backgroundColor: theme.newPalette.downloadAgent.actionBackground,
    color: theme.newColors.primary.fieryOrange[500],
    fill: theme.newColors.primary.fieryOrange[500],

    "&:hover": {
      color: theme.newColors.neutral[100],
      backgroundColor: theme.newColors.primary.fieryOrange[500],
      fill: theme.newColors.neutral[100],
    },
  },
}));

export default useStyles;
