import { TextField } from "@material-ui/core";

// Types
import { InputFieldProps } from "./InputField";

// Styles
import useStyles from "./InputField.styles";

/**
 * InputField Component
 * @example Correct usage
 * ```ts
 * <InputField
 *  id="id"
 *  label="Label"
 *  value={value}
 *  type="text"
 *  onChange={handleOnChange}
 * />
 * ```
 *
 * @example Correct usage with password type
 * ```ts
 * <InputField
 *  id="id"
 *  label="Label"
 *  value={value}
 *  type="password"
 *  onChange={handleOnChange}
 * />
 *
 * @example Correct usage with error message
 * ```ts
 * <InputField
 *  id="id"
 *  label="Label"
 *  value={value}
 *  type="password"
 *  errorMessage="This is error message"
 *  onChange={handleOnChange}
 * />
 */
export const InputField = ({
  id,
  type,
  label,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  defaultValue,
  onKeyUp,
  inputProps,
  inputRef,
  InputProps,
  placeholder = "",
  extraClass = "",
  errorMessage = false,
  readOnly = false,
  fullWidth = false,
  hiddenLabel = false,
  autoComplete = "off",
  size = "medium",
  variant = "outlined",
  required = false,
  multiLine = false,
  rows = 1,
}: InputFieldProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TextField
      type={type}
      id={id}
      name={name}
      label={label && `${label}${required ? "*" : ""}`}
      variant={variant}
      color="secondary"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={errorMessage ? true : false}
      helperText={errorMessage}
      className={`${classes.wrapper} ${extraClass} ${
        errorMessage && classes.errorState
      }`}
      disabled={readOnly}
      defaultValue={defaultValue}
      onKeyUp={onKeyUp}
      inputProps={inputProps}
      inputRef={inputRef}
      fullWidth={fullWidth}
      InputProps={InputProps}
      size={size}
      hiddenLabel={hiddenLabel}
      autoComplete={autoComplete}
      multiline={multiLine}
      minRows={rows}
      placeholder={placeholder}
    />
  );
};
