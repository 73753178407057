import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Icons
import Add from "@material-ui/icons/Add";

// Actions
import { fetchExportConfig, deleteExportConfig } from "@Store/actions";

// Utils
import Utils from "@Utils";

// Hooks
import usePagination from "@Hooks/usePagination";
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useTenantAccess from "@Hooks/useTenantAccess";

// Constants
import Constants from "@Constants";
import { SettingsPageTabs, EmptyStateMessages } from "@Constants/common";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import { ButtonIDs, EmptyStateIds, TableIds } from "@Constants/Id";

// Components
import ConfigActions from "@Components/ConfigActions";
import EmptyState from "@Components/EmptyState";
import PaginatedTable from "@Components/Table/PaginatedTable";
import AddOrUpdateConfig from "./subComponents/addOrUpdateConfig";
import Button from "@Components/Button";

// Styles
import useStyles from "./styles";

const EventFeed = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { checkAccessAndRender, canAccess } = usePermissionFilter();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [configToBeUpdated, setConfigToBeUpdated] = useState<{
    exportUrl: string;
    token?: string;
    authHeaderKey?: string;
    authHeaderValue?: string;
    enabled: boolean;
    configId: string;
  } | null>(null);

  const { selectedTenant } = useTenantAccess();

  const exportConfig = useSelector((state: GlobalState) => state.exportConfigs);

  const { currentPage, noOfRowsPerPage, onPageChange, onRowSizeChange } =
    usePagination({
      searchString: `tab=${SettingsPageTabs.EventFeed}`,
    });

  const getExportConfigs = () => {
    dispatch(
      fetchExportConfig({
        page: currentPage,
        pageSize: noOfRowsPerPage,
      })
    );
  };

  const onEditActionClick = (data: RowData) => {
    setConfigToBeUpdated({
      exportUrl: data.exportUrl.value,
      token: data.token?.value,
      authHeaderKey: data.authHeaderKey?.value,
      authHeaderValue: data.authHeaderValue?.value,
      enabled: Boolean(data.enabled.value),
      configId: data.id.value,
    });
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setConfigToBeUpdated(null);
  };

  const enableToggleConfig: TableIconColumnConfig = {
    iconElement: (data: RowData) => (
      <ConfigActions
        rowData={data}
        targetName="Config"
        deleteConfig={deleteExportConfig}
        editFunction={onEditActionClick}
        onDeleteSuccess={getExportConfigs}
        editBtnLabel="Edit export config"
        deleteBtnLabel="Delete export config"
      />
    ),
  };

  useEffect(() => {
    getExportConfigs();
  }, [selectedTenant.selectedTenantId]);

  return (
    <>
      {checkAccessAndRender({
        child: (
          <div
            className={`d-flex justify-end ${classes.topActionBtnContainer}`}
          >
            <Button
              id={ButtonIDs.createFeedButton}
              onClick={() => setEditModalOpen(true)}
              startIcon={<Add />}
            >
              Create Feed
            </Button>
          </div>
        ),
        featureAccessLevel: FeatureToAccessTypeMap.eventFeed.create,
      })}
      {!exportConfig.loading &&
        (exportConfig.data.length ? (
          <PaginatedTable
            id={TableIds.eventFeed}
            headers={Constants.TableColumnsConfig.EventFeed}
            rowsData={Utils.parseToRowDataType(
              Constants.TableColumnsConfig.EventFeed,
              exportConfig.data
            )}
            showPagination={!exportConfig.loading && !exportConfig.error}
            pagination={{
              noOfRowsPerPage: noOfRowsPerPage,
              totalRowsAvailable: exportConfig.pagination?.totalRowsAvailable,
              currentPageNumber: currentPage,
              jumpToPage: (pageNumber: number) => {
                onPageChange(pageNumber);
              },
              onClickSetPageSize: (pageSize: number) => {
                onRowSizeChange(pageSize);
              },
              lastPageNumber: exportConfig.pagination?.lastPageNumber,
            }}
            removeTableShadow
            iconColumn={
              canAccess(FeatureToAccessTypeMap.eventFeed.update)
                ? enableToggleConfig
                : null
            }
          />
        ) : (
          <EmptyState
            id={EmptyStateIds.eventConfig}
            messageTitle={EmptyStateMessages.exportConfig}
            messageSubTitle={
              currentPage <= 1 ? EmptyStateMessages.exportConfigPrompt : ""
            }
            svgImage={Constants.SVGIcons.noConfigFound}
            svgHeight={115}
            svgWidth={145}
            actionButton={
              currentPage <= 1 &&
              canAccess(FeatureToAccessTypeMap.eventFeed.create)
                ? {
                    text: "Create first config",
                    onClick: () => setEditModalOpen(true),
                  }
                : null
            }
          />
        ))}
      {checkAccessAndRender({
        child: (
          <AddOrUpdateConfig
            isModalOpen={editModalOpen}
            closeModal={handleCloseModal}
            configToBeEdited={configToBeUpdated}
            onSuccess={getExportConfigs}
          />
        ),
        featureAccessLevel: FeatureToAccessTypeMap.eventFeed.create,
      })}
    </>
  );
};

export default EventFeed;
