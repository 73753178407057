import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@material-ui/core";

// Components
import Button from "@Components/Button";

// Constants
import Constants from "@Constants";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import { ButtonIDs, MultiVersionDownloadModalIds } from "@Constants/Id";

// Actions
import { generateNewAgentInstallerToken, showAlert } from "@Store/actions";

// Hooks
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useTenantAccess from "@Hooks/useTenantAccess";

// Styles
import useStyles from "./styles";

const InstallationToken = (): JSX.Element => {
  const classes = useStyles();

  // Get agent installer details state from redux store
  const agentInstallerDetails: AgentInstallerDetailsState = useSelector(
    (state: GlobalState) => state.agentInstallerDetails
  );
  const [isConfirmationViewOpen, setConfirmationViewOpen] = useState(false);

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  const { checkAccessAndRender } = usePermissionFilter();
  const { selectedTenant } = useTenantAccess();

  // Show success toast after copying token
  const handleOnCopyAlert = () => {
    dispatch(
      showAlert({
        message: "Copied",
        severity: Constants.AlertSeverity.success,
      })
    );
  };

  // open confirmation modal
  const getConfirmation = () => {
    setConfirmationViewOpen(true);
  };

  // Fetch a new token after the user confirms
  const handleConfirmAndCancel = (confirm: boolean) => {
    if (confirm) {
      dispatch(generateNewAgentInstallerToken());
    }
    setConfirmationViewOpen(false);
  };

  return (
    <>
      <div
        id={MultiVersionDownloadModalIds.tokenContainer}
        className={`${classes.tokenWrapper} flex`}
      >
        <span
          id={MultiVersionDownloadModalIds.token}
          className={`text-truncate ${classes.tokenText}`}
        >
          {agentInstallerDetails.loading
            ? ""
            : agentInstallerDetails.installToken}
        </span>
        <div
          id={MultiVersionDownloadModalIds.copyTokenWrapper}
          title="copy"
          className="margin-left-auto cursor-pointer"
        >
          <CopyToClipboard
            text={agentInstallerDetails.installToken}
            onCopy={handleOnCopyAlert}
          >
            <FileCopyRoundedIcon className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </div>
      {isConfirmationViewOpen ? (
        <div
          id={MultiVersionDownloadModalIds.generateTokenConfirmationWrapper}
          className={`flex ${classes.confirmationSection}`}
        >
          <div>
            <Typography variant="subtitle2" align="left">
              {Constants.AlertMessages.generateNewInstallationTokenTitle}
            </Typography>
            <Typography variant="body2" align="left">
              {Constants.AlertMessages.generateNewInstallationTokenDescription}
            </Typography>
          </div>
          <div className="flex-column justify-between">
            <Button
              id={ButtonIDs.generateNewTokenConfirmation}
              extraClass="w-100"
              size="small"
              onClick={() => handleConfirmAndCancel(true)}
            >
              Generate New Token
            </Button>
            <Button
              id={ButtonIDs.cancelGenerateNewTokenConfirmation}
              extraClass="w-100"
              variant="outlined"
              size="small"
              onClick={() => handleConfirmAndCancel(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        !selectedTenant.isNonEnterprise &&
        checkAccessAndRender({
          featureAccessLevel: FeatureToAccessTypeMap.installers.generateToken,
          child: (
            <Button
              id={ButtonIDs.generateNewToken}
              disabled={agentInstallerDetails.loading}
              extraClass={classes.generateTokenButton}
              disableElevation={true}
              variant="outlined"
              onClick={getConfirmation}
              startIcon={<RefreshRoundedIcon />}
            >
              Generate New Token
            </Button>
          ),
        })
      )}
    </>
  );
};

export default InstallationToken;
