/* eslint-disable */
const bcrypt = require("bcryptjs");
const saltRounds = 10;
const salt = bcrypt.genSaltSync(saltRounds);

/**
 * Generates hash of a string
 * @param string
 * @returns hashed string
 */
const generateHash = (value: string) => bcrypt.hashSync(value, salt);

const compareHash = (value: string, hash: string) =>
  bcrypt.compareSync(value, hash);

export default {
  generateHash,
  compareHash,
};
