import { Typography } from "@material-ui/core";

// Components
import Button from "@Components/Button";
import Avatar from "@Components/Avatar";
import SVGWrapper from "@Components/SVGWrapper";
import Tooltip from "@Components/Tooltip";
import ToggleButton from "@Components/ToggleButton";
import Link from "@Components/Link";

// Constants
import Constants from "@Constants";
import { AvatarIDs, ButtonIDs, HeaderIds, LinkIds } from "@Constants/Id";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";
import useAuth from "@Hooks/useAuth";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Styles
import useStyles from "./ProfilePopoverContent.styles";

// Utils
import Utils from "@Utils";

// Types
import { ProfilePopoverContentProps } from "./ProfilePopoverContent";

/**
 * ProfilePopoverContent component - used to show the popover when the user clicks on the avatar in the desktop mode and rendering the username, avatar and settings in the Sidebar in mobile view
 * @example Correct usage with the username, avatar and settings visible in the popover
 * ```ts
 * <ProfilePopoverContent id={uniqueId} showUserNameAndAvatar showSettings />
 * ```
 * @example Correct usage with only the username and avatar visible in the popover
 * ```ts
 * <ProfilePopoverContent id={uniqueId} showUserNameAndAvatar />
 * ```
 * @example Correct usage with only the settings visible in the popover
 * ```ts
 * <ProfilePopoverContent id={uniqueId} showSettings />
 * ```
 */
export const ProfilePopoverContent = ({
  showUserNameAndAvatar = false,
  showSettings = false,
}: ProfilePopoverContentProps): JSX.Element => {
  const { activeTheme, toggleTheme } = useAppTheme();
  const { userInfo, handleLogout } = useAuth();
  const { isIdpUser } = usePermissionFilter();

  const classes = useStyles();

  const Angle = (
    <span className={classes.angle}>
      <SVGWrapper width={20} height={20} viewBox="0 0 20 20">
        {Constants.SVGIcons.angleDown}
      </SVGWrapper>
    </span>
  );

  return (
    <div id={HeaderIds.profilePopover} className={classes.contentContainer}>
      {showUserNameAndAvatar && (
        <div className="flex flex-column align-center">
          <Avatar
            id={AvatarIDs.userAvatarProfilePopover}
            text={Utils.getFirstLetterOfWordsInString(userInfo.fullname)}
            extraClass={classes.userAvatar}
          />
          <Typography variant="h5" className="text-truncate">
            {`${userInfo.username} ${userInfo.fullname}`}
          </Typography>
        </div>
      )}
      {showSettings && (
        <ul className={classes.settings}>
          <li>
            <Link
              id={LinkIds.myProfileLink}
              link={Constants.AppRoutes.ProtectedRoutes.Profile}
              extraClass={`${classes.link} flex justify-between`}
            >
              <span className={`${classes.linkText} link-inner-text`}>
                My Profile
              </span>
              {Angle}
            </Link>
          </li>
          {!isIdpUser && (
            <li>
              <Link
                id={LinkIds.passwordAndSecurity}
                link={Constants.AppRoutes.ProtectedRoutes.Security}
                extraClass={`${classes.link} flex justify-between`}
              >
                <span className={`${classes.linkText} link-inner-text`}>
                  Password and Security
                </span>
                {Angle}
              </Link>
            </li>
          )}
          <li className={`${classes.padding} flex justify-between`}>
            <span className={classes.linkText}>Dark Mode</span>
            <Tooltip
              title={`Switch to ${
                activeTheme === Constants.Themes.dark
                  ? Constants.Themes.light
                  : Constants.Themes.dark
              } mode`}
              showArrow
              placement="bottom-start"
              tooltipChildren={
                <ToggleButton
                  id="themeSwitchToggleButton"
                  name="themeSwitchToggleButton"
                  onChange={toggleTheme}
                  onKeyDown={(e) => e.key === "Enter" && toggleTheme()}
                  checked={activeTheme === Constants.Themes.dark}
                />
              }
            />
          </li>
          <li className={classes.padding}>
            <Button
              id={ButtonIDs.profilePopoverContent}
              variant="text"
              extraClass={`${classes.linkText} ${classes.logoutButton}`}
              onClick={handleLogout}
            >
              Log out
            </Button>
          </li>
        </ul>
      )}
    </div>
  );
};
