import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  useAuth as useOIDCAuth,
  AuthContextProps as OIDCAuthContextProps,
} from "oidc-react";

// Hooks
import useAuth, { AuthHook as BasicAuthHook } from "@Hooks/useAuth";
import useTenantAccess from "@Hooks/useTenantAccess";

// Cookies
import Cookies from "@Helpers/cookies";

// Constants
import Constants from "@Constants";

// Add _retry property in axios request config
declare module "axios" {
  export interface AxiosRequestConfig {
    _retry: boolean;
    sendTenantHeader?: boolean;
  }
}

let auth: OIDCAuthContextProps | BasicAuthHook;
let tenantId: string;

// Initialize auth context
export const AxiosInitialize = (): null => {
  const oidcAuth = useOIDCAuth();
  const basicAuth = useAuth();
  const { selectedTenant } = useTenantAccess();

  if (process.env.REACT_APP_USE_NATIVE_LOGIN) auth = basicAuth;
  else auth = oidcAuth;

  tenantId = selectedTenant.selectedTenantId;

  return null;
};

// Configure Axios interceptor
export function axiosInterceptor(): {
  setupInterceptor: (axiosInstance: AxiosInstance) => AxiosInstance;
} {
  const { REACT_APP_USE_NATIVE_LOGIN } = process.env;

  const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    // Set X-CSRF-token in request header for required API calls (if not using sso login)
    if (
      REACT_APP_USE_NATIVE_LOGIN &&
      config.method !== "GET" &&
      Constants.NoCSRFAPIUrls.every((e) => !config.url?.includes(e))
    ) {
      config.headers = {
        "X-CSRF-TOKEN":
          Cookies.getCookieValue(Constants.TokenCookiesKeyName.csrfToken) || "",
      };
    }
    config.headers = {
      ...config.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (!REACT_APP_USE_NATIVE_LOGIN && config.method !== "OPTIONS") {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          (auth as OIDCAuthContextProps).userData?.access_token
        }`,
      };

      if (tenantId && (config.sendTenantHeader ?? true))
        config.headers = {
          ...config.headers,
          "X-TenantID": tenantId,
        };
    }

    config.withCredentials = true;

    return config;
  };

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  // Handle refresh token logic
  const onResponseError = async (
    error: AxiosError<never | { msg: string }>,
    axiosInstance: AxiosInstance
  ) => {
    const originalConfig = error.config;
    const data = JSON.stringify(error?.response?.data);

    /**
     * If error code is 401
     * and if error message is about token revoked (occurs if refresh token is removed from the API)
     * or error occurs on refresh token API call itself
     * then logout and redirect user to login
     */
    if (
      (error?.response?.status === Constants.ErrorCodes.invalidCredential ||
        error?.response?.status === Constants.ErrorCodes.accessDenied) &&
      (data?.includes(Constants.ErrorMessages.tokenRevoked) ||
        data?.includes(Constants.ErrorMessages.missingTokenOrCookies) ||
        data?.includes(Constants.ErrorMessages.invalidTokenOrCookies) ||
        (originalConfig.url &&
          originalConfig.url.includes(Constants.APIUrls.RefreshToken)))
    ) {
      if (REACT_APP_USE_NATIVE_LOGIN)
        (auth as BasicAuthHook).redirectToLoginOnTokenExpire();

      return Promise.reject(error);
    }

    /**
     * If error code is 401
     * and error message is about token expiry
     * then call refresh token API and retry original API request
     */
    if (
      error?.response?.status === Constants.ErrorCodes.invalidCredential &&
      data?.includes(Constants.ErrorMessages.jwtExpired) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      if (REACT_APP_USE_NATIVE_LOGIN) {
        const errorMessage = await (auth as BasicAuthHook).refreshTokens();
        if (!errorMessage) {
          return axiosInstance(originalConfig);
        }
      } else {
        (auth as OIDCAuthContextProps).signIn();
      }
    }

    return Promise.reject(error);
  };

  function setupInterceptor(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, (error) =>
      onResponseError(error, axiosInstance)
    );
    return axiosInstance;
  }

  return { setupInterceptor };
}
