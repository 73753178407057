import React from "react";

import EventDetailsContainer from "@Containers/eventDetails";
import ErrorBoundary from "@Containers/errorBoundary";

const EventDetails = (): JSX.Element => (
  <ErrorBoundary>
    <EventDetailsContainer />
  </ErrorBoundary>
);

export default EventDetails;
