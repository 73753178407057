import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  filterPopover: {
    "& .MuiPopover-paper": {
      backgroundColor: theme.newPalette.table.optionsPopoverBg,
    },
  },

  filterText: {
    margin: `${theme.pxToRem(2)} ${theme.pxToRem(3)} 0 0`,
    fontSize: theme.pxToRem(14),
    color: theme.newPalette.text.primary.main,
  },

  filterToggleIcon: {
    fontSize: theme.pxToRem(26),
    transition: "transform 200ms ease-in",
    color: theme.newPalette.text.primary.main,
  },

  filterToggleIconOpen: {
    transform: "rotate(180deg)",
  },
}));

export default useStyles;
