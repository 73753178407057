import React, { useState, useEffect, useRef } from "react";
import { Autocomplete as MuiAutoComplete } from "@material-ui/lab";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import Fuse from "fuse.js";

// Components
import SVGWrapper from "@Components/SVGWrapper";

// Constants
import { Regex } from "@Constants/common";
import SVGIcons from "@Constants/icon";

// Types
import { FilterAutoSuggestProps } from "./FilterAutoSuggest";

// Styles
import useStyles from "./FilterAutoSuggest.styles";

/**
 * FilterAutoSuggest component - used to input strings for fuzzy matching on the tables by table field names
 *
 * @example Correct usage
 * ```ts
 *  <FilterAutoSuggest
 *    id="id"
 *    value={null}
 *    options={{
 *      items: (
 *              [
 *                {
 *                  field:"a",
 *                  label:"A",
 *                  helperText: "helper text for a"
 *                },
 *                {
 *                  field:"b",
 *                  label:"B",
 *                  helperText: "helper text for b"
 *                }
 *              ]
 *      ),
 *      key: "field",
 *      renderOption: (option: Record<string, string>) => (
 *        <FilterAutoSuggestOption
 *          label={option.label}
 *          helperText={option.helperText}
 *        />
 *      ),
 *    }}
 *    inputValue={searchString}
 *    onInputValueChange={(newValue) => setSearchString(newValue)}
 *    label="Search"
 *  />
 * ```
 */
export const FilterAutoSuggest = ({
  id,
  value,
  options,
  onChange,
  inputValue,
  onInputValueChange,
  onEnterKeyPress,
  suggestionConfigForValue,
  handleClearInput,
  label = "",
  placeholder = "",
  errorMessage = false,
  textFieldExtraClass = "",
  extraClass = "",
}: FilterAutoSuggestProps) => {
  const classes = useStyles();
  const [isSuggestionPopoverOpen, setIsSuggestionPopoverOpen] = useState(false);
  const isFirstRenderCycle = useRef(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  /**
   * Performs fuzzy search for the given value in the data
   * @param data The data in which the value is being searched
   * @param searchValue The value to be searched
   * @returns The search results after approximate matching
   */
  const fuzzySearch = (
    data: Array<Record<string, string>>,
    searchValue: string
  ): Array<Record<string, string>> => {
    // If the search string is empty return all the data
    if (!searchValue) {
      return data;
    }

    const fuse = new Fuse(data, {
      keys: [
        {
          name: options.key,
          // Give it more weight as this is what will be populated in the field and based on this only we apply the filters in the API
          weight: 2,
        },
        // The default weight will be 1
        "label",
      ],
      shouldSort: true,
    });

    const searchResults = fuse.search(searchValue);
    return searchResults.map((searchItem) => searchItem.item);
  };

  /**
   * Parses the inputValue and extracts the name of the last field and its current value
   * @returns The name of the last field and its value in the input string or null
   */
  const getLastFieldNameAndValue = () => {
    const lastIndexOfColon = inputValue.trim().lastIndexOf(":");
    const subStringWithFieldName = inputValue
      .trim()
      .substring(0, lastIndexOfColon)
      .trim();
    const fieldName = subStringWithFieldName
      .substring(subStringWithFieldName.lastIndexOf(" ") ?? 0)
      .trim();
    const fieldValue = inputValue
      .trim()
      .substring(lastIndexOfColon + 1)
      .trim();

    // If the input string ends with : or "", then only suggestion for value should be visible
    if (Regex.endsWithColonOrQuotes.test(inputValue.trim())) {
      return {
        fieldName,
        value: Regex.startAndEndWithQuotes.test(fieldValue)
          ? fieldValue.slice(1, -1)
          : fieldValue,
      };
    }

    return null;
  };

  /**
   * Checks whether the suggestions are available for the value of the last field or not
   */
  const isSuggestionAvailableForLastFieldValue = (): boolean => {
    const { fieldName, value: fieldValue } = getLastFieldNameAndValue() || {
      fieldName: "",
      value: "",
    };

    // If suggestions are available for the value of the current field
    if (
      fieldName === suggestionConfigForValue?.fieldName &&
      suggestionConfigForValue?.suggestions
    ) {
      const isValueEqualToSuggestion =
        suggestionConfigForValue?.suggestions.some(
          (suggestion) => suggestion[options.key] === fieldValue
        );

      /**
       * If the field value is empty
       * or suggestions are available for the entered value and the entered value isn't equal to suggested value
       * then so the suggestions
       */
      return (
        fieldValue === "" ||
        (fuzzySearch(suggestionConfigForValue.suggestions, fieldValue) &&
          !isValueEqualToSuggestion)
      );
    }

    return false;
  };

  /**
   * Returns the suggestions for the value of any given field
   */
  const getSuggestionsForValue = (): Record<string, string>[] => {
    const lastFieldNameAndValue = getLastFieldNameAndValue();

    return suggestionConfigForValue?.fieldName ===
      lastFieldNameAndValue?.fieldName && suggestionConfigForValue?.suggestions
      ? fuzzySearch(
          suggestionConfigForValue.suggestions,
          lastFieldNameAndValue?.value ?? ""
        )
      : [];
  };

  /**
   * Filter the suggestions depending on the input string
   * @param allSuggestions - all the available suggestions
   * @returns - Suggestions applicable for the current input string
   */
  const getApplicableSuggestions = (
    allSuggestions: Record<string, string>[]
  ) => {
    // Check if the user is entering the value for any field and if there are any suggestion for the value of that field
    const suggestionsForValue = getSuggestionsForValue();
    if (isSuggestionAvailableForLastFieldValue() && suggestionsForValue.length)
      return suggestionsForValue;

    // Check if currently a value is being entered
    if (inputValue.indexOf(":") >= 0) {
      const subStringAfterColon = inputValue.substring(
        inputValue.lastIndexOf(":") + 1
      );

      /**
       * Check if the value is being entered between the quotes
       * If the substring ends with a double quote and there is a white space after that
       * We need to show the suggestions for the next field
       * Otherwise we don't need to show the suggestions
       */
      if (
        Regex.startAndEndWithQuotes.test(subStringAfterColon.trim()) &&
        !Regex.endsWithQuotesFollowedBySpace.test(subStringAfterColon)
      ) {
        return [];
      }

      /**
       * Check if the value is being entered without the quotes
       * After splitting the value by space, if there is only one string in the result array
       * Then it means that the value is being entered
       */
      const subStringSplitBySpacing = subStringAfterColon.trim().split(" ");
      if (subStringSplitBySpacing.length <= 1) {
        return [];
      }
    }

    // Split the strings based on spaces
    const inputStrings = inputValue.trim().split(" ");
    // Extract the last string which is responsible for the suggestions
    const filterValue = inputStrings.length
      ? inputStrings[inputStrings.length - 1]
      : "";

    return fuzzySearch(allSuggestions, filterValue);
  };

  // When the input value changes, open the options popover
  useEffect(() => {
    // If it is rendered for the first time, then the suggestions popover should be closed
    if (isFirstRenderCycle.current) {
      isFirstRenderCycle.current = false;
      return;
    }

    if (inputValue.trim().endsWith(`""`)) {
      // Move the cursor between the double quotes
      if (inputRef.current) {
        inputRef.current.selectionStart = inputValue.length - 1;
        inputRef.current.selectionEnd = inputValue.length - 1;
        // If there are suggestions for the value of the last field, open the suggestion popover
        if (isSuggestionAvailableForLastFieldValue()) {
          setIsSuggestionPopoverOpen(true);
        }
      }
      // Show the suggestion popover for value
    } else if (!getApplicableSuggestions(options.items).length) {
      // Close the popover if we don't have any suggestions for the current input string
      setIsSuggestionPopoverOpen(false);
    } else {
      // Open the suggestions popover only if we've at least one suggestion to show
      setIsSuggestionPopoverOpen(true);
    }
  }, [inputValue]);

  /**
   * Handles applying the selected field in the input string
   * @param _e The event fired when the user selects one of the available options
   * @param newValue The options selected by the user
   */
  const handleChange = (
    _e: React.ChangeEvent<{}>,
    newValue: Record<string, string> | string | null
  ) => {
    setIsSuggestionPopoverOpen(false);
    onChange?.(newValue);

    if (newValue) {
      const trimmedInputValue = inputValue.trim();

      // If the option is chosen for a value of any field
      if (
        (trimmedInputValue.endsWith(`"`) || trimmedInputValue.endsWith(":")) &&
        suggestionConfigForValue
      ) {
        let newValueWithoutSuggestion = trimmedInputValue.endsWith(":")
          ? trimmedInputValue
          : "";

        // Get the substring of the input value without the value for which suggestion is shown
        if (trimmedInputValue.endsWith(`"`)) {
          const valuesSeparatedByColon = trimmedInputValue.split(":");
          // Delete the last string for which the suggestion was shown and is clicked
          valuesSeparatedByColon.splice(valuesSeparatedByColon.length - 1, 1);
          // Sub-string without having the string for which the suggestion is chosen
          newValueWithoutSuggestion = valuesSeparatedByColon.join(":");
        }
        const newInputValue = `${newValueWithoutSuggestion}:"${
          (newValue as Record<string, string>)[options.key]
        }"`;
        onInputValueChange(newInputValue);
        return;
      }

      // If the options is chosen for field
      const valuesSeparatedBySpaces = trimmedInputValue.split(" ");
      // Delete the last string for which the suggestion was shown and is clicked
      valuesSeparatedBySpaces.splice(valuesSeparatedBySpaces.length - 1, 1);
      // Sub-string without having the string for which the suggestion is chosen
      const newValueWithoutSuggestion = valuesSeparatedBySpaces.join(" ");
      // The new input value with the chosen suggestion and quotes to enter the value for the chosen field
      const newInputValue = `${newValueWithoutSuggestion} ${
        (newValue as Record<string, string>)[options.key]
      }:""`;
      // Handle the new value
      onInputValueChange(newInputValue);
    }
  };

  /**
   * Handles submitting the input value only when suggestion isn't open
   * @param e The event fired when the keyboard key is clicked
   */
  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === "Enter" && !isSuggestionPopoverOpen && onEnterKeyPress?.(e);

  return (
    <>
      <MuiAutoComplete
        id={id}
        open={isSuggestionPopoverOpen}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        options={options.items}
        renderOption={options.renderOption}
        getOptionLabel={(option) => option[options.key]}
        filterOptions={getApplicableSuggestions}
        className={`${extraClass} ${classes.autoComplete}`}
        classes={{
          endAdornment: "d-none",
          popper: classes.optionsPopper,
          option: classes.autoCompleteOption,
        }}
        onFocus={() =>
          getApplicableSuggestions(options.items).length &&
          setIsSuggestionPopoverOpen(true)
        }
        onBlur={() => setIsSuggestionPopoverOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            error={!!errorMessage}
            className={`${classes.textField} ${textFieldExtraClass}`}
            onChange={(e) => onInputValueChange(e.target.value)}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.startAdornment}
                >
                  <SVGWrapper width={20} height={20} viewBox="0 0 16 16">
                    {SVGIcons.search}
                  </SVGWrapper>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={`${classes.endAdornment} ${
                    !inputValue && "d-none"
                  }`}
                >
                  <IconButton
                    onClick={() => {
                      onInputValueChange("");
                      handleClearInput?.();
                    }}
                  >
                    <SVGWrapper width={24} height={24} viewBox="0 0 24 24">
                      {SVGIcons.close}
                    </SVGWrapper>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={handleEnterClick}
          />
        )}
      />
      {errorMessage && (
        <div id={`${id}-error-message`} className={classes.errorMessage}>
          {errorMessage}
        </div>
      )}
    </>
  );
};
