import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Containers
import Events from "./events";
import Devices from "./devices";
import ErrorBoundary from "@Containers/errorBoundary";

// hooks
import useTab from "@Hooks/useTab";
import useCustomHistory from "@Hooks/useCustomHistory";

// Constants
import { DashboardPageTabs } from "@Constants/common";
import { DashboardContainerIds } from "@Constants/Id";

// Utils
import Utils from "@Utils";

// styles
import useStyles from "./styles";

const Dashboard = (): JSX.Element => {
  const classes = useStyles();
  const tabDetails = useTab();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useCustomHistory();

  // Tracks navigated page number
  const [currentPage] = useState<number>(
    Utils.getPageNumberFromQueryParams(queryParams)
  );
  const [pageSize] = useState<number>(
    Utils.getPageSizeFromQueryParams(queryParams)
  );

  // Get the currentlyActivePageTab from the query-param or set the default one, that is, events
  const [currentlyActivePageTab, setCurrentlyActivePageTab] = useState<string>(
    Utils.getTabStringFromQueryParams(queryParams) || DashboardPageTabs.Events
  );

  // Change the currently active tab and page-content when user clicks browsers' back button
  useEffect(() => {
    setCurrentlyActivePageTab(
      Utils.getTabStringFromQueryParams(queryParams) ||
        tabDetails.currentlyActivePageTab ||
        DashboardPageTabs.Events
    );
    tabDetails.setActivePageTab(
      (Utils.getTabStringFromQueryParams(queryParams) ||
        tabDetails.currentlyActivePageTab ||
        DashboardPageTabs.Events) as unknown as DashboardPageTabs,
      true
    );
  }, [location]);

  // To generate queryParams in case of null queryParams on dashboard page
  useEffect(() => {
    if (Utils.getTabStringFromQueryParams(queryParams) === null) {
      history.replace(
        Utils.getNewPath(
          location.pathname,
          `tab=${currentlyActivePageTab}&` +
            Utils.getSearchStringToAppend(queryParams),
          {
            page: currentPage,
            page_size: pageSize,
          }
        )
      );
    }
  }, [location]);

  // Change the currently active page tab to the default page tab if it is not valid
  useEffect(() => {
    if (
      currentlyActivePageTab !== DashboardPageTabs.Events &&
      currentlyActivePageTab !== DashboardPageTabs.Devices
    ) {
      setCurrentlyActivePageTab(DashboardPageTabs.Events);
      tabDetails.setActivePageTab(DashboardPageTabs.Events, true);
    }
  }, []);

  return (
    <div
      id={DashboardContainerIds.page({ tab: currentlyActivePageTab })}
      className={classes.dashboardWrapper}
    >
      {currentlyActivePageTab === DashboardPageTabs.Events && (
        <ErrorBoundary>
          <Events />
        </ErrorBoundary>
      )}
      {currentlyActivePageTab === DashboardPageTabs.Devices && (
        <ErrorBoundary>
          <Devices />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default Dashboard;
