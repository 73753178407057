import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: theme.pxToRem(198),
    backgroundColor: theme.newPalette.labelPopover.background,
    color: theme.newPalette.labelPopover.text,
    alignItems: "unset",
  },

  header: {
    padding: `${theme.pxToRem(10)} ${theme.pxToRem(12)}`,

    "& svg": {
      fill: theme.newPalette.labelPopover.settings,
    },
  },

  settings: {
    padding: 0,
    minWidth: "fit-content",
  },

  inputContainer: {
    height: theme.pxToRem(40),
    margin: `0 ${theme.pxToRem(5)}`,
    color: theme.newPalette.labelPopover.text,
  },

  listTitle: {
    padding: `${theme.pxToRem(20)} ${theme.pxToRem(10)} 0`,
    fontWeight: 500,
  },

  listContainer: {
    maxHeight: theme.pxToRem(360),
    overflowY: "auto",
  },

  subListContainer: {
    maxHeight: theme.pxToRem(200),
    overflowY: "auto",
  },

  listItem: {
    padding: 0,

    "&.listItem-loading": {
      opacity: 0.5,
    },
  },

  formControlLabel: {
    padding: `0 ${theme.pxToRem(10)}`,
    height: theme.pxToRem(40),
    width: "100%",
  },

  checkBox: {
    "& .MuiIconButton-label": {
      color: theme.newPalette.labelPopover.checkbox,
    },

    "& .MuiSvgIcon-root": {
      width: theme.pxToRem(16),
      height: theme.pxToRem(16),
    },
  },

  actionContainer: {
    alignItems: "unset",
    gap: theme.pxToRem(10),
    padding: `${theme.pxToRem(12)} ${theme.pxToRem(10)}`,
  },

  maxTagMessage: {
    opacity: 0,
    height: 0,
    transition: "all 400ms ease-in-out",
    margin: `0 ${theme.pxToRem(10)}`,

    "&.maxMessage": {
      opacity: 1,
      height: theme.pxToRem(22),
      margin: `12px ${theme.pxToRem(10)} 0`,
    },

    "& svg": {
      fill: theme.newPalette.text.primary.main,
    },
  },

  button: {
    border: `${theme.pxToRem(1)} solid transparent`,

    "&.MuiButton-root.Mui-disabled": {
      opacity: 0.5,
      backgroundColor: `${theme.newPalette.manageTagModal.disabledButton} !important`,
      borderColor: `${theme.newPalette.manageTagModal.disabledButtonBorder} !important`,
    },
  },
}));

export default useStyles;
