import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dashboardWrapper: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginTop: theme.pxToRem(80),
    },
  },
  cardWrapper: {
    width: "100%",
    borderRadius: theme.pxToRem(20),
    backgroundColor: theme.newPalette.card.background,
    color: theme.newPalette.barGraph.color,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.pxToRem(26)} ${theme.pxToRem(24)}`,
    height: theme.pxToRem(300),
  },
  totalCardWrapperDevices: {
    "& rect": {
      fill: theme.colors.pink,
      width: theme.pxToRem(42),
      height: theme.pxToRem(42),
    },
  },
  link: {
    width: "fit-content",
    marginLeft: theme.pxToRem(18),
  },
  barGraphTitle: {
    fontSize: theme.pxToRem(14),
    opacity: 0.7,
  },
  tableWrapper: {
    padding: `${theme.pxToRem(18)} ${theme.pxToRem(16)}`,
  },
  filterSortWrapper: {
    padding: `0 ${theme.pxToRem(16)}`,
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      flexDirection: "column",
    },
  },
  tableCardWrapper: {
    borderRadius: theme.pxToRem(8),
    overflow: "visible",
    background: "transparent",

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginBottom: theme.pxToRem(10),
    },
  },
  tableTitle: {
    fontSize: theme.pxToRem(18),
    color: theme.newPalette.text.secondary.main,
    ...theme.utils.semiBoldText(),
  },
  tableTitleEvents: {
    margin: `${theme.pxToRem(25)} 0 ${theme.pxToRem(10)} 0`,
  },
  datePickerWrapper: {
    marginBottom: theme.pxToRem(30),
    justifyContent: "end",

    [theme.breakpoints.up(Breakpoints.tabletMin)]: {
      position: "fixed",
      top: theme.pxToRem(100),
      right: theme.pxToRem(24),
      zIndex: 1200,
    },

    // for tablet only
    [theme.breakpoints.between(Breakpoints.tabletMin, Breakpoints.tabletMin)]: {
      top: theme.pxToRem(65),
      right: theme.pxToRem(24),
    },

    // for mobile
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginBottom: theme.pxToRem(20),
      justifyContent: "flex-start",
    },
  },
  dashboardCardsWrapper: {
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      overflowX: "auto",
    },
  },
  datePickerContainer: {
    marginBottom: theme.pxToRem(30),

    // for mobile
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginBottom: theme.pxToRem(20),
    },
  },
}));

export default useStyles;
