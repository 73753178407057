import { useState, useEffect } from "react";
import { Typography, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutoComplete } from "@material-ui/lab";

// Types
import { AutoCompleteProps } from "./AutoComplete";

// Styles
import useStyles from "./AutoComplete.styles";

// Constants
import { AutoCompleteIDs } from "@Constants/Id";

/**
 * AutoComplete Component - normal text input enhanced by a panel of suggested options
 * @example Correct usage
 * ```tsx
 * <AutoComplete
 *  id="id"
 *  fillInputOnSelect
 *  label="label"
 *  onChange={handleOnChange}
 *  options={options}
 * />
 * ```
 *
 * @example Correct usage with error message
 * ```tsx
 * <AutoComplete
 *  id="id"
 *  fillInputOnSelect
 *  label="label"
 *  onChange={handleOnChange}
 *  options={options}
 *  errorMessage="Error message"
 * />
 * ```
 */
export const AutoComplete = ({
  id,
  value,
  options,
  onChange,
  textFieldSize = "medium",
  errorMessage = false,
  blurOnSelect = false,
  fillInputOnSelect = false,
  useInMultiSelect = false,
  label = "",
  defaultValue = "",
  placeholder = "",
  textFieldExtraClass = "",
  openOnFocus = false,
  clearInvalidInputOnBlur = false,
}: AutoCompleteProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(defaultValue);

  /**
   * Function triggered when the user changes/selects an option from the dropdown.
   * if fillInputOnSelect is `true`, the value gets filled in the textfield.
   * if useInMultiSelect is `true`, the value gets cleared.
   * @param _e - Change event
   * @param newValue - new value selected/changed by the user
   */
  const handleChange = (_e: React.ChangeEvent<{}>, newValue: string | null) => {
    onChange(newValue);
    if (useInMultiSelect) {
      setInputValue("");
    } else if (fillInputOnSelect) {
      setInputValue(newValue ?? "");
    }
  };

  /**
   * Render the option in the dropdown.
   * If option matches to cloud resource label then render the corresponding icon from the cloud resources.
   * @param option The option to render
   */
  const renderOption = (option: string) => (
    <>
      <Typography id={AutoCompleteIDs.option({ id, option })}>
        {option}
      </Typography>
    </>
  );

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <MuiAutoComplete
        id={id}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        options={options}
        renderOption={renderOption}
        className={classes.autoComplete}
        blurOnSelect={blurOnSelect}
        openOnFocus={openOnFocus}
        classes={{
          endAdornment: classes.endAdornment,
          popper: classes.optionsPopper,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            error={!!errorMessage}
            size={textFieldSize}
            className={textFieldExtraClass}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() =>
              clearInvalidInputOnBlur &&
              inputValue !== value &&
              setInputValue("")
            }
          />
        )}
      />
      {errorMessage && (
        <div id={`${id}-error-message`} className={classes.errorMessage}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default AutoComplete;
