import { useEffect, useState } from "react";
import { Toolbar, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

// Actions
import { toggleSidebar } from "@Store/actions";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";
import Link from "@Components/Link";

// Constants
import Constants from "@Constants";
import { ProtectedRoutes } from "@Constants/routes";
import { ButtonIDs, LinkIds, SVGIconIds } from "@Constants/Id";

// Components
import InPageTabs from "../InPageTabs";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";

// Styles
import useMobileSpecificStyles from "./ToolbarMobile.styles";
import useStyles from "@Components/Header/Header.styles";

// Types
import { ToolbarMobileProps } from "./ToolbarMobile";

/**
 * ToolbarMobile Component - the Toolbar component for mobile view
 *
 * @example Correct usage without in-page tab
 * ```ts
 * <ToolbarMobile
 *   id="id"
 *   pageTitle="Device Details"
 *   isTabSecondary={true}
 *   handleBack={handleBack}
 *   headerButton={headerButton}
 * />
 * ```
 *
 * @example Correct usage without in-page tab
 * ```ts
 * <ToolbarMobile
 *   id="id"
 *   pageTitle="Device Details"
 *   isTabSecondary={true}
 *   handleBack={handleBack}
 *   headerButton={headerButton}
 *   pageTabs={pageTabs}
 *   activePageTab={activePageTab}
 *   setActivePageTab={setActivePageTab}
 * />
 * ```
 */
export const ToolbarMobile = ({
  id,
  pageTitle,
  isTabSecondary = false,
  handleBack,
  pageTabs,
  activePageTab,
  setActivePageTab,
  headerButton,
}: ToolbarMobileProps): JSX.Element => {
  const classes = useStyles();
  const mobileSpecificClasses = useMobileSpecificStyles({ isTabSecondary });
  const { activeTheme, getBrandLogo } = useAppTheme();
  const dispatch = useDispatch();

  const openSidebar = () => dispatch(toggleSidebar(true));

  const [scrolledDown, setScrolledDown] = useState<boolean>(false);

  useEffect(() => {
    // as soon as a user starts scrolling, scrolledDown will be set to true
    const handleScroll = () => setScrolledDown(window.scrollY > 1);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeTheme]);

  const getPageTitle = () => (
    <Typography
      variant="h3"
      className={`text-capitalize 
      ${isTabSecondary && "flex justify-start"} 
      ${(!scrolledDown || isTabSecondary) && mobileSpecificClasses.pageTitle}`}
    >
      {pageTitle}
    </Typography>
  );

  return (
    <>
      <Toolbar
        id={id}
        className={`${mobileSpecificClasses.toolbar} 
        ${scrolledDown && mobileSpecificClasses.fixedTop}`}
      >
        <div className={mobileSpecificClasses.toolbarContentWrapper}>
          <div className="flex justify-between">
            {isTabSecondary && (
              <Button
                id={ButtonIDs.toolbarMobileBackButton}
                extraClass={classes.backButton}
                variant="text"
                onClick={handleBack}
              >
                <SVGWrapper
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  id={SVGIconIds.backBtnIcon}
                >
                  {Constants.SVGIcons.angleDown}
                </SVGWrapper>
              </Button>
            )}
            <Link
              id={LinkIds.brandLogoLink}
              link={ProtectedRoutes.Dashboard}
              extraClass={mobileSpecificClasses.logoWrapperLink}
            >
              {scrolledDown && !isTabSecondary ? (
                <SVGWrapper
                  width={23}
                  height={23}
                  viewBox="0 0 23 23"
                  id={SVGIconIds.brandLogo}
                >
                  {Constants.SVGIcons.brandLogoSmall}
                </SVGWrapper>
              ) : (
                <img
                  src={getBrandLogo()}
                  alt={Constants.LogoAltText}
                  className={mobileSpecificClasses.logo}
                />
              )}
            </Link>
            {scrolledDown && !isTabSecondary && getPageTitle()}

            <Button
              id={ButtonIDs.toolbarMobileHamburger}
              extraClass={mobileSpecificClasses.hamburger}
              onClick={openSidebar}
              variant="text"
            >
              <SVGWrapper
                width={20}
                height={20}
                viewBox="0 0 20 20"
                id={SVGIconIds.hamburgerIcon}
              >
                {Constants.SVGIcons.hamburger}
              </SVGWrapper>
            </Button>
          </div>
          {!scrolledDown && !isTabSecondary && getPageTitle()}
          {pageTabs && (
            <InPageTabs
              extraClass={scrolledDown ? mobileSpecificClasses.tabScrolled : ""}
              pageTabs={pageTabs}
              activePageTab={activePageTab}
              setActivePageTab={setActivePageTab}
            />
          )}
        </div>
      </Toolbar>
      {isTabSecondary && getPageTitle()}
      {headerButton && (
        <span
          className={`d-block w-100 margin-bottom-25 ${mobileSpecificClasses.headerBtnWrapper}`}
        >
          {headerButton}
        </span>
      )}
    </>
  );
};
