import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    minWidth: theme.pxToRem(120),
    marginRight: theme.pxToRem(30),
  },
  skeletonLoader: {
    marginTop: theme.pxToRem(-8),
  },
}));

export default useStyles;
