import { alpha, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { StyleArgTypes } from "./LevelledProgressBar";

/**
 * The styles to be applied to the LevelledProgressBar component
 */
export const useLevelledProgressBarStyles = makeStyles((theme: Theme) => ({
  progressBarContainer: {
    margin: `0 ${theme.pxToRem(18)} 0 ${theme.pxToRem(12)}`,
    alignItems: "initial",
  },
  levelBar: ({ totalLevels }: StyleArgTypes) => ({
    background: theme.lightTheme.secondary.mediumLight,
    height: theme.pxToRem(3),
    margin: `${theme.pxToRem(8)} ${theme.pxToRem(2.5)} 0 ${theme.pxToRem(2.5)}`,
    borderRadius: theme.pxToRem(20),
    width: `${100 / totalLevels}%`,
  }),
  tooWeak: {
    background: `${theme.lightTheme.primary.poppyRed}!important`,
  },
  weak: {
    background: `${theme.lightTheme.primary.warmYellowLight}!important`,
  },
  medium: {
    background: `${theme.lightTheme.primary.warmYellow}!important`,
  },
  strong: {
    background: `${theme.lightTheme.primary.green}!important`,
  },
  label: {
    float: "right",
    margin: `${theme.pxToRem(4)} ${theme.pxToRem(20)} 0 0`,
    fontSize: theme.pxToRem(12),
    color: alpha(theme.colors.grey, 0.9),
  },
}));

/**
 * Styles to be applied to the Checklist component
 */
export const useChecklistStyles = makeStyles((theme: Theme) => ({
  checklistContainer: {
    margin: `${theme.pxToRem(16)} 0 0`,
    background: theme.palette.secondary.main,
    padding: theme.pxToRem(20),
    borderRadius: theme.pxToRem(8),
    width: theme.pxToRem(285),
    position: "absolute",
    zIndex: 2,
    top: theme.pxToRem(8),
    left: "50%",
    transform: "translate(-50%,0)",
  },
  checkIcon: {
    width: theme.pxToRem(14),
    height: theme.pxToRem(14),
    marginRight: theme.pxToRem(6),
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  checklistItem: {
    display: "flex",
    color: theme.colors.white,
    marginBottom: theme.pxToRem(10),
    "&:last-child": {
      marginBottom: "0",
    },
  },
  checklistItemText: {
    fontWeight: theme.fontWeights.semiBold,
    fontSize: theme.pxToRem(12),
    lineHeight: theme.pxToRem(16),
  },
  completedChecklist: {
    color: `${theme.colors.green}!important`,
  },
  triangle: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    top: theme.pxToRem(12),
  },
}));
