import { Breakpoints } from "@Constants/common";
import { Theme, alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    padding: `${theme.pxToRem(25)} ${theme.pxToRem(30)}`,
  },
  popoverTitle: {
    color: theme.newPalette.text.primary.light,
  },
  closeButton: {
    background: "none",
    textDecoration: "none",
    padding: 0,
    width: theme.pxToRem(24),
    height: theme.pxToRem(24),
    justifyContent: "flex-end",

    "& path": {
      fill: theme.newPalette.modal.cancelIconColor,
    },

    "&:hover": {
      background: "none",
    },
  },
  text: {
    ...theme.utils.bodyText1(),
    color: theme.newPalette.text.primary.light,
  },
  icon: {
    fontSize: theme.pxToRem(18),
    marginLeft: theme.pxToRem(10),
    transition: "100ms all ease-in",
  },
  popoverExpandedIcon: {
    transform: "rotate(180Deg)",
  },
  DropDownButton: {
    padding: `${theme.pxToRem(18)} ${theme.pxToRem(20)}`,
    borderRadius: theme.pxToRem(8),
    backgroundColor: theme.newPalette.card.background,

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: `${theme.pxToRem(12)}`,
    },
  },
  applyButton: {
    ...theme.utils.buttonMedium(),
    width: theme.pxToRem(200),
    backgroundColor: theme.newColors.primary.fieryOrange[500],
    margin: `0 ${theme.pxToRem(30)} ${theme.pxToRem(20)}`,
    "&:hover": {
      backgroundColor: theme.newColors.primary.fieryOrange[600],
    },
  },
  popoverWrapper: {
    "& .MuiPopover-paper": {
      boxShadow: `${theme.pxToRem(20)} ${theme.pxToRem(20)} ${theme.pxToRem(
        80
      )} ${theme.newPalette.card.shadowColor}`,
      marginTop: theme.pxToRem(16),
      borderRadius: theme.pxToRem(20),
      backgroundColor: theme.newPalette.card.background,
    },
  },
  calendarWrapper: {
    background: "transparent",
  },
  edge: {
    background: theme.newColors.primary.fieryOrange[500],
    width: theme.pxToRem(36),
    borderRadius: theme.pxToRem(18),
    margin: "0 auto",
    "&:before": {
      position: "absolute",
      width: theme.pxToRem(18),
      height: "100%",
      background: alpha(theme.newColors.primary.fieryOrange[500], 0.1),
    },
  },
  day: {
    "& span": {
      color: `${theme.newPalette.text.primary.light} !important`,
    },
  },
  dayPassive: {
    opacity: 0.3,
  },
  dayDisabled: {
    opacity: 0.3,
    background: "transparent",
    cursor: "default",
  },
  inRange: {
    background: alpha(theme.newColors.primary.fieryOrange[500], 0.1),
  },
  dayInPreview: {
    borderColor: theme.newColors.primary.fieryOrange[500],
    "& span": {
      color: `${theme.newColors.neutral[100]} !important`,
    },
  },
  monthName: {
    marginTop: theme.pxToRem(10),
  },
  monthAndYearPickers: {
    "& select": {
      background: theme.newPalette.downloadAgent.background,
      color: theme.newPalette.text.primary.light,
      padding: theme.pxToRem(10),
      width: theme.pxToRem(86),
      "& option": {
        background: theme.newPalette.card.background,
      },
    },
  },
}));

export default useStyles;
