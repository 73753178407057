import { makeStyles, Theme, alpha } from "@material-ui/core/styles";

// constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    overflow: "hidden",
    color: theme.newPalette.text.primary.main,
    "& .MuiTabs-root": {
      background: "transparent",
      textTransform: "capitalize",
    },
    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
    },
    "& .MuiTab-root": {
      ...theme.utils.buttonLarge(),
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  buttonTabsContainer: {
    boxShadow: "none",
    "& .MuiTabs-root": {
      backgroundColor: `${theme.newPalette.downloadAgentModal.tabsBg}`,
      padding: theme.pxToRem(8),
      borderRadius: theme.pxToRem(4),
    },
    "& .MuiTab-root": {
      color: alpha(theme.newPalette.downloadAgentModal.tabColor, 0.5),
    },
    "& .Mui-selected": {
      borderRadius: theme.pxToRem(4),
      color: `${theme.newPalette.downloadAgentModal.tabColor}`,
      background: `${theme.newPalette.downloadAgentModal.activeTabBg}`,
    },
  },
  standardTabsContainer: {
    "& .MuiTabs-flexContainer": {
      gap: theme.pxToRem(40),
      boxShadow: `inset 0 -${theme.pxToRem(1)} 0 ${
        theme.newPalette.tabs.bottomBorder
      }`,
      [theme.breakpoints.down(Breakpoints.tabletMin)]: {
        gap: theme.pxToRem(20),
        overflowX: "auto",
      },
    },

    "& .MuiTab-root": {
      color: theme.newPalette.text.secondary.main,
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      padding: `${theme.pxToRem(10)} 0`,

      [theme.breakpoints.down(Breakpoints.tabletMin)]: {
        ...theme.utils.regularText(),
        fontSize: theme.pxToRem(14),
      },
    },

    "& .MuiTab-root.Mui-selected": {
      ...theme.utils.boldText(),
      color: theme.newColors.primary.fieryOrange[500],
      background: "transparent",
      boxShadow: `inset 0 -${theme.pxToRem(1)} 0 ${
        theme.newColors.primary.fieryOrange[500]
      }`,
    },
  },
}));

export default useStyles;
