import { makeStyles, Theme, darken } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    color: theme.newPalette.text.primary.main,
    marginTop: theme.pxToRem(23),
    position: "relative",
    "& .MuiInputBase-root .MuiInputBase-input": {
      color: theme.newPalette.input.color,
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.newColors.primary.fieryOrange[500]}!important`,
    },
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.newPalette.input.borderColor,
      },
    "& .MuiFormLabel-root": {
      color: theme.newPalette.input.borderColor,
      "&.Mui-focused": {
        color: theme.newColors.primary.fieryOrange[500],
      },
      "&.Mui-error": {
        color: theme.newColors.primary.poppyRed[500],
      },
    },
    "& .MuiSelect-icon": {
      color: theme.newPalette.input.borderColor,
    },
  },
  selectOption: {
    padding: `${theme.pxToRem(16)} ${theme.pxToRem(20)}`,
    color: theme.newPalette.input.color,
    background: theme.newPalette.select.optionsPopoverBg,
    ...theme.utils.caption2(),
    "&:hover": {
      background: `${theme.newPalette.select.optionsPopoverItemHoverBg}!important`,
    },
    "&.Mui-selected": {
      background: darken(
        theme.newPalette.select.optionsPopoverItemHoverBg,
        0.04
      ),
    },
  },
  addBgToMuiSelectIcon: {
    "& .MuiSelect-icon": {
      height: theme.pxToRem(52),
      width: theme.pxToRem(40),
      background: theme.newColors.primary.fieryOrange[500],
      color: theme.newColors.neutral[100],
      top: theme.pxToRem(1),
      right: theme.pxToRem(1),
    },
  },
  inputActive: {
    borderColor: theme.newColors.primary.fieryOrange[500],
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.newColors.primary.fieryOrange[500],
    },
    "& .MuiFormLabel-root": {
      color: theme.newColors.primary.fieryOrange[500],
    },
  },
  textField: {
    top: theme.pxToRem(4),
    left: theme.pxToRem(2),
    zIndex: 2,
    background: "transparent",
    width: `calc(100% - ${theme.pxToRem(44)})`,
    position: "absolute",
    "& .MuiInput-underline::after, & .MuiInput-underline::before": {
      border: "none!important",
    },
    "& .MuiInputBase-input": {
      padding: `${theme.pxToRem(14)} ${theme.pxToRem(12)}`,
    },
  },
  colorRed: {
    color: theme.newColors.primary.poppyRed[500],
  },
  errorMessage: {
    fontSize: theme.pxToRem(10),
    color: theme.newColors.primary.poppyRed[500],
    margin: `0 0 ${theme.pxToRem(-15)}`,
  },
  dropDown: {
    maxHeight: theme.pxToRem(400),
  },
}));

export default useStyles;
