// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// Handle actions for fetching agent installer details
export const fetchAgentInstallerDetails = () =>
  bindClientFunc({
    clientFunc: client.getAgentInstallerDetails,
    onFailure: ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_REQUEST,
    onSuccess: ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// Handle generating new agent installer token
export const generateNewAgentInstallerToken = () =>
  bindClientFunc({
    clientFunc: client.generateAgentInstallerToken,
    onFailure: ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_FAILED,
    onRequest: ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_REQUEST,
    onSuccess: ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;
