import React from "react";

import DeviceDetailsContainer from "@Containers/deviceDetails";
import ErrorBoundary from "@Containers/errorBoundary";

const DeviceDetails = (): JSX.Element => (
  <ErrorBoundary>
    <DeviceDetailsContainer />
  </ErrorBoundary>
);

export default DeviceDetails;
