import { makeStyles, Theme } from "@material-ui/core";

// Types
import { TimelineDotStyleArgs } from "./TimelineDot";

const useStyles = makeStyles((theme: Theme) => ({
  timelineDot: {
    width: theme.pxToRem(16),
    height: theme.pxToRem(16),
  },
  timelineDotFilled: {
    background: ({ dotColor }: TimelineDotStyleArgs) =>
      dotColor ? dotColor : theme.lightTheme.primary.fieryOrange,
    boxShadow: ({ dotColor }: TimelineDotStyleArgs) =>
      `0 0 ${theme.pxToRem(2)} 0 ${
        dotColor ? dotColor : theme.lightTheme.primary.fieryOrange
      }`,
  },
  timelineDotOutlined: {
    border: ({ dotColor }: TimelineDotStyleArgs) =>
      dotColor
        ? `${theme.pxToRem(2)} solid ${dotColor}`
        : `${theme.pxToRem(2)} solid ${theme.lightTheme.primary.fieryOrange}`,
  },
}));

export default useStyles;
