import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    "& .MuiPaper-root": {
      backgroundColor: theme.newPalette.labelPopover.background,
    },
  },
}));

export default useStyles;
