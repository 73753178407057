import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToolbarHeight } from "@Constants/common";

const useMobileSpecificStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    background: theme.newPalette.appBg,
    padding: `${theme.pxToRem(20)} 0`,
    transition: "all 100ms ease",
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 1300,
    display: "block",
  },
  toolbarContentWrapper: {
    maxWidth: theme.pxToRem(480),
    padding: `${theme.pxToRem(0)} ${theme.pxToRem(18)}`,
    margin: "auto",
  },
  fixedTop: {
    background: `${theme.newPalette.header.fixedMobileHeaderBg}!important`,
    boxShadow: `0 ${theme.pxToRem(9)} ${theme.pxToRem(160)} ${
      theme.newPalette.header.fixedMobileHeaderShadow
    }`,
    transition: "all 400ms ease",
  },
  logoWrapperLink: {
    margin: ({ isTabSecondary }: { isTabSecondary: boolean }) =>
      isTabSecondary
        ? `${theme.pxToRem(8)} 0 0 ${theme.pxToRem(-32)}`
        : `${theme.pxToRem(5)} 0 0`,
  },
  logo: {
    width: theme.pxToRem(100),
  },
  hamburger: {
    padding: 0,
    minWidth: "initial",
    width: theme.pxToRem(20),
    "&:hover": {
      background: "transparent",
    },
    "& svg path": {
      fill: theme.newPalette.header.color,
    },
  },
  pageTitle: {
    margin: ({ isTabSecondary }: { isTabSecondary: boolean }) =>
      isTabSecondary
        ? `${theme.pxToRem(ToolbarHeight.mobile - 30)} 0 ${theme.pxToRem(30)}`
        : `${theme.pxToRem(30)} 0 0`,
  },
  tabScrolled: {
    display: "flex !important",
    marginBottom: 0,
    width: "100%",
    "& .MuiButton-root": {
      width: "100%",
    },
  },
  headerBtnWrapper: {
    marginTop: theme.pxToRem(-15),
  },
}));

export default useMobileSpecificStyles;
