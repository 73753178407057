import { TableCell, Typography, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Components
import Link from "@Components/Link";
import TooltipWithCopy from "@Components/Tooltip/TooltipWithCopy";

// Utils
import Utils from "@Utils";

// Actions
import { showAlert } from "@Store/actions";

// Constants
import Constants from "@Constants";

// Hooks
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";

// Types
import { CellProps } from "./Cell";

// Styles
import useStyles from "./Cell.styles";

/**
 * Cell Component
 *
 * @example Correct usage
 * ```tsx
 * <Cell
 *  header={header}
 *  cellData={cellData}
 *  rowData={rowData}
 * />
 * ```
 *
 * @example Correct usage with show copy icon
 * ```tsx
 * <Cell
 *  showCopyIcon
 *  header={header}
 *  cellData={cellData}
 *  rowData={rowData}
 * />
 * ```
 */
export const Cell = ({
  header,
  cellData,
  rowData = {},
  truncateConfig,
  cssTruncate = true,
  showCopyIcon = false,
  inHorizontalTable = false,
  borderAroundCell = false,
}: CellProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useCustomMediaQuery();

  /**
   * Checks whether the color is applied to the cell data.
   * `OnClick` handler on cell can only be triggered if `isColored` returns true.
   */
  const isColored = () =>
    (header.isColumnColored || cellData.colored) && cellData.colored !== false;

  const getColor = () =>
    // priority is given to the colored property of cellData
    isColored()
      ? cellData.color ||
        header.color?.(cellData) ||
        theme.lightTheme.secondary.clearWater
      : theme.newPalette.table.color;

  /**
   * On triggering copy button, a success alert is shown with the message `Copied`
   */
  const handleOnCopyAlert = () =>
    dispatch(
      showAlert({
        message: "Copied",
        severity: Constants.AlertSeverity.success,
      })
    );

  /**
   * Checks whether `capitalized` is true in the config objects.
   * If `true` makes the content uppercase.
   */
  const isCapitalized = (): boolean | undefined =>
    (header.capitalized || cellData.capitalized) &&
    cellData.capitalized !== false;

  // Parsing the cell data with the available config
  const parsedCellData = (dateFormat?: string): TransformedCellData => {
    let parsedData: TransformedCellData = cellData;

    // if the column/label shows a date, format it to a user readable format
    if ((header.isDate || cellData?.isDate) && cellData?.isDate !== false) {
      const date = Utils.formatDateTime(
        parsedData.value,
        header.dateTimeFormat ||
          (isTablet
            ? dateFormat ?? Constants.DateTimeFormats.format2
            : Constants.DateTimeFormats.format1)
      );

      // If the date is invalid `-` is replaced instead of cell data
      parsedData = {
        ...parsedData,
        value: date === "Invalid Date" ? "-" : date,
      };
    }

    // parse cell data according to the transform method provided(if any)
    if (header.transformData) {
      parsedData = header.transformData(cellData, rowData);
    }
    return parsedData;
  };

  const classes = useStyles({
    color: getColor(),
    truncate: cssTruncate,
    maxWidth: header.maxWidth,
    inHorizontalTable: inHorizontalTable,
    isMobile,
  });

  const getCellJSX = () => (
    <Typography
      component="span"
      variant="body1"
      className={classes.tableCell}
      onClick={() =>
        isColored() &&
        header.renderAsButton &&
        (cellData.onClick?.(rowData) ||
          header.renderAsButton.onColumnCellClick?.(rowData))
      }
    >
      {truncateConfig
        ? Utils.getTruncatedString({
            value:
              typeof parsedCellData().value === "string"
                ? (parsedCellData().value as string)
                : "",
            ...truncateConfig,
          })
        : parsedCellData().value}
    </Typography>
  );

  // Cell is wrapped in a Link if  getRedirectUrl method is present in renderAsLink on header config
  const cellDataJSX = () =>
    header.renderAsLink && header.renderAsLink.getRedirectUrl(rowData) ? (
      <Link link={header.renderAsLink.getRedirectUrl(rowData)}>
        {getCellJSX()}
      </Link>
    ) : (
      getCellJSX()
    );

  // renders masked value in place of cell data.
  const MaskedValue = (): JSX.Element => (
    <input
      className={classes.maskedInput}
      type="password"
      value={"*".repeat(cellData.maskedCharLength || 20)}
      disabled
    />
  );

  // If header config contains addTooltipWithCopy, a tooltip is rendered with a copy button.
  const renderDataWithOrWithoutTooltip = (): JSX.Element => {
    return header.addTooltipWithCopy ? (
      <TooltipWithCopy
        tooltipText={
          typeof parsedCellData(Constants.DateTimeFormats.format1).value ===
          "string"
            ? (parsedCellData(Constants.DateTimeFormats.format1)
                .value as string)
            : ""
        }
        tooltipCopyChildren={cellDataJSX()}
      />
    ) : (
      cellDataJSX()
    );
  };

  return (
    <TableCell
      className={`${borderAroundCell && classes.tableRowBorderLeft} 
      ${showCopyIcon && "position-relative"} 
      ${classes.tableRow} ${inHorizontalTable && classes.noLeftPadding}
      ${isMobile && !inHorizontalTable && "d-flex"}`}
      scope="row"
    >
      {isMobile && !inHorizontalTable && (
        <Typography
          variant="subtitle2"
          className={`text-truncate ${classes.mobileHeader}`}
        >
          {header.label}
        </Typography>
      )}
      <span
        className={`${cssTruncate && "text-truncate"} 
          display-inline-block
          ${isCapitalized() && "text-capitalize"} 
          ${classes.tableCell}`}
      >
        {cellData.masked ? <MaskedValue /> : renderDataWithOrWithoutTooltip()}
      </span>
      {showCopyIcon && (
        <CopyToClipboard text={cellData.value} onCopy={handleOnCopyAlert}>
          <FileCopyRoundedIcon
            className={`${classes.copyIcon} copy-icon cursor-pointer`}
          />
        </CopyToClipboard>
      )}
    </TableCell>
  );
};
