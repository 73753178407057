import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Components
import Button from "@Components/Button";
import InfoCard from "@Components/InfoCard";
import Tabs from "@Components/Tabs";
import TooltipWithCopy from "@Components/Tooltip/TooltipWithCopy";
import EmptyState from "@Components/EmptyState";

// icons
import AddIcon from "@material-ui/icons/Add";
import SaveAlt from "@material-ui/icons/SaveAlt";

// Containers
import ThreatDetails from "./subComponents/threatDetailsTable";
import EventsForThreat from "./subComponents/eventsForThreat";
import ErrorBoundary from "../errorBoundary";

// Actions
import {
  postFileRequest,
  downloadThreat,
  fetchEventDetails,
} from "@Store/actions";

// Constants
import Constants from "@Constants";
import {
  ThreatDetailsTabIndex,
  ThreatDetailsQueryPageTabs,
  ThreatDetailsPageTabs,
  ThreatDetailsTabs,
  InfoCardIconType,
} from "@Constants/common";
import {
  ButtonIDs,
  EmptyStateIds,
  InfoCardIds,
  TabIds,
  TooltipIds,
} from "@Constants/Id";
import { sortBySearchParam } from "@Constants/sorting";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";

// Utils
import Utils from "@Utils";

// Hooks
import useTab from "@Hooks/useTab";
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useCustomHistory from "@Hooks/useCustomHistory";
import useTenantAccess from "@Hooks/useTenantAccess";

const ThreatDetailsContainer = (): JSX.Element => {
  const { eventId, threatId } = useParams<{
    eventId: string;
    threatId: string;
  }>();
  const { selectedTenant } = useTenantAccess();
  const history = useCustomHistory();

  /**
   * Get event details state from redux store
   * @note This is required to handle threat details page reload on changing selected tenant.
   */
  const { error, loading: eventDetailsLoading }: EventDetailsState =
    useSelector((state: GlobalState) => state.eventDetails);

  const tabs = useTab();
  const { checkAccessAndRender } = usePermissionFilter();
  const { isMobile, isTablet, isDesktop, isLargeDesktop } =
    useCustomMediaQuery();

  //Download Threat State
  const { DownloadThreatloading } = useSelector(
    (state: GlobalState) => state.downloadThreat
  );

  // Initialize useLocation hook
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Uncomment while integration of request file
  const threatDetails: ThreatDetailsState = useSelector(
    (state: GlobalState) => state.threatDetails
  );

  const fileRequest: FileRequestState = useSelector(
    (state: GlobalState) => state.fileRequest
  );

  const threatDetailsTabs: TabParameters[] = [
    ThreatDetailsQueryPageTabs.details,
    ThreatDetailsQueryPageTabs.events,
  ];

  const isValidTabQueryParam = (): boolean => {
    const params = Object.keys(ThreatDetailsPageTabs).indexOf(
      queryParams.get(Constants.QueryParams.tab) as string
    );
    return params == -1 ? false : true;
  };

  const [currentTab, setCurrentTab] = useState(() => {
    const paramString = Utils.getTabStringFromQueryParams(queryParams);
    return isValidTabQueryParam() ? paramString : ThreatDetailsTabIndex.Details;
  });

  // Initialize useDispatch hook to dispatch actions
  // Uncomment while integration of request file
  const dispatch = useDispatch();

  /**
   * Fetch event details again when selected tenant is changed
   */
  useEffect(() => {
    dispatch(fetchEventDetails(eventId));
  }, [selectedTenant.selectedTenantId]);

  // Configure tabs
  const tabsData: TabsData[] = [
    {
      tab: {
        icon: Constants.SVGIcons.threat,
        label: "Details",
        iconWrapperConfig: {
          viewBox: "0 0 25 24",
          width: 25,
          height: 24,
        },
      },
      tabPanel: (
        <ErrorBoundary>
          <ThreatDetails
            threatId={threatId}
            eventId={eventId}
            eventDetailsFetching={eventDetailsLoading}
          />
        </ErrorBoundary>
      ),
      id: TabIds.threat,
    },
    {
      tab: {
        icon: Constants.SVGIcons.eventTab,
        iconWrapperConfig: {
          viewBox: "0 0 25 24",
          width: 25,
          height: 24,
        },
        label: "Events",
      },
      tabPanel: (
        <ErrorBoundary>
          <EventsForThreat
            threatId={threatId}
            eventDetailsFetching={eventDetailsLoading}
          />
        </ErrorBoundary>
      ),
      id: TabIds.threatEvents,
    },
  ];

  /**
   * TODO: To demonstrate the working flow of Request/Download file below codes are written
   * these codes are only for mock purpose and must be removed during actual integration.
   */
  // Uncomment while integration of request file
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);

  const handleRequestFileClick = () => dispatch(postFileRequest(eventId));

  const handleDownloadFileClick = async () => {
    const { response } = await dispatch(downloadThreat(threatId));
    if (response) {
      window.open(response.download_url);
    }
  };

  useEffect(() => {
    if (String(threatDetails.threat.available) === "false") {
      setIsDownloadAvailable(false);
    } else if (String(threatDetails.threat.available) === "true") {
      setIsDownloadAvailable(true);
    }
  }, [threatDetails]);

  /**
   * Update tab query param if its invalid
   */
  useEffect(() => {
    if (Utils.isInvalidTabQueryParam(queryParams)) {
      tabs?.setActivePageTab(currentTab as ThreatDetailsTabIndex, true);
    }
  }, [location.key]);

  useEffect(() => {
    if (!Utils.isInvalidTabQueryParam(queryParams)) {
      if (!isValidTabQueryParam()) {
        setCurrentTab(ThreatDetailsTabIndex.Details);
        tabs.setActivePageTab(ThreatDetailsTabIndex.Details, true);
      } else {
        setCurrentTab(Utils.getTabStringFromQueryParams(queryParams));
        tabs.setActivePageTab(
          Utils.getTabStringFromQueryParams(
            queryParams
          ) as ThreatDetailsTabIndex,
          true
        );
      }
    }

    /**
     * If on switching tab from events, sorting queries are still applied.
     * then the history will be reset since there is no functionality for sorting in details page.
     * */
    const locationParams = new URLSearchParams(location.search);
    if (
      tabs.currentlyActivePageTab === ThreatDetailsTabIndex.Details &&
      locationParams.has(sortBySearchParam)
    ) {
      history.replace(
        `${location.pathname}?tab=${tabs.currentlyActivePageTab}`
      );
    }
  }, [location]);

  const getThreatIdCharsToShowFromStartAndEnd = () => {
    // We don't need to truncate for large desktop
    if (isLargeDesktop) {
      // When we set both "noOfCharactersToDisplayFromStart" and "noOfCharactersToDisplayFromEnd" to 0, the string won't be truncated
      return 0;
    } else if (isDesktop) {
      return 20;
    } else if (isTablet && !isMobile) {
      return 15;
    } else if (isMobile) {
      return 5;
    }
  };

  const handleTabSwitch = (newTab: number) => {
    const paramString = threatDetailsTabs[newTab].label;
    setCurrentTab(paramString);
    tabs?.setActivePageTab?.(paramString as ThreatDetailsTabIndex);
  };

  return !error || eventDetailsLoading ? (
    <>
      <div className="flex justify-between margin-bottom-30">
        <InfoCard
          id={InfoCardIds.threatDetails}
          width={12}
          iconType={InfoCardIconType.largeDefault}
          infoTitle="Threat ID"
          infoSubtitle={
            <TooltipWithCopy
              placement="bottom-start"
              tooltipText={"Copy"}
              textToBeCopied={threatId}
              tooltipCopyChildren={threatId}
              truncateLabelValuePair={{
                value: threatId,
                noOfCharactersToDisplayFromStart:
                  getThreatIdCharsToShowFromStartAndEnd(),
                noOfCharactersToDisplayFromEnd:
                  getThreatIdCharsToShowFromStartAndEnd(),
                truncateFromBothStartAndEnd: true,
              }}
              id={TooltipIds.threatId}
            />
          }
          infoSubTitleClasses="font-weight-normal"
          icon={Constants.SVGIcons.threatDetails}
          button={
            checkAccessAndRender({
              child: (
                <Button
                  id={ButtonIDs.threatRequestDownload}
                  onClick={
                    isDownloadAvailable
                      ? handleDownloadFileClick
                      : handleRequestFileClick
                  }
                  disabled={
                    DownloadThreatloading ||
                    fileRequest.loading ||
                    eventDetailsLoading
                  }
                  startIcon={isDownloadAvailable ? <SaveAlt /> : <AddIcon />}
                >
                  {isDownloadAvailable ? "Download File" : "Request File"}
                </Button>
              ),
              featureAccessLevel: FeatureToAccessTypeMap.threats.fileAccess,
            }) as JSX.Element | null
          }
        />
      </div>
      <Tabs
        defaultSelectedTab={
          threatDetailsTabs[
            ThreatDetailsTabs[currentTab as ThreatDetailsTabIndex]
          ].index
        }
        onTabSwitch={handleTabSwitch}
        tabs={tabsData}
      />
    </>
  ) : (
    <EmptyState id={EmptyStateIds.threatDetails} />
  );
};

export default ThreatDetailsContainer;
