import Switch from "@material-ui/core/Switch";

// Types
import { ToggleButtonProps } from "./ToggleButton";

// Styles
import useStyles from "./ToggleButton.styles";

/**
 * ToggleButton Component
 * @example Correct usage
 * ```tsx
 * <ToggleButton
 *  onChange={handleOnChange}
 *  checked={isChecked}
 *  name={name}
 * />
 * ```
 */
export const ToggleButton = ({
  "aria-label": ariaLabel,
  ...props
}: ToggleButtonProps) => {
  const classes = useStyles();
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: `${classes.thumb} position-relative`,
        track: classes.track,
      }}
      inputProps={ariaLabel ? { "aria-label": ariaLabel } : {}}
      {...props}
    />
  );
};
