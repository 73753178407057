import { Breakpoints } from "@Constants/common";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  tokenWrapper: {
    padding: `${theme.pxToRem(16)} ${theme.pxToRem(25)}`,
    maxWidth: theme.pxToRem(493),
    margin: "0 auto",
    background: theme.newPalette.downloadAgentModal.installationTokenBg,
    borderRadius: theme.pxToRem(6),
    border: `${theme.pxToRem(1)} solid ${
      theme.newPalette.downloadAgentModal.installationTokenBorderColor
    }`,
  },
  generateTokenButton: {
    backgroundColor: theme.newPalette.downloadAgentModal.generateTokenBtnBg,
    color: theme.newPalette.downloadAgentModal.generateTokenBtnColor,
    border: "none",
    marginTop: theme.pxToRem(20),

    "&:hover": {
      border: "none",
      backgroundColor: theme.newPalette.colorTransformOnHover(
        theme.newPalette.downloadAgentModal.generateTokenBtnBg,
        0.1
      ),
      color: theme.newPalette.colorTransformOnHover(
        theme.newPalette.downloadAgentModal.generateTokenBtnBg,
        0.5
      ),
    },

    "& .MuiSvgIcon-root": {
      fontSize: theme.pxToRem(24),
    },
  },
  tokenText: {
    color: theme.newPalette.downloadAgentModal.installationTokenColor,
    maxWidth: theme.pxToRem(460),
    display: "inline-block",
    ...theme.utils.mediumText(),
  },
  copyIcon: {
    fontSize: theme.pxToRem(20),
    color: theme.newPalette.downloadAgentModal.CopyIconColor,

    "&:hover": {
      color: theme.newPalette.colorTransformOnHover(
        theme.newPalette.downloadAgentModal.CopyIconColor,
        0.1
      ),
    },
  },
  confirmationSection: {
    marginTop: theme.pxToRem(20),
    gap: theme.pxToRem(10),
    padding: theme.pxToRem(16),
    backgroundColor: theme.newPalette.downloadAgent.actionBackground,
    maxWidth: theme.pxToRem(530),
    margin: "0 auto",
    borderRadius: theme.pxToRem(10),
    borderLeft: `${theme.pxToRem(4)} solid ${
      theme.newColors.primary.fieryOrange[500]
    }`,

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      display: "block",
    },

    "& .flex-column": {
      gap: theme.pxToRem(4),
      [theme.breakpoints.down(Breakpoints.mobileMin)]: {
        marginTop: theme.pxToRem(10),
      },
    },
  },
}));

export default useStyles;
