import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  addOrEditForm: {
    padding: `${theme.pxToRem(5)} 0`,
  },
  inputField: {
    minWidth: theme.pxToRem(488),
  },
  modal: {
    "& .MuiDialog-container .MuiDialog-paper": {
      maxWidth: theme.pxToRem(600),
    },
  },
  modalCancelBtn: {
    top: theme.pxToRem(40),
    right: theme.pxToRem(40),
  },
  addNewTenantBtnContainer: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      zIndex: 1200,
      marginBottom: theme.pxToRem(20),
    },
  },
}));

export default useStyles;
