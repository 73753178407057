import { TableCell } from "@material-ui/core";

// Components
import SelectionCell from "../Cells/SelectionCell";

// Types
import { HeadersProps } from "./Headers";

// Styles
import useStyles from "./Headers.styles";

/**
 * Table Header Component
 * @example Correct usage
 * ```tsx
 * <Headers
 *   headers={headers}
 *   addOptionsHeader={
 *     options?.list && !!options.list.length && options.shouldRender
 *   }
 *   optionsHeaderName={optionsHeaderName}
 *   addIconsHeader={!!iconColumn}
 *   iconsHeaderName={iconColumn.columnHeading}
 * />
 * ```
 */
export const Headers = ({
  headers,
  optionsHeaderName,
  iconsHeaderName,
  width,
  inHorizontalTable = false,
  addOptionsHeader = false,
  addIconsHeader = false,
  supportSelection = false,
  areAllRowsSelected = false,
  handleRowsSelection,
  isIndeterminateState = false,
  isLoading = false,
  isSelectLimitReached = false,
}: HeadersProps): JSX.Element => {
  const classes = useStyles({ width, inHorizontalTable });

  return (
    <>
      {supportSelection && (
        <SelectionCell
          isSelected={areAllRowsSelected}
          onChange={(selected) => handleRowsSelection?.(selected, true)}
          isIndeterminate={isIndeterminateState}
          isDataLoading={isLoading}
          isSelectLimitReached={isSelectLimitReached}
        />
      )}
      {headers.map(
        (header) =>
          // Does not render the header cell if blacklist is `true` in header config.
          !header.blacklist && (
            <TableCell
              key={header.name}
              className={`${classes.tableHead} ${width && classes.fixedWidth}`}
            >
              {header.label}
            </TableCell>
          )
      )}
      {/* Renders an option column with the given optionsHeaderName as header name */}
      {addOptionsHeader && (
        <TableCell key="options" className={classes.tableHead}>
          {optionsHeaderName}
        </TableCell>
      )}
      {/* Renders an icon column with the given iconsHeaderName as header name */}
      {addIconsHeader && (
        <TableCell key="iconColumnHeading" className={classes.tableHead}>
          {iconsHeaderName}
        </TableCell>
      )}
    </>
  );
};
