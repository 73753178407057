import { Skeleton } from "@material-ui/lab";

// Types
import { SkeletonLoaderProps } from "./SkeletonLoader";

/**
 * SkeletonLoader component
 *
 * @example Correct usage for showing skeleton loader in place of text
 * ```ts
 * <SkeletonLoader id={uniqueId} height={textHeight} width={textWidth} />
 * ```
 *
 * @example Correct usage for showing skeleton loader in place of images
 * ```ts
 * <SkeletonLoader id={uniqueId} variant="rect" />
 * ```
 */
export const SkeletonLoader = ({
  id,
  variant = "text",
  width = "100%",
  height,
  animation,
  className,
}: SkeletonLoaderProps): JSX.Element => (
  <Skeleton
    id={id}
    variant={variant}
    width={width}
    height={height}
    animation={animation}
    className={className}
  />
);
