import Constants from "@Constants/index";

const initialState: AddTagsToDevices = {
  loading: false,
  isAdded: false,
  error: "",
};

/**
 * Handles adding tags to the devices
 * @param state AddTag
 * @param action GenericAction
 * @returns AddTagsToDevices
 */
const addTagsToDevicesReducer = (
  state: AddTagsToDevices = initialState,
  action: GenericAction
): AddTagsToDevices => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_TAGS_TO_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
        isAdded: false,
      };
    case Constants.ActionTypes.ADD_TAGS_TO_DEVICES_SUCCESS:
      return {
        loading: false,
        isAdded: true,
        error: "",
      };
    case Constants.ActionTypes.ADD_TAGS_TO_DEVICES_FAILED:
      return {
        loading: false,
        isAdded: false,
        error: action?.payload,
      };
    case Constants.ActionTypes.ADD_TAGS_TO_DEVICES_STATE_RESET:
      return {
        loading: false,
        isAdded: false,
        error: "",
      };
  }

  return state;
};

export default addTagsToDevicesReducer;
