import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    maxWidth: theme.pxToRem(530),
    margin: "0 auto",
  },
}));

export default useStyles;
