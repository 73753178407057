// Breakpoint values
const BreakpointValues = {
  xs: 0,
  sm: 48,
  md: 64,
  lg: 80,
  xl: 120,
};

export default BreakpointValues;
