// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 * Handles action for updating the current access level of the user
 * @param accessLevel the current access level of the user
 * @returns the action to be dispatched
 */
export const updateAccessLevel = (
  accessLevel: CurrentAccessLevelState
): GenericAction => ({
  type: ActionTypes.UPDATE_ACCESS_LEVEL,
  payload: accessLevel,
});

// Handles actions for fetching the user roles
export const getUserRoles = () =>
  bindClientFunc({
    clientFunc: client.getUserRoles,
    onFailure: ActionTypes.FETCH_USER_ROLES_FAILED,
    onRequest: ActionTypes.FETCH_USER_ROLES_REQUEST,
    onSuccess: ActionTypes.FETCH_USER_ROLES_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// Handle updating the role of the user
export const updateTenantUserRole = (
  userId: string,
  parentTenantId: string,
  role: string
) =>
  bindClientFunc({
    clientFunc: client.updateTenantUserRole,
    onFailure: ActionTypes.UPDATE_TENANT_USER_ROLE_FAILED,
    onRequest: ActionTypes.UPDATE_TENANT_USER_ROLE_REQUEST,
    onSuccess: ActionTypes.UPDATE_TENANT_USER_ROLE_SUCCESS,
    params: [userId, parentTenantId, role],
  }) as unknown as Promise<BindClient>;

// Handle fetching the role of a tenant user
export const fetchTenantUserRole = (userId: string, tenantId: string) =>
  bindClientFunc({
    clientFunc: client.getTenantUserRole,
    onFailure: ActionTypes.FETCH_TENANT_USER_ROLE_FAILED,
    onRequest: ActionTypes.FETCH_TENANT_USER_ROLE_REQUEST,
    onSuccess: ActionTypes.FETCH_TENANT_USER_ROLE_SUCCESS,
    params: [userId, tenantId],
  }) as unknown as Promise<BindClient>;
