// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { Themes } from "@Constants/common";

// handle action for changing the theme
export const changeTheme = (newTheme: Themes): GenericAction => ({
  type: ActionTypes.CHANGE_THEME,
  payload: newTheme,
});
