import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  identityProviderWrapper: {
    borderTop: `${theme.pxToRem(1)} solid ${theme.newColors.neutral[300]}`,
    marginTop: theme.pxToRem(15),
    paddingTop: theme.pxToRem(30),
  },
  addProviderButton: {
    marginTop: theme.pxToRem(30),
    "& svg path": {
      fill: theme.newColors.neutral[100],
    },
  },
  detailsCard: {
    background: theme.newPalette.popover.background,
    borderRadius: theme.pxToRem(20),
    padding: theme.pxToRem(30),
    marginTop: theme.pxToRem(30),
  },
  detailsHeader: {
    minWidth: theme.pxToRem(190),
  },
  detailsRow: {
    marginBottom: theme.pxToRem(20),
  },
  deleteButton: {
    background: theme.newPalette.error[500],
    "&:hover, &:focus, &:active": {
      background: theme.newPalette.error[700],
    },
    width: theme.pxToRem(160),
  },
  editButton: {
    width: theme.pxToRem(160),
  },
}));

export default useStyles;
