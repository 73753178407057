import { makeStyles, Theme } from "@material-ui/core";

// Types
import { TimelineStyleArgTypes } from "./Timeline";

const useStyles = makeStyles((theme: Theme) => ({
  timeline: {
    color: theme.newPalette.text.primary.main,

    "& .MuiTimelineItem-root:before": {
      flex: ({ centered }: TimelineStyleArgTypes) => (centered ? 1 : 0),
      padding: ({ centered }: TimelineStyleArgTypes) =>
        centered ? `${theme.pxToRem(6)} ${theme.pxToRem(16)}` : 0,
    },
  },
  timelineConnector: {
    background: ({ connectorColor }: TimelineStyleArgTypes) =>
      connectorColor ? connectorColor : theme.lightTheme.secondary.mediumLight,
  },
}));

export default useStyles;
