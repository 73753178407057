export const themeColors = {
  primary: {
    poppyRed: {
      // poppy red variants
      900: "#480504",
      800: "#820908",
      700: "#C60D0C",
      600: "#F22321",
      500: "#F44544",
      400: "#FF7171",
      300: "#FFADAD",
      200: "#FFE0E0",
      100: "#FFF5F5",
    },
    warmYellow: {
      900: "#613A00",
      800: "#945900",
      700: "#C77800",
      600: "#FA9600",
      500: "#FFAC2F",
      400: "#FFC36A",
      300: "#FFDBA5",
      200: "#FFEFD6",
      100: "#FFF7EB",
    },
    fieryOrange: {
      900: "#6B2700",
      800: "#9E3900",
      700: "#D14C00",
      600: "#FF6005",
      500: "#FF8139",
      400: "#FF9E68",
      300: "#FFC7A8",
      200: "#FFE8DB",
      100: "#FFF5F0",
    },
  },
  secondary: {
    charcoal: {
      900: "#3D4154",
      800: "#4B5067",
      700: "#616785",
      600: "#707798",
      500: "#9499B2",
      400: "#ACB0C3",
      300: "#C3C8DF",
      200: "#EEEFF6",
      100: "#F5F6FA",
    },
    deepBlue: {
      900: "#3F4C76",
      800: "#1D5677",
      700: "#2A7DAC",
      600: "#5EACD8",
      500: "#90C6E4",
      400: "#AFE7F9",
      300: "#C1ECFA",
      200: "#D9F3FC",
      100: "#FFFFFF",
    },
  },
  neutral: {
    900: "#0E121E",
    800: "#161D31",
    700: "#212941",
    600: "#5C6480",
    500: "#7F87A3",
    400: "#A6ACBF",
    300: "#D5D8E1",
    200: "#F3F4F7",
    100: "#FFFFFF",
  },
  grayscale: {
    900: "#000000",
    800: "#11151C",
    700: "#222938",
    600: "#49556B",
    500: "#65748C",
    400: "#8997AE",
    300: "#AFBCD1",
    200: "#BEC7D9",
    100: "#CCD2E2",
    50: "#E7E9F3",
    25: "#F3F4F9",
  },
};

export const InformationalColors = {
  success: {
    700: "#0BAC42",
    600: "#0DC94D",
    500: "#19F062",
    400: "#53F489",
    300: "#96F8B7",
  },
  warning: {
    700: "#CD810E",
    600: "#EF9A1A",
    500: "#F2AF4A",
    400: "#F6C479",
    300: "#F9D9A9",
  },
  error: {
    700: "#CC150A",
    600: "#F31A0C",
    500: "#F54237",
    400: "#F65A50",
    300: "#F87F77",
  },
  info: {
    700: "#0A7DBD",
    600: "#0C96E4",
    500: "#37B0F5",
    400: "#2EADF4",
    300: "#69C4F7",
  },
};
