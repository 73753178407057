import Constants from "@Constants";

const initialState: DeviceInfoDetailsState = {
  loading: false,
  infoDetails: {},
  error: "",
};

const deviceInfoDetailsReducer = (
  state: DeviceInfoDetailsState = initialState,
  action: GenericAction
): DeviceInfoDetailsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_DEVICE_INFO_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_DEVICE_INFO_DETAILS_SUCCESS:
      return {
        loading: false,
        infoDetails: action?.payload,
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICE_INFO_DETAILS_FAILED:
      return {
        loading: false,
        infoDetails: {},
        error: action?.payload,
      };
    case Constants.ActionTypes.ADD_TAGS_TO_DEVICE_INFO_DETAILS:
      return {
        loading: false,
        error: "",
        infoDetails: {
          ...state.infoDetails,
          tags: [
            ...((state.infoDetails.tags as string[]) || []),
            ...action.payload,
          ],
        },
      };
    case Constants.ActionTypes.DELETE_TAGS_FROM_DEVICE_INFO_DETAILS:
      return {
        loading: false,
        error: "",
        infoDetails: {
          ...state.infoDetails,
          tags: ((state.infoDetails?.tags || []) as string[]).filter(
            (tag) => !(action.payload as string[]).includes(tag)
          ),
        },
      };
    case Constants.ActionTypes.UPDATE_TAG_IN_DEVICE_INFO_DETAILS:
      return {
        loading: false,
        error: "",
        infoDetails: {
          ...state.infoDetails,
          tags: (state.infoDetails.tags as string[])?.map((tag) =>
            tag === action.payload.remove ? action.payload.add : tag
          ),
        },
      };
    case Constants.ActionTypes.RESET_DEVICE_INFO_DETAILS:
      return {
        loading: false,
        error: "",
        infoDetails: {},
      };
  }
  return state;
};

export default deviceInfoDetailsReducer;
