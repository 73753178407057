import Constants from "@Constants";

const initialState: FileRequestState = {
  loading: false,
  error: "",
};

/**
 * Handles actions of post File Request
 * @param state FileRequestState
 * @param action GenericAction
 * @returns FileRequestState
 */
const fileRequestReducer = (
  state: FileRequestState = initialState,
  action: GenericAction
): FileRequestState => {
  switch (action.type) {
    case Constants.ActionTypes.REQUEST_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.REQUEST_FILE_SUCCESS:
      return {
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.REQUEST_FILE_FAILED:
      return {
        loading: false,
        error: action?.payload,
      };
  }
  return state;
};

export default fileRequestReducer;
