import Constants from "@Constants";

const initialState: SidebarState = {
  open: false,
};

/**
 * Handles Sidebar toggle actions
 * @param state
 * @param action
 * @returns SidebarState
 */
const sidebarReducer = (
  state: SidebarState = initialState,
  action: GenericAction
): SidebarState => {
  switch (action.type) {
    case Constants.ActionTypes.OPEN_SIDEBAR:
      return {
        open: true,
      };
    case Constants.ActionTypes.CLOSE_SIDEBAR:
      return {
        open: false,
      };
  }

  return state;
};

export default sidebarReducer;
