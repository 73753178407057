import { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import SVGWrapper from "@Components/SVGWrapper";
import Toolbar from "./SubComponents/Toolbar";
import ToolbarMobile from "./SubComponents/ToolbarMobile";
import InPageTabs from "./SubComponents/InPageTabs";

// Constants
import Constants from "@Constants";
import { SecondaryPageTitles, LeftMenuTabs, Themes } from "@Constants/common";
import { SkeletonLoaderIds } from "@Constants/Id";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";
import useAppTheme from "@Hooks/useAppTheme";
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";

// Styles
import useStyles from "./Header.styles";

// Types
import { HeaderProps } from "./Header";

/**
 * Header component - used to render the header in Header container
 *
 * @example Correct usage with in page tabs
 * ```ts
 * <Header
 *  id="id"
 *  tabName="Dashboard"
 *  username={username}
 *  isTabSecondary={false}
 *  activePageTab={activePageTab}
 *  setActivePageTab={handleActivePageTabChange}
 * />
 * ```
 *
 * @example Correct usage without in page tabs
 * ```ts
 * <Header
 *  id="id"
 *  tabName="Devices"
 *  username={username}
 *  isTabSecondary={false}
 * />
 * ```
 *
 * @example Correct usage with header button
 * ```ts
 * <Header
 *  id="id"
 *  tabName="Devices"
 *  username={username}
 *  headerButton={headerButtonJSX}
 * />
 * ```
 */
export const Header = ({
  id,
  tabName,
  isTabSecondary = false,
  username = null,
  headerButton = null,
  activePageTab = null,
  setActivePageTab = null,
}: HeaderProps): JSX.Element => {
  const classes = useStyles();
  const pageTabs = Constants.PageTabs[tabName as LeftMenuTabs];
  const history = useCustomHistory();
  const { isMobile } = useCustomMediaQuery();
  // infoDetails - to show the device name in header
  const { infoDetails, loading } = useSelector(
    (state: GlobalState) => state.deviceInfoDetails
  );
  // eventDetails - to show the event name in header
  const eventDetailsState = useSelector(
    (state: GlobalState) => state.eventDetails
  );
  // Users - to show the user name in the header
  const { users, loading: usersLoading } = useSelector(
    (state: GlobalState) => state?.tenantUsers
  );
  const { userId } = useParams<{ userId: string }>();

  const { activeTheme } = useAppTheme();

  const handleBack = () => {
    const from = (history.location.state as any)?.from;

    return from && from.length
      ? history.replace(from.pop())
      : history.push(Constants.AppRoutes.ProtectedRoutes.Dashboard);
  };

  /**
   * Returns the icon to be rendered in the header
   * @param pageTitle The title of the page
   * @returns The header icon
   */
  const getHeaderIcon = (pageTitle: string) => {
    switch (pageTitle) {
      case SecondaryPageTitles.deviceDetails:
        return Constants.SVGIcons.deviceDetailsHeaderIcon;
      case SecondaryPageTitles.eventDetails:
        return Constants.SVGIcons.eventDetailsHeaderIcon;
      case SecondaryPageTitles.userDetails:
        return Constants.SVGIcons.userDetailsHeaderIcon;
    }
  };

  /**
   * Finds the user from the redux state depending on the "userId" from the query-params
   * @returns The name of the user for user-details page header
   */
  const getUserName = () => {
    const userDetails = users.find((user) => user.id === userId);
    return `${userDetails?.firstName} ${userDetails?.lastName}`;
  };

  // generate jsx for secondary header title
  const getSecondaryHeaderTitleJSX = (pageTitle: string) => (
    <>
      <span className={classes.headerIcon}>
        <SVGWrapper
          width={32}
          height={32}
          viewBox="0 0 32 32"
          id={`${id}-header-icon`}
        >
          {getHeaderIcon(pageTitle)}
        </SVGWrapper>
      </span>
      <span className={classes.secondaryHeaderTitleText}>
        {pageTitle === SecondaryPageTitles.deviceDetails &&
          (loading ? (
            <SkeletonLoader id={SkeletonLoaderIds.deviceName} />
          ) : (
            infoDetails?.name
          ))}
        {pageTitle === SecondaryPageTitles.eventDetails &&
          (eventDetailsState.loading ? (
            <SkeletonLoader id={SkeletonLoaderIds.eventName} />
          ) : (
            eventDetailsState.eventDetails?.name
          ))}
        {pageTitle === SecondaryPageTitles.userDetails &&
          (usersLoading ? (
            <SkeletonLoader
              id={SkeletonLoaderIds.userName}
              width={200}
              height={50}
            />
          ) : (
            getUserName()
          ))}
      </span>
    </>
  );

  // generate the title for secondary header
  const getSecondaryHeaderTitle = (): string | JSX.Element => {
    const tabTitle = (tabName as SecondaryPageTitleConfig).title;
    const transformable = (tabName as SecondaryPageTitleConfig).transformable;

    return transformable ? getSecondaryHeaderTitleJSX(tabTitle) : tabTitle;
  };

  // get header title
  const getHeaderTitle = (): string | JSX.Element =>
    isTabSecondary ? getSecondaryHeaderTitle() : (tabName as string);

  // for variable changes in .scss files
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      activeTheme == Themes.light ? Themes.light : Themes.dark
    );
  }, [activeTheme]);

  return (
    <AppBar className={`${classes.appBar} d-block`}>
      {isMobile ? (
        <ToolbarMobile
          id={id}
          pageTitle={getHeaderTitle()}
          isTabSecondary={isTabSecondary}
          handleBack={handleBack}
          pageTabs={pageTabs}
          activePageTab={activePageTab}
          setActivePageTab={setActivePageTab}
          headerButton={headerButton}
        />
      ) : (
        <>
          <Toolbar
            id={id}
            pageTitle={getHeaderTitle()}
            username={username}
            isTabSecondary={isTabSecondary}
            headerButton={headerButton}
            handleBack={handleBack}
          />
          {pageTabs && (
            <InPageTabs
              pageTabs={pageTabs}
              activePageTab={activePageTab}
              setActivePageTab={setActivePageTab}
            />
          )}
        </>
      )}
    </AppBar>
  );
};
