import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerBtn: {
    color: theme.newPalette.error[500],
    borderColor: theme.newPalette.error[500],
    "&.Mui-disabled path": {
      fill: theme.newColors.neutral[400],
    },
  },
  plusIcon: {
    marginRight: theme.pxToRem(4),
  },
}));

export default useStyles;
