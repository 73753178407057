import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

// Constants
import Constants from "@Constants";

// Utils
import Utils from "@Utils";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";
import useTab from "@Hooks/useTab";

type PaginationParams = {
  searchString?: string;
  otherQueryParams?: { [key: string]: any };
  next?: string;
};

type PaginationHook = {
  onPageChange: (newPage: number) => void;
  onRowSizeChange: (pageSize: number) => void;
  setNoOfRowsPerPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  noOfRowsPerPage: number;
  currentPage: number;
  resetParams: () => void;
};

/**
 * Hook to manage page number and page size change
 * @param searchString: current url's search param which should be passed along
 * @param queryParams: query params needed except page and page_size
 * @param next: value for next query param
 */
function usePagination({
  searchString = "",
  otherQueryParams = {},
  next,
}: PaginationParams): PaginationHook {
  const { lastActivePageTab } = useTab();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Tracks no of rows rendered per page
  const [noOfRowsPerPage, setNoOfRowsPerPage] = useState<number>(
    Constants.Pagination.pageSizeDefault
  );

  // Tracks navigated page number
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Tracks if user clicked browser back button
  const [backButtonClicked, setBackButtonClicked] = useState<boolean>(false);

  // Initialize useCustomHistory hook to access history
  const history = useCustomHistory();

  /**
   * Check if user navigated to a page manually
   * and check if query params are valid, then update the page number and page size
   */
  useEffect(() => {
    if (lastActivePageTab === "") {
      if (
        !Utils.isInvalidPageQueryParam(queryParams) &&
        !Utils.isPageSizeQueryParamInvalid(queryParams)
      ) {
        setCurrentPage(Utils.getPageNumberFromQueryParams(queryParams));
        setNoOfRowsPerPage(Utils.getPageSizeFromQueryParams(queryParams));
      }
    }
    return;
  }, []);

  // Update URL
  useEffect(() => {
    history.replace(
      Utils.getNewPath(
        location.pathname,
        searchString + Utils.getSearchStringToAppend(queryParams),
        {
          page: currentPage,
          page_size: noOfRowsPerPage,
        }
      )
    );
  }, [currentPage, noOfRowsPerPage]);

  // Track if user navigated using Browser back button
  useEffect(() => {
    window.onpopstate = () => {
      setBackButtonClicked(true);
    };
  }, [location.search, history.replace]);

  // If user navigates using back button, go to page number as assigned in Query params
  useEffect(() => {
    if (backButtonClicked) {
      setCurrentPage(Utils.getPageNumberFromQueryParams(queryParams));
      setNoOfRowsPerPage(Utils.getPageSizeFromQueryParams(queryParams));
    }
    setBackButtonClicked(false);
    return;
  }, [backButtonClicked]);

  const resetParams = () => {
    setCurrentPage(1);
    setNoOfRowsPerPage(Utils.getPageSizeFromQueryParams(queryParams));
  };

  /**
   * Handle changing page number
   * @param newPage
   */
  const onPageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    history.push(
      Utils.getNewPath(
        location.pathname,
        searchString,
        {
          ...otherQueryParams,
          page: newPage,
          page_size: noOfRowsPerPage,
        },
        next
      )
    );
  };

  /**
   * Handle changing row size per page
   * And changing to page number 1
   * @param pageSize
   */
  const onRowSizeChange = (pageSize: number): void => {
    setNoOfRowsPerPage(pageSize);
    history.push(
      Utils.getNewPath(
        location.pathname,
        searchString,
        { ...otherQueryParams, page: 1, page_size: pageSize },
        next
      )
    );
    setCurrentPage(1);
  };

  return {
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
    setNoOfRowsPerPage,
    noOfRowsPerPage,
    currentPage,
    resetParams,
  };
}

export default usePagination;
