import Constants from "@Constants/index";

const initialState: TenantUserState = {
  user: { id: "", email: "", firstName: "", lastName: "" },
  loading: false,
  error: "",
} as const;

/**
 * Fetching tenant user by user id
 * @param state TenantUserState
 * @param action GenericAction
 * @returns TenantUserState
 */
const fetchTenantUserByIdReducer = (
  state: TenantUserState = initialState,
  action: GenericAction
): TenantUserState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_TENANT_USER_BY_ID_REQUEST:
      return {
        ...initialState,
        loading: true,
        error: "",
      };
    case Constants.ActionTypes.FETCH_TENANT_USER_BY_ID_SUCCESS:
      return {
        user: action.payload,
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.FETCH_TENANT_USER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  }

  return state;
};

export default fetchTenantUserByIdReducer;
