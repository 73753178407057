import { makeStyles, Theme } from "@material-ui/core";

import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: theme.newColors.primary.poppyRed[500],
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: `${theme.pxToRem(6)} ${theme.pxToRem(8)}`,
    },
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: "100%",
    },
  },
}));

export default useStyles;
