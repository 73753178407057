import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  downloadButton: {
    background: theme.newColors.fieryOrange,
    color: theme.newColors.neutral[100],

    "&:hover": {
      background: theme.newPalette.colorTransformOnHover(
        theme.newColors.fieryOrange,
        0.1
      ),
    },
  },
  versionMetaDataContainer: {
    marginLeft: theme.pxToRem(8),
    textAlign: "left",
  },
  versionNumber: {
    fontWeight: theme.fontWeights.semiBold,
    // applying the color of modal, since this is child of a modal
    color: theme.newPalette.modal.primaryColor,
    fontSize: theme.pxToRem(14),
    textTransform: "uppercase",
  },
  latestVersion: {
    background: theme.newPalette.success[300],
    color: theme.newPalette.success[700],
    textTransform: "uppercase",
    marginTop: theme.pxToRem(6),
    padding: `${theme.pxToRem(4)} ${theme.pxToRem(8)}`,
    fontSize: theme.pxToRem(8),
    fontWeight: theme.fontWeights.bold,
    borderRadius: theme.pxToRem(10),
  },
}));

export default useStyles;
