import React from "react";
import { useSelector } from "react-redux";
import { Typography, useTheme } from "@material-ui/core";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";
import Timeline from "@Components/Timeline";

// Constants
import Constants from "@Constants";
import { ButtonIDs, SVGIconIds } from "@Constants/Id";

// Types
import { TimelineItems } from "@Components/Timeline/Timeline";

//styles
import useStyles from "./styles";

type VersionInfoProps = {
  downloadUrl: string;
  version: string;
  latest?: boolean;
  downloadButtonDisabled?: boolean;
  primaryDownloadBtn?: boolean;
};

const VersionInfo = ({
  downloadUrl,
  version,
  latest,
  downloadButtonDisabled,
}: VersionInfoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className="flex justify-between align-flex-start margin-bottom-15">
      <div className="flex align-flex-start">
        <SVGWrapper
          width={30}
          height={30}
          viewBox="0 0 30 30"
          id={SVGIconIds.appIcon}
        >
          {Constants.SVGIcons.appIcon}
        </SVGWrapper>
        <div className={classes.versionMetaDataContainer}>
          <Typography className={classes.versionNumber}>
            Version {version}
          </Typography>
          {latest && (
            <Typography component="span" className={classes.latestVersion}>
              Latest Version
            </Typography>
          )}
        </div>
      </div>
      <Button
        id={ButtonIDs.downloadClientVersion}
        extraClass={classes.downloadButton}
        disabled={downloadButtonDisabled}
        onClick={() => window.open(downloadUrl, "_blank")}
      >
        Download
      </Button>
    </div>
  );
};

const AgentVersions = (): JSX.Element => {
  // Get agent installer details state from redux store
  const {
    loading,
    currentVersion,
    downloadUrl,
    otherVersions,
  }: AgentInstallerDetailsState = useSelector(
    (state: GlobalState) => state.agentInstallerDetails
  );
  const theme = useTheme();

  const getTimelineItems = () => {
    const timelineItems: TimelineItems[] = [];
    if (currentVersion && downloadUrl)
      timelineItems.push({
        content: (
          <VersionInfo
            version={currentVersion}
            downloadUrl={downloadUrl}
            downloadButtonDisabled={loading}
            latest
          />
        ),
        bulletType: "dot",
      });
    Object.keys(otherVersions)?.forEach((version) => {
      timelineItems.push({
        content: (
          <VersionInfo
            version={version}
            downloadUrl={otherVersions[version]}
            downloadButtonDisabled={loading}
          />
        ),
        bulletType: "dot",
        currentDotColor: theme.lightTheme.secondary.mediumLight,
      });
    });
    return timelineItems;
  };

  return <Timeline dotVariant="outlined" timelineItems={getTimelineItems()} />;
};

export default AgentVersions;
