import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { AuthProvider } from "oidc-react";

// Redux store
import store from "@Store/index";

// Axios interceptor
import { AxiosInitialize } from "@Client/interceptor";

// Helpers
import getUserManager from "@Helpers/authProviderUserManager";

// Routes
import Routes from "@Routes/index";

// Containers
import Alert from "@Components/Alert";
import ErrorBoundary from "@Containers/errorBoundary";
import InactivityTracker from "@Containers/inactivityTracker";

// Constants
import { Themes } from "@Constants/common";

// Hooks
import useAppTheme from "@Hooks/useAppTheme";
import useTenantAccess from "@Hooks/useTenantAccess";

// Theme Configs
import theme from "@Theme/index";
import lightPalette from "@Theme/lightTheme";
import darkPalette from "@Theme/darkTheme";

// Styles
import "@Styles/styles.scss";

export const SSOProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { useHarbinger } = useTenantAccess();

  // If vanity DNS is used by the user, change the hostname of the user
  if (!useHarbinger) {
    // If REACT_APP_BASE_UI_HOSTNAME is '.manage.dev.halcyon.blue', then the desired hostname is 'manage.dev.halcyon.blue';
    const desiredHostName =
      process.env.REACT_APP_BASE_UI_HOSTNAME?.substring(1);
    window.location.hostname = desiredHostName as string;
  }

  const userManager = getUserManager();
  const { REACT_APP_USE_NATIVE_LOGIN } = process.env;

  return (
    <ErrorBoundary>
      {REACT_APP_USE_NATIVE_LOGIN ? (
        <AuthProvider>{children}</AuthProvider>
      ) : (
        <AuthProvider userManager={userManager}>{children}</AuthProvider>
      )}
    </ErrorBoundary>
  );
};

const AppWithTheme = () => {
  const { activeTheme } = useAppTheme();

  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        newPalette: activeTheme === Themes.light ? lightPalette : darkPalette,
      })}
    >
      <SSOProvider>
        <InactivityTracker>
          <>
            <CssBaseline />
            <Routes />
            <Alert />
            <AxiosInitialize />
          </>
        </InactivityTracker>
      </SSOProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Provider store={store.mainStore}>
      <Router>
        <PersistGate persistor={store.persistedStore}>
          <AppWithTheme />
        </PersistGate>
      </Router>
    </Provider>
  );
};

export default App;
