import Constants from "@Constants";
import Utils from "@Utils";

const initialState: DeviceExtractedKeysState = {
  loading: false,
  extractedKeys: [],
  error: "",
};

/**
 * Handles actions of fetching extracted keys for device
 * @param state DeviceExtractedKeysState
 * @param action GenericAction
 * @returns DeviceExtractedKeysState
 */
const deviceExtractedKeysReducer = (
  state: DeviceExtractedKeysState = initialState,
  action: GenericAction
): DeviceExtractedKeysState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_SUCCESS:
      return {
        loading: false,
        extractedKeys: action?.payload?.data,
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICE_EXTRACTED_KEYS_FAILED:
      return {
        loading: false,
        extractedKeys: [],
        error: action?.payload,
      };
  }
  return state;
};

export default deviceExtractedKeysReducer;
