import { makeStyles, Theme } from "@material-ui/core";

type StyleProps = {
  progress: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  button: {
    position: "relative",
    overflow: "hidden",
  },
  progress: ({ progress }) => ({
    position: "absolute",
    height: "100%",
    width: `${progress}%`,
    top: 0,
    left: 0,
    transition: "width 50ms ease-out",
  }),
  buttonText: {
    position: "relative",
    color: theme.newPalette.text.primary.main,
  },
}));

export default useStyles;
