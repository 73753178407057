import { Box, Typography } from "@material-ui/core";

// Components
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";

// Constants
import { SkeletonLoaderIds } from "@Constants/Id";

// Styles
import useStyles from "../../styles";

export const IdpConfigLoader = () => {
  const classes = useStyles();

  return (
    <>
      {Array.from(Array(6).keys()).map((key) => (
        <Box key={key} className={`d-flex ${classes.detailsRow}`}>
          <Typography className={classes.detailsHeader}>
            <SkeletonLoader width={150} height={22} />
          </Typography>
          <Typography>
            <SkeletonLoader width={200} height={22} />
          </Typography>
        </Box>
      ))}
      <Box className="d-flex flex-gap-6">
        <SkeletonLoader
          id={SkeletonLoaderIds.editIdpBtn}
          width={160}
          height={65}
        />
        <SkeletonLoader
          id={SkeletonLoaderIds.deleteIdpBtn}
          width={160}
          height={65}
        />
      </Box>
    </>
  );
};
