// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { SuccessMessages } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// Handles actions for fetching the user details
export const fetchUserDetails = () =>
  bindClientFunc({
    clientFunc: client.getUserDetails,
    onFailure: ActionTypes.FETCH_USER_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_USER_DETAILS_REQUEST,
    onSuccess: ActionTypes.FETCH_USER_DETAILS_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// Handles actions for fetching the user details through harbinger APIs
export const harbingerFetchUserDetails = () =>
  bindClientFunc({
    clientFunc: client.harbingerGetUserDetails,
    onFailure: ActionTypes.FETCH_USER_DETAILS_FAILED,
    onRequest: ActionTypes.FETCH_USER_DETAILS_REQUEST,
    onSuccess: ActionTypes.HARBINGER_FETCH_USER_DETAILS_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// handle update user details
export const updateUserDetails = ({
  email,
  fullName,
  isEulaAccepted,
}: UpdateUserDetailsPayload) =>
  bindClientFunc({
    clientFunc: client.updateUserDetails,
    onFailure: ActionTypes.UPDATE_USER_DETAILS_FAILED,
    onRequest: ActionTypes.UPDATE_USER_DETAILS_REQUEST,
    onSuccess: ActionTypes.UPDATE_USER_DETAILS_SUCCESS,
    params: [
      fullName,
      email,
      isEulaAccepted ? (isEulaAccepted as unknown as string) : "",
    ],
    handleSuccess: {
      message: SuccessMessages.licenseAccepted,
    },
  });

// handle update user details
export const harbingerUpdateUserDetails = (
  { firstName, lastName }: HarbingerUpdateUserDetailsPayload,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.harbingerUpdateUserProfile,
    onFailure: ActionTypes.UPDATE_USER_DETAILS_FAILED,
    onRequest: ActionTypes.UPDATE_USER_DETAILS_REQUEST,
    onSuccess: ActionTypes.UPDATE_USER_DETAILS_SUCCESS,
    params: [firstName, lastName],
    handleSuccess: {
      message: SuccessMessages.userDetailsUpdate,
      ...handleSuccess,
    },
  }) as unknown as Promise<BindClient>;

// handle update user password
export const updateUserPassword = ({
  currentPassword,
  newPassword,
}: UpdateUserPasswordPayload) =>
  bindClientFunc({
    clientFunc: client.updateUserPassword,
    onFailure: ActionTypes.UPDATE_USER_PASSWORD_FAILED,
    onRequest: ActionTypes.UPDATE_USER_PASSWORD_REQUEST,
    onSuccess: ActionTypes.UPDATE_USER_PASSWORD_SUCCESS,
    params: [currentPassword, newPassword],
  }) as unknown as Promise<BindClient>;

/**
 * Set User's Session preference
 * @param preference User preference {
 *   `askAgain`: Display Prompt on this device again
 *   `staySignedIn`: Session Preference
 * }
 */
export const setUserSessionPreference = (preference: {
  askAgain: boolean;
  staySignedIn: boolean;
}): GenericAction => ({
  type: ActionTypes.ADD_SESSION_PREFERENCE,
  payload: preference,
});

// Handles actions for fetching the user roles
export const getUserRoles = () =>
  bindClientFunc({
    clientFunc: client.getUserRoles,
    onFailure: ActionTypes.FETCH_USER_ROLES_FAILED,
    onRequest: ActionTypes.FETCH_USER_ROLES_REQUEST,
    onSuccess: ActionTypes.FETCH_USER_ROLES_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

/**
 * The action for setting the user active or inactive
 * @param userActive a boolean value denoting the user is active or not
 * @returns the action to be dispatched
 */
export const setUserActivity = (userActive: boolean): GenericAction => ({
  type: userActive ? ActionTypes.USER_ACTIVE : ActionTypes.CLEAR_USER_ACTIVITY,
});

// Fetch tenant user using user id.
export const fetchTenantUserByUserId = ({
  userId,
  tenantId,
}: FetchTenantUserByIdQueryParams) =>
  bindClientFunc({
    clientFunc: client.fetchTenantUserByUserId,
    onFailure: ActionTypes.FETCH_TENANT_USER_BY_ID_FAILED,
    onRequest: ActionTypes.FETCH_TENANT_USER_BY_ID_REQUEST,
    onSuccess: ActionTypes.FETCH_TENANT_USER_BY_ID_SUCCESS,
    params: [userId, tenantId],
  }) as unknown as Promise<BindClient>;
