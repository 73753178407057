import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.newColors.neutral[100],
    backgroundColor: theme.newColors.fieryOrange,
    fontSize: theme.pxToRem(15),
    width: theme.pxToRem(32),
    height: theme.pxToRem(32),
    marginRight: theme.pxToRem(14),
  },
}));

export default useStyles;
