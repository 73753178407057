import Constants from "@Constants";

// Initial state for deleting IDP
const initialState: DeleteIDPConfig = {
  loading: false,
  success: false,
  error: "",
};

/**
 * Handles actions for deleting IDP config
 * @param state DeleteIDPConfig
 * @param action GenericAction
 * @returns DeleteIDPConfig
 */
const deleteIDPConfig = (
  state: DeleteIDPConfig = initialState,
  action: GenericAction
): DeleteIDPConfig => {
  switch (action.type) {
    case Constants.ActionTypes.DELETE_IDP_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case Constants.ActionTypes.DELETE_IDP_CONFIG_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };
    case Constants.ActionTypes.DELETE_IDP_CONFIG_FAILED:
      return {
        loading: false,
        error: action?.payload,
        success: false,
      };
  }
  return state;
};

export default deleteIDPConfig;
