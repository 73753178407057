import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

// Components
import Button from "@Components/Button";

// Styles
import useStyles from "./ConfirmationDialog.styles";

// Types
import { ConfirmationDialogProps } from "./ConfirmationDialog";

// Constants
import { ButtonIDs } from "@Constants/Id";

/**
 * ConfirmationDialog Component - to show the confirmation dialog to the users
 *
 * @example Correct usage with the action buttons of the dialog clickable
 * ```ts
 * <ConfirmationDialog
 *  id="id"
 *  isOpen={isOpen}
 *  handleClose={handleClose}
 *  alertTitle="Alert Title"
 *  disabledButtons={false}
 *  confirmButtonText="Confirm"
 *  handleConfirmation={handleConfirmation}
 * />
 * ```
 *
 * @example Correct usage with the action buttons of the dialog disabled
 * ```ts
 * <ConfirmationDialog
 *  id="id"
 *  isOpen={isOpen}
 *  handleClose={handleClose}
 *  alertTitle="Alert Title"
 *  disabledButtons={true}
 *  confirmButtonText="Confirm"
 *  handleConfirmation={handleConfirmation}
 * />
 * ```
 */
export const ConfirmationDialog = ({
  id,
  isOpen = false,
  handleClose,
  alertTitle,
  alertDescription = null,
  cancelButtonText = "Cancel",
  confirmButtonText = null,
  handleConfirmation,
  disabledButtons = false,
  containerExtraClass = "",
  cancelButtonExtraClass = "",
  confirmButtonExtraClass = "",
  withModal = true,
}: ConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();

  const DialogBody = (): JSX.Element => (
    <>
      <DialogTitle className={`${classes.alertTitle} padding-0`}>
        {alertTitle}
      </DialogTitle>
      <DialogContent className={`padding-0 ${classes.dialogContent}`}>
        <DialogContentText className={classes.alertDescription}>
          <Typography variant="body2" component="span">
            {alertDescription}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-center padding-0">
        <Button
          id={ButtonIDs.cancelConfirmationDialog({ id })}
          onClick={handleClose}
          variant="outlined"
          color="secondary"
          disabled={disabledButtons}
          extraClass={cancelButtonExtraClass}
        >
          {cancelButtonText || "Cancel"}
        </Button>
        {confirmButtonText && (
          <Button
            id={ButtonIDs.confirmConfirmationDialog({ id })}
            disabled={disabledButtons}
            onClick={handleConfirmation}
            extraClass={`${classes.confirmButton} ${confirmButtonExtraClass}`}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </>
  );

  return withModal ? (
    <Dialog
      id={id}
      open={isOpen}
      onClose={handleClose}
      className={`${containerExtraClass} ${classes.dialog}`}
    >
      <DialogBody />
    </Dialog>
  ) : (
    <DialogBody />
  );
};
