import Constants from "@Constants";

const initialState: ExtractedKeyInfo = {
  loading: false,
  extractedKey: {},
  error: "",
};

/**
 * Handles actions of fetching extracted key info
 * @param state ExtractedKeyInfo
 * @param action GenericAction
 * @returns ExtractedKeyInfo
 */
const extractedKeyInfoReducer = (
  state: ExtractedKeyInfo = initialState,
  action: GenericAction
): ExtractedKeyInfo => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EXTRACTED_KEY_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EXTRACTED_KEY_INFO_SUCCESS:
      return {
        loading: false,
        extractedKey: action?.payload,
        error: "",
      };
    case Constants.ActionTypes.FETCH_EXTRACTED_KEY_INFO_FAILED:
      return {
        loading: false,
        extractedKey: {},
        error: action?.payload,
      };
    case Constants.ActionTypes.CLEAR_EXTRACTED_KEY_INFO_STATE:
      return initialState;
  }
  return state;
};

export default extractedKeyInfoReducer;
