import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// Hooks
import useCustomHistory from "./useCustomHistory";

// Utils
import Utils from "@Utils";

/**
 * This hook is used to manage filters on the tabs
 * @param state The initial state
 * @param pageParams - the default params that should be there while pushing the URL on to the browser history
 * @param blacklistParams - the query params that should not be considered to create the new query params
 */
const useFilterAndSorting = (
  state: Record<string, string | null>,
  pageParams = {},
  blacklistParams: string[] = []
) => {
  const [filters, setFilters] = useState(state);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  /**
   * If true, the URL will be replaced from the browser history instead of pushing it to the history
   * After replacing once, the "shouldReplace" state will be reset
   */
  const [shouldReplace, setShouldReplace] = useState(false);

  // Used to keep track of the page render cycle, if the page is rendered for the first time or not
  const pageRenderedForFirstTime = useRef(true);

  // History to update the URL
  const history = useCustomHistory();

  // Load the applied filters from the URL when the page loads for the first time
  useLayoutEffect(() => {
    let newState: Record<string, string | null> = filters;

    // Extract the values for the filters from the URL, if there are any
    queryParams.forEach((value, key) => {
      if (key in filters) {
        newState = { ...newState, [key]: value };
      }
    });

    setFilters(newState);
  }, []);

  /**
   * Update the query params when the filters change
   * Should not update on the first render because the state change can be because of extracting the values from the URL
   */
  useEffect(() => {
    // Don't push the URL, if the page is rendered for the first time
    if (pageRenderedForFirstTime.current) {
      pageRenderedForFirstTime.current = false;
      // Update the URL if there are any default filters applied
      history.replace(
        Utils.getNewPath(
          location.pathname,
          Utils.getSearchStringToAppend(queryParams, blacklistParams),
          {
            ...filters,
            ...pageParams,
          }
        )
      );
      return;
    }

    const newPath = Utils.getNewPath(
      location.pathname,
      Utils.getSearchStringToAppend(queryParams, blacklistParams),
      {
        ...filters,
        ...pageParams,
      }
    );

    // Update the URL when the applied filters change
    if (shouldReplace) {
      history.replace(newPath);
      setShouldReplace(false);
    } else {
      history.push(newPath);
    }
  }, [filters]);

  /**
   * Update the query-params when the location changes and we've filters applied but there aren't any in the URL
   */
  useEffect(() => {
    if (pageRenderedForFirstTime.current) {
      return;
    }

    // Check if the filters are applied but there aren't any in the URL
    const filtersMissingInURL = () =>
      Object.keys(filters).some(
        (filterKey) => !queryParams.has(filterKey) && filters[filterKey]
      );

    if (filtersMissingInURL()) {
      history.replace(
        Utils.getNewPath(
          location.pathname,
          Utils.getSearchStringToAppend(queryParams),
          {
            ...filters,
            ...pageParams,
          }
        )
      );
    }
  }, [location]);

  return {
    filters,
    setFilters,
    setShouldReplace,
  };
};

export default useFilterAndSorting;
