import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import PaginatedTable from "@Components/Table/PaginatedTable";
import Button from "@Components/Button";

// Containers
import AddAndEditUser from "./addAndEditUser";
import DeleteConfirmationDialog from "./deleteUser/confirmationDialog";

// icons
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// Actions
import { fetchTenantUsers } from "@Store/actions";

// Constants
import Constants from "@Constants";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import { ButtonIDs, TableIds } from "@Constants/Id";

// Hooks
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useCustomHistory from "@Hooks/useCustomHistory";

// Utils
import Utils from "@Utils";

// Styles
import useStyles from "./styles";

const Users = (): JSX.Element => {
  // Initialize hooks
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();
  const { canAccess } = usePermissionFilter();
  const history = useCustomHistory();

  // Initialize state variables
  // Track if modal is open/closed
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [isAddUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);

  // Track selected user
  const [selectedUser, setSelectedUser] = useState<EditUserInfo | null>(null);

  // Track if alert dialog is open/closed
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState<boolean>(false);

  // Get redux store
  const { users, pagination, loading } = useSelector(
    (state: GlobalState) => state?.tenantUsers
  );

  // Initialize styles
  const classes = useStyles();

  const { currentPage, noOfRowsPerPage, onPageChange, onRowSizeChange } =
    usePagination({});

  /**
   * close add or edit user modal
   */
  const closeModal = (): void =>
    isAddUserModalOpen ? setAddUserModalOpen(false) : setEditModalOpen(false);

  /**
   * Set data of selected user
   * @param data Record<string, string>
   */
  const setSelectedUserInfo = (data: RowData) => {
    const selectedUserInfo: EditUserInfo = {
      email: (data.email as CellData).value,
      userId: (data.id as CellData).value,
    };
    setSelectedUser(selectedUserInfo);
  };

  /**
   * Toggle delete alert dialog
   */
  const toggleDeleteConfirmationDialog = (): void => {
    setDeleteConfirmationDialogOpen(!isDeleteConfirmationDialogOpen);
  };

  /**
   * Handle fetching users list
   * Dispatch action to fetch devices data
   * Dispatch error toast in case of any error
   */
  const handleFetchUsers = () =>
    dispatch(
      fetchTenantUsers(
        {
          page: currentPage,
          pageSize: noOfRowsPerPage,
        },
        selectedTenant.selectedTenantId
      )
    );

  /**
   * Dispatch action to fetch devices data
   */
  useEffect(() => {
    if (selectedTenant.selectedTenantId.length) handleFetchUsers();
  }, [currentPage, noOfRowsPerPage, selectedTenant]);

  // Options config for Edit and Delete user actions
  const userOptionsConfig: TableOptionsConfig[] = [
    {
      label: "Delete",
      icon: <DeleteOutlineIcon />,
      onClick: (data: RowData) => {
        setSelectedUserInfo(data);
        toggleDeleteConfirmationDialog();
      },
      shouldRender: canAccess(FeatureToAccessTypeMap.users.delete),
    },
    // TODO: unhide during integrating edit tenant user flow
    // {
    //   label: "Edit",
    //   icon: <EditIcon />,
    //   onClick: (data: Record<string, string>) => {
    //     setSelectedUserInfo(data);
    //     toggleDrawer();
    //   },
    //   shouldRender: canAccess(FeatureToAccessTypeMap.users.update),
    // },
  ];

  // Show add user Modal
  const showAddModal = () => setAddUserModalOpen(true);

  // Handles when the user clicks on any row
  const handleRowClick = (rowData: RowData) =>
    history.push(`/user-details/${rowData.id.value}`, {
      from: [...(history.location.state.from || []), "/users"],
    });

  return (
    <>
      <div
        className={`text-align-right text-align-left-sm position-relative-sm ${classes.addNewUserBtnContainer}`}
      >
        <Button
          id={ButtonIDs.addUser}
          onClick={showAddModal}
          startIcon={<AddIcon />}
        >
          Add New User
        </Button>
      </div>
      <PaginatedTable
        id={TableIds.users}
        isLoading={loading}
        headers={Constants.TableColumnsConfig.users}
        rowsData={Utils.parseToRowDataType(
          Constants.TableColumnsConfig.users,
          users
        )}
        handleRowClick={handleRowClick}
        showPagination
        pagination={{
          noOfRowsPerPage: noOfRowsPerPage,
          totalRowsAvailable: pagination?.totalRowsAvailable,
          currentPageNumber: currentPage,
          onClickSetPageSize: (pageSize: number) => {
            onRowSizeChange(pageSize);
          },
          jumpToPage: (pageNumber: number) => {
            onPageChange(pageNumber);
          },
          lastPageNumber: pagination?.lastPageNumber,
        }}
        options={{
          list: userOptionsConfig,
          // TODO: When adding the functionality to update a user, add "canAccess" rule for update as well (using ||)
          shouldRender: canAccess(FeatureToAccessTypeMap.users.delete),
        }}
      />
      <AddAndEditUser
        isEditModalOpen={isEditModalOpen}
        isAddModalOpen={isAddUserModalOpen}
        editUserInfo={isEditModalOpen ? selectedUser : null}
        handleModalClose={closeModal}
        handleFetchUsers={handleFetchUsers}
      />
      <DeleteConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        handleClose={() => setDeleteConfirmationDialogOpen(false)}
        userId={selectedUser?.userId as string}
        handleDeleteSuccess={handleFetchUsers}
      />
    </>
  );
};

export default Users;
