import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    color: theme.newPalette.text.primary.main,
  },
  wideButton: {
    [theme.breakpoints.up(Breakpoints.tabletMin)]: {
      minWidth: theme.pxToRem(180),
    },
  },
}));

export default useStyles;
