import Constants from "@Constants";

const initialState: DownloadThreatState = {
  DownloadThreatloading: false,
  DownloadThreaterror: "",
};

/**
 * Handles actions of Downloading Threat
 * @param state DownloadThreatState
 * @param action GenericAction
 * @returns DownloadThreatState
 */
const downloadThreatReducer = (
  state: DownloadThreatState = initialState,
  action: GenericAction
): DownloadThreatState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_THREAT_DOWNLOAD_REQUEST:
      return {
        ...state,
        DownloadThreatloading: true,
      };
    case Constants.ActionTypes.FETCH_THREAT_DOWNLOAD_SUCCESS:
      return {
        DownloadThreatloading: false,
        DownloadThreaterror: "",
      };
    case Constants.ActionTypes.FETCH_THREAT_DOWNLOAD_FAILED:
      return {
        DownloadThreatloading: false,
        DownloadThreaterror: action?.payload,
      };
  }
  return state;
};

export default downloadThreatReducer;
