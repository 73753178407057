import Constants from "@Constants";

const initialState: SelectedRows = {
  rows: [],
  arePagesSelected: false,
};

/**
 * Handles selection of the rows in the table
 * @param state
 * @param action
 * @returns SelectedRows
 */
const selectedRowsReducer = (
  state: SelectedRows = initialState,
  action: GenericAction
): SelectedRows => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_ROW:
      return {
        rows: [...state.rows, action.payload],
        arePagesSelected: false,
      };
    case Constants.ActionTypes.REMOVE_ROW:
      return {
        rows: [
          ...state.rows.filter((rowId: string) => action.payload !== rowId),
        ],
        arePagesSelected: false,
      };
    case Constants.ActionTypes.SELECT_GIVEN_ROWS:
      return {
        ...state,
        rows: action.payload,
      };
    case Constants.ActionTypes.SELECT_ALL_PAGES:
      return {
        ...state,
        arePagesSelected: true,
      };
    case Constants.ActionTypes.RESET_SELECTED_ROWS:
      return {
        rows: [],
        arePagesSelected: false,
      };
    case Constants.ActionTypes.RESET_ALL_SELECTED_PAGES:
      return {
        rows: [],
        arePagesSelected: false,
      };
  }
  return state;
};

export default selectedRowsReducer;
