import Constants from "@Constants";

const initialState: EventMetricsState = {
  eventMetricsStateLoading: false,
  total: 0,
  allowed: 0,
  blocked: 0,
  sandboxed: 0,
  eventMetricsStateError: "",
};

const eventMetricsReducer = (
  state: EventMetricsState = initialState,
  action: GenericAction
): EventMetricsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EVENT_METRICS_REQUEST:
      return {
        ...state,
        eventMetricsStateLoading: true,
      };
    case Constants.ActionTypes.FETCH_EVENT_METRICS_SUCCESS:
      return {
        eventMetricsStateLoading: false,
        total: action?.payload?.total || 0,
        allowed: action?.payload?.allowed || 0,
        blocked: action?.payload?.blocked || 0,
        sandboxed: action?.payload?.sandboxed || 0,
        eventMetricsStateError: "",
      };
    case Constants.ActionTypes.FETCH_EVENT_METRICS_FAILED:
      return {
        eventMetricsStateLoading: false,
        total: 0,
        allowed: 0,
        blocked: 0,
        sandboxed: 0,
        eventMetricsStateError: action?.payload,
      };
  }
  return state;
};

export default eventMetricsReducer;
