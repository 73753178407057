// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { SuccessMessages } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

// Fetch export config
export const fetchExportConfig = ({ page, pageSize }: PageQueryParams): any =>
  bindClientFunc({
    clientFunc: client.getExportConfigs,
    onFailure: ActionTypes.FETCH_EXPORT_CONFIG_FAILED,
    onRequest: ActionTypes.FETCH_EXPORT_CONFIG_REQUEST,
    onSuccess: ActionTypes.FETCH_EXPORT_CONFIG_SUCCESS,
    params: [page.toString(), pageSize.toString()],
  });

// handle actions for updating an Export config
export const updateExportConfig = (
  {
    exportUrl,
    token,
    authHeaderKey,
    authHeaderValue,
    enabled,
  }: UpdateExportConfigPayload,
  configId: string,
  handleSuccess?: ApiSuccessReporting
): any =>
  bindClientFunc({
    clientFunc: client.updateExportConfigs,
    onFailure: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_FAILED,
    onRequest: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_REQUEST,
    onSuccess: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_SUCCESS,
    params: [
      exportUrl,
      token ?? "",
      authHeaderKey ?? "",
      authHeaderValue ?? "",
      enabled,
      configId,
    ],
    handleSuccess: {
      message: SuccessMessages.exportConfigUpdated,
      ...handleSuccess,
    },
  });

// Handle actions for creating an export config
export const createExportConfig = (
  {
    exportUrl,
    token,
    authHeaderKey,
    authHeaderValue,
    enabled,
  }: UpdateExportConfigPayload,
  handleSuccess?: ApiSuccessReporting
): any =>
  bindClientFunc({
    clientFunc: client.createExportConfigs,
    onFailure: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_FAILED,
    onRequest: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_REQUEST,
    onSuccess: ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_SUCCESS,
    params: [
      exportUrl,
      token ?? "",
      authHeaderKey ?? "",
      authHeaderValue ?? "",
      enabled,
    ],
    handleSuccess: {
      message: SuccessMessages.exportConfigCreated,
      ...handleSuccess,
    },
  });

// Handle actions for deleting a cloud config
export const deleteExportConfig = (configId: string): any =>
  bindClientFunc({
    clientFunc: client.deleteExportConfig,
    onFailure: ActionTypes.DELETE_EXPORT_CONFIG_FAILED,
    onRequest: ActionTypes.DELETE_EXPORT_CONFIG_REQUEST,
    onSuccess: ActionTypes.DELETE_EXPORT_CONFIG_SUCCESS,
    params: [configId],
  });
