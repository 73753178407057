import { InputAdornment, Typography } from "@material-ui/core";

// Components
import Button from "@Components/Button";
import SVGWrapper from "@Components/SVGWrapper";
import InputField from "@Components/InputField";

// Constants
import constants from "@Constants/index";
import { ButtonIDs, InputFieldIds, SVGIconIds } from "@Constants/Id";

// Styles
import useStyles from "@Containers/TagPopover/TagPopoverBody/styles";

type TagPopoverBodyHeaderProps = {
  searchValue: string;
  handleSettingsClick: () => void;
  handleInputSearchCHange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const TagPopoverBodyHeader = ({
  searchValue,
  handleSettingsClick,
  handleInputSearchCHange,
}: TagPopoverBodyHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={`d-flex justify-between ${classes.header}`}>
        <Typography variant="body2">Label as:</Typography>
        <Button
          id={ButtonIDs.tagPopoverBodySettings}
          variant="text"
          extraClass={`justify-end ${classes.settings}`}
          onClick={handleSettingsClick}
        >
          <SVGWrapper
            height={16}
            width={16}
            viewBox="0 0 24 24"
            id={SVGIconIds.settingsIcon}
          >
            {constants.SVGIcons.settings}
          </SVGWrapper>
        </Button>
      </div>
      <InputField
        id={InputFieldIds.searchTags}
        type="text"
        hiddenLabel
        variant="filled"
        value={searchValue}
        onChange={handleInputSearchCHange}
        InputProps={{
          className: classes.inputContainer,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SVGWrapper
                height={16}
                width={16}
                viewBox="0 0 16 16"
                id={SVGIconIds.searchIcon}
              >
                {constants.SVGIcons.search}
              </SVGWrapper>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default TagPopoverBodyHeader;
