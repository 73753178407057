import { Box, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Hooks
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";

// Types
import { RowSkeletonLoaderProps } from "./RowSkeletonLoader";

// Styles
import useStyles from "@Components/Table/PaginatedTable/PaginatedTable.styles";

/**
 * RowSkeletonLoader Component
 *
 * @example Correct usage
 * ```tsx
 * <RowSkeletonLoader
 *  headers={headers}
 * />
 * ```
 */
export const RowSkeletonLoader = ({ headers }: RowSkeletonLoaderProps) => {
  const classes = useStyles();
  const { isMobile } = useCustomMediaQuery();

  return (
    <>
      {Array.from(Array(12).keys()).map((val) => (
        <TableRow key={val} className={classes.row}>
          {headers.map(
            (header) =>
              !header.blacklist && (
                <Box
                  key={header.label}
                  component="td"
                  width={!isMobile ? header.maxWidth : "100%"}
                  padding="1rem"
                  className="flex-1"
                >
                  <Skeleton variant="rect" />
                </Box>
              )
          )}
        </TableRow>
      ))}
    </>
  );
};
