import Constants from "@Constants";
import Utils from "@Utils";

const initialState: TenantUsersState = {
  loading: false,
  users: [],
  error: "",
};

/**
 * Handles actions of fetching tenant users
 * @param state TenantUsersState
 * @param action GenericAction
 * @returns TenantUsersState
 */
const usersReducer = (
  state: TenantUsersState = initialState,
  action: GenericAction
): TenantUsersState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_TENANT_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_TENANT_USERS_SUCCESS:
      return {
        loading: false,
        users: action?.payload?.data,
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_TENANT_USERS_FAILED:
      return {
        loading: false,
        users: [],
        error: action?.payload,
      };
  }
  return state;
};

export default usersReducer;
