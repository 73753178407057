import Constants from "@Constants";
import { Themes } from "@Constants/common";

const initialState: ThemeState = {
  activeTheme: Themes.light,
};

// Handles change of theme
const themeReducer = (
  state: ThemeState = initialState,
  action: GenericAction
): ThemeState => {
  switch (action.type) {
    case Constants.ActionTypes.CHANGE_THEME:
      return {
        activeTheme: action.payload,
      };
  }

  return state;
};

export default themeReducer;
