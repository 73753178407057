import { useEffect, useRef, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { Typography, useTheme, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Range } from "react-date-range";

// Actions
import {
  fetchEventMetrics,
  fetchCriticalEvents,
  fetchPartitionedEventMetrics,
} from "@Store/actions";

// Components
import DashboardCard from "@Components/Card/DashboardCard";
import BaseCard from "@Components/Card";
import BarGraph from "@Components/Graphs/BarGraph";
import DatePicker from "@Components/DatePicker";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";
import PaginatedTable from "@Components/Table/PaginatedTable";

// Constants
import Constants from "@Constants";
import { DashboardEventCardTitles, DashboardPageTabs } from "@Constants/common";
import {
  EventsInitSearchConfig,
  EventsSearchParams,
  EventsTableAutoSuggestOptions,
  KeyForEventsTableAutoSuggest,
  SuggestionsForActionType,
} from "@Constants/tableSearchConst";
import {
  DefaultSortingFieldForEvents,
  DefaultSortingOrder,
  EventsTableSortingFields,
} from "@Constants/sorting";
import {
  CardIds,
  CommonIds,
  DashboardContainerIds,
  DatePickerIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  GraphsIds,
  SortDropdownGroupIds,
  SkeletonLoaderIds,
  TableIds,
} from "@Constants/Id";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";
import useTab from "@Hooks/useTab";

// Utils
import Utils from "@Utils";

// Styles
import useStyles from "@Containers/dashboard/styles";

const Events = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const partitionedEventMetrics = useSelector(
    (state: GlobalState) => state.partitionedEventMetrics
  );

  // Date-picker state
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 36)),
    endDate: new Date(),
  });
  const [changeDateRangeInUrl, setChangeDateRangeInUrl] = useState(false);

  // State for the search string in the filter auto-suggest reusable component
  const [searchString, setSearchString] = useState("");

  const {
    total,
    blocked,
    sandboxed,
    eventMetricsStateLoading,
    eventMetricsStateError,
  }: EventMetricsState = useSelector(
    (state: GlobalState) => state.eventMetrics
  );

  const getStatsNumberOrLoader = (
    statsNumber: string
  ): JSX.Element | string => {
    if (eventMetricsStateLoading) {
      return <SkeletonLoader id={SkeletonLoaderIds.eventStats} />;
    } else if (eventMetricsStateError) {
      return "";
    } else return statsNumber;
  };

  const barProperties: BarConfig = {
    fillColor: theme.newColors.fieryOrange,
    dataKey: "blocked",
    stackId: "events",
  };

  const history = useCustomHistory();

  const location = useLocation();

  const { selectedTenant } = useTenantAccess();
  // Get query params from location.
  const queryParams = new URLSearchParams(location.search);

  // Used to keep the track of the page render cycle i.e. whether the page is rendered for the first time.
  const pageRenderedForFirstTime = useRef(true);

  // Sorting
  const {
    filters: sort,
    setFilters: setSort,
    setShouldReplace,
  } = useFilterAndSorting(
    {
      sortBy: DefaultSortingFieldForEvents,
      sortOrder: DefaultSortingOrder,
    },
    {},
    ["attentionType"]
  );

  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(EventsInitSearchConfig);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
  } = usePagination({
    searchString: `tab=${Utils.getTabStringFromQueryParams(queryParams)}`,
    otherQueryParams: {
      startTime: Utils.getDateRangeFromQueryParam(queryParams).startTime,
      endTime: Utils.getDateRangeFromQueryParam(queryParams).endTime,
      ...searchConfig,
      ...sort,
    },
  });

  // To abort the fetch API request in case we want to make a new fetch API request with different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const tabDetails = useTab();

  // Get redux store
  const { events, pagination, loading }: CriticalEventsState = useSelector(
    (state: GlobalState) => state.criticalEvents
  );

  const handleDeviceClick = (data: RowData): string =>
    data.device_id
      ? generatePath(Constants.AppRoutes.ProtectedRoutes.DeviceDetails, {
          id: (data.device_id as CellData).value,
        })
      : "";

  const handleDateRangeChange = (range: Range) => {
    setDateRange(range);
    setChangeDateRangeInUrl(true);
  };

  const handleEventSubjectClick = (data: RowData): string => {
    const threatId = (data.threat_id as CellData).value;
    const eventId = (data.id as CellData).value;
    return threatId && eventId
      ? generatePath(Constants.AppRoutes.ProtectedRoutes.ThreatDetails, {
          threatId,
          eventId,
        })
      : "";
  };

  /**
   * Convert the date to UTC
   * @param date
   * @returns date in UTC
   */
  const convertDateToUTC = (date: Date) =>
    new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

  const getISOString = (dateString: Date) => {
    const date = new Date(dateString);
    const tzOffset = date.getTimezoneOffset() * 60 * 1000; // offset in milliseconds;

    // Convert date with offset to UTC and then to ISO format
    return convertDateToUTC(new Date(date.getTime() - tzOffset)).toISOString();
  };

  useEffect(() => {
    function getFetchCriticalEvents() {
      const startTime = getISOString(dateRange.startDate as Date);
      const endTime = getISOString(dateRange.endDate as Date);

      dispatch(
        fetchCriticalEvents(
          {
            page: currentPage,
            pageSize: noOfRowsPerPage,
            ...searchConfig,
          },
          sort.sortBy as string,
          sort.sortOrder as string,
          startTime,
          endTime,
          abortControllerRef.current.signal
        )
      );
    }
    // TODO: Remove the logic to abort the request when we have a way to handle calling the API only once
    if (selectedTenant.selectedTenantId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      getFetchCriticalEvents();
    }
  }, [
    currentPage,
    noOfRowsPerPage,
    dateRange,
    selectedTenant,
    sort,
    searchConfig,
  ]);

  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    setSearchString(
      (prev) => Utils.getSearchStringFromSearchConfig(searchConfig) ?? prev
    );
  }, [searchConfig]);

  useEffect(() => {
    // Change date range when back button is clicked
    const { startTime, endTime } =
      Utils.getDateRangeFromQueryParam(queryParams);
    if (startTime && endTime) {
      setChangeDateRangeInUrl(false);
      setDateRange((prevRange) => ({
        ...prevRange,
        startDate: new Date(startTime),
        endDate: new Date(endTime),
      }));
    }
  }, [location]);

  useEffect(() => {
    if (selectedTenant.selectedTenantId) {
      const startTime = getISOString(dateRange.startDate as Date);
      const endTime = getISOString(dateRange.endDate as Date);

      dispatch(fetchEventMetrics(startTime, endTime));
    }
  }, [dateRange, selectedTenant]);

  useEffect(() => {
    if (selectedTenant.selectedTenantId) {
      const startTime = getISOString(dateRange.startDate as Date);
      const endTime = getISOString(dateRange.endDate as Date);

      dispatch(fetchPartitionedEventMetrics(startTime, endTime));
    }
  }, [dateRange, selectedTenant]);

  // change URL when selected date-range changes
  useEffect(() => {
    const startTime = getISOString(dateRange.startDate as Date);
    const endTime = getISOString(dateRange.endDate as Date);

    if (changeDateRangeInUrl) {
      history.push(
        Utils.getNewPath(
          location.pathname,
          `tab=${Utils.getTabStringFromQueryParams(queryParams)}&` +
            Utils.getSearchStringToAppend(queryParams),
          {
            page: currentPage,
            page_size: noOfRowsPerPage,
            start_time: startTime,
            end_time: endTime,
          }
        )
      );
    }
  }, [dateRange]);

  // Whenever the tabs changes, reset the sorting filters so that they aren't applied to the next tab
  useEffect(() => {
    if (!tabDetails.currentlyActivePageTab) return;
    // Skip if the page is rendered for the first time
    if (pageRenderedForFirstTime.current) {
      pageRenderedForFirstTime.current = false;
      return;
    }
    // Replace the URL since we're resetting the defaults
    setShouldReplace(true);
    setSort({
      sortBy:
        tabDetails.currentlyActivePageTab !== DashboardPageTabs.Events
          ? null
          : DefaultSortingFieldForEvents,
      sortOrder:
        tabDetails.currentlyActivePageTab !== DashboardPageTabs.Events
          ? null
          : DefaultSortingOrder,
    });
    setSearchConfig((prev) =>
      tabDetails.currentlyActivePageTab === DashboardPageTabs.Events
        ? EventsInitSearchConfig
        : prev
    );
  }, [tabDetails.currentlyActivePageTab]);

  // add click handlers
  const headerWithClickHandlers = () => {
    const withDeviceNameClickHandler = Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.deviceName,
      Constants.TableColumnsConfig.events,
      null,
      handleDeviceClick
    );
    const withEventNameClickHandler = Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.name,
      withDeviceNameClickHandler,
      null,
      Utils.getEventDetailsUrlForTableCell
    );
    return Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.subject,
      withEventNameClickHandler,
      null,
      handleEventSubjectClick
    );
  };

  // get row data with value under name column discolored, if threat_id is not there with event
  const getRowsData = (): RowData[] => {
    const parsedRowsData = Utils.parseToRowDataType(
      Constants.TableColumnsConfig.events,
      events
    );
    return parsedRowsData.map((rowData) => {
      const threatId = rowData.threat_id as CellData;
      if (!threatId) {
        // get cell data of device name cell
        const eventNameCellData = rowData[
          Constants.TableColumnsConfig.EventsColumnNames.name
        ] as CellData;

        // add colored -> false, to not color if threat_id is null
        rowData[Constants.TableColumnsConfig.EventsColumnNames.name] = {
          ...eventNameCellData,
          colored: false,
        };
        return rowData;
      }
      return rowData;
    });
  };

  const graphColorStatsMap = {
    [Constants.DashboardEventCardTitles.total]: (color?: boolean) =>
      color ? theme.newColors.red : total,
    [Constants.DashboardEventCardTitles.blocked]: (color?: boolean) =>
      color ? theme.newColors.fieryOrange : blocked,
    [Constants.DashboardEventCardTitles.sandboxed]: (color?: boolean) =>
      color ? theme.newColors.warmYellow : sandboxed,
  };

  // get the graph color or the stats to show in the card
  const getGraphColorOrStats = (
    cardTitle: DashboardEventCardTitles,
    color?: boolean
  ) => graphColorStatsMap[cardTitle as DashboardEventCardTitles](color);

  // Handle changes in the applied sorting
  const handleSortingFilterChange = (
    sortBy: string,
    sortOrder: SortStateType
  ) => setSort({ sortBy, sortOrder });

  return (
    <>
      <div
        id={DashboardContainerIds.datePickerWrapper}
        className={`flex justify-between ${classes.datePickerWrapper}`}
      >
        <DatePicker
          id={DatePickerIds.datePickerForEvents}
          startDate={dateRange?.startDate}
          endDate={dateRange?.endDate}
          onChange={handleDateRangeChange}
        />
      </div>
      <Grid
        id={DashboardContainerIds.eventsStatsWrapper}
        container
        spacing={3}
        className="margin-bottom-25"
      >
        {Constants.DashboardCardsData.events.map((cardData) => (
          <Grid key={cardData.title} item xs={12} sm={6} md={6}>
            <DashboardCard
              id={CardIds.eventsStats({ title: cardData.title.toLowerCase() })}
              title={cardData.title}
              link={{
                text: cardData.linkTitle ?? "",
                url: cardData.redirectLink || "#",
              }}
              statsNumber={getStatsNumberOrLoader(
                Utils.addCommasToNumber(
                  getGraphColorOrStats(
                    cardData.title as DashboardEventCardTitles
                  )
                )
              )}
              graph={{
                data: partitionedEventMetrics.partitions,
                dataKey: cardData.dataKey,
                color: getGraphColorOrStats(
                  cardData.title as DashboardEventCardTitles,
                  true
                ) as string,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <BaseCard id={CardIds.eventsBarGraph} extraClass={classes.cardWrapper}>
        <Typography
          className={`margin-bottom-25 font-weight-bold ${classes.barGraphTitle}`}
        >
          Total events{" "}
          {partitionedEventMetrics.partitions[0] &&
            `from ${partitionedEventMetrics.partitions[0].when} to ${
              partitionedEventMetrics.partitions[
                partitionedEventMetrics.partitions.length - 1
              ].when
            }`}
        </Typography>
        <BarGraph
          id={GraphsIds.dashboardEventsBarGraph}
          showXAxisLine={false}
          showYAxisLine={false}
          data={partitionedEventMetrics.partitions}
          xAxisDataKeyName="when"
          barProperties={barProperties}
          axisTickColor={theme.newPalette.barGraph.color}
        />
      </BaseCard>
      <Typography
        id={DashboardContainerIds.eventsTableTitle}
        component="h2"
        className={`${classes.tableTitle} ${classes.tableTitleEvents}`}
      >
        Critical Events
      </Typography>
      <div
        id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
        className="margin-top-5 margin-bottom-15 flex-gap-16 d-flex justify-between"
      >
        <FilterAutoSuggest
          id={FilterAutoSuggestIds.dashboardEvents}
          value={null}
          options={{
            items: EventsTableAutoSuggestOptions,
            key: KeyForEventsTableAutoSuggest,
            renderOption: (option: Record<string, string>) => (
              <FilterAutoSuggestOption
                id={FilterAutoSuggestOptionIds.option({ label: option.label })}
                label={option.label}
                helperText={option.helperText}
              />
            ),
          }}
          inputValue={searchString}
          onInputValueChange={(newValue) => setSearchString(newValue)}
          placeholder="Search"
          onEnterKeyPress={() =>
            Utils.handleApplySearchForEvents(
              searchString,
              EventsTableAutoSuggestOptions,
              searchConfig,
              setSearchConfig,
              dispatch,
              setCurrentPage
            )
          }
          handleClearInput={() => setSearchConfig(EventsInitSearchConfig)}
          extraClass="flex-grow-1"
          suggestionConfigForValue={{
            fieldName: EventsSearchParams.actionType,
            suggestions: SuggestionsForActionType,
          }}
        />
        <SortDropdownGroup
          id={SortDropdownGroupIds.events}
          value={sort.sortBy ?? ""}
          sortState={(sort.sortOrder as "asc" | "desc") ?? null}
          options={EventsTableSortingFields}
          onChange={handleSortingFilterChange}
        />
      </div>
      <PaginatedTable
        id={TableIds.events}
        isLoading={loading}
        headers={headerWithClickHandlers()}
        rowsData={getRowsData()}
        showPagination
        pagination={{
          noOfRowsPerPage: noOfRowsPerPage,
          totalRowsAvailable: pagination?.totalRowsAvailable,
          currentPageNumber: currentPage,
          jumpToPage: (pageNumber: number) => {
            onPageChange(pageNumber);
          },
          onClickSetPageSize: (pageSize: number) => {
            onRowSizeChange(pageSize);
          },
          lastPageNumber: pagination?.lastPageNumber,
        }}
      />
    </>
  );
};

export default Events;
