import {
  Checkbox,
  FormControlLabel,
  ListItem,
  Typography,
} from "@material-ui/core";

// Components
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";

// Styles
import useStyles from "@Containers/TagPopover/TagPopoverBody/styles";

type TagItemProps = {
  suggestion: string;
  handleCheckInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  isChecked: boolean;
  isDisabled: boolean;
  loading?: boolean;
};

const TagItem = ({
  suggestion,
  handleCheckInputChange,
  isChecked,
  isDisabled,
  loading,
}: TagItemProps) => {
  const classes = useStyles();
  return (
    <ListItem
      className={`${classes.listItem} ${loading && "listItem-loading"}`}
      disableGutters
    >
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            value={suggestion}
            onChange={handleCheckInputChange}
            className={classes.checkBox}
            checked={isChecked}
            disabled={isDisabled || loading}
          />
        }
        label={
          loading ? (
            <SkeletonLoader width={160} />
          ) : (
            <Typography variant="body2">{suggestion}</Typography>
          )
        }
      />
    </ListItem>
  );
};

export default TagItem;
