import { Breakpoints } from "@Constants/common";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    width: "100%",

    "& .MuiFormHelperText-root": {
      position: "static",
    },
  },
  modal: {
    "& .MuiDialog-paper": {
      maxWidth: theme.pxToRem(635),
      overflow: "hidden",
    },
  },
  modalContent: {
    paddingTop: theme.pxToRem(10),
  },
  addNewUserBtnContainer: {
    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      zIndex: 1200,
      marginBottom: theme.pxToRem(20),
    },
  },
}));

export default useStyles;
