import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  optionsPopover: {
    "& .MuiPopover-paper": {
      backgroundColor: theme.newPalette.table.optionsPopoverBg,
      padding: 0,
      borderRadius: theme.pxToRem(6),
    },
  },
  optionItem: {
    padding: theme.pxToRem(12),
    fontSize: theme.pxToRem(13),
    color: theme.newPalette.text.primary.main,
    borderRadius: 0,
    "& svg": {
      color: theme.newPalette.text.primary.main,
      fontSize: theme.pxToRem(16),
      marginRight: theme.pxToRem(5),
    },
    "&:hover": {
      backgroundColor: theme.newPalette.table.optionsPopoverItemHoverBg,
    },
  },
  tableRowBorderLeft: {
    borderLeft: `${theme.pxToRem(1)} solid ${theme.colors.lightGrey}`,
    opacity: 1,
  },
  optionIcon: {
    width: theme.pxToRem(35),
    paddingLeft: 0,
    border: 0,
    "& .MuiSvgIcon-root": {
      color: theme.newPalette.text.primary.main,
      fontSize: theme.pxToRem(20),
      opacity: 0.5,
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        opacity: 1,
      },
    },
  },
  iconBtn: {
    "&.Mui-focusVisible .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
}));

export default useStyles;
