import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

// Components
import PaginatedTable from "@Components/Table/LabelValuePairTable";

// Actions
import { fetchThreatDetails } from "@Store/actions";

//Constants
import Constants from "@Constants";

// Utils
import Utils from "@Utils";
import { TableIds } from "@Constants/Id";

// Props type
type ThreatDetailsProps = {
  threatId: string;
  showThreatId?: boolean;
  fixedWidthColumnLabelValueConfig?: {
    keyName: string;
    width: number;
  };
  eventId: string;
  eventDetailsFetching?: boolean;
};

const ThreatDetails = ({
  eventId,
  showThreatId,
  threatId,
  eventDetailsFetching = false,
}: ThreatDetailsProps): JSX.Element => {
  // Get threat details state from redux store
  const threatDetails: ThreatDetailsState = useSelector(
    (state: GlobalState) => state.threatDetails
  );

  // Initialize hooks
  const dispatch = useDispatch();

  const handleThreatIdLinkClick = (data: RowData): string => {
    const threatIdFromData = data.id.value;
    return threatId && eventId
      ? generatePath(Constants.AppRoutes.ProtectedRoutes.ThreatDetails, {
          threatId: threatIdFromData,
          eventId,
        })
      : "";
  };

  const addIdIfShown = (
    labelConfig: Record<string, Omit<TableHeaderConfig, "name">>
  ) =>
    showThreatId
      ? {
          ...labelConfig,
          id: {
            label: "ID:",
            isColumnColored: true,
            showCopyIconOnHover: true,
            blacklist: false,
            renderAsLink: {
              getRedirectUrl: handleThreatIdLinkClick,
            },
          },
        }
      : labelConfig;

  // Trigger dispatching the action to fetch data, when the component loads
  useEffect(() => {
    if (threatId) {
      dispatch(fetchThreatDetails(threatId));
    }
  }, [threatId]);

  return (
    <PaginatedTable
      id={TableIds.threat}
      isLoading={threatDetails.loading || eventDetailsFetching}
      labelValuePairs={Utils.getLabelValuePairsForTable(
        threatDetails.threat,
        addIdIfShown(
          Constants.TableColumnsConfig.ThreatDetailsLabelConfigByFieldName
        )
      )}
      removeTableShadow
      labelWidth={140}
    />
  );
};

export default ThreatDetails;
