import { Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

// Actions
import { createTag, fetchTagList } from "@Store/actions";

// Components
import Button from "@Components/Button";
import InputField from "@Components/InputField";
import Modal from "@Components/Modal";

// Constants
import { CreateTagModalConstants } from "@Constants/common";
import Constants from "@Constants/index";
import { ButtonIDs, InputFieldIds, ModalIds } from "@Constants/Id";

// Helpers
import { createNewTagValidation } from "@Helpers/validation";

// Styles
import useStyles from "./styles";

type CreateTagModalProps = {
  isOpen: boolean;
  defaultValue: string;
  onClose: () => void;
  handleTogglePopover: () => void;
};

const CreateTagModal = ({
  isOpen,
  defaultValue,
  onClose,
}: CreateTagModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tagFormConfig = Constants.FormFields.TagConfig;

  const formik = useFormik({
    initialValues: {
      [tagFormConfig.createTag.name]: defaultValue,
    },
    validationSchema: createNewTagValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const tag = values[tagFormConfig.createTag.name];

      /**
       * Actions to be done after a tag is created successfully
       */
      const onCreateTag = () => {
        dispatch(fetchTagList());
        onClose();
      };

      // Create a tag
      dispatch(createTag(tag, { sideEffect: onCreateTag }));
    },
  });

  return (
    <Modal
      id={ModalIds.createTag}
      isOpen={isOpen}
      close={onClose}
      title={CreateTagModalConstants.modalTitle}
      modalTitleExtraClass={classes.modalTitle}
      modalContentExtraClass={classes.modalContent}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="body2" className={classes.label}>
          {tagFormConfig.createTag.confirmationModalDescription}
        </Typography>
        <InputField
          id={InputFieldIds.createTagModal}
          label={tagFormConfig.createTag.label}
          type="text"
          name={tagFormConfig.createTag.name}
          value={formik.values[tagFormConfig.createTag.name]}
          onChange={formik.handleChange}
          errorMessage={
            formik.touched[tagFormConfig.createTag.name] &&
            formik.errors[tagFormConfig.createTag.name]
          }
          autoComplete="off"
        />
        <div className={`flex justify-end ${classes.actionContainerModal}`}>
          <Button
            id={ButtonIDs.createTagConfirmationModalClose}
            size="large"
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id={ButtonIDs.createTagConfirmation}
            type="submit"
            size="large"
            color="primary"
            extraClass={classes.primaryButton}
            disabled={!formik.values[tagFormConfig.createTag.name]}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateTagModal;
