import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    padding: `0 ${theme.pxToRem(10)}`,
    height: theme.pxToRem(40),
    width: "100%",
  },
  checkbox: {
    padding: 0,
    marginRight: theme.pxToRem(10),
    "& .MuiIconButton-label": {
      color: theme.newPalette.labelPopover.checkbox,
    },
    "& .MuiSvgIcon-root": {
      width: theme.pxToRem(16),
      height: theme.pxToRem(16),
    },
  },
}));

export default useStyles;
