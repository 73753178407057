import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

// Components
import PaginatedTable from "@Components/Table/PaginatedTable";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";

// Containers
import DeviceInfoDetails from "@Containers/deviceInfoDetails";

// Constants
import Constants from "@Constants";
import {
  DevicesTableAutoSuggestOptions,
  KeyForDevicesTableAutoSuggest,
  FieldNameForSearchField,
  DevicesInitSearchConfig,
  tableSearchErrorMessages,
} from "@Constants/tableSearchConst";
import {
  DefaultSortingFieldForDevices,
  DefaultSortingOrder,
  DevicesTableSortingFields,
} from "@Constants/sorting";
import {
  CommonIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  SortDropdownGroupIds,
  TableIds,
} from "@Constants/Id";
import { Regex } from "@Constants/common";

// Utils
import Utils from "@Utils";

// Actions
import {
  fetchDevices,
  resetDeviceInfoDetails,
  resetAllSelectedPages,
  showAlert,
} from "@Store/actions";

// Hooks
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";

const Devices = (): JSX.Element => {
  // Initialize hooks
  const dispatch = useDispatch();
  const { isMobile } = useCustomMediaQuery();
  const { selectedTenant } = useTenantAccess();
  // State for the search string in the filter auto-suggest reusable component
  const [searchString, setSearchString] = useState("");

  // Initialize state variables
  // Track if device info details drawer is open/closed
  const [isDeviceInfoDetailsDrawerOpen, setDeviceInfoDetailsDrawerOpen] =
    useState<boolean>(false);

  // Stores id of device for which is selected to show its events in drawer
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  // Get redux store
  const { devices, pagination, loading } = useSelector(
    (state: GlobalState) => state?.devices
  );
  // Selected rows required for bulk tagging
  const { arePagesSelected } = useSelector(
    (state: GlobalState) => state.selectedRows
  );

  // Sorting
  const { filters: sort, setFilters: setSort } = useFilterAndSorting({
    sortBy: DefaultSortingFieldForDevices,
    sortOrder: DefaultSortingOrder,
  });

  // Searching
  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(DevicesInitSearchConfig);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
  } = usePagination({
    searchString: `sortBy=${sort.sortBy}&sortOrder=${sort.sortOrder}`,
  });

  // To abort the fetch API request in case we want to make a new fetch API request with different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  /**
   * Events for device info details drawer
   * Close drawer
   */
  const closeDeviceInfoDetailsDrawer = (): void => {
    setDeviceInfoDetailsDrawerOpen(false);
    setSelectedDeviceId(null);
  };

  /**
   * Update id of selected device
   * and toggle drawer
   * @param data
   */
  const handleDeviceClick = (data: any): string => {
    return generatePath(Constants.AppRoutes.ProtectedRoutes.DeviceDetails, {
      id: (data.id as CellData).value,
    });
  };

  /**
   * Dispatch action to fetch devices data
   * Dispatch error toast in case of any error
   */
  useEffect(() => {
    function getDevices() {
      /**
       *  If a tag searched is invalid, show a warning toast and the API request is not sent
       */
      if (searchConfig.tag && !Regex.IsValidTag.test(searchConfig.tag))
        return dispatch(
          showAlert({
            message: tableSearchErrorMessages.invalidTagSearched,
            severity: "warning",
          })
        );

      /**
       * If searched agent version has space in between, then shows a warning toast and the api request is not send.
       */
      if (
        searchConfig.agentVersion &&
        !Regex.NonWhiteSpace.test(searchConfig.agentVersion)
      )
        return dispatch(
          showAlert({
            message: tableSearchErrorMessages.invalidAgentVersionSearched,
            severity: "warning",
          })
        );

      dispatch(
        fetchDevices(
          {
            page: currentPage,
            pageSize: noOfRowsPerPage,
            ...sort,
            ...searchConfig,
          },
          abortControllerRef.current.signal
        )
      );
    }

    // TODO: Remove the logic to abort the request when we have a way to handle calling the API only once
    if (selectedTenant.selectedTenantId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      getDevices();
    }
  }, [currentPage, noOfRowsPerPage, selectedTenant, sort, searchConfig]);

  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    // Check if there are any filters applied
    if (
      Object.keys(searchConfig).some((searchKey) => !!searchConfig[searchKey])
    ) {
      let newSearchString = "";
      // Construct the search string
      Object.keys(searchConfig).forEach(
        (searchKey) =>
          !!searchConfig[searchKey] &&
          searchKey !== FieldNameForSearchField &&
          (newSearchString += ` ${searchKey}:"${searchConfig[searchKey]}"`)
      );
      if (searchConfig.search) {
        newSearchString += ` ${searchConfig.search}`;
      }
      setSearchString(newSearchString.trim());
    }
  }, [searchConfig]);

  // Info icon column
  const infoIconColumnConfig: TableIconColumnConfig = {
    onClick: (data: RowData) => {
      setSelectedDeviceId((data.id as CellData).value);
      setDeviceInfoDetailsDrawerOpen(true);
    },
    hidden: isMobile,
  };

  // Handle changes in the applied sorting
  const handleSortFilterChange = (
    sortBy: string | null,
    sortOrder: SortStateType
  ) => setSort({ sortBy, sortOrder });

  /**
   * Convert the search string to record and update the state for applied filters
   */
  const applySearch = () => {
    const searchFor = Utils.parseSearchString(
      searchString,
      DevicesTableAutoSuggestOptions.map((option) => option.field)
    );
    // Use lowercase for field name as we're using lower case for case insensitive matching of the field names
    setSearchConfig({
      tag: searchFor.tag ?? null,
      name: searchFor.name ?? null,
      operatingSystem: searchFor.operatingsystem ?? null,
      agentVersion: searchFor.agentversion ?? null,
      search: searchFor.search ?? null,
    });

    // On applying search the current page should reset to the first page.
    setCurrentPage(1);
  };

  // we are clearing the device info details so that in other devices the previously opened device state won't show up.
  useEffect(() => {
    dispatch(resetDeviceInfoDetails());
  }, []);

  return (
    <>
      {devices && (
        <>
          <div
            id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
            className="margin-top-5 margin-bottom-15 d-flex flex-gap-16 justify-between flex-column-sm"
          >
            <FilterAutoSuggest
              id={FilterAutoSuggestIds.devices}
              value={null}
              options={{
                items: DevicesTableAutoSuggestOptions,
                key: KeyForDevicesTableAutoSuggest,
                renderOption: (option: Record<string, string>) => (
                  <FilterAutoSuggestOption
                    id={FilterAutoSuggestOptionIds.option({
                      label: option.label,
                    })}
                    label={option.label}
                    helperText={option.helperText}
                  />
                ),
              }}
              inputValue={searchString}
              onInputValueChange={(newValue) => setSearchString(newValue)}
              placeholder="Search"
              onEnterKeyPress={applySearch}
              handleClearInput={() => setSearchConfig(DevicesInitSearchConfig)}
              extraClass="flex-grow-1"
            />
            <SortDropdownGroup
              id={SortDropdownGroupIds.devices}
              value={sort.sortBy ?? ""}
              sortState={(sort.sortOrder as "asc" | "desc") ?? null}
              options={DevicesTableSortingFields}
              onChange={handleSortFilterChange}
            />
          </div>
          <PaginatedTable
            id={TableIds.devices}
            isLoading={loading}
            headers={Utils.provideButtonOrLinkConfigToHeader(
              Constants.TableColumnsConfig.DevicesColumnNames.localName,
              Constants.TableColumnsConfig.devices,
              null,
              handleDeviceClick
            )}
            rowsData={Utils.parseToRowDataType(
              Constants.TableColumnsConfig.devices,
              devices
            )}
            showPagination={true}
            pagination={{
              noOfRowsPerPage: noOfRowsPerPage,
              totalRowsAvailable: pagination?.totalRowsAvailable,
              currentPageNumber: currentPage,
              jumpToPage: (pageNumber: number) => {
                onPageChange(pageNumber);
                if (arePagesSelected) dispatch(resetAllSelectedPages());
              },
              onClickSetPageSize: (pageSize: number) => {
                onRowSizeChange(pageSize);
              },
              lastPageNumber: pagination?.lastPageNumber,
            }}
            iconColumn={infoIconColumnConfig}
            selection={{
              isEnabled: true,
              customRowId: "id",
            }}
          />
        </>
      )}
      {selectedDeviceId && (
        <DeviceInfoDetails
          isOpen={isDeviceInfoDetailsDrawerOpen}
          handleClose={closeDeviceInfoDetailsDrawer}
          deviceId={selectedDeviceId}
        />
      )}
    </>
  );
};

export default Devices;
