import ActionTypes from "@Constants/actionTypes";

const initialState: AvailableTenantsState = {
  loading: false,
  data: [],
  error: "",
};

const availableTenantsReducer = (
  state: AvailableTenantsState = initialState,
  action: GenericAction
): AvailableTenantsState => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_TENANT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CURRENT_TENANT_INFO_SUCCESS:
      return {
        loading: false,
        data: action.payload.data ?? action.payload,
        error: "",
      };
    case ActionTypes.GET_CURRENT_TENANT_INFO_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
  }
  return state;
};

export default availableTenantsReducer;
