import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Breakpoints } from "@Constants/common";

type StyleArgs = {
  width?: number;
  inHorizontalTable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    fontSize: ({ inHorizontalTable }: StyleArgs) =>
      inHorizontalTable ? theme.pxToRem(13) : theme.pxToRem(11),
    color: theme.newPalette.text.secondary.main,
    textTransform: "uppercase",
    ...theme.utils.semiBoldText(),
    border: 0,
    whiteSpace: "nowrap",
    paddingLeft: ({ inHorizontalTable }: StyleArgs) =>
      inHorizontalTable ? 0 : "",

    [theme.breakpoints.down(Breakpoints.desktopMin)]: {
      padding: theme.pxToRem(8),
      lineHeight: "130%",
    },
  },
  fixedWidth: {
    width: ({ width }: StyleArgs) => theme.pxToRem(width || 150),
    minWidth: ({ width }: StyleArgs) => theme.pxToRem(width || 150),
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      maxWidth: theme.pxToRem(120),
      minWidth: `${theme.pxToRem(120)} !important`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));

export default useStyles;
