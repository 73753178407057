import { Box, Chip, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

// Components
import Tooltip from "@Components/Tooltip";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import UndoTagRemoval from "./subComponent/UndoTagRemoval";

// Containers
import TagPopover from "@Containers/TagPopover";

// Constants
import { SkeletonLoaderIds } from "@Constants/Id";

// Types
import { TagGroupProps } from "./TagGroup";

// Constants
import { TagGroupIds } from "@Constants/Id";

// Styles
import useStyles from "./TagGroup.styles";

/**
 * TagGroup Component
 *
 * @example Correct usage
 * ```tsx
 *  <TagGroup
 *   tags={tags}
 *   onTagDelete={handleTagDelete}
 *   isDeleteProgress={isDeleteProgress}
 *   isLoading={loading}
 * />
 * ```
 */
export const TagGroup = ({
  tags,
  onTagDelete,
  isDeleteProgress = false,
  isLoading = false,
}: TagGroupProps) => {
  const classes = useStyles();
  const appTheme = useTheme();

  return (
    <Box
      id={TagGroupIds.component}
      component="ul"
      className={`d-flex ${classes.root}`}
    >
      {tags.map((tag) => (
        <li key={tag}>
          <Tooltip
            title={tag}
            placement="top"
            arrow
            textColor={appTheme.newPalette.text.primary.main}
            backgroundColor={appTheme.newPalette.card.background}
            tooltipChildren={
              <Chip
                id={TagGroupIds.tagChip({ tag })}
                className={classes.chip}
                label={tag}
                disabled={isDeleteProgress}
                onDelete={() => onTagDelete(tag)}
              />
            }
          />
        </li>
      ))}
      <li>
        {!isLoading ? (
          <TagPopover>
            <Chip
              id={TagGroupIds.addTag}
              className={`${classes.chip} ${
                tags.length > 0
                  ? classes.addChipWithoutTag
                  : classes.addChipWithTag
              }`}
              {...(tags.length === 0 && {
                label: "Add Label",
              })}
              clickable
              icon={<AddIcon />}
            />
          </TagPopover>
        ) : (
          <div
            className={`${
              tags.length
                ? classes.skeletonLoaderRoundWrapper
                : classes.skeletonLoaderWrapper
            }`}
          >
            <SkeletonLoader
              variant="rect"
              className={classes.skeletonLoader}
              id={SkeletonLoaderIds.tagChip}
            />
          </div>
        )}
      </li>
      <UndoTagRemoval />
    </Box>
  );
};
