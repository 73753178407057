import { useMediaQuery, useTheme } from "@material-ui/core";

// Constants
import { Breakpoints } from "@Constants/common";

export type CustomMediaQuery = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isExtraLargeDesktop: boolean;
};

const useCustomMediaQuery = (): CustomMediaQuery => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(Breakpoints.mobileMin));
  const isTablet = useMediaQuery(
    theme.breakpoints.between(Breakpoints.tabletMin, Breakpoints.tabletMin)
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up(Breakpoints.desktopMin));
  const isLargeDesktop = useMediaQuery(
    theme.breakpoints.up(Breakpoints.largeDesktopMin)
  );
  const isExtraLargeDesktop = useMediaQuery(
    theme.breakpoints.up(Breakpoints.extraLargeDesktopMin)
  );
  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isExtraLargeDesktop,
  };
};

export default useCustomMediaQuery;
