import React from "react";
import clsx from "clsx";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

// Styles
import useStyles from "./RadioInput.styles";

// Types
import { RadioGroupProps } from "./RadioInput";

const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioIconButton}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

/**
 * RadioGroup component - used to render radio buttons
 *
 * @example Correct usage without default value
 * ```ts
 * <RadioGroup
 *  radioInputConfigs={radioInputConfigs}
 *  onChangeHandler={handleRadioInputChange}
 * />
 * ```
 *
 * @example Correct usage with default value
 * ```ts
 * <RadioGroup
 *  radioInputConfigs={radioInputConfigs}
 *  onChangeHandler={handleRadioInputChange}
 * />
 * ```
 */
export const RadioGroup = ({
  id,
  radioInputConfigs,
  defaultValue,
  radioGroupName,
  onChangeHandler,
}: RadioGroupProps): JSX.Element => {
  const classes = useStyles();

  const radioInputs = radioInputConfigs.map((radio) => (
    <FormControlLabel
      id={`${radio.label.toLowerCase().replace(/\s/g, "-")}-radio-label`}
      key={radio.label}
      className={classes.radioLabel}
      value={radio.value}
      control={<StyledRadio />}
      label={radio.label}
      classes={{ label: classes.radioLabelText }}
    />
  ));

  /**
   * The handler for handling the changes in the radio input
   * @param event - the event fired when the selected radio input changes
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChangeHandler(event.target.value);

  return (
    <div className={classes.optionsWrapper} id={id}>
      <FormControl component="fieldset" id={`${id}-form-control`}>
        <MuiRadioGroup
          value={defaultValue || radioInputConfigs[0]?.value}
          name={radioGroupName || "radio-inputs"}
          onChange={handleChange}
          id={`${id}-radio-group`}
        >
          {radioInputs}
        </MuiRadioGroup>
      </FormControl>
    </div>
  );
};
