import { TableBody, TableRow } from "@material-ui/core";

// Components
import EmptyState from "@Components/EmptyState";
import TableContainer from "../TableContainer";
import Headers from "../Headers";
import Cell from "../Cells/Cell";
import TableSkeleton from "../TableSkeleton";

// Types
import { LabelValuePairTableProps } from "./LabelValuePairTable";

// Constants
import { EmptyStateIds } from "@Constants/Id";

/**
 * LabelValuePairTable Component
 * @example Correct usage
 * ```tsx
 * <LabelValuePairTable
 *  labelValuePairs={labelValuePairs}
 *  isLoading={isLoading}
 * />
 * ```
 */
export const LabelValuePairTable = ({
  id,
  labelValuePairs,
  labelWidth,
  extraClass = "",
  showBorderAroundCell = false,
  boldLabel = false,
  removeTableShadow = false,
  isLoading = false,
}: LabelValuePairTableProps): JSX.Element => {
  return labelValuePairs.length !== 0 || isLoading ? (
    <TableContainer
      id={id}
      removeTableShadow={removeTableShadow}
      extraClass={extraClass}
      applyMinHeight={labelValuePairs.length === 0}
    >
      <TableBody>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          labelValuePairs.length != 0 &&
          labelValuePairs.map(({ label, value }) => (
            <TableRow key={label.label}>
              <Headers
                width={labelWidth}
                headers={[
                  {
                    ...label,
                    bold: (label.bold || boldLabel) && label.bold !== false,
                  },
                ]}
                inHorizontalTable
              />
              <Cell
                cellData={{
                  ...value,
                  capitalized: value.capitalized || label.capitalized,
                }}
                header={label}
                borderAroundCell={showBorderAroundCell}
                rowData={{ [label.name]: value }}
                showCopyIcon={label.showCopyIconOnHover}
                truncateConfig={label.truncateConfig}
                cssTruncate={false}
                inHorizontalTable
              />
            </TableRow>
          ))
        )}
      </TableBody>
    </TableContainer>
  ) : (
    <EmptyState id={EmptyStateIds.labelValuePair} />
  );
};
