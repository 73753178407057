import { makeStyles, Theme } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    fontSize: theme.pxToRem(14),
    marginRight: theme.pxToRem(20),
    color: theme.newPalette.header.color,
    textDecoration: "none",
    padding: 0,
    "& svg": {
      marginRight: theme.pxToRem(10),
      height: theme.pxToRem(22),
      width: theme.pxToRem(16),
      transform: "rotate(90deg)",
      transition: "ease all 200",
      "& path": {
        fill: theme.newPalette.header.color,
      },
    },
    "&:hover": {
      background: "none",
      color: theme.newPalette.header.color,
      "& svg": {
        transform: "rotate(90deg) scale(1.1)",
      },
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      marginLeft: theme.pxToRem(-16),
    },
  },
}));

export default useStyles;
