import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

// Components
import PaginatedTable from "@Components/Table/PaginatedTable";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";

// Utils
import Utils from "@Utils";

// Constants
import Constants from "@Constants";
import {
  DefaultSortingFieldForEvents,
  DefaultSortingOrder,
  EventsTableSortingFields,
} from "@Constants/sorting";
import {
  EventsTableAutoSuggestOptions,
  FieldNameForSearchField,
  KeyForEventsTableAutoSuggest,
  EventsInitSearchConfig,
  SuggestionsForActionType,
  EventsSearchParams,
} from "@Constants/tableSearchConst";
import {
  CommonIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  SortDropdownGroupIds,
  TableIds,
} from "@Constants/Id";

// Hooks
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";

// Actions
import { fetchEvents } from "@Store/actions";

const Events = (): JSX.Element => {
  // Initialize hooks
  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();
  const [searchString, setSearchString] = useState("");

  // Get redux store
  const { events, pagination, loading } = useSelector(
    (state: GlobalState) => state?.events
  );

  // Sorting
  const { filters: sort, setFilters: setSort } = useFilterAndSorting({
    sortBy: DefaultSortingFieldForEvents,
    sortOrder: DefaultSortingOrder,
  });

  // Searching
  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(EventsInitSearchConfig);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
  } = usePagination({
    searchString: `sortBy=${sort.sortBy}&sortOrder=${sort.sortOrder}`,
  });

  // To abort the fetch API request in case we want to make a new fetch API request with different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const handleEventSubjectClick = (data: RowData): string => {
    const threatId = (data.threat_id as CellData).value;
    const eventId = (data.id as CellData).value;
    return threatId && eventId
      ? generatePath(Constants.AppRoutes.ProtectedRoutes.ThreatDetails, {
          threatId: threatId,
          eventId: (data.id as CellData).value,
        })
      : "";
  };

  const handleDeviceNameClick = (data: RowData): string =>
    generatePath(Constants.AppRoutes.ProtectedRoutes.DeviceDetails, {
      id: (data.device_id as CellData).value,
    });

  /**
   * Dispatch action to fetch devices data
   * Dispatch error toast in case of any error
   */
  useEffect(() => {
    async function getFetchEvents() {
      dispatch(
        fetchEvents(
          {
            page: currentPage,
            pageSize: noOfRowsPerPage,
            ...sort,
            ...searchConfig,
          },
          abortControllerRef.current.signal
        )
      );
    }
    // TODO: Remove the logic to abort the request when we have a way to handle calling the API only once
    if (selectedTenant.selectedTenantId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      getFetchEvents();
    }
  }, [currentPage, noOfRowsPerPage, selectedTenant, sort, searchConfig]);

  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    // Check if there are any filters applied
    if (
      Object.keys(searchConfig).some((searchKey) => !!searchConfig[searchKey])
    ) {
      let newSearchString = "";
      // Construct the search string
      Object.keys(searchConfig).forEach(
        (searchKey) =>
          !!searchConfig[searchKey] &&
          searchKey !== FieldNameForSearchField &&
          (newSearchString += ` ${searchKey}:"${searchConfig[searchKey]}"`)
      );
      if (searchConfig.search) {
        newSearchString += ` ${searchConfig.search}`;
      }
      setSearchString(newSearchString.trim());
    }
  }, [searchConfig]);

  const getConfiguredHeaders = (): TableHeaderConfig[] => {
    const withDeviceNameClickHandler = Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.deviceName,
      Constants.TableColumnsConfig.events,
      null,
      handleDeviceNameClick
    );
    const withEventNameClickHandler = Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.name,
      withDeviceNameClickHandler,
      null,
      Utils.getEventDetailsUrlForTableCell
    );
    return Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsColumnNames.subject,
      withEventNameClickHandler,
      null,
      handleEventSubjectClick
    );
  };

  const getRowsData = (): RowData[] => {
    const parsedRowsData = Utils.parseToRowDataType(
      Constants.TableColumnsConfig.events,
      events
    );
    return parsedRowsData.map((rowData) => {
      const threatId = (rowData.threat_id as CellData).value;
      if (!threatId) {
        // get cell data of device name cell
        const deviceNameCellData = rowData[
          Constants.TableColumnsConfig.EventsColumnNames.name
        ] as CellData;

        // add colored -> false, to not color if threat_id is null
        rowData[Constants.TableColumnsConfig.EventsColumnNames.name] = {
          ...deviceNameCellData,
          colored: false,
        };
        return rowData;
      }
      return rowData;
    });
  };

  // Handle change in the applied sorting
  const onChangeHandler = (sortBy: string, sortOrder: SortStateType) =>
    setSort({ sortBy, sortOrder });

  return (
    <>
      {events && (
        <>
          <div
            id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
            className="margin-top-5 margin-bottom-15 flex-gap-16 d-flex justify-between flex-column-sm"
          >
            <FilterAutoSuggest
              id={FilterAutoSuggestIds.events}
              value={null}
              options={{
                items: EventsTableAutoSuggestOptions,
                key: KeyForEventsTableAutoSuggest,
                renderOption: (option: Record<string, string>) => (
                  <FilterAutoSuggestOption
                    id={FilterAutoSuggestOptionIds.option({
                      label: option.label,
                    })}
                    label={option.label}
                    helperText={option.helperText}
                  />
                ),
              }}
              inputValue={searchString}
              onInputValueChange={(newValue) => setSearchString(newValue)}
              placeholder="Search"
              onEnterKeyPress={() =>
                Utils.handleApplySearchForEvents(
                  searchString,
                  EventsTableAutoSuggestOptions,
                  searchConfig,
                  setSearchConfig,
                  dispatch,
                  setCurrentPage
                )
              }
              handleClearInput={() => setSearchConfig(EventsInitSearchConfig)}
              extraClass="flex-grow-1"
              suggestionConfigForValue={{
                fieldName: EventsSearchParams.actionType,
                suggestions: SuggestionsForActionType,
              }}
            />
            <SortDropdownGroup
              id={SortDropdownGroupIds.events}
              value={sort.sortBy ?? ""}
              sortState={(sort.sortOrder as "asc" | "desc") ?? null}
              options={EventsTableSortingFields}
              onChange={onChangeHandler}
            />
          </div>
          <PaginatedTable
            id={TableIds.events}
            isLoading={loading}
            headers={getConfiguredHeaders()}
            rowsData={getRowsData()}
            showPagination
            pagination={{
              noOfRowsPerPage: noOfRowsPerPage,
              totalRowsAvailable: pagination?.totalRowsAvailable,
              currentPageNumber: currentPage,
              jumpToPage: (pageNumber: number) => {
                onPageChange(pageNumber);
              },
              onClickSetPageSize: (pageSize: number) => {
                onRowSizeChange(pageSize);
              },
              lastPageNumber: pagination?.lastPageNumber,
            }}
          />
        </>
      )}
    </>
  );
};

export default Events;
