import React from "react";

import ThreatDetailsContainer from "@Containers/threatDetail";
import ErrorBoundary from "@Containers/errorBoundary";

const ThreatDetails = (): JSX.Element => (
  <ErrorBoundary>
    <ThreatDetailsContainer />
  </ErrorBoundary>
);

export default ThreatDetails;
