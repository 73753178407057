import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  tabButton: {
    ...theme.utils.buttonLarge(),
    color: theme.newPalette.header.inActiveTabColor,
    backgroundColor: "transparent",
    textTransform: "uppercase",
    "&:hover": {
      background: "transparent",
      color: theme.newPalette.header.inActiveTabColor,
    },

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: `${theme.pxToRem(8)} ${theme.pxToRem(16)}`,
    },
  },
  tabActive: {
    backgroundColor: theme.newPalette.header.activeTabBg,
    color: theme.newPalette.header.activeTabColor,
    "&:hover": {
      background: theme.newPalette.header.activeTabBg,
      color: theme.newPalette.header.activeTabColor,
    },
  },
  disabledStyled: {
    "&.MuiButton-root.Mui-disabled": {
      opacity: 0.5,
    },

    "&.MuiButton-root.Mui-disabled.MuiButton-contained": {
      opacity: 0.9,
      backgroundColor: `${theme.newPalette.button.containedDisabledBg} !important`,
      borderColor: `${theme.newPalette.button.disabledBorder} !important`,
    },

    "&.MuiButton-root.Mui-disabled.MuiButton-outlined": {
      backgroundColor: `transparent !important`,
      borderColor: `${theme.newPalette.button.disabledBorder} !important`,
    },

    "&.MuiButton-root.Mui-disabled.MuiButton-text": {
      backgroundColor: `transparent !important`,
      border: `0 !important`,
    },
  },
}));

export default useStyles;
