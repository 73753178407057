// All forms configs are defined here

// Add user form config
const AddAndEditUser: AddUserForm = {
  username: {
    name: "username",
    label: "Username",
    maxCharacters: 255,
    validationMessages: {
      required: "Username required",
      regex: "Enter a valid username",
      length: "Length must be less than 255 characters",
    },
  },
  email: {
    name: "email",
    label: "Email",
    maxCharacters: 255,
    validationMessages: {
      required: "Email required",
      regex: "Enter a valid email",
      length: "Length must be less than 255 characters",
    },
  },
  fullName: {
    name: "fullName",
    label: "Full Name",
    maxCharacters: 255,
    validationMessages: {
      required: "Full name required",
      regex: "Enter a valid full name",
      length: "Length must be less than 255 characters",
    },
  },
  password: {
    label: "Password",
    name: "password",
    validationMessages: {
      required: "Password required",
      regex:
        'Password must be 8 characters long and contain at least 1 upper case letter, 1 special character [!#$%&?"] and 1 number',
    },
  },
  confirmPassword: {
    label: "Repeat Password",
    name: "repeatPassword",
    validationMessages: {
      required: "Repeat password required",
      mismatch: "Password mismatch",
    },
  },
};

const UpdateProfile = {
  firstName: {
    name: "firstName",
    label: "First Name",
    maxCharacters: 255,
    validationMessages: {
      required: "First name required",
      regex: "Enter a valid First Name",
      length: "Length must be less than 255 characters",
    },
  },
  lastName: {
    name: "lastName",
    label: "Last Name",
    maxCharacters: 255,
    validationMessages: {
      required: "Last name required",
      regex: "Enter a valid Last Name",
      length: "Length must be less than 255 characters",
    },
  },
};

// Add or Edit tenant form config
const AddTenant: TenantForm = {
  fullName: {
    name: "name",
    label: "Tenant Name",
    maxCharacters: 255,
    minCharacters: 3,
    validationMessages: {
      required: "Tenant name required",
      length: "Length must be between 3 to 255 characters",
    },
  },
  alias: {
    name: "alias",
    label: "Tenant Alias",
    maxCharacters: 32,
    minCharacters: 2,
    validationMessages: {
      required: "Tenant name required",
      length: "Length must be between 2 to 32 characters",
    },
  },
};

// Update password form
const UpdatePassword: UpdatePasswordForm = {
  oldPassword: {
    label: "Current Password",
    name: "currentPassword",
    validationMessages: {
      required: "Current Password required",
    },
  },
  newPassword: {
    label: "New Password",
    name: "newPassword",
    validationMessages: {
      required: "New Password required",
      regex:
        'Password must be 8 characters long and contain at least 1 upper case letter, 1 special character [!#$%&?"] and 1 number',
    },
  },
  confirmPassword: {
    label: "Repeat Password",
    name: "repeatPassword",
    validationMessages: {
      required: "Repeat Password required",
      mismatch: "Password mismatch",
    },
  },
};

// Enter Tenant name
const TenantName: TenantNameForm = {
  validationMessage: {
    required: "Tenant name is required to proceed",
    invalid: "Tenant name is invalid",
  },
};

// Update or create export config
const CreateOrUpdateExportConfig: ExportConfigForm = {
  exportUrl: {
    name: "exportUrl",
    label: "Export URL*",
    placeholder: "https://...",
    validationMessages: {
      required: "Export URL is required",
      validURL: "Please enter a valid URL",
    },
  },
  token: {
    name: "bearerToken",
    label: "TOKEN",
  },
  authHeaderKey: {
    name: "authHeaderKey",
    label: "Auth Header Name",
  },
  authHeaderValue: {
    name: "authHeaderValue",
    label: "Auth Header Value",
  },
  enabled: {
    name: "enabled",
    label: "Enabled",
  },
};

// Manage Tags
const TagConfig: TagConfigForm = {
  createTag: {
    name: "newTag",
    label: "Label Name",
    confirmationModalDescription: "Please enter a new label",
  },
  validationMessages: {
    requiredCreateNewTag: "Label should not be empty",
    invalidTag:
      "Tags can only contain lowercase letters, numbers, hyphens (-), or underscores (_).",
  },
};

const OpenIDExternalIDP: AddEditOpenIDExternalIDPForm = {
  displayName: {
    name: "displayName",
    label: "Display Name",
    validationMessages: {
      required: "Display Name required",
    },
  },
  tokenURL: {
    name: "tokenURL",
    label: "Token URL",
    validationMessages: {
      required: "Token URL required",
      regex: "Invalid URL",
    },
  },
  authURL: {
    name: "authURL",
    label: "Authorization URL",
    validationMessages: {
      required: "Authorization URL required",
      regex: "Invalid URL",
    },
  },
  clientID: {
    name: "clientId",
    label: "Client ID",
    validationMessages: {
      required: "Client ID required",
    },
  },
  clientSecret: {
    name: "clientSecret",
    label: "Client Secret",
    validationMessages: {
      required: "Client secret required",
    },
    type: "password",
  },
  userInfoURL: {
    name: "userInfoURL",
    label: "User Info URL",
    validationMessages: {
      regex: "Invalid URL",
    },
  },
  logoutURL: {
    name: "logoutURL",
    label: "Logout URL",
    validationMessages: {
      regex: "Invalid URL",
    },
  },
};

const SAMLExternalIDP: AddEditSAMLExternalIDPForm = {
  displayName: {
    name: "displayName",
    label: "Display Name",
    validationMessages: {
      required: "Display Name required",
    },
  },
  entityId: {
    name: "entityId",
    label: "Entity ID",
    validationMessages: {
      required: "Entity ID required",
    },
  },
  signOnURL: {
    name: "signOnURL",
    label: "Single Sign-On Service URL",
    validationMessages: {
      required: "Single Sign-On Service URL required",
      regex: "Invalid URL",
    },
  },
  logoutURL: {
    name: "logoutURL",
    label: "Single Log-Out Service URL",
    validationMessages: {
      required: "Single Log-Out Service URL required",
      regex: "Invalid URL",
    },
  },
  nameIdPolicyFormat: {
    preventRenderInLoop: true,
    name: "nameIdPolicyFormat",
    label: "NameID Policy Format",
    validationMessages: {
      required: "NameID Policy Format required",
    },
  },
  certificate: {
    preventRenderInLoop: true,
    name: "certificate",
    label: "Validating X509 certificate data",
  },
};

export default {
  AddAndEditUser,
  UpdateProfile,
  AddTenant,
  UpdatePassword,
  TenantName,
  CreateOrUpdateExportConfig,
  TagConfig,
  OpenIDExternalIDP,
  SAMLExternalIDP,
};
