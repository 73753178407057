import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// Components
import DashboardCard from "@Components/Card/DashboardCard";
import PaginatedTable from "@Components/Table/PaginatedTable";
import Filter from "@Components/Filter";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";

// Containers
import DeviceInfoDetails from "@Containers/deviceInfoDetails";

// Constants
import Constants from "@Constants";
import {
  DashboardDevicesDataKeys,
  DashboardPageTabs,
  DevicesNeedingAttentionFilters,
  Regex,
} from "@Constants/common";
import {
  DevicesInitSearchConfig,
  DevicesTableAutoSuggestOptions,
  FieldNameForSearchField,
  KeyForDevicesTableAutoSuggest,
  tableSearchErrorMessages,
} from "@Constants/tableSearchConst";
import {
  DefaultSortingFieldForDevices,
  DefaultSortingOrder,
  DevicesTableSortingFields,
} from "@Constants/sorting";
import {
  CardIds,
  CommonIds,
  DashboardContainerIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  FilterIds,
  SortDropdownGroupIds,
  SkeletonLoaderIds,
  TableIds,
} from "@Constants/Id";

// Utils
import Utils from "@Utils";

// Actions
import { fetchDeviceMetrics, fetchDevices, showAlert } from "@Store/actions";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";
import useCustomMediaQuery from "@Hooks/useCustomMediaQuery";
import usePagination from "@Hooks/usePagination";
import useTenantAccess from "@Hooks/useTenantAccess";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";
import useTab from "@Hooks/useTab";

// Styles
import useStyles from "@Containers/dashboard/styles";

const Devices = (): JSX.Element => {
  const classes = useStyles();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [attentionType, setAttentionType] = useState<string>(
    Utils.getAttentionTypeFromQueryParams(queryParams) ||
      Constants.devicesNeedingAttentionFilterData[0].value
  );
  // State for the search string in the filter auto-suggest reusable component
  const [searchString, setSearchString] = useState("");

  const dispatch = useDispatch();
  const { metrics, loading, error } = useSelector(
    (state: GlobalState) => state.deviceMetrics
  );
  const attentionNeededDevicesState = useSelector(
    (state: GlobalState) => state.devices
  );
  const { isMobile } = useCustomMediaQuery();
  const { selectedTenant } = useTenantAccess();
  const renderedFirstTime = useRef(true);

  // Used to keep the track of the page render cycle i.e. whether the page is rendered for the first time
  const pageRenderedForFirstTime = useRef(true);

  // Sorting
  const {
    filters: sort,
    setFilters: setSort,
    setShouldReplace,
  } = useFilterAndSorting({
    sortBy: DefaultSortingFieldForDevices,
    sortOrder: DefaultSortingOrder,
  });

  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(DevicesInitSearchConfig);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    resetParams,
    setCurrentPage,
  } = usePagination({
    searchString: `tab=${Utils.getTabStringFromQueryParams(queryParams)}`,
    otherQueryParams: {
      attentionType,
      ...sort,
      ...searchConfig,
    },
  });

  // To abort the fetch API request in case we want to call fetch API based on different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  // Initialize useCustomHistory hook to access history
  const history = useCustomHistory();

  // Stores id of device for which is selected to show its events in drawer
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  // Track if device info details drawer is open/closed
  const [deviceDetailsDrawerOpen, setDeviceDetailsDrawerOpen] =
    useState<boolean>(false);

  const tabDetails = useTab();

  /**
   * Events for device info details drawer
   * Close drawer
   */
  const closeDeviceDetailsDrawer = (): void => {
    setDeviceDetailsDrawerOpen(false);
    setSelectedDeviceId(null);
  };

  // Info icon column
  const infoIconColumnConfig: TableIconColumnConfig = {
    onClick: (data: RowData) => {
      setSelectedDeviceId((data.id as CellData).value);
      setDeviceDetailsDrawerOpen(true);
    },
    hidden: isMobile,
  };

  const isValidAttentionType = (): boolean =>
    Object.values(Constants.DevicesNeedingAttentionFilters).some(
      (filterValue) => filterValue === attentionType
    );

  {
    /* TODO: uncomment and modify this to be the part of row data when adding device status column */
  }
  // const statusIconColumnConfig: TableIconColumnConfig = {
  //   columnHeading: "Status",
  //   iconElement: Constants.SVGIcons.noHeartBeat,
  //   onClick: (data: Record<string, string>) => {
  //     console.log(data);
  //   },
  // };

  useEffect(() => {
    setAttentionType(
      Utils.getAttentionTypeFromQueryParams(queryParams) ||
        Constants.DevicesNeedingAttentionFilters.all
    );
  }, [location]);

  useEffect(() => {
    if (selectedTenant.selectedTenantId) dispatch(fetchDeviceMetrics());
  }, [selectedTenant]);

  useEffect(() => {
    function getDevices() {
      /**
       *  If a tag searched is invalid, show a warning toast and the API request is not sent
       */
      if (searchConfig.tag && !Regex.IsValidTag.test(searchConfig.tag))
        return dispatch(
          showAlert({
            message: tableSearchErrorMessages.invalidTagSearched,
            severity: "warning",
          })
        );

      /**
       * If searched agent version has space in between, then shows a warning toast and the api request is not send.
       */
      if (
        searchConfig.agentVersion &&
        !Regex.NonWhiteSpace.test(searchConfig.agentVersion)
      )
        return dispatch(
          showAlert({
            message: tableSearchErrorMessages.invalidAgentVersionSearched,
            severity: "warning",
          })
        );

      dispatch(
        fetchDevices(
          {
            page: currentPage,
            pageSize: noOfRowsPerPage,
            ...sort,
            ...searchConfig,
            attentionType: isValidAttentionType()
              ? attentionType
              : DevicesNeedingAttentionFilters.all,
          },
          abortControllerRef.current.signal
        )
      );
    }
    if (selectedTenant.selectedTenantId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      getDevices();
    }
  }, [
    currentPage,
    noOfRowsPerPage,
    attentionType,
    selectedTenant,
    sort,
    searchConfig,
  ]);

  // TODO: use the util function created in branch 387
  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    // Check if there are any filters applied
    if (
      Object.keys(searchConfig).some((searchKey) => !!searchConfig[searchKey])
    ) {
      let newSearchString = "";
      // Construct the search string
      Object.keys(searchConfig).forEach(
        (searchKey) =>
          !!searchConfig[searchKey] &&
          searchKey !== FieldNameForSearchField &&
          (newSearchString += ` ${searchKey}:"${searchConfig[searchKey]}"`)
      );
      if (searchConfig.search) {
        newSearchString += ` ${searchConfig.search}`;
      }
      setSearchString(newSearchString.trim());
    }
  }, [searchConfig]);

  /**
   * Handle changing page number
   * @param newAttentionType
   */
  const onAttentionTypeChange = (newAttentionType: string): void => {
    setAttentionType(newAttentionType);
    resetParams();
  };

  const getStatsNumberOrLoader = (
    statsNumber: string
  ): JSX.Element | string => {
    if (loading) {
      return <SkeletonLoader id={SkeletonLoaderIds.deviceStats} />;
    } else if (error) {
      return "";
    } else return statsNumber;
  };

  useEffect(() => {
    // find correct filter value to be pushed for attentionType in the URL
    let attentionTypeToBePushed = attentionType;

    // check if attention type is invalid, can be invalid when someone intentionally types wrong value in URL
    if (!isValidAttentionType()) {
      // set valid attention type
      setAttentionType(DevicesNeedingAttentionFilters.all);
      attentionTypeToBePushed = DevicesNeedingAttentionFilters.all;
    }

    // replace the current URL if attentionType is not there in the URL, but only if rendered tab is devices, not events
    // since targeting for all location changes, this can also run when we click events tab and set the wrong URL for events tab
    if (
      !Utils.getAttentionTypeFromQueryParams(queryParams) &&
      Utils.getTabStringFromQueryParams(queryParams) ===
        Constants.DashboardPageTabs.Devices
    ) {
      history.replace(
        Utils.getNewPath(
          location.pathname,
          `tab=${Utils.getTabStringFromQueryParams(queryParams)}`,
          {
            page: currentPage,
            pageSize: noOfRowsPerPage,
            attentionType: attentionTypeToBePushed,
            ...sort,
            ...searchConfig,
          }
        )
      );
    }
    // this hook needs to run for all location changes
    // otherwise if useEffect from header container runs just after this hook
    // when the attentionType query param in the URL was yet to be updated
    // then the URL can be invalid, i.e., not containing attentionType query-param
  }, [location]);

  /**
   * Push the updated URL to the browser history when the "attentionType" changes
   */
  useEffect(() => {
    // If rendered for the first time, we don't need to push the URL as the "attentionType" hasn't changed yet
    if (renderedFirstTime.current) {
      renderedFirstTime.current = false;
      return;
    }
    history.push(
      Utils.getNewPath(
        location.pathname,
        `tab=${Utils.getTabStringFromQueryParams(queryParams)}`,
        {
          page: currentPage,
          pageSize: noOfRowsPerPage,
          attentionType: isValidAttentionType()
            ? attentionType
            : DevicesNeedingAttentionFilters.all,
          ...sort,
          ...searchConfig,
        }
      )
    );
  }, [attentionType]);

  // Whenever the tabs changes, reset the sorting filters so that they aren't applied to the next tab
  useEffect(() => {
    if (!tabDetails.currentlyActivePageTab) return;
    // Skip if the page is rendered for the first time
    if (pageRenderedForFirstTime.current) {
      pageRenderedForFirstTime.current = false;
      return;
    }

    // Replace the URL since we're resetting the defaults
    setShouldReplace(true);
    setSort({
      sortBy:
        tabDetails.currentlyActivePageTab !== DashboardPageTabs.Devices
          ? null
          : DefaultSortingFieldForDevices,
      sortOrder:
        tabDetails.currentlyActivePageTab !== DashboardPageTabs.Devices
          ? null
          : DefaultSortingOrder,
    });
    setSearchConfig((prev) =>
      tabDetails.currentlyActivePageTab === DashboardPageTabs.Devices
        ? DevicesInitSearchConfig
        : prev
    );
  }, [tabDetails.currentlyActivePageTab]);

  // Handle change in the applied sorting
  const handleSortingFilterChange = (
    sortBy: string,
    sortOrder: SortStateType
  ) => setSort({ sortBy, sortOrder });

  /**
   * Convert the search string to record and update the state for applied filters
   */
  const applySearch = () => {
    const searchFor = Utils.parseSearchString(
      searchString,
      DevicesTableAutoSuggestOptions.map((option) => option.field)
    );
    // Use lowercase for field name as we're using lower case for case insensitive matching of the field names
    setSearchConfig({
      tag: searchFor.tag ?? null,
      name: searchFor.name ?? null,
      operatingSystem: searchFor.operatingsystem ?? null,
      agentVersion: searchFor.agentversion ?? null,
      search: searchFor.search ?? null,
    });

    // On applying search the current page should reset to the first page.
    setCurrentPage(1);
  };

  return (
    <>
      <Grid
        id={DashboardContainerIds.devicesStatsWrapper}
        container
        spacing={3}
        className="margin-bottom-25"
      >
        {Constants.DashboardCardsData.devices.map((cardData) => (
          <Grid key={cardData.title} item xs={12} sm={6} md={4}>
            <DashboardCard
              id={CardIds.devicesStats({
                title: cardData.title.toLowerCase().replace(/\s/g, "-"),
              })}
              title={cardData.title}
              link={{
                text: cardData.linkTitle ?? "",
                url: cardData.redirectLink ?? "",
              }}
              statsNumber={getStatsNumberOrLoader(
                Utils.addCommasToNumber(
                  metrics[cardData.dataKey as DashboardDevicesDataKeys]
                )
              )}
            />
          </Grid>
        ))}
      </Grid>
      <div
        id={DashboardContainerIds.devicesTableTitleAndFilterWrapper}
        className={classes.tableCardWrapper}
      >
        <div className={`${classes.tableWrapper} flex justify-between`}>
          <div className="flex justify-between">
            <Typography
              id={DashboardContainerIds.devicesTableTitle}
              component="h2"
              className={classes.tableTitle}
            >
              Devices That Need Attention
            </Typography>
            {/* TODO: add proper link during integration and remove d-none */}
            {/* <Link link={"#"} extraClass={`${classes.link} d-none`} showCaret>
              See all
            </Link> */}
          </div>
          <Filter
            id={FilterIds.devices}
            filterConfig={Constants.devicesNeedingAttentionFilterData}
            onChangeHandler={onAttentionTypeChange}
            defaultFilterValue={DevicesNeedingAttentionFilters.all}
            selectedFilterValue={attentionType}
          />
        </div>
      </div>
      <div
        id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
        className={`${classes.filterSortWrapper} margin-top-5 margin-bottom-15 flex-gap-16 d-flex justify-between`}
      >
        <FilterAutoSuggest
          id={FilterAutoSuggestIds.dashboardDevices}
          value={null}
          options={{
            items: DevicesTableAutoSuggestOptions,
            key: KeyForDevicesTableAutoSuggest,
            renderOption: (option: Record<string, string>) => (
              <FilterAutoSuggestOption
                id={FilterAutoSuggestOptionIds.option({ label: option.label })}
                label={option.label}
                helperText={option.helperText}
              />
            ),
          }}
          inputValue={searchString}
          onInputValueChange={(newValue) => setSearchString(newValue)}
          placeholder="Search"
          onEnterKeyPress={applySearch}
          handleClearInput={() => setSearchConfig(DevicesInitSearchConfig)}
          extraClass="flex-grow-1"
        />
        <SortDropdownGroup
          id={SortDropdownGroupIds.devices}
          value={sort.sortBy ?? ""}
          sortState={(sort.sortOrder as "asc" | "desc") ?? null}
          options={DevicesTableSortingFields}
          onChange={handleSortingFilterChange}
        />
      </div>
      {!attentionNeededDevicesState.loading && (
        <PaginatedTable
          id={TableIds.devices}
          headers={Utils.provideButtonOrLinkConfigToHeader(
            Constants.TableColumnsConfig.DevicesColumnNames.localName,
            Constants.TableColumnsConfig.devices,
            null,
            Utils.getDeviceDetailsUrlForTableCell
          )}
          rowsData={Utils.parseToRowDataType(
            Constants.TableColumnsConfig.devices,
            attentionNeededDevicesState?.devices
          )}
          // show page controller if not loading and everything working fine(no api errors)
          showPagination={
            !attentionNeededDevicesState.loading &&
            !attentionNeededDevicesState.error
          }
          pagination={{
            noOfRowsPerPage: noOfRowsPerPage,
            totalRowsAvailable:
              attentionNeededDevicesState?.pagination?.totalRowsAvailable,
            currentPageNumber: currentPage,
            jumpToPage: (pageNumber: number) => {
              onPageChange(pageNumber);
            },
            onClickSetPageSize: (pageSize: number) => {
              onRowSizeChange(pageSize);
            },
            lastPageNumber:
              attentionNeededDevicesState?.pagination?.lastPageNumber,
          }}
          iconColumn={infoIconColumnConfig}
          removeTableShadow
          // TODO: Uncomment the below if adding device status column
          // deviceStatusColumn={statusIconColumnConfig}
        />
      )}
      {selectedDeviceId && (
        <DeviceInfoDetails
          isOpen={deviceDetailsDrawerOpen}
          handleClose={closeDeviceDetailsDrawer}
          deviceId={selectedDeviceId}
        />
      )}
    </>
  );
};

export default Devices;
