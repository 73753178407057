import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Clear as ClearIcon, Edit as EditIcon } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";

// Components
import InputField from "@Components/InputField";
import Button from "@Components/Button";

// Constants
import FormFields from "@Constants/form";
import {
  DefaultClientSecretPlaceholder,
  IdpProtocols,
} from "@Constants/common";
import { ButtonIDs, InputFieldIds, SettingsIds } from "@Constants/Id";

// Hooks
import useTenantAccess from "@Hooks/useTenantAccess";

// Actions
import { createIdpConfig, updateIdpConfig } from "@Store/actions";

// Helpers
import { openIDExternalIDPFormValidation } from "@Helpers/validation";

// Styles
import useStyles from "./styles";

/**
 * Prop types for AddOrEditOpenIDConnect component
 */
type AddOrEditOpenIDConnectProps = {
  /**
   * Handles showing the idpConfig details to the user
   * @param isIdpConfigVisible - boolean indicating if the IdpConfig panel is visible
   */
  setIsIdpConfigVisible: (isIdpConfigVisible: boolean) => void;

  /**
   * Handles updating the state when the external IDP configuration is created successfully
   * @param isProviderAdded - boolean indicating if the external IDP configuration is created successfully
   */
  setIsProviderAdded: (isProviderAdded: boolean) => void;

  /**
   * If formData is passed, initial values will be set in the form fields
   */
  formData?: Record<string, string> | null;
};

const AddOrEditOpenIDConnect = ({
  setIsIdpConfigVisible,
  setIsProviderAdded,
  formData,
}: AddOrEditOpenIDConnectProps) => {
  const [isClientSecretVisible, setIsClientSecretVisible] = useState(false);
  const [isEditingClientSecret, setIsEditingClientSecret] = useState(false);
  const [clientSecretError, setClientSecretError] = useState("");
  const OpenIDExternalIDPFormConfig = FormFields.OpenIDExternalIDP;
  const { clientSecret } = OpenIDExternalIDPFormConfig;
  const classes = useStyles();

  const dispatch = useDispatch();
  const { selectedTenant } = useTenantAccess();

  const { loading: isCreatingIdp } = useSelector(
    (state: GlobalState) => state.createIDPConfig
  );

  const { loading: isUpdatingIdp } = useSelector(
    (state: GlobalState) => state.updateIDPConfig
  );

  const formik = useFormik({
    initialValues: {
      [OpenIDExternalIDPFormConfig.displayName.name]:
        formData?.displayName ?? "",
      [OpenIDExternalIDPFormConfig.tokenURL.name]: formData?.tokenUrl ?? "",
      [OpenIDExternalIDPFormConfig.authURL.name]:
        formData?.authorizationUrl ?? "",
      [OpenIDExternalIDPFormConfig.clientID.name]: formData?.clientId ?? "",
      [OpenIDExternalIDPFormConfig.clientSecret.name]:
        formData?.clientSecret ?? "",
      [OpenIDExternalIDPFormConfig.userInfoURL.name]:
        formData?.userInfoUrl ?? "",
      [OpenIDExternalIDPFormConfig.logoutURL.name]: formData?.logoutUrl ?? "",
    },
    enableReinitialize: true,
    validationSchema: openIDExternalIDPFormValidation,
    onSubmit: async (values) => {
      // If the configuration is being created, the client secret should be required
      if (!formData && !values.clientSecret) {
        setClientSecretError(clientSecret.validationMessages.required);
        return;
      }

      // Construct the payload for creating or updating the field
      const payload: CreateUpdateIdpParams = {
        idpConfigParams: {
          type: IdpProtocols.oidc,
          displayName: values.displayName,
          isEnabled: true,
          tokenUrl: values.tokenURL,
          authorizationUrl: values.authURL,
          clientId: values.clientId,
          ...(!!values.clientSecret && { clientSecret: values.clientSecret }),
          ...(!!values.logoutURL && { logoutUrl: values.logoutURL }),
          ...(!!values.userInfoURL && { userInfoUrl: values.userInfoURL }),
        },
        tenantID: selectedTenant.selectedTenantId,
      };

      return formData
        ? dispatch(
            updateIdpConfig(payload, {
              sideEffect: () => setIsProviderAdded(true),
            })
          )
        : dispatch(
            createIdpConfig(payload, {
              sideEffect: () => setIsProviderAdded(true),
            })
          );
    },
  });

  /**
   * Reset the error in the client secret when the value changes
   */
  useEffect(() => {
    if (clientSecretError && formik.values[clientSecret.name]) {
      setClientSecretError("");
    }
  }, [clientSecretError, formik.values[clientSecret.name]]);

  /**
   * Handles toggling the editable state of the client secret input field
   */
  const handleToggleEditClientSecret = () =>
    setIsEditingClientSecret((prev) => {
      const newState = !prev;

      /**
       * Client secret should be masked if the default placeholder is shown
       * When the clear button is clicked, the value of the clientSecret should be reset
       */
      if (!newState) {
        formik.setFieldValue(clientSecret.name, "");
        setIsClientSecretVisible(false);
      }

      return newState;
    });

  /**
   * Icon for toggling the visibility of the client secret
   */
  const ClientSecretIcons = useMemo(
    () => (
      <InputAdornment position="end">
        {/* Show the visibility icon only if the user is creating the configuration or if the client-secret is being updated when the user is updating the configuration */}
        {((formData && isEditingClientSecret) || !formData) && (
          <IconButton onClick={() => setIsClientSecretVisible((prev) => !prev)}>
            {isClientSecretVisible ? (
              <VisibilityIcon className={classes.visibilityIcon} />
            ) : (
              <VisibilityOffIcon className={classes.visibilityIcon} />
            )}
          </IconButton>
        )}
        {/* Show the edit/clear icon only in case the user is updating the configuration */}
        {formData && (
          <IconButton onClick={handleToggleEditClientSecret}>
            {isEditingClientSecret ? (
              <ClearIcon className={classes.visibilityIcon} />
            ) : (
              <EditIcon className={classes.visibilityIcon} />
            )}
          </IconButton>
        )}
      </InputAdornment>
    ),
    [isClientSecretVisible, formData, isEditingClientSecret]
  );

  /**
   * Returns a boolean indicating if a field is disabled or not
   * @param fieldName The name of the current field being rendered
   * @returns a boolean value
   */
  const isInputFieldReadOnly = (fieldName: string) =>
    fieldName === clientSecret.name && formData && !isEditingClientSecret;

  /**
   * Returns the default placeholder values for input fields
   * @param fieldName The name of the current field being rendered
   * @return an empty string literal or the default placeholder value
   */
  const getDefaultValueForField = (fieldName: string) =>
    fieldName === clientSecret.name && isInputFieldReadOnly(fieldName)
      ? DefaultClientSecretPlaceholder
      : "";

  return (
    <form id={SettingsIds.addOrEditOidcForm} onSubmit={formik.handleSubmit}>
      {Object.entries(OpenIDExternalIDPFormConfig).map(
        ([fieldName, fieldConfig]) => (
          <InputField
            id={InputFieldIds.addOrEditOidc({ fieldName })}
            key={fieldName}
            type={
              isClientSecretVisible || !fieldConfig.type
                ? "text"
                : fieldConfig.type
            }
            label={fieldConfig.label}
            name={fieldConfig.name}
            extraClass={`margin-bottom-25 ${classes.inputField}`}
            onChange={formik.handleChange}
            value={
              formik.values[fieldConfig.name] ||
              getDefaultValueForField(fieldName)
            }
            errorMessage={
              fieldName === clientSecret.name
                ? clientSecretError
                : formik.touched[fieldConfig.name] &&
                  formik.errors[fieldConfig.name]
            }
            required={!!fieldConfig.validationMessages?.required}
            autoComplete="off"
            InputProps={{
              endAdornment: fieldConfig.type ? ClientSecretIcons : null,
            }}
            readOnly={!!isInputFieldReadOnly(fieldName)}
          />
        )
      )}
      <div className={`d-flex justify-${formData ? "end" : "between"}`}>
        {!formData && (
          <Button
            id={ButtonIDs.addOrEditOidcViewBack}
            disabled={isCreatingIdp}
            onClick={() => setIsIdpConfigVisible(true)}
          >
            Back
          </Button>
        )}
        <Button
          id={ButtonIDs.addOrEditOidcFormSubmit}
          type="submit"
          disabled={isCreatingIdp || isUpdatingIdp}
        >
          {formData ? "Update Identity Provider" : "Add new Identity Provider"}
        </Button>
      </div>
    </form>
  );
};

export default AddOrEditOpenIDConnect;
