import React from "react";
import Box from "@material-ui/core/Box";

// Types
import { TabPanelProps } from "./TabPanel";

/**
 * TabPanel component - used to render the tab panels in the Tab component
 *
 * @example Correct usage for hidden tab panel
 * ```ts
 * <TabPanel value={1} index={0}>
 *  <div>Tab Panel Content</div>
 * </TabPanel>
 * ```
 *
 * @example Correct usage for visible tab panel
 * ```ts
 * <TabPanel value={1} index={1}>
 *  <div>Tab Panel Content</div>
 * </TabPanel>
 * ```
 */
export const TabPanel: React.FC<TabPanelProps> = ({
  id,
  children,
  value,
  index,
  extraClass = "",
}): JSX.Element => (
  <div
    id={id}
    role="tabpanel"
    hidden={value !== index}
    aria-labelledby={`tab-${index}`}
    className={extraClass}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);
