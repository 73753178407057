import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import ToggleButton from "@Components/ToggleButton";

// Constants
import { FeatureToAccessTypeMap } from "@Constants/Access.map";

// Actions
import { updateExportConfig } from "@Store/actions";

// Hooks
import usePermissionFilter from "@Hooks/usePermissionFilter";

interface ToggleEnabledProps {
  enabled: boolean;
  configId: string;
  exportUrl: string;
  token?: string;
  authHeaderKey?: string;
  authHeaderValue?: string;
}

const ToggleEnabled = ({
  enabled,
  configId,
  exportUrl,
  token,
  authHeaderKey,
  authHeaderValue,
}: ToggleEnabledProps) => {
  const dispatch = useDispatch();
  const { checkAccessAndRender } = usePermissionFilter();

  const [isEnabled, setIsEnabled] = useState<boolean>(enabled);

  const createOrUpdateExportConfigState = useSelector(
    (state: GlobalState) => state.updateExportConfig
  );

  const updateConfig = (checked: boolean) => {
    setIsEnabled(checked);

    const payload: UpdateExportConfigPayload = {
      exportUrl: exportUrl,
      enabled: checked.toString(),
      token: token,
      authHeaderKey: authHeaderKey,
      authHeaderValue: authHeaderValue,
    };

    dispatch(updateExportConfig(payload, configId));
  };

  return (
    <>
      {checkAccessAndRender({
        featureAccessLevel: FeatureToAccessTypeMap.eventFeed.update,
        child: (
          <ToggleButton
            name="exportConfigToggleButton"
            disabled={createOrUpdateExportConfigState.loading}
            checked={isEnabled}
            onChange={(_, checked) => updateConfig(checked)}
            aria-label={isEnabled ? "Disable Feed" : "Enable Feed"}
          />
        ),
      }) ?? enabled.toString()}
    </>
  );
};

export default ToggleEnabled;
