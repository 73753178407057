import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, generatePath } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";

// Components
import BaseCard from "@Components/Card";
import Button from "@Components/Button";
import InfoCard from "@Components/InfoCard";
import SkeletonLoader from "@Components/Loaders/SkeletonLoader";
import TooltipWithCopy from "@Components/Tooltip/TooltipWithCopy";

// Containers
import ThreatDetails from "@Containers/threatDetail/subComponents/threatDetailsTable";
import ErrorBoundary from "@Containers/errorBoundary";

// Actions
import {
  downloadThreat,
  fetchEventDetails,
  postFileRequest,
  unblockEventAction,
} from "@Store/actions";

// Constants
import Constants from "@Constants";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import {
  ButtonIDs,
  CardIds,
  InfoCardIds,
  SkeletonLoaderIds,
  EventDetailsIds,
  TooltipIds,
} from "@Constants/Id";

// Utils
import Utils from "@Utils";

// Hooks
import useCustomHistory from "@Hooks/useCustomHistory";
import useTenantAccess from "@Hooks/useTenantAccess";
import usePermissionFilter from "@Hooks/usePermissionFilter";

// Styles
import useStyles from "./styles";

const EventDetailsContainer = (): JSX.Element => {
  // Initialize style and theme hooks
  const classes = useStyles();

  const { selectedTenant } = useTenantAccess();
  const { checkAccessAndRender } = usePermissionFilter();
  // Initialize useCustomHistory hook to access history
  const history = useCustomHistory();

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();
  const URIParams: { threatId: string; eventId: string } = useParams();

  // Get event details state from redux store
  const { eventDetails, loading }: EventDetailsState = useSelector(
    (state: GlobalState) => state.eventDetails
  );

  //Download Threat State
  const { DownloadThreatloading } = useSelector(
    (state: GlobalState) => state.downloadThreat
  );

  // state to know whether running api for unblocking event action
  const unblockEventActionState = useSelector(
    (state: GlobalState) => state.unblockEventAction
  );

  // Uncomment while integration of request file
  const threatDetails: ThreatDetailsState = useSelector(
    (state: GlobalState) => state.threatDetails
  );

  const fileRequest: FileRequestState = useSelector(
    (state: GlobalState) => state.fileRequest
  );

  // Uncomment while integration of request file
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);

  const [actionTaken, setActionTaken] = useState(false);

  // Uncomment while integration of request file
  const handleRequestFileClick = () =>
    dispatch(postFileRequest(URIParams.eventId));

  const handleDownloadFileClick = async () => {
    const { response, errorMessage } = await dispatch(
      downloadThreat(URIParams?.threatId)
    );
    if (!errorMessage) {
      window.open(response.download_url);
    }
  };

  const handleUnblockEventAction = async () =>
    dispatch(
      unblockEventAction(eventDetails.threat_id, {
        sideEffect: () => setActionTaken(true),
      })
    );

  const getActionButton = () => {
    if (!loading && eventDetails.action_type === Constants.EventActions.block) {
      return (
        <Button
          id={ButtonIDs.unblockEvent}
          variant="contained"
          color="secondary"
          extraClass={classes.wideButton}
          onClick={handleUnblockEventAction}
          disabled={
            unblockEventActionState.loading ||
            actionTaken ||
            String(threatDetails.threat.allowed) === "true"
          }
        >
          Unblock
        </Button>
      );
    }
    return null;
  };

  useEffect(() => {
    dispatch(fetchEventDetails(URIParams.eventId));
  }, [selectedTenant]);

  /*useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading) {
      timer = setTimeout(() => {
        setIsLoading(false);
        // Uncomment while integration of request file
        setIsDownloadAvailable(true);
      }, Constants.TimeoutIntervals.threatDetailsRequestFile);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);*/

  useEffect(() => {
    if (String(threatDetails.threat.available) === "false") {
      setIsDownloadAvailable(false);
    } else if (String(threatDetails.threat.available) === "true") {
      setIsDownloadAvailable(true);
    }
  }, [threatDetails]);

  return (
    <>
      <Grid
        id={EventDetailsIds.eventDetailsInfoCardsWrapper}
        container
        spacing={2}
        className="margin-bottom-30"
      >
        <InfoCard
          id={InfoCardIds.eventDetailsAction}
          width={12}
          isAlertCard={Boolean(getActionButton())}
          infoTitle="Action"
          icon={Constants.SVGIcons.actionIcon}
          infoSubtitle={
            loading ? (
              <SkeletonLoader id={SkeletonLoaderIds.eventAction} />
            ) : (
              eventDetails.action_type ?? ""
            )
          }
          button={getActionButton()}
        />
        <InfoCard
          id={InfoCardIds.eventDetailsOccurrence}
          width={4}
          infoTitle="Occurrence"
          icon={Constants.SVGIcons.dateIcon}
          infoSubtitle={
            loading ? (
              <SkeletonLoader id={SkeletonLoaderIds.eventOccurrence} />
            ) : (
              <TooltipWithCopy
                tooltipText={Utils.formatDateTime(eventDetails.occurrence_date)}
                tooltipCopyChildren={
                  <div>
                    {Utils.formatDateTime(
                      eventDetails.occurrence_date,
                      Constants.DateTimeFormats.format2
                    )}
                  </div>
                }
                id={TooltipIds.eventOccurrence}
              />
            )
          }
        />
        <InfoCard
          id={InfoCardIds.eventDetailsDeviceName}
          width={4}
          infoTitle="Device Name"
          icon={Constants.SVGIcons.deviceIcon}
          infoSubtitle={
            loading ? (
              <SkeletonLoader id={SkeletonLoaderIds.eventDevice} />
            ) : (
              <TooltipWithCopy
                id={TooltipIds.deviceName}
                tooltipText={eventDetails?.device_name || ""}
                tooltipCopyChildren={
                  <span
                    onClick={() => {
                      history.push(
                        generatePath(
                          Constants.AppRoutes.ProtectedRoutes.DeviceDetails,
                          {
                            id: eventDetails.device_id,
                          }
                        )
                      );
                    }}
                  >
                    {eventDetails?.device_name || ""}
                  </span>
                }
              />
            )
          }
          onClickInfoSubtitle={() => {}}
        />
        <InfoCard
          id={InfoCardIds.eventDetailsDeviceUser}
          width={4}
          infoTitle="Device User"
          icon={Constants.SVGIcons.usersIcon}
          infoSubtitle={
            <TooltipWithCopy
              id={TooltipIds.deviceUser}
              tooltipText={eventDetails?.executing_user || ""}
              tooltipCopyChildren={
                loading ? (
                  <SkeletonLoader id={SkeletonLoaderIds.eventDeviceUser} />
                ) : (
                  eventDetails?.executing_user || ""
                )
              }
            />
          }
        />
      </Grid>
      <BaseCard id={CardIds.downloadRequestThreat} extraClass="margin-top-15">
        <div className="flex justify-between">
          <Typography
            id={EventDetailsIds.threatTableTitle}
            component="h2"
            variant="subtitle2"
            className={classes.tableTitle}
          >
            THREAT
          </Typography>
          {checkAccessAndRender({
            child: (
              <Button
                id={ButtonIDs.threatRequestDownload}
                variant="outlined"
                color="primary"
                onClick={
                  isDownloadAvailable
                    ? handleDownloadFileClick
                    : handleRequestFileClick
                }
                disabled={DownloadThreatloading || fileRequest.loading}
              >
                {isDownloadAvailable ? "Download File" : "Request File"}
              </Button>
            ),
            featureAccessLevel: FeatureToAccessTypeMap.threats.fileAccess,
          })}
        </div>
        <ErrorBoundary>
          <ThreatDetails
            showThreatId
            threatId={URIParams?.threatId}
            eventId={URIParams.eventId}
          />
        </ErrorBoundary>
      </BaseCard>
    </>
  );
};

export default EventDetailsContainer;
