/**
 * Default access level when using vanity DNS for login
 */
export const DefaultAccessLevelForVanityDNSLogin: AccessLevelTypes = "Admin";

/**
 * Inherited Higher Access map
 */
export const InheritedHigherAccessMap: Record<
  AccessLevelTypes,
  AccessLevelTypes[]
> = {
  ReadOnly: ["ReadOnly", "User", "PowerUser", "Admin"],
  User: ["User", "PowerUser", "Admin"],
  PowerUser: ["PowerUser", "Admin"],
  Admin: ["Admin"],
};

/**
 * Feature access map
 */
export const FeatureToAccessTypeMap: Record<
  ModulesWithRbac,
  Record<string, AccessLevelTypes>
> = {
  tenants: {
    view: "ReadOnly",
    create: "Admin",
    update: "Admin",
    delete: "Admin",
  },
  users: {
    view: "PowerUser",
    create: "PowerUser",
    update: "PowerUser",
    delete: "PowerUser",
  },
  devices: {
    view: "ReadOnly",
    delete: "PowerUser",
    tag: "User",
  },
  threats: {
    view: "ReadOnly",
    fileAccess: "User",
    allow: "Admin",
  },
  extractedKeys: {
    view: "Admin",
  },
  cloud: {
    view: "ReadOnly",
  },
  cloudConnections: {
    default: "PowerUser",
  },
  settings: {
    view: "User",
  },
  eventFeed: {
    create: "PowerUser",
    update: "PowerUser",
    delete: "PowerUser",
  },
  installers: {
    view: "User",
    generateToken: "PowerUser",
  },
  events: {
    view: "ReadOnly",
    requestUpload: "User",
  },
};
