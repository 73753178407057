import React, { useEffect } from "react";
import MuiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import TabPanel from "@Components/TabPanel";

// Styles
import useStyles from "./Tabs.styles";

// Types
import { TabsProps } from "./Tabs";

/**
 * Tabs Component - for adding tabs in the UI
 *
 * @example Correct usage - "standard" tabs variant
 * ```ts
 * <Tabs
 *  tabs={tabsData}
 *  onTabSwitch={handleTabSwitch}
 * />
 * ```
 *
 * @example Correct usage - "button" tabs variant
 * ```ts
 * <Tabs
 *  tabs={tabsData}
 *  onTabSwitch={handleTabSwitch}
 *  buttonTab
 * />
 * ```
 */
export const Tabs: React.FC<TabsProps> = ({
  tabs,
  tabClassName = "",
  tabPanelClassName = "",
  defaultSelectedTab = 0,
  containerClassName = "",
  onTabSwitch,
  buttonTab = false,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultSelectedTab);

  /**
   * Update the initial selected tabs
   */
  useEffect(() => {
    if (value !== defaultSelectedTab) setValue(defaultSelectedTab);
  }, [defaultSelectedTab]);

  /**
   * Handle tab change
   * @param event - the event fired when the tab is changed
   * @param newValue - the value of the index of the selected tab
   */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
    onTabSwitch?.(newValue);
  };

  return (
    <div
      className={`${classes.tabsContainer}
        ${containerClassName}
        ${
          buttonTab
            ? classes.buttonTabsContainer
            : classes.standardTabsContainer
        }`}
    >
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant={buttonTab ? "fullWidth" : "standard"}
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs"
        className={tabClassName}
      >
        {tabs.map(({ tab, id }) => (
          <Tab
            id={`${id}-tab`}
            key={id}
            label={tab.label}
            className={tab.hidden ? "d-none" : ""}
          />
        ))}
      </MuiTabs>
      {tabs.map(({ tabPanel, id }, index) => (
        <TabPanel
          id={`${id}-tab-panel`}
          key={id}
          value={value}
          index={index}
          extraClass={tabPanelClassName ? tabPanelClassName : ""}
        >
          {tabPanel}
        </TabPanel>
      ))}
    </div>
  );
};
