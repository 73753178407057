import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * @param background color for radio button string
 * @returns radial-gradient background string
 */
const radioIconBackground = (background: string) => {
  return `radial-gradient(${background},${background} 28%,transparent 50%)`;
};

const useStyles = makeStyles((theme: Theme) => ({
  radioIconButton: {
    padding: `${theme.pxToRem(10)} ${theme.pxToRem(10)} ${theme.pxToRem(10)} 0`,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioLabel: {
    margin: 0,
    padding: `0 ${theme.pxToRem(22)}`,
    "& span": {
      fontSize: theme.pxToRem(13),
    },
    "&:hover": {
      background: theme.newPalette.radioInput.optionHoverBg,
    },
  },
  radioLabelText: {
    color: `${theme.newPalette.text.primary.main}!important`,
  },
  icon: {
    borderRadius: "50%",
    width: theme.pxToRem(12),
    height: theme.pxToRem(12),
    boxShadow: `inset 0 0 0 ${theme.pxToRem(1)} ${
      theme.newColors.secondary.charcoal[800]
    }`,
    backgroundColor: "transparent",
    "input:disabled ~ &": {
      boxShadow: "none",
      opacity: 0.4,
    },
  },
  checkedIcon: {
    backgroundColor: "transparent",
    boxShadow: `inset 0 0 0 ${theme.pxToRem(1)} ${
      theme.newPalette.radioInput.checkedRadioBg
    }`,
    "&:before": {
      display: "block",
      width: theme.pxToRem(12),
      height: theme.pxToRem(12),
      backgroundImage: radioIconBackground(
        theme.newPalette.radioInput.checkedRadioBg
      ),
      content: '""',
    },
  },
  optionsWrapper: {
    padding: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: theme.pxToRem(14),
      color: theme.newPalette.radioInput.color,
    },
  },
}));

export default useStyles;
