import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  fetchEventsForDeviceOrThreat,
  unblockEventAction,
} from "@Store/actions";

// Components
import Button from "@Components/Button";
import FilterAutoSuggestOption from "@Components/FilterAutoSuggestOption";
import FilterAutoSuggest from "@Components/FilterAutoSuggest";
import PaginatedTable from "@Components/Table/PaginatedTable";
import SortDropdownGroup, {
  SortStateType,
} from "@Components/SortDropdownGroup";

// Constants
import Constants from "@Constants";
import { ThreatDetailsTabIndex } from "@Constants/common";
import { FeatureToAccessTypeMap } from "@Constants/Access.map";
import {
  EventsInitSearchConfig,
  EventsSearchParams,
  EventsTableAutoSuggestOptions,
  KeyForEventsTableAutoSuggest,
  SuggestionsForActionType,
} from "@Constants/tableSearchConst";
import {
  DefaultSortingFieldForEvents,
  DefaultSortingOrder,
  EventsSortingFieldParams,
  EventsTableSortingFields,
} from "@Constants/sorting";
import {
  ButtonIDs,
  CommonIds,
  FilterAutoSuggestIds,
  FilterAutoSuggestOptionIds,
  SortDropdownGroupIds,
  TableIds,
} from "@Constants/Id";

// Hooks
import usePagination from "@Hooks/usePagination";
import usePermissionFilter from "@Hooks/usePermissionFilter";
import useFilterAndSorting from "@Hooks/useFilterAndSorting";

// Utils
import Utils from "@Utils";

type EventsForThreatProps = {
  threatId: string;
  eventDetailsFetching?: boolean;
};

const EventsForDevice = ({
  threatId,
  eventDetailsFetching = false,
}: EventsForThreatProps): JSX.Element => {
  // Get events for device state from redux store
  const { events, pagination, loading }: EventsForDeviceOrThreatState =
    useSelector((state: GlobalState) => state.eventsForDeviceOrThreat);

  const { canAccess } = usePermissionFilter();

  // state to know whether running api for unblocking event action
  const unblockEventActionState = useSelector(
    (state: GlobalState) => state.unblockEventAction
  );

  // Initialize useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // State for the search string in the filter auto-suggest reusable component
  const [searchString, setSearchString] = useState("");

  // Sorting
  const { filters: sort, setFilters: setSort } = useFilterAndSorting({
    sortBy: DefaultSortingFieldForEvents,
    sortOrder: DefaultSortingOrder,
  });

  const EventsTableAutoSuggestOptionsWithoutDeviceName = useMemo(
    () =>
      EventsTableAutoSuggestOptions.filter(
        (event) => event.field !== EventsSearchParams.deviceName
      ),
    []
  );

  const EventsTableSortingFieldWithoutDeviceName = useMemo(
    () =>
      EventsTableSortingFields.filter(
        (event) => event.value !== EventsSortingFieldParams.deviceName
      ),
    []
  );

  // Removing `deviceName` from EventsInitSearchConfig
  const { deviceName, ...EventsInitSearchConfigWithoutDeviceName } =
    EventsInitSearchConfig as Record<string, string | null>;

  const { filters: searchConfig, setFilters: setSearchConfig } =
    useFilterAndSorting(EventsInitSearchConfigWithoutDeviceName);

  const {
    currentPage,
    noOfRowsPerPage,
    onPageChange,
    onRowSizeChange,
    setCurrentPage,
  } = usePagination({
    searchString: `tab=${ThreatDetailsTabIndex.Events}`,
    otherQueryParams: {
      ...sort,
      ...searchConfig,
    },
  });

  // To abort the fetch API request in case we want to make a new fetch API request with different filters
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const infoIconColumnConfig: TableIconColumnConfig = {
    onClick: async (data: RowData) => {
      const actionType = (data.action_type as CellData).value;
      if (actionType === Constants.EventActions.block) {
        dispatch(unblockEventAction((data.threat_id as CellData).value));
      }
    },
    iconElement: (data: RowData): JSX.Element => {
      const actionType: string = (data?.action_type as CellData).value;
      return actionType === Constants.EventActions.block ? (
        <Button
          id={ButtonIDs.unblockThreatEvent}
          color="secondary"
          size="small"
          disabled={unblockEventActionState.loading}
        >
          Unblock
        </Button>
      ) : (
        <></>
      );
    },
  };

  // Trigger dispatching the action to fetch data, when the component loads or page number changes in table
  useEffect(() => {
    function getFetchEventsForDeviceOrThreat() {
      /**
       * dispatch the action to fetch the events data for threat
       */
      dispatch(
        fetchEventsForDeviceOrThreat(
          {
            deviceOrThreatId: threatId,
            pageQueryParams: {
              page: currentPage,
              pageSize: noOfRowsPerPage,
            },
            deviceOrThreat: "threat",
            ...sort,
            ...searchConfig,
          },
          abortControllerRef.current.signal
        )
      );
    }
    // TODO: Remove the logic to abort the request when we have a way to handle calling the API only once
    if (threatId) {
      if (loading) {
        // Abort the current request
        abortControllerRef.current.abort();
        // Update the ref value so that we can make subsequent API calls
        abortControllerRef.current = new AbortController();
      }
      // Make the new request
      getFetchEventsForDeviceOrThreat();
    }
  }, [threatId, currentPage, noOfRowsPerPage, sort, searchConfig]);

  // If there are some filters applied in the search config and the "searchString" is empty, then update the "searchString"
  useEffect(() => {
    setSearchString(
      (prev) => Utils.getSearchStringFromSearchConfig(searchConfig) ?? prev
    );
  }, [searchConfig]);

  const getConfiguredHeaders = (): TableHeaderConfig[] => {
    return Utils.provideButtonOrLinkConfigToHeader(
      Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames
        .deviceName,
      Constants.TableColumnsConfig.eventsForDeviceOrThreat,
      null,
      Utils.getEventDetailsUrlForTableCell
    );
  };

  const getRowsData = (): RowData[] => {
    const parsedRowsData = Utils.parseToRowDataType(
      Constants.TableColumnsConfig.eventsForDeviceOrThreat,
      events
    );
    return parsedRowsData.map((rowData) => {
      // subject field should not be colored
      const subjectCellData = rowData[
        Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames.subject
      ] as CellData;
      rowData[
        Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames.subject
      ] = { ...subjectCellData, colored: false };

      const threatID = (rowData.threat_id as CellData).value;
      if (!threatID) {
        // get cell data of device name cell
        const deviceNameCellData = rowData[
          Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames
            .deviceName
        ] as CellData;

        // add colored -> false, to not color if threat_id is null
        rowData[
          Constants.TableColumnsConfig.EventsForDeviceOrThreatColumnNames.deviceName
        ] = { ...deviceNameCellData, colored: false };
        return rowData;
      }
      return rowData;
    });
  };

  // Handle change in the applied sorting
  const onSortGroupChangeHandler = (sortBy: string, sortOrder: SortStateType) =>
    setSort({ sortBy, sortOrder });

  return (
    <>
      <div
        id={CommonIds.filterAutoSuggestAndSortGroupWrapper}
        className={`flex-column-md margin-top-15 flex-gap-16 d-flex justify-between`}
      >
        <FilterAutoSuggest
          id={FilterAutoSuggestIds.threatDetailsEvents}
          value={null}
          options={{
            items: EventsTableAutoSuggestOptionsWithoutDeviceName,
            key: KeyForEventsTableAutoSuggest,
            renderOption: (option: Record<string, string>) => (
              <FilterAutoSuggestOption
                id={FilterAutoSuggestOptionIds.option({ label: option.label })}
                label={option.label}
                helperText={option.helperText}
              />
            ),
          }}
          inputValue={searchString}
          onInputValueChange={(newValue) => setSearchString(newValue)}
          placeholder="Search"
          onEnterKeyPress={() =>
            Utils.handleApplySearchForEvents(
              searchString,
              EventsTableAutoSuggestOptionsWithoutDeviceName,
              searchConfig,
              setSearchConfig,
              dispatch,
              setCurrentPage
            )
          }
          handleClearInput={() =>
            setSearchConfig(EventsInitSearchConfigWithoutDeviceName)
          }
          extraClass="flex-grow-1"
          suggestionConfigForValue={{
            fieldName: EventsSearchParams.actionType,
            suggestions: SuggestionsForActionType,
          }}
        />
        <SortDropdownGroup
          id={SortDropdownGroupIds.events}
          value={sort.sortBy ?? ""}
          sortState={(sort.sortOrder as "asc" | "desc") ?? null}
          options={EventsTableSortingFieldWithoutDeviceName}
          onChange={onSortGroupChangeHandler}
        />
      </div>
      <PaginatedTable
        id={TableIds.threat}
        isLoading={loading || eventDetailsFetching}
        headers={getConfiguredHeaders()}
        rowsData={getRowsData()}
        extraClass="no-border-radius"
        iconColumn={
          canAccess(FeatureToAccessTypeMap.threats.allow)
            ? infoIconColumnConfig
            : undefined
        }
        showPagination
        pagination={{
          noOfRowsPerPage: noOfRowsPerPage,
          totalRowsAvailable: pagination?.totalRowsAvailable,
          currentPageNumber: currentPage,
          jumpToPage: (pageNumber: number) => {
            onPageChange(pageNumber);
          },
          onClickSetPageSize: (pageSize: number) => {
            onRowSizeChange(pageSize);
          },
          lastPageNumber: pagination?.lastPageNumber,
        }}
      />
    </>
  );
};

export default EventsForDevice;
