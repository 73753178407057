import Constants from "@Constants/index";

const initialState: DeleteTagsFromDevices = {
  loading: false,
  isDeleted: false,
  lastDeletedTag: "",
  error: "",
};

/**
 * Handles deleting tags from devices
 * @param state DeleteTag
 * @param action GenericAction
 * @returns DeleteTag
 */
const deleteTagsFromDevicesReducer = (
  state: DeleteTagsFromDevices = initialState,
  action: GenericAction
): DeleteTagsFromDevices => {
  switch (action.type) {
    case Constants.ActionTypes.DELETE_TAGS_FROM_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };
    case Constants.ActionTypes.DELETE_TAGS_FROM_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        error: "",
      };
    case Constants.ActionTypes.DELETE_TAGS_FROM_DEVICES_FAILED:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        error: action?.payload,
      };
    case Constants.ActionTypes.SET_LAST_DELETED_TAG:
      return {
        ...state,
        lastDeletedTag: action.payload,
      };
    case Constants.ActionTypes.RESET_LAST_DELETED_TAG:
      return {
        ...state,
        lastDeletedTag: "",
      };
  }

  return state;
};

export default deleteTagsFromDevicesReducer;
