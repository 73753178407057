import { darken, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  // Modal Container Styles
  modal: {
    "& .MuiDialog-paper": {
      padding: 0,
    },

    "& .MuiDialog-paper > div:first-child": {
      padding: `${theme.pxToRem(30)} ${theme.pxToRem(40)} ${theme.pxToRem(16)}`,
    },
  },

  // Modal Content Styles
  modalContent: {
    padding: 0,
    overflow: "hidden",
    marginBottom: theme.pxToRem(45),
    minHeight: theme.pxToRem(416),
  },

  // Modal Header Styles
  modalTitle: {
    alignSelf: "start",
  },
  modalSubTitle: {
    alignSelf: "start",
    maxWidth: "90%",
    margin: 0,
  },

  // Input wrapper styles
  inputWrapper: {
    backgroundColor: theme.newPalette.manageTagModal.focus,
    borderRadius: theme.pxToRem(8),
    margin: `0 ${theme.pxToRem(40)}`,

    "& .MuiInputLabel-root": {
      color: theme.newPalette.manageTagModal.tag,
    },

    "&:focus-within": {
      backgroundColor: theme.newPalette.manageTagModal.focus,
    },

    "&:hover, &:hover .MuiInputBase-input": {
      backgroundColor: darken(theme.newPalette.manageTagModal.focus, 0.05),
      borderRadius: theme.pxToRem(8),
    },
  },

  inputField: {
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      bottom: theme.pxToRem(-20),
    },
    "& .Mui-error": {
      color: theme.newPalette.error[500],
    },
  },

  errorMessage: {
    margin: `${theme.pxToRem(4)} ${theme.pxToRem(42)} 0`,
    color: theme.newColors.primary.poppyRed[500],
  },

  // Input styles
  input: {
    backgroundColor: theme.newPalette.manageTagModal.focus,
    borderRadius: theme.pxToRem(8),

    "&.Mui-focused": {
      backgroundColor: "unset",
    },

    "&:focus": {
      backgroundColor: darken(theme.newPalette.manageTagModal.focus, 0.1),
    },

    "&:hover": {
      backgroundColor: "unset",
      borderRadius: theme.pxToRem(8),
    },
  },

  button: {
    border: "1px solid transparent",

    "&:hover ~ ": {
      backgroundColor: darken(theme.newPalette.manageTagModal.background, 0.05),
    },

    "&.MuiButton-root.Mui-disabled": {
      opacity: 0.5,
      backgroundColor: `${theme.newPalette.manageTagModal.disabledButton} !important`,
      borderColor: `${theme.newPalette.manageTagModal.disabledButtonBorder} !important`,
    },
  },

  // Tag List Styles
  tagList: {
    marginTop: theme.pxToRem(68),
    maxHeight: theme.pxToRem(288),
    overflowY: "auto",
  },
}));

export default useStyles;
