import Constants from "@Constants";

const initialState: AgentInstallerDetailsState = {
  loading: false,
  downloadUrl: "",
  installToken: "",
  currentVersion: "",
  otherVersions: {},
  error: "",
};

/**
 * Handles actions of Fetching agent installer details
 * @param state AgentInstallerDetailsState
 * @param action GenericAction
 * @returns AgentInstallerDetailsState
 */
const agentInstallerReducer = (
  state: AgentInstallerDetailsState = initialState,
  action: GenericAction
): AgentInstallerDetailsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_SUCCESS:
      return {
        loading: false,
        downloadUrl: action?.payload?.download_url,
        installToken: action?.payload?.install_token,
        currentVersion: action?.payload?.current_version,
        otherVersions: action?.payload?.other_versions,
        error: "",
      };
    case Constants.ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadUrl: action?.payload?.download_url,
        installToken: action?.payload?.install_token,
        error: "",
      };
    case Constants.ActionTypes.FETCH_AGENT_INSTALLER_DETAILS_FAILED:
      return {
        loading: false,
        downloadUrl: "",
        installToken: "",
        currentVersion: "",
        otherVersions: {},
        error: action?.payload,
      };
    case Constants.ActionTypes.GENERATE_NEW_AGENT_INSTALLER_TOKEN_FAILED:
      return {
        loading: false,
        downloadUrl: "",
        installToken: "",
        currentVersion: "",
        otherVersions: {},
        error: action?.payload,
      };
  }
  return state;
};

export default agentInstallerReducer;
