import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",

    "& .MuiInputBase-root": {
      borderRadius: theme.pxToRem(4),
      color: theme.newPalette.input.color,
    },

    "& .MuiFormLabel-root": {
      fontSize: theme.pxToRem(13),
      color: theme.newPalette.input.placeholderColor,
      opacity: 1,

      "&.Mui-focused": {
        color: theme.newColors.fieryOrange,
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.newColors.neutral[400],
    },

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: theme.newColors.fieryOrange,
        },

        "& .MuiInputBase-input": {
          color: theme.newColors.fieryOrange,
        },
      },
    },

    "& .Mui-disabled": {
      opacity: 0.6,

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.newColors.neutral[400],
      },
    },

    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.newPalette.input.activeBorderColor,
      },
    },
  },
  errorState: {
    "& .MuiInputBase-root": {
      color: theme.newPalette.error[500],
    },

    "& .MuiFormLabel-root": {
      color: theme.newPalette.error[500],
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.newPalette.error[500],
    },

    "& .MuiFormHelperText-root": {
      color: theme.newPalette.error[500],
      fontSize: theme.pxToRem(10),
      margin: 0,
    },

    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.newPalette.error[500],
      },
    },
  },
}));

export default useStyles;
