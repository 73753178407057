import { Color } from "@material-ui/lab/Alert";

// Icons
import SVGIcons from "./icon";

// Routes
import { AppRoutes, BaseRoutePaths, ProtectedRoutes } from "./routes";

// Common Pagination constants
export enum Pagination {
  pageSizeDefault = 10,
  maxNoOfPageButtons = 5,
}

// Common querry params constants
export enum QueryParams {
  page = "page",
  tab = "tab",
  pageSize = "page_size",
  startTime = "start_time",
  endTime = "end_time",
  attentionType = "attentionType",
}

// Common date formats
export enum DateTimeFormats {
  format1 = "YYYY-MM-DD hh:mm a",
  format2 = "YYYY-MM-DD",
  datePickerFormat = "MMM. D, YYYY",
}

// Common regex
export const Regex: Record<string, RegExp> = {
  Email:
    /^(([^<>()[]\\.,;:s@']+(.[^<>()[]\\.,;:s@']+)*)|('.+'))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/,
  Password:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+~`[\]{}|\\:;'<>,.?\/]).{8,}$/,
  SubDomain: /[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/,
  HasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
  HasLowerCase: /.*[a-z].*/,
  HasUpperCase: /.*[A-Z].*/,
  HasDigit: /\d/,
  NonWhiteSpace: /^\S+$/,
  endsWithColonOrQuotes: /[:"]$/,
  startAndEndWithQuotes: /^".*"$/i,
  endsWithQuotesFollowedBySpace: /.*" $/i,
  IsURL:
    /^(?:(?:(?:https|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  IsHttpsURL:
    /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  IsValidTag: /^[a-z](?:_?[a-z0-9\-\_]+)$/,
};

// Common error messages
export enum ErrorMessages {
  invalidLoginCredential = "Invalid credentials for user",
  missingToken = "Missing CSRF token",
  jwtExpired = "Jwt is expired",
  invalidOrMissingRefreshToken = "Invalid or missing refresh token.",
  tokenExpired = "Token has expired",
  sessionExpired = "Session expired",
  tokenRevoked = "Token has been revoked",
  sessionRevoked = "Session has been revoked",
  generalMessage = "Some error occurred, Please try again after some time",
  missingTokenOrCookies = "RBAC: access denied",
  invalidTokenOrCookies = "Jwt issuer is not configured",
  mockAPIDataNotAvailable = "You are using mock data and requested mock data is not available",
  invalidJWT = "Jwt is not in the form of Header.Payload.Signature with two dots and 3 sections",
  emptyTableData = "No Record Found",
  noAccessToManageTenants = "This tenant does not have access to manage subtenants.",
  tagUndo = "Action undone failed",
  invalidTag = "Invalid label name",
  duplicateTagFound = "Duplicate label found",
}

// Alert messages
export enum AlertMessages {
  generateNewInstallationTokenTitle = "Generate a new token?",
  generateNewInstallationTokenDescription = "This will permanently invalidate the existing token.",
  confirmGenerateNewInstallationToken = "Generate New Token",
  deleteConfirmationTitle = "Are you sure?",
  tagCreated = "Label Created Successfully.",
}

// Common error codes
export enum ErrorCodes {
  invalidCredential = 401,
  accessDenied = 403,
  notFound = 404,
}

// Common success messages
export const SuccessMessages = {
  threatDetailsFileRequest: "File request submitted",
  unblockRequestSubmitted: "Unblock request submitted",
  addNewUser: "New user created",
  deleteUser: "User deleted",
  userDetailsUpdate: "User details updated",
  passwordChanged: "Password changed",
  copied: "Copied",
  tenantCreated: "New tenant created",
  tenantUpdated: "Tenant updated",
  deleteTenant: "Tenant deleted",
  deviceDeleted: "Device Deleted",
  licenseAccepted: "End user license agreement accepted",
  exportConfigUpdated: "Export config updated",
  exportConfigCreated: "New export config created",
  tagsAdded: "Labels added",
  tagDeleted: "Label deleted",
  tagUndo: "Action undone.",
  tagUpdated: "Label updated",
  bulkTagsApplied: ({ deviceCount }: { deviceCount: number }) =>
    `Tags applied to ${deviceCount} devices`,
};

// Messages for empty states
export enum EmptyStateMessages {
  // Event Feed
  exportConfig = "No export configs available.",
  exportConfigPrompt = "Please create a new Export config.",

  // Manage Tag Modal
  manageTagModal = "There are no labels to display.",
}

// Type of alert
export const AlertSeverity: Record<"success" | "error", Color> = {
  success: "success",
  error: "error",
};

// Alert toast config
export enum AlertToastConfig {
  alertDuration = 6000,
}

// Unique tabs to be displayed in left menu
export enum LeftMenuTabs {
  Dashboard = "dashboard",
  Devices = "devices",
  Events = "events",
  Users = "users",
  Tenants = "tenants",
  Settings = "settings",
}

export enum LeftMenuHiddenTabs {
  DeviceDetails = "deviceDetails",
  ThreatDetails = "threatDetails",
  EventDetails = "eventDetails",
}

export enum DeviceDetailsTabIndex {
  events = "events",
  system = "system",
  os = "os",
  // TODO: uncomment below to show EPP tab
  // epp = "epp",
  extractedKeys = "extractedKeys",
}

export enum ThreatDetailsTabIndex {
  "Details" = "details",
  "Events" = "events",
}

// Page tabs
export enum PagesWithTabs {
  Dashboard = "dashboard",
  ThreatDetails = "threatDetails",
  DeviceDetails = "deviceDetails",
}

export type PageTabsConfig = {
  title: string;
  name: string;
  isActive: boolean;
};

export enum DashboardPageTabs {
  Events = "events",
  Devices = "devices",
  Reset = "",
}

export enum SettingsPageTabs {
  EventFeed = "eventFeed",
  IdentityProvider = "identityProvider",
  Reset = "",
}

export enum ThreatDetailsPageTabs {
  details = 0,
  events = 1,
}

export enum PasswordPolicies {
  atLeastEightChar = "Contain at least 8 Characters",
  atLeastOneUpper = "Contain at least one uppercase letter",
  atLeastOneLower = "Contain at least one lowercase letter",
  atLeastOneNumber = "Contain at least one number",
  atLeastOneSpecial = "Contain at least one special character",
}

export const ThreatDetailsTabs: Record<
  ThreatDetailsTabIndex,
  ThreatDetailsPageTabs
> = {
  [ThreatDetailsTabIndex.Details]: ThreatDetailsPageTabs.details,
  [ThreatDetailsTabIndex.Events]: ThreatDetailsPageTabs.events,
};

export const ThreatDetailsQueryPageTabs: Record<
  ThreatDetailsTabIndex,
  TabParameters
> = {
  [ThreatDetailsTabIndex.Details]: {
    label: ThreatDetailsTabIndex.Details,
    index: ThreatDetailsPageTabs.details,
  },
  [ThreatDetailsTabIndex.Events]: {
    label: ThreatDetailsTabIndex.Events,
    index: ThreatDetailsPageTabs.events,
  },
};

export enum DeviceDetailsPageTabs {
  events = 0,
  system = 1,
  os = 2,
  // TODO: uncomment below to show the EPP tab also, change the value of "extractedKeys" to 4
  // epp = 3,
  extractedKeys = 3,
}

export const DeviceDetailTabs: Record<
  DeviceDetailsTabIndex,
  DeviceDetailsPageTabs
> = {
  [DeviceDetailsTabIndex.events]: DeviceDetailsPageTabs.events,
  [DeviceDetailsTabIndex.system]: DeviceDetailsPageTabs.system,
  [DeviceDetailsTabIndex.os]: DeviceDetailsPageTabs.os,
  // TODO: uncomment below to show the EPP tab also
  // [DeviceDetailsTabIndex.epp]: DeviceDetailsPageTabs.epp,
  [DeviceDetailsTabIndex.extractedKeys]: DeviceDetailsPageTabs.extractedKeys,
};

export const DeviceDetailsQueryPageTabs: Record<
  DeviceDetailsTabIndex,
  TabParameters
> = {
  [DeviceDetailsTabIndex.events]: {
    label: DeviceDetailsTabIndex.events,
    index: DeviceDetailsPageTabs.events,
  },
  [DeviceDetailsTabIndex.system]: {
    label: DeviceDetailsTabIndex.system,
    index: DeviceDetailsPageTabs.system,
  },
  [DeviceDetailsTabIndex.os]: {
    label: DeviceDetailsTabIndex.os,
    index: DeviceDetailsPageTabs.os,
  },
  // TODO: uncomment below to show the EPP tab also
  // [DeviceDetailsTabIndex.epp]: {
  //   label: DeviceDetailsTabIndex.epp,
  //   index: DeviceDetailsPageTabs.epp,
  // },
  [DeviceDetailsTabIndex.extractedKeys]: {
    label: DeviceDetailsTabIndex.extractedKeys,
    index: DeviceDetailsPageTabs.extractedKeys,
  },
};

export const AllPageTabsName: Partial<
  Record<LeftMenuTabs, Record<DashboardPageTabs, string>>
> = {
  [LeftMenuTabs.Dashboard]: {
    [DashboardPageTabs.Devices]: DashboardPageTabs.Devices,
    [DashboardPageTabs.Events]: DashboardPageTabs.Events,
    [DashboardPageTabs.Reset]: DashboardPageTabs.Reset,
  },
};

export const PageTabs: Partial<Record<LeftMenuTabs, PageTabsConfig[]>> = {
  [LeftMenuTabs.Dashboard]: [
    {
      title: "Events",
      isActive: true,
      name: DashboardPageTabs.Events,
    },
    {
      title: "Devices",
      isActive: false,
      name: DashboardPageTabs.Devices,
    },
  ],
  [LeftMenuTabs.Settings]: [
    {
      title: "Event Feed",
      isActive: true,
      name: SettingsPageTabs.EventFeed,
    },
    {
      title: "Identity Provider",
      isActive: false,
      name: SettingsPageTabs.IdentityProvider,
    },
  ],
};

// Secondary page Titles
export enum SecondaryPageTitles {
  deviceDetails = "Device Details",
  eventDetails = "Event Details",
  threatDetails = "Threat Details",
  profile = "My Profile",
  security = "Password and Security",
  userDetails = "User Details",
}

// Page titles (These will be secondary header title, that is, header with a back button)
export const PageTitles: Partial<
  Record<
    LeftMenuTabs | LeftMenuHiddenTabs | BaseRoutePaths,
    SecondaryPageTitleConfig
  >
> = {
  [BaseRoutePaths.DeviceDetails]: {
    title: SecondaryPageTitles.deviceDetails,
    transformable: true,
  },
  [BaseRoutePaths.EventDetails]: {
    title: SecondaryPageTitles.eventDetails,
    transformable: true,
  },
  [BaseRoutePaths.ThreatDetails]: {
    title: SecondaryPageTitles.threatDetails,
    transformable: false,
  },
  [BaseRoutePaths.Profile]: {
    title: SecondaryPageTitles.profile,
    transformable: false,
  },
  [BaseRoutePaths.Security]: {
    title: SecondaryPageTitles.security,
    transformable: false,
  },
  [BaseRoutePaths.UserDetails]: {
    title: SecondaryPageTitles.userDetails,
    transformable: true,
  },
};

// Tabs config
export const LeftMenuTabList: Array<LeftMenuTabConfig> = [
  {
    label: "Dashboard",
    id: LeftMenuTabs.Dashboard,
    route: AppRoutes.ProtectedRoutes.Dashboard,
  },
  {
    label: "Devices",
    id: LeftMenuTabs.Devices,
    route: AppRoutes.ProtectedRoutes.Devices,
  },
  {
    label: "Events",
    id: LeftMenuTabs.Events,
    route: AppRoutes.ProtectedRoutes.Events,
  },
  {
    label: "Users",
    id: LeftMenuTabs.Users,
    route: AppRoutes.ProtectedRoutes.Users,
  },
  {
    label: "Tenants",
    id: LeftMenuTabs.Tenants,
    route: AppRoutes.ProtectedRoutes.Tenants,
  },
  {
    label: "Settings",
    id: LeftMenuTabs.Settings,
    route: AppRoutes.ProtectedRoutes.Settings,
  },
];

export const PageSizeConfigs = {
  pageSize: "page_size",
  rowsPerPageSize: [10, 30, 50],
  maxNoOfHorizontalTableRows: 8,
};

export const tableSkeletonConfigs = {
  noOfRows: 4,
  skeletonLoaderHeight: 20,
};

// Key name for all cookies received on login
export const TokenCookiesKeyName = {
  accessToken: "access_token",
  csrfToken: "csrf_token",
  refreshToken: "refresh_token",
};

// Routes for each tab in left menu
export const TabRoutes: Record<TabRoutesType, LeftMenuTabs> = {
  [ProtectedRoutes.Dashboard]: LeftMenuTabs.Dashboard,
  [ProtectedRoutes.Devices]: LeftMenuTabs.Devices,
  [ProtectedRoutes.Events]: LeftMenuTabs.Events,
  [BaseRoutePaths.DeviceDetails]: LeftMenuTabs.Devices,
  [BaseRoutePaths.EventDetails]: LeftMenuTabs.Events,
  [BaseRoutePaths.ThreatDetails]: LeftMenuTabs.Events,
  [ProtectedRoutes.Tenants]: LeftMenuTabs.Tenants,
  [ProtectedRoutes.Users]: LeftMenuTabs.Users,
  [ProtectedRoutes.Settings]: LeftMenuTabs.Settings,
  [BaseRoutePaths.UserDetails]: LeftMenuTabs.Users,
};

export enum StringFormatters {
  truncateString = "...",
  hideString = "***",
}

export enum MockAPIAuthHash {
  mockServerUsername = "$2a$10$6S.alC3GEx/JFJZVfB56LuY9KhPr0SCu0GT5TD/6ZTFG9XMSydiiy",
  mockServerPassword = "$2a$10$484pxkJPslPvRK9PPQZAwOv8smuV/Da30LxTrMq6WEgi.9PN6CnOm",
}

export enum TimeoutIntervals {
  threatDetailsRequestFile = 5000,
  mockServerDelay = 1000,
}

// Tabs used in the device-details page
export enum DeviceDetailsTabs {
  events = "Events",
  system = "System",
  os = "OS",
  epp = "EPP",
  extractedKeys = "Extracted Keys",
}

// different action types of events;
export enum EventActions {
  block = "Block",
  sandboxed = "Sandbox",
  allow = "Allow",
}

// Device Details about devices heading items
export enum AboutDeviceHeadings {
  localName = "Local Name",
  deviceName = "Device Name",
  registeredOn = "Registered On",
  lastHeartbeat = "Last Heartbeat",
  driver = "Driver",
}

export enum DevicesNeedingAttentionFilters {
  all = "ALL",
  heartbeat = "HEARTBEAT",
  update = "UPDATE",
}

// Filter Data to be used in devices that needs attention table on Devices tab of dashboard
export const devicesNeedingAttentionFilterData: {
  label: string;
  value: DevicesNeedingAttentionFilters;
}[] = [
  {
    label: "Show All",
    value: DevicesNeedingAttentionFilters.all,
  },
  {
    label: "No Heartbeat",
    value: DevicesNeedingAttentionFilters.heartbeat,
  },
  {
    label: "Needs Update",
    value: DevicesNeedingAttentionFilters.update,
  },
];

// Global theme names that a redux state may take
export enum Themes {
  light = "light",
  dark = "dark",
}

// Memory units, used to convert memory size to upper human readable formats
export const MemoryUnits = ["Bytes", "KB", "MB", "GB", "TB"];

// Event Metrics Partition Types
export enum EventMetricsPartitionTypes {
  months = "months",
  weeks = "weeks",
  days = "days",
  hours = "hours",
}

// Month Names
export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Day Names
export const DayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Media Query breakpoint names
export enum Breakpoints {
  mobileMin = "xs",
  tabletMin = "sm",
  desktopMin = "md",
  largeDesktopMin = "lg",
  extraLargeDesktopMin = "xl",
}

// Dashboard Event Card Titles
export enum DashboardEventCardTitles {
  total = "Total",
  blocked = "Blocked",
  sandboxed = "Sandboxed",
}

// Dashboard Devices Card Titles
export enum DashboardDevicesCardTitles {
  total = "Total",
  noHeartBeat = "No Heartbeat",
  needUpdates = "Need Updates",
}

// Dashboard Devices-tab data keys
export enum DashboardDevicesDataKeys {
  total = "total",
  heartbeats = "heartbeats",
  needUpdates = "needUpdates",
}

// Dashboard Events-tab data keys
export enum DashboardEventsDataKeys {
  total = "total",
  blocked = "blocked",
  sandboxed = "sandboxed",
}

export enum SortOrderState {
  ascending = "asc",
  descending = "desc",
  default = "default",
}

// Dashboard Card details - for events and devices tab
export const DashboardCardsData: DashboardTabWiseCardsData = {
  events: [
    {
      title: DashboardEventCardTitles.total,
      icon: SVGIcons.total,
      linkTitle: "See all events",
      redirectLink: AppRoutes.ProtectedRoutes.Events,
      dataKey: DashboardEventsDataKeys.total,
    },
    {
      title: DashboardEventCardTitles.blocked,
      icon: SVGIcons.blocked,
      dataKey: DashboardEventsDataKeys.blocked,
    },
    // Uncomment to integrate sandbox events
    // {
    //   title: DashboardEventCardTitles.sandboxed,
    //   icon: SVGIcons.sandboxed,
    //   dataKey: DashboardEventsDataKeys.sandboxed,
    // },
  ],
  devices: [
    {
      title: DashboardDevicesCardTitles.total,
      icon: SVGIcons.total,
      linkTitle: "See all devices",
      redirectLink: AppRoutes.ProtectedRoutes.Devices,
      dataKey: DashboardDevicesDataKeys.total,
    },
    {
      title: DashboardDevicesCardTitles.noHeartBeat,
      icon: SVGIcons.noHeartBeatLarge,
      dataKey: DashboardDevicesDataKeys.heartbeats,
    },
    {
      title: DashboardDevicesCardTitles.needUpdates,
      icon: SVGIcons.needsUpdate,
      dataKey: DashboardDevicesDataKeys.needUpdates,
    },
  ],
};

// Event Action Types
export const EventActionTypes = {
  allow: "Allow",
  block: "Block",
  extract: "Extract",
};

// Header Toolbar Height
export const ToolbarHeight = {
  mobile: 120,
  aboveMobile: 60,
};

export const LogoAltText = "Halcyon";

// Screen widths
export enum ScreenWidth {
  minSpaceForDatePicker = 992,
}

// By default display all resources
export const DefaultFilter = {
  label: "All Types",
  value: "*",
};

// Default filter label
export const DefaultFilterLabel = "Filter by";

// Info cards icon type
export enum InfoCardIconType {
  smallPrimary = "smallPrimary",
  largeDefault = "largeDefault",
}

// polling interval for user account information from keycloak(in seconds)
export const POLLING_INTERVAL_FOR_USER_ACCOUNT_INFO = 5;

// User session check time intervals (in minutes)
export const SessionTime = {
  timeToLogout: 2,
  sessionTime: 28,
  coefficientToConvertSecToMin: 1 / 60000,
};

export const UserSessionPrompts = {
  activityPromptTitle: "Stay signed in?",
  activityPromptSubTitle:
    "Do this to stay signed in even when you leave your system for a while.",
  LogoutWarningPromptTitle: "Are you still there?",
  LogoutWarningPromptSubTitle: (remainingTime: number) =>
    `You will be automatically logged out in ${remainingTime} seconds`,
};

export const DefaultAccessLevel: AccessLevelTypes = "ReadOnly";

// The different roles for the users
export const AccessLevels = ["Admin", "PowerUser", "User", "ReadOnly"];

// The label for the role auto-complete in the create user form
export const UserRoleLabel = "Role";

// The data for the select component for updating the role of the user
export const UserRoleSelectComponentConfig: {
  label: AccessLevelTypes;
  value: AccessLevelTypes;
}[] = [
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "PowerUser",
    value: "PowerUser",
  },
  {
    label: "User",
    value: "User",
  },
  {
    label: "ReadOnly",
    value: "ReadOnly",
  },
];

// Query params that can be added by OIDC
export const OidcQueryParams = ["code", "state", "session_state"];

// The error message when a fetch API request is aborted
export const AbortRequestErrorMessage = "canceled";

export const ConfirmationDialogDeleteTag = {
  alertTitle: "Are you Sure?",
  alertDescription: (name: string) =>
    `Do you really want to Delete Label '${name}' ?`,
  confirmationButtonText: "Delete This Label",
};

export enum CreateTagModalConstants {
  modalTitle = "New Label",
}

export enum TagPopoverConstants {
  tagExistsErrorMessage = "The label name you have chosen already exists. Please try another name:",
}

export type AddTagToDevicesParams = {
  ids: string[];
  tags: string[];
};

export type DeleteTagsFromDevicesParams = AddTagToDevicesParams;

export const LocalStorageKeyForRecentlyUsedLabel = "labelSuggestion";

export const MaximumLimitForSelectedTags = 5;

export const AddIdentityProviderModalConst = {
  title: "Select Authentication Protocol",
  openIDTitle: "OpenID Connect",
  samlTitle: "SAML",
  providerAdded: "Identity Provider Successfully Added",
  providerUpdated: "Identity Provider Successfully Updated",
  configProvided:
    "The information below will be required by the identity provider.",
};

export const NameIdPolicyFormatValues = [
  {
    label: "Unspecified",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
  },
  {
    label: "Email address",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
  },
  {
    label: "Persistent",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
  },
  // {
  //   label: "Transient",
  //   value: "urn:oasis:names:tc:SAML:2.0:nameid-format:transient",
  // },
];

export const IDPDataKeyToHeaderMap: Record<string, string> = {
  displayName: "Display Name",
  providerType: "Provider Type",
  namePolicyIdFormat: "Name Policy ID Format",
  principalType: "Principal Type",
  singleSignOnServiceUrl: "Single Sign-on Service Url",
  singleLogOutServiceUrl: "Single Logout Service Url",
  tokenUrl: "Token URL",
  authorizationUrl: "Authorization URL",
  clientId: "Client ID",
  clientSecret: "Client Secret",
  userInfoUrl: "User Info URL",
  logoutUrl: "Logout URL",
};
export const SAMLPrincipleType = "SUBJECT";

export enum IdpProtocols {
  saml = "Saml",
  oidc = "oidc",
}

export const ConfirmationDialogDeleteIDP = {
  title: "Are you sure you want to delete the configuration?",
  confirmationDialogText: "Delete Configuration",
};

export const CreateEditSamlFormConsts = {
  signAuthAndValidateAssertionsLabel: "Validate signature for SAML responses",
  certificateRequiredErrorMsg: "Signing certificate required",
};

export const OidcConfigDetailsHeaderMap: Record<
  OidcConfigDetailsHeader,
  string
> = {
  redirectUrl: "Redirect URL",
  homePageUrl: "Homepage URL",
  logoutUrl: "Logout URL",
};

export const SamlConfigDetailsHeaderMap: Record<
  SamlConfigDetailsHeader,
  string
> = {
  entityId: "Entity ID",
  replyUrl: "Single Sign On URL (ACS)",
  relayStateUrl: "Default Relay State URL",
  logoutUrl: "Single Sign Out URL",
};

export const IdpConfigPathsToUser = {
  common: {
    logoutUrl: ({ tenantAlias }: TenantAlias) =>
      `${process.env.REACT_APP_BASE_SSO_URL}/realms/halcyon/broker/${tenantAlias}/endpoint`,
    homePageOrSignOnUrl: ({ tenantAlias }: TenantAlias) =>
      `${process.env.REACT_APP_BASE_UI_URL}/?kc_idp_hint=${tenantAlias}`,
    redirectOrReplyUrl: ({ tenantAlias }: TenantAlias) =>
      `${process.env.REACT_APP_BASE_SSO_URL}/realms/halcyon/broker/${tenantAlias}/endpoint`,
  },
  saml: {
    entityId: "https://sso.halcyon.ai/realms/halcyon",
    relayStateUrl: `${process.env.REACT_APP_BASE_UI_URL}/`,
  },
};

export const UndoTagPersistTimeMs = 5000;

export const DefaultMaxProgressValue = 100;

// The default placeholder value shown(masked) when the user update the OIDC configuration
export const DefaultClientSecretPlaceholder =
  "default-client-secret-placeholder";

export const MaximumDevicesThatCanBeTaggedOnce = 100;
