import ActionTypes from "@Constants/actionTypes";

const initialState: TenantUserRoleState = {
  loading: false,
  error: "",
  role: "",
};

const fetchTenantUserRoleReducer = (
  state: TenantUserRoleState = initialState,
  action: GenericAction
): TenantUserRoleState => {
  switch (action.type) {
    case ActionTypes.FETCH_TENANT_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.FETCH_TENANT_USER_ROLE_SUCCESS:
      return {
        loading: false,
        error: "",
        role: action.payload?.role,
      };
    case ActionTypes.FETCH_TENANT_USER_ROLE_FAILED:
      return {
        loading: false,
        error: action.payload,
        role: "",
      };
  }

  return state;
};

export default fetchTenantUserRoleReducer;
