import { useEffect, useMemo, useState } from "react";

// Components
import Button from "@Components/Button";
import Modal from "@Components/Modal";
import ConfigDetailsToUser from "../ConfigDetailsToUser";

// Constants
import {
  AddIdentityProviderModalConst,
  SettingsPageTabs,
} from "@Constants/common";
import { ButtonIDs, ModalIds } from "@Constants/Id";

// Hooks
import useTab from "@Hooks/useTab";

// Styles
import useStyles from "../AddOrEditProvider/styles";

const ViewIdpDetails = () => {
  const [isSAMLSelected, setIsSAMLSelected] = useState(false);
  const [isOpenIDSelected, setIsOpenIDSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { currentlyActivePageTab } = useTab();

  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      setIsSAMLSelected(false);
      setIsOpenIDSelected(false);
    }
  }, [isOpen]);

  // Buttons for selecting the protocol
  const ProtocolButtons = useMemo(
    () => (
      <div className="flex flex-gap-16">
        <Button
          id={ButtonIDs.viewOpenIdConfiguration}
          extraClass={classes.modalButton}
          onClick={() => setIsOpenIDSelected(true)}
        >
          OpenID Connect
        </Button>
        <Button
          id={ButtonIDs.viewSamlConfiguration}
          extraClass={classes.modalButton}
          onClick={() => setIsSAMLSelected(true)}
        >
          SAML
        </Button>
      </div>
    ),
    []
  );

  const getModalTitle = () => {
    if (isSAMLSelected) return AddIdentityProviderModalConst.samlTitle;
    if (isOpenIDSelected) return AddIdentityProviderModalConst.openIDTitle;
    return AddIdentityProviderModalConst.title;
  };

  return (
    <>
      {currentlyActivePageTab === SettingsPageTabs.IdentityProvider && (
        <Button
          id={ButtonIDs.viewIdpConfiguration}
          variant="outlined"
          onClick={() => setIsOpen(true)}
        >
          Service Provider Configuration
        </Button>
      )}
      <Modal
        id={ModalIds.viewIdpDetails}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title={getModalTitle()}
        {...((isSAMLSelected || isOpenIDSelected) && {
          subTitle: AddIdentityProviderModalConst.configProvided,
        })}
        modalExtraClass={`${classes.modal} ${
          isSAMLSelected || isOpenIDSelected
            ? classes.showIdpConfigExpanded
            : ""
        }`}
        modalTitleExtraClass={`${classes.modalTitle} ${
          isOpenIDSelected || isSAMLSelected ? "text-align-left" : ""
        }`}
        modalSubtitleExtraClass={`${classes.modalSubtitle} text-align-left`}
      >
        {!isSAMLSelected && !isOpenIDSelected && ProtocolButtons}
        {(isOpenIDSelected || isSAMLSelected) && (
          <ConfigDetailsToUser
            setIsOpenIDSelected={setIsOpenIDSelected}
            setIsSAMLSelected={setIsSAMLSelected}
            isOidcSelected={isOpenIDSelected}
            setIsOpen={setIsOpen}
          />
        )}
      </Modal>
    </>
  );
};

export default ViewIdpDetails;
