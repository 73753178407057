import Constants from "@Constants";

const initialState: DeleteDevice = {
  loading: false,
  isDeleted: false,
  error: "",
};

/**
 * Handles delete device actions
 * @param state DeleteDevice
 * @param action GenericAction
 * @returns DeleteDevice
 */
const deleteDeviceReducer = (
  state: DeleteDevice = initialState,
  action: GenericAction
): DeleteDevice => {
  switch (action.type) {
    case Constants.ActionTypes.DELETE_DEVICE_REQUEST:
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };
    case Constants.ActionTypes.DELETE_DEVICE_SUCCESS:
      return {
        loading: false,
        isDeleted: true,
        error: "",
      };
    case Constants.ActionTypes.DELETE_DEVICE_FAILED:
      return {
        loading: false,
        isDeleted: false,
        error: action?.payload,
      };
    case Constants.ActionTypes.ERASE_DEVICE_DELETED_STATE:
      return {
        loading: false,
        isDeleted: false,
        error: action?.payload,
      };
  }

  return state;
};

export default deleteDeviceReducer;
