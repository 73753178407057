import React from "react";

import ErrorBoundary from "@Containers/errorBoundary";
import UsersContainer from "@Containers/users";

const Users = (): JSX.Element => (
  <ErrorBoundary>
    <UsersContainer />
  </ErrorBoundary>
);

export default Users;
