import React, { useEffect, useState } from "react";
import {
  TableFooter,
  TablePagination as MuiTablePagination,
  TableRow,
  TableCell,
} from "@material-ui/core";

// Components
import TablePaginationActions from "./SubComponents/TablePaginationActions";
import Button from "@Components/Button";

// Constants
import { PageSizeConfigs } from "@Constants/common";
import { ButtonIDs } from "@Constants/Id";

// Types
import { TablePaginationProps } from "./TablePagination";

// Styles
import useStyles from "./TablePagination.styles";

/**
 * TablePagination Component
 * @example Correct usage
 * ```tsx
 * <TablePagination
 *  currentPageNumber={currentPageNumber}
 *  lastPageNumber={lastPageNumber}
 *  jumpToPage={handleJumpToPage}
 *  noOfRowsPerPage={noOfRowsPerPage}
 *  onClickSetPageSize={handleSetPageSize}
 *  totalRowsAvailable={totalRowsAvailable}
 * />
 * ```
 */
export const TablePagination = ({
  id,
  noOfRowsPerPage,
  totalRowsAvailable,
  currentPageNumber,
  jumpToPage,
  onClickSetPageSize,
  lastPageNumber = 1,
  extraClass = "",
  handleAllPagesSelection,
  disableNextClick = false,
  disableBackClick = false,
  showAllPagesSelectionOption = false,
  areAllPagesSelected = false,
}: TablePaginationProps): JSX.Element => {
  const classes = useStyles();

  const [rowPerPage, setRowsPerPage] = useState<number>(noOfRowsPerPage);
  const [currentPageNo, setCurrentPageNo] = useState<number>(currentPageNumber);

  useEffect(
    () => setCurrentPageNo(currentPageNumber),
    [totalRowsAvailable, currentPageNumber]
  );

  useEffect(() => {
    setRowsPerPage(noOfRowsPerPage);
  }, [noOfRowsPerPage]);

  // Handles change in no of rows per page
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Value 10 is used a base for parsing
    const pageNumber = parseInt(event.target.value, 10);
    setRowsPerPage(pageNumber);

    if (PageSizeConfigs.rowsPerPageSize.includes(pageNumber)) {
      onClickSetPageSize?.(pageNumber);
    }
  };

  return (
    <TableFooter id={id}>
      <TableRow className={classes.tableFooterRow}>
        {showAllPagesSelectionOption && (
          <TableCell
            className={`${classes.selectAllItemsCell} d-flex align-center`}
          >
            {areAllPagesSelected ? (
              <span>{`All ${totalRowsAvailable} items are selected.`}</span>
            ) : (
              <span>All items on this page are selected.</span>
            )}
            <Button
              id={
                areAllPagesSelected
                  ? ButtonIDs.clearSelection
                  : ButtonIDs.selectAllPages
              }
              extraClass={`${classes.selectAllItemsBtn} cursor-pointer `}
              variant="text"
              onClick={handleAllPagesSelection}
            >
              {areAllPagesSelected
                ? "Clear Selection"
                : `Select all ${totalRowsAvailable} items.`}
            </Button>
          </TableCell>
        )}
        <MuiTablePagination
          className={extraClass}
          rowsPerPageOptions={PageSizeConfigs.rowsPerPageSize}
          count={totalRowsAvailable || rowPerPage}
          rowsPerPage={rowPerPage}
          page={currentPageNumber - 1}
          onPageChange={() => {}}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={() => (
            <TablePaginationActions
              currentPageNo={currentPageNo}
              jumpToPage={jumpToPage}
              totalRowsAvailable={totalRowsAvailable}
              disableBackClick={disableBackClick}
              disableNextClick={disableNextClick}
              lastPageNumber={lastPageNumber}
            />
          )}
          classes={{
            toolbar: classes.paginationToolbar,
            caption: classes.caption,
            selectRoot: classes.rowsPerPageSelectInput,
            menuItem: classes.menuItem,
          }}
        />
      </TableRow>
    </TableFooter>
  );
};
