import { makeStyles, Theme, darken } from "@material-ui/core/styles";

// Constants
import { Breakpoints } from "@Constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: "fixed",
    flexDirection: "column",
    height: "100vh",
    boxShadow: "none",
    overflowY: "auto",
    zIndex: 1200,
    width: theme.pxToRem(252),
    backgroundColor: theme.newPalette.sidebar.background,
    borderRadius: 0,
    padding: `${theme.pxToRem(25)} ${theme.pxToRem(30)}`,

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      width: theme.pxToRem(204),
      padding: `${theme.pxToRem(18)} ${theme.pxToRem(20)}`,
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: theme.pxToRem(265),
      padding: `${theme.pxToRem(40)} ${theme.pxToRem(20)}`,
    },
  },

  menuButton: {
    backgroundColor: "transparent",
    width: "100%",
    color: theme.newPalette.sidebar.colorInActive,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: `${theme.pxToRem(13)} ${theme.pxToRem(16)} ${theme.pxToRem(
      10
    )} ${theme.pxToRem(18)}`,
    borderRadius: theme.pxToRem(10),
    ...theme.utils.buttonMedium(),

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      padding: `${theme.pxToRem(10)} ${theme.pxToRem(13)} ${theme.pxToRem(
        8
      )} ${theme.pxToRem(10)}`,
    },

    "& svg": {
      fill: theme.newPalette.sidebar.colorInActive,
      transform: "scale(1.1)",

      [theme.breakpoints.down(Breakpoints.tabletMin)]: {
        transform: "scale(1)",
      },
    },

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.newPalette.sidebar.colorActive,

      "& svg": {
        fill: theme.newPalette.sidebar.colorActive,
      },
    },
  },
  tabItemLink: {
    "&:active, &:focus .menu-link-inner-content": {
      color: theme.newPalette.sidebar.colorActive,

      "& svg": {
        fill: theme.newPalette.sidebar.colorActive,
      },
    },
  },
  selected: {
    backgroundColor: theme.newPalette.sidebar.activeTabBg,
    color: theme.newPalette.sidebar.colorActive,

    "& svg": {
      fill: theme.newPalette.sidebar.colorActive,
    },

    "&:hover": {
      backgroundColor: darken(theme.newPalette.sidebar.activeTabBg, 0.02),
    },
  },

  logo: {
    width: theme.pxToRem(174),

    // These styles will be applied for screens <= theme.breakpoints.values.md
    // MUI takes breakpoint value one level above for down function while creating the media query breakpoint
    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      width: theme.pxToRem(134),
    },
  },

  drawer: {
    flexShrink: 0,

    "& .MuiDrawer-paper": {
      boxShadow: "none",
    },
  },

  drawerPaper: {
    width: theme.pxToRem(252),

    [theme.breakpoints.down(Breakpoints.tabletMin)]: {
      width: theme.pxToRem(204),
    },

    [theme.breakpoints.down(Breakpoints.mobileMin)]: {
      width: theme.pxToRem(265),
    },
  },

  sectionHeader: {
    margin: `${theme.pxToRem(15)} 0`,
    color: theme.newPalette.sidebar.colorActive,
  },

  tenantFilter: {
    margin: `${theme.pxToRem(20)} 0`,
  },

  menuLinkIcon: {
    marginRight: theme.pxToRem(10),
  },

  menuLinkLabel: {
    color: "inherit",
  },

  filterTextField: {
    width: theme.pxToRem(130),

    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      paddingRight: theme.pxToRem(30),
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
  },
}));

export default useStyles;
