import React from "react";
import Profile from "@Containers/profile";
import ErrorBoundary from "@Containers/errorBoundary";

const ProfilePage = (): JSX.Element => (
  <ErrorBoundary>
    <Profile />
  </ErrorBoundary>
);

export default ProfilePage;
