import Cookies from "js-cookie";

/**
 * Get cookie value using key
 * @param key string
 * @returns cookie value
 */
const getCookieValue = (key: string): string | undefined => {
  return Cookies.get(key);
};

/**
 * Deletes a cookie
 * @param key string
 */
const deleteCookie = (key: string): void => {
  Cookies.remove(key);
};

export default {
  getCookieValue,
  deleteCookie,
};
