import Constants from "@Constants";

// Initial state for editing/updating a tag
const initialState: UpdateTag = {
  loading: false,
  isUpdated: false,
  error: "",
};

/**
 * Handles actions for editing/updating a tag
 * @param state UpdateTag
 * @param action GenericAction
 * @returns UpdateTag
 */
const editTagReducer = (
  state = initialState,
  action: GenericAction
): UpdateTag => {
  switch (action.type) {
    case Constants.ActionTypes.UPDATE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case Constants.ActionTypes.UPDATE_TAG_SUCCESS:
      return {
        loading: false,
        isUpdated: true,
        error: "",
      };
    case Constants.ActionTypes.UPDATE_TAG_FAILED:
      return {
        loading: false,
        isUpdated: false,
        error: action?.payload,
      };
  }

  return state;
};

export default editTagReducer;
