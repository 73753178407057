import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type TooltipStyleProps = {
  backgroundColor: string;
  textColor?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
    fontSize: theme.pxToRem(15),
    cursor: "pointer",
  },

  hideChildren: {
    visibility: "hidden",
  },

  tooltip: {
    color: ({ textColor }: TooltipStyleProps) => textColor ?? "#fff",

    "&.MuiTooltip-tooltip": {
      backgroundColor: ({ backgroundColor }: TooltipStyleProps) =>
        backgroundColor,
      maxWidth: "100vw",
    },
  },

  tooltipArrow: {
    "&.MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: ({ backgroundColor }: TooltipStyleProps) =>
          backgroundColor,
      },
    },
  },
}));

export default useStyles;
