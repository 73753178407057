import { List, Typography } from "@material-ui/core";

// Components
import TagItem from "../TagItem";

// Constants
import { MaximumLimitForSelectedTags } from "@Constants/common";

// Styles
import useStyles from "@Containers/TagPopover/TagPopoverBody/styles";

type SelectedTagsListProps = {
  checkedSuggestions: string[];
  handleCheckInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const SelectedTagsList = ({
  checkedSuggestions,
  handleCheckInputChange,
}: SelectedTagsListProps) => {
  const classes = useStyles();

  return !!checkedSuggestions.length ? (
    <>
      <Typography className={classes.listTitle} variant="body2">
        Selected
      </Typography>
      <List className={`${classes.subListContainer} custom-scroll-bar`}>
        {checkedSuggestions.map((suggestion) => (
          <TagItem
            key={suggestion}
            suggestion={suggestion}
            isChecked={
              !!checkedSuggestions.find((checked) => checked === suggestion)
            }
            handleCheckInputChange={handleCheckInputChange}
            isDisabled={
              checkedSuggestions.length >= MaximumLimitForSelectedTags &&
              !checkedSuggestions.find((checked) => checked === suggestion)
            }
          />
        ))}
      </List>
    </>
  ) : (
    <></>
  );
};

export default SelectedTagsList;
