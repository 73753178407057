import { useState } from "react";
import { useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";

// Actions
import { showAlert } from "@Store/actions";

// Components
import SVGWrapperComponent from "@Components/SVGWrapper";
import Button from "@Components/Button";
import ConfirmationDialog from "@Components/ConfirmationDialog";

// Constants
import Constants from "@Constants";
import { ConfirmationDialogIds } from "@Constants/Id";
import { AlertSeverity } from "@Constants/common";

// Styles
import useStyles from "./ConfigActions.styles";

// Types
import { ConfigActionProps, CustomSVGWrapperProps } from "./ConfigActions";

/**
 * ConfigActions Component
 * @example Correct usage
 * ```tsx
 * <ConfigActions
 *  rowData={rowData}
 *  targetName="targetName"
 *  deleteConfig={deleteConfig}
 *  editFunction={onEditActionClick}
 *  onDeleteSuccess={onDeleteSuccess}
 *  editBtnLabel="editBtnLabel"
 *  deleteBtnLabel="deleteBtnLabel"
 * />
 * ```
 */
export const ConfigActions = ({
  id,
  rowData,
  targetName = "config",
  deleteConfig,
  editFunction = () => null,
  credentialData = {
    name: "",
    id: "",
  },
  handleDeleteConfirmation = true,
  customDeleteFunction,
  onDeleteSuccess,
  deleteBtnLabel,
  editBtnLabel,
}: ConfigActionProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  // Defined a custom SVGWrapper component because we're having almost same styles and config for all the icons
  const SVGWrapper = ({
    icon,
    fill,
    onClick,
    ariaLabel,
  }: CustomSVGWrapperProps): JSX.Element => (
    <Button
      extraClass={classes.configRowIcons}
      onClick={onClick}
      variant="text"
      ariaLabel={ariaLabel}
    >
      <SVGWrapperComponent
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill={fill || theme.newPalette.text.secondary.main}
      >
        {icon}
      </SVGWrapperComponent>
    </Button>
  );

  const deleteTarget = async () => {
    if (deleteConfig) {
      const { response } = await dispatch(
        deleteConfig(rowData?.id.value ?? credentialData.id)
      );

      if (response) {
        onDeleteSuccess?.();
        dispatch(
          showAlert({
            message: `${targetName} deleted`,
            severity: AlertSeverity.success,
          })
        );
      }
    }

    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (handleDeleteConfirmation) setDeleteModalOpen(true);
    else if (customDeleteFunction) customDeleteFunction();
  };

  return (
    <div id={id}>
      <SVGWrapper
        icon={Constants.SVGIcons.editIcon}
        onClick={() => editFunction(rowData ?? credentialData)}
        ariaLabel={editBtnLabel}
      />
      {/* TODO: uncomment and integrate functionality for below icon once halcyon clarifies it. */}
      {/* <SVGWrapper icon={Constants.SVGIcons.copyIcon} onClick={() => ""} /> */}
      <SVGWrapper
        icon={Constants.SVGIcons.deleteIcon}
        fill={theme.newColors.primary.poppyRed[500]}
        onClick={handleDelete}
        ariaLabel={deleteBtnLabel}
      />
      <ConfirmationDialog
        id={ConfirmationDialogIds.deleteConfig({ targetName })}
        isOpen={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirmation={deleteTarget}
        alertTitle="Are you Sure?"
        alertDescription={`Do you really want to Delete this ${targetName}? This action cannot be undone.`}
        confirmButtonText={`Delete ${targetName}`}
      />
    </div>
  );
};
