// Actions Types
import ActionTypes from "@Constants/actionTypes";

// Constants
import { SuccessMessages } from "@Constants/common";

// Client
import Client from "@Client/client";
import bindClientFunc from "@Client/bindClient";

const client = new Client();

/**
 ** Note: By default, the curried function won't be inferring the final return type of the last called function.
 ** Hence we are explicitly type-casting it to the type returned by the last function i.e. Promise<BindClient>
 */

// TODO: modify for dual backend setup
// Handle fetching tenant users list
export const fetchTenantUsers = (
  { page, pageSize }: PageQueryParams,
  tenantId: string
) =>
  bindClientFunc({
    clientFunc: client.getTenantUsers,
    onFailure: ActionTypes.FETCH_TENANT_USERS_FAILED,
    onRequest: ActionTypes.FETCH_TENANT_USERS_REQUEST,
    onSuccess: ActionTypes.FETCH_TENANT_USERS_SUCCESS,
    params: [page.toString(), pageSize.toString(), tenantId],
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
// Handle adding a new tenant user
export const addTenantUser = (
  { email, role }: AddTenantUserPayload,
  parentTenantId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.addTenantUser,
    onFailure: ActionTypes.ADD_TENANT_USERS_FAILED,
    onRequest: ActionTypes.ADD_TENANT_USERS_REQUEST,
    onSuccess: ActionTypes.ADD_TENANT_USERS_SUCCESS,
    params: [email, parentTenantId, role ?? ""],
    handleSuccess: { message: SuccessMessages.addNewUser, ...handleSuccess },
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
// Handle deleting a tenant user
export const deleteTenantUser = (
  { userId }: DeleteTenantUserPayload,
  parentTenantId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.deleteTenantUser,
    onFailure: ActionTypes.DELETE_TENANT_USER_FAILED,
    onRequest: ActionTypes.DELETE_TENANT_USER_REQUEST,
    onSuccess: ActionTypes.DELETE_TENANT_USER_SUCCESS,
    params: [userId, parentTenantId],
    handleSuccess: { message: SuccessMessages.deleteUser, ...handleSuccess },
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
// Handle fetching the list of child tenants
export const fetchChildTenants = (
  { page, pageSize }: PageQueryParams,
  selectedTenantId: string
) =>
  bindClientFunc({
    clientFunc: client.getChildTenants,
    onFailure: ActionTypes.FETCH_CHILD_TENANTS_FAILED,
    onRequest: ActionTypes.FETCH_CHILD_TENANTS_REQUEST,
    onSuccess: ActionTypes.FETCH_CHILD_TENANTS_SUCCESS,
    params: [page.toString(), pageSize.toString(), selectedTenantId],
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
// Handle deleting a tenant user
export const deleteChildTenant = (
  { tenantId }: DeleteChildTenantPayload,
  parentTenantId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.deleteChildTenant,
    onFailure: ActionTypes.DELETE_CHILD_TENANTS_FAILED,
    onRequest: ActionTypes.DELETE_CHILD_TENANTS_REQUEST,
    onSuccess: ActionTypes.DELETE_CHILD_TENANTS_SUCCESS,
    params: [tenantId, parentTenantId],
    handleSuccess: { message: SuccessMessages.deleteTenant, ...handleSuccess },
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
// Handle adding or updating a tenant
export const addTenant = (
  { name, alias }: AddOrUpdateTenantPayload,
  tenantId: string,
  handleSuccess?: ApiSuccessReporting
) =>
  bindClientFunc({
    clientFunc: client.addTenant,
    onFailure: ActionTypes.ADD_OR_EDIT_TENANT_FAILED,
    onRequest: ActionTypes.ADD_OR_EDIT_TENANT_REQUEST,
    onSuccess: ActionTypes.ADD_OR_EDIT_TENANT_SUCCESS,
    params: [name, alias ?? "", tenantId],
    handleSuccess: { message: SuccessMessages.tenantCreated, ...handleSuccess },
  }) as unknown as Promise<BindClient>;

// TODO: modify for dual backend setup
export const updateSelectedTenant = (selectedTenant?: SelectedTenantState) => ({
  type: ActionTypes.UPDATE_SELECTED_TENANT,
  payload: selectedTenant,
});

// Handle action for getting current User's tenant info
export const fetchCurrentTenantInfo = () =>
  bindClientFunc({
    clientFunc: client.getCurrentTenantInfo,
    onFailure: ActionTypes.GET_CURRENT_TENANT_INFO_FAILED,
    onRequest: ActionTypes.GET_CURRENT_TENANT_INFO_REQUEST,
    onSuccess: ActionTypes.GET_CURRENT_TENANT_INFO_SUCCESS,
    params: [],
  }) as unknown as Promise<BindClient>;

// Handle action for adding a new tenant when a new tenant is created
export const addOrUpdateChildTenantList = (
  data: Record<string, string>
): GenericAction => ({
  type: ActionTypes.ADD_OR_UPDATE_CHILD_TENANT_LIST,
  payload: data,
});
