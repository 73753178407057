import Constants from "@Constants/index";

const initialState: TagList = {
  loading: false,
  tags: [],
  error: "",
};

const tagListReducer = (
  state: TagList = initialState,
  action: GenericAction
): TagList => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_TAG_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_TAG_LIST_SUCCESS:
      return {
        tags: action.payload,
        loading: false,
        error: "",
      };
    case Constants.ActionTypes.FETCH_TAG_LIST_FAILED:
      return {
        loading: false,
        tags: [],
        error: action.payload,
      };
  }
  return state;
};

export default tagListReducer;
