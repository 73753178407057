import React from "react";

import DevicesContainer from "@Containers/devices";
import ErrorBoundary from "@Containers/errorBoundary";

const Devices = (): JSX.Element => (
  <ErrorBoundary>
    <DevicesContainer />
  </ErrorBoundary>
);

export default Devices;
