import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textDecoration: "none",
    color: theme.newPalette.text.primary.main,
    fontSize: theme.pxToRem(13),
    transition: "transform 0.1s ease-out",
    "&:hover": {
      color: theme.newPalette.text.primary.main,
      "& .MuiSvgIcon-root": {
        transform: `scale(1.25) translateX(${theme.pxToRem(1)})`,
      },
    },
  },
  caretIcon: {
    fontSize: theme.pxToRem(20),
    marginBottom: theme.pxToRem(-2),
  },
}));

export default useStyles;
