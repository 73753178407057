import Constants from "@Constants";

const initialState: UpdateExportConfigState = {
  loading: false,
  success: false,
  data: {},
  error: "",
};

const updateExportConfigReducer = (
  state: UpdateExportConfigState = initialState,
  action: GenericAction
): UpdateExportConfigState => {
  switch (action.type) {
    case Constants.ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        error: "",
      };
    case Constants.ActionTypes.ADD_OR_UPDATE_EXPORT_CONFIG_FAILED:
      return {
        loading: false,
        success: false,
        data: {},
        error: action?.payload,
      };
  }
  return state;
};

export default updateExportConfigReducer;
