import { IconButton, TableCell } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

// Types
import { IconCellProps } from "./IconCell";

// Styles
import useStyles from "./IconCell.styles";

/**
 * IconCell Component - renders a cell with a default info icon
 *
 * @example Correct usage
 * ```tsx
 * <IconCell
 *  rowData={rowData}
 *  onClick={handleOnClick}
 * />
 * ```
 *
 * @example Correct usage with custom Icon
 * ```tsx
 * <IconCell
 *  rowData={rowData}
 *  onClick={handleOnClick}
 *  iconElement={iconElement}
 * />
 * ```
 */
export const IconCell = ({
  iconElement,
  onClick,
  rowData,
  borderAroundCell = false,
}: IconCellProps): JSX.Element => {
  const classes = useStyles({ borderAroundCell });

  return (
    <TableCell
      key="infoIcon"
      className={`${borderAroundCell && classes.tableRowBorderLeft} 
      ${classes.iconCell} text-align-right`}
    >
      <IconButton
        onClick={() => onClick?.(rowData)}
        className={`padding-0 ${classes.iconBtn}`}
      >
        <span className="display-inline-block">
          {typeof iconElement === "function"
            ? iconElement?.(rowData)
            : iconElement || <InfoOutlined />}
        </span>
      </IconButton>
    </TableCell>
  );
};
