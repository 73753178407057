import { Themes } from "@Constants/common";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { changeTheme } from "@Store/actions";

// Constants
import Constants from "@Constants";

// Logo
import BrandLogoDark from "@Assets/images/logoDark.svg";
import BrandLogoLight from "@Assets/images/logoLight.svg";

export type AppTheme = {
  activeTheme: Themes;
  getBrandLogo: () => string;
  toggleTheme: () => void;
};

/**
 * useAppTheme: a hook to get theme based configs
 * @returns AppTheme
 */
function useAppTheme(): AppTheme {
  const { activeTheme } = useSelector(
    (state: GlobalState) => state.activeTheme
  );
  const dispatch = useDispatch();

  // get the brand logo
  const getBrandLogo = () =>
    activeTheme === Themes.light ? BrandLogoDark : BrandLogoLight;

  // toggle the theme
  const toggleTheme = () =>
    dispatch(
      activeTheme === Constants.Themes.light
        ? changeTheme(Constants.Themes.dark)
        : changeTheme(Constants.Themes.light)
    );

  return {
    activeTheme,
    getBrandLogo,
    toggleTheme,
  };
}

export default useAppTheme;
