import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  autoComplete: {
    "& .MuiInputBase-root .MuiInputBase-input": {
      color: theme.newPalette.input.color,
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.newColors.primary.fieryOrange[500]}!important`,
    },
    "& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.newPalette.input.borderColor,
      },
    "& .MuiFormLabel-root": {
      color: theme.newPalette.input.borderColor,
      "&.Mui-focused": {
        color: theme.newColors.primary.fieryOrange[500],
      },
    },
    "& .Mui-error": {
      "&.MuiFormLabel-root": {
        color: `${theme.newColors.primary.poppyRed[500]}!important`,
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.newColors.primary.poppyRed[500]}!important`,
      },
    },
  },
  endAdornment: {
    "& .MuiIconButton-root": {
      color: theme.newPalette.input.color,
      position: "static",
    },
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
  errorMessage: {
    color: theme.newColors.primary.poppyRed[500],
    fontSize: theme.pxToRem(12),
    margin: `${theme.pxToRem(2)} 0 ${theme.pxToRem(-14)} ${theme.pxToRem(14)}`,
  },
  optionsPopper: {
    "& div": {
      background: theme.newPalette.select.optionsPopoverBg,
      color: theme.newPalette.input.color,
    },
    "& ul": {
      background: theme.newPalette.select.optionsPopoverBg,
      "& li": {
        color: theme.newPalette.input.color,
      },
      "& li:hover": {
        background: theme.newPalette.select.optionsPopoverItemHoverBg,
      },
    },
  },
  resourceIcon: {
    margin: `0 ${theme.pxToRem(10)} 0 0`,
  },
  optionText: {
    color: theme.newPalette.input.color,
  },
}));

export default useStyles;
