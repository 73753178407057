import Constants from "@Constants";
import Utils from "@Utils";

const initialState: EventsState = {
  loading: false,
  events: [],
  error: "",
};

/**
 * Handles actions of Events Fetching
 * @param state EventsState
 * @param action GenericAction
 * @returns EventsState
 */
const eventsReducer = (
  state: EventsState = initialState,
  action: GenericAction
): EventsState => {
  switch (action.type) {
    case Constants.ActionTypes.FETCH_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Constants.ActionTypes.FETCH_EVENTS_SUCCESS:
      return {
        loading: false,
        events: action?.payload?.data,
        pagination: Utils.getPaginationData(action?.payload?.pagination),
        error: "",
      };
    case Constants.ActionTypes.FETCH_DEVICES_FAILED:
      return {
        loading: false,
        events: [],
        error: action?.payload,
      };
  }
  return state;
};

export default eventsReducer;
