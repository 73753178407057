import Constants from "@Constants";

const initialState: DeleteConfigState = {
  loading: false,
  isDeleted: false,
  error: "",
};

/**
 * Handles actions for deleting a Export config
 * @param state DeleteConfigState
 * @param action GenericAction
 * @returns DeleteConfigState
 */
const deleteExportConfigReducer = (
  state: DeleteConfigState = initialState,
  action: GenericAction
): DeleteConfigState => {
  switch (action.type) {
    case Constants.ActionTypes.DELETE_EXPORT_CONFIG_REQUEST:
      return {
        loading: true,
        isDeleted: false,
        error: "",
      };
    case Constants.ActionTypes.DELETE_EXPORT_CONFIG_SUCCESS:
      return {
        loading: false,
        isDeleted: true,
        error: "",
      };
    case Constants.ActionTypes.DELETE_EXPORT_CONFIG_FAILED:
      return {
        loading: false,
        isDeleted: false,
        error: action?.payload,
      };
  }

  return state;
};

export default deleteExportConfigReducer;
