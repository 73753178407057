import React from "react";

// Components
import LeftMenu from "@Components/LeftMenu";

// Containers
import Header from "@Containers/header";

// Styles
import useStyles from "./styles";

type LayoutProps = {
  children?: React.ReactNode;
};

const Home = ({ children }: LayoutProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={`d-flex min-height-100vh ${classes.appWrapper}`}>
      <LeftMenu />
      <div
        className={`flex-column align-flex-start tableViewLayout ${classes.container}`}
      >
        <Header />
        <main className="w-100 table-view-layout-content home-container">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Home;
